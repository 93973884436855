<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
  <h2>Plan de Estudios</h2>
    <div class="row" id="top">
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" id="planestudios" (change)="seleccionarperiodo()">
                <option>-- Plan de estudios --</option>
                <option *ngFor="let plan of planestudiosactivos" value="{{ plan.id }}">{{ plan.carrera.nombre }} - {{ plan.nombre }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="modal fade" id="cargando" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <h5 class="modal-title">Procesando</h5>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>

      
      <div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl  modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Plan de Estudios</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="banderaPDF">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
              <button type="button" class="btn btn-danger" (click)="imprimirpdf()">Imprimir PDF</button>
            </div>
          </div>
        </div>
    </div>

</body>
</html>