<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <h3 class="mb-2">Candidatos a examen profesional</h3>
        <div class="row" >
            <div class="col-8 mt-2">
                <input  [formControl]="this.busquedaForm.get('dato')" class="form-control" autocomplete="off" type="text" name="busqueda" id="busqueda" placeholder="Matricula o Nombre del alumno">
            </div>
            <div class="col-4 mt-2">
                <button class="btn btn-danger" (click)="consultarCandidato()" type="button">Buscar</button>
            </div>
            <div *ngIf="banderaTabla" class="col-12 mt-2">
                <ng2-smart-table class="table table-hover table-borderless" (custom)="evento($event)" [settings]="settings" [source]="src"></ng2-smart-table>
            </div>
            <div *ngIf="banderaSpiner" class="col-12 mt-2">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-danger " role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> 
            </div>
        </div>

    <!-- modal del respuesta -->
        <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog    " role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        {{ respuesta.mensaje }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                    </div>
                </div>
            </div>
        </div>

    <!-- modal del acciones-->
        <div class="modal fade" id="acciones" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">
                            <b>Matricula:</b> {{alumno.matricula}} <br>
                            <b>Nombre:</b> {{alumno.nombrecompleto}} 
                        </h6>
                        <button type="button" class="close" (click)="cerrarModalPrincipal()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-danger btn-block mb-1 text-white" (click)="abrirModalDocumentos()">Revisar documentos</button>
                            </div>
                            <div class="col-6">
                                <button *ngIf="banderaderecho" class="btn btn-danger btn-block mb-1 text-white" (click)="abrirModalDerecho()">Otorgar derecho</button>
                            </div>
                            <div class="col-6">
                                <button *ngIf="banderalista" class="btn btn-danger btn-block mb-1 text-white" (click)="abrirModalExamenes()">Ver derecho a examen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <!-- modal del Otorgar derecho-->
        <div class="modal fade" id="derecho" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Otorgar Derecho a examen profesional</h6>
                        <button type="button" class="close" (click)="cerrarModalDerecho()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <label for="periodo">Período</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('periodo')" class="form-control" id="periodo"  >
                                    <option>-- Periodo --</option>
                                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="modalidad">Carrera</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('alumno_carrera')" class="form-control" id="modalidad"  >
                                    <option>-- Carrera--</option>
                                    <option  *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{ carrera.carrera }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="matricula">Matrícula</label>
                                <input [formControl]="this.AgregarExamenForm.get('alumno_matricula')" class="form-control" autocomplete="off" type="text" name="matricula" id="matricula"> 
                            </div>
                            <div class="col-6">
                                <label for="nombreA">Nombre del alumno</label>
                                <input [formControl]="this.AgregarExamenForm.get('nombrecompleto')" class="form-control" autocomplete="off" type="text" name="nombreA" id="nombreA">
                            </div>
                            <div class="col-6">
                                <label    for="semestre">Semestre</label>
                                <input [formControl]="this.AgregarExamenForm.get('alumno_semestre')" class="form-control" autocomplete="off" type="text" name="semestre" id="semestre">
                            </div>
                            <div class="col-6">
                                <label for="modalidad">Modalidad</label>
                                <div  class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('modalidad')" class="form-control" id="modalidad"  >
                                    <option>-- Modalidad--</option>
                                    <option *ngFor="let m of modalidad" value="{{ m.nombre }}">{{ m.nombre }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="aula">Aula</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('aula')" class="form-control" id="aula"  >
                                        <option>-- Aula --</option>
                                        <option  *ngFor="let aula of aulas" [value]="aula.id">{{aula.nombre}}</option>                                    
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label  for="presidente">Presidente(a)</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('presidente')" class="form-control" id="presidente"  >
                                    <option>-- Presidente --</option>
                                    <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="secretario">Secretario(a)</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('secretario')" class="form-control" id="secretario"  >
                                    <option>-- Secretario --</option>
                                    <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="sinodal">Sinodal</label>
                                <div class="form-group" >
                                    <select [formControl]="this.AgregarExamenForm.get('sinodal')" class="form-control" id="sinodal"  >
                                    <option>-- Sinodal --</option>
                                    <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Fecha y hora</label>
                                <input  [formControl]="this.AgregarExamenForm.get('fecha')" class="form-control" autocomplete="off" type="datetime-local" name="fechahora" id="fechahora">
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Resultado del EGEL</label>
                                <input  [formControl]="this.AgregarExamenForm.get('egelr')" class="form-control" autocomplete="off" type="number" name="egelr" id="egelr">
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Promoción de la salud y prevención de riesgos</label>
                                <input  [formControl]="this.AgregarExamenForm.get('egel1')" class="form-control" autocomplete="off" type="number" name="egel1" id="egel1">
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Diagnostico medico</label>
                                <input  [formControl]="this.AgregarExamenForm.get('egel2')" class="form-control" autocomplete="off" type="number" name="egel2" id="egel2">
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Tratamiento y evolución</label>
                                <input  [formControl]="this.AgregarExamenForm.get('egel3')" class="form-control" autocomplete="off" type="number" name="egel3" id="egel3">
                            </div>
                            <div class="col-6">
                                <label for="fechahora">Sociomedicina</label>
                                <input  [formControl]="this.AgregarExamenForm.get('egel4')" class="form-control" autocomplete="off" type="number" name="egel4" id="egel4">
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="AgregarFecha()">Agregar</button>
                        <button type="button" class="btn btn-secundary" (click)="cerrarModalDerecho()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    <!-- modal de los documentos-->
        <div class="modal fade" id="documentos" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Revisar Documentos</h6>
                        <button type="button" class="close" (click)="cerrarModalDocumentos()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <table class="table table-light table-hover table-striped table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th>Documento</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Servicio Social</td>
                                    <td>
                                        <P *ngIf="ss">Revisado</P>
                                        <P *ngIf="!ss">No revisado</P>
                                    </td>
                                    <td>
                                        <a *ngIf="ss" class="btn btn-danger btn-sm text-white boton" (click)="consultarServicioSocial()" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <!-- <a *ngIf="!ss" class="btn btn-danger btn-sm text-white boton" title="Validar"><i class="fas fa-list-alt " ></i></a> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>Servicio Social Universitario</td>
                                    <td>
                                        <P *ngIf="ssu">Revisado</P>
                                        <P *ngIf="!ssu">No revisado</P>
                                    </td>
                                    <td>
                                        <a *ngIf="ssu" class="btn btn-danger btn-sm text-white boton" (click)="consultarServicioSocialU()" title="Ver PDF"><i class="fas fa-file-pdf" ></i></a>
                                        <!-- <a *ngIf="!ssu" class="btn btn-danger btn-sm text-white boton" title="Validar"><i class="fas fa-list-alt " ></i></a></td> -->
                                </tr>
                                <tr>
                                    <td>Biblioteca</td>
                                    <td>
                                        <P *ngIf="biblioteca">Revisado</P>
                                        <P *ngIf="!biblioteca">No revisado</P>
                                    </td>
                                    <td>
                                        <a *ngIf="biblioteca" class="btn btn-danger btn-sm text-white boton" (click)="consultarArchivo('BIBLIOTECA')" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <a *ngIf="!biblioteca" >
                                            <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i></label>
                                            <input type="file" #inputFile (change)="onFileSelect($event, 'BIBLIOTECA')" id="documento" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Resultado EGEL</td>
                                    <td>
                                        <P *ngIf="egel">Revisado</P>
                                        <P *ngIf="!egel">No revisado</P></td>
                                    <td>
                                        <a *ngIf="egel" class="btn btn-danger btn-sm text-white boton" (click)="consultarArchivo('EGEL')" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <div *ngIf="!egel" >
                                            <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i></label>
                                            <input type="file" #inputFile (change)="onFileSelect($event, 'EGEL')" id="documento" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                                        </div>
                                        </td>
                                </tr>
                                <tr>
                                    <td>Kardex</td>
                                    <td>
                                        <P *ngIf="kardex">Revisado</P>
                                        <P *ngIf="!kardex">No revisado</P>
                                    </td>
                                    <td>
                                        <a *ngIf="kardex" class="btn btn-danger btn-sm text-white boton" (click)="consultarkardex()" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <!-- <a *ngIf="!kardex" class="btn btn-danger btn-sm text-white boton" title="Validar"><i class="fas fa-list-alt" ></i></a></td> -->
                                </tr>
                                <tr>
                                    <td>Pagos</td>
                                    <td>
                                        <P *ngIf="pagos">Revisado</P>
                                        <P *ngIf="!pagos">No revisado</P>
                                    </td>
                                    <td> 
                                       <a *ngIf="pagos" class="btn btn-danger btn-sm text-white boton" (click)="consultarArchivo('PAGOS')" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <div *ngIf="!pagos">
                                            <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i></label>
                                            <input  type="file" #inputFile (change)="onFileSelect($event, 'PAGOS')" id="documento" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                                        </div>
                                </tr>
                                <tr>
                                    <td>Examen Práctico</td>
                                    <td>
                                        <P *ngIf="exprof">Revisado</P>
                                        <P *ngIf="!exprof">No aprobado</P>
                                    </td>
                                    <td>
                                        <a *ngIf="exprof" class="btn btn-danger btn-sm text-white boton" (click)="actaExamen()" title="Ver PDF"><i class="fas fa-file-pdf " ></i></a>
                                        <!-- <a *ngIf="!exprof" class="btn btn-danger btn-sm text-white boton" title="Validar"><i class="fas fa-list-alt" ></i></a> -->
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secundary" (click)="cerrarModalDocumentos()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- modal de los Examenes del alumno-->
        <div class="modal fade" id="examenes" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Examenes del alumno</h6>
                        <button type="button" class="close" (click)="cerrarModalExamenes()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <div *ngIf="banderaTablaExamen">
                            <ng2-smart-table class="table table-hover table-borderless" (custom)="eventExamenesAlumno($event)" [settings]="settingsExamenes" [source]="examenesAlumno"></ng2-smart-table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secundary" (click)="cerrarModalExamenes()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal para ver pdf -->
        <div class="modal fade" id="verpdf" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title"></h6>
                        <button type="button" class="close" (click)="cerrarModalPDF()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <div *ngIf="banderapdf">
                            <tld-file-viewer [src]="pdf" [type]="type"></tld-file-viewer> 
                        </div>                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="descargarPDF()">Descargar PDF</button>
                        <button type="button" class="btn btn-secundary" (click)="cerrarModalPDF()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal para calificar -->
        <div class="modal fade" id="calificar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog " role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Calificar</h6>
                        <button type="button" class="close" (click)="cerrarCalificar()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <label for="sinodal">Calificación</label>
                                <div class="form-group" >
                                    <select [formControl]="this.CalificartForm.get('calificacion')" class="form-control" id="calificacion"  >
                                        <option value="1">Acreditado</option>
                                        <option value="0">No acreditado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="observacion">Observaciones</label>
                                <textarea [formControl]="this.CalificartForm.get('observacion')" class="form-control" maxlength="250"  autocomplete="off" name="observacion" id="observacion"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="Calificar()">Guardar</button>
                        <button type="button" class="btn btn-secundary" (click)="cerrarCalificar()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

            <!-- modal del Editar derecho-->
            <div class="modal fade" id="editarderecho" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title">Editar derecho a examen profesional</h6>
                            <button type="button" class="close" (click)="cerrarModalEditarDerecho()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div> 
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <label for="periodo">Período</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('periodo')" class="form-control" id="periodo"  >
                                        <option>-- Periodo --</option>
                                        <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="modalidad">Carrera</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('alumno_carrera')" class="form-control" id="modalidad"  >
                                        <option>-- Carrera--</option>
                                        <option  *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{ carrera.carrera }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="matricula">Matrícula</label>
                                    <input [formControl]="this.EditarExamenForm.get('alumno_matricula')" class="form-control" autocomplete="off" type="text" name="matricula" id="matricula"> 
                                </div>
                                <div class="col-6">
                                    <label for="nombreA">Nombre del alumno</label>
                                    <input [formControl]="this.EditarExamenForm.get('nombrecompleto')" class="form-control" autocomplete="off" type="text" name="nombreA" id="nombreA">
                                </div>
                                <div class="col-6">
                                    <label    for="semestre">Semestre</label>
                                    <input [formControl]="this.EditarExamenForm.get('alumno_semestre')" class="form-control" autocomplete="off" type="text" name="semestre" id="semestre">
                                </div>
                                <div class="col-6">
                                    <label for="modalidad">Modalidad</label>
                                    <div  class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('modalidad')" class="form-control" id="modalidad"  >
                                        <option>-- Modalidad--</option>
                                        <option *ngFor="let m of modalidad" value="{{ m.nombre }}">{{ m.nombre }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="aula">Aula</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('aula')" class="form-control" id="aula"  >
                                            <option>-- Aula --</option>
                                            <option  *ngFor="let aula of aulas" [value]="aula.id">{{aula.nombre}}</option>                                    
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label  for="presidente">Presidente</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('presidente')" class="form-control" id="presidente"  >
                                        <option>-- Presidente --</option>
                                        <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="secretario">Secretario</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('secretario')" class="form-control" id="secretario"  >
                                        <option>-- Secretario --</option>
                                        <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="sinodal">Sinodal</label>
                                    <div class="form-group" >
                                        <select [formControl]="this.EditarExamenForm.get('sinodal')" class="form-control" id="sinodal"  >
                                        <option>-- Sinodal --</option>
                                        <option  *ngFor="let maestro of responseMaestros" [value]="maestro.persona.id">{{maestro.persona.apellidop}} {{maestro.persona.apellidom}} {{maestro.persona.nombres}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Fecha y hora</label>
                                    <input  [formControl]="this.EditarExamenForm.get('fecha')" class="form-control" autocomplete="off" type="datetime-local" name="fechahora" id="fechahora">
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Resultado del EGEL</label>
                                    <input  [formControl]="this.EditarExamenForm.get('egelr')" max="10" min="0" class="form-control" autocomplete="off" type="number" name="eegelr" id="eegelr">                               
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Promoción de la salud y prevención de riesgos</label>
                                    <input  [formControl]="this.EditarExamenForm.get('egel1')" max="10" min="0" class="form-control" autocomplete="off" type="number" name="eegel1" id="eegel1">
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Diagnostico medico</label>
                                    <input  [formControl]="this.EditarExamenForm.get('egel2')" max="10" min="0" class="form-control" autocomplete="off" type="number" name="eegel2" id="eegel2">
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Tratamiento y evolución</label>
                                    <input  [formControl]="this.EditarExamenForm.get('egel3')" max="10" min="0" class="form-control" autocomplete="off" type="number" name="eegel3" id="eegel3">
                                </div>
                                <div class="col-6">
                                    <label for="fechahora">Sociomedicina</label>
                                    <input  [formControl]="this.EditarExamenForm.get('egel4')" max="10" min="0" class="form-control" autocomplete="off" type="number" name="eegel4" id="eegel4">
                                </div>
                                <div class="col-6">
                                    <label for="cancelarExamen">Cancelar examen</label>
                                    <button class="btn btn-danger btn-block mb-1 text-white" (click)="abriModalCancelar()">Cancelar</button>
                                </div>
                            </div>
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="EditarFecha()">Guardar</button>
                            <button type="button" class="btn btn-secundary" (click)="cerrarModalEditarDerecho()">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- modal de cancelacion -->
            <div class="modal fade" id="cancelacion" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog " role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title">Aviso de cancelacion</h6>
                            <button type="button" class="close" (click)="cerrarModalCancelacion()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div> 
                        <div class="modal-body">
                            ¿Estas seguro de cancelar este examen profesional del alumno <b>{{alumno.nombrecompleto}}</b>, este proceso es <b>irreversible y cuenta como oportunidad</b> para presentar el examen profesional?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="Cancelar()">Aceptar</button>
                            <button type="button" class="btn btn-secundary" (click)="cerrarModalCancelacion()">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
    <!--  -->
    </div>
</body>
</html>