<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}"
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-7">

                        <div class="card">
                            <div class="card-body">
                                <h3>Importación de SESCO</h3>
                                <hr>
                                <nav>
                                    <a class="card-link btn btn-link dropdown-toggle shadow-none" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Importar</a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a *ngIf="!banderaAlumnos" class="dropdown-item" routerLink="/sesco/alumnos" routerLinkActive="active">Alumnos</a>
                                        <a *ngIf="!banderaImportarCali" class="dropdown-item" routerLink="/sesco/calificaciones" routerLinkActive="active">Calificaciones</a>
                                        <a class="dropdown-item" routerLink="/sesco/importar-Cal" routerLinkActive="active">Calificaciones de Periodos Escolares</a>
                                        <a class="dropdown-item" routerLink="/sesco/datos-alumnos" routerLinkActive="active">Datos de Alumnos</a>
                                        <a class="dropdown-item" routerLink="/sesco/datos-personal" routerLinkActive="active">Datos del Personal</a>
                                        <a class="dropdown-item" routerLink="/sesco/periodos" routerLinkActive="active">Periodos Escolares</a>

                                    </div> 
                                    <a *ngIf="!banderaEnviarCalificaciones" class="card-link" routerLink="/sesco/enviarCalificaciones" routerLinkActive="active">Enviar Calificaciones</a>
                                    <a  class="card-link" routerLink="/sesco/mandar-inscritos" routerLinkActive="active">Enviar Inscripciones</a>
                                    <a  class="card-link" routerLink="/sesco/pagos" routerLinkActive="active">Actualizar Pagos</a>

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>
