import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {LocalDataSource} from 'ng2-smart-table';
import { ExcelService } from '../services/excel.service';
import { saveAs } from 'file-saver';

/**
 * Importacion de libreria moment
 */
var moment = require('moment');

/**
 * Importacion de JQUERY
 */
declare var $:any;
/**
 * Componente relacionado a GRUPOS
 */
@Component({
  selector: 'app-grupos-horarios',
  templateUrl: './grupos-horarios.component.html',
  styleUrls: ['./grupos-horarios.component.css']
})
export class GruposHorariosComponent implements OnInit {
  /**
   *  INTERFACE DE MAESTRO PARA SABER QUIEN ESTA EN SESION Y HACER LAS CONSULTAS
   */
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  /**
   *  FORM PARA ABRIR UN GRUPO INDIVIDUAL
   */
  grupoindividual = new FormGroup({
    carrera:new FormControl(''),
    clavemateria:new FormControl(''),
    email:new FormControl(''),
    grupo:new FormControl(''),
    idcarrera: new FormControl(0),
    idgrupo: new FormControl(0),
    idmaestro: new FormControl(0),
    idmateria: new FormControl(0),
    idmpe: new FormControl(0),
    idperiodo: new FormControl(0),
    idpersona: new FormControl(0),
    idplanestudio: new FormControl(0),
    nombreGrupo: new FormControl(''),
    nombremaestro: new FormControl(''),
    nombremateria: new FormControl(''),
    periodo: new FormControl(''),
    plandeestudio: new FormControl(''),
    semestre:new FormControl(0),
    alias:new FormControl(''),
    maximo:new FormControl(0),
    maximoi:new FormControl(0),
    matriculamaestro:new FormControl(''),
    firmado: new FormControl({value: 0, disabled: false}),
    practica:new FormControl(false),
    orden:new FormControl(0)
  });
  /**
   *  FORM PARA CREAR UN GRUPO INDIVIDUAL
   */
  creargrupo = new FormGroup({
    maximo:new FormControl(0),
    maximoi:new FormControl(0),
    nombre:new FormControl(''),
    maestromatricula:new FormControl(''),
    mpeid:new FormControl(0),
    periodoescolarid:new FormControl(0),
    alias:new FormControl(''),
    matriculap:new FormControl('')
  });
  /**
   *  CONFIGURACIONES DE LA TABLA PARA MOSTRAR LOS GRUPOS Y MATERIAS
   */
  settings = {
    columns:{
      carrera:{
        title:'Carrera',
        editable:false,
        width:'10%'
      },
      periodo:{
        title:'Período',
        editable:false,
        width:'10%'
      },
      nombreGrupo:{
        title:'Grupo',
        editable:false,
        width:'5%'
      },
      nombremateria:{
        title:'Materia',
        editable:false,
        type: String,

        width:'25%'
      },
      nombremaestro:{
        title:'Maestro',
        editable:false,
        width:'25%'
      },
      matriculamaestro:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[

      ],
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }
  /**
   *  CONFIGURACIONES DE LA TABLA PARA MOSTRAR HORARIOS DEL GRUPO
   */
  settingsHorario = {
    columns:{
      aula:{
        title:'Aula',
        editable:false,
        width:'30%'
      },
      dia:{
        title:'Día',
        editable:false,
        width:'25%'
      },
      hinicio:{
        title:'Hora Inicio',
        editable:false,
        width:'20%'
      },
      hfin:{
        title:'Hora Final',
        editable:false,
        width:'20%'
      },
      practica:{
        title:'Práctica',
        editable:false,
        width:'20%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'eliminar', title:'<a class="btn btn-danger btn-sm text-white" title="Editar"><i class="fas fa-times" > </i></a> '}
      ],
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin horario disponibles.'
  }
  /**
   *  VARIABLE CON DIAS DE LA SEMANA PARA AGREGAR HORARIOS
   */
  diassemana = [
    'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'
  ];
  /**
   *  VARIABLE CON LOS SEMESTRES PARA AGREGAR HORARIOS
   */
  semestres = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  ];
  /**
   *  VARIABLE CON LOS NOMBRES DE LOS GRUPOS PARA AGREGAR HORARIOS
   */
  grupos = [
    "A", "B", "C", "D", "E"
  ];
  /**
   *  VARIABLE CON ACCIONES PARA LOS GRUPOS EN LA PARTE DE CUPOS
   */
  acciones = [
    "SUMAR", "RESTAR"
  ]
  /**
   *  VARIABLE CON LA RESPUESTA DEL BACKEND
   */
  response = {
    codigo:'',
    mensaje:'',
    detalle:''
  }
  /**
   *  LISTA DE GRUPOS DISPONIBLES
   */
  listagrupos = [];
  /**
  *  LISTA DE PERIODOS DISPONIBLES
  */
  listaperiodos = [];
  /**
  *  LISTA DE MAESTROS DISPONIBLES
  */
  listamaestros = [];
  /**
  *  LISTA DE HORARIOS DISPONIBLES DEL GRUPO
  */
  listahorariodegrupo = [];
  /**
  *  LISTA DE AULAS DISPONIBLES
  */
  listaaulas = [];
  /**
  *  LISTA DE HORARIOS EMPALMADOS EN CASO DE EXISTIR A LA HORA DE DAR DE ALTA HORARIO
  */
  listahorariosempalmados = [];
  /**
  *  LISTA DE HORARIOS DEL GRUPO
  */
  itemSourceHorario: LocalDataSource; 
  /**
  *  VARIABLE PARA MOSTRAR HORARIO COMPLETO
  */
  horariocompleto;
  /**
  *  BANEDRA PARA MOSTRAR DATOS AL ABRIR EL MODAL DE AVISO ANTES DE ELIMINAR 
  */
  banderadatos:boolean = true;

  /****VARIABLES PARA CREAR GRUPOS***/
  /**
  * LISTA DE PERIODOS ESCOLARES DISPONIBLES PARA CREAR GRUPOS
  */
  listaperiodoscrear = [];
  /**
  * LISTA DE CARRERAS DISPONIBLES PARA CREAR GRUPOS
  */
  listacarrerasacrear = [];
  /**
  * LISTA DE MATERIAS PLAN DE ESTUDIOS DISPONIBLES PARA CREAR GRUPOS
  */
  listamateriaspe = [];
  /**
  * LISTA DE MATERIAS PLAN DE ESTUDIOS DISPONIBLES PARA CREAR GRUPOS YA FILTRADAS PARA MOSTRAR MAS FACIL EN EL COMBO
  */
  listamateriaspefiltradas = [];
  /**
  * VALOR PRETERMINADO PARA MOSTRAR EL NUMERO DE ALUMNOS REGULARES
  */
  maximo = 0;
  /**
  * VALOR PRETERMINADO PARA MOSTRAR EL NUMERO DE ALUMNOS IRREGULARES
  */
  maximoi = 0;
  /**
  * VARAIABLE PARA ALMACENAR PLAN DE ESTUDIO SELECCIONADO
  */
  planestudioseleccionado;
  /**
  * BANDERA PARA MOSTRAR TABLA AL CREAR GRUPO
  */
  banderamostrartabla:boolean = true;
  /**
  * BANDERA PARA MOSTRAR TABLA DE EMPALMES DE HORARIOS
  */
  banderaempalmes:boolean = true;

  aluDisponibles = [];

  /**
   * Inicializar el servicio de catalogos
   * @param catalogosService 
   */
  constructor(private catalogosService: CatalogosService,private excelService:ExcelService) { }

  /**
   * ngOninit se utiliza para ejecutar todo lo que esta en esta funcion al ingresar la pagina.
   */
  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.itemSourceHorario = new LocalDataSource(this.listahorariodegrupo);
    this.consultarperiodos();
    this.consultarmaestros();
    this.consultaraulas();
    this.consultarcarreras();
    this.listaPeriodosEscolaresCompleta();
  }

  inscribirAlumno(matricula){
    let parametros = {
      grupoid: this.grupoindividual.get('idgrupo').value,
      matriculap: this.maestro.matricula,
      matricula: matricula
    };
    this.catalogosService.inscribirInterSemestral(parametros).subscribe({
      next:(res:any) => {
        this.response = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.response.codigo == 'OK'){
          this.aluDisponibles = this.aluDisponibles.filter(function( obj ) {
            return obj.matricula !== matricula;
          });
        }
        $('#respuesta').modal('show');
      }
    });
  }

  consularAlumnos(id){
    let parametros = {
      matriculap: this.maestro.matricula,
      grupoid: id
    }
    this.catalogosService.consultarAlumnosParaIntersemestral(parametros).subscribe({
      next:(res:any) => {
        this.aluDisponibles = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.aluDisponibles.sort(function (a, b) {
          if (a.apellidop > b.apellidop) {
            return 1;
          }
          if (a.apellidop < b.apellidop) {
            return -1;
          }
          return 0;
        });
      }
    });
  }

/**
 *  Esta funcion cierra el modal para agregar lugares a una materia
 */
  agregarlugaresmateriasalir(){
    $('#lugarespormateria').modal('hide');
  }
/**
 *  Esta funcion abre el modal para agregar lugares a una materia
 */
  agreguarlugaresmateriamodal(){
    $('#lugarespormateria').modal('show');
    this.consultarmaterias2();
  }
/**
 * Esta funcion se agregan los lugares por materia tomando en cuenta
 * periodo, planestudio, materia, accion, regulares, irregulares
 */
  agregarlugarespormateria(){
    $('#lugarespormateria').modal('hide');
    let periodo = <HTMLSelectElement>document.getElementById('periodoescolarpormateria');
    let planestudio = <HTMLSelectElement>document.getElementById('carrerapormateria');
    let materia = <HTMLSelectElement>document.getElementById('materiapormateria');
    let accion = <HTMLSelectElement>document.getElementById('accionesmateria');
    let regulares = <HTMLSelectElement>document.getElementById('regularespormateria');
    let irregulares = <HTMLSelectElement>document.getElementById('irregularespormateria');
    var jason = {
      periodoid:parseInt(periodo.value),
      planestudioid:parseInt(planestudio.value),
      mpeid:parseInt(materia.value),
      accion:accion.value,
      regular:parseInt(regulares.value),
      irregular:parseInt(irregulares.value),
      matriculap:this.maestro.matricula
    }
    this.catalogosService.cuposmasivos(jason).subscribe({
      next:(data:any) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.response.codigo == "OK"){
          regulares.value = "";
          irregulares.value = "";
        }
        $('#respuesta').modal('show');
        this.consultargruposcompleta();
      }
    });
  }
/**
 * Esta funcion se utiliza para agregar lugares masivos a una materia a todos los grupos.
 */
  agregarlugaresmasivo(){
    $('#lugaresmasivo').modal('hide');
    let periodo = <HTMLSelectElement>document.getElementById('periodoescolarcrearmasivo');
    let semestre = <HTMLSelectElement>document.getElementById('semestresmasivo');
    let grupo = <HTMLSelectElement>document.getElementById('grupomasivo');
    let regulares = <HTMLSelectElement>document.getElementById('regularesmasivo');
    let irregulares = <HTMLSelectElement>document.getElementById('irregularesmasivo');
    let accion = <HTMLSelectElement>document.getElementById('acciones');
    let planestudio = <HTMLSelectElement>document.getElementById('carreramasivo');
    var jason = {
      periodoid:parseInt(periodo.value),
      semestre:parseInt(semestre.value),
      grupo:grupo.value,
      regular:parseInt(regulares.value),
      irregular:parseInt(irregulares.value),
      accion:accion.value,
      matriculap:this.maestro.matricula,
      planestudioid:parseInt(planestudio.value)
    }
    this.catalogosService.cuposmasivos(jason).subscribe({
      next:(data:any) => {
        this.response = data;
        console.log(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.response.codigo == "OK"){
          regulares.value = "";
          irregulares.value = "";
        }
        $('#respuesta').modal('show');
      }
    });
  }
/**
 *  Funcion para cerrar el modal de agregar lugares masivo
 */
  cerraragregar(){
    $('#lugaresmasivo').modal('hide');
  }
/**
 *  Funcion para abrir el modal de agregar lugares masivo
 */
  agrearlugaresmasivomodal(){
    $('#lugaresmasivo').modal('show');
  }
/**
 *  Funcion para abrir el modal de crear grupos.
 */
  cerrarcreargrupos(){
    $('#crear').modal('hide');
  }
/**
 *  Funcion para cerrar el modal de crear horario.
 */
  cerrarmodalhorario(){
    $('#agregarhorariomodal').modal('hide');
    this.listahorariosempalmados = [];
    this.banderaempalmes = true;
  }
/**
 *  Funcion para cerrar el modal de respuesta del servidor.
 */
  cerrarrrespuesta(){
    $('#respuesta').modal('hide');
  }
/**
 *  Funcion para abrir el modal de crear grupos.
 */
  creargruposmodal(){
    $('#crear').modal('show');
    this.consultarmaterias();
  }

  cerrarempalmegrupos(){
    $('#empalmes').modal('hide');
  }

/**
 *  Funcion para abrir el modal agregar horario.
 */
  agregarhorariomodal(){
    $('#agregarhorariomodal').modal('show');
  }
/**
 *  Funcion para cerrar el modal agregar horario.
 */
  agregarhorariomodalsalir(){
    $('#agregarhorariomodal').modal('hide');
  }
/**
 *  Funcion para eliminar horario tomando en cuenta
 * horariocompletoid, matricula del maestro.
 */
  eliminarhorario(){
    $('#aviso').modal('hide');
    this.catalogosService.delete(this.horariocompleto.id, this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.consultarhorariosdegrupo(this.grupoindividual.get('idgrupo').value);
      }
    });
  }
/**
 *  Funcion para cerar el modal de aviso antes de elminar un horario
 */
  cerraraviso(){
    $('#aviso').modal('hide');
  }
/**
 *  Funcion para abrir el modal de aviso antes de elminar un horario
 */
  aviso(event){
    this.horariocompleto = event.data;
    $('#aviso').modal('show');
    this.banderadatos = false;
  }
/**
 * Esta funcion es para editar un grupo tomando en cuenta
 * grupoid, nombre, matricula del maestro del grupo, mpeid, periodoescolarid, maximo regulares y irregulares, matriculap, alias
 */
  editargrupo(){
    var maestroid = <HTMLSelectElement>document.getElementById('maestro');
    var jason = {
      id:this.grupoindividual.get('idgrupo').value,
      nombre:this.grupoindividual.get('grupo').value,
      maestromatricula:{matricula:"", id:parseInt(maestroid.value)},
      mpeid:{id:this.grupoindividual.get('idmpe').value},
      periodoescolarid:{id:this.grupoindividual.get('idperiodo').value},
      maximo:this.grupoindividual.get('maximo').value,
      maximoi:this.grupoindividual.get('maximoi').value,
      matriculap:this.maestro.matricula,
      alias: this.grupoindividual.get('alias').value,
      orden: this.grupoindividual.get('orden').value,
    }
    // console.log(jason);
    this.catalogosService.editarGrupo(jason).subscribe({
      next:(data:any) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.consultargruposcompleta();
      }
    })
  }
periodoescolaractivos=[];

periodosabiertos=[];
// consultarperiodoescolares(){
//   this.catalogosService.consultarperiodoescolar(this.maestro.matricula).subscribe({
//     next:(data : any) => {
//       this.periodoescolaractivos = data;
//       //console.log(data);
//     },
//     error:(err) => {
//       console.log(err);
//     },
//     complete:() => {
//       for(var i = 0; i < this.periodoescolaractivos.length; i++){
//         if(this.periodoescolaractivos[i].estado == "ABIERTO" || "ACTUAL"){
//           this.periodosabiertos.push(this.periodoescolaractivos[i]);
//         }
//       }
//       /*this.consultarcarrerasactivos();*/
//     }
//   });
// }



/**
 *  Funcion para cerar grupos
 */

  creargrupos(index){
    let alias = <HTMLSelectElement>document.getElementById(`aliascrear${index}`);
    let nombre = <HTMLSelectElement>document.getElementById(`grupocrear`);
    let matriculamaestro;
    let periodoescolarid = <HTMLSelectElement>document.getElementById('periodoescolarcrear');
    // debugger
    var maestroid = <HTMLSelectElement>document.getElementById(`maestrocrear${index}`);

    let mpeid = this.listamateriaspefiltradas[index].id;
    let maximo = <HTMLSelectElement>document.getElementById(`maximocrear${index}`);
    let maximoi = <HTMLSelectElement>document.getElementById(`maximoicrear${index}`);
    let maestrocrear = <HTMLSelectElement>document.getElementById(`maestrocrear${index}`);
    for(var x = 0; x < this.listamaestros.length; x++){
      if(maestrocrear.value == this.listamaestros[x].id){
        matriculamaestro = this.listamaestros[x].matricula;
      }
    }
    
    var jason = {
      maximo:parseInt(maximo.value),
      maximoi:parseInt(maximoi.value),
      nombre:nombre.value,
      maestromatricula:{matricula:matriculamaestro, id:parseInt(maestroid.value)},
      mpeid:{id:mpeid},
      periodoescolarid:{id:parseInt(periodoescolarid.value)},
      alias:alias.value,
      matriculap:this.maestro.matricula
    }
    // console.log(jason);
    this.catalogosService.creargrupo(jason).subscribe({
      next:(data:any) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.response.codigo == "OK"){
          this.listamateriaspefiltradas.splice(index, 1);
          $('#respuesta').modal('show');
        }else{
          $('#respuesta').modal('show');
        }
      }
    });
  }
/**
 *  Funcion para revisar si expiste un empalme en los horarios
 */
  revisarempalme(){
    var aula = <HTMLSelectElement>document.getElementById('aula');
    var dia = <HTMLSelectElement>document.getElementById('dias');
    var hinicio  = <HTMLSelectElement>document.getElementById('hinicio');
    var hfin  = <HTMLSelectElement>document.getElementById('hfin');
    let hi = moment(hinicio.value,'HH:mm a').format('HH:mm:ss a');
    let hf = moment(hfin.value,'HH:mm a').format('HH:mm:ss a');
    if(hi >= hf){
      console.log(false);
    }else{
      if(hi < hf){
        var jason = {
          grupoid:this.grupoindividual.get('idgrupo').value,
          matriculap:this.maestro.matricula,
          dia:dia.value,
          horaInicio:hinicio.value+':00',
          horaFin:hfin.value+':00',
          aulaid:aula.value
        };
        this.catalogosService.empalme(jason).subscribe({
          next:(data:any) => {
            this.listahorariosempalmados = data;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            if(this.listahorariosempalmados.length > 0){
              this.banderaempalmes = false;
            }else{
              if(this.listahorariosempalmados.length == 0){
                this.insertarhorario(jason);
              }
            }
          }
        });
      }
    }
  }
/**
 * Funcion para insertar un horario
 * @param data 
 */
  insertarhorario(data){
    let pract = ((this.grupoindividual.get('practica').value==true)?1:0);
    var jason = {
      aulId:parseInt(data.aulaid),
      gruId:data.grupoid,
      grhDia:data.dia,
      grhHoraFin:data.horaFin,
      matriculap:this.maestro.matricula,
      grhHoraInicio:data.horaInicio,
      practica:pract
    }
    console.log(jason);
    
    this.catalogosService.agregarhorario(jason).subscribe({
      next:(data:any) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.consultarhorariosdegrupo(this.grupoindividual.get('idgrupo').value);
      }
    });
  }

/**
 * Funcion para abrir grupo y realizar acciones
 * event = grupo seleccionado
 * @param event 
 */
  abrirgrupo(event){
    
    $('#grupo').modal('show');
    this.grupoindividual.patchValue(event.data);
    var maestro = <HTMLSelectElement>document.getElementById('maestro');
    for(var i = 0; i < this.listamaestros.length; i++){
      if(event.data.idmaestro == this.listamaestros[i].id){
        maestro.selectedIndex = i;
      }
    this.showOrden();
    }
    if(this.grupoindividual.get('firmado').value >= 1){
      this.grupoindividual.get('matriculamaestro').disable();
    }else{
      this.grupoindividual.get('matriculamaestro').enable();
    }
    this.consultarhorariosdegrupo(event.data.idgrupo);
    this.consularAlumnos(event.data.idgrupo);
  }
/**
 * Funcion para filtrar las materias plan estudios para facilitar la visualizacion en el HTML
 */
  filtrarmaterias(){
    this.listamateriaspefiltradas = [];
    var semestre = <HTMLSelectElement>document.getElementById('semestrecrear');
    var r = <HTMLSelectElement>document.getElementById('mregularescrear');
    var ir = <HTMLSelectElement>document.getElementById('mirregularescrear');
    this.maximo = parseInt(r.value);
    this.maximoi = parseInt(ir.value);
    console.log(semestre.value);
    for(var i = 0; i < this.listamateriaspe.length; i++){
      // console.log(semestre.value + " "+ this.listamateriaspe[i].semestre)
      if(semestre.value == this.listamateriaspe[i].semestre){
        this.listamateriaspefiltradas.push(this.listamateriaspe[i]);
      }
    }
    this.banderamostrartabla = false;
  }
/**
 * Funcion para tomar el plan de estudios y filtrar sus materias
 */
  consultarmaterias(){
    var plan = <HTMLSelectElement>document.getElementById('carreracrear');
    this.planestudioseleccionado = plan.value;
    this.consultarmateriasplanestudios(plan.value);
  }
/**
 * Funcion para tomar el plan de estudios y filtrar sus materias
 */
  consultarmaterias2(){
    var plan = <HTMLSelectElement>document.getElementById('carrerapormateria');
    this.planestudioseleccionado = plan.value;
    this.consultarmateriasplanestudios(plan.value);
  }
/**
 * Funcion para consultar materias plan estudios disponibles
 * @param id 
 */
  consultarmateriasplanestudios(id){
    this.listamateriaspefiltradas = [];
    this.catalogosService.materiaconsultarmateriasplanestudios(this.maestro.matricula, id).subscribe({
      next:(data:any) => {
        this.listamateriaspe = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
/**
 * Funcion para consultar plan de estudios disponibles
 */
  consultarcarreras(){
    this.catalogosService.listacarreras(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listacarrerasacrear = data;
      },
      error:(err) =>{
        console.log(err);
      },
      complete:() => {

      }
    });
  }
/**
 * Funcion para consultar aulas disponibles
 */
  consultaraulas(){
    this.catalogosService.listaaulas(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listaaulas = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
/**
 * Consultar horarios del grupo seleccionado
 * @param idgrupo 
 */
  consultarhorariosdegrupo(idgrupo){
    let horarios = [];
    this.listahorariodegrupo = [];
    this.catalogosService.horariodeGrupo(this.maestro.matricula, idgrupo).subscribe({
      next:(data:any) => {
        horarios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < horarios.length; i++){
          var jason = {
            dia:horarios[i].dia,
            hfin:horarios[i].hfin,
            hinicio:horarios[i].hinicio,
            aula:horarios[i].aula.nombre,
            aulaid:horarios[i].aula.id,
            id:horarios[i].id,
            practica:((horarios[i].practica==1)?"SI":"NO")
          }
          this.listahorariodegrupo.push(jason);
        }
        // console.table(this.listahorariodegrupo);
        this.itemSourceHorario = new LocalDataSource(this.listahorariodegrupo);
      }
    });
  }
/**
 * Funcion para consultar maestros disponibles
 */
  consultarmaestros(){
    this.catalogosService.listamaestro(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listamaestros = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.listamaestros.sort(function (a, b) {
          if (a.persona.apellidop > b.persona.apellidop) {
            return 1;
          }
          if (a.persona.apellidop < b.persona.apellidop) {
            return -1;
          }
          return 0;
        });
      }
    });
  }
/**
 * Funcion para consultar periodos escolares
 */
  consultarperiodos(){
    this.catalogosService.listaperiodos(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listaperiodos = data;
        this.listaperiodoscrear = data;
      },
      error:(err) => {
          console.log(err);
      },
      complete:() => {
        //this.consultargruposcompleta();
      }
    });
  }
  listaperiodosCompleta=[];
  listaPeriodosEscolaresCompleta(){
    this.catalogosService.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listaperiodosCompleta = data;
      },
      error:(err) => {
          console.log(err);
      },
      complete:() => {
        //this.consultargruposcompleta();
      }
    });
  }


/**
 * Funcion para consultar todos los grupos disponibles
 */
  consultargruposcompleta(){
    this.catalogosService.listaGrupos(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.listagrupos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
      }
    });
  }

  gruposEmpalme=[];
  /**
 * Funcion para consultar los Grupos con Empalme
 */
  consultargruposempalme(){
    this.catalogosService.gruposEmpalme(this.gruposPC.value).subscribe({
      next:(data:any) => {
        this.gruposEmpalme = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
      }
    });
  }

  
  /**
  *  Funcion para abrir el modal de Empalme de Grupos.
  */
  empalmegruposmodal(){
    $('#empalmes').modal('show');
    if(this.gruposPC.valid){
        this.consultargruposempalme();
    }
  }
  
  /**
   *  CONFIGURACIONES DE LOS GRUPOS CON EMPALME
   */
  empalmes = {
    columns:{
      grupo:{
        title:'Grupo',
        editable:false,
        width:'25%'
      },
      maestro:{
        title:'Maestro',
        editable:false,
        width:'25%'
      },
      empalme:{
        title:'Grupo Empalme',
        editable:false,
        width:'25%'
      },
      maestroEmpalme:{
        title:'Maestro Empalme',
        editable:false,
        width:'25%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[

      ],
      position: 'right'
    },
    pager:{
      perPage:12
    },
    noDataMessage:'Sin datos disponibles.'
  }

  gruposPC = new FormGroup({
    matriculap: new FormControl(''),
    periodoid: new FormControl('', Validators.required),
    carreraid: new FormControl('', Validators.required)
  })

  gruposPeriodoCarrera(){
    this.gruposPC.patchValue({matriculap:this.maestro.matricula})
    
    //console.log(this.gruposPC.value);
    this.catalogosService.gruposPeriodoCarrera(this.gruposPC.value).subscribe({
      next:(data:any) => {
        this.listagrupos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
      }
    });
  }


  /**
 * Funcion para activar o desacivar el campo del orden la foliación 
 */
  showOrden(){
    //console.log(true);
    if(this.grupoindividual.get('alias').value==''){
      this.grupoindividual.get('orden').disable();
      //console.log(true);
    }else{
      this.grupoindividual.get('orden').enable();
    }
  }

  exportEmpalmes():void {
    var expEmpalmes = [];
    for(var i = 0; i < this.gruposEmpalme.length; i++){
      var jason = [
        this.gruposEmpalme[i].periodo,
        this.gruposEmpalme[i].carrera,
        this.gruposEmpalme[i].grupo,
        this.gruposEmpalme[i].maestro,
        this.gruposEmpalme[i].empalme,
        this.gruposEmpalme[i].maestroEmpalme
      ]
      expEmpalmes.push(jason);
    }
      this.excelService.exportAsExcelFileGrupos(expEmpalmes);
  }


}
