<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h2>Listado de Inscripciones</h2>
    <div class="row" id="top">
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
                  <option>-- Períodos Abiertos --</option>
                  <option *ngFor="let periodo of periodosabiertos" value="{{ periodo.id }}">{{ periodo.nombre }}</option>
                </select>
              </div>
        </div>
    </div>
    <div class="row" id="top" *ngIf="banderaspinner">
        <div class="col-12" *ngIf="!banderatabla" >
            <ng2-smart-table class="table table-hover table-borderless" [settings]="settingslistado" [source]="programaciones"></ng2-smart-table>
        </div>    
    </div>
    <div class="row" id="top">
        <div class="col-12" *ngIf="!banderaspinner">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>    </div>
</body>
</html>