import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { ServicioSocialService } from '../servicio-social.service';
declare var $ : any;

@Component({
  selector: 'app-formato-liberacion',
  templateUrl: './formato-liberacion.component.html',
  styleUrls: ['./formato-liberacion.component.css']
})
export class FormatoLiberacionComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  };
  type = FileMimeType.PDF;
  src;
  banderaPDF: boolean = true;
  periodos=[];
  plandeestudios=[];
  formatoForm = new FormGroup({
    periodoid: new FormControl(''),
    planestudiosid:new FormControl('')
  });
  @Input('alumno') alumno:any;
  constructor(private ss: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    console.log(this.alumno);
    this.consultarperiodos();
    this.consultarplanes();
  }

  formatoLiberacion(){
    $('#f').modal('show');
    this.banderaPDF = false;
  }

  formatoLiberacionSalir(){
    $('#f').modal('hide');
  }

  consultarFormato(){
    this.banderaPDF = true;
    if(this.alumno.semestre == '12' || this.alumno.semestre == '13'){
      this.ss.formatoLiberacionInternado(this.maestro.matricula, this.alumno.id, this.formatoForm.get('periodoid').value, this.formatoForm.get('planestudiosid').value).subscribe({
        next:(res:any) => {
          this.src = res;
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          this.formatoLiberacion();
        }
      });
    }
    if(this.alumno.semestre == '14'){
      this.ss.formatoServicioSocial(this.maestro.matricula, this.alumno.id, this.formatoForm.get('periodoid').value, this.formatoForm.get('planestudiosid').value).subscribe({
        next:(res:any) => {
          this.src = res;
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          this.formatoLiberacion();
        }
      });
    }
  }

  consultarperiodos(){
    this.ss.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.periodos = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarplanes(){
    this.ss.consultarplanestudios().subscribe({
      next:(res: any) => {
        this.plandeestudios = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

}
