
<div>
    
</div>


<div *ngIf="banderapdf">
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div *ngIf="!banderapdf">
    <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
</div>