<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row" id="top">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}"
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-7">
                        <div class="card">
                            <div class="card-body">
                                <h3>Configuraciones Servicios Escolares</h3>
                                <hr>
                                <nav>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="!banderaPeriodosEscolares" id="btnGroupDrop1" type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Periodos Escolares
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            <a *ngIf="!babderaCaptura" class="dropdown-item agregar" routerLink="/configuracionesSE/agregarFechas" routerLinkActive="active" >Fechas Para Captura de Calificaciones</a>
                                            <a *ngIf="!banderaConsultaFechas" class="dropdown-item agregar" routerLink="/configuracionesSE/consultar" routerLinkActive="active" >Fechas Para Examenes</a>
                                            <a *ngIf="!banderaListadoPeriodos" class="dropdown-item agregar" routerLink="/configuracionesSE/agregar-periodo-escolar" routerLinkActive="active" >Listado</a>
                                            <a *ngIf="!banderaDiasFestivos" class="dropdown-item agregar" routerLink="/configuracionesSE/dias-festivos" routerLinkActive="active" >Días Festivos</a>
                                        </div>
                                    </div>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="!banderaCatalogos" id="btnGroupDrop1" type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Catálogos
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                          <a *ngIf="!banderaCatAulas" class="dropdown-item agregar" routerLink="/configuracionesSE/aulas" routerLinkActive="active" >Aulas</a>
                                          <a *ngIf="!banderaCatMaterias" class="dropdown-item agregar" routerLink="/configuracionesSE/materias" routerLinkActive="active" >Materias</a>
                                          <a *ngIf="!banderaCatGrupos" class="dropdown-item agregar" routerLink="/configuracionesSE/grupos" routerLinkActive="active" >Grupos</a>
                                          <a *ngIf="!banderaTipoDocumentos" class="dropdown-item agregar" routerLink="/configuracionesSE/documentos" routerLinkActive="active" >Tipos de Documentos</a>
                                          <a *ngIf="!banderaCatalogos"  class="dropdown-item agregar" routerLink="/configuracionesSE/departamentales" routerLinkActive="active" >Departamentales</a>

                                        </div>
                                    </div>
                                    
                                    <div class="btn-group">
                                        <button *ngIf="!banderaInscripciones" class="btn dropdown-toggle" role="button" id="dropdownMenuInscripciones" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Inscripciones</button>
                                        <div  class="dropdown-menu" aria-labelledby="dropdownMenuInscripciones">
                                            <a class="dropdown-item" routerLink="/configuracionesSE/inscripciones" routerLinkActive="active">Programar Inscripción</a>
                                            <!-- <a class="dropdown-item" routerLink="/configuracionesSE/listado" routerLinkActive="active">Listado Inscripciones</a> -->
                                            <a class="dropdown-item" routerLink="/configuracionesSE/primer-semestre" routerLinkActive="active">Primer Semestre</a>
                                             <a class="dropdown-item" routerLink="/configuracionesSE/cierre-p-inscripciones" routerLinkActive="active">Cerrar inscripción</a> 
                                            <!-- <div class="dropdown-divider"></div> -->
                                        </div>
                                    </div>
                                    <div class="btn-group">
                                        <button class="btn dropdown-toggle" role="button" id="dropdownMenuPlanEstudio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Planes de estudio</button>
                                        <div  class="dropdown-menu" aria-labelledby="dropdownMenuPlanEstudio">
                                            <a *ngIf="!banderaPlanEstudios" class="dropdown-item"  routerLinkActive="active" routerLink="/configuracionesSE/planestudios">PDF Planes de Estudio</a>
                                            <a class="dropdown-item" routerLink="/configuracionesSE/planestudios-relacion" routerLinkActive="active">Relaciones Plan de Estudio</a>
                                            <a class="dropdown-item" routerLink="/configuracionesSE/planestudios-admin" routerLinkActive="active">Administración Plan de Estudio</a>

                                        </div>
                                    </div>
                                    
                                    <a *ngIf="!banderaParametros" class="card-link agregar" routerLink="/configuracionesSE/configuracion-general">Parámetros</a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</body>
</html>