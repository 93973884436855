import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private http: HttpClient, private global: Global) { }

  consultardatos(matricula){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendario/datos/${matricula}`)
  }

  consultarcalendario(idperiodo, matriculap, idcarrera, idsemestre){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendario/${idperiodo}/${matriculap}/${idcarrera}/${idsemestre}`, { responseType: 'arraybuffer' })
  }

  consultarcalendariopdf(idperiodo, matriculap, idcarrera, idsemestre){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendario/${idperiodo}/${matriculap}/${idcarrera}/${idsemestre}`)
  }

}
