<body>

  <div *ngIf="banderaterminado">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
  </div>
  </div>

    <div class="row" *ngIf="!banderaterminado">
        <div class="col-4" *ngIf="banderadocumentossubidos">
            <div class="text-center">
                <h4>Documentos en Revisión</h4>
            </div>
            <div>
                <ng2-smart-table class="table table-hover table-borderless" (custom)="verdocumento($event)" [settings]="settings2" [source]="documentossubidos"></ng2-smart-table>
            </div>
        </div>
        <div class="col-4" *ngIf="banderadocumentosvalidados && !banderadocumentossubidos">
          <div class="text-center">
              <h4>Documentos Validados</h4>
          </div>
          <div>
              <ng2-smart-table class="table table-hover table-borderless" (custom)="verdocumento($event)" [settings]="settings3" [source]="documentosvalidados"></ng2-smart-table>
          </div>
        </div>
        <div class="col-4" *ngIf="banderadocumentospendientes">
            <div class="text-center">
                <h4>Documentos Pendientes</h4>
            </div>
            <div>
                <ng2-smart-table class="table table-hover table-borderless" (custom)="abrirmodaldocumento($event)" [settings]="settings" [source]="documentosposibles"></ng2-smart-table>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!banderaterminado">
      <div class="col-4" *ngIf="banderadocumentosvalidados && banderadocumentossubidos">
          <div class="text-center">
              <h4>Documentos Validados</h4>
          </div>
          <div>
              <ng2-smart-table class="table table-hover table-borderless" (custom)="verdocumento($event)" [settings]="settings3" [source]="documentosvalidados"></ng2-smart-table>
          </div>
      </div>
    </div>

    <div class="modal fade" id="formulario" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="formulario" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ documentoselecionadonombre }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir documento</label>
                        <input type="file" (change)="onFileSelect($event)" id="documento" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                    </div>
                    <div class="col-6" *ngIf="!banderatabla">
                        <table class="table table-sm table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th>Documento</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ uploadForm.get('file').value.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="subirdocumento()" [disabled]="uploadForm.invalid">Guardar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="documentos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="formulario" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
          <div class="modal-content" *ngIf="!banderadocumento">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ documentoselecionadonombre }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="descargardocumento()">Descargar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>


      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="respuesta" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>