<body>
    <!-- <div class="row">
        <div class="col-3"  *ngIf="!banderamatricula">
              <input type="text" [formControl]="this.buscarmatriculaform.get('matricula')" class="form-control" id="matricula" placeholder="Matrícula">
        </div>
        <div class="col-3" *ngIf="!banderamatricula">
          <button class="btn btn-danger"  [disabled]="buscarmatriculaform.invalid" (click)="consultaralumno()"><i id="icon" class="fas fa-search"  title="Buscar"></i> Buscar</button>
        </div>
    </div> -->

    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="pills-alumno-tab" data-toggle="pill" href="#alumno-home" role="tab" aria-controls="alumno-home" aria-selected="true">Avance</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="pills-detalle-tab" data-toggle="pill" href="#detalle-profile" role="tab" aria-controls="detalle-profile" aria-selected="false">Detalle</a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="alumno-home" role="tabpanel" aria-labelledby="alumno-home-tab">
        <div class="row pt-2">
          <div class="col-12" *ngIf="!banderatabla">
              <ng2-smart-table class="table table-hover table-borderless" (custom)="avancealumno($event)" (userRowSelect)="seleccionaralumnoavance($event)" [settings]="settings2" [source]="itemSource2"></ng2-smart-table>
          </div>
          <div class="col-12" *ngIf="banderatabla">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="detalle-profile" role="tabpanel" aria-labelledby="detalle-profile-tab">
        <div class="row pt-2">
          <div class="col-12" *ngIf="!banderatabla">
              <ng2-smart-table (userRowSelect)="seleccionaralumno($event)" class="table table-hover table-borderless" [settings]="settings" [source]="itemSource"></ng2-smart-table>
          </div>
          <div class="col-12" *ngIf="banderatabla">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="alumno" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ nombrealumno }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verdocumentos()">Documento</button>
                    </div>
                    <div class="col-6">
                      <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verestado()">Estado de solicitud</button>
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6">
                      <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="liberarssu()" *ngIf="horaspendientes == 0">Liberación</button>
                  </div>
              </div> -->
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="avance" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ nombrealumno }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-6">
                      <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verConstancia()">Liberar</button>
                  </div>
              </div>
              <!-- <div class="row">
                  <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="liberarssu()" *ngIf="horaspendientes == 0">Liberación</button>
                </div>
            </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
  </div>

    <div class="modal fade" id="documentos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ nombrealumno }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regresarmenu()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-center" *ngIf="banderacargando">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only text-danger">Loading...</span>
                </div>
              </div>
              <div *ngIf="!banderacargando">
                <div *ngIf="banderasindatos">
                  <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
                </div>
                <div class="text-center" *ngIf="!banderasindatos">
                  <label>Sin documentos disponibles.</label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="guardardocumento()" *ngIf="banderasindatos">Descargar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="regresarmenu()">Aceptar</button>
            </div>
          </div>
        </div>
    </div>

    <!-- <div class="modal fade" id="documento" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ nombrealumno }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"  (click)="regresardocumentos()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            </div>            
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal" (click)="regresardocumentos()">Válido</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal"  (click)="regresardocumentos()">Inválido</button>
            </div>
          </div>
        </div>
    </div> -->


    <div class="modal fade" id="constancia" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ nombrealumno }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regresarmenu()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h6>Fecha de liberación</h6>
              <div class="input-group-append">
                <input class="form-control" name="mydate" placeholder="Fecha de liberación"
                angular-mydatepicker #dp="angular-mydatepicker" 
                [(ngModel)]="fecha" [options]="myDpOptions" 
                (dateChanged)="fechas($event)"/>
              <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                  <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="liberarserviciosocial()">Aceptar</button>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="documento" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog  modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regresarmenu()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
          <div *ngIf="banderapdf">
              <div class="d-flex justify-content-center">
                  <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
              </div>
          </div>
          <div *ngIf="!banderapdf">
            <div *ngIf="banderasindatos">
              <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
            <div class="text-center" *ngIf="!banderasindatos">
              <label>Sin documentos disponibles.</label>
            </div>
          </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" *ngIf="banderasindatos" (click)="guardardocumento()">Imprimir PDF</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="regresarmenu()">Aceptar</button>
          </div>
        </div>
      </div>
  </div>

  <!-- <div class="modal fade" id="documentodeliberacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Documento</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regresarmenu()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="banderapdf">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div *ngIf="!banderapdf">
            <tld-file-viewer [src]="src2" [type]="type"></tld-file-viewer>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" >Imprimir PDF</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" >Aceptar</button>
        </div>
      </div>
    </div>
</div> -->

    <div class="modal fade" id="estado" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Estatus de la solicitud</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="regresarmenu()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estatus">Estatus actual del la solicitud</label>
              <select class="form-control" id="estatuslista">
                <option *ngFor="let estatus of estatus" [value]="estatus">{{ estatus }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="regresarmenu()">Salir</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cambiarestado()">Actualizar estatus</button>
          </div>
        </div>
      </div>
  </div>

    <div class="modal fade" id="sinalumnos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Sin alumnos con la matrícula ingresada.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
          </div>
        </div>
      </div>
  </div>

  <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modalt-itle" id="staticBackdropLabel">¡Atención!</h5>
          <button type="button" class="close" aria-label="Close" (click)="cerrarrespuesta()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ respuesta.mensaje }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarrespuesta()">Aceptar</button>
        </div>
      </div>
    </div>
</div>

</body>