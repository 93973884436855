import { Component, OnInit, Input } from '@angular/core';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';

@Component({
  selector: 'app-visualizar-documentos',
  templateUrl: './visualizar-documentos.component.html',
  styleUrls: ['./visualizar-documentos.component.css']
})
export class VisualizarDocumentosComponent implements OnInit {

  src = '';
  type = FileMimeType.PDF;
  @Input() id: string;

  constructor(private global: Global) { }

  ngOnInit(): void {
    this.src = `${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/documento/${this.id}`;
  }

}
