import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, FormArrayName } from '@angular/forms';
import { ja } from 'date-fns/locale';
var moment = require('moment');
declare var $ : any;

@Component({
  selector: 'app-dias-festivos-pe',
  templateUrl: './dias-festivos-pe.component.html',
  styleUrls: ['./dias-festivos-pe.component.css']
})
export class DiasFestivosPEComponent implements OnInit {

  settings = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'33%'
      },
      fecha:{
        title:'Fecha',
        editable:false,
        width:'33%'
      } ,
      fechaFeriado:{
        title:'Fecha Feriado',
        editable:false,
        width:'34%'
      }      
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'eliminarmodal', title: '<i class="fas fa-times"></i>'}
      ],
      position: 'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', 
      mo: 'Lun', 
      tu: 'Mar', 
      we: 'Mie', 
      th: 'Jue', 
      fr: 'Vie', 
      sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 
      2: 'Feb', 
      3: 'Mar', 
      4: 'Abr', 
      5: 'May', 
      6: 'Jun', 
      7: 'Jul', 
      8: 'Ago', 
      9: 'Sept', 
      10: 'Oct', 
      11: 'Nov', 
      12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  diasF = [];
  banderadiasF:boolean = true;
  periodosescolares = [];
  idperiodo = '';
  myDateInit: boolean = true;
  banderaformulario:boolean = true;
  banderabtn:boolean = true;
  fecha = '';
  fechaFeriado='';
  validacion = '';
  diasfestivos : FormGroup;
  respuesta = {
    mensaje:'',
    codigo:'',
    detalle:''
  }
  diaselecionado;
  periodoselecionado;

  constructor(private personalService: PersonalService, private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
    this.diasfestivos = this.fb.group({
      fecha:[''],
      nombre:[''],
      periodo:this.idperiodo
    });
  }

  consultarperiodosescolares(){
    this.personalService.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolares = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultardiasfestivos(){
    this.personalService.consultardiasfestivosPE(this.idperiodo).subscribe({
      next:(data : any) => {
        this.diasF = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.diasF.length == 0){
          this.banderadiasF = false;
        }
      }
    });
  }

  selecionarperiodo(){
    this.diasF = [];
    let periodo = <HTMLSelectElement>document.getElementById('periodo');
    this.idperiodo = periodo.value;
    this.consultardiasfestivos();
    this.banderaformulario = false;
    for(var i = 0; i < this.periodosescolares.length; i++){
      if(this.idperiodo == this.periodosescolares[i].id){
        this.periodoselecionado = this.periodosescolares[i]
      }
    }
  }


  agregardia(){
    var nombre = <HTMLSelectElement>document.getElementById('nombre');
    var fecha = <HTMLSelectElement>document.getElementById('fecha');
    var fechaFeriado = <HTMLSelectElement>document.getElementById('fechaFeriado');
    var jason = {
      periodo:this.idperiodo,
      nombre:nombre.value,
      fecha:this.fecha,
      fechaFeriado:this.fechaFeriado,
      matriculap:this.maestro.matricula
    }
    if((nombre.value == null) || (nombre.value == undefined) || (nombre.value == '')){
      this.validacion = 'Ingrese el nombre del dia festivo.';
      $('#validacion').modal('show');
    }else{
      if((this.fecha == null) || (this.fecha == undefined) || (this.fecha == '')){
        this.validacion = 'Ingrese la fecha del dia festivo.';
        $('#validacion').modal('show');
      }else{
        this.personalService.agregardiasfestivosPE(jason).subscribe({
          next:(data : any) => {
            this.respuesta = data;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            if(this.respuesta.codigo == "OK"){
              this.consultardiasfestivos();
              nombre.value = '';
              fecha.value = '';
            }else{
              if(this.respuesta.codigo == "ERROR"){
                $('#respuesta').modal('show');
              }
            }
          }
        });
      }
    }
  }

  eliminarmodal(event){
    this.diaselecionado = event.data;
    $('#eliminar').modal('show');
    this.banderabtn = false;
  }

  eliminardiafestivo(){
    let json ={
      matriculap:this.maestro.matricula
    } 
    this.personalService.eliminardiasfestivos(this.diaselecionado.id,json ).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        if(this.respuesta.codigo == "OK"){
          this.consultardiasfestivos();
        }
      }
    });
  }




  formatoFecha(event){
    this.fecha = event.singleDate.formatted;
  }

  formatoFechaFeriado(event){
    this.fechaFeriado = event.singleDate.formatted;
  }

}
