import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
declare var $: any;

@Component({
  selector: 'app-importar-calif',
  templateUrl: './importar-calif.component.html',
  styleUrls: ['./importar-calif.component.css']
})
export class ImportarCalifComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };
  periodosescolares = [];
  plandeestudios = [];
  semestres = [];

  constructor(private sesco: SescoService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
    this.consultarplanestudios();
  }

  abriraviso(){
    $('#aviso1').modal('show'); 
  }

  abriraviso2(){
    $('#aviso2').modal('show'); 
  }

  importarCalificaciones(){
    let periodo = <HTMLSelectElement>document.getElementById('periodoescolar');
    let plan = <HTMLSelectElement>document.getElementById('plan');
    let semestre = <HTMLSelectElement>document.getElementById('semestre');
    let json = {
      matriculap: this.maestro.matricula,
      periodoid: periodo. value,
      planestudioid: plan.value,
      semestre: semestre.value
    };
    console.log(json);
    // this.sesco.importCalificacionesPeriodoEspecifico(json).subscribe({
    //   next:(res: any) => {
    //     this.respuesta = res;
    //   },
    //   error:(err) => {
    //     console.log(err);
    //   },
    //   complete:() => {
    //     $('#respuesta').modal('hide');
    //   }
    //  });
  }

  consultarperiodosescolares(){
    this.sesco.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarplanestudios(){
    this.sesco.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.plandeestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarsemestres(){
    let planestudio = <HTMLSelectElement> document.getElementById('plan');
    this.sesco.consultarsemestres(this.maestro.matricula, planestudio.value).subscribe({
      next: (res: any) => {
        this.semestres = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

}
