import { DepartamentalesComponent } from './catalogos/departamentales/departamentales.component';
import { CierreParaIncripcionesComponent } from './inscripciones/cierre-para-incripciones/cierre-para-incripciones.component';
import { ExamenProfesionalComponent } from './personal/examen-profesional/examen-profesional.component';
import { SeguimientoEgresadosComponent } from './personal/seguimiento-egresados/seguimiento-egresados.component';
import { AdminPlanEstudioComponent } from './planestudios/admin-plan-estudio/admin-plan-estudio.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GruposComponent } from '../app/maestros/grupos/grupos.component';
import { GruposMateriasComponent } from '../app/personal/grupos-materias/grupos-materias.component';
import { EscolaresGuard } from './auth/guards/escolares.guard';
import { MaestrosGuard } from './auth/guards/maestros.guard';
import { MateriasComponent } from './personal/materias/materias.component';
import { AlumnosComponent } from './personal/alumnos/alumnos.component';
import { KardexComponent } from './personal/kardex/kardex.component';
import { ConstanciaComponent } from './personal/constancia/constancia.component';
import { HorarioAlumnoComponent } from './personal/horario-alumno/horario-alumno.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { AuthGuardGuard } from './auth/guards/auth-guard.guard';
import { AulasComponent } from './catalogos/aulas/aulas.component';
import { ValidacionComponent } from './cuota-interna/validacion/validacion.component';
import { BajaMateriasComponent } from './personal/baja-materias/baja-materias.component';
import { TramitesEscolaresComponent } from './tramites/tramites-escolares/tramites-escolares.component';
import { MateriasCatalogoComponent } from './catalogos/materias-catalogo/materias-catalogo.component';
import {GruposCatalogoComponent } from './catalogos/grupos/grupos.component';
import {ClasificacionesComponent } from './catalogos/clasificaciones/clasificaciones.component'
import { GeneracionComponent } from './tramites/generacion/generacion.component';
import { CatalogoDocumentosComponent } from './documentos/catalogo-documentos/catalogo-documentos.component';
import { RolesComponent } from './catalogos/roles/roles.component';
import { SubirDocumentosComponent } from './personal/subir-documentos/subir-documentos.component';
import { AdministracionCicloEscolarComponent } from './personal/administracion-ciclo-escolar/administracion-ciclo-escolar.component';
import { ListadoConfiguracionPEComponent } from './personal/listado-configuracion-pe/listado-configuracion-pe.component';
import { AgregarNuevoPEComponent } from './personal/agregar-nuevo-pe/agregar-nuevo-pe.component';
import { ConsultarPeridoEComponent } from './personal/consultar-perido-e/consultar-perido-e.component';
import { ConfiguracionComponent } from './personal/configuracion/configuracion.component';
import { DatosPersonalesComponent } from './personal/datos-personales/datos-personales.component';
import { CambiarPasswordComponent } from './personal/cambiar-password/cambiar-password.component';
import { DiasFestivosPEComponent } from './personal/dias-festivos-pe/dias-festivos-pe.component';
import { CalendarioComponent } from './configuraciones/calendario/calendario.component';
import { CalendarioExamenesComponent } from './configuraciones/calendario-examenes/calendario-examenes.component';
import { BecasComponent } from './personal/becas/becas.component';
import { AdministracionPlazasComponent} from './servicio-social/administracion-plazas/administracion-plazas.component';
import {PlazasComponent} from './servicio-social/plazas/plazas.component';
import {InstitucionesComponent} from './servicio-social/instituciones/instituciones.component';
import { InicioComponent } from './servicio-social-universitario/inicio/inicio.component';
import { ListadoComponent } from './servicio-social-universitario/listado/listado.component';
import { InicioExComponent } from './examen-profesional/inicio/inicio.component';
import { AgregarComponent } from './examen-profesional/agregar/agregar.component';
import { DocumentosPerfilComponent } from './personal/documentos-perfil/documentos-perfil.component';
import { InicioMaestrosComponent } from './maestros/inicio-maestros/inicio-maestros.component';
import { MaestroComponent } from './maestros/maestro/maestro.component';
import { InicioNotificacionesComponent } from './notificaciones/inicio-notificaciones/inicio-notificaciones.component';
import { InicioAdministracionComponent } from './administracion/inicio-administracion/inicio-administracion.component';
import { InicioMateriasComponent } from './personal/inicio-materias/inicio-materias.component';
import { InscripcionesComponent } from './administracion/inscripciones/inscripciones.component';
import { ListadoIComponent } from './administracion/listado/listado.component';
import { NotificacionesComponent } from './catalogos/notificaciones/notificaciones.component';
import { InicioServicioSocialComponent } from './servicio-social/inicio-servicio-social/inicio-servicio-social.component';
import { InicioPEComponent } from './planestudios/inicio/inicio.component';
import { MapapdfComponent } from './planestudios/mapapdf/mapapdf.component';
import { InicioCalendarioComponent } from './calendario/inicio/inicio.component';
import { PdfComponent } from './calendario/pdf/pdf.component';
import { InicioTutorialesComponent } from './tutoriales/inicio/inicio.component';
import { PdfBloquesComponent } from './administracion/pdf-bloques/pdf-bloques.component';
// import { AdminTutorialesComponent } from './tutoriales/agregar/agregar.component';
import { ListadoTutorialesComponent } from './tutoriales/listado/listado.component';
import { AdminTutorialesComponent } from './tutoriales/agregar/agregar.component';
import { AltaAlumnosNuevoIngresoComponent } from './personal/alta-alumnos-nuevo-ingreso/alta-alumnos-nuevo-ingreso.component';
import { AuditoriaInicioComponent } from './auditoria/auditoria-inicio/auditoria-inicio.component';
import { AuditoriaInscripcionesComponent } from './auditoria/inscripciones/inscripciones.component';
import { AuditoriaLoginComponent } from './auditoria/login/login.component';
import { GruposHorariosComponent } from './catalogos/grupos-horarios/grupos-horarios.component';
import { InscripcionesSescoComponent } from './administracion/inscripciones-sesco/inscripciones-sesco.component';
import { AlumnosInicioComponent } from './personal/alumnos-inicio/alumnos-inicio.component';
import { SescoInicioComponent } from './sesco/inicio/inicio.component';
import { SescoAlumnosComponent } from './sesco/alumnos/importacion-sesco-alumnos.component';
import { SescoCalificacionesComponent } from './sesco/calificaciones/calificaciones.component';
import {InicioConfGeneralComponent} from './conf-general/inicio-conf-general/inicio-conf-general.component';
import { JustificantesComponent } from './maestros/justificantes/justificantes.component';
import { EnviarSescoCalificacionesComponent } from './sesco/sesco-calificaciones/sesco-calificaciones.component';
import { AuditoriaGruposComponent} from './auditoria/grupos/grupos.component';
import { AuditoriaGrupoHorariosComponent } from './auditoria/grupo-horarios/grupo-horarios.component';
import { AuditoriaCalendarioExamenesComponent } from './auditoria/calendario-examenes/calendario-examenes.component';
import { AuditoriaCalendarioComponent } from './auditoria/calendario/calendario.component';
import { AuditoriaPreactaComponent } from './auditoria/preacta/preacta.component';
import { ConfiguracionesSEComponent } from './personal/configuraciones-se/configuraciones-se.component';
import { ConfiguracionesGComponent } from './personal/configuraciones-g/configuraciones-g.component';
import { CoutasComponent } from './personal/coutas/coutas.component';
import { InicioInformesComponent } from './informes/inicio/inicio.component';
import { TramitesComponent } from './tramites/tramites/tramites.component';
import { AuditoriaAlumnosComponent } from './auditoria/alumnos/alumnos.component';
import { AlumnoPleComponent } from './auditoria/alumno-ple/alumno-ple.component';
import { AvisosComponent } from './auditoria/avisos/avisos.component';
import { UsuariosComponent } from './auditoria/usuarios/usuarios.component';
import { UsuariosRolesComponent } from './auditoria/usuarios-roles/usuarios-roles.component';
import { AuditoriaRolesComponent } from './auditoria/auditoria-roles/auditoria-roles.component';
import { AuditoriaPermisosComponent } from './auditoria/auditoria-permisos/auditoria-permisos.component';
import { ListadoPlazasComponent } from './servicio-social/listado-plazas/listado-plazas.component';
import { AlumnosSolicitudComponent } from './servicio-social/alumnos-solicitud/alumnos-solicitud.component';
import { DocumentosComponent } from './servicio-social/documentos/documentos.component';
import { ReporteFinalComponent } from './servicio-social/reporte-final/reporte-final.component';
import { ConfiguracionesComponent } from './servicio-social/configuraciones/configuraciones.component';
import { PrimerSemestreComponent } from './administracion/primer-semestre/primer-semestre.component';
import { ReporteAsignacionComponent } from './servicio-social/reporte-asignacion/reporte-asignacion.component';
import { BuquedaAlumnosComponent } from './servicio-social/buqueda-alumnos/buqueda-alumnos.component';
import { AuditoriaLoginPersonalComponent } from './auditoria/auditoria-login-personal/auditoria-login-personal.component';
import { AuditoriaDatosComplementariosComponent } from './auditoria/auditoria-datos-complementarios/auditoria-datos-complementarios.component';
import { AuditoriaMateriasComponent } from './auditoria/auditoria-materias/auditoria-materias.component';
import { AuditoriaPagosComponent } from './auditoria/auditoria-pagos/auditoria-pagos.component';
import { AuditoriaVistaComponent } from './auditoria/auditoria-vista/auditoria-vista.component';
import { PeriodosComponent } from './sesco/periodos/periodos.component';
import { BitacoraComponent } from './auditoria/bitacora/bitacora.component';
import { MandarInscritosComponent } from './sesco/mandar-inscritos/mandar-inscritos.component';
import { ImportarCalifComponent } from './sesco/importar-calif/importar-calif.component';
import { AdminComponent } from './planestudios/admin/admin.component';
import { LiberacionesComponent } from './servicio-social-universitario/liberaciones/liberaciones.component';
import { DatosAlumnosComponent } from './sesco/datos-alumnos/datos-alumnos.component';
import { SescoDatosPersonalComponent } from './sesco/sesco-datos-personal/sesco-datos-personal.component';
import { PagosComponent } from './sesco/pagos/pagos.component';

const routes: Routes = [
  {
    path:'error',
    loadChildren:() => import('./errors/errors.module').then(m => m.ErrorsModule)
  },
  {
    path:'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path:'inicio',
    loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'informes',
    loadChildren: () => import('./informes/informes.module').then(m => m.InformesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'personal',
    loadChildren: () => import('./personal/personal.module').then(m => m.PersonalModule),
    canLoad: [AuthGuardGuard]
  },
  {
    path:'maestros',
    loadChildren: () => import('./maestros/maestros.module').then(m => m.MaestrosModule),
    canLoad: [AuthGuardGuard, MaestrosGuard]
  },
  {
    path:'inscripciones',
    loadChildren: () => import('./inscripciones/inscripciones.module').then(m => m.InscripcionesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'catalogos',
    loadChildren: () => import('./catalogos/catalogos.module').then(m => m.CatalogosModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'configuraciones',
    loadChildren: () => import('./configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'servicio-s',
    loadChildren: () => import('./servicio-social/servicio-social.module').then(m => m.ServicioSocialModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'cuotaInterna',
    loadChildren: () => import('./cuota-interna/cuota-interna.module').then(m => m.CuotaInternaModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'documentos',
    loadChildren: () => import('./documentos/documentos.module').then(m => m.DocumentosModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'tramites',
    loadChildren: () => import('./tramites/tramites.module').then(m => m.TramitesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'cierre',
    loadChildren: () => import('./cierre/cierre.module').then( m => m.CierreModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'servicio-social-uni',
    loadChildren: () => import('./servicio-social-universitario/servicio-social-universitario.module').then(m => m.ServicioSocialUniversitarioModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'examen-profesionales',
    loadChildren: () => import('./examen-profesional/examen-profesional.module').then(m => m.ExamenProfesionalModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'notifiacion',
    loadChildren:() => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'admin',
    loadChildren:() => import('./administracion/administracion.module').then(m => m.AdministracionModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'pe',
    loadChildren:() => import('./planestudios/planestudios.module').then(m => m.PlanestudiosModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'calendari',
    loadChildren:() => import('./calendario/calendario.module').then(m => m.CalendarioModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'tut',
    loadChildren:() => import('./tutoriales/tutoriales.module').then(m => m.TutorialesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'importacion-sesco',
    loadChildren:() => import('./sesco/sesco.module').then(m => m.SescoModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'conf-general',
    loadChildren:() => import('./conf-general/conf-general.module').then(m => m.ConfGeneralModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'grupos/:id',
    component: GruposComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'grupo/:id',
    component: GruposMateriasComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'calendario-fechas',
    component: CalendarioComponent
  },
  {
    path: 'calendario-examenes',
    component: CalendarioExamenesComponent
  },
  {
    path: 'plazas-administracion',
    component: AdministracionPlazasComponent
  },
  {
    path: 'plazas-asignacion',
    component: PlazasComponent
  },
  {
    path: 'instituciones',
    component: InstitucionesComponent
  },
  // {
  //   path: 'clasificacion-personal/:id',
  //   component: ClasificacionPersonalComponent
  // },
  {
    path:'alumnos',
    component:AlumnosInicioComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'alumnos-inicio',
        component:AlumnosComponent
      }
    ]
  },
  {
    path: 'validacion',
    component: ValidacionComponent
  },
  {
    path:'kardex/:matriculaAlumno',
    component:KardexComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'constancia/:matriculaAlumno',
    component:ConstanciaComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'horario/:matriculaAlumno',
    component:HorarioAlumnoComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'bajas-materias/:matriculaAlumno',
    component:BajaMateriasComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'tramites-escolares',
    component:TramitesEscolaresComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'generacion',
    component:GeneracionComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'subir-documentos',
    component:SubirDocumentosComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'admin-periodo',
    component:AdministracionCicloEscolarComponent,
    canActivate:[AuthGuard],
    children:[{
      path:'listado-configuracion',
      component:ListadoConfiguracionPEComponent
    },
    {
      path:'agregar-nuevo',
      component:AgregarNuevoPEComponent
    },
    {
      path:'consultar',
      component:ConsultarPeridoEComponent
    },
    {
      path:'dias-festivos',
      component:DiasFestivosPEComponent
    }]
  },
  {
    path:'perfil',
    component:ConfiguracionComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'datos-personales',
        component:DatosPersonalesComponent
      },
      {
        path:'cambiar-contraseña',
        component:CambiarPasswordComponent
      },
      {
        path:'documentos',
        component:DocumentosPerfilComponent
      }
    ]
  },
  {
    path:'becas',
    component:BecasComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'servicio-social-universitario',
    component:InicioComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'listado',
        component:ListadoComponent,
      },
      {
        path:'liberaciones',
        component:LiberacionesComponent
      }
    ]
  },
  {
    path:'servicio-social',
    component:InicioServicioSocialComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'plazas',
        component:AdministracionPlazasComponent,
        canActivate:[AuthGuard],
      },
      {
        path:'instituciones',
        component: InstitucionesComponent,
        canActivate:[AuthGuard],
      },
      {
        path:'listadoPlazas',
        component: ListadoPlazasComponent,
        canActivate:[AuthGuard],
      },
      {
        path:'alumnosSolicitud',
        component: AlumnosSolicitudComponent,
        canActivate:[AuthGuard],
      },
      {
        path: 'documentos',
        component: DocumentosComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'reporte',
        component: ReporteFinalComponent,
        canActivate: [AuthGuard ]
      },
      {
        path: 'configuraciones',
        component: ConfiguracionesComponent,
        canActivate: [AuthGuard ]
      },
      {
        path: 'reporte-asignacion',
        component: ReporteAsignacionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'alumnos/busqueda',
        component: BuquedaAlumnosComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path:'examen-profesional',
    component:InicioExComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'agregar',
        component:AgregarComponent
      },
      {
        path: 'seguimiento-egresados',
        component: SeguimientoEgresadosComponent,
      },{
        path:'list',
        component:ExamenProfesionalComponent
      }
    ]
  },
  {
    path:'docentes',
    component:InicioMaestrosComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'materias',
        component:MaestroComponent
      },
      {
        path:'justificantes',
        component:JustificantesComponent
      }
    ]
  },
  {
    path:'notificaciones',
    component:InicioNotificacionesComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'administracion',
    component:InicioAdministracionComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path: 'roles',
        component: RolesComponent
      },
      {
        path: 'aulas',
        component: AulasComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'materias',
        component: MateriasCatalogoComponent
      },
      {
        path: 'grupos',
        component: GruposHorariosComponent
      },
      {
        path: 'documentos',
        component: CatalogoDocumentosComponent
      },
      {
        path: 'clasificaciones',
        component: ClasificacionesComponent
      },
      {
        path: 'inscripciones',
        component: InscripcionesComponent
      },
      {
        path: 'listado',
        component: ListadoIComponent
      },
      {
        path: 'notificaciones',
        component:NotificacionesComponent
      },
      {
        path:'inscripciones-sesco',
        component:InscripcionesSescoComponent
      },
      {
        path:'primer-semestre',
        component:PrimerSemestreComponent
      }
    ]
  },
  {
    path:'materias',
    component:InicioMateriasComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'materias',
        component:MateriasComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'calendario-examenes',
        component: CalendarioExamenesComponent
      },
    ]
  },
  {
    path:'plandeestudios',
    component:InicioPEComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'mapa',
        component:MapapdfComponent
      }
    ]
  },
  {
    path:'calendario',
    component:InicioCalendarioComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'pdf',
        component:PdfComponent
      }
    ]
  },
  {
    path: 'tutorial',
    component:InicioTutorialesComponent, 
    canActivate:[AuthGuard],
    children:[
    {
      path: 'admin',
      component: AdminTutorialesComponent
    },
    {
      path:'list',
      component:ListadoTutorialesComponent
    }
    ]
  },
  {
    path:'bloques',
    component:PdfBloquesComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'altas-alumnos-nuevo-ingreso',
    component:AltaAlumnosNuevoIngresoComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'auditoria',
    component:AuditoriaInicioComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'inscripciones',
        component:AuditoriaInscripcionesComponent
      },
      {
        path:'acceso',
        component:AuditoriaLoginComponent
      },
      {
        path:'acceso-personal',
        component:AuditoriaLoginPersonalComponent
      },
      {
        path:'grupos',
        component:AuditoriaGruposComponent
      },
      {
        path:'grupos-horario',
        component:AuditoriaGrupoHorariosComponent
      },
      {
        path:'calendario-examenes',
        component:AuditoriaCalendarioExamenesComponent
      },
      {
        path:'calendario',
        component:AuditoriaCalendarioComponent
      },
      {
        path:'preactas',
        component:AuditoriaPreactaComponent
      },
      {
        path:'alumnos',
        component:AuditoriaAlumnosComponent
      },
      {
        path:'alumnos-plan-estudio',
        component:AlumnoPleComponent
      },
      {
        path:'alumnos-datos-c',
        component:AuditoriaDatosComplementariosComponent
      },
      {
        path:'materias',
        component:AuditoriaMateriasComponent
      },
      {
        path:'pagos',
        component:AuditoriaPagosComponent
      },
      {
        path:'semestres',
        component:AuditoriaVistaComponent
      },
      {
        path:'bitacora',
        component:BitacoraComponent
      },
      {
        path:'notificaciones',
        component:AvisosComponent
      },
      {
        path:'usuarios',
        component:UsuariosComponent
      },
      {
        path:'usuarios-roles',
        component:UsuariosRolesComponent
      },
      {
        path:'roles',
        component:AuditoriaRolesComponent
      },
      {
        path:'permisos',
        component:AuditoriaPermisosComponent
      }
    ]
  },
  {
    path:'sesco',
    component:SescoInicioComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path: 'pagos',
        component: PagosComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'alumnos',
        component:SescoAlumnosComponent,
        canActivate:[AuthGuard],
      },
      {
        path:'calificaciones',
        component:SescoCalificacionesComponent,
        canActivate:[AuthGuard],
      },
      {
        path:'enviarCalificaciones',
        component:EnviarSescoCalificacionesComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'periodos',
        component:PeriodosComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'mandar-inscritos',
        component: MandarInscritosComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'importar-Cal',
        component: ImportarCalifComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'datos-alumnos',
        component: DatosAlumnosComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'datos-personal',
        component: SescoDatosPersonalComponent,
        canActivate:[AuthGuard]
      }
    ]
  }, 
  {
    path: 'configuracion-general',
    component:InicioConfGeneralComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'configuracionesSE',
    component: ConfiguracionesSEComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path:'agregarFechas',
        component: ListadoConfiguracionPEComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'agregar-periodo-escolar',
        component: AgregarNuevoPEComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'planestudios',
        component: MapapdfComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'planestudios-relacion',
        component: AdminComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'planestudios-admin',
        component: AdminPlanEstudioComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'consultar',
        component:ConsultarPeridoEComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'dias-festivos',
        component:DiasFestivosPEComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'aulas',
        component: AulasComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'materias',
        component: MateriasCatalogoComponent
      },
      {
        path: 'grupos',
        component: GruposHorariosComponent
      },
      {
        path: 'documentos',
        component: CatalogoDocumentosComponent
      },
      {
        path: 'configuracion-general',
        component:InicioConfGeneralComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'inscripciones',
        component: InscripcionesComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'listado',
        component: ListadoIComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'primer-semestre',
        component:PrimerSemestreComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'cierre-p-inscripciones',
        component:CierreParaIncripcionesComponent,
        canActivate:[AuthGuard]
      },
      {
        path:'departamentales',
        component:DepartamentalesComponent,
        canActivate:[AuthGuard]
      }
    ]
  },
  {
    path: 'configuracionesG',
    component: ConfiguracionesGComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'notificaciones',
        component: NotificacionesComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'coutas',
      component: CoutasComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'interna',
          component: ValidacionComponent,
          canActivate:[AuthGuard]
        },
        {
          path: 'tramites',
          component: TramitesComponent,
          canActivate: [AuthGuard]
        }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
