import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditoria-inicio',
  templateUrl: './auditoria-inicio.component.html',
  styleUrls: ['./auditoria-inicio.component.css']
})
export class AuditoriaInicioComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  access = [];
  banderaAInscripciones : boolean = true;
  banderaAAcceso : boolean = true;
  banderaADatos : boolean = true;
  banderaAlumnos : boolean = true;
  banderaPreactas  : boolean = true;
  banderaGrupos : boolean = true;
  banderaGruposHorarios : boolean = true;
  banderaCalendariosExamenes : boolean = true;
  banderaCalendario : boolean = true;
  banderaNotificaciones : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtn();
  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10136 || this.access[i] == 3){
        this.banderaAAcceso = false;
      }
      if(this.access[i] == 10137 || this.access[i] == 3){
        this.banderaADatos = false;
      }
      if(this.access[i] == 10138 || this.access[i] == 3){
        this.banderaAlumnos = false;
      }
      if(this.access[i] == 10139 || this.access[i] == 3){
        this.banderaPreactas = false;
      }
      if(this.access[i] == 10140 || this.access[i] == 3){
        this.banderaGrupos = false;
      }
      if(this.access[i] == 10141 || this.access[i] == 3){
        this.banderaGruposHorarios = false;
      }
      if(this.access[i] == 10142 || this.access[i] == 3){
        this.banderaCalendariosExamenes = false;
      }
      if(this.access[i] == 10143 || this.access[i] == 3){
        this.banderaCalendario = false;
      }
      if(this.access[i] == 10144 || this.access[i] == 3){
        this.banderaNotificaciones = false; 
      }
      if(this.access[i] == 10158 || this.access[i] == 3){
        this.banderaAInscripciones = false;
      }
    }
  }

}
