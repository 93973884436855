import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarIzqComponent } from './navbar-izq/navbar-izq.component';
import { RouterModule } from '@angular/router';
import { BienvenidoComponent } from './bienvenido/bienvenido.component';



@NgModule({
  declarations: [InputComponent, NavbarIzqComponent, BienvenidoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports:[InputComponent, NavbarIzqComponent, BienvenidoComponent]
})
export class SharedModule { }
