<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid">
        <form [formGroup]="busqueda">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="planestudios">Plan de Estudios</label>
                        <select class="form-control" [formControl]="busqueda.get('planestudioid')" id="planestudios">
                          <option *ngFor="let plan of planestudios" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{ plan.plandeestudio }})</option>
                        </select>
                      </div>
                </div>
                <div class="col-4">
                    <label>Matrícula</label>
                    <div class="form-inline">
                        <div class="form-group mr-3 mb-2">
                          <input type="text" [formControl]="busqueda.get('matricula')" class="form-control" id="matricula">
                        </div>
                        <button class="btn btn-danger mb-2" (click)="validar()">Importar</button>
                      </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-12" *ngIf="!bandera">
                <ng2-smart-table class="table table-hover table-borderless" [settings]="settings" [source]="calificacionesfiltrados"></ng2-smart-table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>