import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../services/personal.service';
import jspdf from 'jspdf';
declare var $ : any;
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';

import html2canvas from 'html2canvas';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';

@Component({
  selector: 'app-kardex',
  templateUrl: './kardex.component.html',
  styleUrls: ['./kardex.component.css']
})
export class KardexComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  // datosKardex:{
  //   alumno: string,
  //   calle: string,
  //   colonia: string,
  //   credapro: string,
  //   estado: string,
  //   fechanac: string,
  //   lugarnacimiento: string,
  //   matricula: string,
  //   municipio: string,
  //   procede: string,
  //   prommataprob: string,
  // }
  // historialkardex:{
  //   acta: string,
  //   calificacion: string,
  //   credito: string,
  //   cve: string,
  //   fecha: string,
  //   grupo: string,
  //   materia: string,
  //   semestre: string,
  //   tipoexamen: string,
  // }
  datoskardex = {
    carrera:'',
    alumno:'',
    calle:'',
    colonia:'',
    procede:'',
    matricula:'',
    prommataprob:'',
    credapro:'',
    estado:'',
    lugarnacimiento:'',
    fechanac:''
  };
  historialkardex;
  bandera = true;
  src;
  type = FileMimeType.PDF;
  bandera2:boolean = true;
  matriculaAlumno;

  @Input() alumno: string;
  @Input() planestudioid;
  @Input() firma;

  constructor(private route: ActivatedRoute, private personalService: PersonalService) { }

  ngOnInit(): void {
    // const matriculaAlumno = this.route.snapshot.paramMap.get('matriculaAlumno');
    this.matriculaAlumno = localStorage.getItem('alumno');
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.imprimirkardex();
      // var jason = {
      //   dato: matriculaAlumno,
      //   matriculap: this.maestro.matricula
      // }
      // this.personalService.consultarDatosKardex(jason).subscribe({
      //   next:(resp : any) => {
      //     this.datoskardex = resp;
      //   },
      //   error:(err) => {
      //     console.log(err);
      //   }
      // });
      // this.personalService.consultarHistorialKardex(jason).subscribe({
      //   next:(res) => {
      //     this.historialkardex = res;
      //     this.bandera = false;
      //   },
      //   error:(err) => {
      //     console.log(err);
      //   },
      //   complete:() => {
      //     if(this.historialkardex.length == 0){
      //       this.bandera2 = false;
      //     }
      //   }
      // });
  }

  imprimirkardex(){
    console.log(this.planestudioid);
    this.src = '';
    this.personalService.imprimirKardexCorto(this.alumno, this.maestro.matricula, this.firma, this.planestudioid).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.bandera2 = false;
        // var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
        // saveAs(blob, `Documento`);
      }
    })
  }

  //   public downloadPDF():void {
  //     var data = <HTMLSelectElement>document.getElementById('contentToConvert');
  //     html2canvas(data).then(canvas => {
  //       var imgWidth = 208;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       const contentDataURL = canvas.toDataURL('image/png');
  //       let pdf = new jspdf('p', 'mm', 'a4');
  //       var position = 0;
  //       pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //       pdf.save('newPDF.pdf');
  //     })
  // }

  //   getPDF(){

	// 	var HTML_Width = $("#contentToConvert").width();
	// 	var HTML_Height = $("#contentToConvert").height();
	// 	var top_left_margin = 15;
	// 	var PDF_Width = HTML_Width+(top_left_margin*2);
	// 	var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
	// 	var canvas_image_width = HTML_Width;
	// 	var canvas_image_height = HTML_Height;

	// 	var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;


	// 	html2canvas($("#contentToConvert")[0],{allowTaint:true}).then(function(canvas) {
	// 		canvas.getContext('2d');

	// 		var imgData = canvas.toDataURL("image/jpeg", 1.0);
	// 		var pdf = new jspdf('p', 'mm',  [PDF_Width, PDF_Height]);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);

  //     // var imgData = canvas.toDataURL("image/jpeg", 1.0);
	// 		// var pdf = new jspdf('p', 'mm',  'a4');
	// 	  //   pdf.addImage(imgData, 'JPG', 0, 0, 208, imgHeight);


	// 		for (var i = 1; i <= totalPDFPages; i++) {
  //       pdf.addPage(PDF_Width, 'p');
  //       //pdf.addPage((PDF_Width).toString(), 'p');
  //        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
	// 			// pdf.addImage(imgData, 'JPG', 0, 0, 208, imgHeight);
	// 		}

	// 	    pdf.save("HTML-Document.pdf");
  //       });
  // };

  public captureScreen() {
    // var data = document.getElementById('contentToConvert');
    // html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
    //   const image = { type: 'jpeg', quality: 0.98 };
    //   const margin = [0.5, 0.5];
    //   const filename = 'myfile.pdf';

    //   var imgWidth = 8.5;
    //   var pageHeight = 10;

    //   var innerPageWidth = imgWidth - margin[0] * 2;
    //   var innerPageHeight = pageHeight - margin[1] * 2;

    //   var pxFullHeight = canvas.height;
    //   var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    //   var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    //   var pageHeight = innerPageHeight;

    //   var pageCanvas = document.createElement('canvas');
    //   var pageCtx = pageCanvas.getContext('2d');
    //   pageCanvas.width = canvas.width;
    //   pageCanvas.height = pxPageHeight;

    //   var pdf = new jspdf('p', 'in', [8.5, 10.5]);

    //   for (var page = 0; page < nPages; page++) {
    //     if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
    //       pageCanvas.height = pxFullHeight % pxPageHeight;
    //       pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
    //     }

    //     var w = pageCanvas.width;
    //     var h = pageCanvas.height;
    //     pageCtx.fillStyle = 'white';
    //     pageCtx.fillRect(0, 0, w, h);
    //     pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

    //     if (page > 0) pdf.addPage();
    //     var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
    //     pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
    //   }
    //   pdf.save();
    // });
  }
}
