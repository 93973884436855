<div>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-Agregar" role="tabpanel" aria-labelledby="nav-Agregar-tab">
        <div class="row">
            <div class="mt-3 col-12">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <input type="text" class="form-control" [formControl]="TutorialesForm.get('titulo')" id="titulo" placeholder="Título" aria-describedby="emailHelp"> 
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" [formControl]="TutorialesForm.get('descripcion')" placeholder="Descripción" id="descripcion" rows="6"></textarea>
                        </div>
                        
                        <div class="form-group">
                            <input type="text" class="form-control" [formControl]="TutorialesForm.get('url')" placeholder="URL" id="url" aria-describedby=""> 
                            <!-- <textarea class="form-control" [formControl]="TutorialesForm.get('url')" placeholder="URL" id="url" rows="6"></textarea> -->
                        </div>
                        <div>
                            <h6>Roles asignados a tutorial</h6>
                        </div>
                        <ng2-smart-table (custom)="eliminarrol($event)" class="table table-hover table-borderless" [settings]="settings2" [source]="itemSource"></ng2-smart-table>
                    </div>
                    <div class="col-lg-6" *ngIf="!banderroles">
                        <div>
                            <h6>Roles disponibles</h6>
                        </div>
                        <ng2-smart-table (custom)="agregarrol($event)" class="table table-hover table-borderless" [settings]="settings" [source]="itemSource2"></ng2-smart-table>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-6">
                    
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <button (click)="enviar()" class="float-right btn btn-danger" [disabled]="(TutorialesForm.invalid)|| (this.rolesselecionados.length == 0)">Enviar Tutorial</button>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
        <div class="tab-pane fade" id="nav-Listado" role="tabpanel" aria-labelledby="nav-Listado-tab">
            <div class="row">
                <div class="col-12">
                <ng2-smart-table class="table table-hover table-borderless" (custom)="aviso($event)" [settings]="settings3" [source]="itemSource3"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>

    <!-- modal despuesta -->
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
            </div>
        </div>
    </div>
</div>
