import { Component, OnInit } from '@angular/core';
import { CalendarioService } from '../service/calendario.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
declare var $ : any;

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {

  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  settingslistado = {
    columns:{
      periodonombre:{
        title:'Período',
        editable:false,
        filter:false,
        width:'50%'
      },
      semestre2:{
        title:'Semestre',
        editable:false,
        filter:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'verpdf', title:'<a class="btn btn-danger btn-sm text-white boton" title="pdf"><i class="fas fa-calendar-alt"></i></a>' }
      ],
      position:'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin calendarios disponibles.'
  }
  datafinal=[];
  src;
  type = FileMimeType.PDF;
  datoscalendario;
  banderapdf:boolean = true;
  banderatabla:boolean = true;
  datos = [];
  datos2 = [];
  datosfiltrados = [];
  constructor(private calendarioService: CalendarioService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultardatos();
  }

  addItem(item){
    var index = this.datos.findIndex(x => {
      (x.carrera === item.carrera) && (x.matricula === item.matricula) && (x.periodo === item.periodo) && (x.semestre === item.semestre)
    });
    if(index === -1){
      this.datosfiltrados.push(item);      
    }else{

    }
  }

  consultardatos(){
    this.calendarioService.consultardatos(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.datos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.datosfiltrados = [];
        this.datos.sort(function (a, b) {
          if (a.semestre > b.semestre) {
            return 1;
          }
          if (a.semestre < b.semestre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        for(var i = 0; i < this.datos.length; i++){
          var jason = {
            semestre2:'Semestre '+this.datos[i].semestre,
            carrera:this.datos[i].carrera,
            periodo:this.datos[i].periodo,
            matricula:this.datos[i].matricula,
            semestre:this.datos[i].semestre,
            periodonombre:this.datos[i].periodonombre
          }
          this.datafinal.push(jason);
        }
        this.banderatabla = false;
      }
    });
  }

  verpdf(event){
    this.banderapdf = true;
    this.datoscalendario = event.data;
    $('#cargando').modal('show');
    this.calendarioService.consultarcalendario(event.data.periodo, event.data.matricula, event.data.carrera, event.data.semestre).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        if(err.status == 500){
          this.httpstatus = err.status;
          this.httpstatustext = 'No existen datos para el período seleccionado.';
          this.httpurl = err.url;
          setTimeout(() => {
            $('#cargando').modal('hide');
            this.banderahttp = false;
          }, 700);
        }else{
          this.httpstatus = err.status;
          this.httpstatustext = err.statusText;
          this.httpurl = err.url;
          setTimeout(() => {
            $('#cargando').modal('hide');
            this.banderahttp = false;
          }, 500);
        }
      },
      complete:() => {
        $('#cargando').modal('hide');
        setTimeout(() => {
          $('#documento').modal('show');
          this.banderapdf = false;
        }, 500)
      }
    });
  }

  imprimirpdf(){
    this.calendarioService.consultarcalendariopdf(this.datoscalendario.periodo, this.datoscalendario.matricula, this.datoscalendario.carrera, this.datoscalendario.semestre);
  }

}
