import { FormGroup, FormControl } from '@angular/forms';
import { PersonalService } from './../services/personal.service';
import { Component, OnInit } from '@angular/core';
declare var $ : any;
@Component({
  selector: 'app-seguimiento-egresados',
  templateUrl: './seguimiento-egresados.component.html',
  styleUrls: ['./seguimiento-egresados.component.css']
})
export class SeguimientoEgresadosComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  };

  settings = {
    columns:{
      matricula:{
        title:'Matricula',
        editable:false,         
        width:'15%'
      },
      nombrecompleto:{
        title:'Alumno',
        editable:false,         
        width:'40%'
      },
      semestre:{
        title:'Semestre',
        editable:false,         
        width:'15%'
      },
      carrera:{
        title:'Carrera',
        editable:false,         
        width:'30%'
      },
      
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
        //{ name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  busquedaForm = new FormGroup({
    dato: new FormControl(''),
    semestre: new FormControl(0)
  });

  banderaTabla: boolean = false;
  banderaSpiner: boolean = false ;

  src : any;

  constructor(private personal: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }
    
  busquedaEgresados(){
    this.banderaTabla = false
    this.banderaSpiner = true;
    let jason = {
      matriculap: this.maestro.matricula,
      dato: this.busquedaForm.get('dato').value,
    }
    this.personal.listarCandidatos(jason).subscribe({
      next:(data : any) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaSpiner = false;
        this.banderaTabla = true;
      }
    });
  }
  evento(e){

  }

}
