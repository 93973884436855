<body>
    <div class="row">
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" id="periodoescolares" (change)="periodoselecionadof()">
                    <option value="">--Período Escolar--</option>
                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="col-3" *ngIf="!banderacarrera">
            <select class="form-control" id="carrera" (change)="carreraselecionadaf()">
                <option value="">--Carrera--</option>
                <option *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{ carrera.carrera }}</option>
            </select>
        </div>
    </div>
</body>