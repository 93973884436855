import { Component, OnInit } from '@angular/core';
import { ConfiguracionesService } from '../configuraciones.service';

@Component({
  selector: 'app-Calendario',
  templateUrl: './Calendario.component.html',
  styleUrls: ['./Calendario.component.css']
})
export class CalendarioComponent implements OnInit {
  

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  constructor(private calendario: ConfiguracionesService) { }
  listaFechas:any;

  settingsCalendario = {
    columns:{
      carreraNombre:{
        title:'Carrera',
        editable:false/*,         
        width:'13%'*/
      },
      semestre:{
        title:'Semestre',
        editable:false
      },
      ordinarios_fin:{
        title:'Fin Ordinario',
        editable:false
      },
      extraordinarios_inicio:{
        title:'Inicio Ext.',
        editable:false
      },
      extraordinarios_fin:{
        title:'Fin Extra',
        editable:false
      },
      especial_inicio:{
        title:'Inicio Especial',
        editable:false
      },
      especial_fin:{
        title:'Fin Especial',
        editable:true
      }
    },
    actions:false/*{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'Acciones',
      position: 'right'
    }*/,
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.calendario.listaFechas(this.maestro.matricula,1,0).subscribe({
      next: (res) =>{
        this.listaFechas = res;
      },
      error: ()=>{
        throw new Error('Error al cargar fechas');
      }
    });
  }

}
