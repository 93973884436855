import { Component, OnInit } from '@angular/core';
import { MaestroService } from '../services/maestro.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
declare var $:any;
@Component({
  selector: 'app-justificantes',
  templateUrl: './justificantes.component.html',
  styleUrls: ['./justificantes.component.css']
})
export class JustificantesComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  archivo = [];
  justificantes = [];

  bandera = true;
  src;
  type = FileMimeType.PDF;
  bandera2:boolean = true;
  id = 0;
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  constructor(private Service: MaestroService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarJusticiantes();
  }

  consultarJusticiantes(){
    var jason = {
      "matriculap":this.maestro.matricula
      
    }
    this.Service.consultarjustificantesmaestros(jason).subscribe({
      next:(resp:any) => {
        this.justificantes = resp;
        
      },
      error:() => {
        throw new Error("Error al recuperar datos");
      },
      complete: () => {
        console.log(this.justificantes);

      }
    })
  }
  showDocument(id){
    this.bandera2 = true;
    var jason = {
      matriculap:this.maestro.matricula,
      id: id
    }
    this.Service.consultarachivojustificante(jason).subscribe({
      next:(resp:any) => {
        this.archivo = resp;
        
      },
      error:() => {
        throw new Error("Error al recuperar datos");
      },
      complete: () => {
        this.archivo.forEach(e => {
          this.src =  "data:application/pdf;base64,"+e.archivo;
        });
        this.id = id;
        this.bandera2 = false;
      }
    })

    $('#Documento').modal('show');

  }

  acceptar(){
    $('#Documento').modal('hide');
    let respuestas = [];
    var jason = {
      id:this.id,
      status:"ACEPTADO"
    }
    console.log(jason);

    this.Service.cambiarStatus(jason).subscribe({
      next:(data : any) => {
        respuestas.push(data);
        
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
          this.id = 0;
          this.respuesta = respuestas[0];
          $('#respuesta').modal('show');
          this.bandera = false;
          this.consultarJusticiantes();
        
      }
    });
  }
  denegar(){
    $('#Documento').modal('hide');
    let respuestas = [];
    var jason = {
      id:this.id,
      status:'DENEGADO'
    }
    console.log(jason);

    this.Service.cambiarStatus(jason).subscribe({
      next:(data : any) => {
        respuestas.push(data);
        
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
          this.id = 0;
          this.respuesta = respuestas[0];
          $('#respuesta').modal('show');
          this.bandera = false; 
          this.consultarJusticiantes();
      }
    });
  }

}
