import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

interface Asistencia{
  alumno: {
    matricula: string
  },
  grupo: {
    id: number
  },
  asistencia: number
}

interface AsistenciaEditar{
  grupo:{
    id: number
  },
  fecha: string
}

@Injectable({
  providedIn: 'root'
})
export class MaestroService {

  constructor(private http: HttpClient, private global: Global) { }

  maestrosGrpAl(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/grupoMaestro/${matricula}`, null)
  }

  alumGrps(idGrupo: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/alxgrupo/${idGrupo}`, null)
  }

  alumGr(idGrupo: string, matriculap){
   window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaMaestro/${idGrupo}/${matriculap}`);
  }

  asistencia(asistencia: Asistencia){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Asistencia/edit`, asistencia);
  }

  editarAsistencia(asistencia: AsistenciaEditar){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Asistencia/consultar`, asistencia);
  }

  imprmirListas(id: number,matriculap: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Asistencia/getList/${id}/${matriculap}`, {responseType: 'blob'});
  }

  consultarnumerodependientes(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calificaciones/consultar/contar`, datos);
  }

  consultarjustificantesmaestros(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/justificantes/list/pendiente/maestro`, datos);
  }
  consultarachivojustificante(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/justificantes/list/archivos`, datos);
  }
  cambiarStatus(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/justificantes/cambio/status`, datos);
  }

  tipocalificacion(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/tipocalificacion`, datos);
  }

}
