import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-plazas',
  templateUrl: './plazas.component.html',
  styleUrls: ['./plazas.component.css']
})
export class PlazasComponent implements OnInit {
  

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  constructor(private consultas: ServicioSocialService) { }

  

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaPeriodos();
    this.listaInstituciones();
  }

  formPeriodo= new FormGroup({
    periodoid:new FormControl(0)}
  );

  periodosescolares:any;

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.periodosescolares = res;
      },
      error: ()=>{
        throw new Error('Error al cargar períodos');
      },
      complete:()=>{
        console.log(this.periodosescolares);
        // //this.settingsPeriodos.columns
        // //const array1 = this.periodos[0].k;
        // for (var key in this.periodos[0]) {
        //   //this.settingsPeriodos.columns;
        //   //console.log(key);
          
        //   //console.log(this.periodos[0][key]);
        // }
        // //console.log(this.settingsPeriodos.columns);
        // console.log("Antes: ");
        // console.log(this.settingsPeriodos);
        // this.settingsPeriodos =   Object.assign(JSON.parse('{"columns":{"periodo": {"title": "Período","editable":false},"estado": {"title": "Estado","editable":false},"inicio": {"title": "Inicio","editable":false},"fin": {"title": "Fin"}}}'));
        // //this.settingsPeriodos.columns= Object.assign(JSON.parse('{"periodo": {"title": "Período","editable":false},"estado": {"title": "Estado","editable":false},"inicio": {"title": "Inicio","editable":false},"fin": {"title": "Fin"}}'));
        // //console.log(JSON.parse('{"columns":{"periodo": {"title": "Período","editable":false},"estado": {"title": "Estado","editable":false},"inicio": {"title": "Inicio","editable":false},"fin": {"title": "Fin"}}}'));
        // console.log("Después: ");
        // console.log(this.settingsPeriodos);
        //this.settingsPeriodos.columns=JSON.parse('{"periodo": {"title": "Período","editable":false},"estado": {"title": "Estado","editable":false},"inicio": {"title": "Inicio","editable":false},"fin": {"title": "Fin"},"inicioordinario":{"title": "inicioordinario","editable":false},}');
      }
    });
  }

  formPlaza= new FormGroup({
    periodoid: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")])
    ,institucionid: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")])
    ,plazasasignadas: new FormControl(0,[Validators.required, Validators.pattern("^[0-9]*$")])
    ,plazasanosignadas: new FormControl(0,[Validators.required, Validators.pattern("^[0-9]*$")])
    ,plazas: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")])
    ,ubicacion: new FormControl('',[Validators.required])
  });

  sumarPlazas(){
    this.formPlaza.patchValue({
      periodoid: this.formPlaza.get('periodoid').value
      ,institucionid: this.formPlaza.get('institucionid').value
      ,plazasasignadas: this.formPlaza.get('plazasasignadas').value
      ,plazasanosignadas: this.formPlaza.get('plazasanosignadas').value
      ,plazas: this.formPlaza.get('plazasasignadas').value + this.formPlaza.get('plazasanosignadas').value
      ,ubicacion: this.formPlaza.get('ubicacion').value
    });
    //(<HTMLSelectElement>document.getElementById('plazas')).value=this.formPlaza.get('plazasasignadas').value+this.formPlaza.get('plazasnoasignadas').value
    console.log(this.formPlaza.get('plazas').value );

  }

  limpiarPlaza(){
    this.formPlaza.reset();
  }

  instituciones:any;
  listaInstituciones(){
    this.consultas.listaInstituciones(this.maestro.matricula,0).subscribe({
      next: (res) =>{
        this.instituciones = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Instituciones');
      },
      complete:()=>{
        console.log(this.instituciones);
      }
    });
  }

  plazas:any;
  listaPlazas(){
    //(<HTMLSelectElement>document.getElementById('plazaperiodoid')).value
    this.consultas.listadoPlazasAsignar( this.maestro.matricula, this.formPeriodo.get('periodoid').value, 0 ).subscribe({
      next: (res) =>{
        this.plazas = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Plazas');
      },
      complete:()=>{
        console.log(this.plazas);
      }
    });
  }

  

  accionPlazas(valores){
    switch (valores.action)
    {
      case "editar": 
      console.log(valores);
          break;
      case "borrar": 
          break;
      default: 
      
        break;
    }
  }

  settingsPlazas = {
    columns:{
     periodo:{
       title:'Período',
       editable:false/*,         
       width:'13%'*/
    },
    institucion:{
      title:'Institucion',
      editable:false/*,         
      width:'9%'*/
    },
    disponible:{
       title:'Disponible',
       editable:false/*,         
       width:'7%'*/
     },
     estado:{
       title:'Estado República',
       editable:false/*,         
       width:'9%'*/
     },
     alumno:{
       title:'alumno',
       editable:false/*,         
       width:'9%'*/
     }/*,
     inicioinscripreg:{
       title:'Insc. Reg.',
       editable:false,         
       width:'9%'
     },
     fininscripreg:{
       title:'Fin Insc. Reg.',
       editable:false,         
       width:'9%'
     }*/
   },
   actions:{
     add:false,
     edit:false,
     delete:false,
     columnTitle:'',
     type:'html',
     custom:[
       { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-pencil-alt " ></i></a>'},
       { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
     ],         
     width:'15%',
     position: 'right'
   },
   pager:{
     perPage:15
   },
   noDataMessage:'Sin datos disponibles.'
 }

}
