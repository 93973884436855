import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { data } from 'jquery';
import{ RolesService }from '../services/roles.service'
declare var $:any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false/*,
        width:'43%'*/
      },
      descripcion:{
        title:'Descripción',
        editable:false/*,
        width:'43%'*/
      }
    }, 
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      
      custom:[
        { name:'abrireditarrol', title:'' },
        { name:'abrireliminarrrol', title:'' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settingspermisosactivos = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'35%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      },
      menu:{
        title:'Menu',
        editable:false,
        width:'15%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'eliminarpermiso', title: 'Inactivar'}
      ],
      position: 'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settingspermisosinactivos = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'35%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      },
      menu:{
        title:'Menu',
        editable:false,
        width:'15%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'agregarpermiso', title: 'Activar'}
      ],
      position: 'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settingsvistasactivas = {
    columns:{
      carrera:{
        title:'Carrera',
        editable:false,
        width:'45%'
      },
      rol:{
        title:'Rol',
        editable:false,
        width:'45%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'10%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settingsvistasasignables = {
    columns:{
      carrera:{
        title:'Carrera',
        editable:false,
        width:'50%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'50%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  roles = [];
  permisosactivos = [];
  permisosinactivos = [];
  vistasactivas = [];
  vistasasignables = [];
  semestresxcarrera = [];
  semestresasignadosvista = [];
  semestresnoasignadosvista = [];
  semestresfiltrados = [];
  semestresselecionados = [];

  semestresparaeliminar = [];
  semestresparaagregar = [];

  rolselecionado;
  carreraselescionado;
  crearrolform;
  editarrolform;
  editarroldatos;
  idrolaeliminar;

  banderarolselecionado:boolean = true;
  banderacarreraselecionado:boolean = true;
  banderamodal:boolean = true;
  banderaeditar:boolean = true;
  banderaeliminar:boolean = true;

  access = [];
  banderaCrear : boolean = true;
  banderaEditar : boolean = true;
  banderaEliminar : boolean = true;

  constructor(private rolesService: RolesService){}

  ngOnInit(){
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtn();
    this.consultarroles();
    this.crearrolform = new FormGroup({
      descripcion: new FormControl('',[Validators.required]),
      nombre: new FormControl('',[Validators.required]),
      matriculap: new FormControl(this.maestro.matricula)
    });
    this.editarrolform = new FormGroup({
      descripcion: new FormControl('',[Validators.required]),
      nombre: new FormControl('',[Validators.required]),
      matriculap: new FormControl(this.maestro.matricula),
      id: new FormControl(''),
    });

  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){

        if(this.access[i] == 10090 || this.access[i] == 3){
          this.banderaCrear = false;
        }
        if(this.access[i] == 10091 || this.access[i] == 3){
          this.banderaEditar = false;
          this.settings.actions.custom[0].title = '<a class="btn btn-danger btn-sm text-white" title="Editar"><i class="fas fa-pencil-alt" > </i></a>';
        }
        if(this.access[i] == 10092 || this.access[i] == 3){
          this.banderaEliminar = false;
          this.settings.actions.custom[1].title = '<a class="btn btn-danger btn-sm text-white" title="Detalles" ><i class="fas fa-trash" ></i></a>';
        }
    }
  }

  abrirrol(event){
    this.rolselecionado = event.data;
    $('#rolselecionado').modal('show');
    this.consultarpermisosactivos();
    this.consultarpermisosinactivos();
    this.consultarvistasaginables();
    this.banderarolselecionado = false;
  }

  cerrarrol(){
    $('#rolselecionado').modal('hide');
  }

  cerrarrespuesta(){
    $('#respuesta').modal('hide');
  }

  abrircrearrol(){
    $('#crearrol').modal('show');
  }

  cerrarcrearrol(){
    $('#crearrol').modal('hide');
  }

  abrireditarrol(event){
    this.editarroldatos = event.data;
    if (this.editarroldatos.avisos == "S") {
      this.editarrolform.get('nombre').setValue(event.data.nombre);
      this.editarrolform.get('descripcion').setValue(event.data.descripcion);
      this.editarrolform.get('id').setValue(event.data.id);
      $('#editarrol').modal('show');
      this.banderaeditar = false;
    }else{
      this.respuesta = { 
        codigo:'',
        detalle:'',
        mensaje:'Este rol es de un usuario por lo que no se puede modificar'
      };
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1500);
    }
  }

  cerrareditarrol(){
    $('#editarrol').modal('hide');
  }

  abrireliminarrol(event){ 
    if (this.editarroldatos.avisos == "S") {
    this.idrolaeliminar = event.data;
    $('#eliminarrrol').modal('show');
    this.banderaeliminar = false;
  }else{
      this.respuesta = { 
        codigo:'',
        detalle:'',
        mensaje:'Este rol es de un usuario por lo que no se puede eliminar'
      };
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1500);
    }
  }

  cerrareliminarrol(){
    $('#eliminarrrol').modal('hide');
  }

  onCustomAction(event){
    switch ( event.action) {
      case 'abrireditarrol':
        this.abrireditarrol(event);
        break;
      case 'abrireliminarrrol':
        this.abrireliminarrol(event);
    }
  }

  consultarroles(){
    this.rolesService.consultarroles(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.roles = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for (let i = 0; i < this.roles.length; i++) {
          console.log(this.roles[i].avisos)
        }
      }
    });
  }

  consultarpermisosactivos(){
    var jason = {
      dato:this.rolselecionado.id,
      matriculap:this.maestro.matricula
    }
    this.rolesService.consultarpermisosactivos(jason).subscribe({
      next:(data : any) => {
        this.permisosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() =>{

      }
    });
  }

  consultarpermisosinactivos(){
    var jason = {
      dato:this.rolselecionado.id,
      matriculap:this.maestro.matricula
    }
    this.rolesService.consultarpermisosinactivos(jason).subscribe({
      next:(data : any) => {
        this.permisosinactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() =>{

      }
    });
  }

  agregarpermiso(event){
    var jason = {
      dato:this.rolselecionado.id,
      dato2:event.data.id,
      dato3:this.maestro.matricula
    }
    this.rolesService.agregarpermiso(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.respuesta.codigo == "OK"){
          this.consultarpermisosactivos();
          this.consultarpermisosinactivos();
        }
        if(this.respuesta.codigo == "ERROR"){
          $('#respuesta').modal('show');
        }
      }
    });
  }

  eliminarpermiso(event){
    var jason = {
      dato:this.rolselecionado.id,
      dato2:event.data.id,
      dato3:this.maestro.matricula
    }
    this.rolesService.eliminarpermiso(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.respuesta.codigo == "OK"){
          this.consultarpermisosactivos();
          this.consultarpermisosinactivos();
        }
        if(this.respuesta.codigo == "ERROR"){
          $('#respuesta').modal('show');
        }
      }
    });
  }

  crearrol(){
    this.rolesService.crearrol(this.crearrolform.value).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      }, 
      complete:() => {
        $('#crearrol').modal('hide');
        setTimeout(() => {
          $('#respuesta').modal('show');
          this.consultarroles();
        }, 1500);
      }
    });
  }

  editarrol(){
    this.rolesService.editarrol(this.editarrolform.value).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      }, 
      complete:() => {
        $('#editarrol').modal('hide');
        setTimeout(() => {
          $('#respuesta').modal('show');
          this.consultarroles();
        }, 1500);
      }
    })
  }

  eliminarrol(){
    var jason = {
      dato:this.idrolaeliminar.id,
      matriculap:this.maestro.matricula
    }
    this.rolesService.eliminarrol(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#eliminarrrol').modal('hide');
        setTimeout(() => {
          $('#respuesta').modal('show');
          this.consultarroles();
        }, 1500);
      }
    });
  }

  consultarvistasactivaspormatricula(){
    var jason = {
      dato:this.rolselecionado.id,
      matriculap:this.maestro.matricula
    }
    this.rolesService.consultarvistasactivaspormatricular(jason).subscribe({
      next:(data : any) => {
        this.vistasactivas = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarvistasaginables(){
    var jason = {
      matriculap:this.maestro.matricula
    }
    this.rolesService.consultarvistasasignables(jason).subscribe({
      next:(data : any) => {
        this.vistasasignables = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  generarcheckbox(){
    this.semestresselecionados = [];
    this.semestresparaagregar = [];
    this.semestresparaeliminar = [];
    this.banderacarreraselecionado = true;
    var carrera = <HTMLSelectElement>document.getElementById('vistacarreras');
    for(var x = 0; x < this.vistasasignables.length; x++){
      if(carrera.value == this.vistasasignables[x].carrera_id){
        this.carreraselescionado = this.vistasasignables[x];
      }
    }
    for(var i = 1; i <= this.carreraselescionado.semestre; i++){
      this.semestresxcarrera.push(i);
    }
    this.consultarsemestresasignados();
  }

  consultarsemestresasignados(){
    var jason = {
      dato:this.rolselecionado.id,
      dato2:this.carreraselescionado.carrera_id,
      dato3:this.maestro.matricula
    }
    this.rolesService.consultarsemestresasignados(jason).subscribe({
      next:(data : any) => {
        this.semestresasignadosvista = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarsemestresnoasignados();
      }
    });
  }

  consultarsemestresnoasignados(){
    var jason = {
      dato:this.rolselecionado.id,
      dato2:this.carreraselescionado.carrera_id,
      dato3:this.maestro.matricula
    }
    this.rolesService.consultarsemestresnoasignados(jason).subscribe({
      next:(data : any) => {
        this.semestresnoasignadosvista = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semestresfiltrados = [];
        for(var i = 0; i < this.semestresasignadosvista.length; i++){
          this.semestresfiltrados.push(this.semestresasignadosvista[i]);
          this.semestresselecionados.push(this.semestresasignadosvista[i]);
        }
        for(var x = 0; x < this.semestresnoasignadosvista.length; x++){
          this.semestresfiltrados.push(this.semestresnoasignadosvista[x]);
        }
        this.banderacarreraselecionado = false;
      }
    });
  }

  agregarsemestre(semestre, event){
    if(event.target.checked){
      this.semestresselecionados.push(semestre);
    }else{
      for(var x = 0; x < this.semestresselecionados.length; x++){
        if(semestre == this.semestresselecionados[x]){
          this.semestresselecionados.splice(x, 1);
        }
      }
    }
  }

  guardarmodificaciones(){
      for( var i = 0; i < this.semestresasignadosvista.length; i++ ){
        if(this.semestresselecionados.includes(this.semestresasignadosvista[i])){

        }else{
          this.semestresparaeliminar.push(this.semestresasignadosvista[i]);
        }
      }
      for( var x = 0; x < this.semestresnoasignadosvista.length; x++ ){
        if(this.semestresselecionados.includes(this.semestresnoasignadosvista[x])){
          this.semestresparaagregar.push(this.semestresnoasignadosvista[x]);
        }else{
          
        }
      }
      if(this.semestresparaeliminar.length != 0 ){
        this.eliminarsemestresvista(this.semestresparaeliminar);
      }
      if(this.semestresparaagregar.length != 0){
        this.agregarsemestresvista(this.semestresparaagregar);
      }
  }

  agregarsemestresvista(semestres){
    var semestresarreglo = semestres;
    var respuseta = [];
    for(var x = 0; x < semestresarreglo.length; x++){
      var jason = {
        rol:this.rolselecionado.id,
        carrera_id:semestresarreglo[x].carrera_id,
        semestre:semestresarreglo[x].semestre,
        matriculap:this.maestro.matricula
      }
      this.rolesService.agregarsemestredevista(jason).subscribe({
        next:(data : any) => {
          this.respuesta = data;
          respuseta.push(data);
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          if(semestresarreglo.length == respuseta.length){
            for(var x = 0; x < respuseta.length; x++){
              if(respuseta[x].codigo == "ERROR"){
                this.banderamodal = false;
                $('#respuesta').modal('show');
                break;
              }
            }
            if(this.banderamodal == true){
              $('#respuesta').modal('show');
            }
            this.semestresparaagregar = [];
          }
        }
      });
    }
  }

  eliminarsemestresvista(semestres){
    var semestresarreglo = semestres;
    var respuseta = [];
    for(var x = 0; x < semestresarreglo.length; x++){
      var jason = {
        dato:semestresarreglo[x].id,
        matriculap:this.maestro.matricula
      }
      this.rolesService.eliminarsemestredevista(jason).subscribe({
        next:(data : any) => {
          this.respuesta = data;
          respuseta.push(data);
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          if(semestresarreglo.length == respuseta.length){
            for(var x = 0; x < respuseta.length; x++){
              if(respuseta[x].codigo == "ERROR"){
                this.banderamodal = false;
                $('#respuesta').modal('show');
                break;
              }
            }
            if(this.banderamodal == true){
              $('#respuesta').modal('show');
            }
            this.semestresparaeliminar = [];
          }
        }
      });
    }
  }



}