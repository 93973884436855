<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-7">

                        <div class="card">
                            <div class="card-body">
                                <h3>Período/Ciclo Escolar</h3>
                                <hr>
                                <nav>
                                    <a class="card-link" routerLink="/admin-periodo/agregar-nuevo" routerLinkActive="active">Períodos Escolares</a>
                                    <a class="card-link" routerLink="/admin-periodo/consultar" routerLinkActive="active">Consultar</a> 
                                    <a class="card-link" routerLink="/admin-periodo/listado-configuracion" routerLinkActive="active">Listado y Configuración</a>
                                    <a class="card-link" routerLink="/admin-periodo/dias-festivos" routerLinkActive="active">Dias Festivos</a>
                                  </nav>
                            </div>
                          </div>
                          
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>