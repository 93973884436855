import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { AuthGuard } from '../auth/guards/auth.guard';

const routes: Routes = [
  {
    path:'',
    component: LoginComponent
  },
  {
    path:'changePass',
    component: ChangePassComponent
  },
  {
    path:'resetPass',
    component: ResetPassComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
