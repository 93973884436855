<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            
                <div class="col-lg-12" [hidden]="!banderaSpinner">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                </div>

                <div class="col-lg-12" [hidden]="banderaSpinner">
                    <div [hidden]="bandera">
                        <table class="table table-striped table-hover">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th scope="col">Maestro</th>
                                    <th scope="col">Matrícula</th>
                                    <th scope="col">Grupo</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let grupo of grupos; let i = index">
                                    <td scope="col">{{ grupo.maestro.persona.apellidop }} {{ grupo.maestro.persona.apellidom }} {{ grupo.maestro.persona.nombres }}</td>
                                    <td scope="col">{{ grupo.maestro.matricula }}</td>
                                    <td>{{ grupo.nombre }}</td>
                                    <td>
                                        <button *ngIf="!banderaVisualizarLista" class="btn btn-danger" (click)="verlistapdf(grupo)">Visualizar lista</button>
                                        <!-- <button class="btn btn-danger" (click)="imprimirLista(grupo.id)">Imprimir Lista</button> -->
                                    </td>
                                    <td *ngIf="!banderaVisualizarPreacta"><button class="btn btn-danger" (click)="preacta(grupo.id)" target="_blank" [routerLink]="['/cierre/calificaciones', grupo.id]">Preacta</button></td>
                                    <td *ngIf="!banderaReabrirActa">
                                      <label *ngIf="grupo.firmado == -99">Acta cerrada</label>
                                      <button class="btn btn-danger" *ngIf="grupo.firmado > 0" (click)="abrirmensaje1(grupo)">Reabrir acta {{ (grupo.firmado==1)?'ordinaria':'' }}{{ (grupo.firmado==2)?'extraordinaria':'' }}</button>
                                    </td>
                                    <!-- *ngIf="grupo.firmado > 0" -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div [hidden]="!bandera">
                        <label>No hay grupos disponibles..</label>
                    </div>
                </div>

                <div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" aria-label="Close" (click)="cerrarpdf()">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="!banderadocumento">
                                <app-visualizar-lista-grupo-pdf [id]="this.materiaid" [matriculap]="this.maestro.matricula"></app-visualizar-lista-grupo-pdf>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button (click)="imprimirLista()" *ngIf="!banderaImprimirPDF" class="btn btn-danger">Imprimir Lista PDF</button>
                            <button type="button" class="btn btn-danger" (click)="cerrarpdf()">Aceptar</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="aviso1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                          <button type="button" class="close" (click)="cerraraviso1()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="!banderadatos">
                                ¿Está seguro de reabrir acta de calificación <strong>{{ (grupo.firmado==0)?'sin acceso':'' }}{{ (grupo.firmado==1)?'ordinaria':'' }}{{ (grupo.firmado==2)?'extraordinaria':'' }}</strong> del grupo <strong>{{ grupo.mpe.semestre }}{{ grupo.nombre }}</strong> de la materia <strong>{{ grupo.mpe.materia.nombre }}</strong>?
                            </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" (click)="cerraraviso1()">Salir</button>
                          <button type="button" class="btn btn-danger" (click)="abrirmensaje2()">Aceptar</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade" id="aviso2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Comentarios</h5>
                          <button type="button" class="close" (click)="cerraraviso2()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Comentarios</label>
                                <textarea [formControl]="this.uploadform.get('comentarios')" class="form-control" id="comentarios" rows="3"></textarea>
                            </div>
                            <div class="form-group">
                                <input type="file" (change)="onFileSelect($event)" enctype="multipart/form-data" class="form-control-file" id="docuemtno">
                            </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" (click)="cerraraviso2()">Salir</button>
                          <button type="button" class="btn btn-danger"[disabled]="uploadform.invalid" (click)="abrirmensaje3()">Aceptar</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal" id="cargando" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-body">
                            <div class="text-center">
                                <h5 class="modal-title">Procesando</h5>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade" id="aviso3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                          <button type="button" class="close" (click)="cerraraviso3()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            ¡Este proceso invalida las calificaciones anteriores!
                            <ul>
                                <li>Para ingresar nuevas calificaciones deberá estar vigente el período de captura, en caso contrario comunicarse a Servicios Escolares.</li>
                                <li>Se deberá firmar nuevamente el acta de calificaciones.</li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" (click)="cerraraviso3()">Salir</button>
                            <button type="button" class="btn btn-danger" (click)="abriractas()">Aceptar</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal fade" id="respuesta" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                          <button type="button" class="close" aria-label="Close" (click)="cerrarrespuesta()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                            {{ respuesta.mensaje }}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" (click)="cerrarrespuesta()">Salir</button>
                        </div>
                      </div>
                    </div>
                  </div>

        </div>        
    </div>
</body>
</html>