import { Component, OnInit } from '@angular/core';
import { CatalogosService } from '../services/catalogos.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
var moment = require('moment');
import {LocalDataSource} from 'ng2-smart-table';
import { de } from 'date-fns/locale';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
declare var $ : any;
declare var _ : any;


@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {
  p: number = 1;
  imagen:any;
  

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  myDpOptions2: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  fechaI1: IMyDateModel = null;
  fechaF1: IMyDateModel = null;
  fechainicio1;
  fechainicio_ISO1;
  fechafin1;
  fechafin_ISO1;




  fechaI: IMyDateModel = null;
  fechaF: IMyDateModel = null;
  fechainicio= "";
  fechainicio_ISO;
  fechafin = "";
  fechafin_ISO;

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  avisosForm = new FormGroup({
    titulo: new FormControl('', [Validators.required]),
    aviso: new FormControl(''),
    inicio: new FormControl(''),
    fin: new FormControl(''),
    matricula: new FormControl('')
  });

  editPrio = new FormGroup({
    id: new FormControl(''),
    prioridad: new FormControl(''),
  });
  addRol = new FormGroup({
    id: new FormControl('')
  });


  editAvisoForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    titulo: new FormControl('', [Validators.required]),
    aviso: new FormControl(''),
    inicio: new FormControl(''),
    fin: new FormControl(''),
    matricula: new FormControl('')
  });

  AvisoRoles = []



  settings = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'agregarrol', title:'<i class="fas fa-plus"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin roles activos disponibles.'
  }

  settings2 = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'eliminarrol', title:'<i class="fas fa-times"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Eliga roles para la notificación.'
  }


  settings3 = {
    columns:{
      titulo:{
        title:'Titulo',
        editable:false,
        width:'25%'
      },
      aviso:{
        title:'Aviso',
        editable:false,
        width:'25%'
      },
      inicio:{
        title:'Inicio',
        editable:false,
        width:'25%'
      },
      fin:{
        title:'Fin',
        editable:false,
        width:'25%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'eliminaraviso', title:'<i class="fas fa-times"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin avisos disponibles.'
  }

  settings4 = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'agregarrol', title:'<i class="fas fa-plus"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin roles activos disponibles.'
  }

  settings5 = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'eliminarrol', title:'<i class="fas fa-times"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Eliga roles para la notificación.'
  }


  itemSource: LocalDataSource;
  itemSource2: LocalDataSource;
  itemSource3: LocalDataSource;
  itemSource4: LocalDataSource;
  itemSource5: LocalDataSource;

  banderroles:boolean = true;
  avisosactivos = [];
  rolesactivos = [];
  rolesactivos1 = [];
  rolesselecionados = [];
  rolesselecionados1 = [];
  validacion = '';
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  prioridadseleccionado = '';
  avisocompleto;
  banderaaviso:boolean = true;
  banderacarga:boolean = true;
  banderenviar: boolean = false;
  banderenviar1: boolean = false;
  configAviso: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Aviso',
    translate: 'no',
    sanitize: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    toolbarHiddenButtons: [
      ['indent', 'outdent', 'heading', 'fontName', 'strikeThrough', 'superscript', 'subscript'],
      ['textColor', 'backgroundColor', 'customClasses', 'insertImage',
      'insertVideo','unlink', 'insertHorizontalRule']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  configAviso1: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Aviso',
    translate: 'no',
    sanitize: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    toolbarHiddenButtons: [
      ['indent', 'outdent', 'heading', 'fontName', 'strikeThrough', 'superscript', 'subscript'],
      ['textColor', 'backgroundColor', 'customClasses', 'insertImage',
      'insertVideo','unlink', 'insertHorizontalRule']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  prioridad =[
    {'nombre': "Baja", id: 3 },
    {'nombre': "Media", id: 2 },
    {'nombre': "Alta", id: 1 }
  ];
  notiroles = [];
  safeurl: SafeUrl;
  htmlContent = '';
  htmlContent1 = '';
  htmlContentWithoutStyles='';
  text = '';
  text1 = '';
  idaviso = '';
  idavi = '';
  sem;
  plan;
  sem2 = '';
  plan2 = '';
  access = [];
  banderaListado : boolean = true;
  banderaAgregar : boolean = true;
  plandeestudios=[];
  semestres=[];
  semestres2=[];
  constructor(private catalogosService: CatalogosService,  private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtn();
    this.consultarroles();
    this.itemSource = new LocalDataSource(this.rolesselecionados);
    this.consultarroles1();
    this.consultarplanestudios();
  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10126 || this.access[i] == 3){
        this.banderaAgregar = false;
      }
      if(this.access[i] == 10127 || this.access[i] == 3){
        this.banderaListado = false;
      }
    }
  }

  notirol(){


  }


  avisomodal(id){
    this.idaviso = id;
    
    $('#avisomodal').modal('show');
    this.banderaaviso = false;
  }
  editAviso(id, titulo, aviso, inicio, fin){
    this.editAvisoForm.patchValue({id:id});
    this.editAvisoForm.patchValue({titulo:titulo});
    this.htmlContent1 = aviso;
    this.text1 = this.htmlContent1
    this.editAvisoForm.patchValue({ aviso:this.text1 });
    $("#finicio").val(inicio);
    $("#ffin").val(fin);
    this.editAvisoForm.patchValue({inicio:inicio});
    this.editAvisoForm.patchValue({fin:fin});

    $('#editAviso').modal('show');

  }
  editPrioridad(id,prio){
    this.editPrio.patchValue({id:id});
    this.editPrio.patchValue({prioridad:prio});
    $("#prioridad1").val(prio);
    console.log(this.editPrio.value);
    $('#editPrioridad').modal('show');

  }

  enviarEditPrioridas(){
    var periodoid = <HTMLSelectElement>document.getElementById('prioridad1');
    var jason ={
      id:this.editPrio.get('id').value,
      prioridad:periodoid.value,
      matriculap: this.maestro.matricula
    };
    console.log(jason);
    this.catalogosService.editarPrioridad(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderacarga = true;
        this.consultardatosactivos();

        $('#respuesta').modal('show');
      }
    });

  }
  addNewRol(id, aviso =[]){

    let filtrado2 = [];
    let filtrado = [];

    this.addRol.patchValue({id:id});
    for(var x = 0; x < aviso.length; x++){
      filtrado2.push(aviso[x].rol[0].id);
    }
    console.log(filtrado2);

    filtrado = this.rolesactivos1.filter(x => !filtrado2.includes(x.id));
    console.log(filtrado)
    this.rolesactivos1 = filtrado;
    this.itemSource5 = new LocalDataSource(filtrado);
    $('#addRol').modal('show');


  }
  enviarRolNew(){

    let respuestas = [];
    var periodoid = <HTMLSelectElement>document.getElementById('prioridad2');
    let sem = <HTMLSelectElement>document.getElementById('semestre2');
    let plan = <HTMLSelectElement>document.getElementById('planestudio2');
    let matricula = <HTMLSelectElement>document.getElementById('matricula');
      for(var i = 0; i < this.rolesselecionados1.length; i++){
        this.AvisoRoles.push({
          rol_id: this.rolesselecionados1[i].id,
          prioridad: periodoid.value,
          ple:plan.value,
          semestre:sem.value 
        })
        }
        var jason = {
          id:this.addRol.get('id').value,
          matriculap: this.maestro.matricula,
          rol:this.AvisoRoles,
          matricula:matricula
        }
        console.log(jason);

        this.catalogosService.addRolesNew(jason).subscribe({
          next:(data : any) => {
            respuestas.push(data);
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {

              this.respuesta = respuestas[0];
              this.AvisoRoles = [];
              $('#respuesta').modal('show');
              this.addRol.reset();
              $('#prioridad2').prop('selectedIndex',0);
              $('#semestre2').prop('selectedIndex',0);
              $('#planestudio2').prop('selectedIndex',0);
              this.rolesselecionados1 = [];
              this.itemSource4 = new LocalDataSource();
              this.consultardatosactivos;
              this.consultarroles1();


          }
        });


  }

  eliminarnotificacion(){
    var jason = {
      matriculap: this.maestro.matricula,
      id:this.idaviso

    }
    this.catalogosService.borraraviso(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderacarga = true;
        this.consultardatosactivos();
        $('#respuesta').modal('show');
      }
    });
  }

//Consulta el plan de estudio 
  consultarplanestudios(){
    this.catalogosService.listaCarreras(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.plandeestudios = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
//al seleccionar un plan de estudio se activa el semestre
  semestre(){
    this.semestres=[];
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    this.plandeestudios.forEach(x => {
      if(plan.value == x.idplanestudio){
          for (let i = 1; i <= x.semestre; i++) {
            this.semestres.push({semestre:i}) 
          }
      }
    });
    console.log(this.semestres );
  }
  semestre2(){
    this.semestres2=[];
    let plan = <HTMLSelectElement>document.getElementById('planestudio2');
    this.plandeestudios.forEach(x => {
      if(plan.value == x.idplanestudio){
          for (let i = 1; i <= x.semestre; i++) {
            this.semestres2.push({semestre:i}) 
          }
      }
    });
    console.log(this.semestres );
  }
  enviar(){
    console.log(this.avisosForm.get('aviso').value)
    this.banderenviar = true;
    let respuestas = [];
    let sem = <HTMLSelectElement>document.getElementById('semestre');
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    let matricula = <HTMLSelectElement>document.getElementById('matricula');
    if(this.fechainicio_ISO > this.fechafin_ISO){
      this.validacion = 'La fecha de inicio no puede ser despues de la fecha de finalización.'
      $('#validacion').modal('show');
    }else{
      this.sanitizer.bypassSecurityTrustHtml(this.text);
      // this.avisosForm.get('aviso'). = this.text;
      this.avisosForm.patchValue({ aviso:this.text });

      for(var i = 0; i < this.rolesselecionados.length; i++){
        this.AvisoRoles.push({
          rol_id:this.rolesselecionados[i].id,
          prioridad:this.prioridadseleccionado,
          ple:plan.value,
          semestre:sem.value,
          matricula: matricula.value
        })
        }
        var jason = {
          titulo:this.avisosForm.get('titulo').value,
          aviso:this.avisosForm.get('aviso').value,
          inicio:this.fechainicio,
          fin:this.fechafin,
          rol:this.AvisoRoles,
          matriculap: this.maestro.matricula
        }

        this.catalogosService.agregaraviso(jason).subscribe({
          next:(data : any) => {
            respuestas.push(data);
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
              this.respuesta = respuestas[0];
              this.AvisoRoles = [];
              $('#respuesta').modal('show');
              this.avisosForm.reset();
              this.htmlContent = ""; 
              $('#prioridad').prop('selectedIndex',0);
              $('#semestre').prop('selectedIndex',0);
              $('#planestudio').prop('selectedIndex',0);
              this.fechaI = null;
              this.prioridadseleccionado = '';
              this.fechaF = null;
              this.rolesselecionados = [];
              this.itemSource = new LocalDataSource(this.rolesselecionados);
              this.banderenviar = false;
              this.consultardatosactivos;
              this.consultarroles();
          }
        });

    }
  }

  enviarEditForm(){
    this.banderenviar1 = true;
    let respuestas = [];
    if(this.fechainicio_ISO > this.fechafin_ISO){
      this.validacion = 'La fecha de inicio no puede ser despues de la fecha de finalización.'
      $('#validacion').modal('show');
    }else{
      this.sanitizer.bypassSecurityTrustHtml(this.text1);
      //this.avisosForm.get('aviso').value = this.text1;
      this.editAvisoForm.patchValue({ aviso:this.text1 });
      this.fechainicio1 = this.editAvisoForm.get('inicio').value;
      this.fechafin1 = this.editAvisoForm.get('fin').value;
      console.log(this.editAvisoForm.value);

        var jason = {
          id: this.editAvisoForm.get('id').value,
          titulo:this.editAvisoForm.get('titulo').value,
          aviso:this.editAvisoForm.get('aviso').value,
          inicio:this.fechainicio1,
          fin:this.fechafin1,
          matriculap: this.maestro.matricula
        }
        console.log(jason);

        this.catalogosService.editAviso(jason).subscribe({
          next:(data : any) => {
            respuestas.push(data);
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            //if(respuestas.length == this.rolesselecionados.length){
              this.respuesta = respuestas[0];
              $('#respuesta').modal('show');
              this.editAvisoForm.reset();
              this.htmlContent1 = "";
              $('#prioridad').prop('selectedIndex',0);
              this.fechaI1 = null;
              this.fechaF1 = null;
              this.banderenviar1 = false;
              this.consultardatosactivos();

              ;

            //}
          }
        });

    }
  }

  eliminarrol(event){
    for(var x = 0; x < this.rolesselecionados.length; x++){
      if(event.data.id == this.rolesselecionados[x].id){
        this.rolesactivos.push(this.rolesselecionados[x]);
      }
    }
    for(var i = 0; i < this.rolesselecionados.length; i++){
      if(event.data.id == this.rolesselecionados[i].id){
        this.rolesselecionados.splice(i, 1);
      }
    }
    this.rolesactivos.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.itemSource2 = new LocalDataSource(this.rolesactivos);
    this.itemSource = new LocalDataSource(this.rolesselecionados);
  }

  agregarrol(event){
    for(var i = 0; i < this.rolesactivos.length; i++){
      if(event.data.id == this.rolesactivos[i].id){
        this.rolesselecionados.push(this.rolesactivos[i]);
      }
    }
    for(var x = 0; x < this.rolesactivos.length; x++){
      if(event.data.id == this.rolesactivos[x].id){
        this.rolesactivos.splice(x, 1);
      }
    }
    this.itemSource = new LocalDataSource(this.rolesselecionados);
    this.itemSource2 = new LocalDataSource(this.rolesactivos);
  }
//delete rol
  eliminarrol1(event){
    for(var x = 0; x < this.rolesselecionados1.length; x++){
      if(event.data.id == this.rolesselecionados1[x].id){
        this.rolesactivos1.push(this.rolesselecionados1[x]);
      }
    }
    for(var i = 0; i < this.rolesselecionados1.length; i++){
      if(event.data.id == this.rolesselecionados1[i].id){
        this.rolesselecionados1.splice(i, 1);
      }
    }
    this.rolesactivos1.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.itemSource5 = new LocalDataSource(this.rolesactivos1);
    this.itemSource4 = new LocalDataSource(this.rolesselecionados1);
  }
//add new rol
  agregarrol1(event){
    for(var i = 0; i < this.rolesactivos1.length; i++){
      if(event.data.id == this.rolesactivos1[i].id){
        this.rolesselecionados1.push(this.rolesactivos1[i]);
      }
    }
    for(var x = 0; x < this.rolesactivos1.length; x++){
      if(event.data.id == this.rolesactivos1[x].id){
        this.rolesactivos1.splice(x, 1);
      }
    }
    this.itemSource4 = new LocalDataSource(this.rolesselecionados1);
    this.itemSource5 = new LocalDataSource(this.rolesactivos1);

  }



  handleFileSelect(evt){
    console.log(evt);

    var files = evt.target.files;
    var file = files[0];
    if (file.size<1000000){
      if(file.type == "image/jpeg"){
        if (files && file) {
          var reader = new FileReader();
          reader.onload =this._handleReaderLoadedimg.bind(this);
          reader.readAsBinaryString(file);
      }
      }else{
        if(file.type == "application/pdf"){
          if (files && file) {
              var reader = new FileReader();
              reader.onload =this._handleReaderLoadedpdf.bind(this);
              reader.readAsBinaryString(file);
          }
        }
      }
    }else {
      this.respuesta={
        codigo:'',
        detalle:'El archivo debe ser menor a 1 MB',
        mensaje:'El archivo debe ser menor a 1 MB'
      };
      $('#respuesta').modal('show');
    }
  }
  _handleReaderLoadedimg(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.sanitizer.bypassSecurityTrustHtml( this.htmlContent += '<img class="figure-img img-fluid rounded" src="'+'data:image/jpeg;base64,'+btoa(binaryString)+'" />');
    this.text = this.htmlContent;
    console.log(this.htmlContent);
  }
  _handleReaderLoadedpdf(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.sanitizer.bypassSecurityTrustHtml(this.htmlContent += '<a download="FAMEN.pdf" href="data:application/pdf;base64,'+btoa(binaryString)+'">Descargar</a>');
    this.text = this.htmlContent;
    console.log(this.htmlContent);
  }
//Al editar aviso
  handleFileSelect1(evt){
    //debugger
    var files = evt.target.files;
    var file = files[0];
    if(file.type == "image/jpeg"){
      if (files && file) {
        var reader = new FileReader();
        reader.onload =this._handleReaderLoadedimg1.bind(this);
        reader.readAsBinaryString(file);
    }
    }else{
      if(file.type == "application/pdf"){
        if (files && file) {
            var reader = new FileReader();
            reader.onload =this._handleReaderLoadedpdf1.bind(this);
            reader.readAsBinaryString(file);
        }
      }
    }
  }
//Al editar aviso
  _handleReaderLoadedimg1(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.sanitizer.bypassSecurityTrustHtml( this.htmlContent1 += '<img class="figure-img img-fluid rounded" src="'+'data:image/jpeg;base64,'+btoa(binaryString)+'" />');
    this.text1 = this.htmlContent1;
    console.log(this.htmlContent1);
  }
//Al editar aviso
  _handleReaderLoadedpdf1(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.sanitizer.bypassSecurityTrustHtml(this.htmlContent1 += '<a download="FAMEN.pdf" href="data:application/pdf;base64,'+btoa(binaryString)+'">Descargar</a>');
    this.text1 = this.htmlContent1;
    console.log(this.htmlContent1);
  }

  consultarroles(){
    this.catalogosService.consultarrolesavisos(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.rolesactivos = data;

      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // for(var i = 0; i < this.rolesactivos.length; i++){
        //   if(this.rolesactivos[i].nombre == "ALUMNO"){
        //     this.rolesactivos.splice(i, 1);
        //   }
        // }
        var jason = {
          id:-1,
          descripcion:'PARA ENVIAR NOTIFICACION A ALUMNOS',
          escolares:0,
          nombre:'NOTIFICACIÓN ALUMNOS',
          relacion:"0"
        }
        this.rolesactivos.push(jason);

        this.rolesactivos.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });


        this.itemSource2 = new LocalDataSource(this.rolesactivos);
        this.banderroles = false;
        this.consultardatosactivos();
      }
    });
  }
  consultarroles1(){
    this.catalogosService.consultarrolesavisos(this.maestro.matricula).subscribe({
      next:(data : any) => {

        this.rolesactivos1 = data;

      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var jason = {
          id:-1,
          descripcion:'PARA ENVIAR NOTIFICACION A ALUMNOS',
          escolares:0,
          nombre:'NOTIFICACIÓN ALUMNOS',
          relacion:"0"
        }
        this.rolesactivos1.push(jason);
        this.rolesactivos1.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });



        this.banderroles = false;
        this.consultardatosactivos();

      }
    });
  }
  showHTML(){
    this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
    this.text = this.htmlContent;
    this.sanitizer.bypassSecurityTrustHtml(this.text1);
    console.log(this.text)
  }
  showHTML1(){
    this.sanitizer.bypassSecurityTrustHtml(this.htmlContent1);
    this.text1 = this.htmlContent1;
    this.sanitizer.bypassSecurityTrustHtml(this.text1);

    console.log(this.text1)
  }
  consultardatosactivos(){
    this.catalogosService.consultaravisosactivos().subscribe({
      next:(data : any) => {
        this.avisosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.itemSource3 = new LocalDataSource(this.avisosactivos);
        // console.log(this.avisosactivos);
        //this.filtraravisos()
        this.banderacarga = false;

      }

    });

  }

  // filtraravisos(){
  //   var filtrado = [];
  //   this.avisosactivos.forEach(function(valor, index){
  //     var bandera = false;
  //     for(var i = 0; i < filtrado.length; i++){
  //       if(valor.aviso == filtrado[i].aviso){
  //         bandera = true;
  //         filtrado[i].roles.push({ rol: valor.rol, id:valor.id });
  //       }
  //     }
  //     if(!bandera){
  //       filtrado.push({
  //           aviso:valor.aviso,
  //           fin:valor.fin,
  //           inicio:valor.inicio,
  //           titulo:valor.titulo,
  //           id:valor.id,
  //           roles:[
  //             { rol: valor.rol, id: valor.id }
  //           ],
  //           roldesc:[]
  //       });
  //     }
  //   });
  //   for(var a = 0; a < filtrado.length; a++){
  //     for(var s = 0; s < filtrado[a].roles.length; s++){
  //       for(var x = 0; x < this.rolesactivos.length; x++){
  //         if(this.rolesactivos[x].id == filtrado[a].roles[s].rol){
  //           filtrado[a].roldesc.push({id:filtrado[a].roles[s].id, desc:this.rolesactivos[x].nombre});
  //         }
  //       }
  //     }
  //   }

  //   // for(var a = 0; a < filtrado.length; a++){
  //   //   for(var s = 0; s < filtrado[a].roles.length; s++){
  //   //     if(filtrado[a].roles[s].rol <= 0 || filtrado[a].roles[s].rol >= 0){
  //   //       filtrado[a].roles.splice(s, 1);
  //   //     }
  //   //   }
  //   // }
  //   this.notiroles = filtrado;
  //   this.safeurl = this.notiroles;
  //   console.log(filtrado);
  // }

  seleccionarprioridad(){
    var periodoid = <HTMLSelectElement>document.getElementById('prioridad');
    this.prioridadseleccionado = periodoid.value;
  }

  fechainicioI(event){
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioF(event){
    this.fechafin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }
  fechainicioI1(event){
    this.fechainicio1 = event.singleDate.formatted;
    this.editAvisoForm.patchValue({ inicio:this.fechainicio1});
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioF1(event){
    this.fechafin1 = event.singleDate.formatted;
    this.editAvisoForm.patchValue({ fin:this.fechafin1});
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }

}