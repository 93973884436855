import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-materias',
  templateUrl: './inicio-materias.component.html',
  styleUrls: ['./inicio-materias.component.css']
})
export class InicioMateriasComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  };

  access = [];
  banderaListadoMaterias : boolean = true;
  banderaCalendarioExamenes : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10016 || this.access[i] == 3){
        this.banderaListadoMaterias = false;
      }
      if(this.access[i] == 10024 || this.access[i] == 3){
        this.banderaCalendarioExamenes = false;
      }

    }
  }

}
