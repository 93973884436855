<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <div [hidden]="bandera">
                            <table class="table table-hover table-sm table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Materia</th>
                                        <th scope="col">Semestre</th>
                                        <th scope="col">Grupo</th>
                                        <th scope="col">No. Repeticiónes</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let materia of materiasCursando; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ materia.grupo.mpe.materia.nombre }} {{ materia.grupo.alias }}</td>
                                        <td>{{ materia.grupo.mpe.semestre }}</td>
                                        <td>{{ materia.grupo.nombre }}</td>
                                        <td>{{ materia.grupo.mpe.repetida }}</td>
                                        <td><button (click)="primerAviso(materia.grupo.mpe.id)" type="button" class="close" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <!-- Modal primer aviso -->
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atencion!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    ¿Estás seguro que deseas eliminar la materia del horario del alumno?
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="segundoAviso()">Entiendo</button>
                </div>
            </div>
            </div>
        </div>

        <!-- Modal segundo aviso -->
        <div class="modal fade" id="staticBackdrop2" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atencion!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    Éste proceso no puede deshacerse, ¿Seguro que deseas continuar?
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="eliminarMateria()">Entiendo</button>
                </div>
            </div>
            </div>
        </div>

        <!-- Modal resultado aviso -->
        <div class="modal fade" id="staticBackdropResult" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atencion!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    {{ response.mensaje }}
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
            </div>
        </div>

    </div>
</body>
</html>