<tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
<!-- <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container bg-white">
        <div>
            
        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <h2>Universidad Juárez del Estado de Durango</h2>
                    <h3>FACULTAD DE MEDICINA Y NUTRICIÓN</h3>
                </div>
            </div>
        </div>

        <div class="row" id="top">

        </div>

        <div class="row">
            <div class="col-sm-12 ml-2">
                <h5><strong>A quien corresponda:</strong></h5>
                <h5>{{ textos }}</h5>
            </div>
        </div>

        <div class="row" id="top">

        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="line">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">SEM</th>
                            <th scope="col">MATERIAS</th>
                            <th scope="col">CAL</th>
                            <th scope="col">EXA</th>
                            <th scope="col">FECHA</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let materia of historialMenorIgual5">
                        <tr>
                            <td>{{ materia.semestre }}</td>
                            <td>{{ materia.materia }}</td>
                            <td>{{ materia.calificacion }}</td>
                            <td>{{ materia.examen | slice:0:3 }}</td>
                            <td>{{ materia.fecha }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-6">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">SEM</th>
                            <th scope="col">MATERIAS</th>
                            <th scope="col">CAL</th>
                            <th scope="col">EXA</th>
                            <th scope="col">FECHA</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let materia of historialMayor5">
                        <tr>
                            <td>{{ materia.semestre }}</td>
                            <td>{{ materia.materia }}</td>
                            <td>{{ materia.calificacion }}</td>
                            <td>{{ materia.examen | slice:0:3 }}</td>
                            <td>1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="line">
                </div>
            </div>
        </div>

        <div class="row" id="top">

        </div>

        <div class="row" *ngIf="!bandera">
            <div class="col-sm-12 ml-2">
                <h5><strong>Promedio: </strong>{{ pie.promedio | slice:0:5 }}</h5>
                <h5>{{ pie.footer }}</h5>
            </div>
        </div>

        <div class="row" id="top">

        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="text-center">
                    <h5>ATENTAMENTE</h5>
                    <h5>"Por mi raza hablará mi espiritu"</h5>
                </div>
            </div>
        </div>

        <div class="row" id="top">

        </div>
        </div>
    </div>

</body>
</html> -->