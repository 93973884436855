<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="row">
        <div class="col-lg-2 img">
            <div>
                
                <img src="../../../assets/img/avatar.jpg" alt="..." class="rounded-circle">
            </div>
        </div>
        <div class="col-lg-10">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-row">
                        <div class="form-group col-4">
                          <label for="nombres">Nombres</label>
                          <input type="text" readonly [formControl]="this.datospersonalform.get('nombres')" class="form-control" id="nombres">
                        </div>
                        <div class="form-group col-4">
                          <label for="apellidop">Apellido Paterno</label>
                          <input type="text" readonly [formControl]="this.datospersonalform.get('apellidop')" class="form-control" id="apellidop">
                        </div>
                        <div class="form-group col-4">
                            <label for="apellidom">Apellido Materno</label>
                            <input type="text" readonly [formControl]="this.datospersonalform.get('apellidom')" class="form-control" id="apellidom">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-row">
                        <div class="form-group col-6">
                          <label for="curp">CURP</label>
                          <input type="text" [formControl]="this.datospersonalform.get('curp')" class="form-control" id="curp">
                          <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.curp.touched || datospersonalform.controls.curp.dirty)">
                            <div class="text-danger" *ngIf="datospersonalform.controls.curp.errors?.required">Ingrese una CURP.</div>
                            <div class="text-danger" *ngIf="datospersonalform.controls.curp.errors?.maxlength">CURP no debe de ser mayor de 18 caracteres.</div>
                            <div class="text-danger" *ngIf="datospersonalform.controls.curp.errors?.minlength">CURP no debe de ser menor de 18 caracteres.</div>
                        </div>
                        </div>
                        <div class="form-group col-6">
                          <label for="rfc">RFC</label>
                          <input type="text" [formControl]="this.datospersonalform.get('rfc')" class="form-control" id="rfc">
                          <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.rfc.touched || datospersonalform.controls.rfc.dirty )">
                            <div class="text-danger" *ngIf="datospersonalform.controls.rfc.errors?.required">Ingrese una RFC.</div>
                            <div class="text-danger" *ngIf="datospersonalform.controls.rfc.errors?.maxlength">RFC no debe de ser mayor de 13 caracteres.</div>
                            <div class="text-danger" *ngIf="datospersonalform.controls.rfc.errors?.minlength">RFC no debe de ser menor de 12 caracteres.</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-row">
                        <div class="form-group col-4">
                          <label for="matricula">Matrícula</label>
                          <input type="text" readonly [formControl]="this.datospersonalform.get('matricula')" class="form-control" id="matricula">
                        </div>
                        <div class="form-group col-4">
                          <label for="email">Correo Electronico</label>
                          <input type="text" [formControl]="this.datospersonalform.get('email')" class="form-control" id="email">
                          <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.email.touched || datospersonalform.controls.email.dirty )">
                            <div class="text-danger" *ngIf="datospersonalform.controls.email.errors?.required">Ingrese el correo institucional.</div>
                            <div class="text-danger" *ngIf="datospersonalform.controls.email.errors?.pattern">Correo ingresado no es valido.</div>
                        </div>
                        </div>
                        <div class="form-group col-4">
                            <label for="telefono">Telefono</label>
                            <input type="text" [formControl]="this.datospersonalform.get('celular')" class="form-control" id="telefono">
                            <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.celular.touched || datospersonalform.controls.celular.dirty )">
                              <div class="text-danger" *ngIf="datospersonalform.controls.celular.errors?.required">Ingrese un telefono.</div>
                              <div class="text-danger" *ngIf="datospersonalform.controls.celular.errors?.maxlength">Numero de telefono no debe exceder los 10 digitos.</div>
                              <div class="text-danger" *ngIf="datospersonalform.controls.celular.errors?.minlength">Numero de telefono no debe ser menos de 10 digitos.</div>
                              <div class="text-danger" *ngIf="datospersonalform.controls.celular.errors?.pattern">Numero de telefono no debe contener caracterres numericos.</div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-row">
                        <div class="form-group col-6">
                          <label for="calle">Calle</label>
                          <input type="text" [formControl]="this.datospersonalform.get('calle')" class="form-control" id="calle">
                          <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.calle.touched || datospersonalform.controls.calle.dirty )">
                            <div class="text-danger" *ngIf="datospersonalform.controls.calle.errors?.required">Ingrese la calle.</div>
                        </div>
                        </div>
                        <!-- <div class="form-group col-3">
                          <label for="ninterior">Numero Interior</label>
                          <input type="text" [formControl]="this.datospersonalform.get('ninterior')" class="form-control" id="ninterior">
                        </div> -->
                        <div class="form-group col-6">
                            <label for="nexterior">Numero Exterior</label>
                            <input type="text" [formControl]="this.datospersonalform.get('numeroexterior')" class="form-control" id="nexterior">
                            <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.numeroexterior.touched || datospersonalform.controls.numeroexterior.dirty )">
                              <div class="text-danger" *ngIf="datospersonalform.controls.numeroexterior.errors?.required">Ingrese el numero exterior.</div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-row">
                        <div class="form-group col-6">
                          <label for="colonia">Colonia/Fraccionamiento</label>
                          <input type="text" [formControl]="this.datospersonalform.get('colonia')" class="form-control" id="colonia">
                          <div *ngIf="datospersonalform.invalid && (datospersonalform.controls.colonia.touched || datospersonalform.controls.colonia.dirty )">
                            <div class="text-danger" *ngIf="datospersonalform.controls.colonia.errors?.required">Ingrese la colonia o fraccionamiento.</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-lg-12" *ngIf="banderaeditardatos">
                    <button class="btn btn-danger float-right" (click)="modificardatos()">Modificar datos</button>
                </div> -->
                <div class="col-lg-12">
                    <button class="btn btn-danger float-right" (click)="guardar()">Guardar Cambios</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarresuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarresuesta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>
</body>
</html>