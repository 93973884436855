import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-alumnos-inicio',
  templateUrl: './alumnos-inicio.component.html',
  styleUrls: ['./alumnos-inicio.component.css']
})
export class AlumnosInicioComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  };

  access = [];
  banderaAgregarAlumno : boolean = true;
  banderaBuscarAlumno : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10030){
        this.banderaAgregarAlumno = false;
      }
      if(this.access[i] == 10031){
        this.banderaBuscarAlumno = false;
      }
    }
  }

  agregar(){
    $('#alumnos').modal('show');
  }

}
