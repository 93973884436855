import { Injectable } from '@angular/core';
import{HttpClient}from '@angular/common/http'
import {Global} from 'src/global';



@Injectable({
    providedIn: 'root'
  })
  export class ServicioSocialService {
    constructor(private http: HttpClient, private global:Global) { }

    //http://localhost:8080/famen_escolares/Famen/EstadosRepublica/lista/903966/0

    listaEstadosRepublica(matricula: string,idedo: number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/EstadosRepublica/lista/${matricula}/${idedo}`,null);
    }

    //http://localhost:8080/famen_escolares/Famen/PlazasServicioSocial/lista/903966/0

    listaPlazasAdministrar(matricula: string,idperiodo:string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/lista/${matricula}/${idperiodo}`,null);
    }

    insertarPlazasAdministrar(cuerpo:any){
      return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/registroPlazas`,cuerpo);
    }

    asignarPlaza(plaza:any){
      return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/insPlaza`,plaza);
    }

    /*listaPlazasAsignar( matricula: string, idperiodo: number, idplaza: number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/lista/${matricula}/${idperiodo}/${idplaza}`,null);
    }*/
    listadoPlazasAsignar(matricula:string,idperiodo:number,idplaza:number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/lista/${matricula}/${idperiodo}/${idplaza}`,null);
    }

    listaInstituciones(matricula: string,idinstitucion: number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Instituciones/lista/${matricula}/${idinstitucion}`,null);
    }

    listaConfig(datos){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/configuracion/plazas/lista`,datos);
    }

    asignarInstituciones(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Instituciones/registro`,parametros);
    }

    listaSemestres(matricula: string,idple){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matricula}/${idple}`,null);
    }

    listaCarreras(matricula: string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matricula}`,null);
    }

    periodos(matricula: string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/list/${matricula}`,null);
    }

    listadoPlazas(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/lista `,parametros);
    }

    listadoDocSol(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documentos/alumno `,parametros);
    }

    consultarsolicitudes(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/listaSolicitudes`, parametros);
    }

    cambiarEstado(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/solicitud/estado `, parametros);
    }

    asignarPlazaAlumno(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/asignacion`, parametros);
    }
    cierreAsigancion(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/cerrarAsignacion`, parametros);
    }
    subirFormatoSS(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/Alumno/Solicitud/upload`, parametros)
    }
    subirFormatoEditSS(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/Alumno/Solicitud/upload/edit`, parametros)
    }
    editActivo(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/formatos/activos`, parametros)
    }
    listaFormatos(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/formatos/todos`, parametros)
    }

    reporteFinalSS(matriculap, periodo, plan, semestre){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/ReporteFinalAsignacion/${matriculap}/${periodo}/${plan}/${semestre}`, { responseType: 'arraybuffer' })
    }
    insertConfi(data){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/configuracion/plazas/insert`,data, { headers: this.global.headers } )
    }
    Document(id){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documento/solicitud/${id}`,{ responseType: 'arraybuffer' })
    }
    consultarReporteAsignacion(matricula, periodo, plan){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/FormatoAsignacion/${matricula}/${periodo}/${plan}`,{ responseType: 'arraybuffer' })
    }
    verDocumento(id: string){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documento/${id}`, { responseType: 'arraybuffer' })
    }
    consultaralumnos(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/InternadoYservicioSocial/listado`, parametros)
    }
    consultarplanestudios(){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
    }
    listaPeriodosEscolaresCompleta(matriculap){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
    }
    reincripcion(datos){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/InternadoYservicioSocial/reinscribir`, datos)
    }
    consultarplanestudiosFiltrados(matriculap){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/lista/${matriculap}`, null);
    }
    cambiarEstadoSS(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/InternadoYservicioSocial/estado`, parametros);
    }
    capturarCalificacionSS(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/InternadoYservicioSocial/calificar`, parametros);
    }
    consultarArchivos(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Archivos/consulta`, parametros, { headers: this.global.headers });
    }
    subirArchivos(parametros){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Archivos/insert`, parametros);
    }
    verArchivo(matriculap, clave){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Archivos/descargar/${matriculap}/${clave}`, { responseType: 'arraybuffer' });
    }
    formatoLiberacionInternado(matriculap, alumnoSSID, periodo, planestudio){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/liberacionInternado/${matriculap}/${alumnoSSID}/${periodo}/${planestudio}`, { responseType: 'arraybuffer' })
    }
    formatoServicioSocial(matriculap, alumnoSSID, periodo, planestudio){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/liberacionServicioSocial/${matriculap}/${alumnoSSID}/${periodo}/${planestudio}`, { responseType: 'arraybuffer' })
    }
}