<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
      <h2>Tipos de Documentos</h2>
      <nav>
        <div class="nav nav-tabs mb-2" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-Documentos-tab" data-toggle="tab" href="#nav-Documentos" role="tab" aria-controls="nav-Documentos" aria-selected="true">Documentos</a>
          <a class="nav-link" id="nav-Personal-tab" data-toggle="tab" href="#nav-Personal" role="tab" aria-controls="nav-Personal" aria-selected="false">Personal</a>
          <a class="nav-link" id="nav-Alumnos-tab" data-toggle="tab" href="#nav-Alumnos" role="tab" aria-controls="nav-Alumnos" aria-selected="false">Alumnos</a>
          <a class="nav-link" id="nav-Alumnos-tab" data-toggle="tab" routerLink="/generacion" role="tab" aria-controls="nav-Alumnos" aria-selected="false">Generación de Documentos</a>
        </div>
      </nav>
      <div class="tab-content " id="nav-tabContent">
        <!-- tabla de documentos solicitados por alumnos-->
        <div class="tab-pane fade show active" id="nav-Documentos" role="tabpanel" aria-labelledby="nav-Documentos-tab">
          <div class="row">
                  <div class="col-lg-12">   
                  <!-- ---------------------------Listar documentos---------------------- -->
                    <div class="row" id="top">
                      <div id='lista' class="col-lg-12">
                          <table class="table table-sm table-striped table-hover table-borderless">
                              <thead class="bg-danger text-white">
                                  <tr>
                  
                                      <th scope="col">Nombre</th>
                                      <th scope="col">Precio</th>
                                      <th scope="col"></th>
                                      <th scope="col">
                                          <button (click)='vaciarModal()' data-target="#modalCreateDocForm" data-toggle="modal" class="text-light" id="btn-add-doc"><i id="plus" class="fas fa-plus"></i></button>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let documento of response">
                                      <td scope="col">{{documento.nombre}}</td>
                                      <td scope="col">{{documento.precio}}</td>
                                      <td>
                                          <button (click)="onEdit(documento.nombre,documento.precio,documento.id)"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
                  
                                      </td>
                                    
                                      <td scope="col">
                                          <button (click)='onDelete(documento.nombre,documento.precio,documento.id)' class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                      </td>
                                      
                                      
                                  </tr>
                              </tbody>
                          </table>
                  
                      </div>
                  </div>
                  
                  <!------------------ modal crear documento-------------------------- -->
                  <form [formGroup]='createForm' (ngSubmit)="onCreate()">
                          <div class="modal fade" id="modalCreateDocForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <table class="table table-striped">
                                  <thead class="bg-danger text-white">
                                      <tr>
                                          <div class="modal-header text-center">
                                              <h4 class="modal-title w-100 font-weight-bold">Crear nuevo documento</h4>
                                              <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                              </button>
                                          </div>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <div class="modal-body mx-3">
                                                  <div class="md-form mb-3">
                                                      <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                                      <input  type="text" class="form-control validate" [formControl]="this.createForm.get('nombre')" id="nombreInputN" placeholder="Nombre del documento">
                                                  </div>
                                                  <div class="md-form mb-3">
                                                      <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                                      <input  type="text" class="form-control validate" [formControl]="this.createForm.get('precio')" id="precioInputN" placeholder="Precio">
                                                  </div>

                                                </div>
                                                <div class="md-form mb-4">
                                                  <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                  <button [disabled]='createForm.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                                
                                              </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                              
                            </div>
                          </div>
                        </div>
                      </form>
          <!-- --------------------------------------------Modal para editar-------------------------------------------------------------------------------------- -->
                      <form [formGroup]='editForm' (ngSubmit)="confirmarEdit()">
                          <div class="modal fade" id="modaldEditDocForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <table class="table table-striped">
                                  <thead class="bg-danger text-white">
                                      <tr>
                                          <div class="modal-header text-center">
                                              <h4 class="modal-title w-100 font-weight-bold">Editar Documento</h4>
                                              <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                              </button>
                                          </div>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>
                                              <div class="modal-body mx-3">
                                                  <div class="md-form mb-3">
                                                      <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                                      <input  type="text" class="form-control validate" [formControl]="this.editForm.get('nombre')" id="nombreInputE" placeholder="Nombre del documento">
                                                  </div>
                                                  <div class="md-form mb-3">
                                                      <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                                      <input  type="text" class="form-control validate" [formControl]="this.editForm.get('precio')" id="precioInputE" placeholder="Precio">
                                                  </div>

                                                </div>
                                                <div class="md-form mb-4">
                                                  <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                  <button [disabled]='editForm.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                                
                                              </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                              
                            </div>
                          </div>
                        </div>
                      </form>

                      <!------------------------- modal de confirmacion ------------------------------------------>

                      <div class="modal fade" id="modal-confirmacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Felicidades!</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                {{this.respuesta.mensaje}}
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- ----------------------modal de aviso de confirmacion de crear documento------------------------------------------------------ -->
                        <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">¡Atencion!</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                {{this.modalConf}}
                                <label><strong>{{this.documento.nombre}}</strong> con un precio de $<span><strong>{{ this.documento.precio }}</strong> </span></label>
                              </div>
                              <div class="modal-footer">
                                <button type="button" (click)='onCloseCreate()' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onFinish()">Aceptar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div> 
            </div>
        </div>
        <!-- tabla de docuentos solictados a personal -->
        <div class="tab-pane fade show" id="nav-Personal" role="tabpanel" aria-labelledby="nav-Personal-tab">
          <div class="row">
            <div class="col-lg-12">   
            <!-- ---------------------------Listar documentos---------------------- -->
              <div class="row" id="top">
                <div id='lista' class="col-lg-12">
                    <table class="table table-sm table-striped table-hover table-borderless">
                        <thead class="bg-danger text-white">
                            <tr>
            
                                <th scope="col">Nombre</th>
                                <th scope="col">Obligatorio</th>
                                <th scope="col"></th>
                                <th scope="col">
                                    <button (click)='vaciarModal()' data-target="#modalCreatePersonalDocForm" data-toggle="modal" class="text-light" id="btn-add-doc"><i id="plus" class="fas fa-plus"></i></button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let documento of docsper">
                                <td scope="col">{{documento.nombre}}</td>
                                <td scope="col">{{documento.obligatorio}}</td>
                                <td>
                                    <button (click)="onEditP(documento.nombre,documento.obligatorio,documento.id)"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
            
                                </td>
                              
                                <td scope="col">
                                    <button (click)='onDeleteP(documento.nombre,documento.obligatorio,documento.id)' class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                </td>
                                
                                
                            </tr>
                        </tbody>
                    </table>
            
                </div>
            </div>
            
              <!------------------ modal crear documento personal-------------------------- -->
              <form [formGroup]='createFormP' (ngSubmit)="onCreateP()">
                      <div class="modal fade" id="modalCreatePersonalDocForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <table class="table table-striped">
                              <thead class="bg-danger text-white">
                                  <tr>
                                      <div class="modal-header text-center">
                                          <h4 class="modal-title w-100 font-weight-bold">Crear nuevo documento</h4>
                                          <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                          </button>
                                      </div>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>
                                          <div class="modal-body mx-3">
                                              <div class="md-form mb-3">
                                                  <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                                  <input  type="text" class="form-control validate" [formControl]="this.createFormP.get('nombre')" id="nombreInputP" placeholder="Nombre del documento">
                                              </div>
                                              <div class="md-form mb-3">
                                                  <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                                  <div class="form-check">
                                                    <input class="form-check-input "  [formControl]="this.createFormP.get('obligatorio')" type="checkbox" value="" id="CreateCheckBoxP">
                                                    <label class="form-check-label" for="CreateCheckBoxP">
                                                      Obligatorio
                                                    </label>
                                                  </div>
                                                  <!-- <input  type="text" class="form-control validate" [formControl]="this.createForm.get('obligatotio')" id="precioInputN" placeholder="Precio"> -->
                                              </div>

                                            </div>
                                            <div class="md-form mb-4">
                                              <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                              <button [disabled]='createFormP.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                            
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          
                        </div>
                      </div>
                    </div>
              </form>
              <!-- --------------------------------------------Modal para editar-------------------------------------------------------------------------------------- -->
              <form [formGroup]='editFormP' (ngSubmit)="confirmarEditP()">
                  <div class="modal fade" id="modaldEditDocFormP" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <table class="table table-striped">
                          <thead class="bg-danger text-white">
                              <tr>
                                  <div class="modal-header text-center">
                                      <h4 class="modal-title w-100 font-weight-bold">Editar Documento</h4>
                                      <button (click)='vaciarModalP()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>
                                      <div class="modal-body mx-3">
                                          <div class="md-form mb-3">
                                              <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                              <input  type="text" class="form-control validate" [formControl]="this.editFormP.get('nombre')" id="nombreInputE" placeholder="Nombre del documento">
                                          </div>
                                          <div class="md-form mb-3">
                                              <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                              <!-- <input  type="text" class="form-control validate" [formControl]="this.editForm.get('precio')" id="precioInputE" placeholder="Precio"> -->
                                              <div class="form-check">
                                                <input class="form-check-input" [formControl]="this.editFormP.get('obligatorio') "type="checkbox" value="" id="editCheckBoxP">
                                                <label class="form-check-label" for="editCheckBoxP">
                                                  Obligatorio
                                                </label>
                                              </div>
                                          </div>

                                        </div>
                                        <div class="md-form mb-4">
                                          <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                          <button [disabled]='editFormP.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                        
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      
                    </div>
                  </div>
                </div>
              </form>

              <!------------------------- modal de confirmacion ------------------------------------------>
              <div class="modal fade" id="modal-confirmacionP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Felicidades!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        {{this.respuesta.mensaje}}
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                      </div>
                    </div>
                  </div>
              </div>

              <!-- ----------------------modal de aviso de confirmacion de crear documento------------------------------------------------------ -->
              <div class="modal fade" id="avisoP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">¡Atencion!</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      {{this.modalConf}}
                      <label><strong>{{this.documentop.nombre}}</strong> y este <span><strong>{{ this.documentop.obligatorio }}</strong> es obligatorio</span></label>
                    </div>
                    <div class="modal-footer">
                      <button type="button" (click)='onCloseCreate()' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onFinishP()">Aceptar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
        <!-- Tabla de documentos solicitados a alumnos  -->
        <div class="tab-pane fade show" id="nav-Alumnos" role="tabpanel" aria-labelledby="nav-Alumnos-tab">
          <div class="row">
            <div class="col-lg-12">   
            <!-- ---------------------------Listar documentos---------------------- -->
              <div class="row" id="top">
                <div id='lista' class="col-lg-12">
                    <table class="table table-sm table-striped table-hover table-borderless">
                        <thead class="bg-danger text-white">
                            <tr>
            
                                <th scope="col">Nombre</th>
                                <th scope="col">Obligatorio</th>
                                <th scope="col"></th>
                                <th scope="col">
                                    <button (click)='vaciarModalA()' data-target="#modalCreateAlumnoDocForm" data-toggle="modal" class="text-light" id="btn-add-doc"><i id="plus" class="fas fa-plus"></i></button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let documento of docsalu">
                                <td scope="col">{{documento.nombre}}</td>
                                <td scope="col">{{documento.obligatorio}}</td>
                                <td>
                                    <button (click)="onEditA(documento.nombre,documento.obligatorio,documento.id)"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
            
                                </td>
                              
                                <td scope="col">
                                    <button (click)='onDeleteA(documento.nombre,documento.obligatorio,documento.id)' class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                </td>
                                
                                
                            </tr>
                        </tbody>
                    </table>
            
                </div>
            </div>
            
              <!------------------ modal crear documento Alumnos-------------------------- -->
              <form [formGroup]='createFormA' (ngSubmit)="onCreateA()">
                      <div class="modal fade" id="modalCreateAlumnoDocForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <table class="table table-striped">
                              <thead class="bg-danger text-white">
                                  <tr>
                                      <div class="modal-header text-center">
                                          <h4 class="modal-title w-100 font-weight-bold">Crear nuevo documento</h4>
                                          <button (click)='vaciarModalA()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                          </button>
                                      </div>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>
                                          <div class="modal-body mx-3">
                                              <div class="md-form mb-3">
                                                  <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                                  <input  type="text" class="form-control validate" [formControl]="this.createFormA.get('nombre')" id="nombreInputA" placeholder="Nombre del documento">
                                              </div>
                                              <div class="md-form mb-3">
                                                  <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                                  <div class="form-check">
                                                    <input class="form-check-input "  [formControl]="this.createFormA.get('obligatorio')" type="checkbox" value="" id="CreateCheckBoxA">
                                                    <label class="form-check-label" for="CreateCheckBoxA">
                                                      Obligatorio
                                                    </label>
                                                  </div>
                                                  <!-- <input  type="text" class="form-control validate" [formControl]="this.createForm.get('obligatotio')" id="precioInputN" placeholder="Precio"> -->
                                              </div>

                                            </div>
                                            <div class="md-form mb-4">
                                              <button (click)='vaciarModalA()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                              <button [disabled]='createFormA.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                            
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          
                        </div>
                      </div>
                    </div>
              </form>
              <!-- --------------------------------------------Modal para editar---------------------------------------------------------- -->
              <form [formGroup]='editFormA' (ngSubmit)="confirmarEditA()">
                  <div class="modal fade" id="modaldEditDocFormA" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <table class="table table-striped">
                          <thead class="bg-danger text-white">
                              <tr>
                                  <div class="modal-header text-center">
                                      <h4 class="modal-title w-100 font-weight-bold">Editar Documento</h4>
                                      <button (click)='vaciarModalA()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>
                                      <div class="modal-body mx-3">
                                          <div class="md-form mb-3">
                                              <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                              <input  type="text" class="form-control validate" [formControl]="this.editFormA.get('nombre')" id="nombreInputA" placeholder="Nombre del documento">
                                          </div>
                                          <div class="md-form mb-3">
                                              <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                              <!-- <input  type="text" class="form-control validate" [formControl]="this.editForm.get('precio')" id="precioInputE" placeholder="Precio"> -->
                                              <div class="form-check">
                                                <input class="form-check-input" [formControl]="this.editFormA.get('obligatorio') "type="checkbox" value="" id="editCheckBoxA">
                                                <label class="form-check-label" for="editCheckBoxA">
                                                  Obligatorio
                                                </label>
                                              </div>
                                          </div>

                                        </div>
                                        <div class="md-form mb-4">
                                          <button (click)='vaciarModalA()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                          <button [disabled]='editFormA.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                        
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      
                    </div>
                  </div>
                </div>
              </form>

              <!------------------------- modal de confirmacion ------------------------------------------>
              <div class="modal fade" id="modal-confirmacionA" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Atencion!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        {{this.respuesta.mensaje}}
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                      </div>
                    </div>
                  </div>
              </div>

              <!-- ----------------------modal de aviso de confirmacion de crear documento------------------------------------------------------ -->
              <div class="modal fade" id="avisoA" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">¡Atencion!</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      {{this.modalConf}}
                      <label><strong>{{this.documentoA.nombre}}</strong> y este <span><strong>{{ this.documentoA.obligatorio }}</strong> es obligatorio</span></label>
                    </div>
                    <div class="modal-footer">
                      <button type="button" (click)='onCloseCreate()' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onFinishA()">Aceptar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
        
</body>
</html>



