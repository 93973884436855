import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
declare var $: any;
@Component({
  selector: 'app-sesco-calificaciones',
  templateUrl: './sesco-calificaciones.component.html',
  styleUrls: ['./sesco-calificaciones.component.css']
})
export class EnviarSescoCalificacionesComponent implements OnInit {
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };
  periodosescolares = [];
  periodosescolaresfiltrados = [];
  plandeestudios = [];
  semestres = [];
  plandeesudioselecionado;
  constructor(
    private sesco: SescoService
  ) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
    this.consultarplanestudios();
  }

  abriraviso(){
    $('#aviso1').modal('show');
  }

  abriraviso2(){
    $('#aviso2').modal('show');
  }

  enviarcalificaciones(){
    let periodo = <HTMLSelectElement> document.getElementById('periodoescolar');
    let plan = <HTMLSelectElement> document.getElementById('plan');
    let semestre = <HTMLSelectElement> document.getElementById('semestre');
    $('#cargando').modal('show');
    this.sesco.sescoCalificaicones(periodo.value, plan.value, semestre.value).subscribe({
      next: (res: any) => {
        this.respuesta = res;
        $('#cargando').modal('hide');
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1000);
      }
    });
  }

  consultarperiodosescolares(){
    this.sesco.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodosescolares.length; i++){
          if((this.periodosescolares[i].estado == "ABIERTO") || (this.periodosescolares[i].estado =="ACTUAL")){
            this.periodosescolaresfiltrados.push(this.periodosescolares[i]);
          }
        }
      }
    });
  }

  consultarplanestudios(){
    this.sesco.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.plandeestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarsemestres(){
    let planestudio = <HTMLSelectElement> document.getElementById('plan');
    this.sesco.consultarsemestres(this.maestro.matricula, planestudio.value).subscribe({
      next: (res: any) => {
        this.semestres = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

}
