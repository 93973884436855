<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid bg-white">
        <form [formGroup]="busquedaForm">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="inicio">Fecha Inicio</label>
                        <input type="date" [formControl]="this.busquedaForm.get('inicio')" class="form-control" id="inicio">
                      </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="fin">Fecha Fecha Fin</label>
                        <input type="date" [formControl]="this.busquedaForm.get('fin')" class="form-control" id="fin">
                      </div>
                </div>
            </div>
            <div class="row">
                    <div class="col-12">
                        <button class="btn btn-danger  float-right" (click)="buscar()" [disabled]="busquedaForm.invalid">Buscar</button>
                    </div>
            </div>
        </form>
        <div class="row pt-3">
            <div class="col-12" *ngIf="!banderatabla">
                <ng2-smart-table class="table table-hover table-borderless" (userRowSelect)="abrir($event)" [settings]="settings" [source]="faltasfiltradas"></ng2-smart-table>
            </div>
            <div class="col-12 d-flex justify-content-center" *ngIf="banderacarga">
              <div class="spinner-border text-center text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="justificar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel"></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> -->
            <div class="modal-body">
                <div class="form-group">
                  <label for="comentario">Comentarios</label>
                  <textarea class="form-control" [formControl]="this.uploadForm.get('comentario')" id="comentario" rows="5"></textarea>
                </div>
                <div class="custom-file"style="text-align: right;">
                  <i class="fas fa-upload text-danger"></i><label id="filename" for="documento" class="btn btn-link text-danger">Subir archivos</label>
                  <input type="file" id="documento" (change)="handleFileSelect($event)" enctype="multipart/form-data" accept="application/pdf" style="display: none">  
                </div>
            </div>
            <div class="modal-footer">
              <button id="guardar" type="button" class="btn btn-danger" onClick="disabled=true;" [disabled]="uploadForm.invalid" (click)="enviar()"><div *ngIf="!banderaboton" class="spinner-border spinner-border-sm text-white" role="status"></div>Guardar</button>
              <button type="button" class="btn btn-secondary" (click)="salir()">Salir</button>
            </div>
          </div>
        </div>
      </div>  
      
      <div class="modal fade" id="mensajenew" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="closeReply()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="closeReply()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>
</body>
</html>