<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>

                <div class="col-lg-12" id="top">
                    <div class="form-row">
                        <div class="col-lg-4">
                            <select class="form-control" id="docs" (change)="selecionarDocumento()" [disabled]="disabled">
                            <option>---Selecionar Documento---</option>
                            <option value="nuevo">Nuevo Documento</option>
                            <option value="registrado">Documento Registrado</option>
                            </select>
                        </div>
                      </div>
                </div>


                <div class="col-lg-12" id="top">
                    <div class="form-row">
                        <div class="col-lg-4" [hidden]="banderaNuevo">
                            <label for="documentos">Tipo de Documento</label>
                            <select class="form-control" id="documentos" (change)="generarDocumento()">
                            <option>---Selecionar Documento---</option>
                            <option *ngFor="let documento of documentosDisponibles" [value]="documento.id">{{documento.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-lg-4" [hidden]="banderaConsultar">
                            <label for="consultar">Consultar Documento</label>
                            <select class="form-control" id="consultar" (change)="editarDocumento()">
                            <option>---Selecionar Documento---</option>
                            <option *ngFor="let doc of documentosRegistrados" [value]="doc.id">{{doc.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" id="top" [hidden]="activos">
                    <div class="form-row">
                        <div>
                            <label>Estado</label><br>
                            <div class="form-check form-check-inline">
                                <input (change)="cambiarEstado()" [checked]=" documentoEditable.estado == 'Activo' " class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Activo">
                                <label class="form-check-label" for="inlineRadio1">Activo</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input (change)="cambiarEstado()" [checked]=" documentoEditable.estado == 'Inactivo' " class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Inactivo">
                                <label class="form-check-label" for="inlineRadio2">Inactivo</label>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" id="top">
                  <div class="form-row">
                    <div class="col-lg-4" [hidden]="editor">
                      <label for="variables">Parámetros</label>
                      <select class="form-control" id="variables" (change)="agregarParametro()">
                        <option>--Parámetros--</option>
                        <option *ngFor="let parametro of parametros" [value]="parametro.editor">{{ parametro.parametros }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12" id="top" [hidden]="editor">
                    <div style="background-color: white;">
                        <angular-editor (input)="showHTML()"  [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>
                    </div>
                </div>

                <div class="row" id="top" *ngIf="!banderabtn">
                    <div class="col-lg-12">
                        <div class="float-left">
                            <button class="btn btn-danger mr-3" [disabled]="htmlContent == '' " (click)="abrirAviso()" [hidden]="botton">Guardar</button>
                            <button class="btn btn-danger mr-3" (click)="reiniciar()">Salir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  ¿Seguro que deseas guardar el siguiente documento?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="enviarDatos()">Aceptar</button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"  (click)="reiniciar()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {{ response.mensaje }}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reiniciar()">Aceptar</button>
                </div>
              </div>
            </div>
          </div>

        </div>
</body>
</html>