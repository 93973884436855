<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid">
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                  <label for="periodo3">Período Escolar</label>
                  <select class="form-control" id="periodoescolar">
                    <option value="">--Período Escolar--</option>
                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.periodo }}</option>
                  </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                  <label for="plan">Plan de Estudio</label>
                  <select class="form-control" id="plan" (change)="consultarsemestres()">
                    <option value="">--Plan de Estudios--</option>
                    <option *ngFor="let plan of plandeestudios" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{ plan.plandeestudio }})</option>
                  </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                  <label for="semestre">Semestre</label>
                  <select class="form-control" id="semestre">
                    <option value="">--Semestre--</option>
                    <option *ngFor="let semestre of semestres" [value]="semestre.semestre">{{ semestre.semestre }}</option>
                  </select>
                </div>
            </div>
            <div class="col-3">
              <div class="form-group pt-4 mt-2">
                <button class="btn btn-danger" (click)="abriraviso()">Importar</button>
              </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="aviso1" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            El proceso que esta por ejecutar es <strong>IRREVERSIBLE</strong> y importara las calificaciones de la <strong>BASE DE DATOS DE SESCO</strong>
            del período seleccionado.
            <!-- (en una tabla de transferencia de informacion, posteriormente es necesario notificar a escolares de la UJED). -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="abriraviso2()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

    
    <div class="modal fade" id="aviso2" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ¿Esta seguro que desea continuar?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="importarCalificaciones()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal" id="cargando">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
              <div class="text-center">
                  <h5 class="modal-title">Procesando</h5>
              </div>
              <div class="d-flex justify-content-center">
                  <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>