<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}"
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                      <div>
                        <form (ngSubmit)="onSubmit()" [formGroup]="busqueda">
                          <div class="form-row">
                            <div class="form-group">
                              <input type="text" class="form-control" id="matricula" placeholder="Matrícula o Nombre"  [formControl]="this.busqueda.get('matricula')">
                            </div>
                            <div class="form-group">
                              <button class="btn btn-danger ml-3" [disabled]="this.busqueda.invalid"><i class="fas fa-search"></i>Buscar</button>
                            </div>
                          </div>
                        </form>
                      </div>
                   </div>
                </div>

                <div class="row" id="top">
                  <div class="col-lg-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pendientes-tab" data-toggle="tab" href="#pendientes" role="tab" aria-controls="pendientes" aria-selected="true">Pendientes</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="terminados-tab" data-toggle="tab" titile="Concluidos" href="#terminados" role="tab" aria-controls="terminados" aria-selected="false">Concluidos</a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="pendientes" role="tabpanel" aria-labelledby="pendientes-tab">
                        <div>
                          <ng2-smart-table [settings]="settings" [source]="solicitudesNoListos" (userRowSelect)="tomarIdSolicitud($event)" class="table table-hover table-borderless table-striped table-sm"></ng2-smart-table>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="terminados" role="tabpanel" aria-labelledby="terminados-tab">
                        <div>
                          <ng2-smart-table [settings]="settings2" [source]="solicitudesListos" class="table table-hover table-borderless table-striped table-sm"></ng2-smart-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ¿Desea generar el siguiente documento?
              <label><strong>{{documento.tipodoc.nombre | titlecase}}</strong> del alumno con matricula <span><strong>{{ documento.matricula }}</strong> solicitada el <strong>{{ documento.fechasolicitud | date:'d/M/yy' }}</strong></span></label>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="abrirperidos()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="periodo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Selecione el período escolar del documento solicitado</label>
                <select class="form-control" id="exampleFormControlSelect1" (change)="guardarIdPeriodo()">
                  <option>--Período Escolar--</option>
                  <option  *ngFor="let per of periodos" [value]="per.id">{{ per.nombre }}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="atenderEscolares()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{this.respuesta.mensaje}}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="staticBackdropSinDatos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title text-center" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div>
                    <label>No se encuentran registros con los datos ingresados</label>
                </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
        </div>
    </div>

</body>
</html>