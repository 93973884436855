<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tutoriales</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <div class="card">
                            <div class="card-body">
                                <h3>Tutoriales</h3>
                                <hr>
                                <small class="text-muted">Recuerda que sí cuentas con soporte extendido puedes ingresar con tu cuenta de acceso a tu mesa de servicio desde el siguiente enlace: <a href="https://siitecs.freshdesk.com/" class="text-danger" target="_blank" rel="noopener noreferrer"> Soporte <i class="fas fa-external-link-alt"></i></a></small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>