import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {DocumentosService} from '../services/documentos.service';
declare var $:any;
@Component({
  selector: 'app-catalogo-documentos',
  templateUrl: './catalogo-documentos.component.html',
  styleUrls: ['./catalogo-documentos.component.css']
})
export class CatalogoDocumentosComponent implements OnInit {
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  busqueda = new FormGroup({
    nombre: new FormControl(''),
  });
  createForm = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    precio: new FormControl(null,[Validators.required,Validators.pattern("^[0-9]*$")])
  });
  editForm = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    precio: new FormControl(null,[Validators.required,Validators.pattern("^[0-9]*$")])
  });
  createFormP = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    obligatorio: new FormControl('')
  });
  createFormA = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    obligatorio: new FormControl('')
  });
  editFormP = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    obligatorio: new FormControl('')
  });
  editFormA = new FormGroup({
    nombre: new FormControl('',[Validators.required,Validators.minLength(3)]),
    obligatorio: new FormControl('')
  });
  response:any;
  responseCreate:any;
  responseEdit:any;
  responseDelete:any;


  // id del documento a editar
  idEdit:number;
  idEditPersonl:number;
  idEditAlumno:number;

  //id del documento a eliminar
  idDelete:number;
  //mensaje para modal
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  //documento a crear
  documento = {
    nombre:"",
    precio:null
  }
  documentop = {
    nombre:"",
    obligatorio:null
  }
  documentoA = {
    nombre:"",
    obligatorio:null
  }
  docs = [];
  docsper = [];
  docsalu = [];
  
  //Variable para cambiar valor del modal de confirmacion
  modalConf='';

  // varaiable para identificar que funcion se usara al confirmar que si se esta de acuerdo
  funcion='';
  constructor(private document:DocumentosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listarDocumentos();
    this.listarDocumentosPersonal();
    this.listarDocumentosAlmnmo();
  }
  listarDocumentos(){
    this.document.listaDocumentos(this.maestro.matricula).subscribe({
      next: (res)=>{
        this.response=res;
        console.log(this.response);
        
      },
      error: () => {
        throw new Error('Error al listar documentos')
      }
    })
  }
  
  onCreate(){
    var nombreInput = (<HTMLInputElement>document.getElementById('nombreInputN')).value;
    var precioInput = (<HTMLInputElement>document.getElementById('precioInputN')).value;

    this.documento.nombre=nombreInput;
    this.documento.precio=precioInput;
    this.modalConf='¿Deseas crear el siguiente documento?'
    this.funcion='onFinishCreate()'

    this.funcion='crear';
    $('#modalCreateDocForm').modal('hide');
    $('#aviso').modal('show');

  }
  onFinish(){
    if(this.funcion=='crear'){
      console.log('Entro a crear');

      this.onFinishCreate()

    }
    if(this.funcion=='editar'){
      console.log('Entro a editar');

      this.onFinishEdit()

    }
    if(this.funcion=='eliminar'){
      console.log('Entro a eliminar');
      
      this.onFinishDelete();
    }
  }
  
  onFinishCreate(){

    var jason = {
      nombre:this.documento.nombre,
      precio: parseInt(this.documento.precio),
      matriculap: this.maestro.matricula
    }
    this.document.insertar(jason).subscribe({
      next:(res:any)=>{
        this.responseCreate=res;
        this.respuesta = res;
        
        console.log(res);
        
      },
      complete:()=>{
        this.listarDocumentos()
        $('#modalCreateDocForm').modal('hide');
        $('#modal-confirmacion').modal('show');
        this.documento.precio=null;
        this.documento.nombre='';
        
      },
      error:(err)=>{
        throw new Error(err)
      }
    })
  }
  vaciarModal(){
    this.createForm.patchValue({
      nombre:'',
      precio:null
    })
  }
  onEdit(nombre:string,precio:number,id:number){
    this.idEdit=id
    console.log(`nombre ${nombre}, precio ${precio}`);
    
    this.editForm.patchValue({
      nombre:nombre,
      precio:precio
    })
    
    $('#modaldEditDocForm').modal('show');
  }

  confirmarEdit(){
    var nombreInput = (<HTMLInputElement>document.getElementById('nombreInputE')).value;
    var precioInput = (<HTMLInputElement>document.getElementById('precioInputE')).value;

    this.documento.nombre=nombreInput;
    this.documento.precio=precioInput;

    this.funcion='editar'
    this.modalConf='¿Deseas editar el documento con la siguiente información?'

    $('#modaldEditDocForm').modal('hide');
    $('#aviso').modal('show');
    
  }
  onFinishEdit(){
    

    console.log(`nombreI ${this.documento.nombre}, precioI ${this.documento.precio}`);
    

    var jason={
      id:this.idEdit,
      nombre: this.documento.nombre,
      precio: parseInt(this.documento.precio),
      matriculap: this.maestro.matricula
    }
    console.log(jason);
    

    this.document.editar(jason).subscribe({
      next:(res:any)=>{
        this.responseEdit=res;
        this.respuesta=res      
      },
      complete:()=>{
        this.listarDocumentos()
        $('#modaldEditDocForm').modal('hide');
        $('#modal-confirmacion').modal('show');
      },
      error:(err)=>{
        throw new Error(err)
      }
    })
  }
  onDelete(nombre:string, precio:number,id:number){
    this.idDelete=id;
    this.documento.nombre=nombre;
    this.documento.precio=precio;
    this.funcion='eliminar';
    console.log(`funcion a usar: ${this.funcion}`);
    
    this.modalConf='¿Deseas eliminar el documento con la siguiente información?'
    $('#aviso').modal('show');

  }
  onFinishDelete(){
    var jason={
      dato:this.idDelete,
      matriculap:this.maestro.matricula
    }
    this.document.eliminar(jason).subscribe({
      next: (res:any)=>{
        this.responseDelete=res;
        console.log(this.responseDelete);
        this.respuesta=res;
        
      },
      complete:()=>{
        this.listarDocumentos()
        $('#modal-confirmacion').modal('show');
      },
      error:()=>{
        throw new Error("No se pudo eliminar el documento")
      }
    })
  }
  onCloseCreate(){
  }
  //--------------------------- Personal ------------------------------------//
  listarDocumentosPersonal(){
    this.document.listaDocumentosPersonal().subscribe({
      next: (data : any)=>{
        this.docsper = data;
        console.log(this.docsper);
        
      },
      error: () => {
        throw new Error('Error al listar documentos')
      }
    })

  }
  //---------------------- Crear ------------------//
  onCreateP(){
    var nombreInput = this.createFormP.value.nombre;
    var obligatorioInput ;
    if(this.createFormP.value.obligatorio==true){
      obligatorioInput= 'SI';
    }else
    if(this.createFormP.value.obligatorio==false){
      obligatorioInput= 'NO';
    }
    this.documentop.nombre=nombreInput;
    this.documentop.obligatorio=obligatorioInput ;
    this.modalConf='¿Deseas crear el siguiente documento?'
    this.funcion='onFinishCreateP()'
    
    this.funcion='crear';
    $('#modalCreatePersonalDocForm').modal('hide');
    $('#avisoP').modal('show');
  }
  onFinishCreateP(){
    console.log(this.createFormP.value);
    var obliga ;
    if(this.createFormP.value.obligatorio==true){
      obliga= 'S';
    }else 
    if(this.createFormP.value.obligatorio==false){
      obliga= 'N';
    }
    
    var jason = {
      nombre:this.documentop.nombre,
      obligatorio:obliga,
    }
    console.log(jason);
    this.document.insertarP(jason).subscribe({
      next:(res:any)=>{
        this.responseCreate=res;
        this.respuesta=res      
      },
      complete:()=>{
        this.listarDocumentosPersonal()
        $('#modalCreatePersonalDocForm').modal('hide');
        $('#modal-confirmacionP').modal('show');
        this.documentop.obligatorio=null;
        this.documentop.nombre='';
        
      },
      error:(err)=>{
        throw new Error(err)
      }
    });
    
    this.vaciarModalP();
  }
  //---------------------- Editar ------------------//
  onEditP(nombre:string,obligatorio:string,id:number){
    this.idEdit=id
    console.log(`nombre ${nombre}, obligatorio ${obligatorio}`);
    if(obligatorio =='S'){
      this.editFormP.patchValue({
      nombre:nombre,
      obligatorio:true
    })
    }else
    if(obligatorio =='N'){
      this.editFormP.patchValue({
      nombre:nombre,
      obligatorio:false
    })
    }
    
    
    $('#modaldEditDocFormP').modal('show');
  }
  confirmarEditP(){
    var nombreInput = this.editFormP.value.nombre;
    var obligatorioInput ;
    
    if(this.editFormP.value.obligatorio==true){
      obligatorioInput= 'SI';
    }else
    if(this.editFormP.value.obligatorio==false){
      obligatorioInput= 'NO';
    }
    this.documentop.nombre=nombreInput;
    this.documentop.obligatorio = obligatorioInput;

    this.funcion='editar'
    this.modalConf='¿Deseas editar el documento con la siguiente información?'

    $('#modaldEditDocFormP').modal('hide');
    $('#avisoP').modal('show');
    
  }
  onFinishEditP(){
    console.log(this.editFormP.value);
    var obliga ;
    if(this.editFormP.value.obligatorio==true){
      obliga= 'S';
    }else 
    if(this.editFormP.value.obligatorio==false){
      obliga= 'N';
    }
    var jason={
      id:this.idEdit,
      nombre: this.editFormP.value.nombre,
      obligatorio: obliga,
    }
    console.log(jason);
    

    this.document.editarP(jason).subscribe({
      next:(res:any)=>{
        this.responseEdit=res;
        this.respuesta=res      
      },
      complete:()=>{
        this.listarDocumentosPersonal()
            this.editFormP.patchValue({
              nombre:'',
              obligatorio:null
            })
        $('#modaldEditDocFormP').modal('hide');
        $('#modal-confirmacionP').modal('show');
      },
      error:(err)=>{
        throw new Error(err)
      }
    })
  }
  vaciarModalP(){
    this.createFormP.patchValue({
      nombre:'',
      obligatorio:null
    })
  }
  onDeleteP(nombre:string, obligatorio:number,id:number){
  
    this.idDelete=id;
    this.documentop.nombre=nombre;
    this.documentop.obligatorio=obligatorio;
    this.funcion='eliminar';
    console.log(`funcion a usar: ${this.funcion}`);
    
    this.modalConf='¿Deseas eliminar el documento con la siguiente información?'
    $('#avisoP').modal('show');
  }
  onFinishDeleteP(){
    var jason={
      id:this.idDelete,
    }
    this.document.eliminarP(jason).subscribe({
      next: (res:any)=>{
        this.responseDelete=res;
        console.log(this.responseDelete);
        this.respuesta=res;
        
      },
      complete:()=>{
        this.listarDocumentosPersonal()
        $('#modal-confirmacionP').modal('show');
      },
      error:()=>{
        throw new Error("No se pudo eliminar el documento")
      }
    })
  }
  onFinishP(){
    if(this.funcion=='crear'){
      console.log('Entro a crear');

      this.onFinishCreateP()

    }
    if(this.funcion=='editar'){
      console.log('Entro a editar');

      this.onFinishEditP()

    }
    if(this.funcion=='eliminar'){
      console.log('Entro a eliminar');
      
      this.onFinishDeleteP();
    }
  }
  //-------------------------- Alumnos------------------------------//
  listarDocumentosAlmnmo(){
    this.document.listaDocumentosAlumno().subscribe({
      next: (data : any)=>{
        this.docsalu = data;
        console.log(this.docsalu);
        
      },
      complete:()=>{
        console.log(this.docsalu);
      },
      error: () => {
        throw new Error('Error al listar documentos')
      }
    })
  }
  //--------------------------- Crear documento Alumno ----------------------//
  onCreateA(){
    console.log(this.createFormA.value);
    var nombreInputA = this.createFormA.value.nombre;
    var obligatorioInputA ;
    if(this.createFormA.value.obligatorio==true){
      obligatorioInputA= 'SI';
    }else
    if(this.createFormA.value.obligatorio==false){
      obligatorioInputA= 'NO';
    }

    
    this.documentoA.nombre=nombreInputA;
    this.documentoA.obligatorio=obligatorioInputA ;
    this.modalConf='¿Deseas crear el siguiente documento?'
    this.funcion='onFinishCreateA()'
    
    this.funcion='crear';
    $('#modalCreateAlumnoDocForm').modal('hide');
    $('#avisoA').modal('show');
  }
  onFinishCreateA(){
    console.log(this.createFormA.value);
    
    var obliga ;
    if(this.createFormA.value.obligatorio==true){
      obliga= 'S';
    }else 
    if(this.createFormA.value.obligatorio==false){
      obliga= 'N';
    }
    var jason = {
      nombre:this.createFormA.value.nombre,
      obligatorio:obliga,
    }

    console.log(jason);
    this.document.insertarA(jason).subscribe({
      next:(res:any)=>{
        this.responseCreate=res;
        this.respuesta=res      
      },
      complete:()=>{
        this.listarDocumentosAlmnmo()
        $('#modalCreateAlumnoDocForm').modal('hide');
        $('#modal-confirmacionA').modal('show');
        this.documentop.obligatorio=null;
        this.documentop.nombre='';
        
      },
      error:(err)=>{
        throw new Error(err)
      }
    });
    this.vaciarModalA();
    
    
  }
  //---------------------- Editar Alumnos ------------------//
  onEditA(nombre:string,obligatorio:string,id:number){
    this.idEdit=id
    console.log(`nombre ${nombre}, obligatorio ${obligatorio}`);
    
    if(obligatorio =='S'){
      this.editFormA.patchValue({
      nombre:nombre,
      obligatorio:true
    })
    }else
    if(obligatorio =='N'){
      this.editFormA.patchValue({
      nombre:nombre,
      obligatorio:false
    })
    } 
    $('#modaldEditDocFormA').modal('show');
  }
  confirmarEditA(){
    console.log(this.editFormA.value);
    var nombreInputA = this.editFormA.value.nombre;
    var obligatorioInputA ;
    
    if(this.editFormA.value.obligatorio==true){
      obligatorioInputA= 'SI';
    }else
    if(this.editFormA.value.obligatorio==false){
      obligatorioInputA= 'NO';
    }
    this.documentoA.nombre=nombreInputA;
    this.documentoA.obligatorio = obligatorioInputA;

    this.funcion='editar'
    this.modalConf='¿Deseas editar el documento con la siguiente información?'

    $('#modaldEditDocFormA').modal('hide');
    $('#avisoA').modal('show');
    
  }
  onFinishEditA(){
    console.log(this.editFormA.value);
    var obliga ;
    if(this.editFormA.value.obligatorio==true){
      obliga= 'S';
    }else 
    if(this.editFormA.value.obligatorio==false){
      obliga= 'N';
    }
    var jason={
      id:this.idEdit,
      nombre: this.editFormA.value.nombre,
      obligatorio: obliga,
    }
    console.log(jason);
    

    this.document.editarA(jason).subscribe({
      next:(res:any)=>{
        this.responseEdit=res;
        this.respuesta=res      
      },
      complete:()=>{
        this.listarDocumentosAlmnmo();
            this.editFormA.patchValue({
              nombre:'',
              obligatorio:null
            })
        $('#modaldEditDocFormA').modal('hide');
        $('#modal-confirmacionA').modal('show');
      },
      error:(err)=>{
        throw new Error(err)
      }
    })
    this.vaciarModalA();
  }
  vaciarModalA(){
    this.createFormA.patchValue({
      nombre:'',
      obligatorio:null
    })
  }
  onDeleteA(nombre:string, obligatorio:number,id:number){
  
    this.idDelete=id;
    this.documentoA.nombre=nombre;
    this.documentoA.obligatorio=obligatorio;
    this.funcion='eliminar';
    console.log(`funcion a usar: ${this.funcion}`);
    
    this.modalConf='¿Deseas eliminar el documento con la siguiente información?'
    $('#avisoA').modal('show');
  }
  onFinishDeleteA(){
    var jason={
      id:this.idDelete,
    }
    this.document.eliminarA(jason).subscribe({
      next: (res:any)=>{
        this.responseDelete=res;
        console.log(this.responseDelete);
        this.respuesta=res;
        
      },
      complete:()=>{
        this.listarDocumentosAlmnmo()
        $('#modal-confirmacionA').modal('show');
      },
      error:()=>{
        throw new Error("No se pudo eliminar el documento")
      }
    })
  }
  onFinishA(){
    if(this.funcion=='crear'){
      console.log('Entro a crear');
      console.log(this.createFormA.value);
      this.onFinishCreateA()

    }
    if(this.funcion=='editar'){
      console.log('Entro a editar');
      console.log(this.editFormA.value);
      this.onFinishEditA()

    }
    if(this.funcion=='eliminar'){
      console.log('Entro a eliminar');
      
      this.onFinishDeleteA();
    }
  }
}
