import { PlanestudiosService } from './../service/planestudios.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SIGABRT } from 'constants';
import { log } from 'console';
declare var $:any;
@Component({
  selector: 'app-admin-plan-estudio',
  templateUrl: './admin-plan-estudio.component.html',
  styleUrls: ['./admin-plan-estudio.component.css']
})
export class AdminPlanEstudioComponent implements OnInit {
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  formPlanEstudio = new FormGroup({
    planestudio: new FormGroup({
      nombre : new FormControl('', [Validators.required]),
      clave : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$") ]),
      estatus : new FormControl('', [Validators.required]),
      semestres:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
      calificacionminima:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
      carrera: new FormGroup({
        id: new FormControl('')
      }),
      nombreCarrera: new FormControl(''),
      carreraid:new FormControl('',[Validators.required]),}),
      matriculap: new FormControl('',[Validators.required]),
  });
  formPlanEstudioEdit = new FormGroup({
    planestudio: new FormGroup({
      id : new FormControl(0, [Validators.required]),
      nombre : new FormControl('', [Validators.required]),
      clave : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$") ]),
      estatus : new FormControl('', [Validators.required]),
      semestres:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
      calificacionminima:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
      carrera: new FormGroup({
        id: new FormControl('')
      }),
      nombreCarrera: new FormControl(''),
      carreraid:new FormControl('',[Validators.required]),}),
      matriculap: new FormControl('',[Validators.required]),
  });
  formMateria = new FormGroup({
      clave : new FormControl('', [Validators.required ]),
      semestre:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
      materiaid:new FormControl('',[Validators.required]),
      electiva:new FormControl('',[Validators.required]),
      planid: new FormControl(''),
      matriculap: new FormControl(''),
      repetida:new FormControl(''),
      cuentapromedio:new FormControl(''),
      cantidadrenglones:new FormControl(''),
      tipocalificacion:new FormControl(''),
      visible:new FormControl(''),
      renglon:new FormControl(''),
      orden:new FormControl(''),
  });

  formEditMateria = new FormGroup({
    id: new FormControl('', [Validators.required ]),
    clave : new FormControl('', [Validators.required ]),
    semestre:new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$") ]),
    materiaid:new FormControl('',[Validators.required]),
    materia:new FormControl(''),
    electiva:new FormControl('',[Validators.required]),
    planid: new FormControl(''),
    matriculap: new FormControl(''),
    repetida:new FormControl(''),
    cuentapromedio:new FormControl(''),
    cantidadrenglones:new FormControl(''),
    tipocalificacion:new FormControl(''),
    visible:new FormControl(''),
    renglon:new FormControl(''),
    orden:new FormControl(''),
});

  settings = {
    columns:{
      nombre:{
        title:'Plan de estudio',
        editable:false,         
        width:'20%'
      },
      clave:{
        title:'Clave del plan de estudio',
        editable:false,         
        width:'10%'
      },
      carrera:{
        title:'Carrera',
        editable:false,         
        width:'20%'
      },
      claveoficial:{
        title:'Clave de la carrera',
        editable:false,         
        width:'10%'
      },
      nivele:{
        title:'Nivel',
        editable:false,         
        width:'10%'
      },
      semestres:{
        title:'Semestres',
        editable:false,         
        width:'10%'
      },
      estatus:{
        title:'Estado',
        editable:false,         
        width:'10%'
      },
      calificacionminima:{
        title:'Calificación minima',
        editable:false,         
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
        //{ name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }
  settings2 = {
    columns:{
      materia:{
        title:'Materia',
        editable:false,         
        width:'50%'
      },
      clave:{
        title:'Clave de la materia',
        editable:false,         
        width:'15%'
      },
      electiva:{
        title:'Electiva',
        editable:false,         
        width:'10%'
      },
      semestre:{
        title:'Semestres',
        editable:false,         
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
        { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:8
    },
    noDataMessage:'Sin datos disponibles.'
  }
  planestudiosactivos = [];
  filtroPlanEstudio = [];
  materiasPL = [];
  filtroMateriasPL = [];
  banderamaterias: boolean = false;
  carreras = [];
  materiadisponible = [];
  bandera : boolean = false;
  idplan;
  semestres = [];
  banderaMateriaN: boolean = true;
  Mpe = {
    clave: '',
    clavemateria: '',
    electiva: '',
    id: 0,
    idmateria: 0,
    idple: 0,
    materia: '',
    cantidadrenglones:0,
    cuentapromedio:0,
    orden:0,
    renglon:0,
    repetida:0,
    semestre: 0,
    materiaid:0
  };
  constructor(private planestudios: PlanestudiosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarcarreras();
    this.consultarplanes();
  }

  consultarplanes(){
    this.bandera = false
    this.planestudios.consultarplanestudios().subscribe({
      next:(data : any) => {
        this.planestudiosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.planestudiosactivos.forEach(p => {
          this.filtroPlanEstudio.push({
            id: p.id,
            calificacionminima: p.calificacionminima,
            carrera: p.carrera.nombre,
            claveoficial: p.carrera.claveoficial,
            nivele: p.carrera.nivele.descripcion,
            carreraid: p.carrera.id,
            clave: p.clave,
            estatus: p.estatus,
            nombre: p.nombre,
            semestres:p.semestres
            })
        });
        this.bandera = true;
        
    }
    });
  }
  consultarcarreras(){
    
    this.planestudios.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carreras = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        
    }
    });
  }
// --- Agregar un nuevo plan --- //
  agregar(){
    $("#insert").modal('show') 
    let carrera = <HTMLSelectElement>document.getElementById('carreras');
    carrera.selectedIndex = 0;
    this.formPlanEstudio.reset();
    this.formPlanEstudio.get('matriculap').patchValue(this.maestro.matricula);
    this.formPlanEstudio.get('planestudio').get('estatus').patchValue('S');
  }
  insert(){
    //console.log(this.formPlanEstudio.value);
    let jason = {
      
      nombre: this.formPlanEstudio.get('planestudio').get('nombre').value,
      clave: this.formPlanEstudio.get('planestudio').get('clave').value,
      estatus: this.formPlanEstudio.get('planestudio').get('estatus').value,
      semestres: this.formPlanEstudio.get('planestudio').get('semestres').value,
      calificacionminima: this.formPlanEstudio.get('planestudio').get('calificacionminima').value,
      nombreCarrera: this.formPlanEstudio.get('planestudio').get('nombreCarrera').value,
      carreraid: this.formPlanEstudio.get('planestudio').get('carreraid').value,
      matriculap: this.formPlanEstudio.get('matriculap').value,
    }
    //console.log(jason);
    
    this.planestudios.insertplan(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $("#insert").modal('hide') 
        this.formPlanEstudio.reset();
        $("#respuesta").modal('show') 
        if(this.respuesta.codigo == "OK"){
          this.consultarplanes();
        }
      }
    });
  }
// --- Editar un plan --- //
  edit(e){
    //console.log(e);
    if(e.action == 'editar'){
    //form de edicion de plan de estudio
      this.formPlanEstudioEdit.get('planestudio').get('id').patchValue(e.data.id);
      this.formPlanEstudioEdit.get('planestudio').get('nombre').patchValue(e.data.nombre);
      this.formPlanEstudioEdit.get('planestudio').get('clave').patchValue(e.data.clave);
      this.formPlanEstudioEdit.get('planestudio').get('estatus').patchValue(e.data.estatus);
      this.formPlanEstudioEdit.get('planestudio').get('semestres').patchValue(e.data.semestres);
      this.formPlanEstudioEdit.get('planestudio').get('calificacionminima').patchValue(e.data.calificacionminima);
      this.formPlanEstudioEdit.get('planestudio').get('nombreCarrera').patchValue(e.data.nombreCarrera);
      this.formPlanEstudioEdit.get('planestudio').get('carreraid').patchValue(e.data.carreraid);
      this.formPlanEstudioEdit.get('matriculap').patchValue(this.maestro.matricula);
    //consulta las materias del plan de estudio
      let s = parseInt(e.data.semestres);
      this.semestres = [];
      for(var x = 1; x <= s; x++) {
        
        this.semestres.push({
          semestre:x
        })
      }
      this.idplan = e.data.id;
      this.consultaMPE();
      this.consultaMNPE();
    //Form Materias del plan de estudio
    
    $("#editar").modal('show') 
    }

  }
  insertEdit(){
    let jason = {
      id:this.formPlanEstudioEdit.get('planestudio').get('id').value,
      nombre: this.formPlanEstudioEdit.get('planestudio').get('nombre').value,
      clave: this.formPlanEstudioEdit.get('planestudio').get('clave').value,
      estatus: this.formPlanEstudioEdit.get('planestudio').get('estatus').value,
      semestres: this.formPlanEstudioEdit.get('planestudio').get('semestres').value,
      calificacionminima: this.formPlanEstudioEdit.get('planestudio').get('calificacionminima').value,
      nombreCarrera: this.formPlanEstudioEdit.get('planestudio').get('nombreCarrera').value,
      carreraid: this.formPlanEstudioEdit.get('planestudio').get('carreraid').value,
      matriculap: this.formPlanEstudioEdit.get('matriculap').value,
    }
    //console.log(jason);
    
    this.planestudios.editplan(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $("#editar").modal('hide') 
        this.formPlanEstudioEdit.reset();
        $("#respuesta").modal('show') 
        if(this.respuesta.codigo == "OK"){
          this.consultarplanes();
        }
      }
    });
  }
// --- Materias del plan --- //
  consultaMPE(){
    this.filtroMateriasPL = [];
    this.banderamaterias = false;
    this.planestudios.consultarMPE(this.maestro.matricula,this.idplan ).subscribe({
      next:(data : any) => {
        this.materiasPL = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.materiasPL.forEach(e => {
          this.filtroMateriasPL.push({

            id:e.id,
            idple: e.idple,
            clave:e.clave,
            materia: e.materia.nombre,
            materiaid: e.materia.id,
            clavemateria: e.materia.clave,
            semestre:e.semestre,
            electiva: (e.electiva==0)?"NO":"SI",
            cantidadrenglones:e.cantidadrenglones,
            tipocalificacion:e.tipocalificacion,
            cuentapromedio:e.cuentapromedio,
            orden:e.orden,
            visible:e.visible,
            renglon:e.renglon,
            repetida:e.repetida
          });
        });
        //console.log(this.materiasPL);
        
        this.banderamaterias = true;
      }
    });
  }
//
  consultaMNPE(){
    this.banderaMateriaN = false;
    let jason = {
      matriculap: this.maestro.matricula,
      planestudioid: this.idplan
    }
    this.planestudios.consultarMNPE(jason).subscribe({
      next:(data : any) => {
        this.materiadisponible = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaMateriaN = true;

      }
    });
  }
  agregarMaterias(){
    let jason = {
      matriculap: this.maestro.matricula,
      semestre: this.formMateria.get('semestre').value,
      clave:this.formMateria.get('clave').value,
      electiva:this.formMateria.get('electiva').value,
      repetida:this.formMateria.get('repetida').value,
      planid:this.idplan,
      materia:{
        id:this.formMateria.get('materiaid').value
      },
      cuentapromedio:this.formMateria.get('cuentapromedio').value,
      cantidadrenglones:this.formMateria.get('cantidadrenglones').value,
      tipocalificacion: 'N',
      visible:this.formMateria.get('visible').value,
      renglon:this.formMateria.get('renglon').value,
      orden:this.formMateria.get('orden').value
      
    }
    this.planestudios.insertmpe(jason,this.idplan,this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.formMateria.reset();
        $("#editar").modal('hide') 
        $("#respuesta").modal('show')
        this.consultaMNPE();
      }
    });
  }

  editMaterias(){
    let jason = {
      matriculap: this.maestro.matricula,
      semestre: this.formEditMateria.get('semestre').value,
      clave:this.formEditMateria.get('clave').value,
      electiva:this.formEditMateria.get('electiva').value,
      repetida:this.formEditMateria.get('repetida').value,
      planid:this.idplan,
      materia:{
        id:this.formEditMateria.get('materiaid').value
      },
      cuentapromedio:this.formEditMateria.get('cuentapromedio').value,
      cantidadrenglones:this.formEditMateria.get('cantidadrenglones').value,
      tipocalificacion:this.formEditMateria.get('tipocalificacion').value,
      visible:this.formEditMateria.get('visible').value,
      renglon:this.formEditMateria.get('renglon').value,
      orden:this.formEditMateria.get('orden').value
      
    }
    this.planestudios.editmpe(jason,this.formEditMateria.get('id').value,this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.formEditMateria.reset();
        $("#editMpe").modal('hide');
        $("#editar").modal('hide') 
        $("#respuesta").modal('show')
        this.consultaMNPE();
      }
    });
  }
  cerrarModalEdit(){
    $("#editar").modal('hide');
    this.formEditMateria.reset();
    this.formMateria.reset();
  }
  cerrarModalEditForm(){
    $("#editMpe").modal('hide');
  }
  quitarMaterias(e){
    //console.log(e);
    if(e.action == 'borrar'){
      this.Mpe = e.data;
      $("#aviso").modal('show');
    }
    if(e.action == 'editar'){
      //debugger
      this.Mpe = e.data;
      this.formEditMateria.get('semestre').patchValue(this.Mpe.semestre);
      this.formEditMateria.get('id').patchValue(this.Mpe.id);
      this.formEditMateria.get('clave').patchValue(this.Mpe.clave);
      this.formEditMateria.get('electiva').patchValue(((this.Mpe.electiva=='SI')?1:0  ));
      this.formEditMateria.get('repetida').patchValue(this.Mpe.repetida);
      this.formEditMateria.get('materiaid').patchValue(this.Mpe.materiaid);
      this.formEditMateria.get('cuentapromedio').patchValue(e.data.cuentapromedio);
      this.formEditMateria.get('cantidadrenglones').patchValue(e.data.cantidadrenglones);
      this.formEditMateria.get('tipocalificacion').patchValue('N');
      this.formEditMateria.get('visible').patchValue(e.data.visible);
      this.formEditMateria.get('renglon').patchValue(e.data.renglon);
      this.formEditMateria.get('orden').patchValue(e.data.orden);
      this.formEditMateria.get('materia').patchValue(e.data.materia);
      //console.log(this.Mpe);
      //console.log(e.data);
      $("#editMpe").modal('show');
      this.formEditMateria.get('materia').disable()
    }
  }

  deleteM(){
    //console.log(this.Mpe);
    
    let jason = {
      matriculap: this.maestro.matricula,
      mpeId: this.Mpe.id
    }
   // console.log(jason);
    
    this.planestudios.deletempe(this.Mpe.id, this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.Mpe = {
          clave: '',
          clavemateria: '',
          electiva: '',
          id: 0,
          idmateria: 0,
          idple: 0,
          materia: '',
          semestre: 0,
          cantidadrenglones:0,
          cuentapromedio:0,
          orden:0,
          renglon:0,
          repetida:0,
          materiaid:0
        };
        //this.consultaMNPE();
        $("#aviso").modal('hide');
        $("#editar").modal('hide') 
        $("#respuesta").modal('show')

      }
    });
  }
}
