import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CalAnimation, IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
import { AuditoriaService } from '../services/auditoria.service';

@Component({
  selector: 'app-auditoria-permisos',
  templateUrl: './auditoria-permisos.component.html',
  styleUrls: ['./auditoria-permisos.component.css']
})
export class AuditoriaPermisosComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  settings = {
    columns : {
      rol:{
        title:'Rol',
        editable:false,
        width:'6%'
      },
      nombre_rol:{
        title:'Descripción',
        editable:false,
        width:'15%'
      },
      permiso:{
        title:'Permisos',
        editable:false,
        width:'15%'
      },
      fecha:{
        title:'Fecha Hora',
        editable:false,
        width:'10%'
      },
      accion:{
        title:'Acción',
        editable:false,
        width:'10%'
      },
      usuario:{
        title:'Usuario',
        editable:false,
        width:'8%'
      },
      nombre_personal:{
        title:'Nombre del personal',
        editable:false,
        width:'13%'
      },
      ip_public:{
        title:'IP publica',
        editable:false,
        width:'10%'
      },
      ip_private:{
        title:'IP privada',
        editable:false,
        width:'10%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30
    },
    noDataMessage:'Sin auditoria disponible.'
  }

  busqueda = new FormGroup({
    matricula:new FormControl('')
  });

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  fechainicio;
  fechainicio_ISO;
  fechaI: IMyDateModel = null;
  fechafin;
  fechafin_ISO;
  fechaF: IMyDateModel = null;
  idperiodo = "0";
  periodosescolares = [];
  periodosescolaresfiltrados = [];
  auditoria = [];
  auditoriafiltrada = [];
  bandera:boolean = true;
  bandera2:boolean = true;
  
  constructor(private auditoriaservice: AuditoriaService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  consultarperiodoescolares(){
    this.auditoriaservice.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  buscar(){
    this.auditoria = [];
    this.auditoriafiltrada = [];
    this.bandera2 = false;
    this.bandera = true;
    var jason = {
      
      matricula:this.busqueda.get('matricula').value,
      ffin:this.fechafin_ISO,
      finicio:this.fechainicio_ISO,
      gruId:"",
      grunombre:"",
      semestre:"",
      materia:"",
      matriculap:"",
    }
    this.auditoriaservice.consultarapermisos(jason).subscribe({
      next:(data : any) => {
        this.auditoria = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.auditoria.length; i++){
          var jason = {
            rol:this.auditoria[i].rol,
            permiso:this.auditoria[i].permiso,
            fecha: this.auditoria[i].fecha,
            accion: this.auditoria[i].accion,
            usuario:this.auditoria[i].usuario,
            ip_public:this.auditoria[i].ip_public,
            ip_private:this.auditoria[i].ip_private,
            nombre_personal:this.auditoria[i].nombre_personal,
            nombre_rol:this.auditoria[i].nombre_rol
          }
          this.auditoriafiltrada.push(jason);
        }
        this.bandera = false;
      }
    });
  }

  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    if(periodoid.value == ""){

    }else{
      this.idperiodo = periodoid.value;
    }
  }

  fechainicioF(event){
    this.fechafin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioI(event){
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }


}
