import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

/**
 * Servicio para llamar a las peticiones de backend relacionadas con SESCO
 */
@Injectable({
  providedIn: 'root'
})
export class SescoService {
  /**
   * Injectar  modulos HTTP CLIENT y variables GLOBALES de configuración
   * @param http 
   * @param global 
   */
  constructor(private http: HttpClient, private global: Global) { }
/**
 * Consultar todos los periodos escolares disponibles
 * @param matriculap 
 * @returns 
 */
  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbierto/${matriculap}`, null)
  }
/**
 * Consultar todos los planes de estudios disponibles
 * @param matriculap 
 * @returns 
 */
  consultarCarrerasActivas(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matriculap}`, null)
  }
  /**
   * Consultar todos los planes de estudios disponibles
   * @param matriculap 
   * @returns 
   */
    consultarcarreras(matriculap){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listado/${matriculap}`, null)
    }
/**
 * Consultar semestres del plan de estudios id enviado
 * @param matriculap 
 * @param idplanstudio 
 * @returns 
 */
  consultarsemestres(matriculap, idplanstudio){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matriculap}/${idplanstudio}`, null);
  }
/**
 * Consultar los alumnos que esten en SESCO pero no en este sistema
 * @returns 
 */
  listaalumnossesco(data){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/Alumnos`,data);
  }
/**
 * Importar alumnos que tenga sesco pero no en el sistema
 * @param alumno 
 * @returns 
 */
  importalumnossesco(alumno){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/insert`, alumno, { headers: this.global.headers });
  }
/**
 * Importar calificaciones que esten en sesco y no esten en el historial
 * @param jason 
 * @returns 
 */
  importarcalificaciones(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/Calificaciones/Alumno`, jason);
  }
/**
 * Enviar calificaciones a sesco
 * @param periodoid 
 * @param planid 
 * @param semestre 
 * @returns 
 */
  sescoCalificaicones(periodoid, planid, semestre){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SescoCalif/enviar/${periodoid}/${planid}/${semestre}`)
  }

  importPeriodosEscolares(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/Periodos`, parametros);
  }

  importCalificacionesPeriodoEspecifico(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/Calificaciones/importar`, parametros);
  }

  mandarAlumnosInscritos(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SescoCalif/enviar/inscritos`, parametros);
  }

  consultaDatosAlumnoSesco(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/Alumnos`, parametros);
  }

  actualizarDatosAlumnoSesco(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/upDatosSesco  `, parametros);
  }
  actualizarDatosPersonalSesco(matriculap,matriculaCambiar,todo){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/conexion/llamadaPersonal/${matriculap}/${matriculaCambiar}/${todo}  `, );
  }
  actualizarCoutaInterna(fecha) {
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/conexion/llamadaPago/${fecha}`);
  }
  actualizarCoutaExterna() {
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sesco/actualizar`) 
  }
}
