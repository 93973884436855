<div>
  <div class="row" id="top">
      <div class="col-12">
          <h3>Solicitudes de Alumnos</h3>
      </div>
  </div>
  <div class="row" id="top">
      <div class="col-4">
          <select class="form-control" id="periodo" >
              <option  value="">--Período Escolar--</option>
              <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
          </select>
      </div>
      <div class="col-4">
          <select (change)="semestre()" class="form-control" id="planestudio" >
              <option value="">--Plan de Estudio--</option>
              <option *ngFor="let plan of planesactivos" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{plan.plandeestudio}})</option>
          </select>
      </div>
      <div class="col-4">
          <button type="button" class="btn btn-danger mr-2" (click)="consultarAlumnos()">Buscar</button>
          <button type="button" class="btn btn-danger mr-2"  data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Activar Filtros</button>
          <button *ngIf="!banderaSolicitudes" type="button" class="btn btn-danger mr-2" (click)="cierre()">Cerrar</button>
        </div>
  </div>

  <div class="collapse" id="collapseExample">
      <div class="card card-body">
          <div class="row">
              <div class="col-4">
                  <div class="form-group">
                  <label for="plan">Estado</label>
                  <select class="form-control" id="estado">
                      <option value="">--Estado--</option>
                      <option value="ACEPTADA">ACEPTADA</option>
                      <option value="RECHAZADA">RECHAZADA</option>
                      <option value="REVISIÓN">REVISIÓN</option>
                  </select>
                  </div>
              </div>
          </div>
        </div>
  </div>

  <div class="row" *ngIf="!banderaSolicitudes">
      <div class="col-12">
          <ng2-smart-table class="table table-hover table-borderless" [settings]="settings" [source]="solicitudes" (custom)="abriracciones($event)"></ng2-smart-table>
      </div>
  </div>

  <div class="modal fade" id="acciones" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
                Acciones para el alumno<br>
                <strong>{{ matricula }} {{ alumno }}</strong>
              </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                  <button class="btn btn-danger btn-block mb-1" (click)="cambiarEstadoModal()" data-dismiss="modal">Actualizar Estado</button>
              </div>
              <div class="col-6">
                  <button class="btn btn-danger btn-block mb-1" (click)="asignarPlazaModal()" data-dismiss="modal" *ngIf="estatdoActual == 'ACEPTADA' ">Asignar Plaza</button>
              </div>
              <div class="col-6">
                <button class="btn btn-danger btn-block mb-1" (click)="verDoc()" data-dismiss="modal"  >Ver documentos</button>
            </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
<!-- Actualizar el estado  -->
    <div class="modal fade" id="estadoModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
                Actualizar estado para el alumno<br>
                <strong>{{ matricula }} {{ alumno }}</strong>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-12">
                      <label>Estado Actual</label>
                      <select class="form-control" id="estatus" >
                          <option value="">--SIN ESTATUS--</option>
                          <option value="REVISIÓN">REVISIÓN</option>
                          <option value="ACEPTADA">ACEPTADA</option>
                          <option value="RECHAZADA">RECHAZADA</option>
                      </select>
                  </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>Comentario</label>
                  <textarea rows="4" class="form-control" id="comentario"></textarea>
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cambiarEstado()">Aceptar</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
<!-- Asignar plazas -->
    <div class="modal fade" id="asignarplaza" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
                Asignar plaza para el alumno<br>
                <strong>{{ matricula }} {{ alumno }}</strong>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-12" *ngIf="!banderaPlazas">
                      <ng2-smart-table class="table table-hover table-borderless" (custom)="asignarPlaza($event)" [settings]="settingsPlazas" [source]="plazasDisponblesF"></ng2-smart-table>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              ¡Atención!
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              {{ respuesta.mensaje }}
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="documentosSolicitud" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Documentos de la solicitud
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-striped table-sm table-borderless">
              <thead class="thead-danger text-light">
                  <tr>
                    <th>Nombre</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let arch of listDocAlu">
                    <td>{{arch.archivo}}</td>
                    <td></td>
                    <td><a class="btn btn-danger btn-sm text-white" (click)="documento(arch.id)">
                      <i class="fas fa-file"></i>
                    </a></td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-xl  modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div *ngIf="!bandera">
              <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            <!-- <button type="button" class="btn btn-danger" (click)="imprimiractasporgrupo()">Imprimir Lista PDF</button> -->
          </div>
        </div>
      </div>
  </div>
  <!-- modal de seleccion de semestre -->
  <div class="modal fade" id="cierre" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Selecciona el semestre
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" id="top">
            <div class="col-12">
                <select [formControl]="cierreForm.get('semestre')" class="form-control" id="semestre">
                    <option value="">--SEMESTRE--</option>
                    <option *ngFor="let s of semestres" [value]="s.semestre">{{s.tipo}}</option>
                </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" [disabled]="this.cierreForm.invalid" (click)="confirmacion()">Enviar</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal de confirmacion -->
  <div class="modal fade" id="confirmacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Confirmación
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ¿Estas seguro de de cerrar?, al aceptar se va a <b>enviar la informacion</b> de las solicitudes
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" [disabled]="this.cierreForm.invalid" (click)="enviarCierre()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal de confirmacion de la plaza -->
    <div class="modal fade" id="aviso1" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              ¡Atención!
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ¿Asignar institución <strong>{{ institucion }}</strong> en el estado de <strong>{{ estado }}</strong> de tipo <strong>{{ tipo }}</strong> en el periodo <strong>{{ periodo }}</strong>
            al alumno <strong>{{ alumno }}</strong> con matrícula <strong>{{ matricula }}</strong>?
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarAviso()">Cerrar</button>
              <button type="button" class="btn btn-danger" (click)="asignar()">Asignar</button>
          </div>
        </div>
      </div>
    </div>
</div>