import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    usuario: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
    password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)])
  });

  roles = [];
  filtrado = [];

  // copiasforms = [];

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    // this.copias();
    localStorage.clear();
  }

  // copias(){
  //   for(var i = 0; i < 3; i++){
  //     this.copiasforms.push(this.loginForm);
  //   }
  //   for(var i = 0; i < this.copiasforms.length; i++){
  //     // console.log(this.copiasforms[i].controls.value = `HOLA${i}`);
  //     console.log(this.copiasforms[i].controls.usuario.value);
    
  //   }
  // }

  onSubmit(){
    if(this.loginForm.invalid){
      this.loginForm.setErrors({ incomplete: true });
    }else {
      this.authService.login(this.loginForm.value).subscribe({
        next: (resp) => {
          var check = resp;
          if(resp.codigo === "OK"){
            // this.authService.buscarRol(this.loginForm.controls["usuario"].value).subscribe({
            //   next:(res) => {
            //     localStorage.setItem('rol', JSON.stringify(res));
            //     this.roles = JSON.parse(localStorage.getItem("rol"));
            //     for(var i = 0; i < this.roles.length; i++){
            //       this.filtrado.push(this.roles[i].id);
            //     }
            //     localStorage.setItem("roles", JSON.stringify(this.filtrado));
            //   },
            //   error:() => {
            //     throw new Error("Error al buscar rol")
            //   }
            // })
            this.authService.personal(this.loginForm.controls["usuario"].value).subscribe({
              next:(resp) => {
                localStorage.setItem('personal', JSON.stringify(resp));
                if(check.detalle.charAt(0) === "0"){
                  this.router.navigateByUrl('/changePass');
                }
                else{
                  this.router.navigateByUrl('/inicio');
                }
              },
              error:(err) => {
                throw new Error('Error al buscar personal con matricula ingresada');
              }
            })
          } else{
            this.loginForm.setErrors({ credentials: true });
            throw new Error('Acceso Denegado, Revise sus credenciales');
          }
        },
        error: (err) => {
          if(!err.status){
            this.loginForm.setErrors({ noConnection: true });
          } else{
            this.loginForm.setErrors({ unknownError: true })
          }
        }
      })
    }
  }
 
  }
