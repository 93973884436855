import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class MaestrosGuard implements CanLoad {

  filtrado = [];
  bandera = false;

  constructor(private router: Router) { }


  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    
      this.filtrado = JSON.parse(localStorage.getItem("roles"));
      if(this.filtrado.length > 0){
        for(var i = 0; i < this.filtrado.length; i++){
          if(this.filtrado[i] == 2){
            this.bandera = true;
            break;
          }
        }
      }else{
        
      }

      if(this.bandera == true){
        return true;
      }else{
        if(this.bandera == false){
          this.router.navigateByUrl("/inicio");
          return false;
        }
      }

  }
}
