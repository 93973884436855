import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
declare var $: any;

@Component({
  selector: 'app-periodos',
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.css']
})
export class PeriodosComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };

  constructor(private sesco: SescoService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }

  abriraviso(){
    $('#aviso1').modal('show'); 
  }

  abriraviso2(){
    $('#aviso2').modal('show'); 
  }

  importarPeriodos(){
    let json = {matriculap:this.maestro.matricula};
    console.log(json);
    this.sesco.importPeriodosEscolares(json).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('hide');
      }
     });
  }

}
