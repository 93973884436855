<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <!-- <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div> -->
            <div class="col-lg-12">
                <!-- <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div> -->
                <div class="row" id="top" [hidden]="!bandera2">
                    <div class="col-lg-12" [hidden]="!bandera">
                        <div>
                            <div class="card">
                                <div class="card-body">
                                    <label><span class="text-danger">Matrícula:</span> {{ alumno.matricula }} </label> <label class="ml-2"><span class="text-danger"> Alumno:</span> {{ alumno.apellidop }} {{ alumno.apellidom }} {{ alumno.nombres }}</label> <label class="ml-2"><span class="text-danger">Semestre:</span> {{ alumno.semestre }}</label>
                                    <label class="ml-2"><span class="text-danger">Estatus:</span> {{ alumno.estatus }}</label> <label class="ml-2"><span class="text-danger">Regular:</span> {{ alumno.regular }}</label>
                                </div>
                              </div>
                        </div>
                        <div>
                            <ng-container>
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let materia of materias" [hidden]="materia.egh?.length == 0">
                                        <div>
                                            <div class="text-center">
                                                <label><span class="text-danger">{{ materia.grupo.mpe.materia.nombre }} {{ materia.grupo.alias }}</span> Semestre: <span class="text-danger">{{ materia.grupo.mpe.semestre }}</span> Grupo: <span class="text-danger">{{ materia.grupo.nombre }}</span></label><br>
                                                <label><span class="text-danger">Docente:</span> {{ materia.egh[0].maestro | titlecase }} <span class="text-danger">Correo:</span> {{ materia.egh[0].mail_maestro }}</label>
                                            </div>
                                            <div>
                                                <table class="table table-hover table-sm">
                                                    <!-- <thead>
                                                        <tr>
                                                            <th *ngFor="let dia of materia.egh">.</th>
                                                        </tr>
                                                    </thead> -->
                                                   <tbody>
                                                       <tr>
                                                           <td *ngFor="let dia of materia.egh"><span class="text-danger">{{ dia.dia }}</span></td>
                                                           <td>
                                                            <button type="button" class="close" aria-label="Close" (click)="primerAvisoEliminarMaterias(materia.grupo.mpe.id)" >
                                                                <span aria-hidden="true">&times;</span>
                                                              </button>
                                                           </td>
                                                       </tr>
                                                       <tr>
                                                           <td *ngFor="let hini of materia.egh"><span class="text-danger">I:</span> {{ hini.hinicio }}</td>
                                                       </tr>
                                                       <tr>
                                                           <td *ngFor="let hfin of materia.egh"><span class="text-danger">F:</span> {{ hfin.hfin }}</td>
                                                       </tr>
                                                       <tr>
                                                           <td *ngFor="let aula of materia.egh"><span class="text-danger">A:</span> {{ aula.aula.nombre }}</td> 
                                                       </tr>
                                                   </tbody> 
                                                </table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row" id="top" [hidden]="bandera2">
                    <div class="col-lg-10">
                        <div class="card">
                            <div class="card-body">
                                <div class="text-center">
                                    <label>¡Alumno no cuenta con horario!</label>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
                
                <!-------------------------------------------------------------------------------------------------------------------------------------------->

                        <!-- Modal primer aviso -->
                        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                                <button type="button" class="close" (click)="cerrarModal1()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    ¿Estás seguro de que deseas eliminar la materia del horario registrado?
                                </div>
                                <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" (click)="cerrarModal1()">Cerrar</button>
                                <button type="button" class="btn btn-danger" (click)="segundoAviso()">Aceptar</button>
                                </div>
                            </div>
                            </div>
                        </div>

                        <!-- Modal segundo aviso -->
                        <div class="modal fade" id="staticBackdrop2" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                                <button type="button" class="close" (click)="cerrarModal2()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    Éste proceso es permanente, ¿Seguro que deseas continuar?
                                </div>
                                <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" (click)="cerrarModal2()">Cerrar</button>
                                <button type="button" class="btn btn-danger" (click)="eliminarMateria()">Aceptar</button>
                                </div>
                            </div>
                            </div>
                        </div>

                        <!-- Modal resultado aviso -->
                        <div class="modal fade" id="staticBackdropResult" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                                <button type="button" class="close" (click)="cerrarModal3()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                <div class="modal-body">
                                    {{ response.mensaje }}
                                </div>
                                <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" (click)="cerrarModal3()">Cerrar</button>
                                </div>
                            </div>
                            </div>
                        </div>

                <!-------------------------------------------------------------------------------------------------------------------------------------------->

            </div>
        </div>
    </div>
</body>
</html>