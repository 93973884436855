import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {MateriasService} from '../services/materias.service';
declare var $: any;
@Component({
  selector: 'app-materias-catalogo',
  templateUrl: './materias-catalogo.component.html',
  styleUrls: ['./materias-catalogo.component.css']
})
export class MateriasCatalogoComponent implements OnInit {

  constructor(private materia: MateriasService) { }
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  practica = [
    {nombre: 'Si', value: 'S'},
    {nombre: 'No', value: 'N'}
  ];

  response: any;
  responseEdit: any;
  responseDelete: any;
  responseCreate: any;

  claveModal: string;
  creditosModal: string;
  horasModal: string;
  nombreModal: string;
  nombreCModal: string;
  practicaModal: boolean=true;
  acreditadaModal: boolean=false;
  idModal: number


  createForm = new FormGroup({
    clave: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    creditos: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    horas: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    nombre: new FormControl('', [Validators.required, Validators.minLength(6)]),
    nombreCorto: new FormControl('',),
    practica: new FormControl(''),
    acreditada: new FormControl('')
  });

  editform = new FormGroup({
    clave: new FormControl('', [Validators.required, Validators.maxLength(15)] ),
    creditos: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    horas: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    nombre: new FormControl('', [Validators.required, Validators.minLength(6)]),
    nombreCorto: new FormControl(''),
    practica: new FormControl(''),
    acreditada: new FormControl('')
  });
  detailsform = new FormGroup({
    clave: new FormControl('',[Validators.required, Validators.maxLength(15)] ),
    creditos: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    horas: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    nombre: new FormControl('', [Validators.required, Validators.minLength(6)]),
    nombreCorto: new FormControl(''),
    practica: new FormControl(''),
    acreditada: new FormControl('')
  });
  busqueda = new FormGroup({
    nombre: new FormControl(''),
  });

  onCreate(){
    

    let practica = (<HTMLSelectElement>document.getElementById('practica')).value;
    let acreditada = (<HTMLSelectElement>document.getElementById('acreditada')).value;
    

    
    console.log(`practica ${practica}, acreditada ${acreditada}`);
    
    this.createForm.patchValue({practica: practica, acreditada: acreditada});
    const cl = this.createForm.get('clave').value;
    const cr = this.createForm.get('creditos').value;
    const h = this.createForm.get('horas').value;
    const n = this.createForm.get('nombre').value;
    const nc = this.createForm.get('nombreCorto').value;
    const p = this.createForm.get('practica').value;
    const a = this.createForm.get('acreditada').value;
    var conf = confirm(
      `ATENCIÓN! Esta seguro de crear la materia ${n}?`
    );
    if(conf==true){
      var jason = {
        matriculap: this.maestro.matricula,
        materia: {
          clave: cl,
          creditos: cr,
          horas: h,
          nombre: n,
          nombrec: nc,
          practica: p,
          acreditada: a
        }
       }
       console.log(jason);
       this.materia.insertar(jason).subscribe({
         next: (res) => {
           this.responseCreate = res;
           if(this.responseCreate.codigo === 'OK'){
             var conf = confirm(
               `Se ha creado la materia ${n}`
             );
             $('#modalCreateMateriasForm').modal('hide'); 
             this.vaciarModal()
             this.cargarMaterias()
           }else{
             console.log(this.responseCreate);
             var conf = confirm(
               `No se pudo crear la materia`
             );
           }
           
         },
         error: () => {
           throw new Error('Error al insertar materia');
         }
       })
    }else{

    }
    
  }
  onEdit(id,nombre, nombrec,clave,creditos,practica,acreditada, horas){
    //console.log(`practica ${practica}, acreditada ${acreditada}`);
    this.idModal=id;
    if(practica ==='S'){
      this.practicaModal=true;
    }else if(practica ==='N'){
      this.practicaModal=false;
    }
    if(acreditada==='S'){
      this.acreditadaModal=true;
    }else if(acreditada ==='N'){
      this.acreditadaModal=false;
    }
    
    //console.log(`practicam ${this.practicaModal}, acreditadam ${this.acreditadaModal}`);
    
    this.editform.patchValue({
      clave: clave,
      creditos: creditos,
      horas: horas,
      nombre: nombre,
      nombreCorto: nombrec
  });
  $('#modalEditMateriasForm').modal('show'); 

  }

  onFinishEdit(){
    var conf = confirm(
      `Esta seguro de querer editar la información de esta materia??`
    );
    if(conf==true){
      var nombreInputE = (<HTMLInputElement>document.getElementById('nombreInputE')).value;
      var nombreCortoInputE = (<HTMLInputElement>document.getElementById('nombreCortoInputE')).value;
      var claveInputE = (<HTMLInputElement>document.getElementById('claveInputE')).value;
      var creditosInputE = (<HTMLInputElement>document.getElementById('creditosInputE')).value;
      var horasInputE = (<HTMLInputElement>document.getElementById('horasInputE')).value;
  
      let practica = (<HTMLSelectElement>document.getElementById('practicaE')).value;
      let acreditada = (<HTMLSelectElement>document.getElementById('acreditadaE')).value;
      console.log(`practica ${practica}, acreditada ${acreditada}`);
      
      var jason={
        matriculap: this.maestro.matricula,
        materia:{
          id:this.idModal,
          clave:claveInputE,
          creditos: creditosInputE,
          horas: horasInputE,
          nombre: nombreInputE,
          nombrec: nombreCortoInputE,
          practica: practica,
          acreditada: acreditada
        }
      }
      console.log(jason);
  
      this.materia.editar(jason).subscribe({
        next: (res) =>{
          this.responseEdit=res;
          if(this.responseEdit.codigo=='OK'){
            var conf = confirm(
              `Se editó la información de la materia satisfactoriamente.`
            );
            $('#modalEditMateriasForm').modal('hide'); 
            this.cargarMaterias();
  
          }else{
            var conf = confirm(
              this.responseEdit.mensaje
            );
          }
        },
        error:()=>{
          throw new Error("No se pudo actualizar")
        }
      })
    }else{

    }
    
    
  }
  onDelete(id: number, nombre: string){
    var jason = {
      dato: id,
      matriculap: this.maestro.matricula
    }
    console.log(jason);
    var conf = confirm(
      `ATENCION! Esta seguro de eliminar esta materia, este proceso es irreversible. Esta seguro de continuar?`
    );
    if(conf==true){
      this.materia.borrar(jason).subscribe({
        next: (res) => {
            this.responseDelete = res;
            console.log(this.responseDelete.mensaje);
            if(this.responseDelete.codigo == 'Error'){
              var conf = confirm(
                `No se pudo eliminar esta materia :(`
              );
            }else{
              var conf = confirm(
                `Se eliminó con éxito la materia "${nombre} :)"`
              );
              this.cargarMaterias()
            }
  
            console.log(this.responseDelete);   
        },
        error: () =>{
          throw new Error('Error al borrar')
        }
      })
    }else{

    }
    
       
  }
  onDetails(nombre, nombrec,clave,creditos,practica,acreditada, horas){
    var p,a;
    if(practica=='S'){
      p='Si'
    }else{
      p='No'
    }
    if(acreditada=='S'){
      a='Si'
    }else{
      a="No"
    }
    this.detailsform.patchValue({
      clave: clave,
      creditos: creditos,
      horas: horas,
      nombre: nombre,
      nombreCorto: nombrec,
      practica: p,
      acreditada: a
    });
    $('#modalDetailsMateriasForm').modal('show');

  }
  onClose(){

  }

  details(){
    
  }

  recargarlista(){

  }
  
  buscarMateria(){
    if(this.busqueda.get('nombre').value === ''){
      this.cargarMaterias();
    }else{
      var jason = {
        dato: this.busqueda.get('nombre').value,
        matriculap: this.maestro.matricula
      }
      console.log(jason);

      this.materia.buscar(jason).subscribe({
        next: (res) => {
          this.response = res;
        },
        error: () => {
          throw new Error('No se pudo buscar');
        }

      });
      
    }
  }
  cargarMaterias(){
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.materia.lista(this.maestro.matricula).subscribe({
      next: (res) => {
        this.response=res;
        console.log(this.response);
      },
      error: ()=>{
        throw new Error("Error al traer la informacion")
      }
    })
  }
  vaciarModal(){
    this.createForm.patchValue({
      clave: '',
      creditos: '',
      horas: '',
      nombre: '',
      nombreCorto: ''
  });
    this.acreditadaModal=false;
    this.practicaModal=false;
  }


  ngOnInit(): void {
    this.cargarMaterias();
  }

}
