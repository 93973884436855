import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { saveAs } from 'file-saver';
declare var $ : any;

@Component({
  selector: 'app-reporte-asignacion',
  templateUrl: './reporte-asignacion.component.html',
  styleUrls: ['./reporte-asignacion.component.css']
})
export class ReporteAsignacionComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  periodosescolares = [];
  planesactivos = [];
  src;
  type = FileMimeType.PDF;
  banderapdf: boolean = true;
  constructor(private consultas: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaPeriodos();
    this.consultar();
  }

  consultarReporte(){
    let periodo = <HTMLSelectElement>document.getElementById('plazaperiodoid2');
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    if(periodo.value == ''){

    }
    if((periodo.value != '') && (plan.value != '')){
      this.repote(periodo.value, plan.value)
    }
  }

  imprimiractasporgrupo(){
    var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
    saveAs(blob, `Reporte`);
  }

  

  repote(periodo, plan){
    this.banderapdf = true;
    this.src = '';
    this.consultas.consultarReporteAsignacion(this.maestro.matricula, periodo, plan).subscribe({
      next:(res: ArrayBuffer) =>{
        this.src = new Uint8Array(res);
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        $('#documento').modal('show');
        this.banderapdf = false;
      }
    });
  }

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next:(res: any) =>{
        this.periodosescolares = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{

      }
    });
  }

  consultar(){
    this.consultas.listaCarreras(this.maestro.matricula).subscribe({
      next:(res: any) => {
       this.planesactivos = res;
      },
      error:(err) => {
       console.log(err);
      },
      complete:() => {

      }
    });
  }

}
