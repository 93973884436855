<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div *ngIf="banderahttp">
        <div class="row">
            <div class="col-12">
                <div  class="row" id="top">
                    <div class="col-12" *ngIf="!banderaspinner">      
                        <div *ngIf="banderasinmaterias">
                            <table class="table table-sm table-striped table-hover table-borderless text-center text-sm-center">
                                <thead class="bg-danger  text-white">
                                    <tr>
                                        <th scope="col">Período</th>
                                        <th scope="col">Materia</th>
                                        <th scope="col">Grupo</th>
                                        <th scope="col">Carrera</th>
                                        <th scope="col">Aula</th>
                                        <th scope="col">Alumnos</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col" *ngIf="!banderapendientes">Pendientes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let materia of materias; let i = index">
                                        <td scope="col">{{ materia.periodo }}</td>
                                        <td scope="col">{{ materia.mpe.materia.nombre }} {{ materia.alias }}</td>
                                        <td scope="col">{{ materia.mpe.semestre }}{{ materia.nombre }}</td>
                                        <td scope="col">{{ materia.carrera }}</td>
                                        <td scope="col">{{ materia.nombreaula[0] }}</td>
                                        <td scope="col">{{ materia.insr+materia.insirr}}</td>
                                        <td scope="col">
                                            <button *ngIf="!banderaVisualizarListaPDF" class="btn btn-danger btn-sm" (click)="visualizarlistapdf(materia.mpe.materia.nombre, materia.id)">Visualizar Lista</button>
                                        </td>
                                        <td>
                                            <button *ngIf="!banderaTomarAsistencia" class="btn btn-danger btn-sm"  [routerLink]="['/grupos', materia.id]">Tomar Asistencia</button>
                                        </td>
                                        <td>
                                            <button *ngIf="!banderaImprimirAsistencia" class="btn btn-danger btn-sm"  (click)="imprimirConcentrado(materia.id)">Imprimir Asistencias</button>
                                        </td>
                                        <td>
                                            <button *ngIf="!banderaCapturarCalificaciones" class="btn btn-danger btn-sm" [routerLink]="['/cierre/calificaciones/', materia.id]">Capturar Calificaciones</button>
                                        </td>
                                        <td>
                                            <label *ngIf="materia.calificacionesenrevision != 0">{{ materia.calificacionesenrevision }}</label>
                                        </td>
                                        <!-- <td *ngIf="!banderapendientes">
                                            <div class="text-center">
                                                <label *ngIf="(!banderaasync) && (datos[i].dato > 0)" class="text-center">{{ datos[i].dato }}</label>
                                            </div>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!banderasinmaterias">
                            <h4>No cuentas con grupos asignados.</h4>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="banderaspinner">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
    </div>
    <div *ngIf="!banderahttp">
        <div class="row">
            <div class="col-lg-9">
                <app-http-errors [status]="httpstatus" [statustext]="httpstatustext" [url]="httpurl"></app-http-errors>
            </div>
        </div>
    </div>

    <div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ nombredegrupo }}</h5>
                <button type="button" class="close" aria-label="Close" (click)="cerrarpdf()">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="!banderadocumento">
                    <app-visualizar-lista-grupo-pdf [id]="this.materiaid" [matriculap]="this.maestro.matricula" ></app-visualizar-lista-grupo-pdf>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="verGrupo()" class="btn btn-danger" *ngIf="!banderaImprimirPDFListaPDF">Imprimir Lista PDF</button>
                <button type="button" class="btn btn-danger" (click)="cerrarpdf()">Aceptar</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-sm" id="loading" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="row" id="top"></div>
            <div *ngIf="bandera" class="text-center">
                <div><label>Procesamiento en curso, espere por favor.</label></div>
                <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="!bandera" class="text-center">
                <div><label>¡Documento procesado con éxito!</label></div>
            </div>
            <div class="row" id="top"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="staticBackdropCalificaciones" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title text-center" id="staticBackdropLabel">Calificaciones</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div *ngIf="!Calificaciones">
                    <app-corregir-calificacion-maestro></app-corregir-calificacion-maestro>
                </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
        </div>
    </div>

</body>
</html>