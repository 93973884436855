<div class="form-group">
    <div class="input-group-prepend">
        <span class="input-group-text"><i [class]="icon"></i></span>
        <input [type]="type" [placeholder]="placeholder" [formControl]="control" class="form-control" />
    </div>
    <!-- <div *ngIf="showErrors()" class="form-control is-invalid">
      <p *ngIf="control.errors.required">
          Campo Obligatorio
      </p> -->
      <!-- <p *ngIf="control.errors.minlength">
          Debes de ingresar un minimo de {{ control.errors.minlength.requiredLength }} caracteres.
      </p>
      <p *ngIf="control.errors.maxlength">
          No puede ser mayor a {{ control.errors.maxlength.requiredLength }} caracteres
      </p>
  </div> -->
</div>