import { SescoService } from './../services/sesco.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any
@Component({
  selector: 'app-sesco-datos-personal',
  templateUrl: './sesco-datos-personal.component.html',
  styleUrls: ['./sesco-datos-personal.component.css']
})
export class SescoDatosPersonalComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  };

  respuesta = {
    codigo:'',
    mensaje:'',
    detalle:''
  };

  busqueda = new FormGroup({
    dato: new FormControl('',Validators.required),
    todo: new FormControl(0,Validators.required)
  })
  constructor(private service : SescoService ) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }
  Actualizar(){
    $('#aceptar').modal('show');
  }
  enviar(){
    this.service.actualizarDatosPersonalSesco(this.maestro.matricula,this.busqueda.get('dato').value,this.busqueda.get('todo').value).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#aceptar').modal('hide');
        $('#respuesta').modal('show')
        this.busqueda.reset()
        this.busqueda.get('todo').patchValue(0)
      }
    });
    
  }

}
