<body>
    <form [formGroup]="alumnosform">
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-12">
                <div class="bg-danger text-white text-center">Datos Generales</div>
            </div>
        </div>
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-4">
                <div class="form-group">
                    <label for="nombres">* Nombre</label>
                    <input type="text" [formControl]="this.alumnosform.get('nombres')" class="form-control data" id="nombres">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.nombres.touched || alumnosform.controls.nombres.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.nombres.errors?.required">Ingrese un nombre.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="apellidop">Apellido Paterno</label>
                    <input type="text" [formControl]="this.alumnosform.get('apellidop')" class="form-control data" id="apellidop">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.apellidop.touched || alumnosform.controls.apellidop.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.apellidop.errors?.obligatorio">Ingrese un apellido mínimo.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="apellidom">Apellido Materno</label>
                    <input type="text" [formControl]="this.alumnosform.get('apellidom')" class="form-control data" id="apellidom">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.apellidom.touched || alumnosform.controls.apellidom.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.apellidom.errors?.obligatorio">Ingrese un apellido mínimo.</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-4">
                <div class="form-group">
                    <label for="fechanacimiento">* Fecha de Nacimiento</label>
                    <input [formControl]="this.alumnosform.get('fechanacimiento')" type="date" class="form-control data" id="fechanacimiento">
                    <!-- <input type="text" [formControl]="this.alumnosform.get('fechanacimiento')" class="form-control data" id="fechanacimiento"> -->
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.fechanacimiento.touched || alumnosform.controls.fechanacimiento.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.fechanacimiento.errors?.required">Ingrese la fecha de nacimiento.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="curp">* CURP</label>
                    <input type="text" [formControl]="this.alumnosform.get('curp')" class="form-control data" id="curp">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.curp.touched || alumnosform.controls.curp.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.curp.errors?.required">Ingrese el CURP.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.curp.errors?.minlength">La CURP no debe de ser menor de 18 caracteres.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.curp.errors?.maxlength">La CURP no debe de ser mayor de 18 caracteres.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="rfc">* RFC</label>
                    <input type="text" [formControl]="this.alumnosform.get('rfc')" class="form-control data" id="rfc">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.rfc.touched || alumnosform.controls.rfc.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.rfc.errors?.required">Ingrese la RFC.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.rfc.errors?.minlength">La RFC no debe de ser menor de 12 caracteres.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.rfc.errors?.maxlength">La RFC no debe de ser mayor de 13 caracteres.</small>
                    </div>
                </div>
            </div>
        </div>  
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-4">
                <div class="form-group">
                    <label for="matricula">* Matrícula</label>
                    <input type="text" [formControl]="this.alumnosform.get('matricula')" class="form-control data" id="matricula">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.matricula.touched || alumnosform.controls.matricula.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.matricula.errors?.required">Ingrese la matrícula.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="correo">* Correo Institucional</label>
                    <input type="text" [formControl]="this.alumnosform.get('email')" class="form-control data" id="correo">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.email.touched || alumnosform.controls.email.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.email.errors?.required">Ingrese el correo instituciónal.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.email.errors?.pattern">El correo ingresado es inválido.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="telefono">* Teléfono</label>
                    <input type="text" [formControl]="this.alumnosform.get('telefono')" class="form-control data" id="telefono">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.telefono.touched || alumnosform.controls.telefono.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.telefono.errors?.required">Ingrese el numero de telefono.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.telefono.errors?.minlength">El mínimo es de 10 digitos.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.telefono.errors?.minlength">El maxímo es de 10 digitos.</small>
                        <small class="text-danger" *ngIf="alumnosform.controls.telefono.errors?.pattern">El numero telefonico solo puede contener caracteres numericos.</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-4">
                <div class="form-group">
                    <label for="tipo">Tipo</label>
                    <select class="form-control data" [formControl]="this.alumnosform.get('tipo')" id="tipo">
                      <option *ngFor="let tipo of tipos" [value]="tipo.tipo">{{ tipo.tipo }}</option>
                    </select>
                  </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="planestudio">Plan de Estudios</label>
                    <select class="form-control data" [formControl]="this.alumnosform.get('planestudio')" id="planestudio">
                      <option *ngFor="let plan of planesdeestudios" [value]="plan.id">{{ plan.nombre }}</option>
                    </select>
                  </div>
            </div>
        </div>
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-12">
                <div class="bg-danger text-white text-center">Domicilio</div>
            </div>
        </div>
        <div class="row pt-3 pl-2 pr-2">
            <div class="col-4">
                <div class="form-group">
                    <label for="calle">* Calle</label>
                    <input type="text" [formControl]="this.alumnosform.get('calle')" class="form-control data" id="calle">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.calle.touched || alumnosform.controls.calle.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.calle.errors?.required">Ingrese la calle.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="colonia">* Colonia</label>
                    <input type="text" [formControl]="this.alumnosform.get('colonia')" class="form-control data" id="colonia">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.colonia.touched || alumnosform.controls.colonia.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.colonia.errors?.required">Ingrese el minicipio.</small>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="codigopostal">* Código Postal</label>
                    <input type="text" [formControl]="this.alumnosform.get('codigopostal')" class="form-control data" id="codigopostal">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.codigopostal.touched || alumnosform.controls.codigopostal.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.codigopostal.errors?.required">Ingrese el código postal.</small>
                    </div>
                </div>
            </div>
            <!-- <div class="col-4">
                <div class="form-group">
                    <label for="ninterior">Numero Interior</label>
                    <input type="text" [formControl]="this.alumnosform.get('ninterior')" class="form-control data" id="ninterior">
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="nexterior">* Numero Exterior</label>
                    <input type="text" [formControl]="this.alumnosform.get('nexterior')" class="form-control data" id="nexterior">
                    <div *ngIf="alumnosform.invalid && (alumnosform.controls.nexterior.touched || alumnosform.controls.nexterior.dirty)">
                        <small class="text-danger" *ngIf="alumnosform.controls.nexterior.errors?.required">Ingrese el numero exterior.</small>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row pt-3 pl-2 pr-2 pb-3">
            <div class="col-12">
                <button class="float-right btn btn-danger" [disabled]="(alumnosform.invalid)||(!banderabutton)" (click)="ingresaralumno()"> <span *ngIf="!banderabutton" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Agregar</button>
            </div>
        </div>
    </form>

    <div class="modal fade" id="respuestai" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" (click)="salir()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body" >
                {{ response.mensaje }}
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="salir()">Aceptar</button>
            </div>
        </div>
        </div>
    </div>

</body>
