<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Configuración</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-12">
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <h2>Parametros Generales</h2>
                        <table class="table table-sm table-striped table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th>Parámetro</th>
                                    <th>Valor mínimo</th>
                                    <th>Valor máximo</th>
                                    <th>Periodo</th>
                                    <th>
                                        <button class="btn btn-sm text-white" data-bs-toggle="modal" data-bs-target="#formadd" (click)="addConf()"><i class="fa fa-plus" aria-hidden="true"></i></button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of configuraciones">
                                    <td>{{c.parametro}}</td>
                                    <td>{{c.valor}}</td>
                                    <td>{{c.valor_max}}</td>
                                    <td >{{c.periodo_nombre}}</td>
                                    <td>
                                        <button class="btn btn-danger btn-sm text-center" data-bs-toggle="modal" data-bs-target="#formEdit" (click)="editConf(c.id ,c.parametro,c.valor,c.valor_max,c.periodo_id)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Modal Agregar nuevo parametro -->
                    <div id="formadd" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header bg-danger text-white">
                                    <h5 class="modal-title" id="my-modal-title">Nueva confguración</h5>
                                    <button class="close text-white" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row ">
                                        <div class="col-12 mb-2">
                                            <h6>Parametro</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.createForm.get('parametro')" name="parametro" id="parametro" aria-describedby="" placeholder="Parametro">
                                        </div>
                                        
                                        <div class="col-6">
                                            <h6>Valor minimo</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.createForm.get('valor')" name="v_min" id="v_min" aria-describedby="" placeholder="Valor minimo">
                                        </div>
                                        
                                        <div class="col-6">
                                            <h6>Valor maximo</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.createForm.get('valor_max')" name="v_max" id="v_max" aria-describedby="" placeholder="Valor maximo">
                                        </div>

                                        <div class="col-6">
                                            <h6>Periodo</h6>
                                            <div class="form-group">
                                                <select class="form-control" id="periodo"  >
                                                    <option>--- Período Escolar ---</option>
                                                    <option value="0">Todos los periodos</option>
                                                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                <button type="submit" (click)="enviarCreateForm()" [disabled]='createForm.invalid' class="btn btn-danger">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal Editar un parametro -->
                    <div id="formEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header bg-danger text-white">
                                    <h5 class="modal-title" id="my-modal-title">Editar Configuración</h5>
                                    <button class="close text-white" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="row ">
                                        <div class="col-12 mb-2">
                                            <h6>Parametro</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.editForm.get('parametro')" name="parametroE" id="parametroE" aria-describedby="" placeholder="Parametro">
                                        </div>
                                        
                                        <div class="col-6">
                                            <h6>Valor maximo</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.editForm.get('valor_max')" name="v_maxE" id="v_maxE" aria-describedby="" placeholder="Valor maximo">
                                        </div>
                                        
                                        <div class="col-6">
                                            <h6>Valor minimo</h6>
                                            <input type="text" class="form-control validate" [formControl]="this.editForm.get('valor')" name="v_minE" id="v_minE" aria-describedby="" placeholder="Valor minimo">
                                        </div>
                                        <div class="col-6">
                                            <h6>Periodo</h6>
                                            <div class="form-group">
                                                <select class="form-control" id="periodoE" >
                                                    <option>--- Período Escolar ---</option>
                                                    <option value="0">Todos los periodos</option>
                                                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                <button type="submit" (click)="enviarEditForm()" class="btn btn-danger">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal respuesta -->
                    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                {{ respuesta.mensaje }}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>