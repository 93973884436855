import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { MaestroService } from '../services/maestro.service';
import { CustomDatepickerI18nService, I18n } from '../services/custom-datepicker-i18n.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css'],
  providers: [I18n,{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class GruposComponent implements OnInit {

  model: NgbDateStruct;
  date: { year: number, month: number, day: number };
  @ViewChild('dp') dp: NgbDatepicker;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }


  alumnosGrupo;
  alumnosGrupo2;
  asistencia = [];
  grupos = [];
  grupo = [];
  fechaActual;
  alumnosEnLista = [];
  bandera = true;
  bandera2 = true;

  constructor(private maestroService: MaestroService, private route: ActivatedRoute, private router: Router, private calendar: NgbCalendar) {
   }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  enviar(){
    const id = this.route.snapshot.paramMap.get('id');  
    var fechaConsultar = {
      fecha:`${this.model.year}-${this.model.month}-${this.model.day}`,
      grupoId:id
    }
    console.log(fechaConsultar);
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    const id = this.route.snapshot.paramMap.get('id');
    this.consultarLista();
  }

  tomarLista(alumno, event){
    if(this.bandera == false){
    if(event.target.checked){
      this.asistencia.push(alumno);
      console.log(this.asistencia);
    }else{
      for(var i = 0; i < this.alumnosGrupo2.length; i++){
        if(this.asistencia[i] == alumno){
          this.asistencia.splice(i, 1);
          console.log(this.asistencia);
        }
      }
    }
    }else{
      if(event.target.checked){
        this.asistencia.push(alumno);
      }else{
        for(var i = 0; i < this.alumnosGrupo.length; i++){
          if(this.asistencia[i] == alumno){
            this.asistencia.splice(i, 1);
          }
        }
      }
    }
  }

  enviarLista(){
        
      var confirmar = confirm("¡ATENCION\n La información que estas apunto de guardar no podra ser modificada!");
      confirmar;
      if(confirmar == true){
        var filtradoMatricula = [];
        var jason;
        var grupoId = this.route.snapshot.paramMap.get('id');
        var filtradoMatriAsist = [];

    
        for( var i = 0; i < this.alumnosGrupo2.length; i++){
         filtradoMatricula.push(this.alumnosGrupo2[i].alumno.matricula);
        }
        for(var y = 0; y < this.alumnosGrupo2.length; y++){
          if(this.alumnosGrupo2[y].asistencia == 1){
            this.asistencia.push(this.alumnosGrupo2[y].alumno.matricula);
          }
        }
        console.log(this.asistencia);
        for( var x = 0; x < filtradoMatricula.length; x++){
          if(this.asistencia.includes(filtradoMatricula[x])){
            jason = {
              alumno:{
                matricula:filtradoMatricula[x]
              },
              grupo:{
                id:parseInt(grupoId)
              },
              asistencia:1,
              fecha: moment().format("YYYY-MM-DD")
            }
            filtradoMatriAsist.push(jason);
            console.log(jason)
          }
          else{
            jason = {
              alumno:{
                matricula:filtradoMatricula[x]
              },
              grupo:{
                id:parseInt(grupoId)
              },
              asistencia:0,
              fecha: moment().format("YYYY-MM-DD")
            }
            filtradoMatriAsist.push(jason);
            console.log(jason)
          }
        }
        for(var z = 0; z < filtradoMatriAsist.length; z++)
          this.maestroService.asistencia(filtradoMatriAsist[z]).subscribe({
            next:(resp) => {
              console.log(resp)
            },
            error:() => {
              throw new Error('Error al enviar lista de asistencia!');
            }
          })
          this.bandera = false;
          alert("La información se guardó exitosamente!");
          // this.router.navigateByUrl('/maestros');
      }else{
        
      }
    }

    consultarLista(){
      var alumnosCA = [];
      const id = this.route.snapshot.paramMap.get('id');
      var jason = {
        grupo:{
          id:parseInt(id)
        },
        fecha: moment().format("YYYY-MM-DD"),
        matriculap: this.maestro.matricula
      }
      this.maestroService.editarAsistencia(jason).subscribe({
        next:(resp) => {
          localStorage.setItem("lista", JSON.stringify(resp));
          this.alumnosEnLista = JSON.parse(localStorage.getItem("lista"));
          if(this.alumnosEnLista.length == 0){
            this.maestroService.alumGrps(id).subscribe({
              next:(resp) => {
                this.alumnosGrupo = resp;
                console.log(this.alumnosGrupo);
                this.grupos = JSON.parse(localStorage.getItem('grupos'));
                for( var i = 0; i < this.grupos.length; i++ ){
                  if(this.grupos[i].id == id){
                    this.grupo.push(this.grupos[i]);
                  }
                }
                this.fechaActual = moment();
              },
              error:() => {
                throw new Error('Error al encontrar alumnos de grupo');
              }
            })
          }else{
            this.bandera = false;
            this.bandera2 = false;
            this.alumnosGrupo2 = resp;
            this.grupos = JSON.parse(localStorage.getItem('grupos'));
            for( var i = 0; i < this.grupos.length; i++ ){
              if(this.grupos[i].id == id){
                this.grupo.push(this.grupos[i]);
              }
            }
            this.fechaActual = moment();
            
          }
        },
        error:() => {
          throw new Error("Error al recuperar datos");
        }
      })
    }

  }
