<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h2>Auditoria Actas y Preactas </h2> 
    <p><small class="text-danger">Datos obligatorios (*)</small></p>
    <div class="row">
        
        <div class="col-3">
            <label>Fecha Inicio</label><em class="text-danger">*</em>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="fechaI" [options]="myDpOptions" 
                    (dateChanged)="fechainicioI($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
            <div class="col-3">
            <label>Fecha Fin</label><em class="text-danger">*</em>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha Fin" 
                    angular-mydatepicker #dp2="angular-mydatepicker" 
                    [(ngModel)]="fechaF" [options]="myDpOptions" 
                    (dateChanged)="fechainicioF($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
            <div class="col-6">
            <label for="matricula">Matrícula Del Personal</label>
            <form class="form-inline">
                <div class="form-group pr-3 mb-2">
                    <input type="text" class="form-control" [formControl]="busqueda.get('matricula')" id=matricula placeholder="Matrícula">
                </div>
                <button class="btn btn-danger mb-2" [disabled]="busqueda.invalid" (click)="buscar()">Buscar</button>
                <button class="btn btn-danger  mb-2 ml-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Activar Filtros</button>

                </form>
            </div>
            <div class="col-12 mb-2">
                <div class="collapse " id="collapseExample">
                    <div class="card card-body">
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                <label for="plan">ID Grupo</label>
                                <input type="text" class="form-control" [formControl]="busqueda.get('gruId')" id=gruid placeholder="ID Grupo">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label>Período Escolar</label>
                                    <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
                                        <option>-- Períodos Escolares --</option>
                                        <option *ngFor="let periodo of periodosescolares" value="{{ periodo.id }}">{{ periodo.nombre }}</option>
                                    </select>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div *ngIf="!bandera2">
        <div class="row" *ngIf="!bandera">
            <div class="col-12">
                <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" [settings]="settings" [source]="auditoriafiltrada"></ng2-smart-table>
                <small>Numero de registros: {{contador}}</small>
            </div>
        </div>
        <div class="row" *ngIf="bandera">
            <div class="col-12">
                <div class="text-center">
                    <div class="spinner-border text-danger" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>