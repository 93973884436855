import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../../personal/services/excel.service';
import {ConfiguracionesService} from '../configuraciones.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { Global } from 'src/global';
import { saveAs } from 'file-saver';
declare var $ : any;

@Component({
  selector: 'app-calendario-examenes',
  templateUrl: './calendario-examenes.component.html',
  styleUrls: ['./calendario-examenes.component.css']
})
export class CalendarioExamenesComponent implements OnInit {
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  constructor(private configuracionesService: ConfiguracionesService, private router: Router, private global: Global, private excelService:ExcelService) { }

  
  fecha = new Date();
  fechaActual= this.fecha.getFullYear()+"-"+(this.fecha.getMonth()+1) +"-"+ this.fecha.getDate() ;
  
  access = [];
  banderaListadoFechas : boolean = true;
  banderaAgregarFechas  : boolean = true;
  banderaEditarFechas : boolean = true;
  banderaEliminarFechas : boolean = true;
  banderatabla : boolean = true;

  createForm= new FormGroup({
    idCalendario: new FormControl({value:0}),
    mpeId: new FormControl('',[Validators.required, Validators.pattern("^[0-9]+$")]),
    periodoId: new FormControl('',[Validators.required, Validators.pattern("^[0-9]+$")]),
    fechaOrdinario: new FormControl(''),
    semestre: new FormControl(''),
    fechaExtraordinario: new FormControl('')
  });
  
  semestre = [];
  carreras:any;
  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10027){
        this.banderaEliminarFechas = false;
      }
      if(this.access[i] == 10026){
        this.banderaEditarFechas = false;
      }
      if(this.access[i] == 10028){
        this.banderaAgregarFechas = false;
      }
      if(this.access[i] == 10025){
        this.banderaListadoFechas = false;
      }
    }
    this.returnBtns();
    this.carrerasActivas();
    this.listaFechas(0);
    this.listaPeriods();
    this.listaMaterias(0);
    
  }

  returnBtns(){
    if(this.banderaEditarFechas == false){
      this.btn1 = '<a class="btn btn-danger btn-sm text-white boton mr-1" title="Editar"><i class="fas fa-pencil-alt " ></i></a>';
    }
    if(this.banderaEliminarFechas == false){
      this.btn2 = '<a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>';
    }
    this.settingsExamenes.actions.custom[0].title = this.btn1;
    this.settingsExamenes.actions.custom[1].title = this.btn2;
  }

  carrerasActivas(){
    this.configuracionesService.listaCarreras(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.carreras = res;
      },
      error: ()=>{
        throw new Error('Error al cargar carreras');
      }, 
      complete: ()=>{
        this.selectPlan(0);

      }
    });
  }

  fechasExamenes:any;

  listaFechas(idPeriodo:number){
    
    this.configuracionesService.listaCalendarioExamenes(this.maestro.matricula,idPeriodo).subscribe({
      next: (res) =>{
        this.fechasExamenes = res;
      },
      error: ()=>{
        throw new Error('Error al cargar fechas');
      },
      complete: () => {
        this.banderatabla = true;
      }
    });
  }
  
  btn1 = '';
  btn2 = '';

  settingsExamenes = {
    columns:{
      periodo:{
        title:'Periodo',
        editable:false/*,         
        width:'13%'*/
      },
      carrera:{
        title:'Carrera',
        editable:false/*,         
        width:'13%'*/
      },
      semestre:{
        title:'Semestre',
        editable:false,         
        width:'7%'
      },
      materia:{
        title:'Materia',
        editable:false
      },
      fecha_ordinario:{
        title:'Fecha Ordinario',
        editable:false
      },
      fecha_extraordinario:{
        title:'Fecha Ext.',
        editable:false
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:this.btn1},
        { name:'borrar', title:this.btn2}
      ],
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }


  periodos:any;

  listaPeriods(){
    this.configuracionesService.periodos(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.periodos = res;
      },
      error: ()=>{
        throw new Error('Error al cargar períodos');
      }
    });
  }

  filtroMateria : any;
  materiasPle=[];
  banderaMateria : boolean = false;
  listaMaterias(ple){
    this.banderaMateria = false;
    // console.log("Ple: "+(<HTMLSelectElement>document.getElementById(`carrera`)).value);
    ple=(ple=="0")?'0':(<HTMLSelectElement>document.getElementById(`carrera`)).value;
    let sem = (<HTMLSelectElement>document.getElementById(`semestre`)).value
    this.configuracionesService.materiaPlanEstudio(this.maestro.matricula,ple).subscribe({
      next:(res)=>{
            this. filtroMateria=res;
      },
      error:(err)=>{
        throw new Error(err)
      },
      complete: ()=>{
        this.banderaMateria = true;
        this.materiasPle = []
        this.filtroMateria.forEach(element => {
          if(element.semestre == sem){
          this.materiasPle.push({
            id: element.id,
            materia:{
              nombre: element.materia.nombre,
            },
            semestre:element.semestre
          });
        }
        });
        
      }
    });
  };
  banderaSemestre : boolean = false;
  selectPlan(ple){
    //debugger
    this.banderaSemestre = false;
    ple=(ple=="0")?'1':(<HTMLSelectElement>document.getElementById(`carrera`)).value
    
    if(ple == ""){
      this.banderaSemestre = false;
      this.banderaMateria = false;
    }else {
      this.banderaSemestre = true;
    }
    //let plan = (<HTMLSelectElement>document.getElementById(`carrera`)).value
    this.semestre = [];
    this.materiasPle = []
    this.carreras.forEach(e => {      
      if (e.idcarrera == ple){
        
        for (let i = 1; i <= e.semestre; i++) {
          this.semestre.push({
            id: i,
            semestre:i
          })
        }
      }
    });
  }

  onCloseFechas(){
    this.semestre = [];
    this.materiasPle = []
    this.createForm.reset();
    (<HTMLSelectElement>document.getElementById(`periodoEscolar`)).disabled=false;
        (<HTMLSelectElement>document.getElementById(`carrera`)).disabled=false;
        (<HTMLSelectElement>document.getElementById(`materia`)).disabled=false;
        (<HTMLSelectElement>document.getElementById(`semestre`)).disabled=false;

  }

  responseCreate:any;
  onCreate(){
    (<HTMLSelectElement>document.getElementById(`periodoEscolar`)).disabled=false;
        (<HTMLSelectElement>document.getElementById(`carrera`)).disabled=false;
        (<HTMLSelectElement>document.getElementById(`materia`)).disabled=false;

    var jason={
      idCalendario: (this.createForm.value.idCalendario)?this.createForm.value.idCalendario:0,
      mpeId: this.createForm.value.mpeId,
      periodoId: this.createForm.value.periodoId,
      fechaOrdinario: this.createForm.value.fechaOrdinario,
      fechaExtraordinario: this.createForm.value.fechaExtraordinario,
      matriculap:this.maestro.matricula
    };

    this.configuracionesService.insertarFechas(jason).subscribe({
      next:(res)=>{
        this.responseCreate = res;
            if(this.responseCreate.codigo === 'OK'){
              var conf = confirm(
                `Se han actualizado las fechas para la materia`
              );
            }else{
              console.log(this.responseCreate);
              var conf = confirm(
                `Fechas no actualizadas`
              );
            }
      },
      error:(err)=>{
        throw new Error(err)
      }
      ,complete:()=>{
        this.listaFechas(this.createForm.get('periodoId').value);
        //$('#modalFechas').modal('hide');
        //this.createForm.reset();
        
        /*
        this.createForm.patchValue({idCalendario: 0,
        mpeId: jason.mpeId,
        periodoId: jason.periodoId,
        fechaOrdinario: jason.fechaOrdinario,
        fechaExtraordinario: jason.fechaExtraordinario});*/
        //console.log(this.createForm);
      }
    });


  }

  accion(valores){
    switch (valores.action)
    {
      case "editar": 
        this.mpeId(valores.data.mpe_id);
      //console.log(valores.data);
      
        this.createForm.patchValue({idCalendario: valores.data.idCalendarioExamen,
          mpeId: valores.data.mpe_id,
          periodoId: valores.data.periodo_id,
          fechaOrdinario: this.formatoFecha("S",valores.data.fecha_ordinario),
          fechaExtraordinario: this.formatoFecha("S",valores.data.fecha_extraordinario),
          semestre: valores.data.semestre,
          matriculap:this.maestro.matricula
        });

          $('#modalFechas').modal('show');
          break;
      case "borrar": 
          this.bajaFechaMateria(valores.data.idCalendarioExamen);
          break;
      default: 
      
        break;
    }
        

  }


  mpeId(mpeId:any){
    this.configuracionesService.materiaMpeId(this.maestro.matricula,mpeId).subscribe({
      next: (res) =>{
        var nRes=[];
        nRes[0]=res;
        this.materiasPle=[];
        this.materiasPle[0]=nRes[0];
      },
      error: ()=>{
        throw new Error('Error al cargar datos');
      },
      complete:()=>{
        (<HTMLSelectElement>document.getElementById(`periodoEscolar`)).disabled=true;
        (<HTMLSelectElement>document.getElementById(`carrera`)).disabled=true;
        (<HTMLSelectElement>document.getElementById(`materia`)).disabled=true;
        (<HTMLSelectElement>document.getElementById(`semestre`)).disabled=true;
      }
    });
  }

  
  bajaFechaMateria(id:any){
    this.configuracionesService.bajaFechaMateria(this.maestro.matricula,id).subscribe({
      next: (res) =>{
        this.responseCreate = res;
            if(this.responseCreate.codigo === 'OK'){
              var conf = confirm(
                `Se ha dado de baja la fecha de Examen`
              );
            }
      },
      error: ()=>{
        throw new Error('Error al eliminar registro');
      },
      complete:()=>{
        (<HTMLSelectElement>document.getElementById(`periodoEscolar`)).disabled=true;
        (<HTMLSelectElement>document.getElementById(`carrera`)).disabled=true;
        (<HTMLSelectElement>document.getElementById(`materia`)).disabled=true;
        this.listaFechas(0);
      }
    });
  }


  formatoFecha(tipo:any,fecha:any){
    if(tipo=='D'){
      return this.fecha.getFullYear()+"-"+(this.fecha.getMonth()+1) +"-"+ this.fecha.getDate() ;
    }else{
      var separar=[];
      if(fecha.indexOf("-")!=0){
        separar=fecha.split("-");
      }else{
        separar=fecha.split("/");
      }
      return separar[2]+"-"+separar[1]+"-"+separar[0];
    }
  }

}
