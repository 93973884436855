<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">   
                <div class="row" id="top">
                    <div class="col-lg-6">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div> 
                <div class="row" id="cuerpo"> 
                    <div class="col-lg-12">        
                        <h3>Administración de Plazas</h3>
                        <div class="md-form mb-3 col-8">
                            <!---->
                            <label data-error="wrong"  data-success="right" for="defaultForm-email">Período Escolar</label>
                            <select  class="form-control" (change)="listaPlazas()" id="plazaperiodoid2" [formControl]="this.formPeriodo.get('periodoid')">
                                <option>--Período Escolar--</option>
                                <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                            </select>
                        </div>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="plazas" data-toggle="tab" href="#Plazas" role="tab" aria-controls="Plazas" aria-selected="true">Lista de Plazas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="agregarPlaza" href="#AgregarPlaza" aria-controls="AgregarPlaza" aria-selected="false"  data-target="#modalPlaza" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
                            </li>
                        </ul>
                        <div class="tab-content " id="myTabContent">
                            <div class="tab-pane fade show active" id="plazas" role="tabpanel" aria-labelledby="plazas">
                                
                                <ng2-smart-table class="table table-hover table-borderless" [settings]="settingsPlazas" [source]="plazas" (custom)='accionPlazas($event)'></ng2-smart-table>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            
                
                   
            
        </div>
    </div>

    <!-- Modal Crear Plaza -->
    <div class="modal fade" id="modalPlaza" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <table class="table table-striped">
                    <thead class="bg-danger text-white">
                        <tr>
                            <div class="modal-header text-center">
                            <h4 class="modal-title w-100 font-weight-bold">Crear Plazas</h4>
                            <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>En Construcción!<!-- (ngSubmit)="crearPeriodo()" 
                                    <form  [formGroup]='formPlaza' >
                                        <div class="form-row">
                                            <div class="md-form mb-3 col-8">
                                                <label data-error="wrong"  data-success="right" for="defaultForm-email">Período Escolar</label>
                                                <select class="form-control" id="plazaperiodoid" [formControl]="this.formPlaza.get('periodoid')">
                                                    <option>--Período Escolar--</option>
                                                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="form-row">
                                            
                                            <div class="md-form mb-3 col-8">
                                                <label data-error="wrong"  data-success="right" for="defaultForm-email">Institucion</label>
                                                <select class="form-control" id="institucionid" [formControl]="this.formPlaza.get('institucionid')">
                                                    <option>--Institucion--</option>
                                                    <option *ngFor="let institucion of instituciones" [value]="institucion.institucionid">{{ institucion.institucion }}</option>
                                                </select>
                                            </div>
                                        </div> 
                                        
                                        <div class="form-row">
                                            
                                            <div class="md-form mb-3 col-8">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Ubicación</label>
                                                <input  type="text" min="0" class="form-control validate" [formControl]="this.formPlaza.get('ubicacion')" id="ubicacion">
                                            </div> 
                                        </div>                                          
                                        
                                        <div class="form-row">
                                            
                                            <div class="md-form mb-3 col-3">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Plazas asignadas</label>
                                                <input  type="number" min="0" (change)="sumarPlazas()" class="form-control validate" [formControl]="this.formPlaza.get('plazasasignadas')" id="plazasasignadas">
                                            </div>    
                                            <div class="md-form mb-3 col-3">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Plazas no asignadas</label>
                                                <input  type="number" min="0" (change)="sumarPlazas()" class="form-control validate" [formControl]="this.formPlaza.get('plazasanosignadas')" id="plazasanosignadas">
                                            </div>     
                                        </div>    
                                        
                                        <div class="form-row">
                                            <div class="md-form mb-4">
                                                <button (click)='limpiarPlaza()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Nuevo</button>
                                                <button [disabled]='!formPlaza.valid' id="btnAceptarPlaza" type="submit" class="btn btn-danger btn-md">Guardar</button>
                                                
                                            </div>  
                                        </div>                     
                                    </form>-->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
     
</body>
</html>


