import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';


interface atenderFinancieros{
  dato:string,
  matriculap: string
}

interface formatos{
  contenido:string,
  nombre:string,
  estado:string,
  matriculap:string,
  tipoid:string
}

interface formatosEditado{
  id:Number
  contenido:string,
  nombre:string,
  estado:string,
  matriculap:string,
  tipoid:Number
}

@Injectable({
  providedIn: 'root'
})
export class TramitesService {

  constructor(private global: Global, private http: HttpClient) {

  }

  financieroSolicitudNoListo(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/financierosolicitudesnolisto/${matricula}`, null)
  }

  financieroSolicitudListo(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/financierosolicitudeslisto/${matricula}`, null)
  }

  atenderFinancieros(datos: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/atenderfinancieros`, datos)
  }

  documentosListosEscolares(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/documentoslistosEscolares/${matricula}`, null)
  }

  documentosNoListosEscolares(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/documentosnolistosEscolares/${matricula}`, null)
  }

  atenderEscolares(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/atenderescolares`, dato);
  }

  imprimirKardex(matricula: string, matriculap: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/${matricula}/${matriculap}`);
  }

  imprimirConstancia(matricula: string, matriculap: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/${matricula}/${matriculap}`);
  }

  buscarDocEscolares(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/busqueda`, dato);
  }

  buscarDocEscolaresListos(datos: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/busquedalistos`, datos)
  }

  buscarDocFinancieros(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/busquedafinancieros`, dato)
  }
  buscarDocFinancierosNoPago(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/busquedafinancieros`, dato)
  }
  buscarDocFinancierosPago(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SolicitudDoc/busquedafinancieroslistos`, dato)
  }

  tipoId(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tipodoc/lista/${matricula}`, null)
  }

  enviarFormato(datos: formatos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/editor`, datos, { headers:this.global.headers })
  }

  listarDocumentos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/lista/${matricula}`, null)
  }

  enviarFormatoEditado(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/editar`, datos, { headers:this.global.headers })
  }

  cambiarEstadoDocumento(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/editar`, dato)
  }

  consultarEditor(dato: atenderFinancieros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/consulta`, dato)
  }

  imprimirDocument(idformato: string, matricula: string, matriculap: string, idperiodo: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/${idformato}/${matricula}/${matriculap}/${idperiodo}`)
  }

  periodosEscoalres(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/lista/${matricula}`, null)
  }

  paramestrosdeDocumento(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/editor/listaparametros/${matricula}`, null)
  }

  // http://localhost:8080/famen_escolares/Famen/editor/{idformato}/{{matricula}}/{matriculapersonal}/{idperiodo}

}
