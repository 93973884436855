<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
  <h2>Programar Inscripción</h2>
  <div class="row" id="top">
    <div class="col-3">
      <div class="form-group">
        <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
          <option>-- Períodos Escolares --</option>
          <option *ngFor="let periodo of periodoescolaractivos" value="{{ periodo.id }}">{{ periodo.nombre}}</option>
        </select>
      </div>
    </div>
    <div class="col-3" *ngIf="!banderacarrera">
      <div class="form-group">
          <select class="form-control" id="carrera" (change)="seleccionarcarrera()">
            <option>-- Carreras --</option>
            <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
          </select>
        </div>
  </div>
  </div>

  <div class="row" id="top">
    <div class="col-12" *ngIf="!banderasemestres">
        <h6>Semestres</h6>
        <div class="form-group float-left pl-2"  *ngFor="let semestres of semestresactivos">
            <div class="form-group form-check">
                <input type="checkbox" (change)="agregarsemestre(semestres.semestre, $event)" class="form-check-input bg-danger" id="semestres{{semestres.semestre}}">
                <label class="form-check-label semestres pl-2 pr-2" for="semestres{{semestres.semestre}}"> {{ semestres.semestre }}</label>
            </div>
        </div>
        <div class="form-group float-left pl-2">
            <div class="form-group form-check">
                <input (change)="selecionartodo($event)" type="checkbox" class="form-check-input bg-danger" value="0" id="todo">
                <label class="form-check-label semestres pl-2 pr-2" for="todo"> Todos</label>
            </div>
        </div>
    </div>
  </div>

  <div *ngIf="!banderapasos">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" disabled id="pills-actas-tab" data-toggle="pill" href="#pills-actas" role="tab" aria-controls="pills-actas" aria-selected="true">1)  Actas</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link active btn" id="pills-horario-grupos-tab" data-toggle="pill" href="#pills-horario-grupos" role="tab" aria-controls="pills-horario-grupos" aria-selected="false">2)  Horarios de Grupos</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" id="pills-cupos-tab" data-toggle="pill" href="#pills-cupos" role="tab" aria-controls="pills-cupos" aria-selected="false">3)  Cálculo de Cupos</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" id="pills-fechas-tab" data-toggle="pill" href="#pills-fechas" role="tab" aria-controls="pills-fechas" aria-selected="false">4)  Fechas de Inscripciones</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" id="pills-listado-tab" data-toggle="pill" href="#pills-listado" role="tab" aria-controls="pills-listado" aria-selected="false">5)  Generación de Bloques</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" id="pills-consulta-tab" data-toggle="pill" href="#pills-consulta" role="tab" aria-controls="pills-consulta" aria-selected="false">6)  Consulta de Bloques</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link btn" id="pills-horarios-tab" data-toggle="pill" href="#pills-horarios" role="tab" aria-controls="pills-horarios" aria-selected="false">7)  Descargar Horarios</button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      
      <div class="tab-pane fade" id="pills-actas" role="tabpanel" aria-labelledby="pills-actas-tab">
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label for="nombre">Período Escolar Anterior</label>
              <input disabled type="text" [value]="periodoescolaranterior.nombre" class="form-control" id="nombre" aria-describedby="período escolar">
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <input disabled type="text" [value]="periodoescolaranterior.estado" class="form-control" id="estado">
            </div>
          </div>
          <div class="col-9">
            <div *ngIf="banderacargando">
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div *ngIf="!banderacargando">
              <h6 class="text-center">Actas pendientes por firmar</h6>
              <ng2-smart-table class="table table-hover table-borderless" [settings]="settings2" [source]="grupos"></ng2-smart-table>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade  show active" id="pills-horario-grupos" role="tabpanel" aria-labelledby="pills-horario-grupos-tab">
        <div class="row">
          <div class="col-4">
            <label>Seleccione el período escolar de origen para duplicar grupos.</label>
            <select class="form-control" id="periodoescolarcompletaorigen" (change)="selecionarperiodoescolarcompletaorigen()">
              <option>-- Períodos Escolares --</option>
              <option *ngFor="let periodo of periodosescolarescompletacerradas" value="{{ periodo.id }}">{{ periodo.periodo }}</option>
            </select>
          </div>
          <div class="col-4">
            <label>Seleccione el período escolar de destino.</label>
            <select class="form-control" id="periodoescolarcompletadestino" (change)="selecionarperiodoescolarcompletadestino()">
              <option>-- Períodos Escolares --</option>
              <option *ngFor="let periodo of periodossecolarescompletaabiertas" value="{{ periodo.id }}">{{ periodo.periodo }}</option>
            </select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <button class="btn btn-danger" (click)="clonargrupos()">Duplicar</button>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-cupos" role="tabpanel" aria-labelledby="pills-cupos-tab">
        <!-- <div class="row">
          <div class="col-4">
            <label>Seleccione un plan de estudios para calcular cupos.</label>
            <select class="form-control" id="plan" (change)="selecionarplan()">
              <option>-- Plan de Estudios --</option>
              <option *ngFor="let plan of dataE" [value]="plan.id">{{ plan.nombre }}</option>
            </select>
          </div>
        </div> -->
        <!-- <h6>Haga clic para calcular cupos</h6>
        <button class="btn btn-danger" (click)="calcularcupos()">Calcular</button> -->
        <h6>Haga clic para calcular cupos</h6>
          <div class="pt-3">
            <button class="btn btn-danger" (click)="calcularcupos()">Calcular</button> 
          </div>
      </div>

      <div class="tab-pane fade" id="pills-fechas" role="tabpanel" aria-labelledby="pills-fechas-tab">

        <div class="row" *ngIf="!banderasemestres" id="top">
          <div class="col-3">
            <label>Fecha Inicio</label>
              <div class="input-group-append">
                  <input class="form-control" name="mydate" placeholder="Fecha inicio de inscripciones" 
                  angular-mydatepicker #dp="angular-mydatepicker" 
                  [(ngModel)]="fechaI" [options]="myDpOptions" 
                  (dateChanged)="fechainicioI($event)"/>
              <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                  <i class="fas fa-calendar-alt"></i>
              </button>
              </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Hora Inicio</label>
                <input type="time" class="form-control" id="horai" aria-describedby="horai">
              </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Horas por Día</label>
                <input type="number" class="form-control" id="horasd" placeholder="Horas por día">
              </div>
          </div>

          <!-- <div class="col-3">
            <label>Minutos de Duración de Bloque</label>
              <input type="number" class="form-control" id="dbloque" aria-describedby="bloque" placeholder="Duracion de bloque">
          </div> -->
        </div>
  
        <div class="row" *ngIf="!banderasemestres" id="top">
          <div class="col-3">
            <label>Alumnos por Bloque</label>
              <input type="number" class="form-control" id="abloque" aria-describedby="bloque" placeholder="Alumnos por bloque">
          </div>
            <div class="col-3">
                <div class="form-group">
                  <label>Minutos por Alumno</label>
                    <input type="number" class="form-control" id="minutosporalumno" aria-describedby="bloque" placeholder="Minutos por alumnos">
                  </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Separación por Bloque</label>
                  <input type="number" class="form-control" id="sbloque" aria-describedby="sbloque" placeholder="Separacion por bloque">
                </div>
          </div>
        </div>
  
        <div class="row" *ngIf="!banderasemestres" id="top">
          <!-- <div class="col-3">
            <div class="form-group">
              <label>Minutos entre Alumnos</label>
                <input type="number" class="form-control" id="minutosentrealumno" aria-describedby="minutosentrealumno" placeholder="Minutos entre Alumnos">
              </div>
          </div> -->
            <div class="col-3">
              <label>Tipo de Alumno</label>
              <div class="form-group">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" (change)="tipoalumnos($event)" name="inlineRadioOptions" id="regular" value="SI">
                  <label class="form-check-label" for="inlineRadio1">Regular</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" (change)="tipoalumnos($event)" name="inlineRadioOptions" id="irregular" value="NO">
                  <label class="form-check-label" for="inlineRadio2">Irregular</label>
                </div>
              </div>
            </div>

            <div class="col-3">
              <label>Acción</label>
              <div class="form-group">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" (change)="simulacion($event)" type="radio" name="inlineRadioOptions2" id="si" value="1">
                  <label class="form-check-label" for="si">Simular</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input"  (change)="simulacion($event)" type="radio" name="inlineRadioOptions2" id="no" value="0">
                  <label class="form-check-label" for="no">Programar</label>
                </div>
              </div>
            </div>

        </div>
  
        <div class="row" *ngIf="!banderasemestres" id="top">
            <div class="col-3">
                <button class="btn btn-danger mr-2" (click)="programarinscripcion()">Generar</button>
            </div>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-listado" role="tabpanel" aria-labelledby="pills-listado-tab">
        <div *ngIf="!banderatablabloques">
          <div>
            <button class="btn btn-danger mr-2" (click)="exportarAsXLSX()">Descargar Excel</button>
            <!-- <button class="btn btn-danger" (click)="verpdf()">Descargar PDF</button> -->
          </div>
          <ng2-smart-table class="table table-hover table-borderless" [settings]="settings" [source]="datafiltrada"></ng2-smart-table>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-consulta" role="tabpanel" aria-labelledby="pills-consulta-tab">
        <div class="row">
          <div class="col-3">
            <button class="btn btn-danger" (click)="consultarpdfbloques()">Consultar PDF</button>
          </div>
          <div class="col-3">
              <button class="btn btn-danger mr-2" (click)="listarBloques()">Actualizar listado</button>
          </div>
        </div>
        <ng2-smart-table class="table table-hover table-borderless" [settings]="listaBloques" [source]="listadoBloques"></ng2-smart-table>
        
      </div>

      <div class="tab-pane fade" id="pills-horarios" role="tabpanel" aria-labelledby="pills-horarios-tab">
        <div>
          <button class="btn btn-danger" (click)="consultarhorarios()">Consultar Horarios</button>
        </div>
      </div>

    </div>
  </div>

  <!-------------------------------------------------------------------------------------------------------------->
    <!-- <div class="row" id="top">
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
                  <option>-- Períodos Abiertos --</option>
                  <option *ngFor="let periodo of periodosabiertos" value="{{ periodo.id }}">{{ periodo.nombre }}</option>
                </select>
              </div>
        </div>
        <div class="col-3" *ngIf="!banderacarrera">
            <div class="form-group">
                <select class="form-control" id="carrera" (change)="seleccionarcarrera()">
                  <option>-- Carreras --</option>
                  <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
                </select>
              </div>
        </div>
    </div>

    <div class="row" id="top">
        <div class="col-12" *ngIf="!banderasemestres">
            <h6>Semestres</h6>
            <div class="form-group float-left pl-2"  *ngFor="let semestres of semestresactivos">
                <div class="form-group form-check">
                    <input type="checkbox" (change)="agregarsemestre(semestres.semestre, $event)" class="form-check-input bg-danger" id="semestres{{semestres.semestre}}">
                    <label class="form-check-label semestres pl-2 pr-2" for="semestres{{semestres.semestre}}"> {{ semestres.semestre }}</label>
                </div>
            </div>
            <div class="form-group float-left pl-2">
                <div class="form-group form-check">
                    <input (change)="selecionartodo($event)" type="checkbox" class="form-check-input bg-danger" value="0" id="todo">
                    <label class="form-check-label semestres pl-2 pr-2" for="todo"> Todos</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!banderasemestres" id="top">
        <div class="col-3">
            <div class="input-group-append">
                <input class="form-control" name="mydate" placeholder="Fecha inicio de inscripciones" 
                angular-mydatepicker #dp="angular-mydatepicker" 
                [(ngModel)]="fechaI" [options]="myDpOptions" 
                (dateChanged)="fechainicioI($event)"/>
            <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                <i class="fas fa-calendar-alt"></i>
            </button>
            </div>
        </div>
        <div class="col-3">
            <input type="number" class="form-control" id="abloque" aria-describedby="bloque" placeholder="Alumnos por bloque">
        </div>
        <div class="col-3">
            <input type="number" class="form-control" id="dbloque" aria-describedby="bloque" placeholder="Duracion de bloque">
        </div>
    </div>

    <div class="row" *ngIf="!banderasemestres" id="top">
        <div class="col-3">
            <div class="form-group">
                <input type="time" class="form-control" id="horai" aria-describedby="horai">
              </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <input type="number" class="form-control" id="horasd" placeholder="Horas por día">
              </div>
        </div>
        <div class="col-3">
            <div class="form-group">
                <input type="number" class="form-control" id="minutosporalumno" aria-describedby="bloque" placeholder="Minutos por alumnos">
              </div>
        </div>
    </div>

    <div class="row" *ngIf="!banderasemestres" id="top">
        <div class="col-3">
            <div class="form-group">
                <input type="number" class="form-control" id="sbloque" aria-describedby="sbloque" placeholder="Separacion por bloque">
              </div>
        </div>
        <div class="col-3">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="tipoalumnos($event)" name="inlineRadioOptions" id="regular" value="si">
                <label class="form-check-label" for="inlineRadio1">Regular</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" (change)="tipoalumnos($event)" name="inlineRadioOptions" id="irregular" value="no">
                <label class="form-check-label" for="inlineRadio2">Irregular</label>
              </div>
        </div>
    </div>

    <div class="row" *ngIf="!banderasemestres" id="top">
        <div class="col-3">
            <button class="btn btn-danger mr-2" (click)="programarinscripcion()">Generar</button>
        </div>
    </div> -->

  <!-------------------------------------------------------------------------------------------------------------->


    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="validacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ validacion }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuestabloques" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ¡A ocurrido un error al procesar los bloques, por favor intente de nuevo!
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="cargando" data-dissmis="modal" tabindex="-1" role="dialog"  data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
                <div class="text-center">
                    <h5 class="modal-title">Procesando</h5>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="pdf" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Bloques</h5>
              <button type="button" class="close" (click)="salir()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="!banderapdf">
                <app-pdf-bloques [bloques]="datafiltrada"></app-pdf-bloques>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="gruposcupos" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Grupos</h5>
              <button type="button" class="close"  (click)="salir2()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="!banderatablagrupos">
                <ng2-smart-table class="table table-hover table-borderless" [settings]="settings3" [source]="grupocuposfiltrados"></ng2-smart-table>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" (click)="exportaraexcel()">Exportar</button>
              <button class="btn btn-danger" (click)="salir2()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="documento" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Bloques PDF</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div *ngIf="!banderapdf2">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" (click)="imprimirdoc2()">Imprimir PDF</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>