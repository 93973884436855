import { Component, Input, OnInit } from '@angular/core';
import { InscripcionesService } from '../services/inscripciones.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-inscripciones',
  templateUrl: './inscripciones.component.html',
  styleUrls: ['./inscripciones.component.css']
})
export class InscripcionesComponent implements OnInit {

  @Input() alumno: string;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }
  response = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  materias = [];
  grupos = [];
  grupo = [];
  listadogrupos = [];
  listadogruposhorario = [];
  horarioactual = [];
  mensajesmodal = '';
  aviso = '';
  horarioFIJO = [];

  materiaempalmada1 = '';
  materiaempalmada2 = '';
  hora1 = '';
  dia1 = '';

  constructor(private InscripcionesService: InscripcionesService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.materiascursables();
    this.consultaralumnos();
  }

  materiascursables(){
    this.InscripcionesService.materiasCursables(this.alumno).subscribe({
      next: (res : any) => {
        this.materias = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete:() => {
        this.gruposhorarios();
      }
    });
  }

  gruposhorarios(){
    this.InscripcionesService.gruposHorario(this.alumno).subscribe({
      next:(res : any) => {
        this.grupos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  tomarId(materiaId: Number){
    this.grupo = this.grupos.filter( g => g.grupo.mpe.materia.id === materiaId );
    var grp = [];
      this.grupo.forEach(function(valor, index){
        let bandera = false;
        for(var i = 0; i < grp.length; i++){
          if(valor.grupo.id == grp[i].gpo_id){
            bandera = true;
            grp[i].dias.push({aula: valor.aula.nombre, dia: valor.dia, hinicio: valor.hinicio, hfin: valor.hfin, maestro: valor.maestro});
          }
        }
        if(!bandera){
          grp.push({gpo_id: valor.grupo.id, semestre: valor.grupo.mpe.semestre, gpo_nombre: valor.grupo.nombre,materia: valor.grupo.mpe.materia.nombre, maestro: valor.maestro, alias: valor.grupo.alias, dias:[{aula: valor.aula.nombre, dia: valor.dia, hinicio: valor.hinicio, hfin: valor.hfin}]});
        }
      });

    this.listadogrupos = grp;
  }

  onItemDrop(materias: any) {
    var grp = [];
    var materiasseleccionados = [];
    this.grupos.forEach(function(valor, index){
      let bandera = false;
      for(var i = 0; i < grp.length; i++){
        if(valor.grupo.id == grp[i].gpo_id){
          bandera = true;
          grp[i].dias.push({aula: valor.aula.nombre, dia: valor.dia, hinicio: valor.hinicio, hfin: valor.hfin});
        }
      }
      if(!bandera){
        grp.push({gpo_id: valor.grupo.id, semestre: valor.grupo.mpe.semestre, gpo_nombre: valor.grupo.nombre, materia: valor.grupo.mpe.materia.nombre, maestro: valor.maestro, dias:[{aula: valor.aula.nombre, dia: valor.dia, hinicio: valor.hinicio, hfin: valor.hfin}]});
        }
    });
    // console.log(materias.dragData);
    // console.log(this.horarioFIJO);    
    let validacion1 = this.empalme(this.listadogruposhorario, materias.dragData);
    // console.log(validacion1);
    if(validacion1 == true){
      let validacion2 = this.empalme(this.horarioFIJO, materias.dragData);
      // console.log(validacion2);
      if(validacion2 == true){
        this.listadogruposhorario.push(materias.dragData);  
      }else{
        //EMPALME
        $('#empalme').modal('show');
      }
    }else{
      //EMPALME
      $('#empalme').modal('show');
    }
      for(var i = 0; i < this.materias.length; i++){
        for(var x = 0; x < this.listadogruposhorario.length; x++){
          if(this.listadogruposhorario[x].materia != this.materias[i].materia.nombre){
          }else{
            materiasseleccionados.push(this.materias[i])
          }
        }
      }
      let intersextion = this.materias.filter(x => !materiasseleccionados.includes(x));
      this.materias = intersextion;
    }

    mandarMaterias(){
        var materias2 = [];
        for( var i = 0; i < this.listadogruposhorario.length; i++){
          var materias = this.listadogruposhorario[i].gpo_id;
          var jason2 = {
            dato:this.alumno,
            dato2:materias,
            dato3:this.maestro.matricula
          }
          materias2.push(jason2);
          }
          this.listadogruposhorario = [];
          this.InscripcionesService.mandarMaterias(materias2).subscribe({
          next:(res : any) => {
            this.response = res;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            if(this.response.codigo == "OK"){
              this.mensajesmodal = "Inscripción concluida satisfactoriamente."
              $('#mensaje').modal('show');
            }else{
              if(this.response.codigo == "ERROR"){
                this.mensajesmodal = "A ocurrido un error al realizar la inscripción, intentelo de nuevo."
                $('#mensaje').modal('show');
                this.materiascursables();
              }
            }
          }
        }); 
     }
  
     eliminarmateriadehorario(index){
      this.listadogruposhorario = [];
      this.materiascursables();
    }

    salir(){
      $('#mensaje').modal('hide');
    }

    salir2(){
      $('#empalme').modal('hide');
    }


    consultaralumnos(){
      var jason = {
        dato:this.alumno,
        matriculap:this.maestro.matricula
      }
      this.InscripcionesService.listarMateriasXAlumno(jason).subscribe({
        next:(data:any) => {
          this.horarioactual = data;
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          let horario = [];      
          this.horarioactual.forEach(function(valor, index){
            let bandera = false;
            // for(var i = 0; i < horario.length; i++){
            //   if(valor.grupo.id == horario[i].gpo_id){
            //     bandera = true;
            //     // horario[i].egh.push({aula: valor.aula.nombre, dia: valor.dia, hini: valor.hinicio, hfin: valor.hfin});
            //   }
            // }
            if(!bandera){
              horario.push({gpo_id: valor.grupo.id, semestre: valor.grupo.mpe.semestre, gpo_nombre: valor.grupo.nombre, materia: valor.grupo.mpe.materia.nombre, maestro: valor.grupo.maestro.persona.apellidop+" "+valor.grupo.maestro.persona.apellidom+" "+valor.grupo.maestro.persona.nombres, dias: valor.egh});
              }
          });
          this.horarioFIJO = horario;
          for(var i = 0; i < this.horarioFIJO.length; i++){
            // console.table(this.horarioFIJO[i].dias)
          }
        }
      });
    }

    empalme(grupos, materia){
      for(var grupo in grupos){
        if(grupos[grupo].gpo_id != materia.gpo_id){
          if(grupos[grupo].gpo_nombre.substring(2,30) == materia.gpo_nombre.substring(2, 30)){
            var d = grupos[grupo].dias;
            for(var dmat in materia.dias){
              var dia = materia.dias[dmat];
              var mini = moment(dia.hinicio, ['h:m a', 'H:m']);
              var mfin = moment(dia.hfin, ['h:m a', 'H:m']);
              var filtro = d.filter(d =>d.dia == dia.dia);
              for( var i = 0; i < filtro.length; i++){
                var gini = moment(filtro[i].hinicio, ['h:m a', 'H:m']);
                var gfin = moment(filtro[i].hfin, ['h:m a', 'H:m']);
                if ((mini.isBetween(gini,gfin) || mini.isSame(gini)) || (mfin.isBetween(gini,gfin) || mfin.isSame(gfin))){
                  this.materiaempalmada1 = grupos[grupo].materia + ' ' + 'Grupo ' + grupos[grupo].semestre + grupos[grupo].gpo_nombre.substring(0, 1);
                  this.materiaempalmada2 = materia.materia + ' ' + 'Grupo ' + materia.semestre + materia.gpo_nombre.substring(0, 1);
                  if(mini.isBetween(gini,gfin) == true){
                    this.hora1 = dia.hinicio;
                    this.dia1 = filtro[0].dia;
                  }else{
                    if(mini.isSame(gini)){
                      this.hora1 = dia.hinicio;
                      this.dia1 = filtro[0].dia;
                    }else{
                      if(mfin.isBetween(gini,gfin)){
                        this.hora1 = dia.hinicio;
                        this.dia1 = filtro[0].dia;
                      }else{
                        if(mfin.isSame(gfin)){
                          this.hora1 = dia.hinicio;
                          this.dia1 = filtro[0].dia;
                        }
                      }
                    }
                  }
                  return false;
                }
              }
            }
          }
        // console.log(grupos[grupo].gpo_nombre.substring(2,30) + " CONTRA " + materia.gpo_nombre.substring(2, 30));
      }else{

      }
    }
      return true;
    }

}
