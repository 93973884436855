import { Component, OnInit } from '@angular/core';
import { AdministracionService } from '../services/administracion.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import { parse } from 'path';
import { ExcelService } from 'src/app/administracion/services/excel.service';
var moment = require('moment');
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { saveAs } from 'file-saver';
declare var $ : any;


import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-primer-semestre',
  templateUrl: './primer-semestre.component.html',
  styleUrls: ['./primer-semestre.component.css']
})
export class PrimerSemestreComponent implements OnInit {
  planForm = new FormGroup({
    planid: new FormControl(0, [Validators.required]),
    fechainicio: new FormControl(0, [Validators.required]),
    fechafin: new FormControl(0, [Validators.required]),
    minutos: new FormControl(0, [Validators.required]),
  });
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }
  carrerasactivos = [];
  respuesta = {
    codigo: '',
    detalle: '',
    mensaje: ''
  };
  banderaspinner: boolean = true;
  constructor(private administracionService : AdministracionService, private excelService: ExcelService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarcarrerasactivos();
  }

  validar(){
    if(this.planForm.invalid){

    }else{
      this.planForm.get('minutos').patchValue(moment(this.planForm.get('fechafin').value).diff(this.planForm.get('fechainicio').value, 'minutes'));
      this.sembrarPrimerSemestre();
    }
  }

  sembrarPrimerSemestre(){
    let json = {
      planestudioid: this.planForm.get('planid').value,
      fechainicio: this.planForm.get('fechainicio').value,
      minutosinscripcion: this.planForm.get('minutos').value,
      matriculap: this.maestro.matricula
    };
    this.banderaspinner = false;
    this.administracionService.sembradoPrimerSemestre(json).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaspinner = true;
        $('#res').modal('show');
      }
    });
  }

  consultarcarrerasactivos(){
    this.administracionService.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carrerasactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }


}
