import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { ServicioSocialService } from '../servicio-social.service';
declare var $ : any;

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  
  settings= {
    columns:{
      formato:{
        title:'Título',
        editable:false,    
        width:'60%'
      },
      semestre:{
        title:'Semestre',
        editable:false,    
        width:'10%'
      },
      activo:{
        title:'Activo',
        editable:false,    
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  uploadForm = new FormGroup({
    formato: new FormControl('', [Validators.required]),
    file:new FormControl('', [Validators.required]),
    extension:new FormControl('', [Validators.required]),
    semestre:new FormControl(9, [Validators.required])
  });

  uploadFormEdit = new FormGroup({
    formato: new FormControl('', [Validators.required]),
    file:new FormControl('', [Validators.required]),
    extension:new FormControl('', [Validators.required]),
    semestre:new FormControl(9, [Validators.required]),
    id:new FormControl(0, [Validators.required])
  });
  estadoForm = new FormGroup({
    id: new FormControl(0, [Validators.required]),
    activo: new FormControl(0, [Validators.required])
  });
  banderatabla:boolean = true;
  banderaextension:boolean = true;
  banderatabla1:boolean = true;
  banderaextension1:boolean = true;
  doc ={
    formato: '',
    id: 0,
    semestre: 0,
    activo:''
  };
  listado= [];
  list= [];
  type = FileMimeType.PDF;
  src;
  bandertabla: boolean = true;
  banderadoc:boolean = true;
  banderaEditar:boolean = true;
  constructor(private servicioSocial: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consulta();
  }

  onFileSelect(event) {
    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   this.uploadForm.get('file').setValue(file);
    // }
    // if(this.uploadForm.get('file').value != ''){
    //   this.banderatabla = false;
    // }
    this.banderaextension = true;
    this.banderatabla = true;
    var name = event.target.files[0].name;
    if(name.slice(-4) == ".pdf"){
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.uploadForm.get('file').setValue(file);
        this.uploadForm.get('extension').patchValue('pdf')
      }
      if(this.uploadForm.get('file').value != ''){
        this.banderatabla = false;
      }
    }else{
      this.banderaextension = false;
    }
  }

  

  guardar(){
    const formdata = new FormData();
    formdata.append('semestre', this.uploadForm.get('semestre').value);
    formdata.append('formato', this.uploadForm.get('formato').value);
    formdata.append('extension', this.uploadForm.get('extension').value);
    formdata.append('file', this.uploadForm.get('file').value);
    // console.log(this.uploadForm.get('file').value);
    this.servicioSocial.subirFormatoSS(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.respuesta.codigo == "OK"){
          this.uploadForm.reset();
        }
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1000);
      }
    });
  }

  consulta(){
    this.listado = []
    this.banderatabla= false;
      let jason = {
        matricula:this.maestro.matricula
      }
      this.servicioSocial.listaFormatos(jason).subscribe({
        next:(data : any) => {
          this.list = data;
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          //console.log(this.listado);
          
          for (let i = 0; i < this.list.length; i++) {
            this.listado.push({
              formato:this.list[i].formato,
              semestre:(this.list[i].semestre!=0)?this.list[i].semestre:'Todos los semestres',
              id:this.list[i].id,
              activo:this.list[i].activo
            }) ;

          }
          this.banderatabla =true; 
        }
      });
  }
  //Acciones 
  seleccionar(e){
    this.doc = e.data;
    if(this.doc.activo=='SI'){
      this.banderaEditar= true;
    }else{
      this.banderaEditar= false;
    }
    console.log(this.doc);

    
    $('#acciones').modal('show');
  }
  cerrarmodalacciones(){
    $('#acciones').modal('hide');
  }
  
  //Ver Docuemtos 
  verDocumento(id){
    this.banderadoc = true; 
    this.servicioSocial.verDocumento(id).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#verDoc').modal('show');
        this.banderadoc = false; 
        
        // var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
        // saveAs(blob, `Documento`);
      }
    });
    
  }
  cerrarmodaldoc(){
    $('#verDoc').modal('hide');
  }
  //Editar
  editar(){
    this.uploadFormEdit.get('formato').patchValue(this.doc.formato);
    this.uploadFormEdit.get('semestre').patchValue(this.doc.semestre);
    this.uploadFormEdit.get('id').patchValue(this.doc.id);
    $('#editar').modal('show');
  }
  onFileSelectEdit(event) {
    this.banderaextension1 = true;
    this.banderatabla1 = true;
    let name = event.target.files[0].name;
    if(name.slice(-4) == ".pdf"){
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.uploadFormEdit.get('file').setValue(file);
        this.uploadFormEdit.get('extension').patchValue('pdf')
      }
      if(this.uploadFormEdit.get('file').value != ''){
        this.banderatabla1 = false;
      }
    }else{
      this.banderaextension1 = false;
    }
  }
  edit(){
    const formdata = new FormData();
    formdata.append('semestre', this.uploadFormEdit.get('semestre').value);
    formdata.append('formato', this.uploadFormEdit.get('formato').value);
    formdata.append('extension', this.uploadFormEdit.get('extension').value);
    formdata.append('file', this.uploadFormEdit.get('file').value);
    formdata.append('id', this.uploadFormEdit.get('id').value);

    this.servicioSocial.subirFormatoEditSS(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        this.banderatabla1=true;
        $('#editar').modal('hide');
        $('#acciones').modal('hide');
        if(this.respuesta.codigo == "OK"){
          this.uploadFormEdit.reset();
        }
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1000);
        this.consulta();
      }
    });
  }
  
  cerrarmodaleditar(){
    $('#editar').modal('hide');
  }
  //Activar o desactivar el formato de solicitud
  activar(id, activo){
    //debugger
    this.estadoForm.get('id').patchValue(id);
    if(activo=='SI'){
      this.estadoForm.get('activo').patchValue(1);
    }else {
      this.estadoForm.get('activo').patchValue(0);
    }
    $('#modalactivo').modal('show');
    $('#acciones').modal('hide');
  }
  cerrarmodalactivo(){
    $('#modalactivo').modal('hide');
  }
  guardarActivo(){
    this.servicioSocial.editActivo(this.estadoForm.value).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        this.banderatabla1=true;
        $('#modalactivo').modal('hide');
        //$('#acciones').modal('hide');
        if(this.respuesta.codigo == "OK"){
          this.estadoForm.reset();
        }
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1000);
        this.consulta();
      }
    });
    
  }
  //Mensaje de respuesta
  cerrarmodalrespuseta(){
    $('#respuesta').modal('hide');
  }

}
