<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="col-lg-12">   
            <div class="row" id="cuerpo"> 
                <h3>Períodos Escolares</h3>
                <div class="container-fluid">
                    <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="periodos" data-toggle="tab" href="#Periodos" role="tab" aria-controls="Periodos" aria-selected="true">Lista Períodos</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="agregarperiodo()" *ngIf="!banderaAgregarPeriodo" class="nav-link" id="agregarPeriodo" href="#AgregarPeriodo" aria-controls="AgregarPeriodo" aria-selected="false"  data-target="#modalPeriodo" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
                        </li>
                    </ul>
                    <div class="tab-content " id="myTabContent">
                        <div class="tab-pane fade show active" id="fechas" role="tabpanel" aria-labelledby="fechas">
                            <div *ngIf="banderatabla">
                                <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" [settings]="settingsPeriodos" (custom)="acciones($event)" [source]="periodos"></ng2-smart-table>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="crearperiodo" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Calendario de Período</h5>
                  <button type="button" class="close" (click)="cerraragrearperiodo()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="periodonombre">Período Escolar</label>
                                <input type="text" [formControl]="periodosForm.get('periodo')" class="form-control" id="periodonombre" aria-describedby="periodonombre">
                              </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="claveoficial">Clave Oficial</label>
                                <input type="number" maxlength="5"   [formControl]="periodosForm.get('claveoficial')" class="form-control" id="claveoficial" aria-describedby="claveoficial">
                              </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="idtipoperiodo">Tipo Período</label>
                                <select class="form-control" id="idtipoperiodo">
                                    <option *ngFor="let tipo of tipoperiodos" value="{{ tipo.value }}">{{ tipo.tipo }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="estado">Estado</label>
                            <select class="form-control" id="estado">
                                <option *ngFor="let estado of estados" value="{{ estado.value }}">{{ estado.tipo }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label for="fechai">Fecha Inicio</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha de Inicio de Período"
                                angular-mydatepicker #dp="angular-mydatepicker" 
                                [(ngModel)]="fechaI" [options]="myDpOptions" 
                                (dateChanged)="fechainicioI($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="fechaf">Fecha Fin</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha de Fin de Período"
                                angular-mydatepicker #dp2="angular-mydatepicker" 
                                [(ngModel)]="fechaF" [options]="myDpOptions" 
                                (dateChanged)="fechainicioF($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label for="fechavi">Vacaciones Inicio</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate"  placeholder="Fecha de Inicio de Vacaciones"
                                angular-mydatepicker #dp3="angular-mydatepicker" 
                                [(ngModel)]="fechaVI" [options]="myDpOptions" 
                                (dateChanged)="fechainicioVI($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp3.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="fechavf">Vacaciones Fin</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha de Fin de Vacaciones"
                                angular-mydatepicker #dp4="angular-mydatepicker" 
                                [(ngModel)]="fechaVF" [options]="myDpOptions" 
                                (dateChanged)="fechainicioVF($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp4.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-12">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="inscripcion" [checked]="periodosForm.get('inscripcion').value == 1" (change)="esinscripcioncheck($event)">
                                <label class="form-check-label" for="inscripcion">Inscripción</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" [disabled]="periodosForm.invalid" (click)="datosperiodo()">Guardar</button>
                </div>
              </div>
            </div>
        </div>


         <!-- ----------------------------------modal para agregar fecha-------------------------------------------------------------- -->
              
         <!-- <div class="modal fade" id="modalPeriodo" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <table class="table table-striped">
                        <thead class="bg-danger text-white">
                            <tr>
                                <div class="modal-header text-center">
                                <h4 class="modal-title w-100 font-weight-bold">Calendario de Período</h4>
                                <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <form  [formGroup]='periodosForm' (ngSubmit)="crearPeriodo()">

                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="crearPeriodo-tab" data-toggle="tab" href="#crearPeriodo" role="tab" aria-controls="crearPeriodo" aria-selected="false">Datos de Período</a>
                                            </li>
                                        </ul>
                    
                                          <div class="tab-content" id="myTabContent">
                                            
                                            <div class="tab-pane fade show active" id="crearPeriodo" role="tabpanel" aria-labelledby="crearPeriodo-tab">
                                                <div class="form-row"><hr></div>
                                                <div class="form-row">
                                                    <div class="md-form mb-3 col-6">
                                                        <input  type="hidden"  class="form-control validate" [formControl]="this.periodosForm.get('id')" id="id" >
                                                        <label data-error="wrong"  data-success="right" for="defaultForm-email">Período Escolar</label>
                                                        <input  type="text" class="form-control validate" [formControl]="this.periodosForm.get('periodo')" id="periodo">
                                                    </div>                              
                                                    
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Tipo Período</label>
                                                        <select id='carrera' class="form-control" [formControl]="this.periodosForm.get('idtipoperiodo')" id="idtipoperiodo">
                                                            <option value="1" >ANUAL</option>
                                                            <option value="2">SEMESTRAL</option>
                                                            <option value="3">CUATRIMESTRE</option>
                                                            <option value="4">TRIMESTRAL</option>
                                                            <option value="5">VERANO</option>
                                                            <option value="6">INVIERNO</option>
                                                        </select>
                                                    </div>                                 
                                                    
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Estado</label>
                                                        <select id='carrera' class="form-control" [formControl]="this.periodosForm.get('estado')" id="estado">
                                                            <option value="ACTUAL">ACTUAL</option>
                                                            <option value="ABIERTO">ABIERTO</option>
                                                            <option value="CERRADO" selected>CERRADO</option>
                                                        </select>
                                                    </div>   
                                                </div>

                                                <div class="form-row"><hr></div>

                                                <div class="form-row">  
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Período</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicio')" id="inicio" >
                                                    </div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Período</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('fin')" id="fin" >
                                                    </div>      
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Vacaciones</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('iniciovacaciones')" id="iniciovacaciones" >
                                                    </div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Vacaciones</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('finvacaciones')" id="finvacaciones" >
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                                    
                                            <div class="tab-pane fade" id="inscripcion" role="tabpanel" aria-labelledby="inscripcion-tab">
                                                
                                                <div class="form-row"><hr></div>
                                                <div class="form-row">   
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Insc. Regulares</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicioinscripreg')" id="inicioInscripcionRegular" >
                                                    </div>
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Insc. Regulares</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('fininscripreg')" id="finInscripcionRegular" >
                                                    </div>
                                                </div>
                                                <div class="form-row"><hr></div>
                                                <div class="form-row">
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Insc. Irregulares</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicioinscripirreg')" id="inicioInscripcionIrregular" >
                                                    </div>
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Insc. Irregulares</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicioinscripirreg')" id="finInscripcionIrregular" >
                                                    </div>
                                                </div>
                                            </div>
                                                    
                                            <div class="tab-pane fade" id="examenes" role="tabpanel" aria-labelledby="examenes-tab">
                                                <div class="form-row"><hr></div>
                                                <div class="form-row">  
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Ordinarios</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicioordinario')" id="inicioOrdinario" >
                                                    </div>
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Ordinarios</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('finordinario')" id="finOrdinario" >
                                                    </div>
                                                </div>
                                                <div class="form-row"><hr></div>
                                                <div class="form-row">
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Inicio Extraordinarios</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('inicioextraordinario')" id="inicioExtraOrdinario" >
                                                    </div>
                                                    <div class="md-form mb-3 col-2"></div>
                                                    <div class="md-form mb-3 col-3">
                                                        <label data-error="wrong" data-success="right" for="defaultForm-email">Fin Extraordinarios</label>
                                                        <input  type="date" class="form-control validate" [formControl]="this.periodosForm.get('finextraordinario')" id="finExtraOrdinario" >
                                                    </div>
                                                </div>
                                            </div>
                                                    
                                            <div class="tab-pane fade" id="x" role="tabpanel" aria-labelledby="x-tab">
                                                Datos de Examenes
                                            </div>
                                          </div>
                                
                                       <div class="form-row"><hr></div>
                                        <div class="md-form mb-4">
                                            <button  type="button" (click)="cerrarFormulario()" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                            <button [disabled]='!fechasValidas' id="btnAceptarPeriodo" type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                           
                                        </div>
                                    </form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>
</body>

    <div class="modal fade" id="validacion" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">¡Atención!</h5>
            <button type="button" class="close" (click)="cerrarvalidaciones()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                {{ aviso }}
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger"  (click)="cerrarvalidaciones()">Aceptar</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">¡Atención!</h5>
            <button type="button" class="close" (click)="cerrarrespuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                {{ response.mensaje }}
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger"  (click)="cerrarrespuesta()">Aceptar</button>
            </div>
        </div>
        </div>
    </div>

</html>


