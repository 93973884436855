import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

interface busquedaAlumno{
  dato: string,
  matriculap: string
}

@Injectable({
  providedIn: 'root'
})
export class CierreService {
  

  constructor(private http: HttpClient, private global: Global) { }

  consultaralumnos(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/consultar`, datos);
  }

  guardarsinenviar(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/guardar`, datos);
  }

  guardarpreacta(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/enviar`, datos);
  }

  tipocalificacion(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/tipocalificacion`, datos);
  }

  listaperiodosescolares(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }
  

  listarsemestres(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matriculap}/0`, null)
  }

  listarCarrera(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/planEstudioLicenciatura/${matriculap}`, null)
  }

  consultaracta(semestre, idperiodo, idcarrera, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/listaActas/${semestre}/${idperiodo}/${idcarrera}/${matriculap}`)
  }

  consultaractas(semestre, idperiodo, idcarrera, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/lista/Actas/${semestre}/${idperiodo}/${idcarrera}/${matriculap}`)
  }

  imprimirpreacta(idgrupo, idtipocalificacion){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaPreacta/${idgrupo}/${idtipocalificacion}`)
  }

  consultardatosacta(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/consultamaestro`, datos)
  }

  cierre(idperiodo, idcarrera, semestre, matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Cierre/cerrar/${idperiodo}/${idcarrera}/${semestre}/${matriculap}`, null)
  }

  firmar(matriculap, idgrupo, formdata){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Sign/${matriculap}/${idgrupo}`, formdata)
  }

  cierrepreactas(){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaPreactaCierre/generar`,  { responseType: 'arraybuffer' })
  }

  reabirircalifcaciones(matriculaA, datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/solicitarcambio/${matriculaA}`, datos)
  }
  
  listarMateriasXAlumno(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inscripcionelistamaterias`, datos)
  }

  subirdocumento(formdata){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calificaciones/upload`, formdata)
  }

  consultarcambiosolicitud(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calificaciones/consultar`, datos);
  }

  cerraractasporsemestre(periodo, carrera, semestre, firmado){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaActa/${periodo}/${carrera}/${semestre}/0/0/${firmado}`, { responseType: 'arraybuffer' })
  }

  veractasporsemestre(periodo, carrera, semestre, tipocalificacion, idgrupo, firmado){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaActa/${periodo}/${carrera}/${semestre}/${tipocalificacion}/${idgrupo}/${firmado}`, { responseType: 'arraybuffer' })
  }

  cerraractasporsemestrewindow(periodo, carrera, semestre){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaActa/${periodo}/${carrera}/${semestre}/0/0`); 
  }

  imprimiractaordinaria(idgrupo){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaPreacta/${idgrupo}/1`);
  }

  imprimiractaextraordinaria(idgrupo){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaPreacta/${idgrupo}/2`);
  }

  verconcentradoporsemestre(periodo, carrera, semestre, tipocalificacion, idgrupo){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaActa/${periodo}/${carrera}/${semestre}/${tipocalificacion}/${idgrupo}`, { responseType: 'arraybuffer' })
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }

  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }

  generarfoliosordinario(periodo, plan,matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Cierre/GeneraFoliosOrd/${periodo}/${plan}/${matriculap}`)
  }

  generarfoliosextraordinario(periodo, plan, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Cierre/GeneraFoliosExt/${periodo}/${plan}/${matriculap}`)
  }

  consultarFolios(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/foliosPreactas`, parametros);
  }

  cambiarFolios(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/foliosPreactas/editar`, parametros);
  }

  foliarInter(periodo1, periodo2, plan, matricula){
      return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Cierre/GeneraFoliosInterOrd/${periodo1}/${periodo2}/${plan}/${matricula}`)
  }

}
