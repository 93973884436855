<body>
    <div class="container-fluid">
        <!-- <div class="row" id="top"></div> -->
        <div class="row" id="top">
            <div class="col-lg-12">
              <table class="table table-sm table-hover table-borderless">
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>

        <div class="modal fade" id="aviso1" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" (click)="cerraraviso1()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Mensaje 1</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="mostraraviso2()">Aceptar</button>
                  <button type="button" class="btn btn-secondary" (click)="cerraraviso1()">Cerrar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="modal fade" id="aviso2" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" (click)="cerraraviso2()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Mensaje 2</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="cerraraviso2()">Aceptar</button>
                  <button type="button" class="btn btn-secondary" (click)="cerraraviso2()">Cerrar</button>
                </div>
              </div>
            </div>
        </div>

    </div>
</body>