import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
declare var $ : any;
var moment = require('moment');

@Component({
  selector: 'app-consultar-perido-e',
  templateUrl: './consultar-perido-e.component.html',
  styleUrls: ['./consultar-perido-e.component.css']
})
export class ConsultarPeridoEComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }
  periodosescolares = [];
  carreras = [];
  periodoselecionado = [];
  idperiodo = '';
  carreraselecionada = '';
  semestreselecionado;
  banderacarrera:boolean = true;
  banderatabla:boolean = true;
  banderaeditar:boolean = true;
  settings = {
    columns:{
      semestre:{
        title:'Semestre',
        editable:false,
        width:'10%'
      },
      ordinarios_inicio:{
        title:'Inicio de Ordinarios',
        editable:false,
        width:'10%',
      },
      ordinarios_fin:{
        title:'Fin de Ordinarios',
        editable:false,
        width:'10%',
      },
      extraordinarios_inicio:{
        title:'Inicio de Extraordinarios',
        editable:false,
        width:'10%',
      },
      extraordinarios_fin:{
        title:'Fin de Extraordinarios',
        editable:false,
        width:'10%',
      },
      especial_inicio:{
        title:'Inicio de Especial',
        editable:false,
        width:'10%'
      },
      especial_fin:{
        title:'Fin de Especial',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30,
      // custom:[
      //   { name:'selecionarDocument', title:'Solicitar' }
      // ]
    },
    noDataMessage:'Sin semestres registrados.'
  }

  examenOI = '';
  examenOF = '';
  examenEI = '';
  examenEF = '';
  examenESIN = '';
  examenESFN = '';
  examenOI_ISO = '';
  examenOF_ISO = '';
  examenEI_ISO = '';
  examenEF_ISO = '';
  examenESIN_ISO = '';
  examenESFN_ISO = '';
  examenordinarioinicio: IMyDateModel = null;
  examenordinariofin: IMyDateModel = null;
  examenextraordinarioinicio: IMyDateModel = null;
  examenextraordinariofin: IMyDateModel = null;
  preactasordinariasinicio: IMyDateModel = null;
  preactasordinariasfin: IMyDateModel = null;
  preactasextraordinariasinicio: IMyDateModel = null;
  preactasextraordinariasfin: IMyDateModel = null;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };
  datoseditar;
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  constructor(private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
  }

  editarfechas(event){
    console.log(event.data);
    this.examenordinarioinicio = {
      singleDate:{ formatted:event.data.ordinarios_inicio, date: { year: parseInt(event.data.ordinarios_inicio.substring(6)), month: parseInt(event.data.ordinarios_inicio.substring(3, 5)), day: parseInt(event.data.ordinarios_inicio.substring(0, 2)) } },
      isRange: false
    };
    this.examenordinariofin = {
      singleDate:{ formatted:event.data.ordinarios_fin, date: { year: parseInt(event.data.ordinarios_fin.substring(6)), month: parseInt(event.data.ordinarios_fin.substring(3, 5)), day: parseInt(event.data.ordinarios_fin.substring(0, 2)) } },
      isRange: false
    };
    this.examenextraordinarioinicio = {
      singleDate:{ formatted:event.data.extraordinarios_inicio, date: { year: parseInt(event.data.extraordinarios_inicio.substring(6)), month: parseInt(event.data.extraordinarios_inicio.substring(3, 5)), day: parseInt(event.data.extraordinarios_inicio.substring(0, 2)) } },
      isRange: false
    };
    this.examenextraordinariofin = {
      singleDate:{ formatted:event.data.extraordinarios_fin, date: { year: parseInt(event.data.extraordinarios_fin.substring(6)), month: parseInt(event.data.extraordinarios_fin.substring(3, 5)), day: parseInt(event.data.extraordinarios_fin.substring(0, 2)) } },
      isRange: false
    };
    this.preactasordinariasinicio = {
      singleDate:{ formatted:event.data.especial_inicio, date: { year: parseInt(event.data.especial_inicio.substring(6)), month: parseInt(event.data.especial_inicio.substring(3, 5)), day: parseInt(event.data.especial_inicio.substring(0, 2)) } },
      isRange: false
    };
    this.preactasordinariasfin = {
      singleDate:{ formatted:event.data.especial_fin, date: { year: parseInt(event.data.especial_fin.substring(6)), month: parseInt(event.data.especial_fin.substring(3, 5)), day: parseInt(event.data.especial_fin.substring(0, 2)) } },
      isRange: false
    };
    $('#editarfechas').modal('show');
    var data = {
      carrera:event.data.carrera,
      especial_inicio:this.preactasordinariasinicio.singleDate.formatted,
      especial_fin:this.preactasordinariasfin.singleDate.formatted,
      extraordinarios_fin:this.examenextraordinariofin.singleDate.formatted,
      extraordinarios_inicio:this.examenextraordinarioinicio.singleDate.formatted,
      ordinarios_fin:this.examenordinariofin.singleDate.formatted,
      ordinarios_inicio:this.examenordinarioinicio.singleDate.formatted,
      periodo:event.data.periodo,
      semestre:event.data.semestre+"",
      matriculap : this.maestro.matricula
    }
    this.datoseditar = data;
  }

  guardar(){
    $('#editarfechas').modal('hide');
    // console.log(this.datoseditar);
      this.personalService.agregarcalendarios(this.idperiodo, this.carreraselecionada, this.datoseditar).subscribe({
        next:(data : any) => {
          this.respuesta = data;
        },
        error:(err) => {
          console.log(err);
          setTimeout(() => {
            $('#respuesta22').modal('show');
          }, 1000)
        },
        complete:() => {
          setTimeout(() => {
            $('#respuesta22').modal('show');
          }, 1000)
          this.consultarcalendarios();
        }
      });
  }

  abrireditar(semestre){
    this.semestreselecionado = semestre;
    $('#editar').modal('show');
    this.banderaeditar = false;
  }

  reset(){
    this.banderaeditar = true;
  }

  selecionarperiodo(){
    let periodo = <HTMLSelectElement>document.getElementById('periodo');
    this.idperiodo = periodo.value;
    this.banderacarrera = false;
  }

  selecionarcarrera(){
    let carrera = <HTMLSelectElement>document.getElementById('carrera');
    this.carreraselecionada = carrera.value;
    this.consultarcalendarios();
  }

  consultarcalendarios(){
    this.personalService.consultarcalendarios(this.idperiodo, this.carreraselecionada).subscribe({
      next:(res : any) => {
        this.periodoselecionado = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderatabla = false;
      }
    });
  }

  consultarperiodosescolares(){
    this.personalService.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolares = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarcarreras();
      }
    });
  }

  consultarcarreras(){
    this.personalService.listarcarrara(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.carreras = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }


  // this.examenordinarioinicio = {
  //   singleDate:{ date: { year: parseInt(event.data.ordinarios_inicio.substring(6)), month: parseInt(event.data.ordinarios_inicio.substring(3, 5)), day: parseInt(event.data.ordinarios_inicio.substring(0, 2)) } },
  //   isRange: false
  // };
  // this.examenordinariofin = {
  //   singleDate:{ date: { year: parseInt(event.data.ordinarios_fin.substring(6)), month: parseInt(event.data.ordinarios_fin.substring(3, 5)), day: parseInt(event.data.ordinarios_fin.substring(0, 2)) } },
  //   isRange: false
  // };
  // this.examenextraordinarioinicio = {
  //   singleDate:{ date: { year: parseInt(event.data.extraordinarios_inicio.substring(6)), month: parseInt(event.data.extraordinarios_inicio.substring(3, 5)), day: parseInt(event.data.extraordinarios_inicio.substring(0, 2)) } },
  //   isRange: false
  // };
  // this.examenextraordinariofin = {
  //   singleDate:{ date: { year: parseInt(event.data.extraordinarios_inicio.substring(6)), month: parseInt(event.data.extraordinarios_inicio.substring(3, 5)), day: parseInt(event.data.extraordinarios_inicio.substring(0, 2)) } },
  //   isRange: false
  // };
  // this.preactasordinariasinicio = {
  //   singleDate:{ date: { year: parseInt(event.data.especial_inicio.substring(6)), month: parseInt(event.data.especial_inicio.substring(3, 5)), day: parseInt(event.data.especial_inicio.substring(0, 2)) } },
  //   isRange: false
  // };
  // this.preactasordinariasfin = {
  //   singleDate:{ date: { year: parseInt(event.data.especial_fin.substring(6)), month: parseInt(event.data.especial_fin.substring(3, 5)), day: parseInt(event.data.especial_fin.substring(0, 2)) } },
  //   isRange: false
  // };

  examenordinarioi(event){
    this.datoseditar.ordinarios_inicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenOI_ISO = moment(date).format('YYYY-MM-DD');
  }

  examenordinariof(event){
    this.datoseditar.ordinarios_fin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenOF_ISO = moment(date).format("YYYY-MM-DD");
    }

  examenextraordinarioi(event){
    this.datoseditar.extraordinarios_inicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenEI_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenextraordinariof(event){
    this.datoseditar.extraordinarios_fin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenEF_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenESI(event){
    this.datoseditar.especial_inicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenESIN_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenESF(event){
    this.datoseditar.especial_fin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenESFN_ISO = moment(date).format("YYYY-MM-DD");
  }

}
