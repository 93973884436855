import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {

  fecha = '';
  response = { codigo: '', detalle: '', mensaje: '', id: 0 }

  constructor(public sescoService: SescoService) { }

  ngOnInit(): void {
  }

  tomarFecha(event) {
    this.fecha = moment(event.target.value).format('DD-MM-yyy')
  }

  actualizarCoutaInterna() {
    $('#loading').modal('show');
    this.sescoService.actualizarCoutaInterna(this.fecha).subscribe({
      next: (response: any) => {
        this.response = response;
        $('#loading').modal('hide');
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
        $('#mensaje').modal('show')
      }
    });
  }

  actualizarCoutaExterna() {
    $('#loading').modal('show');
    this.sescoService.actualizarCoutaExterna().subscribe({
      next: (response: any) => {
        this.response = response;
        $('#loading').modal('hide');
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
        $('#mensaje').modal('show')
      }
    });
  }

}
