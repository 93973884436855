<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid" *ngIf="banderahttp">
        <div class="row">
            <div class="col-12">
                <div [hidden]="!banderaSpinner">
                    <div class="row" id="top">
                        <div class="col-12">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div [hidden]="banderaSpinner">
                    <div class="row" id="top">
                        <div class="col-lg-12">
                            <div [hidden]="bandera">
                                <div>
                                    <button *ngIf="!banderaExportarExcel" class="btn btn-danger mb-2" (click)="exportAsXLSX()"  data-toggle="tooltip" data-placement="top" title="Exportar a Excel">Exportar</button>
                                    <!-- <button *ngIf="materiasPE" class="btn btn-banger" (click)="exportAsXLSX()" data-toggle="tooltip" data-placement="top" title="Exportar a Excel"><i class="fas fa-file-excel"></i> Exportar</button> -->
                                    <ng2-smart-table (userRowSelect)="verGrupos($event)" class="table table-hover table-sm table-striped table-borderless" [settings]="settings" [source]="materiasPE"></ng2-smart-table>
                                </div>
                            </div>
                            <div [hidden]="bandera2">
                                <label>No tienes permiso o materias disponibles..</label>
                            </div>
                        </div>
                    </div> 

                    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="grupos" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">SEMESTRE:{{ datoMateria.semestre }}<br>MATERIA:{{ datoMateria.nombre }}</h5>
                                <button (click)="reset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Grupos">
                                    <app-grupos-materias></app-grupos-materias>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()" >Aceptar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" *ngIf="!banderahttp">
        <div class="row">
            <div class="col-lg-9">
                
                <app-http-errors [status]="httpstatus" [statustext]="httpstatustext" [url]="httpurl"></app-http-errors>
            </div>
        </div>
    </div>
</body>
</html>