import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class InicioService {

  constructor(private http: HttpClient, private global: Global) { }
  listaTutoriales(matriculap: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tutoriales/list/personal/${matriculap}`, null);
  }
  agregartutorial(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tutoriales/insert`, datos,{ headers:this.global.headers });
  }
  consultarroles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/buscaroles/${matricula}`, null);
  }
}
