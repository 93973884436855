<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="row" id="top">
        <div class="col-3">
          <div class="form-group">
            <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
              <option>-- Períodos Escolares --</option>
              <option *ngFor="let periodo of periodosabiertos" value="{{ periodo.id }}">{{ periodo.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="col-3" *ngIf="!banderacarrera">
          <div class="form-group">
              <select class="form-control" id="carrera" (change)="seleccionarcarrera()">
                <option>-- Carreras --</option>
                <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }} - ({{ carrera.plandeestudio }})</option>
              </select>
            </div>
        </div>
        <div class="col-3" *ngIf="!banderasemestres">
            <div class="form-group">
                <select class="form-control" id="semestre" (change)="seleccionarsemestre()">
                  <option>-- Semestre --</option>
                  <option *ngFor="let semestre of semestresactivos" value="{{ semestre.semestre }}">{{ semestre.semestre }}</option>
                </select>
              </div>
        </div>
        <div class="col-3" *ngIf="!banderabtn">
            <div class="form-group">
                <button class="btn btn-danger">Aceptar</button>
            </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>