<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container bg-white">
        <div *ngIf="!bandera2">
            <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
        </div>
        <div *ngIf="bandera2">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
        </div>
    </div>
    <!-- <button (click)="captureScreen()" class="btn btn-danger">Imprimir PDF</button> -->
    <!-- <div [hidden]="!bandera2">
        <div id="contentToConvert" class="container bg-white" *ngIf="!bandera">

            <div class="row">
                <div class="col-sm-2">
                    <div class="text-center">
                        <img src="../../../assets/img/logosin.png">
                    </div>
                </div>
                <div class="col-sm-10">
                    <div class="text-center">
                        <h2 class="lucida">Universidad Juárez del Estado de Durango</h2>
                        <h3 class="helvetica">FACULTAD DE MEDICINA Y NUTRICIÓN</h3>
                        <h4>LICENCIADO EN {{ datoskardex.carrera }}</h4>
                    </div>
                </div>
            </div>
    
            <div class="row" id="top">
    
            </div>
    
            <div class="row">
                <div class="col-sm-7 ml-2">
                    <div>
                        <h6><strong>Alumno:</strong><strong> {{ datoskardex.alumno }}</strong> </h6>
                        <h6><strong>Calle: {{ datoskardex.calle }}</strong> </h6>
                        <h6><strong>Colonia: {{ datoskardex.colonia }}</strong> </h6>
                        <h6><strong>Procede: {{ datoskardex.procede }}</strong> </h6>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div>
                        <h6><strong>Matricula: {{ datoskardex.matricula }}</strong></h6>
                        <h6><strong>Prom Materias aprob: {{ datoskardex.prommataprob }}</strong> </h6>
                        <h6><strong>Créd Aprob: {{ datoskardex.credapro }}</strong> </h6>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-sm-5 ml-2">
                    <h6><strong>Lugar de Nacimiento: Estado: {{ datoskardex.estado }}</strong> </h6>
                </div>
                <div class="col-sm-3">
                    <h6><strong>Municipio: {{ datoskardex.lugarnacimiento }}</strong> </h6>
                </div>
                <div class="col-sm-3">
                    <h6><strong>Fecha Nac: {{ datoskardex.fechanac }}</strong> </h6>
                </div>
            </div>
    
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="line">
                    </div>
                </div>
            </div>
    
            <div class="row pb-5">
                <div class="col-sm-12">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Cve:</th>
                                <th scope="col">Cred</th>
                                <th scope="col">Sem</th>
                                <th scope="col">Materia</th>
                                <th scope="col">Grupo:</th>
                                <th scope="col">Acta:</th>
                                <th scope="col">Calific.</th>
                                <th scope="col">Fecha:</th>
                                <th scope="col">Tipo Examen:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let materia of historialkardex; let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{materia.cve}}</td>
                                <td>{{materia.credito}}</td>
                                <td>{{materia.semestre}}</td>
                                <td>{{materia.materia}}</td>
                                <td>{{materia.grupo}}</td>
                                <td>{{materia.acta}}</td>
                                <td>{{materia.calificacion}}</td>
                                <td>{{materia.fecha}}</td>
                                <td>{{materia.tipoexamen}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row pt-5">
                <div class="col-sm-4">
                    <div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="line2">
                    </div>
                    <div class="text-center">
                        <strong>DR. JESÚS RAMOS GARCÍA</strong>
                    </div>
                    <div class="text-center pt-3">
                        <strong>JEFE DE DEPARTAMENTO ESCOLAR</strong>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div [hidden]="bandera2">
        <label>Sin datos disponibles.</label>
    </div> -->
</body>
</html>
