<h2>Programar Inscripción Primer Semestre</h2>
<div class="row">
    <div class="col-3">
        <form  [formGroup]="planForm">
            <div class="form-group">
                <label for="inicio">Plan de Estudios</label>
                <select [formControl]="planForm.get('planid')" class="form-control" id="carrera">
                <option value="0">-- Planes de Estudio --</option>
                <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="inicio">Fecha Inicio</label>
        <input [formControl]="planForm.get('fechainicio')" type="datetime-local" class="form-control" id="inicio">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="fin">Fecha Fin</label>
        <input [formControl]="planForm.get('fechafin')" type="datetime-local" class="form-control" id="fin">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group pt-4 mt-2">
        <button class="btn btn-danger" (click)="validar()">Generar Grupos</button>
      </div>
    </div>
</div>
<div class="row" *ngIf="!banderaspinner">
    <div class="col-12 text-center">
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
    </div>
</div>

<div class="modal fade" id="res" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ respuesta.mensaje }}<br>
          {{ respuesta.detalle }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>