<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="ml-2">
        <table class="table table-sm table-striped table-hover table-borderless text-sm">
            <thead class="bg-danger  text-white">
                <tr>
                    <th>Alumno</th>
                    <th>Fecha de la falta</th>
                    <th>Materia</th>
                    <th>Grupo</th>
                    <th>Comentarios</th>
                    <th></th>
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let j of  justificantes">
                    <td>{{j.nombreAlumno}}</td>
                    <td>{{j.fechafalta}}</td>
                    <td>{{j.materia}}</td>
                    <td>{{j.grupo}}</td>
                    <td>{{j.comentario}}</td>
                    <td>
                        <button class="btn btn-danger btn-sm ml-2 text-white text-center"  data-target="#Documento" data-toggle="modal" (click)="showDocument(j.id)">
                            <i class="far fa-file text-center"></i> 
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <div id="Documento" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h5 class="modal-title" id="my-modal-title">Archivo</h5>
                        <button class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="!bandera2">
                            <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
                        </div>
                        <div *ngIf="bandera2">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success  ml-2 text-white" >
                            <i class="far fa-check-circle"  data-target="#respuesta" data-toggle="modal"  (click)="acceptar()"> Acceptar</i> 
                        </button>
                        <button class="btn btn-danger ml-2 text-white"  data-target="#respuesta" data-toggle="modal"  (click)="denegar()">
                            <i class="far fa-times-circle"> Denegar</i> 
                        </button>
                        <button class="btn btn-secundary" data-dismiss="modal" aria-label="Close">
                        Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ respuesta.mensaje }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>
</html>