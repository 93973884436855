import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class SescoInicioComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  
  access = [];
  banderaAlumnos : boolean = true;
  banderaImportarCali : boolean = true;
  banderaEnviarCalificaciones : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtns();
  }

  viewBtns(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10129 || this.access[i] == 3){
        this.banderaAlumnos = false;
      }
      if(this.access[i] == 10132 || this.access[i] == 3){
        this.banderaImportarCali = false;
      }
      if(this.access[i] == 10133 || this.access[i] == 3){
        this.banderaEnviarCalificaciones = false;
      }
    }
  }

}
