<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-7">

                        <div class="card">
                            <div class="card-body">
                                <h3>Auditoría</h3>
                                <hr>

                                <nav>                                
                                    <a class="card-link" routerLink="/auditoria/bitacora" routerLinkActive="active">Auditoría técnica </a>
                                    <!-- <a class="card-link" routerLink="/auditoria/inscripciones" routerLinkActive="active">Inscripciones</a>
                                    <a class="card-link" routerLink="/auditoria/acceso" routerLinkActive="active">Acceso</a> -->
                                    <div class="btn-group">
                                        <a class="card-link btn btn-link dropdown-toggle shadow-none" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Auditorias Disponibles</a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" routerLink="/auditoria/acceso-personal" routerLinkActive="active">Acceso del personal</a>
                                            <a *ngIf="!banderaAAcceso" class="dropdown-item" routerLink="/auditoria/acceso" routerLinkActive="active">Acceso de alumnos </a>
                                            <a *ngIf="!banderaAlumnos" class="dropdown-item" routerLink="/auditoria/alumnos-plan-estudio" routerLinkActive="active">Alumnos</a>
                                            <a *ngIf="!banderaAInscripciones" class="dropdown-item" routerLink="/auditoria/inscripciones" routerLinkActive="active">Alumnos inscripciones</a>
                                            <a *ngIf="!banderaCalendario" class="dropdown-item" routerLink="/auditoria/calendario" routerLinkActive="active">Calendario</a>
                                            <a *ngIf="!banderaCalendariosExamenes" class="dropdown-item" routerLink="/auditoria/calendario-examenes" routerLinkActive="active">Calendarios de examenes</a>
                                            <a *ngIf="!banderaADatos" class="dropdown-item" routerLink="/auditoria/alumnos" routerLinkActive="active">Datos de alumnos</a>
                                            <a *ngIf="!banderaGrupos" class="dropdown-item" routerLink="/auditoria/grupos" routerLinkActive="active">Grupos</a>
                                            <a *ngIf="!banderaGruposHorarios" class="dropdown-item" routerLink="/auditoria/grupos-horario" routerLinkActive="active">Horario  de grupos</a>
                                            <a class="dropdown-item" routerLink="/auditoria/materias" routerLinkActive="active">Materias</a>
                                            <a *ngIf="!banderaNotificaciones" class="dropdown-item" routerLink="/auditoria/notificaciones" routerLinkActive="active">Notificaciones</a>
                                            <a class="dropdown-item" routerLink="/auditoria/pagos" routerLinkActive="active">Pagos</a> 
                                            <a class="dropdown-item" routerLink="/auditoria/permisos" routerLinkActive="active">Permisos</a>
                                            <a *ngIf="!banderaPreactas" class="dropdown-item" routerLink="/auditoria/preactas" routerLinkActive="active">Preactas</a>
                                            <a class="dropdown-item" routerLink="/auditoria/roles" routerLinkActive="active">Roles</a>
                                            <a class="dropdown-item" routerLink="/auditoria/usuarios-roles" routerLinkActive="active">Roles de usuarios</a>
                                            <a class="dropdown-item" routerLink="/auditoria/semestres" routerLinkActive="active">Semestres del personal</a> 
                                            <a class="dropdown-item" routerLink="/auditoria/usuarios" routerLinkActive="active">Usuarios</a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>