import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
declare var $ : any;

@Component({
  selector: 'app-becas',
  templateUrl: './becas.component.html',
  styleUrls: ['./becas.component.css']
})
export class BecasComponent implements OnInit {

  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  becasdealumno = [];
  becasdisponibles = [];
  periodosescolares = [];
  alumno;
  periodoselecionado;
  becaselecionada;
  banderaalumno:boolean = true;
  banderasinmatricula:boolean = true;
  banderasinresultados:boolean = true;
  banderaresultados:boolean = true;
  banderabecas:boolean = true;
  banderamodal:boolean = true;
  banderamodal2:boolean = true;
  banderatablabecas:boolean = true;
  idbeca;

  today = new Date();
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();

  // this.today = this.mm + '/' + this.dd + '/' + this.yyyy;

  constructor(private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolar();
  }

  consultarbecasalumno(){
    var jason = {
      dato:this.periodoselecionado,
      dato2:this.alumno.matricula
    }
    this.personalService.listarbecasdealumno(jason).subscribe({
      next:(data : any) => {
        this.becasdealumno = data;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {

      }
    });
  }

  consultarbecas(){
    this.personalService.listarbecas().subscribe({
      next:(data : any) => {
        this.becasdisponibles = data;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        this.banderabecas = false;
        this.banderaresultados = false;
      }
    });
  }

  selecionarbeca(){
    var beca = <HTMLSelectElement>document.getElementById('becas');
    this.becaselecionada = beca.value;
    for(var i = 0; i < this.becasdisponibles.length; i++){
      if(this.becaselecionada == this.becasdisponibles[i].id){
        this.becaselecionada = this.becasdisponibles[i];
      }
    }
    $('#avisobeca').modal('show');
    this.banderamodal = false;
  }

  asingarbecas(){
    var jason = {
      periodo:parseInt(this.periodoselecionado),
      beca:{
        becId:this.becaselecionada.id
      },
      alumno:{
        matricula:this.alumno.matricula
      },
      f_inicio: this.dd + '-' + this.mm + '-' + this.yyyy,
      f_fin: this.dd + '-' + this.mm + '-' + this.yyyy,
    }
    this.personalService.agregarbeca(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  eliminarbeca(id){
    this.idbeca = id;
    for(var i = 0; i < this.becasdisponibles.length; i++){
      if(id == this.becasdisponibles[i].id){
        this.becaselecionada = this.becasdisponibles[i];
      }
    }
    $('#elimnarbeca').modal('show');
    this.banderamodal2 = false;
  }

  eliminarbecas(){
    this.personalService.eliminarbeca(this.idbeca).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  consultarperiodoescolar(){
    this.personalService.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        
      }
    });
  }

  consultaralumno(){
    this.banderasinresultados = true;
    this.banderasinmatricula = true;
    var periodoescolar = <HTMLSelectElement>document.getElementById('periodoescolares');
    var alumno = <HTMLSelectElement>document.getElementById('matricula');
    if(alumno.value != ""){
      this.personalService.alumno(alumno.value).subscribe({
        next:(data : any) => {
          this.alumno = data;
        },
        error:(err) => {
          this.httpstatus = err.status;
          this.httpstatustext = err.statusText;
          this.httpurl = err.url;
          this.banderahttp = false;
        },
        complete:() => {
          if((this.alumno == null) || (this.alumno == undefined)){
            this.banderasinresultados = false;
          }else{
            this.consultarbecas();
            this.consultarbecasalumno();
            alumno.value = '';
            periodoescolar.selectedIndex = 0;

          }
        }
      });
    }else{
      this.banderasinmatricula = false;
    }
  }

  periodoselecionadof(){
    var periodoescolar = <HTMLSelectElement>document.getElementById('periodoescolares');
    this.periodoselecionado = periodoescolar.value;
    if(this.periodoselecionado != ""){
      this.banderaalumno = false;
    }else{
      this.banderaalumno = true;
    }
  }

}
