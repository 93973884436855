import { Component, OnInit } from '@angular/core';
import { CierreService } from '../services/cierre.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

declare var $ : any;

@Component({
  selector: 'app-corregir-calificacion-escolares',
  templateUrl: './corregir-calificacion-escolares.component.html',
  styleUrls: ['./corregir-calificacion-escolares.component.css']
})
export class CorregirCalificacionEscolaresComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  alumno = {
    apellidom:'',
    apellidop:'',
    estatus:'',
    matricula:'',
    nombres:'',
    regular:'',
    semestre:'',
    carrera:''
  };

  response = {
    codigo:'',
    mensaje:'',
    detalle:''
  }

  response2 = {
    codigo:'',
    mensaje:'',
    detalle:''
  }

  matriculaAlumno;
  materias;
  idgrupo;
  firmas = [];
  tabla = [];
  materia;
  alumnocompleto = JSON.parse(localStorage.getItem('alumnocompleto'));
  banderamateria:boolean = true;
  banderatabla:boolean = true;
  banderacalificacion:boolean = true;
  banderabtn:boolean = true;
  uploadForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private cierreservice: CierreService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.matriculaAlumno = localStorage.getItem('alumno');
    this.alumno = JSON.parse(localStorage.getItem('alumnocompleto'));
    this.consultarmaterias();
    this.uploadForm = this.formBuilder.group({
      documento: ['', Validators.required],
    });
  }

  consultarfirmas(){
    for(var i = 0; i < this.materias.length; i++){
      var jason = {
        dato:this.materias[i].grupo.id,
        matriculap:this.maestro.matricula
      }
      this.cierreservice.consultardatosacta(jason).subscribe({
        next:(data) => {
          this.firmas.push(data);
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
            
          // if(this.materias.length == this.firmas.length){
          //   for(var i = 0; i < this.materias.length; i++){
          //     for(var x = 0; x < this.firmas.length; x++){
          //       if(this.materias[i].grupo.id == this.firmas[x].id){
          //         let datos = {
          //           firmado:this.firmas[x].firmado,
          //           id:this.materias[i].grupo.id,
          //           nombre:this.materias[i].grupo.mpe.materia.nombre,
          //           semestre:this.materias[i].grupo.mpe.semestre,
          //           grupo:this.materias[i].grupo.nombre,
          //           apellidop:this.materias[i].grupo.maestro.persona.apellidop,
          //           apellidom:this.materias[i].grupo.maestro.persona.apellidom,
          //           nombres:this.materias[i].grupo.maestro.persona.nombres,
          //           matricula:this.materias[i].grupo.maestro.persona.matricula,
          //           calificacion:this.materias[i].calificacion
          //         }
          //         this.tabla.push(datos);
          //       }
          //     }
          //   }
          //   for(var j = 0; j < this.tabla.length; j++){
          //     if(this.tabla[j].calificacion != 0){
          //       this.banderacalificacion = false;
          //       break;
          //     }
          //   }
          //   for(var a = 0; a < this.tabla.length; a++){
          //     if(this.tabla[a].calificacion != 0){
          //       this.banderabtn = false;
          //       break;
          //     }
          //   }
          //   this.banderatabla = false;
          // }
        }
      });
    }
  }

  onFileSelect(event){
    if (event.target.files.length > 0) {
      const documento = event.target.files[0];
      this.uploadForm.get('documento').setValue(documento);
    }
  }

  cerrarrespuesta(){
    $('#avisorespuesta').modal('hide');
  }

  cerrarrespuesta2(){
    $('#avisorespuesta2').modal('hide');
  }

  subirdocumento(){
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('documento').value);
    formData.append('grupo', this.idgrupo);
    formData.append('matricula', this.matriculaAlumno);
    formData.append('matriculap', this.maestro.matricula);
    formData.append('tipo', '');
    $('#subirdocumento').modal('hide');
    this.cierreservice.subirdocumento(formData).subscribe({
      next:(res : any) => {
        this.response = res;
      },
      error:(err) => {
        console.log(err);
        $('#avisorespuesta').modal('show');
      },
      complete:() => {
        if(this.response.codigo == "OK"){
          this.abrircalificacion();
        }else{
          $('#avisorespuesta').modal('show');
        }
      }
    });
  }

  abrircalificacion(){
    var datos = {
      matriculap:this.maestro.matricula,
      dato:this.idgrupo
    }
    this.cierreservice.reabirircalifcaciones(this.matriculaAlumno, datos).subscribe({
      next:(res : any) => {
        this.response2 = res;
      },
      error:(err) => {
        console.log(err);
        $('#avisorespuesta').modal('show');
      },
      complete:() => {
          $('#avisorespuesta2').modal('show');
      }
    });
  }

  consultarmaterias(){
    var jason = {
      dato:this.matriculaAlumno,
      matriculap:this.maestro.matricula,
      hr: 'D'
    }
    this.cierreservice.listarMateriasXAlumno(jason).subscribe({
      next:(res) => {
        this.materias = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.materias.length; i++){
          let datos = {
            firmado:this.materias[i].grupo.firmado,
            id:this.materias[i].grupo.id,
            nombre:this.materias[i].grupo.mpe.materia.nombre,
            semestre:this.materias[i].grupo.mpe.semestre,
            grupo:this.materias[i].grupo.nombre,
            apellidop:this.materias[i].grupo.maestro.persona.apellidop,
            apellidom:this.materias[i].grupo.maestro.persona.apellidom,
            nombres:this.materias[i].grupo.maestro.persona.nombres,
            matricula:this.materias[i].grupo.maestro.persona.matricula,
            calificacion:this.materias[i].calificacion,
            revision:this.materias[i].revision
          }
          console.log(datos);
          this.tabla.push(datos);
      }
      for(var j = 0; j < this.tabla.length; j++){
        if(this.tabla[j].calificacion != 0.0){
          this.banderacalificacion = false;
          break;
        }
      }
      for(var a = 0; a < this.tabla.length; a++){
        if(this.tabla[a].calificacion == 0.0 || this.tabla[a].calificacion > 0.0){
          this.banderabtn = false;
          break;
        }
      }
      this.banderatabla = false;
      }
    });
  }

  mostraraviso1(materia, id){
    this.materia = materia; 
    this.idgrupo = id;
    $('#aviso1').modal('show');
    this.banderamateria = false;
  }

  cerraraviso1(){
    $('#aviso1').modal('hide');
  }

  cerraraviso2(){
    $('#aviso2').modal('hide');
  }

  mostraraviso2(){
    $('#aviso1').modal('hide');
    setTimeout(() => {
      $('#aviso2').modal('show');
    },1000);  
  }

  cerrarmodaldocumento(){
    $('#subirdocumento').modal('hide');
  }  

  abrirmodaldocumento(){
    $('#aviso2').modal('hide');
    setTimeout(() => {
      $('#subirdocumento').modal('show');
    },1000);
  }  

}
