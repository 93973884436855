<div [formGroup]="busquedaForm" class="row">
    <div class="col-5">
        <div class="form-group">
            <select [formControl]="busquedaForm.get('periodoid')" class="form-control" id="periodo">
                <option value="">--Período Escolar--</option>
                <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
            </select>
        </div>
    </div>
    <div class="col-5">
        <div class="form-group">
            <select [formControl]="busquedaForm.get('planestudioid')" class="form-control" id="plan">
                <option value="">--Plan de Estudios--</option>
                <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
            </select>
        </div>
    </div>
    <div class="col-2">
        <button class="btn btn-danger btn-block" (click)="consultarliberaciones()">Buscar</button>
    </div>
</div>
<div class="row" *ngIf="!banderatabla">
    <div class="col-12">
        <ng2-smart-table (userRowSelect)="abirtareas($event)" class="table table-hover table-borderless table-striped"  [settings]="settings" [source]="liberaciones"></ng2-smart-table>
    </div>
</div>

<div class="modal fade" id="t" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
            Matrícula: {{ alumno.matricula  }}<br> {{ alumno.alumno }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="consultarpdfliberacion()">Formato Liberación</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="formato" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
            Formato Liberación
            Matrícula: {{ alumno.matricula  }}<br> {{ alumno.alumno }}
            </h5>
            <button type="button" class="close" (click)="cerrarpdf()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderapdf">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="cerrarpdf()">Salir</button>
        </div>
        </div>
    </div>
</div>