<div>
    <h4>Configuración</h4>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="configuraciones" data-toggle="tab" href="#configuraciones" role="tab" aria-controls="configuraciones" aria-selected="true">Lista de configuracion</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="agregarConf" href="#AgregarConf" aria-controls="AgregarConf" aria-selected="false"  data-target="#modalconf" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
        </li>
    </ul>
    <div class="tab-content " id="myTabContent">
        <div class="tab-pane fade show active" id="configuraciones" role="tabpanel" aria-labelledby="configuraciones">
            <div *ngIf="!banderatabka">
                <ng2-smart-table class="table table-hover table-borderless" (userRowSelect)="seleccionar($event)" [settings]="settings" [source]="conf"></ng2-smart-table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalconf" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Configuracion</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div class="modal-body">

                    <ul class="nav nav-tabs" id="myTab1" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="tab-internado" data-toggle="tab" href="#nav-internado" role="tab" aria-controls="nav-internado" aria-selected="true">Internado</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-sersoc" data-toggle="tab" href="#nav-sersoc" role="tab" aria-controls="nav-sersoc" aria-selected="false" >Servicio Social</a>
                        </li>
                    </ul>

                    <div class="tab-content " id="myTabContent1">
                        <div class="tab-pane fade show active " id="nav-internado" role="tabpanel" aria-labelledby="tab-internado">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="nombre">Promoción de internado</label>
                                        <input type="text" [formControl]="formConf.get('promocion')" class="form-control" id="nombre" placeholder="Promoción">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="estado">Periodo</label>
                                        <select class="form-control" id="estado" [formControl]="formConf.get('periodoid')">
                                            <option *ngFor="let per of periodosescolares" [value]="per.id">{{ per.nombre }}</option>
                                        </select>                        
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                    <label for="plan">Plan de Estudio</label>
                                    <select [formControl]="formConf.get('pleid')" class="form-control" id="plan">
                                        <option value="">--Plan de Estudios--</option>
                                        <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fii">Fecha inicio internado</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_inicio_i')" id="ffi" placeholder="Fecha inicio internado">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ffi">Fecha fin internado</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_fin_i')" id="ffi" placeholder="Fecha fin internado">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fiss">Fecha inicio de realización de internado</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_inicio_realiza_i')" id="fiss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ffss">Fecha fin de realización de internado</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_fin_realiza_i')" id="ffss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ffss">Fecha asignación internado</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_asignacion_i')" id="ffss" placeholder="Fecha asignación internado">
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                        
                        <!-- Tab de servicio social -->

                        <div class="tab-pane fade" id="nav-sersoc" role="tabpanel" aria-labelledby="tab-sersoc">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="ss">Servicio social</label>
                                        <input type="text" [formControl]="formConf.get('ss')" class="form-control" id="ss" placeholder="Servicio social">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fiss">Fecha inicio servicio social</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_inicio_ss')" id="fiss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ffss">Fecha fin servicio social</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_fin_ss')" id="ffss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fiss">Fecha inicio de realización de servicio social</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_inicio_realiza_ss')" id="fiss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ffss">Fecha fin de realización de servicio social</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_fin_realiza_ss')" id="ffss" placeholder="Fecha fin servicio social">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="fiss">Fecha asignación servicio social</label>
                                        <input type="date" class="form-control" [formControl]="formConf.get('fecha_asignacion_ss')" id="fiss" placeholder="Fecha a servicio social">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-danger" [disabled]="formConf.invalid "data-dismiss="modal" (click)="guardar()">Guardar</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalconfedit" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Configuración</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <ul class="nav nav-tabs" id="myTab2" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="tab-internado-edit" data-toggle="tab" href="#nav-internado-edit" role="tab" aria-controls="nav-internado-edit" aria-selected="true">Internado</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-sersoc-edit" data-toggle="tab" href="#nav-sersoc-edit" role="tab" aria-controls="nav-sersoc-edit" aria-selected="false" >Servicio Social</a>
                        </li>
                    </ul>

                        <div class="tab-content " id="myTabContent2">
                            <div class="tab-pane fade show active " id="nav-internado-edit" role="tabpanel" aria-labelledby="tab-internado-edit">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="nombre">Promoción de internado</label>
                                            <input type="text" [disabled]="(formConfedit.get('status_i').value==0)" [formControl]="formConfedit.get('promocion')" class="form-control" id="nombre" placeholder="Promoción">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="estado">Periodo</label>
                                            <select class="form-control" id="estado" [formControl]="formConfedit.get('periodoid')">
                                                <option *ngFor="let per of periodosescolares" [value]="per.id">{{ per.nombre }}</option>
                                            </select>                        
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                        <label for="plan">Plan de Estudio</label>
                                        <select [formControl]="formConfedit.get('pleid')" class="form-control" id="plan">
                                            <option value="">--Plan de Estudios--</option>
                                            <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fii">Fecha inicio internado</label>
                                            <input type="date" class="form-control" [formControl]="formConfedit.get('fecha_inicio_i')" id="ffi" placeholder="Fecha inicio internado">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ffi">Fecha fin internado</label>
                                            <input type="date" class="form-control" [formControl]="formConfedit.get('fecha_fin_i')" id="ffi" placeholder="Fecha fin internado">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fiss">Fecha inicio de realización de internado</label>
                                            <input type="date" class="form-control" [formControl]="formConfedit.get('fecha_inicio_realiza_i')" id="fiss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ffss">Fecha fin de realización de internado</label>
                                            <input type="date" class="form-control" [formControl]="formConfedit.get('fecha_fin_realiza_i')" id="ffss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ffss">Fecha asignación internado</label>
                                            <input type="date"  class="form-control" [formControl]="formConfedit.get('fecha_asignacion_i')" id="ffss" placeholder="Fecha asignación internado">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="estado">Estado</label>
                                            <select class="form-control" id="estado" [formControl]="formConfedit.get('status_i')">
                                                <option [value]="0">Abierto</option>
                                                <option [value]="1">Cerrado</option>
                                            </select>                        
                                        </div>
                                    </div>
                                </div>
                                <small class="text-danger" *ngIf="formConfedit.invalid || (formConfedit.get('status_i').value==1)">La configuración está cerrada</small>
                                <small class="text-danger" *ngIf="formConfedit.invalid">Llena todos los datos requeridos</small>
                            </div>
                            
                            
                            <!-- Tab de servicio social edición -->

                            <div class="tab-pane fade" id="nav-sersoc-edit" role="tabpanel" aria-labelledby="tab-sersoc-edit">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="ss">Servicio social</label>
                                            <input type="text" [formControl]="formConfedit.get('ss')" class="form-control" id="ss" placeholder="Servicio social">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fiss">Fecha inicio servicio social</label>
                                            <input type="date"  class="form-control" [formControl]="formConfedit.get('fecha_inicio_ss')" id="fiss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ffss">Fecha fin servicio social</label>
                                            <input type="date"  class="form-control" [formControl]="formConfedit.get('fecha_fin_ss')" id="ffss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fiss">Fecha inicio de realización de servicio social</label>
                                            <input type="date"  class="form-control" [formControl]="formConfedit.get('fecha_inicio_realiza_ss')" id="fiss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ffss">Fecha fin de realización de servicio social</label>
                                            <input type="date"  class="form-control" [formControl]="formConfedit.get('fecha_fin_realiza_ss')" id="ffss" placeholder="Fecha fin servicio social">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="fiss">Fecha asignación servicio social</label>
                                            <input type="date" class="form-control" [formControl]="formConfedit.get('fecha_asignacion_ss')" id="fiss" placeholder="Fecha a servicio social">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="estado">Estado</label>
                                            <select class="form-control" id="estado" [formControl]="formConfedit.get('status_ss')">
                                                <option value="0">Abierto</option>
                                                <option value="1">Cerrado</option>
                                            </select>                        
                                        </div>
                                    </div>
                            </div>
                            <small class="text-danger" *ngIf="formConfedit.invalid || (formConfedit.get('status_ss').value==1)">La configuración está cerrada</small>
                            <small class="text-danger" *ngIf="formConfedit.invalid">Llena todos los datos requeridos</small>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger" [disabled]="formConfedit.invalid " data-dismiss="modal" (click)="edit()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div> 
                <div class="modal-body">
                    {{ respuesta.mensaje }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</div>