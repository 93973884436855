import { Component, OnInit } from '@angular/core';
import { TramitesService } from '../services/tramites.service'
import { Global } from 'src/global';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $ : any;


@Component({
  selector: 'app-tramites-escolares',
  templateUrl: './tramites-escolares.component.html',
  styleUrls: ['./tramites-escolares.component.css']
})
export class TramitesEscolaresComponent implements OnInit {

  busqueda = new FormGroup({
    matricula: new FormControl('', Validators.required),
  })

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  solicitudesNoListos = [];
  solicitudesListos = [];

  noPendientes=0;
  realizados=0;
  idSolicitud;
  matriculaAlumno;
  tipodoc;
  tipodocId;

  documento = {
    fechageneracion:'',
    fechapago:'',
    fechasolicitud:'',
    generado:'',
    id:'',
    matricula:'',
    matriculpap:'',
    pago:'',
    tipodoc:{
      id:Number,
      nombre:'',
      precio:Number
    },
    nombre:''
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  periodos = [];
  idPeriodo;

  bandera:boolean = true;
  bandera2:boolean = true;
  bandera3:boolean = true;

  settings = {
    columns:{
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'15%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'30%'
      },
      nombredoc:{
        title:'Trámite',
        editable:false,
        width:'15%'

      },
      fechasolicitud:{
        title:'Solicitud',
        editable:false,
        width:'20%'
      },
      fechapago:{
        title:'Pago',
        editable:false,
        width:'20%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns:{
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'15%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'30%'
      },
      nombredoc:{
        title:'Trámite',
        editable:false,
        width:'15%'

      },
      fechasolicitud:{
        title:'Solicitud',
        editable:false,
        width:'20%'
      },
      fechageneracion:{
        title:'Generado',
        editable:false,
        width:'20%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }


  constructor(private tramitesService: TramitesService, private global: Global) {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.documentosListosEscolares();
    this.documentosNoListosEscolare();   
    this.periodosEscolares();
  }

  tomarIdSolicitud(value){
    this.idSolicitud = value.data.id;
    this.matriculaAlumno = value.data.matricula;
    this.tipodoc = value.data.tipodoc;
    this.tipodocId = value.data.tipodoc.id;
    this.documento = value.data;
    $('#aviso').modal('show');
  }

  abrirperidos(){
    $('#periodo').modal('show');
  }

  guardarIdPeriodo(){
    this.idPeriodo = <HTMLSelectElement>document.getElementById('exampleFormControlSelect1');
    console.log(this.idPeriodo.value);
  }

  periodosEscolares(){
    this.tramitesService.periodosEscoalres(this.maestro.matricula).subscribe({
      next:(res : any ) => {
        this.periodos = res;
      },
      complete:() => {

      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  documentosListosEscolares(){
    this.tramitesService.documentosListosEscolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.solicitudesListos = res;
      },
      complete:() => {
        this.realizados = this.solicitudesListos.length;
        if(this.solicitudesListos.length == 0){
          this.bandera3 = false;
        }
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  documentosNoListosEscolare(){
    this.tramitesService.documentosNoListosEscolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.solicitudesNoListos = res;
      },
      complete:() => {
        this.noPendientes = this.solicitudesNoListos.length;
        this.bandera = false;
        if(this.solicitudesNoListos.length == 0){
          this.bandera2 = false;
        }
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  atenderEscolares(){
    var documento: any;
    this.tramitesService.imprimirDocument(this.tipodocId, this.matriculaAlumno, this.maestro.matricula, this.idPeriodo.value);
    var jason = {
      dato:this.idSolicitud,
      matriculap:this.maestro.matricula
    }
    this.tramitesService.atenderEscolares(jason).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      complete:() => {
        $('#respuesta').modal('show');
      },
      error:(err) => {
        console.log(err);
      }
    });

    // for(var i = 0; i < this.solicitudesNoListos.length; i++){
    //   if(this.tipodocId == this.solicitudesNoListos[i].tipodoc.id){
    //     documento = this.solicitudesNoListos[i].tipodoc.id;
    //   }
    // }

    // if(documento == 1){
    //   window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/${this.matriculaAlumno}/${this.maestro.matricula}`)
    // }
    // if(documento == 2){
    //   window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/${this.matriculaAlumno}/${this.maestro.matricula}`)
    // }

    setTimeout(() => {
      this.documentosListosEscolares();
      this.documentosNoListosEscolare();
    }, 1500);

  }

  sinDatosDisponibles(){
    $('#staticBackdropSinDatos').modal('show');
  }


  onSubmit(){
    var jason = {
      dato: this.busqueda.get('matricula').value,
      matriculap: this.maestro.matricula
    }
    this.tramitesService.buscarDocEscolares(jason).subscribe({
      next:(rep : any) => {
        this.solicitudesNoListos = rep;
      },
      complete:() => {
        this.realizados = this.solicitudesListos.length;
      },
      error:(err) => {
        console.log(err);
      }
    });
    this.tramitesService.buscarDocEscolaresListos(jason).subscribe({
      next:(res : any) => {
        this.solicitudesListos = res;
      },
      complete:() => {
        this.noPendientes = this.solicitudesNoListos.length;
          if(this.solicitudesListos.length == 0 && this.solicitudesNoListos.length == 0){
            this.sinDatosDisponibles();
        }else{

      }
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

}
