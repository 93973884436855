<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid">
        <div class="row row1"></div>
        <div class="row">
          <div class="col-lg-2">
            <app-navbar-izq></app-navbar-izq>

          <!-- <div>
            <div class="calendar">
              <label>Consultar Historial</label>
            </div>
            <div class="calendar">
              <ngb-datepicker #dp [(ngModel)]="model" (click)="enviar()" (navigate)="navigateEvent($event)"></ngb-datepicker>

              <button class="btn btn-sm btn-outline-primary mr-2" (click)="selectToday()">Hoy</button>            
            
            </div>
          </div> -->
          </div>
            <div class="col-lg-9">
                  <div [hidden]="!bandera2">
                    <div class="card bg-danger" *ngFor="let grp of grupo">
                      <div class="card-body text-light">
                        <label><strong>{{ grp.mpe.materia.nombre }}</strong></label><label>Grupo: <strong>{{ grp.nombre }}</strong></label><label><strong>Fecha Actual: </strong>{{ fechaActual | date:'dd-MM-yyyy HH:mm' }}</label><br>
                        <label>Docente: <strong>{{ maestro.nombres }} {{ maestro.apellidop }} {{ maestro.apellidom }}</strong></label>
                      </div>
                    </div><br>
                      <table class="table table-hover table-sm table-striped">
                          <thead class="thead-danger">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Matrícula</th>
                              <th scope="col">Nombre Completo</th>
                              <th scope="col"  class="input"><div>Asistió</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let alumno of alumnosGrupo; let i = index" >
                              <th>{{ i + 1 }}</th>
                              <td>{{ alumno.matricula }}</td>
                              <td>{{ alumno.apellidop }} {{ alumno.apellidom }} {{ alumno.nombres }}</td>
                              <th scope="col"><div class="input"><input (change)="tomarLista(alumno.matricula,$event)" class="form-check-input" type="checkbox" value="{{alumno.matricula}}" id="defaultCheck{{i}}"></div></th>
                            </tr>
                          </tbody>
                        </table>
                        <div>
                            <button class="btn btn-danger" [hidden]="!bandera" (click)="enviarLista()"><i class="fas fa-share"></i>Enviar</button>
                            <button class="btn btn-danger" [hidden]="bandera"><i class="fas fa-share"></i>Actualizar</button>
                        </div>

                      </div>

                      <div [hidden]="bandera2">
                        <div class="card bg-danger" *ngFor="let grp of grupo">
                          <div class="card-body text-light">
                            <label><strong>{{ grp.mpe.materia.nombre }}</strong></label><label>Grupo: <strong>{{ grp.nombre }}</strong></label><label><strong>Fecha Actual: </strong>{{ fechaActual | date:'dd-MM-yyyy HH:mm' }}</label><br>
                            <label>Docente: <strong>{{ maestro.nombres }} {{ maestro.apellidop }} {{ maestro.apellidom }}</strong></label>
                          </div>
                        </div><br>
                        <table class="table table-sm table-hover">
                          <thead class="bg-danger text-white">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Matricula</th>
                              <th scope="col">Nombre Completo</th>
                              <th scope="col"  class="input"><div>Asistió</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let alum of alumnosGrupo2; let i = index" >
                              <th>{{ i + 1 }}</th>
                              <td>{{ alum.alumno.matricula }}</td>
                              <td>{{ alum.alumno.apellidop }} {{ alum.alumno.apellidom }} {{ alum.alumno.nombres }}</td>
                              <th scope="col"><div class="input"><input (change)="tomarLista(alum.alumno.matricula,$event)" class="form-check-input" type="checkbox" value="{{alum.alumno.matricula}}" id="defaultCheck{{i}}" [checked]="alum.asistencia == 1" [disabled]="alum.asistencia == 1"></div></th>
                            </tr>
                          </tbody>
                        </table>
                        <button class="btn btn-danger" [hidden]="bandera" (click)="enviarLista()"><i class="fas fa-share"></i>Actualizar</button>
                      </div>
                      
                    </div>
        </div>
    </div>

</body>
</html>