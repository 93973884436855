<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
<h2>Relación de las materias del Plan de Estudios</h2>
<div class="row" id="top">
    <div class="col-3">
        <div class="form-group">
            <select class="form-control" id="planestudios" (change)="seleccionarperiodo()">
            <option>-- Plan de estudios --</option>
            <option *ngFor="let plan of planestudiosactivos" value="{{ plan.id }}">{{ plan.carrera.nombre }} - {{ plan.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group" *ngIf="banderasemestre">
            <select class="form-control" id="semestre" (change)="seleccionarsemestre()">
            <option>-- Semestre --</option>
            <option *ngFor="let s of semestresplan" value="{{ s.semestre }}">{{ s.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group" *ngIf="banderamaterias">
            <select class="form-control" id="materias" (change)="seleccionarmateria()">
            <option>-- Materias --</option>
            <option *ngFor="let m of matreriasplan" value="{{ m.id }}">{{ m.materia.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group" *ngIf="bandera">
            <button class="btn btn-danger" (click)="insert()">Agregar</button>
        </div>
    </div>
    <div class="col-12" *ngIf="bandera">
        <ng2-smart-table class="table table-hover table-borderless" (custom)="delete($event)" [settings]="settings" [source]="src"></ng2-smart-table>
    </div>
</div>

<div class="modal fade" id="insert" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl  modal-dialog-scrollable" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Agregar</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row" id="top">
                <div class="col-12">
                    <h5>Plan de estudio</h5>
                </div>
                <div class="col-12 mb-2" *ngIf="banderamodal">
                    <input type="text" autocomplete="off"  class="form-control" disabled id="plan" [value]="planActual.carrera.nombre+' - '+planActual.nombre">
                </div>
                <div class="col-12">
                    <h5>Materia principal</h5>
                </div>
                <div class="col-6">
                    <div class="form-group" >
                        <select class="form-control" id="semestrepri" [disabled]="disablesp" >
                        <option>-- Semestre --</option>
                        <option *ngFor="let s of semestresplan" value="{{ s.semestre }}">{{ s.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group" >
                        <select class="form-control" id="materiaspri"  [disabled]="disablemp" >
                        <option>-- Materias --</option>
                        <option *ngFor="let m of matreriasplan" value="{{ m.id }}">{{ m.materia.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <h5>Materia relacional</h5>
                </div>
                <div class="col-6">
                    <div class="form-group" >
                        <select class="form-control" id="semestrerel"  (change)="seleccionarsemestrerel()">
                        <option>-- Semestre --</option>
                        <option *ngFor="let s of semestresplanrel" value="{{ s.semestre }}">{{ s.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group" >
                        <select class="form-control" id="materiasrel" (change)="seleccionarmateriarel()">
                        <option>-- Materias --</option>
                        <option *ngFor="let m of matreriasplanrel" value="{{ m.id }}">{{ m.materia.nombre }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="guardar()">Agregar</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
        </div>
    </div>
</div>
<!-- modal del respuesta -->
<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal de aviso de borrar -->
<div class="modal fade" id="borrar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                ¿Estas seguro que deseas borrar esta relación?, <b>Está acción es irreversible</b>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="borrar()">Acceptar</button>
                <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>



</body>
</html>