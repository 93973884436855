import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../services/personal.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-constancia',
  templateUrl: './constancia.component.html',
  styleUrls: ['./constancia.component.css']
})
export class ConstanciaComponent implements OnInit {

  @ViewChild('htmlData') htmlData: ElementRef;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  historialCertificado;
  historialMenorIgual5 = [];
  historialMayor5 = [];
  textos;
  pie;
  bandera = true;
  matriculaAlumno = localStorage.getItem('alumno');

  src;
  type = FileMimeType.PDF;

  @Input() planestudioid;
  @Input() firma;

  constructor(private route: ActivatedRoute, private personalService: PersonalService, private global: Global) { }

    ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarconstancia();
    // var jason = {
    //   dato: matriculaAlumno,
    //   matriculap: this.maestro.matricula
    // }
    // this.personalService.consultarDatosConstancia(jason).subscribe({
    //   next:(resp) => {
    //     this.textos = resp[0];
    //   },
    //   error:() => {
    //   }
    // });
    // this.personalService.consultarHistorialConstancia(jason).subscribe({
    //   next:(res) => {
    //     this.historialCertificado = res;
    //     for( var i = 0; i < this.historialCertificado.length; i++){
    //       if(this.historialCertificado[i].semestre <= 5){
    //         this.historialMenorIgual5.push(this.historialCertificado[i]);
    //       }
    //       else{
    //         this.historialMayor5.push(this.historialCertificado[i]);
    //       }
    //     }
    //   },
    //   error:() => {
    //     throw new Error("Error al buscar historial para constancia");
    //   }
    // });
    // this.personalService.constanciaFooter(jason).subscribe({
    //   next:(res)=>{
    //     this.pie = res;
    //     this.bandera = false;
    //   },
    //   error:()=>{
    //     throw new Error("Error al recuperar pie de pagina");
    //   }
    // })
  }

  consultarconstancia(){
    this.src = `${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/${this.matriculaAlumno}/${this.maestro.matricula}/${this.firma}/${this.planestudioid}`
  }



}
