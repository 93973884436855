import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuraciones-se',
  templateUrl: './configuraciones-se.component.html',
  styleUrls: ['./configuraciones-se.component.css']
})
export class ConfiguracionesSEComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  access = [];
  // Periodos Escolares
  banderaPeriodosEscolares : boolean = true;
  banderaListadoPeriodos : boolean = true;
  banderaConsultaFechas : boolean = true;
  banderaDiasFestivos : boolean = true;
  babderaCaptura : boolean = true;
  // Catalogos
  banderaCatalogos : boolean = true;
  banderaCatAulas : boolean = true;
  banderaCatMaterias : boolean = true;
  banderaCatGrupos : boolean = true;
  banderaTipoDocumentos : boolean = true;
  // Planes Estudio
  banderaPlanEstudios : boolean = true;
  // Parametros
  banderaParametros : boolean = true;
  // Inscripciones
  banderaInscripciones : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtn();
  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10206 || this.access[i] == 3){
        this.babderaCaptura = false;
      }
      if(this.access[i] == 10078 || this.access[i] == 3){
        this.banderaPeriodosEscolares = false;
      }
      if(this.access[i] == 10079 || this.access[i] == 3){
        this.banderaListadoPeriodos = false;
      }
      if(this.access[i] == 10082 || this.access[i] == 3){
        this.banderaConsultaFechas = false;
      }
      if(this.access[i] == 10084 || this.access[i] == 3){
        this.banderaDiasFestivos = false;
      }
      if(this.access[i] == 10104 || this.access[i] == 3){
        this.banderaCatalogos = false;
      }
      if(this.access[i] == 10105 || this.access[i] == 3){
        this.banderaCatAulas = false;
      }
      if(this.access[i] == 10109 || this.access[i] == 3){
        this.banderaCatMaterias = false;
      }
      if(this.access[i] == 10114 || this.access[i] == 3){
        this.banderaCatGrupos = false;
      }
      if(this.access[i] == 10121 || this.access[i] == 3){
        this.banderaTipoDocumentos = false;
      }
      if(this.access[i] == 10085 || this.access[i] == 3){
        this.banderaPlanEstudios = false;
      }
      if(this.access[i] == 10148 || this.access[i] == 3){
        this.banderaParametros = false;
      }
      if(this.access[i] == 10095 || this.access[i] == 3){
        this.banderaInscripciones = false;
      }
    }
  }

}
