<div>
    <h2>Cerrar inscripción</h2>
    <div class="row">
        <div class="col-3">
            <div class="form-group">
                <select class="form-control" [formControl]="this.cerrarform.get('periodoid')" id="periodoescolar" >
                    <option value="">-- Períodos Escolares --</option>
                    <option *ngFor="let periodo of periodosescolarescompleta" value="{{ periodo.id }}">{{ periodo.periodo }}</option>
                </select>
            </div>
        </div>
        <div class="col-3" >
            <div class="form-group">
                <select class="form-control"  [formControl]="this.cerrarform.get('planestudioid')"  id="carrera" >
                    <option value="">-- Carreras --</option>
                  <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
                </select>
            </div>
        </div>
        <div class="col-3" >
            <button class="btn btn-danger" (click)="abrirAdvertencia()" [disabled]="this.cerrarform.invalid">Cerrar</button>
        </div>
    </div>
<!-- Modal de respuesta -->
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
<!-- Modal de advertencia -->
<div class="modal fade" id="advertencia" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        <div class="modal-body">
            ¡Está a punto de cerrar el proceso de inscripción! Esta acción inactiva a los Alumnos de <b>Nuevo Ingreso</b> que no cubrieron sus cuotas <b>¿Desea continuar?</b>
        </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-danger" (click)="enviar()" >Aceptar</button>
            </div>
        </div>
    </div>
</div>
</div>