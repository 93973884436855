import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
declare var $ : any;


@Component({
  selector: 'app-documentos-perfil',
  templateUrl: './documentos-perfil.component.html',
  styleUrls: ['./documentos-perfil.component.css']
})
export class DocumentosPerfilComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }

  settings = {
    columns:{
      nombre:{
        title:'Documento',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell.substring(0,3) == "(*)"){
            return cell.slice(4)
          }else{
            return cell
          }
        },
        width:'50%'
      },
      obligatorio:{
        title:'Obligatorio',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell == "N"){
            cell = "NO";
            return cell;
          }else{
            if(cell == "S"){
              cell = "SI";
              return cell;
            }else{
              return cell
            }
          }
        },
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'abrirmodaldocumento', title: '<i class="fas fa-upload"></i>'}
      ],
      position:'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns:{
      nombre:{
        title:'Documento',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell.substring(0,3) == "(*)"){
            return cell.slice(4)
          }else{
            return cell
          }
        },
        width:'100%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'verdocumento', title: '<i class="fas fa-eye"></i>'}
      ],
      position:'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin documentos disponibles.'
  }

  settings3 = {
    columns:{
      nombre:{
        title:'Documento',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell.substring(0,3) == "(*)"){
            return cell.slice(4)
          }else{
            return cell
          }
        },
        width:'100%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'verdocumento', title: '<i class="fas fa-eye"></i>'}
      ],
      position:'right'
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin documentos disponibles.'
  }

  uploadForm = new FormGroup({
    file: new FormControl('', [Validators.required])
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  documentosposibles = [];
  documentossubidos = [];
  documentosvalidados = [];
  documentoselecionadoid = '';
  documentoselecionadonombre = '';
  src = '';
  type = FileMimeType.PDF;

  banderatabla:boolean = true;
  banderadocumento:boolean = true;

  banderadocumentossubidos:boolean = true;
  banderadocumentospendientes:boolean = true;
  banderadocumentosvalidados:boolean = true;
  banderaterminado:boolean = true;

  constructor(private global: Global, private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultardocumentosposibles();
  }

  consultardocumentosvalidados(){
    this.personalService.consultardocumentosvalidados(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.documentosvalidados = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentosvalidados.length == 0){
          this.banderadocumentosvalidados = false;
        }
        // for(var i = 0; i < this.documentosposibles.length; i++){
        //   for(var x = 0; x < this.documentosvalidados.length; x++){
        //     if(this.documentosposibles[i].id == this.documentosvalidados[x].id){
        //       this.documentosvalidados.splice(x, 1);
        //     }
        //   }
        // }
        setTimeout(() => {
          this.banderaterminado = false;
        }, 1000)
      }
    });
  }

  consultardocumentosposibles(){
    this.personalService.documentosPosibles(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.documentosposibles = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentosposibles.length == 0){
          this.banderadocumentospendientes = false;
        }
        this.consultardocumentossubidos();
      }
    });
  }

  consultardocumentossubidos(){
    this.personalService.documentosSubidos(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.documentossubidos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentossubidos.length == 0){
          this.banderadocumentossubidos = false;
        }
        this.consultardocumentosvalidados();
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
    if(this.uploadForm.get('file').value != ''){
      this.banderatabla = false;
    }
  }

  subirdocumento(){
    const formdata = new FormData();
    formdata.append('file', this.uploadForm.get('file').value),
    formdata.append('matricula', this.maestro.matricula);
    formdata.append('tipo', this.documentoselecionadoid);
    this.personalService.subirDocumentos(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.respuesta.codigo == "OK"){
          this.banderaterminado = true;
          this.banderadocumentospendientes = true;
          this.banderadocumentossubidos = true;
          this.banderadocumentosvalidados = true;
          this.consultardocumentosposibles();
          this.uploadForm.get('file').setValue('');
        }else{
          if(this.respuesta.codigo == "ERROR"){
            $('#respuesta').modal('show');
          }
        }
      }
    });
  }

  abrirmodaldocumento(event){
    this.documentoselecionadoid = event.data.id;
    if(event.data.nombre.substring(0,3) == "(*)"){
      this.documentoselecionadonombre = event.data.nombre.slice(4)
    }else{
      this.documentoselecionadonombre = event.data.nombre;
    }    
    $('#formulario').modal('show');
  }

  verdocumento(event){
    this.documentoselecionadoid = event.data.id;
    this.documentoselecionadonombre = event.data.nombre;
    $('#documentos').modal('show');
    this.verdocumentourl();   
  }

  verdocumentourl(){
    this.src = `${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/documento/${this.documentoselecionadoid}`
    this.banderadocumento = false;
  }

  descargardocumento(){
    this.personalService.documentosImprimir(this.documentoselecionadoid);
  }

}
