<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">   
                <div class="row" id="top">
                    <div class="col-lg-6">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div> 
                <div class="row" id="cuerpo"> 
                    <div class="col-lg-12">    
                        <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" [settings]="settingsCalendario" [source]="listaFechas" ></ng2-smart-table><!--(custom)='accion($event)'-->
                    
                    </div>
                </div>
            </div>
            
                
            
            
        </div>
    </div>
</body>
</html>


