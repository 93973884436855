<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid" *ngIf="banderahttp">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                  <div class="col-lg-7">
                      <div class="card">
                          <div class="card-body">
                              <h3>Becas</h3>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="row" id="top">
                  <div class="col-3">
                    <div  class="form-group">
                      <select class="form-control" id="periodoescolares" (change)="periodoselecionadof()">
                        <option value="">--Período Escolar--</option>
                        <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3" *ngIf="!banderaalumno">
                    <div class="form-group">
                      <input type="text" class="form-control" id="matricula" placeholder="Matrícula de alumno">
                    </div>
                  </div>
                  <div class="col-1">
                    <div class="form-group float-right" *ngIf="!banderaalumno">
                      <button class="btn btn-danger" (click)="this.consultaralumno()">Buscar</button>
                    </div>
                  </div>
                  <div  class="col-4">
                    <div class="form-group" *ngIf="!banderasinmatricula">
                      <div class="alert alert-danger" role="alert">
                        Ingrese una matrícula para realizar busqueda.
                      </div>
                    </div>
                    <div class="form-group" *ngIf="!banderasinresultados">
                      <div class="alert alert-danger" role="alert">
                        Sin resultados con la matrícula ingresada.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" id="top">
                  <div class="col-4" *ngIf="!banderabecas">
                    <div class="form-group">
                      <select class="form-control" id="becas" (change)="selecionarbeca()">
                        <option value="">--Becas disponibles--</option>
                        <option *ngFor="let beca of becasdisponibles" [value]="beca.id">{{ beca.descripcion }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-8" *ngIf="!banderaresultados">
                    <table class="table table-sm table-hover table-borderless">
                      <thead class="bg-danger text-white">
                        <tr>
                          <th>Matrícula</th>
                          <th>Alumno</th>
                          <th>Carrera</th>
                          <th>Semestre</th>
                          <th>Regular</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ alumno.matricula }}</td>
                          <td>{{ alumno.nombres | titlecase }} {{ alumno.apellidop | titlecase }} {{ alumno.apellidom | titlecase }}</td>
                          <td>{{ alumno.carrera | titlecase }}</td>
                          <td>{{ alumno.semestre }}</td>
                          <td>{{ alumno.regular | titlecase }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid"  *ngIf="!banderahttp">
      <div class="row" id="top"></div>
      <div class="row">
        <div class="col-lg-2">
          <app-navbar-izq></app-navbar-izq>
        </div>
        <div class="col-9">
          <div class="row" id="top">
            <div class="col-12">
              <app-http-errors [status]="httpstatus" [statustext]="httpstatustext" [url]="httpurl"></app-http-errors>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="elimnarbeca" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" *ngIf="!banderamodal2">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ becaselecionada.descripcion }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ¿Desea eliminar la beca <strong>{{ becaselecionada.descripcion }}</strong> al alumno <strong>{{ alumno.nombres }} {{ alumno.apellidop }} {{ alumno.apellidom }}</strong>?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="eliminarbecas()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="avisobeca" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" *ngIf="!banderamodal">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ becaselecionada.descripcion }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ¿Desea asignar la beca <strong>{{ becaselecionada.descripcion }}</strong> al alumno <strong>{{ alumno.nombres }} {{ alumno.apellidop }} {{ alumno.apellidom }}</strong>?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="asingarbecas()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ respuesta.mensaje }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

</body>
</html>