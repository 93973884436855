<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div *ngIf="banderahttp">
        <div>
            <h3>Cambiar Contraseña</h3>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <ul>
                    <li>Mínimo <strong>8</strong> caracteres</li>
                    <li>Mínimo <strong>1</strong> letra mayúscula</li>
                </ul>
            </div>
            <div class="col-lg-3">
                <ul>
                    <li>Mínimo <strong>1</strong> letra minúscula</li>
                    <li>Mínimo <strong>1</strong> número</li>
                </ul>
            </div>
        </div>
        <form [formGroup]="contraform" (ngSubmit)="cambiarcontra()">
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <input [formControl]="this.contraform.get('contraseñaanterior')" type="password" class="form-control" id="contraseñaA" placeholder="Contraseña Anterior">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñaanterior').errors && this.contraform.get('contraseñaanterior').touched">
                                Ingrese su contraseña anterior.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <input formControlName="contraseñanueva" type="password" class="form-control" id="contraseñaN" placeholder="Contraseña Nueva">
                            </div>
                        </div>
                        <div class="col-6" *ngIf="this.contraform.get('contraseñanueva').errors && this.contraform.get('contraseñanueva').touched">
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñanueva').errors.required">
                                Ingrese su contraseña Nueva.
                            </div>
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñanueva').errors.esigual">
                                Su contraseña no puede ser igual a la anterior.
                            </div>
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñanueva').errors.pattern">
                                Su contraseña no cumple los requisitos.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <input formControlName="contraseñaconfirmar" type="password" class="form-control" id="contraseñaNC" placeholder="Confirme Su Contraseña Nueva">
                            </div>
                        </div>
                        <div class="col-6" *ngIf="this.contraform.get('contraseñaconfirmar').errors && this.contraform.get('contraseñaconfirmar').touched">
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñaconfirmar').errors.required">
                                Confirme su contraseña Nueva.
                            </div>
                            <div class="text-danger" *ngIf="this.contraform.get('contraseñaconfirmar').errors.noesigual">
                                Sus contraseñas nuevas no coinciden.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="float-right">
                        <button class="btn btn-danger" [disabled]="this.contraform.invalid">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="!banderahttp">
        <app-http-errors [status]="httpstatus" [statustext]="httpstatustext" [url]="httpurl"></app-http-errors>
    </div>
    
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
            </div>
        </div>
        </div>
</body>
</html>