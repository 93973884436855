import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CatalogosService } from './../services/catalogos.service';
import { Component, OnInit } from '@angular/core';
declare var $ :any;
@Component({
  selector: 'app-departamentales',
  templateUrl: './departamentales.component.html',
  styleUrls: ['./departamentales.component.css']
})
export class DepartamentalesComponent implements OnInit {
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  /**
   *  CONFIGURACIONES DE LA TABLA PARA MOSTRAR LOS DEPARTAMENTALES
   */
  settings = {
    columns:{
      materia:{
        title:'Materia',
        editable:false,
        width:'30%'
      },
      plan_estudio:{
        title:'Plan de Estudio',
        editable:false,
        width:'30%'
      },
      nombre_rol:{
        title:'Rol',
        editable:false,
        width:'40%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'delete', title:'<a class="btn btn-danger btn-sm  text-center text-white"><i class="fas fa-times"></i></a>' }
      ],
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  } 

  formCreate = new FormGroup({
    materia: new FormControl('',Validators.required),
    matriculap : new FormControl(''),
    rol : new FormControl('',Validators.required)
  })
  
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  }

  src: any;
  rolesactivos: any;
  idDepartamental: number;
  planestudiosactivos = [];
  mpe = [];
  idmpe;
  idplan;
  idrol;
  datos = {
    id: 0,
    id_mpe: 0,
    id_rol: 0,
    materia: "",
    nombre_rol: "",
    plan_estudio: ""
  };

  bandera: boolean = false;
  banderroles: boolean = false;
  banderamaterias: boolean = false;
  constructor(private catalogosService: CatalogosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.buscarDepartamentales();
    this.consultarroles();
    this.consultarplanes();
  }

  buscarDepartamentales() {
    this.bandera = true;
    var jason = {
      matruculap: this.maestro.matricula
    }
    this.catalogosService.getDepartamentales(jason).subscribe({
      next: (res) => {
        this.src = res;
      },
      error: () => {
        throw new Error('No se pudo buscar');
      },
      complete: () => {
        this.bandera = false;
      }
    });
  }

  acciones(e){
    if(e.action === 'delete'){
      $('#borrarDepartamental').modal('show');
      this.idDepartamental = e.data.id;
      this.datos = e.data;
    }
    console.log(e);
  }

  consultarroles(){
    this.banderroles = true;
    this.catalogosService.consultarrolesOrderbyApellido(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.rolesactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // this.rolesactivos.sort(function (a, b) {
        //   if (a.id > b.id) {
        //     return 1;
        //   }
        //   if (a.id < b.id) {
        //     return -1;
        //   }
        //   // a must be equal to b
        //   return 0;
        // });
        this.banderroles = false;
        //console.log(this.rolesactivos);
      }
    });
  }
  
  consultarplanes(){
    this.catalogosService.consultarplanestudios().subscribe({
      next:(data : any) => {
        this.planestudiosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
    }
    });
  }
  seleccionarplan(){
    var planestudios = <HTMLSelectElement>document.getElementById('planestudios');
    this.idplan = planestudios.value;
    this.consultaMPE();
  }
  seleleccionarMateria(){
    var materias = <HTMLSelectElement>document.getElementById('materias');
    this.idmpe = materias.value;
  }
  seleleccionarRol(){
    var rol = <HTMLSelectElement>document.getElementById('rol');
    this.idrol = rol.value;
  }
  consultaMPE(){
    this.banderamaterias = false;
    this.catalogosService.consultarMPE(this.maestro.matricula,this.idplan ).subscribe({
      next:(data : any) => {
        this.mpe = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        this.banderamaterias = true;
      }
    });
  }
  crear(){
    this.bandera = true;
    var jason = {
      matriculap: this.maestro.matricula,
      id_mpe: this.idmpe,
      id_rol: this.idrol
    }
    //console.log(jason);
    this.catalogosService.crearDepartamental(jason).subscribe({
      next: (res : any) => {
        this.respuesta = res;
        
      },
      error: () => {
        throw new Error('No se pudo crear');
      },
      complete: () => {
        this.bandera = false;
        this.buscarDepartamentales();
        this.cerrarCrear();
        $('#respuesta').modal('show');
      }
    });
  }
  borrar(){
    this.bandera = true;
    var jason = {
      id: this.idDepartamental
    }
    this.catalogosService.borrarDepartamental(jason).subscribe({
      next: (res : any) => {
        this.respuesta = res;
      },
      error: () => {
        throw new Error('No se pudo borrar');
      },
      complete: () => {
        this.bandera = false;
        this.buscarDepartamentales();
        this.cerrarBorrar();
        $('#respuesta').modal('show');
      }
    });
  }

  abrirCrear(){
    $('#crearDepartamental').modal('show');
  }

  cerrarCrear(){
    $('#crearDepartamental').modal('hide');
    this.formCreate.reset();
    this.formCreate.get('materia').patchValue('');
    this.formCreate.get('rol').patchValue('');
    $('#planestudios').prop('selectedIndex',0);


  }

  cerrarBorrar(){
    $('#borrarDepartamental').modal('hide');
  }
}
