import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicioSocialService } from '../servicio-social.service';
declare var $:any;
@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.css']
})
export class ConfiguracionesComponent implements OnInit {

  constructor(private consultas: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultar();
    this.listaPeriodos();
    this.consultarplanestudios();
  }

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings= {
    columns:{
      periodo:{
        title:'Periodo',
        editable:false,    
        width:'10%'
      },
      ple:{
        title:'Plan de estudio',
        editable:false,    
        width:'15%'
      },
      promocion:{
        title:'Promocion',
        editable:false,    
        width:'10%'
      },
      finicioi:{
        title:'Fecha inicio internado',
        editable:false,    
        width:'10%'
      },
      ffini:{
        title:'Fecha fin internado',
        editable:false,    
        width:'10%'
      },
      status_i:{
        title:'Estado internado',
        editable:false,    
        width:'10%'
      },
      ss:{
        title:'Servicio social',
        editable:false,    
        width:'10%'
      },
      finicioss:{
        title:'Fecha inicio S.S.',
        editable:false,    
        width:'10%'
      },
      ffinss:{
        title:'Fecha fin S.S.',
        editable:false,    
        width:'10%'
      },
      status_ss:{
        title:'Estado S.S.',
        editable:false,    
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }
  
  formConf= new FormGroup({
    periodoid: new FormControl(0, [Validators.required]),
    periodo: new FormControl('',),
    promocion: new FormControl('',[Validators.required]),
    fecha_inicio_i: new FormControl('',[Validators.required]),
    fecha_fin_i: new FormControl('',[Validators.required]),
    fecha_asignacion_i: new FormControl('',[Validators.required]),
    ss: new FormControl('',[Validators.required]),
    fecha_inicio_ss: new FormControl('',[Validators.required]),
    fecha_fin_ss: new FormControl('',[Validators.required]),
    fecha_asignacion_ss: new FormControl('',[Validators.required]),
    matriculap: new FormControl(''), 
    fecha_inicio_realiza_ss: new FormControl('',[Validators.required]),
    fecha_fin_realiza_ss: new FormControl('',[Validators.required]),
    fecha_inicio_realiza_i: new FormControl('',[Validators.required]),
    fecha_fin_realiza_i: new FormControl('',[Validators.required]),
    pleid:new FormControl(0,[Validators.required]),
    status_ss:new FormControl(0,[Validators.required]),
    status_i:new FormControl(0,[Validators.required])
  });

  formConfedit= new FormGroup({
    periodoid: new FormControl(0, [Validators.required]),
    periodo: new FormControl('',),
    promocion: new FormControl('',[Validators.required]),
    fecha_inicio_i: new FormControl('',[Validators.required]),
    fecha_fin_i: new FormControl('',[Validators.required]),
    fecha_asignacion_i: new FormControl('',[Validators.required]),
    ss: new FormControl('',[Validators.required]),
    fecha_inicio_ss: new FormControl('',[Validators.required]),
    fecha_fin_ss: new FormControl('',[Validators.required]),
    fecha_asignacion_ss: new FormControl('',[Validators.required]),
    matriculap: new FormControl(''),
    fecha_inicio_realiza_ss: new FormControl('',[Validators.required]),
    fecha_fin_realiza_ss: new FormControl('',[Validators.required]),
    fecha_inicio_realiza_i: new FormControl('',[Validators.required]),
    fecha_fin_realiza_i: new FormControl('',[Validators.required]),
    pleid:new FormControl(0,[Validators.required]),
    status_ss:new FormControl(0,[Validators.required]),
    status_i:new FormControl(0,[Validators.required])
  });

  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  }
  banderatabka: boolean = true;
  periodosescolares= [];
  configuracion= [];
  conf = [];
  plandeestudios;
  planesdeestudiosfiltrados= [];
  banderacierre: boolean= true;

  consultar(){
    this.banderatabka = true;
    this.conf=[];
    let jason ={
      matriculap:this.maestro.matricula
    } 
    this.consultas.listaConfig(jason).subscribe({
      next:(res: any) =>{
        this.configuracion = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        
        for (let i = 0; i < this.configuracion.length; i++) {
          this.conf.push({ 
            fasignacioni:this.configuracion[i].fasignacioni,
            fasignacionss: this.configuracion[i].fasignacionss,
            ffini: this.configuracion[i].ffini,
            ffinrealizai: this.configuracion[i].ffinrealizai,
            ffinrealizass: this.configuracion[i].ffinrealizass,
            ffinss: this.configuracion[i].ffinss,
            finicioi: this.configuracion[i].finicioi,
            finiciorealizai: this.configuracion[i].finiciorealizai,
            finiciorealizass: this.configuracion[i].finiciorealizass,
            finicioss: this.configuracion[i].finicioss,
            id: this.configuracion[i].id,
            periodo: this.configuracion[i].periodo,
            periodoid: this.configuracion[i].periodoid,
            ple: this.configuracion[i].ple,
            pleid: this.configuracion[i].pleid,
            promocion: this.configuracion[i].promocion,
            ss: this.configuracion[i].ss,
            status_i: (this.configuracion[i].status_i==0)?'ABIERTO':'CERRADO',
            status_ss: (this.configuracion[i].status_ss==0)?'ABIERTO':'CERRADO' 
          });
        }
        //console.log(this.conf);
        this.banderatabka = false;
      }
    });
  }
  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next:(res: any) =>{
        this.periodosescolares = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        
      }
    });
  }
  guardar(){
    let jason = {
      matriculap:this.maestro.matricula,
      periodoid: this.formConf.get('periodoid').value,
      ss: this.formConf.get('ss').value,
      finicioss: this.formConf.get('fecha_inicio_ss').value,
      ffinss: this.formConf.get('fecha_fin_ss').value,
      fasignacionss: this.formConf.get('fecha_asignacion_ss').value,
      promocion: this.formConf.get('promocion').value,
      finicioi: this.formConf.get('fecha_inicio_i').value,
      ffini: this.formConf.get('fecha_fin_i').value,
      fasignacioni: this.formConf.get('fecha_asignacion_i').value,
      finiciorealizai: this.formConf.get('fecha_inicio_realiza_i').value,
      ffinrealizai: this.formConf.get('fecha_fin_realiza_i').value,
      finiciorealizass: this.formConf.get('fecha_inicio_realiza_ss').value,
      ffinrealizass: this.formConf.get('fecha_fin_realiza_ss').value, 
      status_ss: this.formConf.get('status_ss').value, 
      status_i: this.formConf.get('status_i').value,
      pleid:this.formConf.get('pleid').value
    }
    this.consultas.insertConfi(jason).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        this.consultar();
        this.formConf.reset();
        $('#respuesta').modal('show');
      }
    });
  }
  seleccionar(event){
    //console.log(event);
    this.maestro.matricula,
    this.formConfedit.get('periodoid').patchValue(event.data.periodoid);
    this.formConfedit.get('ss').patchValue(event.data.ss);
    this.formConfedit.get('fecha_inicio_ss').patchValue(event.data.finicioss);
    this.formConfedit.get('fecha_fin_ss').patchValue(event.data.ffinss);
    this.formConfedit.get('fecha_asignacion_ss').patchValue(event.data.fasignacionss);
    this.formConfedit.get('promocion').patchValue(event.data.promocion);
    this.formConfedit.get('fecha_inicio_i').patchValue(event.data.finicioi);
    this.formConfedit.get('fecha_fin_i').patchValue(event.data.ffini);
    this.formConfedit.get('fecha_asignacion_i').patchValue(event.data.fasignacioni);
    this.formConfedit.get('fecha_inicio_realiza_i').patchValue(event.data.finiciorealizai);
    this.formConfedit.get('fecha_fin_realiza_i').patchValue(event.data.ffinrealizai);
    this.formConfedit.get('fecha_inicio_realiza_ss').patchValue(event.data.finiciorealizass);
    this.formConfedit.get('fecha_fin_realiza_ss').patchValue(event.data.ffinrealizass);
    this.formConfedit.get('pleid').patchValue(event.data.pleid);
    this.formConfedit.get('status_i').patchValue((event.data.status_i=='ABIERTO')?0:(event.data.status_i=='CERRADO')?1:0);
    this.formConfedit.get('status_ss').patchValue((event.data.status_ss=='ABIERTO')?0:(event.data.status_ss=='CERRADO')?1:0);
    console.log(this.formConfedit.value);
    
    this.isDisabledI(this.formConfedit.get('status_i').value);
    this.isDisabledSs(this.formConfedit.get('status_ss').value);
    $('#modalconfedit').modal('show');
    

  }

  consultarplanestudios(){
    this.consultas.consultarplanestudiosFiltrados(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.plandeestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.plandeestudios.length; i++){
          if((this.plandeestudios[i].id == 1) || this.plandeestudios[i].id == 2){
            this.planesdeestudiosfiltrados.push(this.plandeestudios[i]);
          }
        }
      }
    });
  }
  isDisabledI(e){
    if(e==1){
      this.formConfedit.get('periodoid').disable();
      this.formConfedit.get('ss').disable();
      this.formConfedit.get('promocion').disable();
      this.formConfedit.get('fecha_inicio_i').disable();
      this.formConfedit.get('fecha_fin_i').disable();
      this.formConfedit.get('fecha_asignacion_i').disable();
      this.formConfedit.get('fecha_inicio_realiza_i').disable();
      this.formConfedit.get('fecha_fin_realiza_i').disable();
      this.formConfedit.get('pleid').disable(); 
    }else{
      this.formConfedit.get('periodoid').enable();
      this.formConfedit.get('promocion').enable();
      this.formConfedit.get('fecha_inicio_i').enable();
      this.formConfedit.get('fecha_fin_i').enable();
      this.formConfedit.get('fecha_asignacion_i').enable();
      this.formConfedit.get('fecha_inicio_realiza_i').enable();
      this.formConfedit.get('fecha_fin_realiza_i').enable();
      this.formConfedit.get('pleid').enable();
    }
  }
  isDisabledSs(e){
    if(e==1){
      this.formConfedit.get('ss').disable();
      this.formConfedit.get('fecha_inicio_ss').disable();
      this.formConfedit.get('fecha_fin_ss').disable();
      this.formConfedit.get('fecha_asignacion_ss').disable();
      this.formConfedit.get('fecha_inicio_realiza_ss').disable();
      this.formConfedit.get('fecha_fin_realiza_ss').disable();
    }else{
      this.formConfedit.get('ss').enable();
      this.formConfedit.get('fecha_inicio_ss').enable();
      this.formConfedit.get('fecha_fin_ss').enable();
      this.formConfedit.get('fecha_asignacion_ss').enable();
      this.formConfedit.get('fecha_inicio_realiza_ss').enable();
      this.formConfedit.get('fecha_fin_realiza_ss').enable();
    }
  }
  edit(){
    let jason = {
      matriculap:this.maestro.matricula,
      periodoid: this.formConfedit.get('periodoid').value,
      ss: this.formConfedit.get('ss').value,
      finicioss: this.formConfedit.get('fecha_inicio_ss').value,
      ffinss: this.formConfedit.get('fecha_fin_ss').value,
      fasignacionss: this.formConfedit.get('fecha_asignacion_ss').value,
      promocion: this.formConfedit.get('promocion').value,
      finicioi: this.formConfedit.get('fecha_inicio_i').value,
      ffini: this.formConfedit.get('fecha_fin_i').value,
      fasignacioni: this.formConfedit.get('fecha_asignacion_i').value,
      finiciorealizai: this.formConfedit.get('fecha_inicio_realiza_i').value,
      ffinrealizai: this.formConfedit.get('fecha_fin_realiza_i').value,
      finiciorealizass: this.formConfedit.get('fecha_inicio_realiza_ss').value,
      ffinrealizass: this.formConfedit.get('fecha_fin_realiza_ss').value,
      status_ss: this.formConfedit.get('status_ss').value, 
      status_i: this.formConfedit.get('status_i').value,
      pleid:this.formConfedit.get('pleid').value 
    }
    this.consultas.insertConfi(jason).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        //console.log(this.respuesta);
        
        $('#respuesta').modal('show');
        this.consultar();
      }
    });
  }
}
