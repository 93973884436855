import { PlanestudiosService } from './../service/planestudios.service';
import { Component, OnInit } from '@angular/core';
import { TIMEOUT } from 'dns';
declare var $: any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  bandera : boolean = false;
  planestudiosactivos = [];
  planestudiosactivos1 ;
  semestre;
  src;

  settings = {
    columns:{
      sem1:{
        title:'Semestre',
        editable:false,         
        width:'10%'
      },
      nombreMateria1:{
        title:'Materia principal',
        editable:false,         
        width:'40%'
      },
      sem2:{
        title:'Semestre',
        editable:false,         
        width:'10%'
      },
      nombreMateria2:{
        title:'Materia requisito',
        editable:false,         
        width:'40%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        //{ name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
          { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }
  banderasemestre: boolean = false;
  banderamaterias: boolean = false;
  semestresplan = [];
  matreriasplan = [];
  matreriasplanpri = [];
  semestresplanrel = [];
  materia;
  semestrepri;
  materiapri;
  semestrerel;
  matreriasplanrel
  materiarel;
  banderapri : boolean = false;
  bmateriaspri :boolean = false;
  planActual;
  mat_rel;
  banderamodal: boolean = false;
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };
  disablesp: boolean = true;
  disablemp: boolean = true;
  constructor(private planestudios: PlanestudiosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  consultarperiodoescolares(){
    this.planestudios.consultarplanestudios().subscribe({
      next:(data : any) => {
        this.planestudiosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
    }
    });
  }
  consultamateriasrelacionadas(){
    this.bandera = false;
    let jason = {
      matriculap: this.maestro.matricula,
      planestuidoid: this.planestudiosactivos1,
      materiaid:this.materia
    };
    this.planestudios.consultarmateriarelacion(jason).subscribe({
      next:(data : any) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.bandera = true;
      }
    });
  }

  consultamaterias(){
    this.banderamaterias = false;
    this.planestudios.consultarmaterias(this.maestro.matricula, this.planestudiosactivos1 , this.semestre).subscribe({
      next:(data : any) => {
        this.matreriasplan = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderamaterias = true;
      }
    });
  }

  seleccionarperiodo(){
    this.banderasemestre = false;
    this.bandera = false;
    var planestudios = <HTMLSelectElement>document.getElementById('planestudios');
    this.planestudiosactivos1 = planestudios.value;
    this.semestresplan =[];
    this.planestudiosactivos.forEach(element => {
      if(element.id == this.planestudiosactivos1){
        for (let i = 1; i <= element.semestres; i++) {
          this.semestresplan.push({
            nombre:"Semestre "+i,
            semestre:i
          })
        }
      }
    });
    this.banderasemestre = true;
    this.banderamaterias = false;
    
    
  }

  seleccionarsemestre(){
    let semestre = <HTMLSelectElement>document.getElementById('semestre');
    this.semestre = semestre.value;
    this.bandera = false;
    this.consultamaterias();
  }

  seleccionarmateria(){
    let materias = <HTMLSelectElement>document.getElementById('materias');
    this.materia = materias.value;
    this.consultamateriasrelacionadas();
  }
// Modal de agregar
  seleccionarsemestrepri(){
    let semestre = <HTMLSelectElement>document.getElementById('semestrepri');
    this.semestrepri = semestre.value;
    this.semestresplanrel =[];
      
        for (let i = 1; i < this.semestrepri; i++) {
          this.semestresplanrel.push({
            nombre:"Semestre "+i,
            semestre:i
          })
        }
      this.seleccionarmateriaEx()
    //this.consultamateriaspri();
  }

  consultamateriaspri(){
    this.banderapri = false;
    this.planestudios.consultarmaterias(this.maestro.matricula, this.planestudiosactivos1 , this.semestrepri).subscribe({
      next:(data : any) => {
        this.matreriasplanpri = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.bmateriaspri = false;
        
        
        
        
      }
    });
  }
  seleccionarmateriapri(){
    let materias = <HTMLSelectElement>document.getElementById('materiaspri');
    this.materiapri = materias.value;
    
  }
//materia relacionada
  seleccionarsemestrerel(){
    let semestre = <HTMLSelectElement>document.getElementById('semestrerel');
    this.semestrerel = semestre.value;

    this.consultamateriasrel();
  }
  consultamateriasrel(){
    this.planestudios.consultarmaterias(this.maestro.matricula, this.planestudiosactivos1 , this.semestrerel).subscribe({
      next:(data : any) => {
        this.matreriasplanrel = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
      }
    });
  }
  seleccionarmateriarel(){
    let materias = <HTMLSelectElement>document.getElementById('materiasrel');
    this.materiarel = materias.value;
    
  }
  edit(event){

  }
//Abre el modal de confirmación de borrado
  delete(event){
    //console.log(event);
    if(event.action =='borrar'){
      this.mat_rel = event.data.materiarid;
      $('#borrar').modal('show');
    }
  }
//borra la relacion 
  borrar(){
    let jason = {
      matriculap: this.maestro.matricula,
      dato:this.mat_rel
    };
    this.planestudios.deleterelacion(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.mat_rel = '';
        $('#borrar').modal('hide');
        this.consultamateriasrelacionadas();
        $('#respuesta').modal('show');
      }
    });
  }
//abre el modal de para guardar una nueva relacion al hace click de agregar
  insert(){
    this.disablesp = false;
    this.disablemp = false;
    this.banderamodal = false;
    this.banderamodal = true;
    this.banderapri = true;
    $('#insert').modal('show');
    
    let semestrepri = <HTMLSelectElement>document.getElementById('semestrepri');
    
    //let  materiaspri = <HTMLSelectElement>document.getElementById('materiaspri');
    this.planestudiosactivos.forEach(element => {
      if(element.id == this.planestudiosactivos1){
        this.planActual = element;
      }
    });
    for (let j = 0; j < this.semestresplan.length; j++) {
      if(this.semestre == this.semestresplan[j].semestre){
        semestrepri.selectedIndex = j+1;
        this.seleccionarsemestrepri();
        this.disablesp = true;
      }
    }

  }

  seleccionarmateriaEx(){
    let m = <HTMLSelectElement>document.getElementById('materiaspri');
    let b :boolean = true;
    setTimeout(() => {
      //console.log(this.matreriasplan);
      for (let x = 0; x < this.matreriasplan.length; x++) {
        if((this.materia ==  this.matreriasplan[x].id )&& b ){
          m.selectedIndex = x+1;
          //console.log(this.matreriasplan[x].id);
          this.seleccionarmateriapri();
          this.disablemp = true;
          b = false;
          //this.bmateriaspri = true;
        }
      }
    }, 200);
  }
//guarda la relacion
  guardar(){
  let jason = {
    matriculap:this.maestro.matricula,
    plan: this.planActual.id,
    mat_pri: this.materiapri,
    mat_rel: this.materiarel
  };

  this.planestudios.insertrelacion(jason).subscribe({
    next:(data : any) => {
      this.respuesta = data;
    },
    error:(err) => {
      console.log(err);
    },
    complete:() => {
      let m = <HTMLSelectElement>document.getElementById('materiasrel');
      let s = <HTMLSelectElement>document.getElementById('materiasrel');
      s.selectedIndex = 0;
      m.selectedIndex = 0;
      $('#insert').modal('hide');
      this.consultamateriasrelacionadas();
      $('#respuesta').modal('show');
    }
  });
  }
}
