<div [formGroup]="calificarForm">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="periodoingreso">Período Escolar</label>
                <select [formControl]="calificarForm.get('periodoid')" class="form-control" id="periodo">
                    <option value="">--Período Escolar--</option>
                    <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
                </select>
            </div>
        </div>  
        <div class="col-6">
            <div class="form-group">
                <label for="fecha">Fecha de Calificación</label>
                <input [formControl]="calificarForm.get('fechacalificacion')" type="date" class="form-control" id="fecha">
              </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="semestre">Semestre</label>
                <select [formControl]="calificarForm.get('semestre')" class="form-control" id="semestre">
                    <option value="">--Semestre--</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                </select>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="calificacion">Calificación</label>
                <input [formControl]="calificarForm.get('calificacion')"  title="Ingrese una valor." type="number" min="0" max="10" maxlength="3" (blur)="validacioncalificaciones()" class="form-control" id="calificacion">
              </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-danger btn-block" (click)="mandarCalificacion()">Guardar Calificación</button>
        </div>
    </div>
</div>

<div class="modal fade" id="validarcalificaciones" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">¡Atención!</h5>
          <button type="button" class="close" (click)="cerrarValidacion()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>La calificación ingresada debe estar en un rango de <strong>0</strong> a <strong>10</strong>, con un máximo de <strong>1 decimal</strong>, <strong>Ejemplo: 0, 8.5, 10.0</strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="cerrarValidacion()">Cerrar</button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
                ¡Atención!
            </h5>
            <button type="button" class="close" (click)="cerrarRespuesta()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    {{ respuesta.mensaje }}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="cerrarRespuesta()">Salir</button>
        </div>
        </div>
    </div>
</div>