
<div>
    <div>
        <table class="table table-striped table-sm table-borderless">
            <thead class="thead-danger text-light">
                <tr>
                    <th>Título</th>
                    <th>Descripción</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="" >
                <tr *ngFor="let tutorial of tutoriales">
                    <td>{{tutorial.titulo}}</td>
                    <td>{{tutorial.descripcion}}</td>
                    <td>
                        <button data-target="#video" type="button" class="btn btn-danger btn-sm mb-1 "  data-dismiss="modal" (click)="onClick(tutorial.url, tutorial.titulo)">
                            <i class="fab fa-youtube text-white "></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div id="video" class="modal fade " tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 *ngFor="let t of titulo" class="modal-title" id="my-modal-title">{{t.titulo}}</h5>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe
                            *ngFor="let url of urls"
                            class="embed-responsive-item p-2"
                            width="700" 
                            height="auto" 
                            [src]="getUrl(url.url)"
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div  id="modalvideo" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                    </div>
                </div>
            </div>
        </div>  -->
    <!-- <a class="ml-2" href="https://www.youtube.com/watch?v={{tutorial.url}}" target="blank">
                        <h5 class="mb-3"><strong >{{tutorial.titulo}}</strong></h5>
                        
                        <figure class="figure">
                            <img [src]="getThumb(tutorial.url,0)" class="figure-img img-fluid rounded" alt="{{tutorial.descripcion}}">
                            <figcaption class="figure-caption ">{{tutorial.descripcion}}.</figcaption>
                        </figure>
                    </a>
                    <hr> -->
                    


</div>

