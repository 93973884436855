import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Global} from 'src/global';

interface Doc{
  nombre: String;
  precio: number;
  matriculap:string;
}
interface DocEdit{
  id:number;
  nombre: String;
  precio: number;
  matriculap:string;
}
interface DocDelete{
  dato:number;
  matriculap:string;
}
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  
  
  constructor(private http: HttpClient, private global: Global) { }
  listaDocumentos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tipodoc/lista/${matricula}`, null);
  }
  listaDocumentosPersonal(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentostodo`, null);
  }
  listaDocumentosAlumno(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentostodo`, null);
  }
  insertar(doc: Doc){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tipodoc/insert`, doc);
  }
  insertarP(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/insert`, doc, {headers: headers});
  }
  insertarA(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/insert`, doc, {headers: headers});
  }
  editar(doc: DocEdit){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tipodoc/editar`, doc);
  }
  editarP(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/edit`, doc, {headers: headers});
  }
  editarA(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/edit`, doc, {headers: headers});
  }
  eliminar(doc: DocDelete){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/tipodoc/delete`, doc);
  }
  eliminarP(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/delete`, doc, {headers: headers});
  }
  eliminarA(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/delete`, doc, {headers: headers});
  }
}
