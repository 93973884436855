import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TramitesService } from '../services/tramites.service';
declare var $ : any;


@Component({
  selector: 'app-generacion',
  templateUrl: './generacion.component.html',
  styleUrls: ['./generacion.component.css']
})
export class GeneracionComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: false,
      height: '50vh',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '2',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    outline:true,
    toolbarHiddenButtons: [
      ['indent', 'outdent', 'heading', 'fontName', 'strikeThrough', 'superscript', 'subscript'],
      ['textColor', 'backgroundColor', 'customClasses', 'unlink', 
      'insertImage', 'insertVideo', 'insertHorizontalRule']
    ]
};

htmlContent = '';
htmlContentWithoutStyles='';
text = '';

documentosDisponibles = [];
documentosRegistrados = [];

documentoNuevoSelecionado;
documentoEditable = {
  contenido:'',
  estado:'',
  id:Number,
  matriculap:'',
  nombre:'',
  tipoid:Number
};


banderabtn:boolean = true;
banderaNuevo:boolean = true;
banderaConsultar:boolean = true;
disabled:boolean = false;
editor:boolean = true;
activos:boolean = true;
botton:boolean = true;
estado = 'Activo';
response = {
  codigo:'',
  detalle:'',
  mensaje:''
}

parametros = [{
  editor:'',
  parametros:''
}]

  constructor(private tramitesService: TramitesService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarDatos();
  }


  consultarDatos(){
    this.tramitesService.tipoId(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.documentosDisponibles = res;
      },
      complete:() => {

      },
      error:(err) => {
        console.log(err);
      }
    });

    this.tramitesService.listarDocumentos(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.documentosRegistrados = res;
      },
      complete:() => {

      },
      error:(err) => {
        console.log(err);
      }
    });

    this.tramitesService.paramestrosdeDocumento(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.parametros = res;
      },
      complete:() => {

      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  cambiarEstado(){
    var activo = <HTMLInputElement>document.getElementById('inlineRadio1');
    var inactivo = <HTMLInputElement>document.getElementById('inlineRadio2');
    if( activo.checked ){
      this.estado = 'Activo';
    }
    if( inactivo.checked ){
      this.estado = 'Inactivo';
    }
  }

  agregarParametro(){
    var parametro = <HTMLInputElement>document.getElementById('variables');
    this.htmlContent += parametro.value;
  }

  


  selecionarDocumento(){
    var doc = <HTMLSelectElement>document.getElementById('docs');

    if(doc.value == 'nuevo'){
      this.banderaNuevo = false;
      this.banderaConsultar = true;
      this.disabled = true;
      this.banderabtn = false;
    }
    if(doc.value == 'registrado'){
      this.banderaConsultar = false;
      this.banderaNuevo = true;
      this.disabled = true;
      this.banderabtn = false;
    }

  }

  generarDocumento(){
    var doc = <HTMLSelectElement>document.getElementById('documentos');
    for(var i = 0; i < this.documentosDisponibles.length; i++){
      if(doc.value == this.documentosDisponibles[i].id){
        this.documentoNuevoSelecionado = this.documentosDisponibles[i];
        break;
      }
    }
    this.activos = false;
    this.editor = false;
    this.botton = false;
  }

  editarDocumento(){
    var doc =  <HTMLSelectElement>document.getElementById('consultar');
    console.log(doc.value);
    for(var i = 0; i < this.documentosRegistrados.length; i++){
      if(doc.value == this.documentosRegistrados[i].id){
        this.documentoEditable = this.documentosRegistrados[i];
      }
    }
    this.htmlContent = this.documentoEditable.contenido;
    this.activos = false;
    this.editor = false;
    this.botton = false;
  }

  reiniciar(){
    var doc = <HTMLSelectElement>document.getElementById('docs');
    doc.selectedIndex = 0;
    var documentos = <HTMLSelectElement>document.getElementById('documentos');
    documentos.selectedIndex = 0;
    var consultar =  <HTMLSelectElement>document.getElementById('consultar');
    consultar.selectedIndex = 0;
    this.disabled = false;
    this.banderaNuevo = true;
    this.banderaConsultar = true;
    this.activos = true;
    this.editor = true;
    this.botton = true;
  }

  abrirAviso(){
    $('#aviso').modal('show');
  }

  enviarDatos(){
    if(this.banderaConsultar == false){
      var jason = {
        id:this.documentoEditable.id,
        contenido:this.htmlContent,
        nombre:this.documentoEditable.nombre,
        estado:this.documentoEditable.estado,
        matriculap:this.maestro.matricula,
        tipoid:this.documentoEditable.tipoid
      }
      this.tramitesService.enviarFormatoEditado(jason).subscribe({
        next:(res : any) => {
          this.response = res;
        },
        complete:() => {
          $('#respuesta').modal('show');
        },
        error:(err) => {
          console.log(err);
        }
      });
    }
    if(this.banderaNuevo == false){
      var jasn = {
        contenido:this.htmlContent,
        nombre:this.documentoNuevoSelecionado.nombre,
        estado:this.estado,
        matriculap:this.maestro.matricula,
        tipoid:this.documentoNuevoSelecionado.id
      }
      this.tramitesService.enviarFormato(jasn).subscribe({
        next:(res : any) => {
          this.response = res;
        },
        complete:() => {
          $('#respuesta').modal('show');
        },
        error:(err) => {
          console.log(err);
        }
      });
    }
    this.consultarDatos();
  }

  // reset(){
  //   this.banderaNuevo = true;
  //   this.banderaConsultar = true;
  //   this.disabled = false;
  //   this.editor = true;
  //   this.activos = true;
  //   this.botton = true;
  // }

  showHTML(){
    this.text = this.htmlContent;
  }

  // agregarTexto(){
  //   // $("#add").click( () => {
  //   //   // $('#txtarea').html('{{ALUMNO_SEMESTRE}}');
  //   //   this.htmlContent.concat('{{ ALUMNO_SEMESTRE }}');
  //   // });
  //   console.log(this.htmlContent);
  // }

}
