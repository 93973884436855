<div>
    <h5>Departamentales</h5>
    <div *ngIf="!bandera" class="mt-3">
        <button class="btn btn-danger mb-2" (click)="abrirCrear()">
            <i class="fas fa-plus"></i>
            Agregar
        </button>
        <ng2-smart-table class="table table-hover table-borderless" (custom)="acciones($event)" [settings]="settings" [source]="src"></ng2-smart-table>
    </div>
    <!-- spinner boostrap de carga centrado -->
    <div class="text-center text-danger" *ngIf="bandera">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- modal de crear un nuevo departamental -->
    <div class="modal fade" id="crearDepartamental" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear</h5>
                    <button type="button" class="close" (click)="cerrarCrear()"  aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <select class="form-control" id="planestudios"  (change)="seleccionarplan()">
                                <option>-- Plan de estudios --</option>
                                <option *ngFor="let plan of planestudiosactivos" value="{{ plan.id }}">{{ plan.carrera.nombre }} - {{ plan.nombre }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <select class="form-control" id="materias" [formControl]="this.formCreate.get('materia')" (change)="seleleccionarMateria()">
                                <option value="">-- Materia --</option>
                                <option *ngFor="let materia of mpe" value="{{ materia.id }}">{{ materia.materia.nombre }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <select class="form-control" id="rol" [formControl]="this.formCreate.get('rol')" (change)="seleleccionarRol()" >
                                <option value="">-- Rol --</option>
                                <option *ngFor="let rol of rolesactivos" value="{{ rol.id }}">{{ rol.nombre }} - {{ rol.descripcion }}</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="cerrarCrear()">Cerrar</button>
                    <button type="button" class="btn btn-danger"[disabled]="this.formCreate.invalid" (click)="crear()">Crear</button>
                </div>
            </div>
        </div>
    </div>
    <!-- modal de aviso -->
    <div class="modal fade" id="borrarDepartamental" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    La materia de <b>{{datos.materia}}</b> a cargo de {{datos.nombre_rol}} <b>dejará de ser considerada departamental</b> ¿Desea aplicar este cambio?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-danger" (click)="borrar()" >Aceptar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- modal de aviso de respuesta -->
    <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Atención!</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{respuesta.mensaje}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    
</div>
