<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Alta</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                                <div class=""><h3>Alumnos nuevo ingreso</h3></div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-lg-12 mt-2">
                        <div class="form-group">
                            
                                    
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="form-group">
                                                    <div class="row" id="top">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <select class="form-control" id="periodoescolar" (change)="seleccionarperiodo()">
                                                                    <option>-- Períodos Escolares --</option>
                                                                    <option *ngFor="let periodo of periodosabiertos" value="{{ periodo.id }}">{{ periodo.nombre }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6" *ngIf="!banderacarrera">
                                                            <div class="form-group">
                                                                <select class="form-control" id="carrera" (change)="seleccionarcarrera()">
                                                                    <option>-- Carreras --</option>
                                                                    <option *ngFor="let carrera of carrerasactivos" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                            </div>
                                        </div>
                                        <div class="col-4">  
                                            <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir archivo</label>
                                            <input type="file" id="documento"  enctype="multipart/form-data" accept=".xlsx" (change)="onFileSelect($event)" style="display: none">
                                        </div> 
                                        
                                        <div class="col-12">
                                            <button type="submit" (click)="enviar()" class="float-right btn btn-danger"  [disabled]="this.uploadForm.get('file').value.size > 3145728 || this.uploadForm.get('file').value == '' ">Enviar</button>
                                        </div>
                                    </div>      
                                
                            
                        </div>
                    </div>

                </div>
                    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                            {{ respuesta.mensaje }}
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>
