import { Component, OnInit } from '@angular/core';
import { AdministracionService } from '../services/administracion.service';
declare var $: any;


@Component({
  selector: 'app-inscripciones-sesco',
  templateUrl: './inscripciones-sesco.component.html',
  styleUrls: ['./inscripciones-sesco.component.css']
})
export class InscripcionesSescoComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }

  periodoescolaractivos = [];
  periodosabiertos = [];
  carrerasactivos = [];
  dataE = [];
  semestresactivos = [];
  semestresfiltrados = [];
  semestre;
  banderabtn:boolean = true;
  banderacarrera:boolean = true;
  banderasemestres:boolean = true;
  periodoidseleccionado;
  planestudiosseleccionado;
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  };

  constructor(private administracionService: AdministracionService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
    this.consultarcarrerasactivos();
  }

  mandarasesco(){
    this.administracionService.mandarinscripcionesasesco(this.periodoidseleccionado, this.planestudiosseleccionado, this.semestre).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  seleccionarsemestre(){
    var semestre = <HTMLSelectElement>document.getElementById('semestre');
    this.semestre = semestre.value;
    this.banderabtn = false;
  }

  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    this.periodoidseleccionado = periodoid.value;
    this.banderacarrera = false;
  }

  seleccionarcarrera(){
    this.semestresfiltrados = [];
    var idplanstudios = <HTMLSelectElement>document.getElementById('carrera');
    this.planestudiosseleccionado = idplanstudios.value;
    this.consultarsemestresactivos();
  }

  consultarperiodoescolares(){
    this.administracionService.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodoescolaractivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodoescolaractivos.length; i++){
          if(this.periodoescolaractivos[i].estado == "ABIERTO" || "ACTUAL"){
            this.periodosabiertos.push(this.periodoescolaractivos[i]);
          }
        }
        console.log(this.periodoescolaractivos);
        this.consultarcarrerasactivos();
      }
    });
  }

  consultarcarrerasactivos(){
    this.administracionService.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carrerasactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for( var i = 0; i < this.carrerasactivos.length; i++ ){
          if(this.carrerasactivos[i].estatus == "S"){
            this.dataE.push(this.carrerasactivos[i]);
          }
        }
      }
    });
  }

  consultarsemestresactivos(){
    this.administracionService.consultarsemestres(this.maestro.matricula, this.planestudiosseleccionado).subscribe({
      next:(data : any) => {
        this.semestresactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semestresactivos.sort(function (a, b) {
          if (a.semestre > b.semestre) {
            return 1;
          }
          if (a.semestre < b.semestre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.banderasemestres = false;

      }
    });
  }


}
