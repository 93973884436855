import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuditoriaRoutingModule } from './auditoria-routing.module';
import { AuditoriaInicioComponent } from './auditoria-inicio/auditoria-inicio.component';
import { AuditoriaInscripcionesComponent } from './inscripciones/inscripciones.component';
import { AuditoriaLoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AuditoriaGruposComponent } from './grupos/grupos.component';
import { AuditoriaGrupoHorariosComponent } from './grupo-horarios/grupo-horarios.component';
import { AuditoriaPreactaComponent } from './preacta/preacta.component';
import { AuditoriaCalendarioComponent } from './calendario/calendario.component';
import { AuditoriaCalendarioExamenesComponent } from './calendario-examenes/calendario-examenes.component';
import { AvisosComponent } from './avisos/avisos.component';
import { AuditoriaAlumnosComponent } from './alumnos/alumnos.component';
import { AlumnoPleComponent } from './alumno-ple/alumno-ple.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuariosRolesComponent } from './usuarios-roles/usuarios-roles.component';
import { AuditoriaRolesComponent } from './auditoria-roles/auditoria-roles.component';
import { AuditoriaPermisosComponent } from './auditoria-permisos/auditoria-permisos.component';
import { AuditoriaLoginPersonalComponent } from './auditoria-login-personal/auditoria-login-personal.component';
import { AuditoriaDatosComplementariosComponent } from './auditoria-datos-complementarios/auditoria-datos-complementarios.component';
import { AuditoriaMateriasComponent } from './auditoria-materias/auditoria-materias.component';
import { AuditoriaPagosComponent } from './auditoria-pagos/auditoria-pagos.component';
import { AuditoriaVistaComponent } from './auditoria-vista/auditoria-vista.component';
import { BitacoraComponent } from './bitacora/bitacora.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

@NgModule({
  declarations: [AuditoriaInicioComponent, AuditoriaInscripcionesComponent, AuditoriaLoginComponent, AuditoriaGruposComponent, AuditoriaGrupoHorariosComponent, AuditoriaPreactaComponent, AuditoriaCalendarioComponent, AuditoriaCalendarioExamenesComponent, AvisosComponent, AuditoriaAlumnosComponent, AlumnoPleComponent, UsuariosComponent, UsuariosRolesComponent, AuditoriaRolesComponent, AuditoriaPermisosComponent, AuditoriaLoginPersonalComponent, AuditoriaDatosComplementariosComponent, AuditoriaMateriasComponent, AuditoriaPagosComponent, AuditoriaVistaComponent, BitacoraComponent],
  imports: [
    CommonModule,
    AuditoriaRoutingModule,
    SharedModule,
    AngularMyDatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2SmartTableModule,
    NgxJsonViewerModule
  ]
})
export class AuditoriaModule { }
