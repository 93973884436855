<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
<h2 class="mb-4">Administración del Plan de Estudios</h2>
<div class="row" id="top">
    <div class="col-6 mb-2" *ngIf="bandera">
        <button class="btn btn-danger" (click)="agregar()"><i class="fa fa-plus" aria-hidden="true"></i> Agregar</button>
    </div>
    <div class="col-12" *ngIf="bandera">
        <ng2-smart-table class="table table-hover table-borderless" (custom)="edit($event)" [settings]="settings" [source]="filtroPlanEstudio"></ng2-smart-table>
    </div>
</div>

<!-- modal del respuesta -->
<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- modal para insert -->
<div class="modal fade" id="insert" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Agregar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <label for="nombre">Nombre del plan de estudio</label>
                        <input type="text" [formControl]="this.formPlanEstudio.get('planestudio').get('nombre')" class="form-control" id="nombre" placeholder="Nombre del plan de estudio">
                    </div>
                    <div class="col-6">
                        <label for="nombre">Clave oficial (SESCO)</label>
                        <input type="number" [formControl]="this.formPlanEstudio.get('planestudio').get('clave')" class="form-control" id="nombre" placeholder="Clave del plan de estudio">
                    </div>
                    <div class="col-6">
                        <label for="nombre">Semestres</label>
                        <input type="number" [formControl]="this.formPlanEstudio.get('planestudio').get('semestres')" class="form-control" id="nombre" placeholder="Semestres">
                    </div>
                    <div class="col-6">
                        <label for="nombre">Calificación minima</label>
                        <input type="number" [formControl]="this.formPlanEstudio.get('planestudio').get('calificacionminima')" class="form-control" id="nombre" placeholder="Calificación minima">
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="carreras">Carrera</label>
                            <select id="carreras" [formControl]="this.formPlanEstudio.get('planestudio').get('carreraid')" class="form-control" name="carrera">
                                <option *ngFor="let c of carreras" [value]="c.id">{{c.nombre}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" [disabled]="this.formPlanEstudio.invalid" (click)="insert()">Aceptar</button>
                <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- modal para editar -->
<div class="modal fade" id="editar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Editar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <a class="nav-link active" id="nav-Editar-tab" data-toggle="tab" href="#nav-Editar" role="tab" aria-controls="nav-Editar" aria-selected="true">Editar</a>
                        <a class="nav-link" id="nav-Listado-tab" data-toggle="tab" href="#nav-Listado" role="tab" aria-controls="nav-Listado" aria-selected="false">Listado</a>
                        <a class="nav-link" id="nav-AgregarM-tab" data-toggle="tab" href="#nav-AgregarM" role="tab" aria-controls="nav-AgregarM" aria-selected="false">Agregar materia</a>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                <!-- Tab de Editar -->
                    <div class="tab-pane fade show active" id="nav-Editar" role="tabpanel" aria-labelledby="nav-Editar-tab">
                        <div class="row">
                            <div class="col-6">
                                <label for="nombre_edit">Nombre del plan de estudio</label>
                                <input type="text" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('nombre')" class="form-control" id="nombre_edit" placeholder="Nombre del plan de estudio">
                            </div>
                            <div class="col-6">
                                <label for="nombre">Clave oficial (SESCO)</label>
                                <input type="number" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('clave')" class="form-control" id="nombre" placeholder="Clave del plan de estudio">
                            </div>
                            <div class="col-6">
                                <label for="nombre">Semestres</label>
                                <input type="number" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('semestres')" class="form-control" id="nombre" placeholder="Semestres">
                            </div>
                            <div class="col-6">
                                <label for="nombre">Calificación minima</label>
                                <input type="number" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('calificacionminima')" class="form-control" id="nombre" placeholder="Calificación minima">
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="carreras">Carrera</label>
                                    <select id="carreras" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('carreraid')" class="form-control" name="carrera">
                                        <option *ngFor="let c of carreras" [value]="c.id">{{c.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="carreras">Carrera</label>
                                    <select id="carreras" [formControl]="this.formPlanEstudioEdit.get('planestudio').get('estatus')" class="form-control" name="estatus">
                                        <option value="S">Activo</option>
                                        <option value="N">Inactivo</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-danger" [disabled]="this.formPlanEstudioEdit.invalid" (click)="insertEdit()">Guardar</button>
                    </div>
                <!-- Tab de qitar materias -->
                    <div class="tab-pane fade show " id="nav-Listado" role="tabpanel" aria-labelledby="nav-Listado-tab">
                        <div *ngIf="banderamaterias">
                            <ng2-smart-table class="table table-hover table-borderless" (custom)="quitarMaterias($event)" [settings]="settings2" [source]="filtroMateriasPL"></ng2-smart-table>
                        </div>
                    </div>
                <!-- Tab de agregar materias -->
                    <div class="tab-pane fade show " id="nav-AgregarM" role="tabpanel" aria-labelledby="nav-AgregarM-tab">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group" *ngIf="banderaMateriaN">
                                    <label for="semestrempe">Semestre</label>
                                    <select id="semestrempe" autocomplete="off" [formControl]="this.formMateria.get('semestre')" class="form-control" name="Materia">
                                        <option *ngFor="let m of semestres " [value]="m.semestre">{{m.semestre}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group" *ngIf="banderaMateriaN">
                                    <label for="materiasmpe">Materia</label>
                                    <select id="materiasmpe" autocomplete="off"  [formControl]="this.formMateria.get('materiaid')" class="form-control" name="Materia">
                                        <option *ngFor="let m of materiadisponible " [value]="m.id">{{m.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiasmpe">Electiva</label>
                                    <select id="materiasmpe" autocomplete="off"  [formControl]="this.formMateria.get('electiva')" class="form-control" name="Materia">
                                        <option value="0">NO</option>
                                        <option value="1">SI</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="clavempe">Clave oficial (SESCO) </label>
                                <input type="text" autocomplete="off"  [formControl]="this.formMateria.get('clave')" class="form-control" id="clavempe" placeholder="Clave">
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="visiblempe">Visible</label>
                                    <select id="visiblempe" autocomplete="off"  [formControl]="this.formMateria.get('visible')" class="form-control" name="Materia">
                                        <option value="0">NO</option>
                                        <option value="1">SI</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="cprommpe">¿Cuenta para el promedio?</label>
                                    <select id="cprommpe" autocomplete="off"  [formControl]="this.formMateria.get('cuentapromedio')" class="form-control" name="Materia">
                                        <option value="0">NO</option>
                                        <option value="1">SI</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="ordenmpe">Orden</label>
                                <input type="number" autocomplete="off" [formControl]="this.formMateria.get('orden')" class="form-control" id="ordenmpe" placeholder="Orden">
                            </div>
                            <div class="col-6">
                                <label for="renglonpe">Renglon</label>
                                <input type="number" autocomplete="off" [formControl]="this.formMateria.get('renglon')" class="form-control" id="renglonpe" placeholder="Renglon">
                            </div>
                        </div>                            
                        <button type="button" class="btn btn-danger mt-2" [disabled]="this.formMateria.invalid" (click)="agregarMaterias()">Agregar</button>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
                
                <button type="button" class="btn btn-secundary" (click)="cerrarModalEdit()">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- modal del aviso de borrar -->
<div class="modal fade" id="aviso" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                ¿Estas seguro que deseas eliminar la materia <b>{{Mpe.materia}} </b> de {{Mpe.semestre}} semestre?, recueda que este proceso es <b>irreversible</b>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="deleteM()">Aceptar</button>
                <button type="button" class="btn btn-secundary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<!-- Editar materia plan de estudio -->
<div class="modal fade" id="editMpe" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Editar Materias del plan de estudio</h5>
                <button type="button" class="close" (click)="cerrarModalEditForm()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div> 
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group" *ngIf="banderaMateriaN">
                            <label for="semestrempee">Semestre</label>
                            <select id="semestrempee" [formControl]="this.formEditMateria.get('semestre')" class="form-control" name="Materia">
                                <option *ngFor="let m of semestres " [value]="m.semestre">{{m.semestre}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="form-group" *ngIf="banderaMateriaN">
                            <label for="materiasmpee">Materia</label>
                            <select id="materiasmpee" [formControl]="this.formEditMateria.get('materiaid')" class="form-control" name="Materia">
                                <option *ngFor="let m of materiadisponible " [value]="m.id">{{m.nombre}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-6">
                        <label for="materiaee">Materia</label>
                        <input type="text" [disabled]="true" [formControl]="this.formEditMateria.get('materia')" class="form-control" id="materiaee" placeholder="Materia">
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="materiasmpee">Electiva</label>
                            <select id="materiasmpee" [formControl]="this.formEditMateria.get('electiva')" class="form-control" name="Materia">
                                <option value="0">NO</option>
                                <option value="1">SI</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="clavempee">Clave oficial</label>
                        <input type="text" [formControl]="this.formEditMateria.get('clave')" class="form-control" id="clavempee" placeholder="Clave">
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="visiblempee">Visible</label>
                            <select id="visiblempee" [formControl]="this.formEditMateria.get('visible')" class="form-control" name="visible">
                                <option value="0">NO</option>
                                <option value="1">SI</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="cprommpee">¿Cuenta para el promedio?</label>
                            <select id="cprommpee" [formControl]="this.formEditMateria.get('cuentapromedio')" class="form-control" name="cuenta">
                                <option value="0">NO</option>
                                <option value="1">SI</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="ordenmpee">Orden</label>
                        <input type="number" [formControl]="this.formEditMateria.get('orden')" class="form-control" id="ordenmpee" placeholder="Orden">
                    </div>
                    <div class="col-6">
                        <label for="renglonpee">Renglon</label>
                        <input type="number" [formControl]="this.formEditMateria.get('renglon')" class="form-control" id="renglonpee" placeholder="Renglon">
                    </div>
                </div>                            
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" [disabled]="this.formEditMateria.invalid" (click)="editMaterias()">Aceptar</button>
                <button type="button" class="btn btn-secundary" (click)="cerrarModalEditForm()">Cerrar</button>
            </div>
        </div>
    </div>
</div>

</body>
</html>
