import { Component, OnInit, AfterContentInit } from '@angular/core';
import { MaestroService } from '../services/maestro.service';
import { Router } from '@angular/router';
import { Global } from 'src/global';
import { saveAs } from 'file-saver';
import { tr } from 'date-fns/locale';
declare var $ : any;


@Component({
  selector: 'app-maestro',
  templateUrl: './maestro.component.html',
  styleUrls: ['./maestro.component.css']
})
export class MaestroComponent implements OnInit {

  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;
  banderaspinner:boolean = true;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  tipo;
  materias = [];
  pendientes = [];
  bandera:boolean = true;
  Calificaciones:boolean = true;
  banderaasync:boolean = true;
  id;
  tipocalificaicones = [];
  idgrupos = [];
  arreglopendientes = [];
  datos = [];
  banderapendientes:boolean = true;
  banderadocumento:boolean = true;
  banderasinmaterias:boolean = true;
  nombredegrupo = '';
  materiaid='';
  

  access = [];
  banderaVisualizarListaPDF : boolean = true;
  banderaImprimirPDFListaPDF : boolean = true;
  banderaTomarAsistencia : boolean = true;
  banderaImprimirAsistencia : boolean = true;
  banderaCapturarCalificaciones : boolean = true;

  constructor(private maestroService: MaestroService, private router: Router, private global: Global) {}

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10003 || this.access[i] == 3){
        this.banderaVisualizarListaPDF = false;
      }
      if(this.access[i] == 10004 || this.access[i] == 3){
        this.banderaImprimirPDFListaPDF = false;
      }
      if(this.access[i] == 10005 || this.access[i] == 3){
        this.banderaTomarAsistencia = false;
      }
      if(this.access[i] == 10007 || this.access[i] == 3){
        this.banderaImprimirAsistencia = false;
      }
      if(this.access[i] == 10008 || this.access[i] == 3){
        this.banderaCapturarCalificaciones = false;
      }
    }
    this.maestroService.maestrosGrpAl(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.materias = res;
        localStorage.setItem('grupos', JSON.stringify(this.materias));
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        if(this.materias.length == 0){
          this.banderaspinner = false;
          this.banderasinmaterias = false;
        }else{
          if(this.materias.length >= 0){
            // this.consultartipocalficacion();
            for(var i = 0; i < this.materias.length; i++ ){
              if(this.materias[i].calificacionesenrevision != 0){
                this.banderapendientes = false;
              }
            }
          }
          this.banderaspinner = false;
        }
      }
    });
  }

  visualizarlistapdf(nombredegrupo, id){
    this.materiaid = id;
    this.nombredegrupo = nombredegrupo;
    
    $('#documento').modal('show');
    this.banderadocumento = false;
  }

  cerrarpdf(){
    $('#documento').modal('hide');
    this.banderadocumento = true;
  }

  consultartipocalficacion(){
    this.banderaasync = true;
    for(var i = 0; i < this.materias.length; i++){
      this.idgrupos.push(this.materias[i].id);
      var jason = {
        dato:this.materias[i].id,
        matriculap:this.maestro.matricula
      }
      this.maestroService.tipocalificacion(jason).subscribe({
        next:(res : any) => {
          this.tipo = res;
          this.tipocalificaicones.push(this.tipo.id);
        },
        error:(err) => {
          this.httpstatus = err.status;
          this.httpstatustext = err.statusText;
          this.httpurl = err.url;
          this.banderahttp = false;
        },
        complete:() => {
          if(this.tipocalificaicones.length === this.materias.length){
            for(var x = 0; x < this.tipocalificaicones.length; x++){
              var jason2 = {
                dato:this.idgrupos[x],
                dato2:this.tipocalificaicones[x],
                dato3:this.maestro.matricula
              }
              this.maestroService.consultarnumerodependientes(jason2).subscribe({
                next:(res: any) => {
                  this.pendientes = res;
                  this.arreglopendientes.push(this.pendientes);
                },
                error:(err) => {
                  this.httpstatus = err.status;
                  this.httpstatustext = err.statusText;
                  this.httpurl = err.url;
                  this.banderahttp = false;
                },
                complete:() => {
                  if(this.idgrupos.length === this.arreglopendientes.length){
                    for(var i = 0; i < this.materias.length; i++){
                      for(var x = 0; x < this.arreglopendientes.length; x++){
                        if(this.materias[i].id == this.arreglopendientes[x].matriculap){
                          if(this.arreglopendientes[x].dato > 0){
                            this.banderapendientes = false;
                          }
                          var jason = {
                            grupoid:this.materias[i].id,
                            dato:parseInt(this.arreglopendientes[x].dato)
                          }
                          this.datos.push(jason);
                        }
                      }
                    }
                    this.banderaasync = false;
                  }
                  this.banderaspinner = false;
                }
              });
            }
          }else{
          }
        }
      });
    }
  }

  consultarpendientes(tipo){
    var jason2 = {
      dato:this.id,
      dato2:tipo,
      dato3:this.maestro.matricula
    }
    this.maestroService.consultarnumerodependientes(jason2).subscribe({
      next:(res: any) => {
        this.pendientes = res;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
      }
    });
  }

  correciones(){
    $('#staticBackdropCalificaciones').modal('show');
    this.Calificaciones = false;
  }

  verGrupo(){
    this.maestroService.alumGr(this.materiaid,this.maestro.matricula);
  }

  imprimirConcentrado(id){
    $('#loading').modal('show');
    this.maestroService.imprmirListas(id,this.maestro.matricula).subscribe({
      next:(res) => {
        saveAs(res, `${id}.xlsx`);
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        this.bandera = false;
      }
    });
  }

}
