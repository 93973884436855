import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuraciones-g',
  templateUrl: './configuraciones-g.component.html',
  styleUrls: ['./configuraciones-g.component.css']
})
export class ConfiguracionesGComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  access = [];
  banderaRoles : boolean = true;
  banderaNotificaciones : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.viewBtn();
  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10089 || this.access[i] == 3){
        this.banderaRoles = false;
      }
      if(this.access[i] == 10125 || this.access[i] == 3){
        this.banderaNotificaciones = false;
      }
    }
  }

}
