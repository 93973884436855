import { Component, Input, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $ : any;
declare var _ : any;
@Component({
  selector: 'app-justificantes',
  templateUrl: './justificantes.component.html',
  styleUrls: ['./justificantes.component.css']
})
export class JustificantesComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  };
  settings = {
    columns:{
      alumno:{
        title:'Alumno',
        editable:false,
        width:'10%'
      },
      materia:{
        title:'Materia',
        editable:false,
        width:'25%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'15%'
      },
      maestro: {
        title:'Maestro',
        editable:false,
        width:'20%'
      },
      fecha: {
        title:'Fecha',
        editable:false,
        width:'20%'
      },
      justificado: {
        title:'Justificado',
        editable:false,
        width:'10%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  };
  busquedaForm = new FormGroup({
    inicio: new FormControl('', [Validators.required]),
    fin: new FormControl('', [Validators.required]),
    matricula: new FormControl(''),
    matriculap: new FormControl('')
  });
  uploadForm = new FormGroup({
    id: new FormControl(''),
    archivo: new FormControl('', [Validators.required]),
    comentario: new FormControl('', [Validators.required]),
    matriculap: new FormControl('')
  });
  @Input() alumno: any;
  faltas = [];
  faltasfiltradas = [];
  banderatabla: boolean = true;
  banderacarga: boolean = false;
  banderaboton: boolean = true;
  idalumnoasistencia = 0;
  respuesta ={
    codigo:'',
    detalle:'',
    mensaje:''
  };
  archivo = "";
  constructor(
    private personal: PersonalService
  ) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }

  buscar(){
    this.banderatabla = true;
    this.banderacarga = true;
    this.busquedaForm.get('matricula').patchValue(this.alumno);
    this.busquedaForm.get('matriculap').patchValue(this.maestro.matricula);
    this.personal.consultarfaltas(this.busquedaForm.value).subscribe({
      next:(res:any) => {
        this.faltas = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.faltas.length; i++){
          let json = {
            id:this.faltas[i].id,
            alumno: this.faltas[i].alumno.apellidop + ' ' + this.faltas[i].alumno.apellidom + ' ' + this.faltas[i].alumno.nombres,
            matricula: this.faltas[i].alumno.matricula,
            materia: this.faltas[i].grupo.mpe.materia.nombre + ' ' + this.faltas[i].grupo.alias,
            semestre: this.faltas[i].grupo.mpe.semestre + this.faltas[i].grupo.nombre,
            maestro: this.faltas[i].grupo.maestro.persona.apellidop + ' ' + this.faltas[i].grupo.maestro.persona.apellidom + ' ' + this.faltas[i].grupo.maestro.persona.nombres,
            asistencia: this.faltas[i].asistencia,
            justificado: this.faltas[i].justificado,
            fecha: this.faltas[i].fecha
          }
          this.faltasfiltradas.push(json);
        }
        this.banderacarga = false;
        this.banderatabla = false;
      }
    });
  }

  salir(){
    this.idalumnoasistencia = 0;
    this.archivo = "";
    $('#justificar').modal('hide');
    console.log("sali");
  }

  enviar(){
    this.banderaboton = false;
    
    var jason = {
      alumnosAsistencia:{id:this.uploadForm.get('id').value},
      archivo: this.uploadForm.get('archivo').value,
      comentario: this.uploadForm.get('comentario').value,
      matriculap:this.uploadForm.get('matriculap').value
    }
    console.log(jason);
    
    this.personal.insertJusticantes(jason).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
          
          this.uploadForm.reset();
          this.banderaboton = true;

          $('#justificar').modal('hide');
          document.getElementById('filename').innerHTML = 'Subir archivos';
          this.salir();
          $('#mensajenew').modal('show');

          
      }
    });
  }
  abrir(event){
    this.idalumnoasistencia = event.data.id;
    console.log(this.idalumnoasistencia);
    this.uploadForm.get('id').patchValue(this.idalumnoasistencia);
    this.uploadForm.get('matriculap').patchValue(this.maestro.matricula);
    
    $('#justificar').modal('show');
  }
  
  closeReply(){
    $('#mensajenew').modal('hide');
    $('#justificantes').modal('show');
  }
  handleFileSelect(evt){
    //console.log(evt);

    var files = evt.target.files;
    var file = files[0];
    if (file.size<1000000){
      
        if(file.type == "application/pdf"){
          if (files && file) {
              var reader = new FileReader();
              reader.onload =this._handleReaderLoadedpdf.bind(this);
              reader.readAsBinaryString(file);
              document.getElementById('filename').innerHTML = `${file.name}`;
          }
        }
      
    }else {
      alert("El archivo debe ser menor a 1 MB");
      
    }
  }
  _handleReaderLoadedpdf(readerEvt) {

    var binaryString = readerEvt.target.result;
    
    this.archivo = btoa(binaryString);
    this.uploadForm.get('archivo').patchValue(this.archivo);
    //console.log(this.archivo);
    

  }

}
