<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>

            <div class="col-lg-10">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-7">

                        <div class="card">
                            <div class="card-body">
                                <h3>Plan de Estudios</h3>
                                <hr>
                                <nav>
                                    <a class="card-link" routerLink="/plandeestudios/mapa" routerLinkActive="active">PDF</a>
                                    <!-- <a class="card-link" routerLink="/administracion/inscripciones" routerLinkActive="active">Programar Inscripción</a>
                                    <a class="card-link" routerLink="/administracion/listado" routerLinkActive="active">Listado Inscripciones</a>
                                    <a class="card-link btn btn-link dropdown-toggle shadow-none" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Catálogos</a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" routerLink="/administracion/aulas" routerLinkActive="active">Aulas</a>
                                        <a class="dropdown-item" routerLink="/administracion/materias" routerLinkActive="active">Materias</a>
                                        <a class="dropdown-item" routerLink="/administracion/grupos" routerLinkActive="active">Grupos</a>
                                        <a class="dropdown-item" routerLink="/administracion/documentos" routerLinkActive="active">Documentos</a>
                                        <a class="dropdown-item" routerLink="/administracion/clasificaciones" routerLinkActive="active">Clasificaciones</a>
                                        <a class="dropdown-item" routerLink="/administracion/notificaciones" routerLinkActive="active">Notificaciones</a>
                                    </div> -->
                                </nav>
                            </div>
                          </div>
                          
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
</html>