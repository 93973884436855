import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as moment from 'moment'  

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  alumnos = [];

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'],  };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileAlumnos(json: any[]): void {
    const titulo = 'Alumnos';
    const fecha = moment().format('DD/MM/YYYY');
    const fecha2 = moment().format('DDMMYYYY');
    const data = json;
    const cabeceras = ['Apellido Paterno', 'Apellido Materno', 'Nombre', 'Matrícula', 
    'Semestre', 'Carrera', 'Estatus', 'Regular','Período Ingreso','Período Egreso'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Alumnos');
    let titulofila = worksheet.addRow([titulo]);
    titulofila.font = { name: 'Arial', family: 4, size: 20, bold: true };
    worksheet.addRow([]);
    let fechafila = worksheet.addRow(['Fecha de generación: '+fecha]);
    fechafila.font = { name: 'Arial', family: 4, size: 16, bold: true };
    let headerRow = worksheet.addRow(cabeceras);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      let row = worksheet.addRow(d);
    });
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30;
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `Alumnos_${fecha2}.xlsx`);
    });
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

 
}