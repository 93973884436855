<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
          <div *ngIf="banderatabla" class="d-flex justify-content-center">
            <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="row" id="top"></div>
          <div class="row" id="top" *ngIf="!banderatabla">
            <div class="col-lg-4">
              <h6><strong>Alumno:  </strong>{{ alumno.nombres }} {{ alumno.apellidop }} {{ alumno.apellidom }}</h6>
            </div>
            <div class="col-lg-3">
              <h6><strong>Carrera:  </strong>{{ alumno.carrera }}</h6>
            </div>
          </div>

          <div class="row" id="top" *ngIf="!banderatabla">
            <div class="col-lg-2">
              <h6><strong>Matrícula:  </strong>{{ alumno.matricula }}</h6>
            </div>
            <div class="col-lg-2">
              <h6><strong>Regular:  </strong>{{ alumno.regular }}</h6>
            </div>
            <div class="col-lg-2">
              <h6><strong>Estatus:  </strong>{{ alumno.estatus }}</h6>
            </div>
            <div class="col-lg-2">
              <h6><strong>Semestre:  </strong>{{ alumno.semestre }}</h6>
            </div>
          </div>

        <div *ngIf="!banderatabla" class="row" id="top">
            <div class="col-lg-12">
              <table class="table table-borderless table-hover table-sm">
                <thead class="bg-danger text-white">
                  <tr>
                    <th class="materia">Materia</th>
                    <th class="grupo">Grupo</th>
                    <th class="maestro">Maestro</th>
                    <th class="matricula">Matricula</th>
                    <th class="calificacion" *ngIf="!banderacalificacion">Calificación</th>
                    <th class="btn" *ngIf="!banderabtn"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let materia of tabla">
                    <td>{{ materia.nombre }}</td>
                    <td>{{ materia.semestre }}{{ materia.grupo }}</td>
                    <td>{{ materia.apellidop }} {{ materia.apellidom }} {{ materia.nombres }}</td>
                    <td>{{ materia.matricula }}</td>
                    <td *ngIf="!banderacalificacion">
                      <label *ngIf="materia.calificacion == -99">NP</label>
                      <label *ngIf="materia.calificacion == -3">NTD</label>
                      <label *ngIf="(materia.calificacion != 0) && (materia.calificacion != -3) && (materia.calificacion != -99)">{{ materia.calificacion }}</label>
                      <label *ngIf="materia.calificacion == 0"></label>
                    </td>
                    <td *ngIf="!banderabtn"><button class="btn btn-danger btn-sm"  *ngIf="materia.firmado > 0 || materia.revision > 0" (click)="mostraraviso1(materia, materia.id)">Permitir cambio de calificación</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="modal fade" id="aviso1" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" (click)="cerraraviso1()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div *ngIf="!banderamateria">
                    <p>¿Está seguro de permitir el cambio de calificación de la materia “<strong>{{ materia.nombre }}</strong>” al alumno “<strong>{{ alumnocompleto.apellidop }} {{ alumnocompleto.apellidom }} {{ alumnocompleto.nombres }}</strong>”?</p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="mostraraviso2()">Aceptar</button>
                  <button type="button" class="btn btn-secondary" (click)="cerraraviso1()">Cerrar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="modal fade" id="aviso2" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" (click)="cerraraviso2()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p> ¡Está acción invalida la pre-acta anterior del maestro para el grupo al que pertenece el alumno, deberá generarla y firmarla nuevamente el maestro correspondiente, ¿Desea continuar con el proceso?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="abrirmodaldocumento()">Aceptar</button>
                  <button type="button" class="btn btn-secondary" (click)="cerraraviso2()">Cerrar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="modal fade" id="subirdocumento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Subir Documento</h5>
                <button type="button" class="close" (click)="cerrarmodaldocumento()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form [formGroup]="uploadForm">
                  <h5>Documento</h5>
                  <input enctype="multipart/form-data" #certificateFile type="file" name="certificate" (change)="onFileSelect($event)">
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="subirdocumento()" [disabled]="this.uploadForm.get('documento').value == '' ">Aceptar</button>
                <button type="button" class="btn btn-secondary" (click)="cerrarmodaldocumento()">Cerrar</button>
              </div>
            </div>
          </div>
      </div>

      <div class="modal fade" id="avisorespuesta" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">¡Atención!</h5>
              <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ response.mensaje }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarrespuesta()">Aceptar</button>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="avisorespuesta2" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">¡Atención!</h5>
            <button type="button" class="close" aria-label="Close" (click)="cerrarrespuesta2()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ response2.mensaje }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="cerrarrespuesta2()">Aceptar</button>
          </div>
        </div>
      </div>
  </div>

    </div>
</body>