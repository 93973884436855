import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { ServicioSocialService } from '../servicio-social.service';
import { saveAs } from 'file-saver';
declare var $ : any;

@Component({
  selector: 'app-subir-documentos-alumnos',
  templateUrl: './subir-documentos-alumnos.component.html',
  styleUrls: ['./subir-documentos-alumnos.component.css']
})
export class SubirDocumentosAlumnosComponent implements OnInit {

  uploadForm: FormGroup;
  src;
  type = FileMimeType.PDF;
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  @Input('alumno') alumno;
  archivos = [];
  nombreA = '';
  banderaSubirArchivo: boolean = true;
  banderaArchivo: boolean = true;
  banderaDoc: boolean = true;
  constructor(private formBuilder: FormBuilder, private ss: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required, ]
    });
    this.consultarArchivos();
  }

  consultarArchivos(){
    let parametros = {
      tabla: 'servicioSocialYPregrado',
      campo: 'aple_id',
      idCampo: this.alumno.alumnoplanestudioid,
      catalogo: 'documentos',
      campoCatalogo: 'liberación',
      matriculap: this.maestro.matricula
    };
    //let temp = [];
    this.ss.consultarArchivos(parametros).subscribe({
      next:(res: any) => {
        this.archivos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.archivos.length > 0){
          this.banderaArchivo = false;
        }else{
          this.banderaSubirArchivo = false;
        }
      }
    });
  }

  guardarArchivo(){
    var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
    saveAs(blob, `Archivo`);
  }

  verArchivo(clave, nombre){
    this.banderaDoc = true;
    this.nombreA = nombre;
    this.ss.verArchivo(this.maestro.matricula, clave).subscribe({
      next:(res: any) => {
        this.src = res;
      },
      error:(err) => {
        console.log(err);
        // this.src = err.error.text;
      },
      complete:() => {
        this.banderaDoc = false;
        $('#docI').modal('show');
      }
    });
  }

  subirArchivo(){
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    formData.append('tabla', 'servicioSocialYPregrado');
    formData.append('campo', 'aple_id');
    formData.append('idCampo', this.alumno.alumnoplanestudioid);
    formData.append('catalogo', 'documentos');
    formData.append('campoCatalogo', 'liberación');
    formData.append('nota', 'liberación SSyP');
    formData.append('matriculap', this.maestro.matricula);
    this.ss.subirArchivos(formData).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.respuestamodal();
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  cerrarDoc(){
    $('#docI').modal('hide');
  }

  respuestamodal(){
    $('#staticBackdropResponse').modal('show');
  }

  respuestamodalCerrar(){
    $('#staticBackdropResponse').modal('hide');
  }

}
