import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class AdministracionService {

  constructor(private http: HttpClient, private global: Global) { }

  consultarperiodoescolar(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  consultarcarreras(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matriculap}`, null)
  }

  consultarsemestres(matriculap, idplanstudio){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matriculap}/${idplanstudio}`, null)
  }

  consultarBloques(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/InscripcionesConfigurar/listadoBloques/`, datos, { headers:this.global.headers })
  }

  listadodeprogramaciones(matricula, idperiodo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/InscripcionesConfigurar/listado/${matricula}/${idperiodo}`, null)
  }

  insertarprogramaciones(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/InscripcionesConfigurar/programarInscripcion `, datos, { headers:this.global.headers })
  }

  consultarperiodoescolaranterior(idperiodo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/periodoAnterior/${idperiodo}`, null)
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }

  duplicargrupos(idperiodoorigen, idperiododestino, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ClonarGrupos/${idperiodoorigen}/${idperiododestino}/${matriculap}`)
  }

  revisaractassinfirmar(matriculap, idperiodo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/gruposSinFirmar/${matriculap}/${idperiodo}`, null);
  }

  calcularcupos(idperiodo, idplan, semestre){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ClonarGrupos/cupos/${idperiodo}/${idplan}/${semestre}`);
  }

  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }

  listaGrupos(matricula, idperiodo, idplan){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/listaGruposN/${matricula}/${idperiodo}/${idplan}`,null);
  }

  consultarpdf(idperiodo, idplanestudios, semestre){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/InscripcionesConfigurar/getConf/${idperiodo}/${idplanestudios}/${semestre}`, { responseType: 'arraybuffer' });
  }

  consultarhorarios(idperiodo, idplanestudios, semestre){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorariosPdf/${idperiodo}/${idplanestudios}/${semestre}`, { responseType: 'arraybuffer' });
  }

  mandarinscripcionesasesco(periodo, plan, semestre){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/InscripcionesSesco/Altas/${periodo}/${plan}/${semestre}`, null)
  }

  sembradoPrimerSemestre(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SembrarNuevoIngreso`, parametros);
  }

}