import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InscripcionesService {

  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }


  constructor(private http: HttpClient, private global: Global) { }

  materiasCursables(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/historiaInscribirtodo/${matricula}`, null)
  }

  gruposHorario(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/grupoHorariotodo/${matricula}`, null)
  }

  mandarMaterias(solicitud){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/insertSinRestriccionLista/`, solicitud)
  }

  listarMateriasXAlumno(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inscripcionelistamaterias`, datos)
  }

  consultarperiodoescolar(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }

  consultarcarreras(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matriculap}`, null)
  }
  
  cerrarInscripciones(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/cerrar`, dato)
  }

}
