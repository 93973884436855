import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalDataSource } from 'ng2-smart-table';
import { InicioService } from 'src/app/tutoriales/service/inicio.service';

declare var $ : any;
@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.css']
})
export class AdminTutorialesComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }
  TutorialesForm = new FormGroup({
    titulo: new FormControl('', [Validators.required]),
    descripcion: new FormControl('',[Validators.required]),
    url: new FormControl('',[Validators.required]),
    rol: new FormControl('')
  });
  settings = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'agregarrol', title:'<i class="fas fa-plus"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin roles activos disponibles.'
  }

  settings2 = {
    columns:{
      nombre:{
        title:'Nombre',
        editable:false,
        width:'50%'
      },
      descripcion:{
        title:'Descripción',
        editable:false,
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'eliminarrol', title:'<i class="fas fa-times"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Eliga roles para la notificación.'
  }

  settings3 = {
    columns:{
      titulo:{
        title:'Titulo',
        editable:false,
        width:'25%'
      },
      aviso:{
        title:'Aviso',
        editable:false,
        width:'25%'
      },
      inicio:{
        title:'Inicio',
        editable:false,
        width:'25%'
      },
      fin:{
        title:'Fin',
        editable:false,
        width:'25%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'eliminaraviso', title:'<i class="fas fa-times"></i>' }
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin avisos disponibles.'
  }
  
  itemSource: LocalDataSource;
  itemSource2: LocalDataSource;
  itemSource3: LocalDataSource;
  banderroles:boolean = true;
  avisocompleto;
  banderaaviso:boolean = true;
  bandera = true;
  tutoriales = [];
  rolesactivos = [];
  rolesselecionados = [];
  validacion = '';
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  constructor(private inicioservice:InicioService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listarTutoriales();
    this.consultarroles();
    this.itemSource = new LocalDataSource(this.rolesselecionados);
  }
  
  enviar(){
    let respuestas = [];
      for(var i = 0; i < this.rolesselecionados.length; i++){
        var jason = {
          titulo:this.TutorialesForm.get('titulo').value,
          descripcion:this.TutorialesForm.get('descripcion').value,
          url:this.TutorialesForm.get('url').value,
          rol:this.rolesselecionados[i].id
        }
        this.inicioservice.agregartutorial(jason).subscribe({
          next:(data : any) => {
            respuestas.push(data);
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            if(respuestas.length == this.rolesselecionados.length){
              this.respuesta = respuestas[0];
              $('#respuesta').modal('show');
              this.TutorialesForm.reset();
              this.rolesselecionados = [];
              this.consultarroles();
              this.itemSource = new LocalDataSource(this.rolesselecionados);
            }
          }
        });
      }
    
  }
  aviso(event){
    this.avisocompleto = event.data;
    $('#avisomodal').modal('show');
    this.banderaaviso = false;
  }
  consultarroles(){
    this.inicioservice.consultarroles(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.rolesactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var jason = {
          id:-1,
          descripcion:'ALUMNOS',
          escolares:0,
          nombre:'NOTIFICAIÓN ALUMNOS',
          relacion:"0"
        }
        this.rolesactivos.push(jason);
        this.rolesactivos.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.itemSource2 = new LocalDataSource(this.rolesactivos);
        this.banderroles = false;
      }
    });
  }
  listarTutoriales(){
    this.inicioservice.listaTutoriales(this.maestro.matricula).subscribe({
      next: (res : any)=>{
        this.tutoriales = res;
        console.log(this.tutoriales);
        
      },
      error: () => {
        throw new Error('Error al listar tutoriales')
      },
      complete:() => {
        this.bandera = false;
      }
    })
  }
  
  eliminarrol(event){
    for(var x = 0; x < this.rolesselecionados.length; x++){
      if(event.data.id == this.rolesselecionados[x].id){
        this.rolesactivos.push(this.rolesselecionados[x]);
      }
    }
    for(var i = 0; i < this.rolesselecionados.length; i++){
      if(event.data.id == this.rolesselecionados[i].id){
        this.rolesselecionados.splice(i, 1);
      }
    }
    this.rolesactivos.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    this.itemSource2 = new LocalDataSource(this.rolesactivos);
    this.itemSource = new LocalDataSource(this.rolesselecionados);
  }

  agregarrol(event){
    for(var i = 0; i < this.rolesactivos.length; i++){
      if(event.data.id == this.rolesactivos[i].id){
        this.rolesselecionados.push(this.rolesactivos[i]);
      }
    }
    for(var x = 0; x < this.rolesactivos.length; x++){
      if(event.data.id == this.rolesactivos[x].id){
        this.rolesactivos.splice(x, 1);
      }
    }
    this.itemSource = new LocalDataSource(this.rolesselecionados);
    this.itemSource2 = new LocalDataSource(this.rolesactivos);
  }

  
}
