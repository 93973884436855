import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class ServicioUniversitarioService {


  constructor(private http: HttpClient, private global: Global) { }

  listaperiodosescolares(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  listarcarrara(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/carrera/${matriculap}`, null)
  }

  buscaralumnos(matriculap, matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/lista/${matriculap}/${matricula}`, null)
  }

  listadoSSU(matricula, matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/lista/${matricula}/${matriculap}  `, null)
  }

  actualizarestados(datos){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/upEstado`, datos);
  }

  visualizardocuments(matricula, matriculaalumno, id){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/DocumentoSolicitud/${matricula}/${matriculaalumno}/${id}`,  { responseType: 'arraybuffer' });
  }

  // liberarssu(datos){
  //   return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/liberacion `, datos ,{ responseType: 'arraybuffer' });
  // }

  generacionpdf(matriculap, alumnoplanestudioid){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/LiberacionPDF/${matriculap}/${alumnoplanestudioid}`, { responseType: 'arraybuffer' });
  }

  liberarservicio(fecha){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/liberacion`, fecha)
  }

  consultarhorasdealumnos(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/Avance`, datos)
  }

  consultarLiberaciones(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/listado/liberaciones`, datos);
  }

  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }

  liberacionSSUPDF(matriculap, apleid){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/LiberacionPDF/${matriculap}/${apleid}`, { responseType: 'arraybuffer' })
  }
  
}
