<div class="row">
    <div class="col-12"><h5>Crear</h5></div>
    <div class="col-4">
        <div class="form-group">
            <input type="text" [formControl]="uploadForm.get('formato')" class="form-control" id="nombre" placeholder="Nombre de Formato">
        </div>
    </div>
    <div class="col-2">
        <div class="form-group">
            <!-- <label for="exampleFormControlSelect1">Semestre</label> -->
            <select [formControl]="uploadForm.get('semestre')" class="form-control" id="exampleFormControlSelect1">
                <option value="">-- Semestre --</option>
                <option value="0">Todos los semestres</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
            </select>
        </div>
    </div>
    <div class="col-2">
        <div class="form-group">
            <div class="input-group">
                <div class="custom-file">
                    <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir documento</label>
                    <input type="file" id="documento" (change)="onFileSelect($event)" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                </div>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="!banderatabla">
        <table class="table table-sm table-hover table-borderless">
            <thead class="bg-danger text-white">
                <tr>
                    <th>Documento</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ uploadForm.get('file').value.name }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <button class="btn btn-danger" (click)="guardar()">Guardar</button>
    </div>
</div>
<div class="row  mt-4">
    <div class="col-12"><h5>Listado de documentos</h5></div>
    <div *ngIf="banderatabla" class="col-12 ">
        <ng2-smart-table class="table table-hover table-borderless" (userRowSelect)="seleccionar($event)" [settings]="settings" [source]="listado"></ng2-smart-table>
    </div>
</div>
<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
        <button type="button" class="close" (click)="cerrarmodalrespuseta()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ respuesta.mensaje }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="cerrarmodalrespuseta()">Aceptar</button>
    </div>
    </div>
</div>
</div>

<div class="modal fade" id="acciones" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ doc.formato }}</h5>
            <button type="button" class="close" (click)="cerrarmodalacciones()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <a class="btn btn-danger  btn-block mb-1 text-white" (click)="verDocumento(doc.id)">Ver documento</a>
                </div>
                <div class="col-6">
                    <a class="btn btn-danger  btn-block mb-1 text-white" *ngIf="banderaEditar" (click)="editar()">Editar</a>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <a class="btn btn-danger  btn-block mb-1 text-white" (click)="activar(doc.id, doc.activo)">Activar/Desactivar</a>
                </div>
                <div class="col-6">
                    
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="cerrarmodalacciones()">Aceptar</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="verDoc" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ doc.formato }}</h5>
            <button type="button" class="close" (click)="cerrarmodaldoc()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderadoc">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="cerrarmodaldoc()">Aceptar</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ doc.formato }} - Edicion</h5>
            <button type="button" class="close" (click)="cerrarmodaleditar()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" [formControl]="uploadFormEdit.get('formato')" class="form-control" id="nombre" placeholder="Nombre de Formato">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <!-- <label for="exampleFormControlSelect1">Semestre</label> -->
                        <select [formControl]="uploadFormEdit.get('semestre')" class="form-control" id="exampleFormControlSelect1">
                            <option value="">-- Semestre --</option>
                            <option value="0">Todos los semestres</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="custom-file">
                                <label for="edit" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir documento</label>
                                <input type="file" id="edit" (change)="onFileSelectEdit($event)" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4" *ngIf="!banderatabla1">
                    <table class="table table-sm table-hover table-borderless">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>Documento</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ uploadFormEdit.get('file').value.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="edit()">Guardar</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalactivo" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ doc.formato }}</h5>
            <button type="button" class="close" (click)="cerrarmodalactivo()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">

                <div class="col-6">
                    <div class="form-group">
                        <!-- <label for="exampleFormControlSelect1">Semestre</label> -->
                        <select [formControl]="estadoForm.get('activo')" class="form-control" id="exampleFormControlSelect1">
                            <option value="">-- Activar --</option>
                            <option value="1">SI</option>
                            <option value="0">NO</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="guardarActivo()">Aceptar</button>
        </div>
        </div>
    </div>
</div>