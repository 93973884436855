import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Global} from 'src/global';

interface NuevaMateria{
    matriculap: string;
    materia:{
      clave: string,
      creditos: string,
      horas: string,
      nombre: string,
      nombrec: string,
      practica: string,
      acreditada: string
    }
}
interface EditarMateria{
  matriculap: string;
  materia:{
    id: number,
    clave: string,
    creditos: string,
    horas: string,
    nombre: string,
    nombrec: string,
    practica: string,
    acreditada: string
  }
}
interface BorrarMateria{
  dato: number;
  matriculap: string;
}
interface BuscarMateria{
  dato: number;
  matriculap: string;
}




@Injectable({
  providedIn: 'root'
})
export class MateriasService {

  constructor(private http: HttpClient, private global: Global) { }

  lista(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Materia/lista/${matricula}`, null);
  }
  insertar(materia: NuevaMateria){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Materia/insert/`, materia, { headers:this.global.headers });
  }
  borrar(materia: BorrarMateria){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Materia/delete/`, materia);
  }
  editar(materia: EditarMateria){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Materia/editar/`, materia, { headers:this.global.headers });
  }
  buscar(buscar: BuscarMateria){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Materia/busqueda/`, buscar);
  }

}
