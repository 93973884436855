import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../../global';

interface LoginCredentials{
  username: string;
  password: string;
}

interface LoginResponse{
  codigo: string;
  detalle: string;
  mensaje: string;
}

interface personal{
  apellidom: string,
  apellidop: string,
  calle: string,
  celular: string,
  colonia: string,
  curp: string,
  email: string,
  fotografia: string,
  maestro: number,
  matricula: string,
  nombres: string;
  numeroexterior: string,
  numerointerior: string,  
  rfc: string,
  tipocontrato: string
}

interface nPassword{
  usuario: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private global: Global) { }

  login(credentails: LoginCredentials){
    return this.http.post<LoginResponse>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Personal/Login`, credentails, { headers: this.global.headers })
  }

  personal(matricula: string){
    return this.http.post<personal>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/personal/${matricula}`, null)
  }

  cambiarPass(nuevaP: nPassword){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Personal/Login/newpasswordPersonal`, nuevaP)
  }

  buscarRol(matricula: String){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PermisoRol/permiso/${matricula}`, null);
  }

  resetPassowrd(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Mailing/SendContrasena/${matricula}`, null)
  }

}
