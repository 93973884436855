<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>

  <div class="container-fluid">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-Agregar-tab" data-toggle="tab" href="#nav-Agregar" role="tab" aria-controls="nav-Agregar" aria-selected="true">Agregar</a>
        <a *ngIf="!banderaListado" class="nav-link" id="nav-Listado-tab" data-toggle="tab" href="#nav-Listado" role="tab" aria-controls="nav-Listado" aria-selected="false">Listado</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-Agregar" role="tabpanel" aria-labelledby="nav-Agregar-tab">
        <div class="row">
          <div class="mt-2 col-12">
              <div class="row">
                  <div class="col-12">
                      <div class="form-group">
                          <input type="text" class="form-control" [formControl]="avisosForm.get('titulo')" id="titulo" placeholder="Título" aria-describedby="emailHelp"> 
                      </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-2 ">
                  <div class="form-group text-center" >
                      <select class="form-control " id="prioridad" (change)="seleccionarprioridad()">
                        <option > <p class="text-muted">Prioridad </p> </option>
                        <option *ngFor="let prio of prioridad" value="{{ prio.id }}">{{ prio.nombre }}</option>
                      </select>
                      
                        <small class="text-danger" *ngIf="(this.prioridadseleccionado == '')">Ingrese la prioridad.</small>
                    
                  </div>
                </div>
                <div class="col-3">
                  <select (change)="semestre()" class="form-control text-center" id="planestudio" >
                      <option value="">Plan de Estudio</option>
                      <option value="0">TODOS</option>
                      <option *ngFor="let plan of plandeestudios" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{plan.plandeestudio}})</option>
                  </select>
                  <small class="text-danger" *ngIf="(this.plan == '')">Seleccione un plan de estudio.</small>
                </div>
                <div class="col-2">
                  <select class="form-control text-center" id="semestre">
                      <option value="">SEMESTRE</option>
                      <option value="0">TODOS LOS SEMESTRE</option>
                      <option *ngFor="let s of semestres" [value]="s.semestre">{{s.semestre}}°SEMESTRE</option>
                  </select>
                  <small class="text-danger" *ngIf="(this.sem == '')">Seleccione un semestre.</small>
              </div>
              
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" [formControl]="avisosForm.get('matricula')" id="matricula" placeholder="Matrícula" aria-describedby="emailHelp"> 
                    </div>
                </div>
            
              <div class="col-3">
                <div class="custom-file"style="text-align: right;">
                    <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir archivos</label>
                    <input type="file" id="documento" (change)="handleFileSelect($event)" enctype="multipart/form-data" accept="image/jpeg, application/pdf" style="display: none">  
                </div>
              </div>  
                <div class="col-12">
                    
                    
                      <div class="form-group">
                        <div style="background-color: white;">
                          <angular-editor (input)="showHTML()"  [config]="configAviso" [(ngModel)]="htmlContent"></angular-editor>
                        </div>
                          <!-- <textarea class="form-control" [formControl]="avisosForm.get('aviso')" placeholder="Aviso" id="aviso" rows="6"></textarea> -->
                        </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <div class="input-group-append">
                          <input class="form-control" name="mydate" placeholder="Fecha inicio de notificación" 
                          angular-mydatepicker #dp="angular-mydatepicker" 
                          [(ngModel)]="fechaI" [options]="myDpOptions" 
                          (dateChanged)="fechainicioI($event)"/>
                      <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                          <i class="fas fa-calendar-alt"></i>
                      </button>
                      </div>
                  </div>
                  <div class="col-6">
                      <div class="input-group-append">
                              <input class="form-control" name="mydate" placeholder="Fecha fin de notificación" 
                              angular-mydatepicker #dp2="angular-mydatepicker" 
                              [(ngModel)]="fechaF" [options]="myDpOptions" 
                              (dateChanged)="fechainicioF($event)"/>
                          <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                      </div>
                  </div>
              </div>
              <div class="row pt-2">
                  <div class="col-12">
                      <div>
                          <h6>Roles asignados a notificación</h6>
                      </div>
                      <ng2-smart-table (custom)="eliminarrol($event)" class="table table-hover table-borderless" [settings]="settings2" [source]="itemSource"></ng2-smart-table>
                  </div>
              </div>
              <div class="row pt-4">
                  <div class="col-12">
                      <button id="enviar" onClick="disabled=true;" (click)="enviar()" class="float-right btn btn-danger" [disabled]="(avisosForm.invalid) || (this.fechainicio == '') || (this.fechafin == '') || (this.prioridadseleccionado == '' ) || (this.sem == '' ) || (this.plan == '' ) ||(this.rolesselecionados.length == 0)">
                        <div *ngIf="banderenviar"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                        Enviar notificación
                      </button>
                  </div>
              </div>
          </div>
          <div class="col-12" *ngIf="!banderroles">
              <div>
                  <h6>Roles disponibles</h6>
              </div>
              <ng2-smart-table (custom)="agregarrol($event)" class="table table-hover table-borderless" [settings]="settings" [source]="itemSource2"></ng2-smart-table>
          </div>
      </div>
      </div>
    <!------------------------------------ Listado ------------------------------------>
      <div class="tab-pane fade" id="nav-Listado" role="tabpanel" aria-labelledby="nav-Listado-tab">
        <div *ngIf="!banderacarga" class="row">
          <div class="col-12">
            
            <!-- <ng2-smart-table class="table table-hover table-borderless" (custom)="aviso($event)" [settings]="settings3" [source]="itemSource3"></ng2-smart-table> -->
            <div class="card">
              <div class="card-body row">
                  <div class="col-12" >
                      <label><strong class=""><h3>Avisos</h3></strong> </label> 
                      <hr>
                  </div>
                  
                  <div class="col-12 mt-4" *ngFor="let aviso of avisosactivos | paginate: { itemsPerPage: 5, currentPage: p }">
                      <div class="row">
                        <div class="col-7"><div class="avred h5"><strong>{{aviso.titulo}}</strong><button class="btn btn-danger btn-sm  ml-2" (click)="editAviso(aviso.id, aviso.titulo, aviso.aviso, aviso.inicio, aviso.fin)"><i class="fas fa-edit "></i></button></div>
                      <div [innerHTML]="aviso.aviso"></div>
                      </div>
                      
                      <div class="col-5">
                        <table class="table table-sm table-striped table-borderless">
                            <thead class="bg-danger text-white">
                              <tr>
                                <th>Nombre del rol</th>
                                <th>Prioridad</th>
                                <th></th>
                                <th><button class="btn btn-ligth btn-sm text-white"   (click)="addNewRol(aviso.id,aviso.rol)"><i class="fas fa-plus "></i></button></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let rol of aviso.rol">
                                <td *ngFor="let r of rol.rol"><small>{{r.nombre}}</small></td>
                                <td>
                                  <a class="disabled" *ngFor="let p of prioridad">
                                  <small *ngIf="p.id == rol.prioridad">{{ p.nombre}}</small>
                                  </a>
                                </td>
                                <td>
                                  <button class="btn btn-danger btn-sm " (click)="editPrioridad(rol.id,rol.prioridad)"><i class="fas fa-edit "></i></button>
                                </td>  
                                <td>  
                                  <button class="btn btn-danger btn-sm" (click)="avisomodal(rol.id)"><i class="fas fa-times "></i></button>
                                </td>
                              </tr>
                            </tbody>
                        
                        </table>
                      </div>
                        
                      

                      <small  class="ml-3"> De {{aviso.inicio}} a {{aviso.fin}}</small>
                      
                      </div>
                      <hr>
                  </div>
                  
              </div>
              <pagination-controls
              class="custom-pagination" 
              (pageChange)="p = $event"
              directionLinks="true"
              autoHide="true"
              responsive="true"
              previousLabel="Anterior"
              nextLabel="Siguiente"
              >
              </pagination-controls>
          </div>
          

          </div>
        </div>
        <div class="row" *ngIf="banderacarga">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  <!-- modal de validacion -->
      <div class="modal fade" id="validacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {{ validacion }}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
              </div>
            </div>
          </div>
        </div>
  <!-- Modal de aviso -->
        <div class="modal fade" id="avisomodal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div *ngIf="!banderaaviso">
                  ¿Está seguro de eliminar el aviso?
                  Este proceso es irreversible y se eliminara de forma permanente.
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="eliminarnotificacion()">Aceptar</button>
              </div>
            </div>
          </div>
        </div>
  
        <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {{ respuesta.mensaje }}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal Editar el Aviso -->
        <div class="modal fade" id="editAviso" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Aviso</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="mt-2 col-12">
                      <div class="row">
                          <div class="col-12">
                              <div class="form-group">
                                  <input type="text" class="form-control" [formControl]="editAvisoForm.get('titulo')" id="titulo" placeholder="Título" aria-describedby="emailHelp"> 
                              </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="custom-file" style="text-align: right;">
                              <label for="documento1" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir archivos</label>
                              <input type="file" id="documento1" (change)="handleFileSelect1($event)" enctype="multipart/form-data" accept="image/jpeg, application/pdf" style="display: none">  
                          </div>
                        </div>  
                        <div class="col-12">
                              <div class="form-group">
                                <div >
                                  <angular-editor (input)="showHTML1()"  [config]="configAviso1" [(ngModel)]="htmlContent1"></angular-editor>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-6">
                              <div class="input-group-append">
                                  <input class="form-control" id="finicio" name="mydate" placeholder="Fecha inicio de notificacion" 
                                  angular-mydatepicker #dp3="angular-mydatepicker" 
                                  [(ngModel)]="fechaI1" [options]="myDpOptions2" 
                                  (dateChanged)="fechainicioI1($event)"/>
                              <button type="button" class="btn btn-danger" (click)="dp3.toggleCalendar()">
                                  <i class="fas fa-calendar-alt"></i>
                              </button>
                              </div>
                          </div>
                          <div class="col-6">
                              <div class="input-group-append">
                                      <input class="form-control" id="ffin" name="mydate" placeholder="Fecha fin de notificacion" 
                                      angular-mydatepicker #dp4="angular-mydatepicker" 
                                      [(ngModel)]="fechaF1" [options]="myDpOptions2" 
                                      (dateChanged)="fechainicioF1($event)"/>
                                  <button type="button" class="btn btn-danger" (click)="dp4.toggleCalendar()">
                                      <i class="fas fa-calendar-alt"></i>
                                  </button>
                              </div>
                          </div>
                      </div>
                      
                  </div>
                  
              </div>
              </div>
              <div class="modal-footer">
                <button type="submit" (click)="enviarEditForm()" class="btn btn-danger" data-dismiss="modal"> <div *ngIf="banderenviar"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>Enviar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- modal para edita la prioridad -->
        <div class="modal fade" id="editPrioridad" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Prioridad</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group text-center" >
                  <select class="form-control " id="prioridad1"   >
                    <option *ngFor="let prio of prioridad" value="{{ prio.id }}">{{ prio.nombre }}</option>
                  </select>
              </div>
              </div>
              <div class="modal-footer">
                <button type="submit" (click)="enviarEditPrioridas()" class="btn btn-danger" data-dismiss="modal">Guardar</button>
              </div>
            </div>
          </div>
        </div>
      <!-- Modal agregar rol -->
      <div class="modal fade" id="addRol" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Agregar Rol</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-4">
                  <div class="h6">
                    Prioridad
                  </div>
                  <div class="form-group text-center" >
                    <select class="form-control " id="prioridad2"   >
                      <option *ngFor="let prio of prioridad" value="{{ prio.id }}">{{ prio.nombre }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="h6">
                    Plan de estudio
                  </div>
                  <select (change)="semestre2()" class="form-control text-center" id="planestudio2" >
                      <option value="">--Plan de Estudio--</option>
                      <option value="0">TODOS</option>
                      <option *ngFor="let plan of plandeestudios" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{plan.plandeestudio}})</option>
                  </select>
                  <small class="text-danger" *ngIf="(this.plan2 == '')">Seleccione un plan de estudio.</small>
                </div>
                <div class="col-4">
                  <div class="h6">
                    Semestre
                  </div>
                  <select class="form-control text-center" id="semestre2">
                      <option value="">--SEMESTRE--</option>
                      <option value="0">TODOS LOS SEMESTRE</option>
                      <option *ngFor="let s of semestres2" [value]="s.semestre">{{s.semestre}}°SEMESTRE</option>
                  </select>
                  <small class="text-danger" *ngIf="(this.sem2 == '')">Seleccione un semestre.</small>
              </div>

                <div class="col-6">
                  <div>
                      <h6>Roles asignados a notificación</h6>
                  </div>
                  <ng2-smart-table (custom)="eliminarrol1($event)" class="table table-hover table-borderless" [settings]="settings5" [source]="itemSource4"></ng2-smart-table>
                </div>

                <div class="col-6" *ngIf="!banderroles">
                  <div>
                      <h6>Roles disponibles</h6>
                  </div>
                    <ng2-smart-table (custom)="agregarrol1($event)" class="table table-hover table-borderless" [settings]="settings4" [source]="itemSource5"></ng2-smart-table>
                </div>
                
            </div>
            </div>
            <div class="modal-footer">
              <button type="submit" (click)="enviarRolNew()" class="btn btn-danger" data-dismiss="modal">Guardar</button>
            </div>
          </div>
        </div>
      </div>

  </div>
    
  <script src='https://cdn.jsdelivr.net/lodash/4.17.2/lodash.min.js'></script>  
</body>
</html>