import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
declare var $ : any;

@Component({
  selector: 'app-listado-plazas',
  templateUrl: './listado-plazas.component.html',
  styleUrls: ['./listado-plazas.component.css']
})
export class ListadoPlazasComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settingsPlazasDetalle = {
    columns:{
    alumno:{
      title:'Alumno',
      editable:false,    
      width:'50%'
    },
    alumnomatricula:{
      title:'Matrícula',
      editable:false,    
      width:'20%'
    },
    disponible:{
      title:'Disponible',
      editable:false,    
      width:'10%'
    },
    tipoasginada:{
      title:'Tipo',
      editable:false,
      width:'10%'
    }
   },
   actions:{
     add:false,
     edit:false,
     delete:false,
     columnTitle:'',
     type:'html',
     custom:[
      //  { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-pencil-alt " ></i></a>'},
      //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
     ],         
     width:'15%',
     position: 'right'
   },
   pager:{
     perPage:10
   },
   noDataMessage:'Sin datos disponibles.'
 }

settingsPlazas = {
  columns:{
  institucion:{
    title:'Institucion',
    editable:false/*,         
    width:'9%'*/
  },
  ubicacion:{
    title:'Ubicación',
    editable:false/*,         
    width:'9%'*/
  },
  rotacion:{
     title:'Rotación',
     editable:false,         
     width:'9%'
   },
   asignadas:{
     title:'Asignadas',
     editable:false,         
     width:'9%'
   },
   noasignadas:{
     title:'No Asignadas',
     editable:false,         
     width:'9%'
   },
   estadoRepublica:{
     title:('Estado República'),
     editable:false/*,         
     width:'9%'*/
   }
 },
 actions:{
   add:false,
   edit:false,
   delete:false,
   columnTitle:'',
   type:'html',
   custom:[
     { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
   ],         
   width:'15%',
   position: 'right'
 },
 pager:{
   perPage:10
 },
 noDataMessage:'Sin datos disponibles.'
}

  periodosescolares = [];
  plazas = [];
  plazasDetalle = [];
  lugares = [];
  banderatabla: boolean = true;
  banderaDetalles: boolean = true;

  nombreInstitucion = '';
  estado = '';
  planEstudios = '';
  periodo = '';
  disponibles = 0;
  nodisponibles = 0;
  total = 0;

  constructor(private consultas: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaPeriodos();
  }

  verDetalles(event){
    this.nombreInstitucion = event.data.institucion;
    this.estado = event.data.estadoRepublica;
    this.planEstudios = `${event.data.planestudio}  - ${event.data.carrera}`;
    this.periodo = event.data.periodo;
    this.total = event.data.disponibles;
    this.disponibles = event.data.asignadas;
    this.nodisponibles = event.data.noasignadas;
    this.listadoPlazasdDetalle(event.data.id);
  }

  accionPlazas(valores){
    switch (valores.action)
    {
      case "editar": 
          break;
      case "borrar": 
          break;
      default: 
      
        break;
    }
  }

  listaPlazas(){
    var idperiodo = <HTMLSelectElement>document.getElementById('plazaperiodoid2');
    this.consultas.listaPlazasAdministrar(this.maestro.matricula, idperiodo.value).subscribe({
      next: (res: any) =>{
        this.plazas = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Plazas');
      },
      complete:()=>{
        this.banderatabla = false;
      }
    });
  }

  listadoPlazasdDetalle(plazaid){
    this.lugares = [];
    this.banderaDetalles = true;
    var idperiodo = <HTMLSelectElement>document.getElementById('plazaperiodoid2');
    let parametros = {
      matriculap: this.maestro.matricula,
      periodoid: idperiodo.value,
      plazaid: plazaid
    };
    this.consultas.listadoPlazas(parametros).subscribe({
      next:(res: any) =>{
        this.plazasDetalle = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        for(var i = 0; i < this.plazasDetalle.length; i++){
          let json = {
            alumnoid: this.plazasDetalle[i].alumnoid,
            alumnoplanestudioid:this.plazasDetalle[i].alumnoplanestudioid,
            asignacionid: this.plazasDetalle[i].asignacionid,
            disponible: this.plazasDetalle[i].disponible,
            estado: this.plazasDetalle[i].estado,
            estadoid: this.plazasDetalle[i].estadoid,
            institucion: this.plazasDetalle[i].institucion,
            instituicionid: this.plazasDetalle[i].institucionid,
            periodo: this.plazasDetalle[i].periodo,
            periodoid: this.plazasDetalle[i].periodoid,
            planestudioid: this.plazasDetalle[i].planestudioid,
            plazaid: this.plazasDetalle[i].plazaid,
            tipoasginada: (this.plazasDetalle[i].tipoasignada == 'NO')?'NO ASIGNADA':'ASIGNADA',
            alumno:(this.plazasDetalle[i].alumno == undefined || this.plazasDetalle[i].alumno == null)?'NO ASIGNADO':this.plazasDetalle[i].alumno,
            alumnomatricula:(this.plazasDetalle[i].alumnomatricula == undefined || this.plazasDetalle[i].alumnomatricula == null)?'NO ASGINADO':this.plazasDetalle[i].alumnomatricula,
            carrera:(this.plazasDetalle[i].carrera == undefined || this.plazasDetalle[i].carrera == null)?'NO ASIGNADO':this.plazasDetalle[i].carrera,
            planestudio:(this.plazasDetalle[i].planestudio == undefined || this.plazasDetalle[i].planestudio == null)?'NO ASIGNADO':this.plazasDetalle[i].planestudio
          }
          this.lugares.push(json);
        }
        this.lugares.sort(function (a, b) {
          if (a.asignacionid < b.asignacionid) {
            return 1;
          }
          if (a.asignacionid > b.asignacionid) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.banderaDetalles = false;
        $('#detalles').modal('show');
      }
    })
  }

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next:(res: any) =>{
        this.periodosescolares = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{

      }
    });
  }

}
