import { Component, Input, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import jspdf from 'jspdf';
declare var $ : any;
import html2canvas from 'html2canvas';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';


@Component({
  selector: 'app-kardex-largo',
  templateUrl: './kardex-largo.component.html',
  styleUrls: ['./kardex-largo.component.css']
})
export class KardexLargoComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

   datoskardex = {
    carrera:'',
    alumno:'',
    calle:'',
    colonia:'',
    procede:'',
    matricula:'',
    prommataprob:'',
    credapro:'',
    estado:'',
    lugarnacimiento:'',
    fechanac:''
  };

  historialkardex;
  bandera:boolean = true;
  bandera2:boolean = true;
  src;
  type = FileMimeType.PDF;
  @Input() alumno: string;
  @Input() planestudioid;

  constructor(private personalService: PersonalService) {}

  ngOnInit(): void {
    // const matriculaAlumno = localStorage.getItem('alumno');
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.imprimirkardexLargo();
    var jason = {
      dato: this.alumno,
      matriculap: this.maestro.matricula
    }
    // this.personalService.consultarKardexLargo(jason).subscribe({
    //   next:(res) => {
    //     this.historialkardex = res;
    //   },
    //   error:(err) => {
    //     console.log(err);
    //   },
    //   complete:() => {
    //     console.log(this.historialkardex);
    //   }
    // });
  }

  imprimirkardexLargo(){
    this.src = '';
    this.personalService.imprimirKardexLargos(this.alumno, this.maestro.matricula, this.planestudioid).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.bandera2 = false;
        // var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
        // saveAs(blob, `Documento`);
      }
    })
  }

  public captureScreen() {
    // var data = document.getElementById('contentToConvert');
    // html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
    //   const image = { type: 'jpeg', quality: 0.98 };
    //   const margin = [0.5, 0.5];
    //   const filename = 'myfile.pdf';

    //   var imgWidth = 8.5;
    //   var pageHeight = 10.8;

    //   var innerPageWidth = imgWidth - margin[0] * 2;
    //   var innerPageHeight = pageHeight - margin[1] * 2;

    //   var pxFullHeight = canvas.height;
    //   var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    //   var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    //   var pageHeight = innerPageHeight;

    //   var pageCanvas = document.createElement('canvas');
    //   var pageCtx = pageCanvas.getContext('2d');
    //   pageCanvas.width = canvas.width;
    //   pageCanvas.height = pxPageHeight;

    //   var pdf = new jspdf('p', 'in', [8.5, 10.5]);

    //   for (var page = 0; page < nPages; page++) {
    //     if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
    //       pageCanvas.height = pxFullHeight % pxPageHeight;
    //       pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
    //     }

    //     var w = pageCanvas.width;
    //     var h = pageCanvas.height;
    //     pageCtx.fillStyle = 'white';
    //     pageCtx.fillRect(0, 0, w, h);
    //     pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

    //     if (page > 0) pdf.addPage();
    //     var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
    //     pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
    //   }
    //   pdf.save();
    // });
  }

}
