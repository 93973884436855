import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as moment from 'moment'

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFileAlumnosSESCO(jason: any[]){
    const titulo = 'Alumnos de SESCO';
    const fecha =  moment().format('DD/MM/YYYY');
    const data = jason;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(`Alumnos`);
    let tituloRow = worksheet.addRow([titulo]);
    tituloRow.font = { name:'Arial', family:4, size:20, bold:true };
    worksheet.addRow([]);
    let  fechafila = worksheet.addRow(['Fecha de generación: '+fecha]);
    fechafila.font = { name:'Arial', family:4, size:20, bold:true };
    worksheet.addRow([]);
    let tabla = worksheet.addTable({
      name: 'Alumnos',
      ref: 'B6',
      headerRow: true,
      columns: [
        {name: 'Alumno', filterButton: true},
        {name: 'Matrícula', filterButton: true},
        {name: 'Calle', filterButton: true},
        {name: 'Colonia', filterButton: true},
        {name: 'Código', filterButton: true},
        {name: 'Celular', filterButton: true},
        {name: 'CURP', filterButton: true},
        {name: 'Correo', filterButton: true},
        {name: 'RFC', filterButton: true},
      ],
      rows: data,
      style: {
        showRowStripes: true,
        showFirstColumn: true
      }
    });
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 20;
    workbook.xlsx.writeBuffer().then((dat) => {
      let blob = new Blob([dat], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `AlumnosSESCO.xlsx`);
    });
  }

}
