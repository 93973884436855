<div>
    <div class="row">
        <div class="col-4">
            <h4>Actualizar Cuota Interna</h4>
            <div class="form-row align-items-center">
                <div class="col-auto my-1">
                    <input (change)="tomarFecha($event)" class="form-control" type="date" />
                </div>
                <div class="col-auto my-1">
                    <button (click)="actualizarCoutaInterna()" class="btn btn-danger">Actualizar</button>
                </div>
            </div>
        </div>
        <div class="col-4">
            <h4>Actualizar Cuota Externa</h4>
            <div class="form-row align-items-center">
                <div class="col-auto my-1">
                    <button (click)="actualizarCoutaExterna()" class="btn btn-danger">Actualizar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      http://192.168.10.38:8080/Famen-0.0.1/api/PagosFAMEN?fecha=xx-xx-xxxx
    </div>
</div>

<div class="modal" id="loading" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

<div class="modal" id="mensaje" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Atención</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ response.mensaje }}<br>
          Cantidad de registros encontrados: {{ response.id }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
        </div>
      </div>
    </div>
  </div>