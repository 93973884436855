import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PersonalService } from '../services/personal.service';
declare var $ : any;

@Component({
  selector: 'app-agregar-alumno',
  templateUrl: './agregar-alumno.component.html',
  styleUrls: ['./agregar-alumno.component.css']
})
export class AgregarAlumnoComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  alumnosform = this.formbuilder.group({
    matricula:['', [Validators.required]],
    nombres:['', [Validators.required]],
    apellidom:[''],
    apellidop:[''],
    calle:['', [Validators.required]],
    colonia:['', [Validators.required]],
    rfc:['', [Validators.required, Validators.minLength(12), Validators.maxLength(13) ]],
    codigopostal:['', [Validators.required, Validators.pattern("^[0-9]*$") ]],
    email:['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    semestre:[1],
    curp:['', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]],
    telefono:['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
    fechanacimiento:['', [Validators.required]],
    estatus:['NUEVO INGRESO'],
    tipo:[''],
    regular:['SI'],
    periodoingreso:[''],
    semestrecalculado:[1],
    planestudio:[''],
    planestudioid:[0],
    matriculap:['']
  },
  {
    validators:[this.validationapellidos('apellidop', 'apellidom')]
  });

  tipos = [
    { tipo:'EXTERNO', id:1 },
    { tipo:'INTERNO', id:2 }
  ]

  response = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  periodosescolares = [];
  periodoid;
  periodonombre;
  tiposelecionado;
  planesdeestudios = [];
  banderabutton:boolean = true;
  constructor(public formbuilder: FormBuilder, private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolar();
    this.consultarplanestudios();
  }

  consultarperiodoescolar(){
    this.personalService.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodosescolares.length; i++){
          if(this.periodosescolares[i].estado == "ACTUAL"){
            this.periodoid = this.periodosescolares[i].id
            this.periodonombre = this.periodosescolares[i].nombre
          }
        }
      }
    })
  }

  consultarplanestudios(){
    this.personalService.consultarplanestudios().subscribe({
      next:(data : any) => {
        this.planesdeestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var tipo = <HTMLSelectElement>document.getElementById('tipo');
        var plan = <HTMLSelectElement>document.getElementById('planestudio');
        plan.selectedIndex = 0;
        tipo.selectedIndex = 0;
      }
    });
  }

  ingresaralumno(){
    this.banderabutton = false;
    if(this.alumnosform.invalid){
      this.alumnosform.markAllAsTouched();
    }else{
      if(this.alumnosform.valid){
        let tip = <HTMLSelectElement>document.getElementById('tipo');
        var plan = <HTMLSelectElement>document.getElementById('planestudio');
        let nombreplanestudio;
        for(var i = 0; i < this.planesdeestudios.length; i++){
          if(plan.value == this.planesdeestudios[i].id){
            nombreplanestudio = this.planesdeestudios[i].nombre
          }
        }
        this.alumnosform.patchValue({ periodoingreso:parseInt(this.periodoid) });
        this.alumnosform.patchValue({ matriculap:this.maestro.matricula });
        this.alumnosform.patchValue({ tipo:tip.value });
        this.alumnosform.patchValue({ planestudioid:parseInt(plan.value) });
        this.alumnosform.patchValue({ planestudio:nombreplanestudio });
        // console.log(this.alumnosform.value);
        this.personalService.agregaralumno(this.alumnosform.value).subscribe({
          next:(data:any) => {
            this.response = data;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            this.banderabutton = true;
            if(this.response.codigo == "OK"){
              this.alumnosform.reset();
              
              $('#respuestai').modal('show');
            }else{
              
              $('#respuestai').modal('show');
            }
          }
        })
      }
    }
  }

  salir(){
    $('#respuestai').modal('hide');
  }

  validationapellidos(apellidop, apellidom){
    return ( FormGroup: FormGroup ) => {
      const apellidopcontrol = FormGroup.get(apellidop);
      const apellidomcontrol = FormGroup.get(apellidom);
      if((apellidopcontrol.value === '' ) && (apellidomcontrol.value === '')){
        apellidopcontrol.setErrors({ obligatorio:true });
        apellidomcontrol.setErrors({ obligatorio:true });
      }else{
        apellidopcontrol.setErrors(null);
        apellidomcontrol.setErrors(null);
      }
    }
  }

}
