import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-bienvenido',
  templateUrl: './bienvenido.component.html',
  styleUrls: ['./bienvenido.component.css']
})
export class BienvenidoComponent implements OnInit {

  @Input() icon: String;
  @Input() alumno: String;
  @Input() carrera: String;
  @Input() matricula: String;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  links=[
    {
      title : 'Ayuda',
      path: '/tutorial/list'
    }
  ];
  
  
}

