import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Global } from 'src/global';

interface BusquedaAlumno{
  dato: string;
  matriculap: string;
}
interface Alumno{
  matricula: string;
  matriculap: string;
  pago: number;
}
@Injectable({
  providedIn: 'root'
})
export class BusquedaAlumnosService {

  constructor(private http: HttpClient,private global: Global) { }

  consultarMatricula(busquedaAlumno: BusquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Permiso/consulta/`, busquedaAlumno);
  }
  validarPago(alumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Permiso/siia/`, alumno);
  }
}
