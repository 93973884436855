import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Global} from 'src/global';

interface AulaEdit{
  matriculap: string;
  aula: {
    id: number,
    capacidad: number,
    edificio: string,
    nombre: string
  };
}
interface AulaBorrar {
  dato: number;
  matriculap: string;
}
interface AulaCrear{
  matriculap: string;
  aula: {
    capacidad: number,
    edificio: string,
    nombre: string
  }
}
@Injectable({
  providedIn: 'root'
})
export class AulasService {

  constructor(private http: HttpClient, private global: Global) { }
  editarAula(aula: AulaEdit){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/editar`, aula, { headers:this.global.headers });
  }
  borrarAula(aula: AulaBorrar){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/delete`, aula);
  }
  listaAulas(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/lista/${matricula}`, null);
  }
  crearAula(aula: AulaCrear){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/insert`, aula, { headers:this.global.headers });
  }
}
