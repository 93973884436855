import { Component, OnInit } from '@angular/core';
import { PlanestudiosService } from '../service/planestudios.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
declare var $ : any;

@Component({
  selector: 'app-mapapdf',
  templateUrl: './mapapdf.component.html',
  styleUrls: ['./mapapdf.component.css']
})
export class MapapdfComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  planestudiosactivos = [];
  planestudiosactivos1;
  src;
  type = FileMimeType.PDF;
  banderaPDF : boolean = false;

  constructor(private planestudios: PlanestudiosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  consultarperiodoescolares(){
    this.planestudios.consultarplanestudios().subscribe({
      next:(data : any) => {
        this.planestudiosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // for(var i = 0; i < this.periodoescolaractivos.length; i++){
        //   if(this.periodoescolaractivos[i].estado == "ABIERTO"){
        //     this.periodosabiertos.push(this.periodoescolaractivos[i]);
        //   }
        // }
    }
    });
  }

  seleccionarperiodo(){
    var planestudios = <HTMLSelectElement>document.getElementById('planestudios');
    this.planestudiosactivos1 = planestudios.value;
    this.consultarpdf();
  }

  consultarpdf(){
    this.banderaPDF = false;
    $('#cargando').modal('show');
    this.planestudios.consultarpdf(this.planestudiosactivos1).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        $('#cargando').modal('hide');
        setTimeout(() => {
          $('#documento').modal('show');
          this.banderaPDF = true;
          $('#cargando').modal('hide');
        }, 1000);
        
      }
    });
  }  

  imprimirpdf(){
    this.planestudios.imprimirpdf(this.planestudiosactivos1);
  }

}
