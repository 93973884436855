<div>
    <h3>Importación de datos del alumno</h3>
    <div class="row">
        <div class="col-4">
            <input type="text" [formControl]="busqueda.get('dato')" class="form-control" id="matricula" placeholder="Matricula" autocomplete="off">
        </div>
        <div class="col-4">
            <button class="btn btn-danger mb-2" (click)="buscar()">Buscar</button>
        </div>
        <div *ngIf="banderaForm" class="col-12 mt-3" >
            <div class="row">
                <div class="col-4 mb-2">
                    <label for="nombre">Nombre</label>
                    <input id="nombre" [formControl]="this.datosAlumno.get('nombre')" placeholder="Nombre" class="form-control">
                </div>
                <div class="col-4 mb-2">
                    <label for="nombre">Apellido Paterno</label>
                    <input id="apellido_paterno" [formControl]="this.datosAlumno.get('apellido_paterno')" placeholder="Apellido Paterno" class="form-control">
                </div>
                <div class="col-4 mb-2">
                    <label for="nombre">Apellido Materno</label>
                    <input id="apellido_materno" [formControl]="this.datosAlumno.get('apellido_materno')" placeholder="Apellido Materno" class="form-control">
                </div>
                <div class="col-4 mb-2">
                    <label for="nombre">Calle</label>
                    <input id="calle" [formControl]="this.datosAlumno.get('calle')" placeholder="Calle" class="form-control">
                </div>
                <div class="col-5 mb-2">
                    <label for="nombre">Colonia</label>
                    <input id="colonia" [formControl]="this.datosAlumno.get('colonia')" placeholder="Colonia" class="form-control">
                </div>
                <div class="col-3 mb-2">
                    <label for="nombre">Código postal</label>
                    <input id="codigo" [formControl]="this.datosAlumno.get('codigo')" placeholder="Codigo" class="form-control">
                </div>
                <div class="col-6 mb-2">
                    <label for="nombre">Celular</label>
                    <input id="celular" [formControl]="this.datosAlumno.get('celular')" placeholder="Celular" class="form-control">
                </div>
                <div class="col-6 mb-2">
                    <label for="nombre">Curp</label>
                    <input id="curp" [formControl]="this.datosAlumno.get('curp')" placeholder="Curp" class="form-control">
                </div>
                <div class="col-6 mb-2">
                    <label for="nombre">Email</label>
                    <input id="email" [formControl]="this.datosAlumno.get('email')" placeholder="Email" class="form-control">
                </div>
                <div class="col-6 mb-2">
                    <label for="nombre">RFC</label>
                    <input id="rfc" [formControl]="this.datosAlumno.get('rfc')" placeholder="RFC" class="form-control">
                </div>
                <div class="col-6 mt-2 mb-2 ml-4">
                    <input id="todo" [formControl]="this.datosAlumno.get('todo')" placeholder="RFC" class="form-check-input" type="checkbox">
                    <label for="todo">Enviar todos los datos del alumno</label>
                </div>
            </div>
            <button (click)="actualizar()" class="btn btn-danger">Actualizar</button>
        </div>
        <div *ngIf="banderaSpinner" class="col-12">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div *ngIf="banderaVacio" class="col-12 mt-3">
            <div class="d-flex justify-content-center">
                <h5>No se encontraron datos con la matrícula proporcionada</h5>
            </div>
        </div>
    </div>
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</div>