<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container">
        <div class="row">
            <div class="col-12 min-vh-95 d-flex flex-column justify-content-center">
                <div class="row">
                    <div class="col-lg-6 col-md-8 mx-auto">
                        <div class="card rounded shadow shadow-sm bg-light">
                            <div class="card-header" id="header">
                                <div>
                                    <img src="../../../assets/img/logov2.2.png" width="100%">
                                </div>
                                <div class="text-center">
                                    <h4>Mensaje: {{ status }} - {{ statustext }}</h4>
                                    <!-- <h5>Pagina solicitada: <small>{{ url }}</small></h5> -->
                                    <h6><small>Ha ocurrido un problema al intentar recuperar el recurso solicitado,
                                        por favor intente de nuevo en un momento, de persistir el problema
                                        comuníquese con soporte técnico.
                                    </small></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>
</html>