<body>

    <div>
        <h4>Fechas Para Captura de Calificaciones</h4>
    </div>

    <div class="row">
        <div class="col-lg-6">

            <div class="form-group">
                <select class="form-control" id="periodo" (change)="selecionarperiodo()">
                  <option>--Período Escolar--</option>
                  <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                </select>
            </div>

        </div>
        <div  class="col-lg-6">
            <div class="form-group" *ngIf="!banderacarrera">
                <select class="form-control" id="carrera" (change)="selecionarcarrera()">
                  <option>--Carrera--</option>
                  <option *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{ carrera.carrera }}</option>
                </select>
            </div>
        </div>

    </div>

    <div class="row ion-padding-top" *ngIf="!banderatabla">
        <div class="col-lg-12">

            <ng2-smart-table class="table table-striped table-sm table-hover table-borderless" (userRowSelect)="editarfechas($event)" [settings]="settings" [source]="periodoselecionado"></ng2-smart-table>

            <!-- <table class="table table-hover table-sm table-borderless">
                <thead class="bg-danger text-white">
                    <tr>
                        <th>Semestre</th>
                        <th>Inicio de ordinario</th>
                        <th>Fin de Ordinario</th>
                        <th>Inicio de Extraordinario</th>
                        <th>Fin de Extraordinario</th>
                        <th>Inicio de Especial</th>
                        <th>Fin de Especial</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fechas of periodoselecionado">
                        <td>{{ fechas.semestre }}</td>
                        <td>{{ fechas.ordinarios_inicio }}</td>
                        <td>{{ fechas.ordinarios_fin }}</td>
                        <td>{{ fechas.extraordinarios_inicio }}</td>
                        <td>{{ fechas.extraordinarios_fin }}</td>
                        <td>{{ fechas.especial_inicio }}</td>
                        <td>{{ fechas.especial_fin }}</td>
                        <th><button class="btn btn-danger btn-sm text-white"><i class="fas fa-pencil-alt " ></i></button></th>
                    </tr>
                </tbody>
            </table> -->

        </div>
    </div>

    <div class="modal fade" id="editar" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">¡Atención!</h5>
              <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                  <app-listado-configuracion-pe [datoseditar]="semestreselecionado" [idperiodoinyectado]="idperiodo" [idcarrerainyectado]="carreraselecionada"></app-listado-configuracion-pe>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="editarfechas" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Editar Fechas</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row" id="top">
                    <div class="col-lg-5">
                        <div class="pt-3">
                            <h5>Examenes Ordinarios</h5>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <label>Fecha de Inicio *</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                                angular-mydatepicker #dp="angular-mydatepicker" 
                                [(ngModel)]="examenordinarioinicio" [options]="myDpOptions" 
                                (dateChanged)="examenordinarioi($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <label>Fecha de Fin *</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha fin" 
                                angular-mydatepicker #dp2="angular-mydatepicker" 
                                [(ngModel)]="examenordinariofin" [options]="myDpOptions" 
                                (dateChanged)="examenordinariof($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-5">
                        <div class="pt-3">
                            <h5>Examenes Extraordinarios</h5>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <label>Fecha de Inicio *</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                                angular-mydatepicker #dp3="angular-mydatepicker" 
                                [(ngModel)]="examenextraordinarioinicio" [options]="myDpOptions" 
                                (dateChanged)="examenextraordinarioi($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp3.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <label>Fecha de Fin *</label>
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha fin" 
                                angular-mydatepicker #dp4="angular-mydatepicker" 
                                [(ngModel)]="examenextraordinariofin" [options]="myDpOptions" 
                                (dateChanged)="examenextraordinariof($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp4.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-5">
                        <div class="pt-3">
                            <h5>Examenes Especiales (Opcional)</h5>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                                angular-mydatepicker #dp5="angular-mydatepicker" 
                                [(ngModel)]="preactasordinariasinicio" [options]="myDpOptions" 
                                (dateChanged)="examenESI($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp5.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="pt-3">
                            <div class="input-group-append">
                                <input class="form-control" name="mydate" placeholder="Fecha fin" 
                                angular-mydatepicker #dp6="angular-mydatepicker" 
                                [(ngModel)]="preactasordinariasfin" [options]="myDpOptions" 
                                (dateChanged)="examenESF($event)"/>
                            <button type="button" class="btn btn-danger" (click)="dp6.toggleCalendar()">
                                <i class="fas fa-calendar-alt"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="guardar()">Aceptar</button>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="respuesta22" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">¡Atención!</h5>
              <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                {{ respuesta.mensaje }}
            </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
    </div>

</body>