import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  constructor(private http: HttpClient, private global: Global) {}

  consultarperiodoescolar(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }
  consultaracciones(json){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/bitacora/lista/acciones`, json)
  }
  detalles(json){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/bitacora/detalle`, json)
  }

  consultarroles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/buscaroles/${matricula}`, null);
  } 

  consultarinscripciones(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AuditoriaInscripcion`, jason)
  }

  consultargrupos(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/grupos/lista/filtro`, jason)
  }

  consultargrupoh(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/grupoh/lista/filtro`, jason)
  }

  consultarcalendarioex(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/calexamenes/lista/filtro`, jason)
  }

  consultarcalendario(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/calendario/lista/filtro`, jason)
  }

  consultarpreacta(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/preactas/lista/filtro`, jason)
  }

  consultaraalumno(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/alumnos/lista/filtro`, jason)
  }
  consultardatosc(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/datosComplemetarios/lista/filtro`, jason)
  }

  consultarmaterias(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/materias/lista/filtro`, jason)
  }

  consultaraalumnople(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/aple/lista/filtro`, jason)
  }

  consultaraavisos(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/avisos/lista/filtro`, jason)
  }
  consultaraavisodetalle(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/avisos/lista/detalle`, jason)
  }
  consultarausuarios(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/usuarios/lista/filtro`, jason)
  }

  consultarausuariosroles(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/usurol/lista/filtro`, jason)
  }

  consultararoles(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/roles/lista/filtro`, jason)
  }

  consultarpagos(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/pagos/lista/filtro`, jason)
  }

  consultarapermisos(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/permisos/lista/filtro`, jason)
  }

  consultarsemestres(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/auditoria/vista/lista/filtro`, jason)
  }

  consultaracceso(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AuditoriaLogin`, jason);
  }

  consultaraccesoP(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AuditoriaLogin/personal`, jason);
  }

  consultarbitacora(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/bitacora/lista/filtro`, jason);
  }

}
