import { Component, OnInit } from '@angular/core';
import { ExamenProfesionalService } from '../services/examen-profesional.service';

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.css']
})
export class AgregarComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  periodosescolares = [];
  carreras = [];
  periodoselecionado;
  carreraselecionada;
  banderacarrera:boolean = true;
  banderatabla:boolean = true;

  constructor(private examenprofesional: ExamenProfesionalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
  }

  periodoselecionadof(){
    var periodoescolar = <HTMLSelectElement>document.getElementById('periodoescolares');
    this.periodoselecionado = periodoescolar.value;
    this.banderacarrera = false;
  }

  carreraselecionadaf(){
    var carrera = <HTMLSelectElement>document.getElementById('carrera');
    this.carreraselecionada = carrera.value;
    this.banderatabla = false;
  }

  consultarperiodosescolares(){
    this.examenprofesional.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarcarreras();
      }
    });
  }

  consultarcarreras(){
    this.examenprofesional.listarcarrara(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carreras = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

}
