import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicioSocialService } from '../servicio-social.service';
declare var $ : any;

@Component({
  selector: 'app-buqueda-alumnos',
  templateUrl: './buqueda-alumnos.component.html',
  styleUrls: ['./buqueda-alumnos.component.css']
})
export class BuquedaAlumnosComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings = {
    columns:{
      apellidop:{
        title:'Apellido Paterno',
        editable:false,
        width:'10%'
      },
      apellidom:{
        title:'Apellido Materno',
        editable:false,
        width:'10%'
      },
      nombres:{
        title:'Nombre',
        editable:false,
        width:'10%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      },
      carrera:{
        title:'Carrera',
        editable:false,
        width:'15%'
      },
      semestre:{
        title:'Sem.',
        editable:false,
        width:'5%'
      },
      regular:{
        title:'Regular',
        editable:false,
        width:'5%'
      },
      estatus:{
        title:'Estatus',
        editable:false,
        width:'10%'
      },
      periodoingreso:{
        title:'Periodo Ingreso',
        editable:false,
        width:'10%'
      },
      periodoegreso:{
        title:'Periodo Egreso',
        editable:false,
        width:'10%'
      }

    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30,
      custom:[
        { name:'selecionarDocument', title:'Solicitar' }
      ]
    },
    noDataMessage:'Sin datos disponibles.'
  }
  alumno = {
    alumnoplanestudioid: 0,
    apellidom: "",
    apellidop: "",
    aple_id: 0,
    asignacionid: 0,
    carrera: "",
    editable: 0,
    estatus: "",
    extraordinario: 0,
    id: 0,
    matricula: "",
    nombrecompleto: "",
    nombres: "",
    oportunidades: 0,
    periodoegreso: "",
    periodoingreso: "",
    planestudioid: 1,
    plesemestres: 0,
    promedioegreso: 0,
    regular: "",
    semestre: ""
  };
  alumnos = [];
  periodos = [];
  plandeestudios = [];
  semestres = [];
  respuesta = {
    codigo: '',
    detalle: '',
    mensaje: ''
  };
  statusForm = new FormGroup({
    status: new FormControl('', [Validators.required])
  });
  perForm = new FormGroup({
    per: new FormControl(0, [Validators.required])
  });

  periodo ;
  banderacalificaciones: boolean = true;
  banderadocs: boolean = true;
  banedraformato: boolean = true;
  constructor(private ss: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodos();
    this.consultarplanes();
  }

  formatoLiberacion(){
    console.log(this.alumno);
    $('#t').modal('hide');
    $('#fomratos').modal('show');
    this.banedraformato = false;
  }

  formatoLiberacionSalir(){
    $('#fomratos').modal('hide');
    this.banedraformato = true;
  }

  subirDocumento(){
    $('#t').modal('hide');
    $('#docs').modal('show');
    this.banderadocs = false;
  }

  subirDocumentoSalir(){
    $('#docs').modal('hide');
    this.banderadocs = true;
  }

  cambiarEstado(){
    let json = {
      matriculap: this.maestro.matricula,
      alumnoplanestudioid: this.alumno.alumnoplanestudioid,
      estatus: this.statusForm.get('status').value
    };
    this.ss.cambiarEstadoSS(json).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  tomarCalificacionesModal(){
    $('#capturarcalificaciones').modal('hide');
  }

  tomarCalificaciones(){
    $('#capturarcalificaciones').modal('show');
    this.banderacalificaciones = false;
  }

  cerrarRespuesta(){
    $('#respuesta').modal('hide');
  }

  cambiarStatusModalCerrar(){
    $('#status').modal('hide');
  }

  reinscribirCerrar(){
    $('#reincribir').modal('hide');
  }

  cambiarStatusModal(){
    $('#status').modal('show');
  }

  abirtareas(event){
    this.banderacalificaciones = true;
    this.banderadocs = true;
    this.banedraformato = true;
    this.alumno = event.data;
    this.statusForm.get('status').patchValue(this.alumno.estatus);
    $('#t').modal('show');
  }

  buscaralumno(){
    var dato = <HTMLSelectElement>document.getElementById('dato');
    var plan = <HTMLSelectElement>document.getElementById('plan');
    var semestre = <HTMLSelectElement>document.getElementById('semestre');
    var regular = <HTMLSelectElement>document.getElementById('regular');
    var estatus = <HTMLSelectElement>document.getElementById('estatus');
    var periodoI = <HTMLSelectElement>document.getElementById('periodoingreso');
    var periodoE = <HTMLSelectElement>document.getElementById('periodoegreso');
    var periodoB = <HTMLSelectElement>document.getElementById('periodobaja');
    let parametros = {
      dato: dato.value,
      planestudioid: plan.value,
      semestre: semestre.value,
      regular: regular .value,
      estatus: estatus.value,
      periodoidingreso: periodoI.value,
      periodoidegreso: periodoE.value,
      periodoidbaja: periodoB.value
    };
    this.ss.consultaralumnos(parametros).subscribe({
      next:(res: any) => {
        this.alumnos = res;
      },
      error:(err) =>  {
      console.log(err);
      }, 
      complete:() => {
      }
    });
  }

  reinscribir(){
    $('#t').modal('hide');
    $('#reincribir').modal('show');
  }
  cambioperiodo(){
    let per =  <HTMLSelectElement>document.getElementById('periodoegreso1');
    this.periodo = per.value;
  }
  enviarReinscripcion(){
    
    let jason = {
      periodoid:this.periodo.value,
      alumnoplanestudioid:this.alumno.alumnoplanestudioid
    }
    this.ss.reincripcion(jason).subscribe({
      next:(res: any) => {
        this.respuesta = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#reincribir').modal('hide');
        $('#respuesta').modal('show');
        this.buscaralumno();
      }
    });
  }

  consultarperiodos(){
    this.ss.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.periodos = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarplanes(){
    this.ss.consultarplanestudios().subscribe({
      next:(res: any) => {
        this.plandeestudios = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarsemestres(){
    var plan = <HTMLSelectElement>document.getElementById('plan');
    this.ss.listaSemestres(this.maestro.matricula, plan.value).subscribe({
      next:(res: any) => {
        this.semestres = res;
        console.log(res);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

}
