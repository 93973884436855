import { Injectable } from '@angular/core';
import{HttpClient}from '@angular/common/http'
import {Global} from 'src/global';


interface CrearFecha{
  idCalendario:number,
  periodoId: number,
  mpeId: number,
  fechaOrdinario: String,
  fechaExtraordinario: String
}

@Injectable({
    providedIn: 'root'
  })
  export class ConfiguracionesService {
    constructor(private http: HttpClient, private global:Global) { }

    insertarFechas(fechasExamenes:CrearFecha){
      return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/CalendarioExamenes/abcFechas`,fechasExamenes);
    }

    listaFechas(matricula: string,idperiodo:number,idple: number){
        return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/fechasCarrera/${matricula}/${idperiodo}/${idple}`,null);
    }
    
    listaSemestres(matricula: string,idple: number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matricula}/${idple}`,null);
    }

    listaCarreras(matricula: string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matricula}`,null);
    }

    materiaPlanEstudio(matricula: string,ple:string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/list/${matricula}/${ple}`,null);
    }

    materiaMpeId(matricula: string,mpeId:string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/mpeId/${matricula}/${mpeId}`,null);
    }

    bajaFechaMateria(matricula: string,mpeId:string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/CalendarioExamenes/bajaFechas/${matricula}/${mpeId}`,null);
    }

    materiaPESemestre(matricula: string,ple:string,semestre:string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/materiasSemestre/${matricula}/${ple}/${semestre}`,null);
    }

    listaCalendarioExamenes(matricula: string,idPeriodo:number){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/CalendarioExamenes/lista/${matricula}/${idPeriodo}`,null);
    }

    periodos(matricula: string){
      return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbierto/${matricula}`,null);
    }
}