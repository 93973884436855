import { Component, OnInit } from '@angular/core';
import { ClasificacionService } from '../services/clasificacion.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
 declare var $:any;
@Component({
  selector: 'app-clasificaciones',
  templateUrl: './clasificaciones.component.html',
  styleUrls: ['./clasificaciones.component.css']
})
export class ClasificacionesComponent implements OnInit {

  createForm = new FormGroup({
    clasificacion: new FormControl('', [Validators.required, Validators.maxLength(20),Validators.minLength(4)]),
    id: new FormControl(null),
  });
  response: any;
  responseCr: any;
  responseDel:any;
  reponseEdit:any;
  respEliminar: any;
  respEdit: any;
  clasificaciones: any;
  

  clasificacionModal = '';
  idModal: number;

  mensajeAvisoError=false;
  mensajeAviso='';
  mensaje='';

  funcion='';

  clasificacionDatos={
    nombre:'',
    id:null
  }
  nombreViejoEdit=''

  editForm = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.maxLength(20),Validators.minLength(4)]),
  });
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  links = [];
  onFinished(){
    if(this.funcion=='eliminar'){
      console.log('entro a eliminar');
      this.onFinishDelete()
      
    }else if(this.funcion=='crear'){
      console.log('entro a crear');
      this.onFinishCreate()
    }else if(this.funcion=='editar'){
      console.log('entro a editar');
      this.onFinishEdit()
      
    }
  }
  onClose(){

    this.clasificacionModal = '';
    this.idModal = null;
  }
  
  onEdit(id: number, clasificacion: string){
    this.clasificacionDatos.id=id;
    this.nombreViejoEdit=clasificacion

    this.editForm.patchValue({
      nombre:clasificacion
    })
    
  }
  onWarningEdit(){
    var aulaInput = (<HTMLInputElement>document.getElementById('clasificacion')).value;

    this.clasificacionDatos.nombre=aulaInput
    this.mensaje=`Esta seguro de editar el nombre de la clasificación  "${this.nombreViejoEdit}"  a`
    this.funcion='editar'
    $('#confirmacion').modal('show');
  }
  onFinishEdit(){

    
        var jason = {
          clasificacion: this.clasificacionDatos.nombre,
          id: this.clasificacionDatos.id
        }
        this.clasificacion.editar(jason).subscribe({
          next: (resp) => {
            this.respEdit = resp;
            console.log(jason);
            
            console.log(this.respEdit);
            
            if(this.respEdit.codigo=='ERROR'){
              this.mensajeAvisoError=true;
              this.mensajeAviso=this.respEdit.mensaje; 
              $('#modalEditForm').modal('hide');
              $('#aviso').modal('show');
            }else{
              this.mensajeAvisoError=false;
              this.mensajeAviso=this.respEdit.mensaje;
              $('#modalEditForm').modal('hide');
              $('#aviso').modal('show');
            }
            this.listarClasificaciones()

          },
          error: () => {
            throw new Error("Error al editar");
          }
        })
  }
  onDelete(id: number,clasificacion:string) {
    console.log('Eliminando ' + id);
    this.clasificacionDatos.nombre=clasificacion;
    this.clasificacionDatos.id=id;
    this.mensaje='Esta seguro de eliminar la clasificación'
    this.funcion='eliminar'
    $('#confirmacion').modal('show');


  }
  onFinishDelete(){
    this.clasificacion.eliminar(this.clasificacionDatos.id).subscribe({
      next: (res) => {
        this.respEliminar = res;
        console.log(this.respEliminar);
        if(this.respEliminar.codigo=='ERROR'){
          this.mensajeAvisoError=true;
          this.mensajeAviso=this.respEliminar.mensaje; 
          $('#aviso').modal('show');
        }else{
          this.mensajeAvisoError=false;
          this.mensajeAviso=this.respEliminar.mensaje;
          
          $('#aviso').modal('show');
        }
        this.listarClasificaciones()
      },
      error: (err) => {
        throw new Error(err);
      }
      
    
    })
  }
  listarClasificaciones(){
    this.clasificacion.listar(this.maestro.matricula).subscribe({
      next: (resp) => {
        this.response = resp;
        console.log('Respuesta');
        console.log(this.response);
      },
      error: () => {
        throw new Error('Error al cargar');
      }
    });
  }
  onCreate() {
    var aulaInput = (<HTMLInputElement>document.getElementById('aulaInputN')).value;

    this.clasificacionDatos.nombre=aulaInput
    this.mensaje='Esta seguro de crear la clasificación'
    this.funcion='crear'
    $('#confirmacion').modal('show');


  }
  
  onFinishCreate(){
    var jason={
      clasificacion:this.clasificacionDatos.nombre,
      id:null
    }
    this.clasificacion.insertar(jason).subscribe({
      next: (resp) => {
        this.responseCr = resp;
        console.log(this.responseCr);

        if(this.responseCr.codigo=='ERROR'){
          this.mensajeAvisoError=true;
          this.mensajeAviso=this.responseCr.mensaje; 
          $('#modalCreateClasificacionForm').modal('hide');
          $('#aviso').modal('show');
        }else{
          this.mensajeAvisoError=false;
          this.mensajeAviso=this.responseCr.mensaje;
          $('#modalCreateClasificacionForm').modal('hide');

          $('#aviso').modal('show');
        }
        this.listarClasificaciones()
        
      },
      error: () => {
        throw new Error('Error al realizar insercion');
      }

    });
    
    this.createForm.patchValue({
      clasificacion: ''
    });
  }

  
  constructor(
    private clasificacion: ClasificacionService,
  ) {}

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    console.log(this.maestro.matricula);
    this.clasificacion.listar(this.maestro.matricula).subscribe({
      next: (res) => {
        this.response = res;
        console.log('Respuesta');
        console.log(this.response);

        this.clasificaciones = this.response;
        // tslint:disable-next-line: prefer-for-of
        // for (let i = 0; i < this.response.length; i++) {
        //   this.links.push(this.response[i].clasificacion);
        // }
        // console.log(this.links[1]);
        // console.log(this.links[1]);
      },
      error: () => {
        throw new Error('Error al cargar');
      },
    });
  }
}

