import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AulasService} from '../services/aulas.service';
declare var $:any;
@Component({
  selector: 'app-aulas',
  templateUrl: './aulas.component.html',
  styleUrls: ['./aulas.component.css']
})
export class AulasComponent implements OnInit {
  response: any;
  responseEdit: any;
  responseDelete: any;
  responseCreate: any;
  aulaModal: string;
  edificioModal: string;
  capacidadModal: number;
  matriculap: string;
  idModal: number;

  mensajeAvisoError=false;
  mensajeAviso='';
  mensaje='';

  funcion='';

  aulaDatos = {
    nombre:"",
    id: null,
    edificio: '',
    capacidad:null
  }

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  editForm = new FormGroup({
    aula: new FormControl('', Validators.required),
    edificio: new FormControl('',Validators.required),
    capacidad: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")])
  });
  createForm = new FormGroup({
    aula: new FormControl('', Validators.required),
    edificio: new FormControl('',Validators.required),
    capacidad: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")])
  });
  onFinish(){
    if(this.funcion=='eliminar'){
      console.log('entro a eliminar');

      this.onFinishDelete();
    }else if(this.funcion=='crear'){
      console.log('entro a crear');
      $('#modalCreateAulasForm').modal('hide');
      this.onFinishCreate();
    }else if(this.funcion=='editar'){
      console.log('entro a editar');

      this.onFinishEdit()
    }
  }
  onDelete(id: number,nombre:string){
    this.aulaDatos.nombre=nombre;
    this.aulaDatos.id=id;
    this.mensaje='Esta seguro de eliminar el aula';
    this.funcion='eliminar'

    $('#confirmacion').modal('show');

    
  }
  onFinishDelete(){
    
    
      this.matriculap = this.maestro.matricula;
      var jason = {
        dato: this.aulaDatos.id,
        matriculap: this.matriculap
      }
      this.aula.borrarAula(jason).subscribe({
        next: (res) => {
          this.responseDelete = res;
          console.log(this.responseDelete);
          if(this.responseDelete.codigo=='ERROR'){
            this.mensajeAvisoError=true;
            this.mensajeAviso=this.responseDelete.mensaje;
          }else{
            this.mensajeAvisoError=false;
            this.mensajeAviso=this.responseDelete.mensaje;
            
            this.recargarLista();
          }
          $('#aviso').modal('show');
        },
        error: () => {
          throw new Error("Error al borrar");
        }
      })
    
  }
  onEdit(aula, edificio, capacidad, id){
    this.aulaModal = aula;
    this.edificioModal = edificio;
    this.capacidadModal = capacidad;
    this.idModal = id;

    this.editForm.patchValue({
      aula: this.aulaModal,
      edificio: this.edificioModal,
      capacidad: this.capacidadModal
    });
  }
  onWarningEdit(){
    var aulaInput = (<HTMLInputElement>document.getElementById('aulaInput')).value;
    var edificioInput = (<HTMLInputElement>document.getElementById('edificioInput')).value;
    var capacidadInput = parseInt((<HTMLInputElement>document.getElementById('capacidadInput')).value);

    this.aulaDatos.nombre=aulaInput;
    this.aulaDatos.edificio=edificioInput;
    this.aulaDatos.capacidad=capacidadInput;
    this.mensaje='Esta seguro de editar la información del aula a ';
    this.funcion='editar'
    $('#confirmacion').modal('show');

  }

  onFinishEdit(){
    
    
    
    if ((this.aulaDatos.nombre !== this.aulaModal) || (this.aulaDatos.edificio !== this.edificioModal) || (this.aulaDatos.capacidad !== this.capacidadModal)){
      this.matriculap = this.maestro.matricula;
      const jason = {
        matriculap: this.matriculap,
        aula: {
          id: this.idModal,
          capacidad: this.aulaDatos.capacidad,
          edificio: this.aulaDatos.edificio,
          nombre: this.aulaDatos.nombre
        }
      };
      
      console.log(jason);
      this.aula.editarAula(jason).subscribe({
        next: (res) => {
          this.responseEdit = res;

          if(this.responseEdit.codigo == 'OK'){
            console.log(this.responseEdit);
            this.mensajeAvisoError=false;
            this.mensajeAviso=this.responseEdit.mensaje;
            this.recargarLista();
            $('#modalEditAulasForm').modal('hide');
            

          }else{
            this.mensajeAvisoError=true;
            this.mensajeAviso=this.responseEdit.mensaje;
            
            $('#modalEditAulasForm').modal('hide');   
          }
          $('#aviso').modal('show');

        },
        error: () => {
          throw new Error('Error al editar');
        }
      });
      
    }
  }
  
  onCreate(){
    var aulaInput = (<HTMLInputElement>document.getElementById('aulaInputN'));
    var edificioInput = (<HTMLInputElement>document.getElementById('edificioInputN'));
    var capacidadInput = (<HTMLInputElement>document.getElementById('capacidadInputN'));
    this.aulaDatos.nombre=aulaInput.value;
    this.aulaDatos.edificio=edificioInput.value;
    this.aulaDatos.capacidad=capacidadInput.value;

    this.mensaje='Esta seguro de queres crear una nueva aula?';
    this.funcion='crear'
    $('#confirmacion').modal('show');
  }

  onFinishCreate(){
    this.matriculap = this.maestro.matricula;
    var jason = {
      matriculap: this.matriculap,
      aula:{
        capacidad:this.aulaDatos.capacidad,
        edificio: this.aulaDatos.edificio,
        nombre: this.aulaDatos.nombre
      }
    }
    console.log(jason);
    // console.log('hola');
    
    this.aula.crearAula(jason).subscribe({
      next:(res)=>{
        console.log('entro a next');
        this.responseCreate=res
        if(this.responseCreate.codigo=='ERROR'){
          this.mensajeAvisoError=true;
          this.mensajeAviso=this.responseCreate.mensaje; 
          $('#aviso').modal('show');
        }else{
          this.mensajeAvisoError=false;
          this.mensajeAviso=this.responseCreate.mensaje;
          
          $('#aviso').modal('show');
          // this.recargarLista();
          
        }
        this.createForm.patchValue({
          aula:'',
          edificio:'',
          capacidad:null
        })
        
      },
      error:(err)=>{
        console.log('hola error');
        
        throw new Error(err);
      }
    })
    
    
  }
  onClose(){
    this.aulaModal = '';
    this.edificioModal = '';
    this.capacidadModal = null;
  }
  constructor(private aula: AulasService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.aula.listaAulas(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.response = res;
        console.log('Respuesta');
        console.log(this.response);
      },
      error: ()=>{
        throw new Error('Error al cargar aulas');
      }
    });
  }
  recargarLista (){
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.aula.listaAulas(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.response = res;
        console.log('Respuesta');
        console.log(this.response);
      },
      error: ()=>{
        throw new Error('Error al cargar aulas');
      }
    });
  }

}
