import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PersonalService } from './../services/personal.service';
import { Component, OnInit } from '@angular/core';
import { saveAs  } from 'file-saver'
import * as moment from 'moment';

declare var $ : any;
@Component({
  selector: 'app-examen-profesional',
  templateUrl: './examen-profesional.component.html',
  styleUrls: ['./examen-profesional.component.css']
})
export class ExamenProfesionalComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  };

  settings = {
    columns:{
      matricula:{
        title:'Matricula',
        editable:false,         
        width:'15%'
      },
      nombrecompleto:{
        title:'Alumno',
        editable:false,         
        width:'40%'
      },
      semestre:{
        title:'Semestre',
        editable:false,         
        width:'15%'
      },
      carrera:{
        title:'Carrera',
        editable:false,         
        width:'30%'
      },
      
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
        //{ name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settingsExamenes = {
    columns:{
      matricula:{
        title:'Matricula',
        editable:false,         
        width:'10%'
      },
      nombrecompleto:{
        title:'Alumno',
        editable:false,         
        width:'25%'
      },
      fecha:{
        title:'Fecha',
        editable:false,         
        width:'15%'
      },
      modalidad:{
        title:'Modalidad',
        editable:false,         
        width:'10%'
      },
      calificaciontxt:{
        title:'Calificación',
        editable:false,         
        width:'10%'
      },
      estado:{
        title:'Estado',
        editable:false,         
        width:'15%'
      },
      
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
        { name:'revisar', title:'<a class="btn btn-danger btn-sm text-white boton ml-2" title="Revision"><i class="fas fa-check-square  " ></i></a>'},
        { name:'imprimir', title:'<a class="btn btn-danger btn-sm text-white boton ml-2" title="Imprimir"><i class="fas fa-print  " ></i></a>'},
        //{ name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  };

  alumno = {
    matricula:'',
    alumnoplanestudioid:0,
    nombrecompleto:'',
    semestre: 0,
    carrera:'',
    carreraid: 0,
    alumnoid: 0,
    planestudioid: 0,
  };

  AgregarExamenForm = new FormGroup({
    aula: new FormControl('',[Validators.required]),
    alumnoplanestudioid :new FormControl('',[Validators.required]),
    nombrecompleto: new FormControl(''),
    alumno_semestre: new FormControl(0),
    alumno_carrera: new FormControl(0),
    alumno_matricula: new FormControl(''),
    matriculap: new FormControl(''),
    presidente:new FormControl('',[Validators.required]),
    secretario:new FormControl('',[Validators.required]),
    sinodal:new FormControl('',[Validators.required]),
    fecha:new FormControl('',[Validators.required]),
    modalidad:new FormControl(''),
    egel1:new FormControl(''),
    egel2:new FormControl(''),
    egel3:new FormControl(''),
    egel4:new FormControl(''),
    egelr:new FormControl(''),
    periodo: new FormControl(0,[Validators.required])
  });

  EditarExamenForm = new FormGroup({
    id: new FormControl(0),
    aula: new FormControl(''),
    alumnoplanestudioid :new FormControl('',[Validators.required]),
    nombrecompleto: new FormControl(''),
    alumno_semestre: new FormControl(0),
    alumno_carrera: new FormControl(0),
    alumno_matricula: new FormControl(''),
    matriculap: new FormControl(''),
    presidente:new FormControl(''),
    secretario:new FormControl(''),
    sinodal:new FormControl(''),
    fecha:new FormControl(''),
    modalidad:new FormControl(''),
    egel1:new FormControl(''),
    egel2:new FormControl(''),
    egel3:new FormControl(''),
    egel4:new FormControl(''),
    egelr:new FormControl(''),
    periodo: new FormControl(0)
  });

  CalificartForm = new FormGroup({
    id: new FormControl(0,[Validators.required]),
    observacion:new FormControl(''),
    calificacion: new FormControl(0,[Validators.required])
  });

  busquedaForm = new FormGroup({
    dato: new FormControl(''),
    semestre: new FormControl(0)
  });

  src = [];
  modalidad = [ 
    {
      nombre: 'VIRTUAL'
    },
    {
      nombre: 'PRESENCIAL'
    }
  ];
  examenesAlumno = [];
  examenAlumnoFiltro = [];
  periodosescolares = [];
  carreras = [];
  responseMaestros : any;
  aulas:any;
  documentos;
  validacion;
  
  banderaTablaExamen: boolean = false;
  banderaTabla:boolean = false;
  banderaSpiner: boolean = false;
  ss:boolean = false;
  ssu:boolean = false;
  biblioteca:boolean = false;
  egel:boolean = false;
  kardex:boolean = false;
  pagos:boolean = false;
  exprof:boolean = false;
  banderapdf: boolean = false;
  banderaderecho: boolean = false;
  banderalista: boolean = false;

  archssu;
  archbiblioteca;
  archegel;
  archpagos;
  pdf;
  type = FileMimeType.PDF;

  uploadForm = new FormGroup({
    file: new FormControl('', [Validators.required])
  });

  constructor(private personal: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarcarreras();
    this.consultarperiodosescolares();
    this.cargarMaestros();
    this.aulasLista();
    
  }
  consultarCandidato(){
    this.banderaTabla = false
    this.banderaSpiner = true;
    let jason = {
      matriculap: this.maestro.matricula,
      dato: this.busquedaForm.get('dato').value,
    }
    this.personal.listarCandidatos(jason).subscribe({
      next:(data : any) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaSpiner = false;
        this.banderaTabla = true;
      }
    });
  }
  consultarperiodosescolares(){
    this.personal.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
      }
    });
  }
  consultarcarreras(){
    this.personal.listarcarrara(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carreras = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  cargarMaestros(){
    this.personal.maestro(this.maestro.matricula).subscribe({
      next:(res)=>{
        this.responseMaestros=res;
      },
      error:()=>{
        throw new Error('Error al consultar maestros')
      },
      complete:() => {

      }
    })
  }

  aulasLista(){
    this.personal.listaAulas(this.maestro.matricula).subscribe({
      next:(res)=>{
        this.aulas=res;
      },
      error:()=>{
        throw new Error('Error al consultar información')
      }
    })
  }

  consultarkardex(){
    this.banderapdf = false
    this.pdf = '';
    this.personal.imprimirKardexLargos(this.alumno.matricula, this.maestro.matricula, this.alumno.planestudioid).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }

  consultarServicioSocial(){
    this.banderapdf = false
    this.pdf = '';
    this.personal.consultaArchivosSS(this.alumno.matricula, this.alumno.planestudioid).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }
//Consulta los archivos de el servicio social universitario
  consultarServicioSocialU(){
    this.banderapdf = false
    this.pdf = '';
    this.personal.consultaArchivosSSU( this.maestro.matricula, this.alumno.matricula, this.archssu).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }
  

  consultarArchivo(a){
    //console.log(this.alumno);
    let id;
    if(a == 'BIBLIOTECA'){
      id = this.archbiblioteca
    }
    if(a == 'PAGOS'){
      id = this.archpagos
    }
    if(a == 'EGEL'){
      id = this.archegel
    }
    this.banderapdf = false
    this.pdf = '';
    this.personal.consultaArchivosGenericos( this.maestro.matricula, id).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }

// Sube el archivo 
  onFileSelect(event, description) {
    var name = event.target.files[0].name;
    if(name.slice(-4) == ".pdf"){
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.uploadForm.get('file').setValue(file);
        this.subirArchivo(description);
      }
    }
  }

  subirArchivo(a){
    const formdata = new FormData();
    formdata.append('file', this.uploadForm.get('file').value),
    formdata.append('matriculap', this.maestro.matricula);
    formdata.append('tabla', 'examen_profesional');
    formdata.append('campo', 'alumno');
    formdata.append('idCampo', this.alumno.alumnoplanestudioid+'');
    formdata.append('campoCatalogo', a);
    formdata.append('nota', '');
    this.personal.subirArchivosGenericos(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //this.consultarDocumentosAlumno();
        $('#documentos').modal('hide');
        $('#respuesta').modal('show');
      }
    })
  }
  evento(e){
    //console.log(e)
    if (e.action == 'editar'){
      this.alumno = e.data
      this.consultarValidacion();
      
    }
  }

  cerrarModalPrincipal(){
    $('#acciones').modal('hide');
    this.alumno = {
      matricula:'',
      alumnoplanestudioid:0,
      nombrecompleto:'',
      semestre: 0,
      carrera:'',
      carreraid: 0,
      alumnoid: 0,
      planestudioid: 0,
    };
  }

  AgregarFecha(){
    let jason = {
      aula : this.AgregarExamenForm.get('aula').value,
      alumno : this.AgregarExamenForm.get('alumnoplanestudioid').value,
      matriculap : this.maestro.matricula,
      presidente : this.AgregarExamenForm.get('presidente').value,
      secretario : this.AgregarExamenForm.get('secretario').value,
      sinodal : this.AgregarExamenForm.get('sinodal').value,
      fecha : this.AgregarExamenForm.get('fecha').value,
      modalidad : this.AgregarExamenForm.get('modalidad').value,
      periodo : this.AgregarExamenForm.get('periodo').value,
      egel1 : this.AgregarExamenForm.get('egel1').value,
      egel2 : this.AgregarExamenForm.get('egel2').value,
      egel3 : this.AgregarExamenForm.get('egel3').value,
      egel4 : this.AgregarExamenForm.get('egel4').value,
      egelr :this.AgregarExamenForm.get('egelr').value,
    }
    //console.log(jason);
    
    this.personal.insertExamenProf(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.AgregarExamenForm.reset();
        this.AgregarExamenForm.enable(); 
        $('#respuesta').modal('show');
        $('#derecho').modal('hide');
        
      }
    });
  }

  EditarFecha(){
    let jason = {
      id: this.EditarExamenForm.get('id').value,
      aula : this.EditarExamenForm.get('aula').value,
      alumno : this.EditarExamenForm.get('alumnoplanestudioid').value,
      matriculap : this.maestro.matricula,
      presidente : this.EditarExamenForm.get('presidente').value,
      secretario : this.EditarExamenForm.get('secretario').value,
      sinodal : this.EditarExamenForm.get('sinodal').value,
      fecha : this.EditarExamenForm.get('fecha').value,
      modalidad : this.EditarExamenForm.get('modalidad').value,
      periodo : this.EditarExamenForm.get('periodo').value,
      egel1 : this.EditarExamenForm.get('egel1').value,
      egel2 : this.EditarExamenForm.get('egel2').value,
      egel3 : this.EditarExamenForm.get('egel3').value,
      egel4 : this.EditarExamenForm.get('egel4').value,
      egelr :this.EditarExamenForm.get('egelr').value,
    }
    this.personal.editarExamenProf(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.EditarExamenForm.reset();
        this.EditarExamenForm.enable(); 
        $('#respuesta').modal('show');
        $('#editarderecho').modal('hide');
        
      }
    });
  }  

  consultarExamensAlumno(){
    this.banderaTablaExamen = false;
    this.examenesAlumno = [];
    let jason = {
      alumno : this.alumno.alumnoplanestudioid,
      matriculap : this.maestro.matricula,
    }
    this.personal.consultaExamenesAlumnos(jason).subscribe({
      next:(data : any) => {
        this.examenAlumnoFiltro = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.examenAlumnoFiltro.forEach(e => {
          this.examenesAlumno.push({
            alumno: e.alumno,
            alumnoplanestudioid: e.alumnoplanestudioid,
            aula: e.aula,
            calificacion: e.calificacion,
            calificaciontxt: (e.calificacion==1)?'ACREDITADO':'NO ACREDITADO',
            carrera: e.carrera ,
            carreraid: e.carreraid,
            estado: e.estado,
            fecha: e.fecha,
            id: e.id,
            matricula: e.matricula,
            modalidad: e.modalidad,
            nombrecompleto: e.nombrecompleto,
            observacion: e.observacion,
            periodo: e.periodo,
            presidente: e.presidente,
            secretario: e.secretario,
            semestre: e.semestre,
            sinodal: e.sinodal,
            egel1 : e.egel1,
            egel2 : e.egel2,
            egel3 : e.egel3,
            egel4 : e.egel4,
            egelr : e.egelr
          })
        });
        console.log(this.examenesAlumno);
        this.banderaTablaExamen = true; 
        $('#examenes').modal('show');
      }
    });
  }

  consultarDocumentosAlumno(){
    let jason = {
      alumno : this.alumno.alumnoplanestudioid,
      matriculap : this.maestro.matricula,
    }
    this.personal.consultaDocumentosAlumnosExamen(jason).subscribe({
      next:(data : any) => {
        this.documentos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //debugger 
        // console.log(this.documentos);
        this.ss = (this.documentos.servicioSocial==1);
        this.ssu = ((this.documentos.servicioSocialUniversitario.charAt(0)==1))?true:false  ;
        this.biblioteca = (this.documentos.biblioteca.charAt(0)==1)?true:false;
        this.egel = (this.documentos.resultadoEGEL.charAt(0)==1)?true:false;
        this.kardex = (this.documentos.kardex==1);
        this.pagos = (this.documentos.pagos.charAt(0)==1)?true:false;
        this.exprof = (this.documentos.examenPractico==1);

        if(this.biblioteca){
          this.archbiblioteca = this.documentos.biblioteca.substr(1);
        }
        if(this.pagos){
          this.archpagos = this.documentos.pagos.substr(1);
        }
        if(this.egel){
          this.archegel = this.documentos.resultadoEGEL.substr(1);
        }
        if(this.ssu){
          this.archssu = this.documentos.servicioSocialUniversitario.substr(1);
        }
        $('#documentos').modal('show');
      }
    });
  }

  consultarValidacion(){
    this.banderaderecho = false;
    this.banderalista = false;
    let jason = {
      alumno : this.alumno.alumnoplanestudioid,
      matriculap : this.maestro.matricula,
    }
    this.personal.consultaValidacionAlumnos(jason).subscribe({
      next:(data : any) => {
        this.validacion = data.validacion;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.validacion);
        if((this.validacion == '1111111')||(this.validacion == '1111110')){
          this.banderaderecho = true;
        }
        if((this.validacion.substr(-1) == 1)){
          this.banderalista = true;
        }
        $('#acciones').modal('show');
        
      }
    });
  }

  Calificar(){
    this.personal.calificarExamenPro(this.CalificartForm.value).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.CalificartForm.reset();
        $('#calificar').modal('hide');
        $('#respuesta').modal('show');
        
      }
    });
  }

  abrirModalDerecho(){
    this.AgregarExamenForm.get('alumno_matricula').patchValue(this.alumno.matricula);
    this.AgregarExamenForm.get('alumnoplanestudioid').patchValue(this.alumno.alumnoplanestudioid);
    this.AgregarExamenForm.get('nombrecompleto').patchValue(this.alumno.nombrecompleto);
    this.AgregarExamenForm.get('alumno_semestre').patchValue(this.alumno.semestre);
    this.AgregarExamenForm.get('alumno_carrera').patchValue(this.alumno.carreraid);
    this.AgregarExamenForm.get('alumno_matricula').disable();
    this.AgregarExamenForm.get('alumnoplanestudioid').disable();
    this.AgregarExamenForm.get('nombrecompleto').disable();
    this.AgregarExamenForm.get('alumno_semestre').disable();
    this.AgregarExamenForm.get('alumno_carrera').disable();
    $('#derecho').modal('show');
    $('#acciones').modal('hide');
  }

  cerrarModalDerecho(){
    this.AgregarExamenForm.reset();
    this.AgregarExamenForm.enable(); 
    $('#acciones').modal('show');
    $('#derecho').modal('hide');
  }

  abrirModalDocumentos(){
    this.consultarDocumentosAlumno();
    
    $('#acciones').modal('hide');

  }
  cerrarModalDocumentos(){
    $('#acciones').modal('show');
    $('#documentos').modal('hide');
  }
  cerrarModalPDF(){
    $('#verpdf').modal('hide');
  }

  abrirModalExamenes(){
    this.consultarExamensAlumno();
    
    $('#acciones').modal('hide');
  }

  cerrarModalExamenes(){
    $('#acciones').modal('show');
    $('#examenes').modal('hide');
  }

  eventExamenesAlumno(e){
    if (e.action == 'editar'){
      //console.log(e.data);
      
      //this.EditarExamenForm.patchValue(e.data);
      
      if((e.data.estado != "CONCLUIDO")&&(e.data.estado != "CANCELADO")){
        this.EditarExamenForm.get('id').patchValue(e.data.id);
        this.EditarExamenForm.get('alumno_matricula').patchValue(e.data.matricula);
        this.EditarExamenForm.get('alumnoplanestudioid').patchValue(e.data.alumnoplanestudioid);
        this.EditarExamenForm.get('nombrecompleto').patchValue(e.data.nombrecompleto);
        this.EditarExamenForm.get('alumno_semestre').patchValue(e.data.semestre);
        this.EditarExamenForm.get('alumno_carrera').patchValue(e.data.carreraid);
        this.EditarExamenForm.get('aula').patchValue(e.data.aula),
        this.EditarExamenForm.get('presidente').patchValue(e.data.presidente),
        this.EditarExamenForm.get('secretario').patchValue(e.data.secretario),
        this.EditarExamenForm.get('sinodal').patchValue(e.data.sinodal),
        this.EditarExamenForm.get('fecha').patchValue((e.data.fecha).replace(' ','T')),
        this.EditarExamenForm.get('modalidad').patchValue(e.data.modalidad),
        this.EditarExamenForm.get('periodo').patchValue(e.data.periodo),
        this.EditarExamenForm.get('egel1').patchValue(e.data.egel1),
        this.EditarExamenForm.get('egel2').patchValue(e.data.egel2);
        this.EditarExamenForm.get('egel3').patchValue(e.data.egel3);
        this.EditarExamenForm.get('egel4').patchValue(e.data.egel4);
        this.EditarExamenForm.get('egelr').patchValue(e.data.egelr);
        this.EditarExamenForm.get('alumno_matricula').disable();
        this.EditarExamenForm.get('alumnoplanestudioid').disable();
        this.EditarExamenForm.get('nombrecompleto').disable();
        this.EditarExamenForm.get('alumno_semestre').disable();
        this.EditarExamenForm.get('alumno_carrera').disable();
        this.EditarExamenForm.get('periodo').disable();
        
        $('#examenes').modal('hide');
        $('#editarderecho').modal('show');
      }else{
        this.respuesta = {
          codigo: 'OK',
          mensaje: 'Este examen esta cancelado o concluido',
          detalle: ''
        };
        $('#examenes').modal('hide');
        $('#respuesta').modal('show');
      }
    }
    if (e.action == 'revisar'){
      if((e.data.estado != 'CONCLUIDO')&&(e.data.estado != 'CANCELADO')){
        this.CalificartForm.get('id').patchValue(e.data.id)
        $('#examenes').modal('hide');
        $('#calificar').modal('show');
      }else{
        this.respuesta = {
          codigo: 'OK',
          mensaje: 'Este examen esta cancelado o concluido',
          detalle: ''
        };
        $('#examenes').modal('hide');
        $('#respuesta').modal('show');
      }
    }
    if (e.action == 'imprimir'){
      if((e.data.estado != 'CONCLUIDO')&&(e.data.estado != 'CANCELADO')){
        this.invitacionExamen(e.data.id);
      }else{
        this.respuesta = {
          codigo: 'OK',
          mensaje: 'Este examen esta cancelado o concluido',
          detalle: ''
        };
        $('#examenes').modal('hide');
        $('#respuesta').modal('show');
      }
    }
      
      
      
  }

  cerrarModalEditarDerecho(){
    this.EditarExamenForm.reset();
    this.EditarExamenForm.enable(); 
    $('#examenes').modal('show');
    $('#editarderecho').modal('hide');
  }

  abriModalCancelar(){
    $('#editarderecho').modal('hide');
    $('#cancelacion').modal('show');
  }

  cerrarModalCancelacion(){
    $('#editarderecho').modal('show');
    $('#cancelacion').modal('hide');
  }
  Cancelar(){
    let jason = {
      id:this.EditarExamenForm.get('id').value,
      matriculap : this.maestro.matricula,
    }
    this.personal.cancelarExamenPro(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#cancelacion').modal('hide');
        $('#respuesta').modal('show');

      }
    });
  }

  actaExamen(){
    
    this.banderapdf = false
    this.pdf = '';
    this.personal.consultaAActaExamenPro( this.maestro.matricula, this.alumno.planestudioid ).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }
  invitacionExamen(id){
    this.banderapdf = false
    this.pdf = '';
    this.personal.consultaInvitacionExamenPro( this.maestro.matricula, id).subscribe({
      next:(data : ArrayBuffer) => {
        this.pdf = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = true;
        $('#verpdf').modal('show');
      }
    })
  }

  cerrarCalificar(){
    $('#examenes').modal('show');
      $('#calificar').modal('hide');
  }

  descargarPDF(){
    var blob = new Blob([this.pdf], {type: "application/pdf;charset=utf-8"});
    saveAs(blob, `Documento`);
  }

}
