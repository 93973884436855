import { Component, Input, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import {LocalDataSource} from 'ng2-smart-table';
import { parse } from 'path';
declare var $ : any;
var moment = require('moment');

@Component({
  selector: 'app-agregar-nuevo-pe',
  templateUrl: './agregar-nuevo-pe.component.html',
  styleUrls: ['./agregar-nuevo-pe.component.css']
})
export class AgregarNuevoPEComponent implements OnInit {

  tipoperiodos = [
    { value:1, tipo:'ANUAL' },
    { value:2, tipo:'SEMESTRAL' },
    { value:3, tipo:'CUATRIMESTRE' },
    { value:4, tipo:'TRIMESTRAL' },
    { value:5, tipo:'VERANO' },
    { value:6, tipo:'INVIERNO' }
  ]

  estados = [
    { value:'ACTUAL', tipo:'ACTUAL' },
    { value:'ABIERTO', tipo:'ABIERTO' },
    { value:'CERRADO', tipo:'CERRADO' }
  ]

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }

  settingsPeriodos = {
    columns:{
     periodo:{
       title:'Período',
       editable:false,         
       width:'20%'
    },
     estado:{
       title:'Estado',
       editable:false,         
       width:'20%'
     },
     inicio:{
       title:'Inicio',
       editable:false,         
       width:'15%'
     },
     fin:{
       title:'Fin',
       editable:false,         
       width:'15%'
     },
     iniciovacaciones:{
       title:'Inicio Vac.',
       editable:false,         
       width:'10%'
     },
     finvacaciones:{
       title:'Fin Vac.',
       editable:false,         
       width:'10%'
     }
   },
   actions:{
     add:false,
     edit:false,
     delete:false,
     columnTitle:'',
     type:'html',
     custom:[
       { name:'editar', title:''},
      //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
     ],         
     width:'15%',
     position: 'right'
   },
   pager:{
     perPage:15
   },
   noDataMessage:'Sin datos disponibles.'
 }
  
  response = {
    codigo:'',
    mensaje:'',
    detalle:''
  };

  periodosForm= new FormGroup({
    id: new FormControl(0),
    idtipoperiodo:new FormControl(0),
    periodo:new FormControl('',[Validators.required]),
    estado: new FormControl(''),
    fin:new FormControl(''),
    finvacaciones:new FormControl(''),
    inicio:new FormControl(''),
    iniciovacaciones:new FormControl(''),
    claveoficial:new FormControl('', [Validators.required]),
    inscripcion:new FormControl(0),
    matriculap:new FormControl('')
  });

  periodos = [];
  campos=[];
  fechainicio;
  fechainicio_ISO;
  fechaI: IMyDateModel = null;
  fechafin;
  fechafin_ISO;
  fechaF: IMyDateModel = null;
  fechavacacionesinicio;
  fechavacacionesinicio_ISO;
  fechaVI: IMyDateModel = null;
  fechavacacionesfin;
  fechavacacionesfin_ISO;
  fechaVF: IMyDateModel = null;
  aviso = '';
  banderatabla:boolean = true;
  esinscripcion = 0;


  access = [];
  banderaAgregarPeriodo : boolean = true;
  banderaEditarPeriodo : boolean = true;

  constructor(private personalService: PersonalService) { 
    document.body.addEventListener('input', this.enforce_maxlength);
  }

   agregarbtn(){
     if(this.banderaEditarPeriodo == false){
       this.settingsPeriodos.actions.custom[0].title = '<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-pencil-alt " ></i></a>';
     }
   }

  esinscripcioncheck(event){
    if (event.target.checked){
      this.esinscripcion = 1;
    }else{
      this.esinscripcion = 0;
    }
  }

  acciones(event){
    switch ( event.action ){
      case 'editar':
      this.editarPeriodo(event.data);
      break;
      case 'borrar':

    }
  }
  enforce_maxlength(event) {
    var t = event.target;
    if (t.hasAttribute('maxlength')) {
      t.value = t.value.slice(0, t.getAttribute('maxlength'));
    }
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.listaPeriodos();
    this.viewBtn();
  }

  viewBtn(){
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10080 || this.access[i] == 3){
        this.banderaEditarPeriodo = false;
        this.agregarbtn();
      }
      if(this.access[i] == 10081 || this.access[i] == 3){
        this.banderaAgregarPeriodo = false;
      }
    }
  }

  fechainicioI(event){
    console.log(event);
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioF(event){
    this.fechafin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioVI(event){
    this.fechavacacionesinicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechavacacionesinicio_ISO = moment(date).format('YYYY-MM-DD');
  }

  
  fechainicioVF(event){
    this.fechavacacionesfin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechavacacionesfin_ISO = moment(date).format('YYYY-MM-DD');
  }



  cerrarvalidaciones(){
    $('#validacion').modal('hide');
  }

  agregarperiodo(){
    $('#crearperiodo').modal('show');
  }
  
  cerrarrespuesta(){
    $('#respuesta').modal('hide');
  }

  cerraragrearperiodo(){
    var idtipoperiodo = <HTMLSelectElement>document.getElementById('idtipoperiodo');
    var estado = <HTMLSelectElement>document.getElementById('estado');
    var inscripcion = <HTMLInputElement>document.getElementById('inscripcion');
    $('#crearperiodo').modal('hide');
    this.periodosForm.reset();
    idtipoperiodo.selectedIndex = 0;
    estado.selectedIndex = 0;
    inscripcion.checked = false;
    this.fechaI = null;
    this.fechaF = null;
    this.fechaVI = null;
    this.fechaVF = null;
  }

  listaPeriodos(){
    this.banderatabla = false;
    this.personalService.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next: (res : any) =>{
        this.periodos = res;
      },
      error: (err)=>{
        console.log(err);
      },
      complete:()=>{
        this.banderatabla = true;
      }
    });
  }



  crearPeriodo(data){
    var idtipoperiodo = <HTMLSelectElement>document.getElementById('idtipoperiodo');
    var estado = <HTMLSelectElement>document.getElementById('estado');
    this.personalService.agregarPeriodoEscolar(data).subscribe({
      next:(res : any)=>{
        this.response = res;
      },
      error:(err)=>{
        console.log(err);
        
      },
      complete:() => {
        if(this.response.codigo === 'OK'){
          $('#crearperiodo').modal('hide');
          setTimeout(() => {
            $('#respuesta').modal('show');
          }, 1000);
          this.periodosForm.reset();
          idtipoperiodo.selectedIndex = 0;
          estado.selectedIndex = 0;
          this.fechaI = null;
          this.fechaF = null;
          this.fechaVI = null;
          this.fechaVF = null;
          this.listaPeriodos();
        }else{
          $('#respuesta').modal('show');
        }
      }
    });
  }

  editarPeriodo(valores){
    $('#crearperiodo').modal('show');
    var idtipoperiodo = <HTMLSelectElement>document.getElementById('idtipoperiodo');
    var estado = <HTMLSelectElement>document.getElementById('estado');
    var inscripcion = <HTMLInputElement>document.getElementById('inscripcion');
    for(var i = 0; i < this.tipoperiodos.length; i++){
      if(valores.idtipoperiodo == this.tipoperiodos[i].value){
        idtipoperiodo.selectedIndex = i;
      }
    }
    for(var x = 0; x < this.estados.length; x++){
      if(valores.estado == this.estados[x].value){
        estado.selectedIndex = x;
        break;
      }
    }
    console.log(valores);
    this.periodosForm.patchValue({
      id:valores.id,
      idtipoperiodo:valores.idtipoperiodo,
      periodo:valores.periodo,
      inicio:valores.inicio,
      iniciovacaciones:valores.iniciovacaciones,
      fin:valores.fin,
      finvacaciones:valores.finvacaciones,
      claveoficial:valores.claveoficial,
      inscripcion:valores.inscripcion
    });
    this.fechaI = {
      singleDate:{ formatted:valores.inicio, date: {year:  parseInt(valores.inicio.substring(6)), month:  parseInt(valores.inicio.substring(3, 5)), day:  parseInt(valores.inicio.substring(0, 2))} },
      isRange:false      
    }
    this.fechaF = {
      singleDate:{ formatted:valores.fin,  date: {year:  parseInt(valores.fin.substring(6)), month: parseInt(valores.fin.substring(3, 5)), day:  parseInt(valores.fin.substring(0, 2))} },
      isRange:false      
    }
    this.fechaVI = {
      singleDate:{ formatted:valores.iniciovacaciones,  date: {year: parseInt(valores.iniciovacaciones.substring(6)), month:  parseInt(valores.iniciovacaciones.substring(3, 5)), day:  parseInt(valores.iniciovacaciones.substring(0, 2))} },
      isRange:false      
    }
    this.fechaVF = {
      singleDate:{ formatted:valores.finvacaciones, date: {year:  parseInt(valores.finvacaciones.substring(6)), month:  parseInt(valores.finvacaciones.substring(3, 5)), day:  parseInt(valores.finvacaciones.substring(0, 2))} },
      isRange:false      
    }
    if(this.periodosForm.get('inscripcion').value == 1){
      inscripcion.checked;
    }
  }

  datosperiodo(){
    var idtipoperiodo = <HTMLSelectElement>document.getElementById('idtipoperiodo');
    var estado = <HTMLSelectElement>document.getElementById('estado');
    var checked = <HTMLInputElement>document.getElementById('inscripcion');
    this.periodosForm.get('idtipoperiodo').patchValue(idtipoperiodo.value);
    this.periodosForm.get('estado').patchValue(estado.value);
    this.periodosForm.get('inicio').patchValue(this.fechaI.singleDate.formatted);
    this.periodosForm.get('iniciovacaciones').patchValue(this.fechaF.singleDate.formatted);
    this.periodosForm.get('fin').patchValue(this.fechaVI.singleDate.formatted);
    this.periodosForm.get('finvacaciones').patchValue(this.fechaVF.singleDate.formatted);
    this.periodosForm.get('inscripcion').patchValue((checked.checked?1:0));
    this.periodosForm.get('matriculap').patchValue(this.maestro.matricula);
    // this.crearPeriodo(this.periodosForm.value);
    // console.log(this.periodosForm.value);
    
    if( this.fechainicio_ISO > this.fechafin_ISO ){
      this.aviso = 'Fecha inicial de Período debe ser menor a la final.';
      $('#validacion').modal('show');
    }else{
      if( this.fechavacacionesinicio_ISO > this.fechavacacionesfin_ISO ){
        this.aviso = 'Fecha inicial de vacaciones debe ser menor a la final.';
        $('#validacion').modal('show');
      }else{
        if( (idtipoperiodo.value == '') || (idtipoperiodo.value == undefined) || (idtipoperiodo.value == null)){
          this.aviso = 'Seleccione un tipo de período.'
          $('#validacion').modal('show');
        }else{
          if( (estado.value == '') || (estado.value == undefined) || (estado.value == null) ){
            this.aviso = 'Seleccione un estado de período.'
            $('#validacion').modal('show');
          }else{
            this.crearPeriodo(this.periodosForm.value);
          }
        }
      }
    }
  }

  eliminarperiodoescolar(){

  }

  

  mensajes=[];
  validarFechas(rsp){
    // (<HTMLSelectElement>document.getElementById(f1)).value;
    // (<HTMLSelectElement>document.getElementById(f1)).value
    
    this.mensajes=[];  
    console.log('Mensajes: ');
    if(this.periodosForm.get('periodo').value=="" || this.periodosForm.get('periodo').value=="undefined"){
      this.mensajes.push("Ingrese nombre de período"); 
    }
    if(this.periodosForm.get('inicio').value >= this.periodosForm.get('fin').value){
      this.mensajes.push("Fecha inicial de Período debe ser menor a la final");
      
    }
      
    if(this.periodosForm.get('iniciovacaciones').value > this.periodosForm.get('finvacaciones').value ){
      this.mensajes.push("Fecha inicial de Vacaciones debe ser menor a la final");
    }
      
    if(this.periodosForm.get('inicioinscripreg').value >= this.periodosForm.get('fininscripreg').value ){
      this.mensajes.push("Fecha inicial de Inscripciones de alumnos regulares debe ser menor a la final");
    }
      
    if(this.periodosForm.get('inicioinscripirreg').value >= this.periodosForm.get('finincripirreg').value ){
      this.mensajes.push("Fecha inicial de Inscripciones de alumnos irregulares debe ser menor a la final");
    }
      
    if(this.periodosForm.get('inicioordinario').value >= this.periodosForm.get('finordinario').value){
      this.mensajes.push("Fecha inicial de exámenes ordinarios debe ser menor a la final");
    }
      
    if(this.periodosForm.get('inicioextraordinario').value >= this.periodosForm.get('finextraordinario').value ){
      this.mensajes.push("Fecha inicial de exámenes extraordinarios debe ser menor a la final");
    }
    (<HTMLSelectElement>document.getElementById('btnAceptarPeriodo')).disabled=true;
    //console.log(this.mensajes);
    console.log(this.periodosForm);
    
    // if (this.mensajes.length==0 && this.periodosForm.valid==true){
    //   this.fechasValidas=true;
    //   (<HTMLSelectElement>document.getElementById('btnAceptarPeriodo')).disabled=true;
    //   return 1;
    //  }else{
    //   this.fechasValidas=false;
    //   (<HTMLSelectElement>document.getElementById('btnAceptarPeriodo')).disabled=false;
    //   if(rsp==1){
    //     $('#modalValidacion').modal('show');
    //   }

    //   return 0;
    //  }
    
    

    
  }
  
  
}
