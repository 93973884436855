import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicioSocialService } from '../servicio-social.service';
declare var $ : any;

@Component({
  selector: 'app-capturar-calificaciones',
  templateUrl: './capturar-calificaciones.component.html',
  styleUrls: ['./capturar-calificaciones.component.css']
})
export class CapturarCalificacionesComponent implements OnInit {

  @Input('id') id;
  @Input('alumnoplanestudioid') alumnoplanestudioid;

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };
  periodos = [];
  calificarForm = new FormGroup({
    matriculap: new FormControl('', [Validators.required]),
    id: new FormControl(0, [Validators.required]),
    alumnoplanestudioid: new FormControl('', [Validators.required]),
    periodoid: new FormControl('', [Validators.required]),
    fechacalificacion: new FormControl('', [Validators.required]),
    semestre: new FormControl('', [Validators.required]),
    calificacion: new FormControl(0, [Validators.required])
  });
  respuesta = {
    codigo: '',
    detalle: '',
    mensaje: ''
 };
  constructor(private ss: ServicioSocialService) {
    document.body.addEventListener('input', this.enforce_maxlength);
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodos();
  }

  cerrarRespuesta(){
    $('#respuesta').modal('hide');
  }

  mandarCalificacion(){
    this.calificarForm.get('matriculap').patchValue(this.maestro.matricula);
    this.calificarForm.get('id').patchValue(this.id);
    this.calificarForm.get('alumnoplanestudioid').patchValue(this.alumnoplanestudioid);
    this.ss.capturarCalificacionSS(this.calificarForm.value).subscribe({
      next:(res: any) => {
        this.respuesta = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  cerrarValidacion(){
    $('#validarcalificaciones').modal('hide');
  }

  consultarperiodos(){
    this.ss.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.periodos = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  validacioncalificaciones(){
    var calificacion  = <HTMLSelectElement>document.getElementById(`calificacion`);
      if(calificacion.value == ""){
        calificacion.style.removeProperty('border-color');
      }else{
        if(parseFloat(calificacion.value) >= 0.0 && parseFloat(calificacion.value) <= 10.0 ){
          calificacion.style.removeProperty('border-color');
        }else{
          $('#validarcalificaciones').modal('show');
          calificacion.style.borderColor = "red";
        }
      }
  }

  enforce_maxlength(event) {
    var t = event.target;
    if (t.hasAttribute('maxlength')) {
      t.value = t.value.slice(0, t.getAttribute('maxlength'));
    }
  }

}
