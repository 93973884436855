import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InscripcionesService } from './../services/inscripciones.service';
import { Component, OnInit } from '@angular/core';
declare var $ : any;
@Component({
  selector: 'app-cierre-para-incripciones',
  templateUrl: './cierre-para-incripciones.component.html',
  styleUrls: ['./cierre-para-incripciones.component.css']
})
export class CierreParaIncripcionesComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }

  cerrarform = new FormGroup({
    matriculap: new FormControl(''),
    periodoid: new FormControl('', Validators.required),
    planestudioid: new FormControl('', Validators.required)
  })

  carrerasactivos = [];
  periodosescolarescompleta = [];

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  constructor(private service : InscripcionesService ) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
    this.consultarcarrerasactivos()
  }

  consultarperiodoescolares(){
    this.service.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolarescompleta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
    
      }
    });
  }
  consultarcarrerasactivos(){
    this.service.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carrerasactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
      }
    });
  }
  abrirAdvertencia(){
    $('#advertencia').modal('show');
  }
  enviar(){
    let jason = {
      matriculap: this.maestro.matricula,
      periodoid: this.cerrarform.get('periodoid').value,
      planestudioid:this.cerrarform.get('planestudioid').value,
    }
    this.service.cerrarInscripciones(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#advertencia').modal('hide');
        $('#respuesta').modal('show');
        this.cerrarform.get('periodoid').patchValue('')
        this.cerrarform.get('planestudioid').patchValue('')
      }
    });
  }

}
