<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>
  <div class="row">
    <div class="col-12">
      <div class="div bg-white">
        <ul class="nav nav-pills flex-column bg-white">
          <div id="logo">
            <a class="navbar-brand">
              <img src="../../../assets/img/logov2.2.png" width="100%">
            </a>
          </div>
          <div *ngIf="!banderamenu">
            <li  class="nav-item" *ngFor="let link of menu">
              <div *ngIf="link.title != 'Catalogos' && link.title != 'Configuraciones'">
                <a class="nav-link" routerLinkActive="bg-danger text-white" [hidden]="link.hidden" [class.disabled]="isDisabled(link.disabled)" [routerLink]="link.path"><span id="icon" class="{{ link.icon }}"></span>{{ link.title }}</a>
              </div>
              <div *ngIf="link.title == 'Catalogos'" [hidden]="link.hidden"> 
                <div class="btn-group dropright">
                  <a class=" nav-link dropdown-toggle" id="cata" routerLinkActive="text-danger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i id="icon" class="{{ link.icon }}"></i>Catálogos</a>
                  <div class="dropdown-menu">
                    <div *ngFor="let catalogo of catalogos">
                      <a href="#" [routerLink]="catalogo.path" routerLinkActive="text-danger">{{catalogo.title}}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="link.title == 'Configuraciones'" [hidden]="link.hidden">       
                <div class="btn-group dropright">
                  <a class=" nav-link dropdown-toggle" id="conifg" routerLinkActive="text-danger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i id="icon" class="{{ link.icon }}"></i>Configuraciones</a>
                  <div class="dropdown-menu">
                    <div *ngFor="let configuracion of configuraciones">
                      <a href="#" [routerLink]="configuracion.path" routerLinkActive="text-danger">{{configuracion.title}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </div>
          <li *ngIf="!banderamenu" class="nav-item">
            <a class="nav-link" routerLinkActive="bg-danger text-white" (click)="logout()"><span id="icon" class="fas fa-sign-out-alt"></span>Salir</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!banderamenu" class="row text-center" >
    <div class="col-12">
      <label>v{{ currentApplicationVersion }}</label>  
    </div>
  </div>

</body>
</html>