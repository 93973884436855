import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CalAnimation, IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
import { AuditoriaService } from '../services/auditoria.service';

@Component({
  selector: 'app-preacta',
  templateUrl: './preacta.component.html',
  styleUrls: ['./preacta.component.css']
})
export class AuditoriaPreactaComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  settings = {
    columns : {
      ins_id:{
        title:'Matrucla del alumno',
        editable:false,
        width:'5%'
      },
      nombre_alumno:{
        title:'Nombre del alumno',
        editable:false,
        width:'10%'
      },
      prea_id:{
        title:'Grupo',
        editable:false,
        width:'8%'
      },
      tipo_cal:{
        title:'Tipo calificacion',
        editable:false,
        width:'5%'
      },
      fecha_cal:{
        title:'Fecha Calificado',
        editable:false,
        width:'10%'
      },
      fecha_captura:{
        title:'Fecha Captura',
        editable:false,
        width:'10%'
      },
      calificacion:{
        title:'Calificacion',
        editable:false,
        width:'5%'
      },
      calificado:{
        title:'Calificado',
        editable:false,
        width:'5%'
      },
      status:{
        title:'Estatus',
        editable:false,
        width:'2%'
      },
      fecha:{
        title:'Fecha Hora',
        editable:false,
        width:'10%'
      },
      accion:{
        title:'Acción',
        editable:false,
        width:'10%'
      },
      usuario:{
        title:'Usuario',
        editable:false,
        width:'8%'
      },
      nombre_personal:{
        title:'Nombre del personal',
        editable:false,
        width:'13%'
      },
      ip_public:{
        title:'IP publica',
        editable:false,
        width:'10%'
      },
      ip_private:{
        title:'IP privada',
        editable:false,
        width:'10%'
      }
      
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30
    },
    noDataMessage:'Sin auditoria disponible.'
  }

  busqueda = new FormGroup({
    matricula:new FormControl(''),
    gruId: new FormControl('')
  });

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  fechainicio;
  fechainicio_ISO;
  fechaI: IMyDateModel = null;
  fechafin;
  fechafin_ISO;
  fechaF: IMyDateModel = null;
  idperiodo = "0";
  periodosescolares = [];
  periodosescolaresfiltrados = [];
  auditoria = [];
  auditoriafiltrada = [];
  bandera:boolean = true;
  bandera2:boolean = true;
  contador = 0;
  constructor(private auditoriaservice: AuditoriaService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  consultarperiodoescolares(){
    this.auditoriaservice.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  buscar(){
    this.auditoria = [];
    this.auditoriafiltrada = [];
    this.bandera2 = false;
    this.bandera = true;
    var jason = {
      perid:parseInt(this.idperiodo),
      matriculap:this.busqueda.get('matricula').value,
      ffin:this.fechafin_ISO,
      finicio:this.fechainicio_ISO,
      gruId:this.busqueda.get('gruId').value,
      grunombre:"",
      semestre:"",
      materia:"",
      matricula:"",
    }
    this.auditoriaservice.consultarpreacta(jason).subscribe({

      next:(data : any) => {
        this.auditoria = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.auditoria.length; i++){
          var jason = {
            ins_id:this.auditoria[i].ins_id,
            prea_id:this.auditoria[i].prea_id,
            fecha_cal:this.auditoria[i].fecha_cal,
            tipo_cal:((this.auditoria[i].tipo_cal==1)?"Ord":((this.auditoria[i].tipo_cal==2)?"Ext":"")),
            fecha_captura:this.auditoria[i].fecha_captura,
            calificado:this.auditoria[i].calificado,
            calificacion:((this.auditoria[i].calificacion==-3)?"NTD":(this.auditoria[i].calificacion==-99)?"NP":this.auditoria[i].calificacion),
            preacta:this.auditoria[i].preacta,
            status:this.auditoria[i].status,
            fecha: this.auditoria[i].fecha,
            accion: this.auditoria[i].accion,
            usuario:this.auditoria[i].usuario,
            ip_public:this.auditoria[i].ip_public,
            ip_private:this.auditoria[i].ip_private,
            nombre_personal:this.auditoria[i].nombre_personal,
            nombre_alumno:this.auditoria[i].nombre_alumno,
          }
          this.auditoriafiltrada.push(jason);
        }
        this.contador = this.auditoriafiltrada.length;
        this.bandera = false;
      }
    });
  }

  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    if(periodoid.value == ""){

    }else{
      this.idperiodo = periodoid.value;
    }
  }

  fechainicioF(event){
    this.fechafin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioI(event){
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

}
