import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class PlanestudiosService {

  constructor(private http: HttpClient, private global: Global) { }

  consultarpdf(idperiodo){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/planEstudios/curricula/${idperiodo}`, { responseType: 'arraybuffer' })
  }

  consultarperiodoescolar(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  consultarmateriarelacion(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/MateriaRelacion`,dato)
  }

  consultarmaterias(matriculap, plan, semestre){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/materiasSemestre/${matriculap}/${plan}/${semestre}`,null)
  }

  insertrelacion(dato){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/insertRequisito`,dato)
  }

  deleterelacion(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/deleteRequisito`,dato)
  }

  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }

  insertplan(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/insert`, dato);
  }

  editplan(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/editar`, dato);
  }

  consultarMPE(matriculap,idPlan){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/list/${matriculap}/${idPlan}`, null);
  }

  consultarMNPE(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/materiaNoplan`, dato);
  }

  insertmpe(dato, idplan,matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/insertaMateriaPlan/${idplan}/${matriculap}`, dato);
  }

  deletempe(mpeid,matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/deleteMateriaPlan/${mpeid}/${matriculap}`, null);
  }

  editmpe(dato,idplan, matriculap){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/editMateriaPlan/${idplan}/${matriculap}`, dato);
  }


  consultarcarreras(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/lista/${matriculap}`, null);
  }

  
  imprimirpdf(idperiodo){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/planEstudios/curricula/${idperiodo}`);
  }

}