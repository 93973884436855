import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { ExcelService } from '../services/excel.service';
import { FormsModule } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-materias',
  templateUrl: './materias.component.html',
  styleUrls: ['./materias.component.css']
})
export class MateriasComponent implements OnInit {

  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  materiasPE = [];
  grupos;
  currentpage = 0;
  term: string;
  semestre: number;
  bandera = true;
  bandera2 = true;

  settings = {
    columns:{
      carrera:{
        title:'Carrera',
        editable:false,
        width:'20%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'10%'
      },
      clave:{
        title:'Clave',
        editable:false,
        width:'10%'
      },
      nombre:{
        title:'Materia',
        editable:false,
        width:'60%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  Grupos:boolean = true;
  grupoId;

  datoMateria =  {
    clave:'',
    electiva:0,
    id:0,
    nombre:'',
    semestre:''
  }

  banderaSpinner:boolean = true;

  access = [];
  banderaExportarExcel : boolean = true;
  banderaVisualizarGrupos : boolean = true;

  constructor(private router: Router, private personalService: PersonalService, private chRef: ChangeDetectorRef, private excelService:ExcelService) {
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    })
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10017 || this.access[i] == 3){
        this.banderaExportarExcel = false;
      }
      if(this.access[i] == 10155 || this.access[i] == 3){
         this.banderaVisualizarGrupos = false;
      }
    }
    this.listarMaterias();
  }

  exportAsXLSX():void {
   this.excelService.exportAsExcelFile(this.materiasPE, 'MATERIAS');
  }

  reset(){
    this.Grupos = true;
  }

  verGrupos(value){
    if(this.banderaVisualizarGrupos == false){
      this.datoMateria = value.data;
      this.grupoId = value.data.id;
      localStorage.setItem('grupo', this.grupoId);
      $('#grupos').modal('show');
      this.Grupos = false;
    }
  }

  listarMaterias(){
    this.personalService.listarMaterias(this.maestro.matricula).subscribe({
      next:(resp : any) => {
        this.materiasPE = resp;
        this.bandera = false;
        if(this.materiasPE == []){
          this.bandera2 = false;
        }
        
      },
      complete:() => {
        this.banderaSpinner = false;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
        this.banderaSpinner = false;
      }
    });
  }

  listarGrupos(id){
    var jason = {
      idmpe:id,
      matricula:this.maestro.matricula
    }
    this.personalService.listarGrupos(jason).subscribe({
      next:(resp) => {
        this.grupos = resp;
        console.log(this.grupos);
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
  }

}
