import { Component, OnInit } from '@angular/core';
import {InicioService} from '../service/inicio.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $ : any;

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoTutorialesComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }
  constructor(
    private inicioservice:InicioService, 
    private _sanitizer: DomSanitizer
    ) { 
    
  }
  
  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listarTutoriales();
  }

  bandera = true;
  tutoriales = [];
  urls = [];
  titulo;

  listarTutoriales(){
    this.inicioservice.listaTutoriales(this.maestro.matricula).subscribe({
      next: (res : any)=>{
        this.tutoriales = res;
        // console.log(this.tutoriales);
        
      },
      error: () => {
        throw new Error('Error al listar tutoriales')
      },
      complete:() => {
        this.bandera = false;
      }
    })
  }
  
  getUrl(url : string) {
    var video : string, results : any;
    if (url === null) {
        return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video   = (results === null) ? url : results[1];
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);   
  }

  getThumb(url, size) {
    var video, results, thumburl;
    
    if (url === null) {
        return '';
    }
    
    results = url.match('[\\?&]v=([^&#]*)');
    video   = (results === null) ? url : results[1];
    
    if(size != null) {
        thumburl = 'http://img.youtube.com/vi/' + video + '/'+ size +'.jpg';
    }else{
        thumburl = 'http://img.youtube.com/vi/' + video + '/mqdefault.jpg';
    }
    
    return thumburl;
      
  }
  onClick(url, titulo){
    
    // console.log(url);
    // console.log(titulo);
    
    this.urls = [{url:url}];
    this.titulo = [{titulo:titulo}];
    $('#video').modal('show');
  }
  
}
