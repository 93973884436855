<body>
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-datosG-tab" data-toggle="tab" href="#nav-datosG" role="tab" aria-controls="nav-datosG" aria-selected="true">Datos Generales</a>
          <a class="nav-link" id="nav-datosC-tab" data-toggle="tab" href="#nav-datosC" role="tab" aria-controls="nav-datosC" aria-selected="false">Datos Complementarios</a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-datosG" role="tabpanel" aria-labelledby="nav-datosG-tab">
            <div class="row m-2">
                <div class="col-12 ">
            
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="nombres">Nombre</label>
                                  <input type="text" disabled   [formControl]="this.datosgeneralesForm.get('nombres')" class="form-control" id="nombres">
                                </div>
                                <div class="form-group col-4">
                                  <label for="apellidop">Apellido Paterno</label>
                                  <input type="text" disabled  [formControl]="this.datosgeneralesForm.get('apellidop')" class="form-control" id="apellidop">
                                </div>
                                <div class="form-group col-4">
                                    <label for="apellidom">Apellido Materno</label>
                                    <input type="text" disabled  [formControl]="this.datosgeneralesForm.get('apellidom')" class="form-control" id="apellidom">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="curp">CURP</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('curp')" class="form-control" id="curp">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.curp.touched || datosgeneralesForm.controls.curp.dirty)">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.required">Ingrese una CURP.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.maxlength">CURP no debe de ser mayor de 18 caracteres.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.minlength">CURP no debe de ser menor de 18 caracteres.</div>
                                </div>
                                </div>
                                <div class="form-group col-4">
                                  <label for="fechanac">Fecha de Nacimiento</label>
                                  <input type="date" disabled  [formControl]="this.datosgeneralesForm.get('fechanacimiento')" class="form-control" id="fechanac">
                                </div>
                                <div class="form-group col-4">
                                    <label for="matricula">Matrícula</label>
                                    <input type="text" disabled [formControl]="this.datosgeneralesForm.get('matricula')" class="form-control" id="matricula" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="calle">Calle</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('calle')" class="form-control" id="calle">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.calle.touched || datosgeneralesForm.controls.calle.dirty )">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.calle.errors?.required">Ingrese la calle.</div>
                                </div>
                                </div>
                                <div class="form-group col-4">
                                  <label for="colonia">Colonia / Fraccionamiento</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('colonia')" class="form-control" id="colonia">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.colonia.touched || datosgeneralesForm.controls.colonia.dirty )">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.colonia.errors?.required">Ingrese la colonia o fraccionamiento.</div>
                                </div>
                                </div>
                                <div class="form-group col-4">
                                    <label for="codigopostal">Código Postal</label>
                                    <input type="text"   [formControl]="this.datosgeneralesForm.get('codigopostal')" class="form-control" id="codigopostal">
                                    <div *ngIf="datosgeneralesForm.invalid  && (datosgeneralesForm.controls.codigopostal.touched || datosgeneralesForm.controls.codigopostal.dirty )">
                                      <div class="text-danger" *ngIf="datosgeneralesForm.controls.codigopostal.errors?.required">Ingrese el código postal.</div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="email">Correo Electrónico</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('email')" class="form-control" id="email">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.email.touched || datosgeneralesForm.controls.email.dirty )">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.email.errors?.required">Ingrese el correo personal.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.email.errors?.pattern">Correo ingresado no es valido.</div>
                                </div>
                                </div>
                                <div class="form-group col-4">
                                  <label for="telefono">Telefono</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('telefono')" class="form-control" id="telefono">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.telefono.touched || datosgeneralesForm.controls.telefono.dirty )">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.required">Ingrese un telefono.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.maxlength">Numero de telefono no debe exceder los 10 digitos.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.minlength">Numero de telefono no debe ser menos de 10 digitos.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.pattern">Numero de telefono solo debe contener caracteres numericos.</div>
                                </div>
                                </div>
                                <div class="form-group col-4">
                                  <label for="telefono">RFC</label>
                                  <input type="text"   [formControl]="this.datosgeneralesForm.get('rfc')" class="form-control" id="rfc">
                                  <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.rfc.touched || datosgeneralesForm.controls.rfc.dirty )">
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.required">Ingrese una RFC.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.maxlength">RFC no debe de ser mayor de 13 caracteres.</div>
                                    <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.minlength">RFC no debe de ser menor de 12 caracteres.</div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="planestudio">Plan de Estudios</label>
                                  <input type="text" disabled  [formControl]="this.datosgeneralesForm.get('planestudio')" class="form-control" id="planestudio">
                                </div>
                                <div class="form-group col-4">
                                  <label for="regular">Regular</label>
                                  <input type="text"  disabled [formControl]="this.datosgeneralesForm.get('regular')" class="form-control" id="regular">
                                </div>
                                <div class="form-group col-4">
                                    <label for="semestre">Semestre</label>
                                    <input type="text" disabled [formControl]="this.datosgeneralesForm.get('semestre')" class="form-control" id="semestre">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-4">
                                  <label for="estatus">Estatus</label>
                                  <input type="text" disabled [formControl]="this.datosgeneralesForm.get('estatus')" class="form-control" id="estatus">
                                </div>
                                <div class="form-group col-4">
                                  <label for="periodoingreso">Periodo</label>
                                  <input type="text"  disabled [formControl]="this.datosgeneralesForm.get('periodoingreso')" class="form-control" id="periodoingreso">
                                </div>
                                <div class="form-group col-4">
                                    <label for="apellidom">Tipo</label>
                                    <input type="text"  disabled [formControl]="this.datosgeneralesForm.get('tipo')" class="form-control" id="tipo">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="top">
                      <div class="col-12">
                        <button class="btn btn-danger float-right" [disabled]="(datosgeneralesForm.invalid)||(!banderabutton)" (click)="editardatosgenerales()"> <span *ngIf="!banderabutton" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar Cambios</button>
                      </div>
                      <!-- <div class="col-12" *ngIf="banderamodificar">
                        <button class="btn btn-danger float-right" (click)="modificar()">Modifcar datos</button>
                      </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-datosC" role="tabpanel" aria-labelledby="nav-datosC-tab">
          <div class="row">
              <div class="col-12">
                  <div class="form-row">
                      <!-- <div class="form-group col-4">
                        <label for="nacionalidad">Nacionalidad</label>
                        <input type="text"   [formControl]="this.datoscomplementariosForm.get('nacionalidad')" class="form-control" id="nacionalidad">
                      </div> -->
                      <div class="form-group col-4">
                        <label for="nacionalidad">Nacionalidad</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('nacionalidad')" class="form-control" id="nacionalidad"> -->
                        <select class="form-control" id="nacionalidad">
                          <option *ngFor="let n of naciones" [value]="n.nac_id">{{ n.nac_nacionalidad }}</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="municipionacimiento">Municipio de Nacimiento</label>
                        <input type="text"   [formControl]="this.datoscomplementariosForm.get('municipionacimiento')" class="form-control" id="municipionacimiento">
                      </div>
                      <!-- <div class="form-group col-4">
                          <label for="extranjero">Extranjero</label>
                          <input type="text"   [formControl]="this.datoscomplementariosForm.get('extranjero')" class="form-control" id="extranjero">
                      </div> -->
                      <div class="form-group col-4">
                        <label for="extranjero">Extranjero</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('extranjero')" class="form-control" id="extranjero"> -->
                        <select  class="form-control" id="extranjero">
                          <option value="NO">NO</option>
                          <option value="SI">SI</option>
                        </select>
                      </div>
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-row">
                    <div class="form-group col-4">
                      <label for="escuelaprocedencia">Escuela de Procedencia</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('escuelaprocedencia')" class="form-control" id="escuelaprocedencia">
                    </div>
                    <!-- <div class="form-group col-4">
                      <label for="trabaja">Trabaja</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('trabaja')" class="form-control" id="trabaja">
                    </div> -->
                    <div class="form-group col-4">
                      <label for="trabaja">Trabaja</label>
                      <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('trabaja')" class="form-control" id="trabaja"> -->
                      <select  class="form-control" id="trabaja">
                        <option value="NO">NO</option>
                        <option value="SI">SI</option>
                      </select>
                    </div>
                    <div class="form-group col-4">
                        <label for="estadocivil">Estado Civil</label>
                        <input type="text"   [formControl]="this.datoscomplementariosForm.get('estadocivil')" class="form-control" id="estadocivil">
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-row">
                    <!-- <div class="form-group col-4">
                      <label for="discapacidad">Discapacidad</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('discapacidad')" class="form-control" id="discapacidad">
                    </div> -->
                    <div class="form-group col-4">
                      <label for="discapacidad">Discapacidad</label>
                      <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('discapacidad')" class="form-control" id="discapacidad"> -->
                        <select class="form-control" id="discapacidad">
                          <option *ngFor="let d of discapacidades" [value]="d.id">{{ d.discapacidad }}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group col-4">
                      <label for="lentes">Lentes</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('lentes')" class="form-control" id="lentes">
                    </div> -->
                    <div class="form-group col-4">
                      <label for="lentes">Lentes</label>
                      <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('lentes')" class="form-control" id="lentes"> -->
                      <select class="form-control" id="lentes">
                        <option value="NO">NO</option>
                        <option value="SI">SI</option>
                      </select>
                    </div>
                    <div class="form-group col-4">
                        <label for="cedula">Cédula</label>
                        <input type="text"   [formControl]="this.datoscomplementariosForm.get('cedulaprofesional')" class="form-control" id="cedula">
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-row">
                    <div class="form-group col-4">
                      <label for="hijos">Hijos</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('hijos')" class="form-control" id="hijos">
                    </div>
                    <div class="form-group col-4">
                      <label for="numerohijos">Numero de Hijos</label>
                      <input type="text"   [formControl]="this.datoscomplementariosForm.get('numerohijos')" class="form-control" id="numerohijos">
                    </div>
              </div>
            </div>
          </div>
          <div class="row" id="top">
            <div class="col-12">
              <button class="btn btn-danger float-right" (click)="guardarcambioscomplementarios()">Guardar Cambios</button>
            </div>
            <!-- <div class="col-12" *ngIf="banderamodificar2">
              <button class="btn btn-danger float-right" (click)="modificar2()">Modifcar datos</button>
            </div> -->
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarresuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarresuesta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>