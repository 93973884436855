import { Component, OnInit } from '@angular/core';
import { TramitesService } from '../services/tramites.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-tramites',
  templateUrl: './tramites.component.html',
  styleUrls: ['./tramites.component.css']
})
export class TramitesComponent implements OnInit {

  busqueda = new FormGroup({
    matricula: new FormControl('', Validators.required),
  })

  settings = {
    columns:{
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'15%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'40%'
      },
      nombredoc:{
        title:'Trámite',
        editable:false,
        width:'20%'
      },
      fechasolicitud:{
        title:'Solicitud',
        editable:false,
        width:'25%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns:{
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'15%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'30%'
      },
      nombredoc:{
        title:'Trámite',
        editable:false,
        width:'15%'
      },
      fechasolicitud:{
        title:'Solicitud',
        editable:false,
        width:'20%'
      },
      fechapago:{
        title:'Pagado',
        editable:false,
        width:'20%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }
  
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  solicitudesNoListos = [];
  solicitudesListos = [];

  noPendientes=0;
  realizados=0;
  idSolicitud;

  documento = {
    fechageneracion:'',
    fechapago:'',
    fechasolicitud:'',
    generado:'',
    id:'',
    matricula:'',
    matriculpap:'',
    pago:'',
    tipodoc:{
      nombre:'',
      id:0,
      precio:0
     },
    nombre:''
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  bandera:boolean = true;
  bandera2:boolean = true;
  bandera3:boolean = true;

  constructor(private tramitesService: TramitesService) {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.financierosSolicitudNoListo();
    this.financierosSolicitudListo();
  }

  tomarIdSolicitud(value){
    this.idSolicitud = value.data.id;
    this.documento = value.data;
    $('#aviso').modal('show');
  }

  financierosSolicitudNoListo(){
    this.bandera2 = true;
    this.tramitesService.financieroSolicitudNoListo(this.maestro.matricula).subscribe({
      next:(resp : any) => {
        this.solicitudesNoListos = resp;
      },
      complete:() => {
        this.noPendientes = this.solicitudesNoListos.length;
        this.bandera2 = false;
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  financierosSolicitudListo(){
    this.bandera3 = true;
    this.tramitesService.financieroSolicitudListo(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.solicitudesListos = res;
      },
      complete:() => {
        this.realizados = this.solicitudesListos.length;
        this.bandera = false;
        this.bandera3 = false;
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  atenderFinancieros(){
    var jason = {
      dato:this.idSolicitud,
      matriculap:this.maestro.matricula
    }
    this.tramitesService.atenderFinancieros(jason).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.financierosSolicitudNoListo();
        this.financierosSolicitudListo();
      },
      error:(err) => {
        console.log(err);
      }
    });
    this.bandera = true;
  }

  sinDatosDisponibles(){
    $('#staticBackdropSinDatos').modal('show');
  }

  onSubmit(){
    this.bandera2 = true;
    this.bandera3 = true;
    var jason = {
      dato: this.busqueda.get('matricula').value,
      matriculap: this.maestro.matricula
    }
    this.tramitesService.buscarDocFinancierosNoPago(jason).subscribe({
      next:(rep : any) => {
        this.solicitudesNoListos = rep ;
      },
      complete:() => {
        this.bandera2 = false;
      },
      error:(err) => {
        console.log(err);
      }
    });
    this.tramitesService.buscarDocFinancierosPago(jason).subscribe({
      next:(rep : any) => {
        this.solicitudesListos=rep;
      },
      complete:() => {
        this.bandera3 = false;
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

}
