import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-http-errors',
  templateUrl: './http-errors.component.html',
  styleUrls: ['./http-errors.component.css']
})
export class HttpErrorsComponent implements OnInit {

  @Input() status: string;
  @Input() statustext: string;
  @Input() url: string;

  constructor() { }

  ngOnInit(): void {
  }

}
