<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <h3>Seguimiento de Alumnos Egresados</h3>
        <div class="row" >
            <div class="col-8 mt-2">
                <input  [formControl]="this.busquedaForm.get('dato')" class="form-control" autocomplete="off" type="text" name="busqueda" id="busqueda" placeholder="Matricula o Nombre del alumno">
            </div>
            <div class="col-4 mt-2">
                <button class="btn btn-danger" (click)="busquedaEgresados()" type="button">Buscar</button>
            </div>
            <div *ngIf="banderaTabla" class="col-12 mt-2">
                <ng2-smart-table class="table table-hover table-borderless" (custom)="evento($event)" [settings]="settings" [source]="src"></ng2-smart-table>
            </div>
            <div *ngIf="banderaSpiner" class="col-12 mt-2">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-danger " role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</body>
</html>
