<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="row" id="top">
        <div class="col-12">
            <h3>Administración de Plazas</h3>
        </div>
    </div>
    <div class="row" id="top">
        <div class="col-4">
            <select (change)="listaPlazas();" class="form-control" id="plazaperiodoid2" >
                <option selected>--Período Escolar--</option>
                <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="!banderatabla">
        <div class="col-12">                   
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="plazas" data-toggle="tab" href="#Plazas" role="tab" aria-controls="Plazas" aria-selected="true">Lista de Plazas</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="agregarPlaza" href="#AgregarPlaza" aria-controls="AgregarPlaza" aria-selected="false"  data-target="#modalPlaza" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
                </li>
            </ul>
            <div class="tab-content " id="myTabContent">
                <div class="tab-pane fade show active" id="plazas" role="tabpanel" aria-labelledby="plazas">
                    <ng2-smart-table class="table table-hover table-borderless" [settings]="settingsPlazas" [source]="plazas" (custom)='accionPlazas($event)'></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalPlaza" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">{{ titituloactiva }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="formPlaza">
                <div class="row" id="top">
                    <div class="col-12">
                        <select class="form-control" id="peridoescolar" >
                            <option selected>--Período Escolar--</option>
                            <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-12">
                        <select (change)="semestre()" class="form-control" id="planestudio" >
                            <option selected>--Plan de Estudio--</option>
                            <option   *ngFor="let plan of planesactivos" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{plan.plandeestudio}})</option>
                        </select>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-12">
                        <select class="form-control" id="institucion">
                            <option>--Institución--</option>
                            <option *ngFor="let institucion of instituciones" [value]="institucion.institucionid">{{ institucion.institucion }}</option>
                        </select>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-12">
                        <select class="form-control" id="semestre">
                            <option>--SEMESTRE--</option>
                            <option *ngFor="let s of semestres" [value]="s.semestre">{{s.tipo}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-6">
                        <div class="form-group">
                            <small id="asignadas" class="form-text text-muted">Asignadas</small>
                            <input type="number" class="form-control" id="asignadas" (blur)="sumarplazas()" [formControl]="this.formPlaza.get('asignadas')">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <small id="noasignadas" class="form-text text-muted">No asignadas</small>
                            <input type="number" class="form-control" id="noasignadas" (blur)="sumarplazas()" [formControl]="this.formPlaza.get('noasignadas')">
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-6">
                        <input type="number" placeholder="Plazas" disabled [value]="sumatoriaplazas" class="form-control" id="plazas">
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Ubicación" class="form-control" id="ubicacion">
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-12">
                        <select class="form-control" id="rotacion" (click)="rotacion()">
                            <option value="">--Rotación--</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
                <div class="row" id="top" *ngIf="!banderarotacion">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" placeholder="Ubicación 1" class="form-control" id="ubicacion1">
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Ubicación 2" class="form-control" id="ubicacion2">
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Ubicación 3" class="form-control" id="ubicacion3">
                        </div>
                    </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Salir</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="tomardatos()">Agregar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarmodalrespuseta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarmodalrespuseta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>


