<div class="col-lg-12">   
    <div class="row" id="cuerpo"> 
        <div class="col-lg-12">        
            <h3>Administración de Instituciones</h3>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="instituciones" data-toggle="tab" href="#Instituciones" role="tab" aria-controls="Instituciones" aria-selected="true">Lista de Instituciones</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="agregarPlaza" href="#AgregarPlaza" aria-controls="AgregarPlaza" aria-selected="false"  data-target="#modalInstitucion" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
                </li>
            </ul>
            <div class="tab-content " id="myTabContent">
                <div class="tab-pane fade show active" id="instituciones" role="tabpanel" aria-labelledby="instituciones">
                    <ng2-smart-table class="table table-hover table-borderless" [settings]="settings" [source]="instituciones" (custom)='accion($event)'></ng2-smart-table>                   
                </div>
            </div>
        
        </div>
    </div>
</div>

<div class="modal fade" id="modalInstitucion" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Institución</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" [formGroup]="formInstitucion">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="nombre">Nombre</label>
                            <input type="text" [formControl]="formInstitucion.get('institucion')" class="form-control" id="nombre" placeholder="Nombre">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="nombrecorto">Nombre Corto</label>
                            <input type="text" class="form-control" [formControl]="formInstitucion.get('nombrecorto')" id="nombrecorto" placeholder="Nombre Corto">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="contacto">Contacto</label>
                            <input type="text" class="form-control" [formControl]="formInstitucion.get('contacto')" id="contacto" placeholder="Contacto">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="estado">Estado</label>
                            <select class="form-control" id="estado" [formControl]="formInstitucion.get('estadoid')">
                                <option>--Estado--</option>
                                <option *ngFor="let estado of estadosRepublica" [value]="estado.estadoid">{{ estado.estado }}</option>
                            </select>                        
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="telefono">Teléfono</label>
                            <input type="text" class="form-control" [formControl]="formInstitucion.get('telefono')" id="telefono" placeholder="Teléfono">
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="guardar()">Guardar</button>
              </div>
        </div>
    </div>
</div>

<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div> 
              <div class="modal-body">
                {{ respuesta.mensaje }}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
              </div>
        </div>
    </div>
</div>

<!-- Modal Crear Plaza -->
<!-- <div class="modal fade" id="modalInstitucion" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <table class="table table-striped">
                <thead class="bg-danger text-white">
                    <tr>
                        <div class="modal-header text-center">
                        <h4 class="modal-title w-100 font-weight-bold">Registrar Institución</h4>
                        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            
                    <div>
                        <form  [formGroup]='formInstitucion' >
                            <div class="form-row">
                                <div class="md-form mb-3 col-6">
                                    <input  type="hidden"  class="form-control validate" [formControl]="this.formInstitucion.get('id')" id="id" >
                                    <label data-error="wrong"  data-success="right" for="defaultForm-email">Institución</label>
                                    <input  type="text" class="form-control validate" [formControl]="this.formInstitucion.get('institucion')" id="institucion">
                                </div>
                            </div> 
                            
                            <div class="form-row">
                                <div class="md-form mb-3 col-6">
                                    <label data-error="wrong" data-success="right" for="defaultForm-email">Estado de la República</label>
                                    <select class="form-control" id="estadoid" [formControl]="this.formInstitucion.get('estadoid')">
                                        <option>--Estado--</option>
                                        <option *ngFor="let estado of estadosRepublica" [value]="estado.id">{{ estado.estado }}</option>
                                      </select>
                                    
                                </div>    
                            </div>    
                            <div class="form-row">
                                <div class="md-form mb-3 col-1">
                                    <label data-error="wrong" data-success="right" for="defaultForm-email">Contacto</label>
                                    <input  type="text" class="form-control validate" [formControl]="this.formInstitucion.get('contacto')" id="contacto">
                                </div>     
                                <div class="md-form mb-3 col-1">
                                    <label data-error="wrong" data-success="right" for="defaultForm-email">Teléfono</label>
                                    <input  type="text" class="form-control validate" [formControl]="this.formInstitucion.get('telefono')" id="telefono">
                                </div>   
                            </div>    
                            <div class="form-row">
                                <div class="md-form mb-4">
                                    <button (click)='limpiarInstitucion()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Nuevo</button>
                                    <button [disabled]='!formInstitucion.valid' id="btnAceptarPlaza" type="submit" class="btn btn-danger btn-md">Guardar</button>
                                   
                                </div>  
                            </div>                     
                        </form>
                    </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->