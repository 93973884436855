import { Component, Input, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
var moment = require('moment');
declare var $ : any;

@Component({
  selector: 'app-listado-configuracion-pe',
  templateUrl: './listado-configuracion-pe.component.html',
  styleUrls: ['./listado-configuracion-pe.component.css']
})
export class ListadoConfiguracionPEComponent implements OnInit {

  @Input() datoseditar:any;
  @Input() idperiodoinyectado:any;
  @Input() idcarrerainyectado:any;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  myDateInit: boolean = true;
  examenordinarioinicio: IMyDateModel = null;
  examenordinariofin: IMyDateModel = null;
  examenextraordinarioinicio: IMyDateModel = null;
  examenextraordinariofin: IMyDateModel = null;
  preactasordinariasinicio: IMyDateModel = null;
  preactasordinariasfin: IMyDateModel = null;
  preactasextraordinariasinicio: IMyDateModel = null;
  preactasextraordinariasfin: IMyDateModel = null;
  examenOI = '';
  examenOF = '';
  examenEI = '';
  examenEF = '';
  examenESIN = '';
  examenESFN = '';
  // preactEI = '';
  // preactEF = '';

  examenOI_ISO = '';
  examenOF_ISO = '';
  examenEI_ISO = '';
  examenEF_ISO = '';
  examenESIN_ISO = '';
  examenESFN_ISO = '';

  periodosescolares = [];
  carreras = [];
  semestres = [];
  semestresfiltrados = [];
  semestreselegidos = [];
  banderacarrera:boolean = true;
  banderasemestres:boolean = true;

  carreraselecionada = '';
  idperiodo = '';
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  validacion = '';
  

  constructor(private personalService: PersonalService) {}

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
  }

  guardarcambios(){
    if(this.examenEF == '' || this.examenEI == '' || this.examenOF == '' || this.examenOI == ''){
      this.validacion = "Ingrese las fechas las fechas solicitadas."
      $('#validacion').modal('show');
    }else{
      if(this.semestreselegidos.length == 0){
        this.validacion = "Eliga minimo un semestre."
        $('#validacion').modal('show');
      }else{
        // if(this.examenOI_ISO <= this.examenOF_ISO){
        //   if(this.examenEI_ISO <= this.examenEF_ISO){
        //     if(this.examenESIN_ISO <= this.examenESFN_ISO){
        //       if(this.examenOI_ISO <= this.examenEI_ISO){
        //         if(this.examenEI_ISO <= this.examenESIN_ISO){
        //           if(this.examenOI_ISO <= this.examenESIN_ISO){
        //             if(this.examenOF_ISO <= this.examenEF_ISO){
        //               if(this.examenEF_ISO <= this.examenESFN_ISO){
        //                 if(this.examenOF_ISO <= this.examenESFN_ISO){
                          var respuesta = [];
                          for(var i = 0; i < this.semestreselegidos.length; i++){
                            var data = {
                              carrera:parseInt(this.carreraselecionada),
                              especial_fin:this.examenESFN,
                              especial_inicio:this.examenESIN,
                              extraordinarios_fin:this.examenEF,
                              extraordinarios_inicio:this.examenEI,
                              ordinarios_fin:this.examenOF,
                              ordinarios_inicio:this.examenOI,
                              periodo:parseInt(this.idperiodo),
                              semestre:this.semestreselegidos[i]+'',
                              matriculap:this.maestro.matricula
                            }
                            this.personalService.agregarcalendarios(this.idperiodo, this.carreraselecionada, data).subscribe({
                              next:(data : any) => {
                                this.respuesta = data;
                                respuesta.push(data);
                              },
                              error:(err) => {
                                console.log(err);
                              },
                              complete:() => {
                                if(this.semestreselegidos.length == respuesta.length){
                                  $('#respuesta').modal('show');
                                }
                              }
                            });
        //                   }
        //                   console.log("Lo lograste!")
        //                 }else{
        //                   this.validacion = "Fechas ingresadas son invalidas."
        //                   $('#validacion').modal('show');
        //                 }
        //               }else{
        //                 this.validacion = "Fechas ingresadas son invalidas."
        //                 $('#validacion').modal('show');
        //               }
        //             }else{
        //               this.validacion = "Fechas ingresadas son invalidas."
        //               $('#validacion').modal('show');
        //             }
        //           }else{
        //             this.validacion = "Fechas ingresadas son invalidas."
        //             $('#validacion').modal('show');
        //           }
        //         }else{
        //           this.validacion = "Fechas ingresadas son invalidas."
        //           $('#validacion').modal('show');
        //         }
        //       }else{
        //         this.validacion = "Fechas ingresadas son invalidas."
        //         $('#validacion').modal('show');
        //       }
        //     }else{
        //       this.validacion = "Fechas ingresadas son invalidas."
        //       $('#validacion').modal('show');
        //     }
        //   }else{
        //     this.validacion = "Fechas ingresadas son invalidas."
        //     $('#validacion').modal('show');
        //   }
        // }else{
        //   this.validacion = "Fechas ingresadas son invalidas."
        //   $('#validacion').modal('show');
        // }
      }
    }
  }
}

  semestresselecionados(semestre, event){
    if(event.target.checked){
      this.semestreselegidos.push(semestre);
    }else{
      for(var i = 0; i < this.semestreselegidos.length; i++){
        if(this.semestreselegidos[i] == semestre){
          this.semestreselegidos.splice(i, 1);
        }
      }
    }
  }

  selecionarperiodo(){
    let periodo = <HTMLSelectElement>document.getElementById('periodo');
    this.idperiodo = periodo.value;
    this.banderacarrera = false;
  }

  selecionarcarrera(){
    let carrera = <HTMLSelectElement>document.getElementById('carrera');
    this.carreraselecionada = carrera.value;
    if(this.semestresfiltrados.length > 0){
      this.semestresfiltrados = [];
    }
    this.semestreselecionado();
    this.banderasemestres = false;
  }

  semestreselecionado(){
    for(var i = 0; i < this.semestres.length; i++){
      if(this.carreraselecionada == this.semestres[i].idcarrera){
        this.semestresfiltrados.push(this.semestres[i]);
      }
    }
  }

  consultarperiodosescolares(){
    this.personalService.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolares = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarcarreras();
      }
    });
  }

  consultarcarreras(){
    this.personalService.listarcarrara(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.carreras = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarsemestres();
      }
    });
  }

  consultarsemestres(){
    this.personalService.listarsemestres(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.semestres = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semestres.sort(function (a, b) {
          if( a.semestre > b.semestre ){
            return 1;
          }
          if( a.semestre < b.semestre ){
            return -1;
          }
          return 0;
        })
      }
    });
  }

  examenordinarioi(event){
    this.examenOI = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenOI_ISO = moment(date).format('YYYY-MM-DD');
  }

  examenordinariof(event){
    this.examenOF = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenOF_ISO = moment(date).format("YYYY-MM-DD");
    }

  examenextraordinarioi(event){
    this.examenEI = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenEI_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenextraordinariof(event){
    this.examenEF = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenEF_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenESI(event){
    this.examenESIN = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenESIN_ISO = moment(date).format("YYYY-MM-DD");
  }

  examenESF(event){
    this.examenESFN = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.examenESFN_ISO = moment(date).format("YYYY-MM-DD");
  }

  // preactasextraordinariasi(event){
  //   this.preactEI = event.singleDate.formatted;
  // }
  // preactasextraordinariasf(event){
  //   this.preactEF = event.singleDate.formatted;
  // }

}
