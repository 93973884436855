import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {BusquedaAlumnosService} from '../services/busqueda-alumnos.service'
import {LocalDataSource} from 'ng2-smart-table';
declare var $ : any;

@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.css']
})
export class ValidacionComponent implements OnInit {
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  busqueda = new FormGroup({
    matricula: new FormControl('', [Validators.required]),
  });

  settings = {
    columns:{
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'15%'
      },
      alumno:{
        title:'Alumno',
        editable:false,
        width:'30%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'15%'
      },
      pagofamen:{
        title:'Pago FAMEN',
        editable:false,
        width:'15%'
      },
      pagosesco:{
        title:'Pago UJED',
        editable:false,
        width:'15%'
      }
    },
    actions:{
      columnTitle:'',
      position:'right',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name:'veralumno', title:'<a class="btn btn-danger btn-sm text-white" title="Editar"><i class="fas fa-pencil-alt" > </i></a>' }
      ]
    },
    pager:{
      perPage:10,
    },
    noDataMessage:'Sin alumnos con la matrícula ingresada o no cuentan con permiso de inscripción.'
  };
  estatus = [
    { estatus:'VALIDADO', valor:1 },
    { estatus:'NO VALIDADO', valor:0 }
  ]
  response = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  alumnocompleto;
  alu: LocalDataSource;
  alumnos = [];
  banderatabla:boolean = true;
  banderaspiner:boolean = true;
  banderadatos:boolean = true;

  constructor(private busquedaAlumno: BusquedaAlumnosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.alu = new LocalDataSource(this.alumnos);
  }

  veralumno(event){
    this.alumnocompleto = event.data;
    var data = <HTMLSelectElement>document.getElementById('estatus');
    for(var i = 0; i < this.estatus.length; i++){
      if(this.alumnocompleto.pagofamen == this.estatus[i].valor){
        data.selectedIndex = i;
        break;
      }
    }
    $('#pago').modal('show');
    this.banderadatos = false;
  }

  buscarmatricula(){
    this.banderaspiner = false;
    this.banderatabla = true;
    var jason = {
      dato:this.busqueda.get('matricula').value,
      matriculap:this.maestro.matricula
    }
    //this.alumnos = [];
    this.busquedaAlumno.consultarMatricula(jason).subscribe({
      next:( data : any ) => {
        this.alumnos  = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaspiner = true;
        this.alu = new LocalDataSource(this.alumnos);
        this.banderatabla = false;
      }
    });
  }

  guardar(){
    var data = <HTMLSelectElement>document.getElementById('estatus');
    var jason = {
      matricula:this.alumnocompleto.matricula,
      matriculap:this.maestro.matricula,
      pago:data.value
    }
    this.busquedaAlumno.validarPago(jason).subscribe({
      next:( data : any ) => {
        this.response = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.response.codigo == "ERROR"){
          $('#response').modal('show');
        }else{
          if(this.response.codigo == "OK"){
            this.buscarmatricula();
          }
        }
      }
    });
  }



  // recargar(){
  //   var jason = {
  //     dato: this.busqueda.get('matricula').value,
  //     matriculap: this.maestro.matricula
  //   }
  //   this.busquedaAlumno.consultarMatricula(jason).subscribe({
  //     next:(res) => {
  //       this.alumnos = res;
  //       console.log(this.alumnos);
  //       if (this.alumnos.length == 0){

  //       }else{
  //         this.bandera = false;
  //       }
  //     },
  //     error:() => {
  //       throw new Error("Error al consultar")
  //     }
  //   })
  // }
  // onSubmit(){
  //   var matriculaAlumno = this.busqueda.controls['matricula'].value;
  //   if(this.busqueda.valid){
  //     var jason = {
  //       dato: matriculaAlumno,
  //       matriculap: this.maestro.matricula
  //     }      
  //     this.busquedaAlumno.consultarMatricula(jason).subscribe({
  //       next:(res) => {
  //         this.alumnos = res;
  //       },
  //       complete:() => {
  //           if (this.alumnos == null ){
  //           alert(`Esta matrícula no existe o no tienes permiso para realizar esta tarea`);
  //         }else{
  //           this.bandera = false;
  //         }
  //       },
  //       error:() => {
  //         throw new Error("Error al consultar")
  //       }
  //     })
  //   }else{
  //     alert("Ingresa una matrícula válida");
  //   }
  // }

  // validarCuota(matriculaE: string, alumno: string){
  //   console.log("Entro a validar");
    
  //   var jason ={
  //     matricula: matriculaE,
  //     matriculap: this.maestro.matricula,
  //     pago: 1
  //   }
  //   console.log(jason);
  //   var conf = confirm(
  //     `!ATENCION\n Estas apunto de validar la cuota interna del alumno ${alumno}, este proceso es IRREVERSIBLE. ¿Esta seguro de continuar?`
  //   );
  //   if(conf==true){
  //     this.busquedaAlumno.validarPago(jason).subscribe({
  //       next:(res)=>{
  //         this.response = res;
  //         console.log(this.response);
  //         var conf = confirm(
  //           this.response.mensaje
  //         );
  //         this.recargar();
  //       },
  //       error: ()=>{
  //         throw new Error("Error al actualizar pago")
  //       }
  //     })
  //   }else{

  //   }

  // }

}
