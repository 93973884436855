<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}" 
                        matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-sm-8">
                        <div _ngcontent-uva-c30="" class="text-center"><h3 _ngcontent-uva-c30="">Expediente</h3>
                            <p style="
                                text-align: left;
                            ">A continuación puedes subir los archivos que solicita la institución para ir creando tu expediente.</p>
                            <p style="
                                text-align: left;
                            ">Ten en cuenta las siguientes indicaciones para realizar tu proceso de manera correcta.</p>
                            <p style="text-align: left;">
                                1.- Todos tus documentos tienen que ir en formato <strong>PDF</strong> <br>
                                2.- Los archivos no deben tener un peso mayor a un <strong>1 Mega</strong> <br>
                                3.- Los registros con <strong>(*)</strong> son obligatorios. <br>
                                <!-- 4.- En caso de dejar documentos pendientes por subir no obligatorios, contaras con <strong>4 semanas</strong> para hacer la carga de los mismos. -->
                            </p>
                        </div>
                        <div *ngIf="!banderatabla">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="bg-danger text-white">
                                        <th scope="col">No.</th>
                                        <th scope="col">Documento</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let documento of documentosSubidos; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ documento.nombre }}</td>
                                        <td></td>
                                        <td><button class="btn btn-danger" (click)="verDocumento(documento.id, documento.nombre)">Visualizar</button></td>
                                        <td><button class="btn btn-danger" (click)="imprimirDocumento(documento.id)">Imprimir</button></td>
                                        <td>
                                            <button (click)="selectDocumento(documento)" type="button" class="close" aria-label="Close"  data-toggle="modal" data-target="#exampleModalBorrarCenter">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div class="right">
                    <div class="row" id="top"></div>
                    <button *ngIf="banderabtn" type="button" class="btn btn-danger" data-toggle="modal" data-target="#exampleModalCenter">
                        Subir Documentos
                    </button>
                </div>

            <div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ documentonombre }}</h5>
                        <button type="button" class="close" aria-label="Close" (click)="cerrarDocumento()">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="!banderadocumento">
                            <app-visualizar-documentos [id]="iddocumento"></app-visualizar-documentos>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="cerrarDocumento()">Aceptar</button>
                    </div>
                    </div>
                </div>
            </div>

                
                <!-- Modal SUBIR DOCUMENTO -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Documentos Solicitados</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div [hidden]="!bandera">
                            <form [formGroup]="uploadForm">
                                <select id="scripts" class="form-control">
                                    <option class="option" *ngFor="let documento of documentos" [value]="documento.nombre">{{ documento.nombre }}</option>
                                </select>
                                <div class="row" id="top"></div>
                                <input enctype="multipart/form-data" accept="application/pdf" #inputFile type="file" name="documento" (change)="onFileSelect($event)">
                                <div class="row" id="top"></div> 
                                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cerrar</button>
                                <button type="submit" (click)="onSubmit()"  class="btn btn-danger" [disabled]="this.uploadForm.get('file').value.size > 3145728 || this.uploadForm.get('file').value == '' ">Guardar</button>
                            </form>
                        </div>
                        <div [hidden]="bandera">
                            <label>{{this.respuesta2.mensaje}}</label>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
    
    
                <!-- Modal ELIMINAR DOCUMENTO -->
                <div class="modal fade" id="exampleModalBorrarCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>¿Estás seguro de eliminar este documento?<br>Se eliminará de forma <strong>PERMANENTE </strong></div>
                            <div>{{ this.documentoSelectionado.nombre }}</div>
                            <div class="row" id="top"></div>
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cerrar</button>
                            <button type="submit" class="btn btn-danger" (click)="borrarDocumento()" data-dismiss="modal">Eliminar</button>
                        </div>
                        </div>
                    </div>
                </div>
    
                <!-- Modal RESPUESTA ELIMINAR DOCUMENTO -->
                <div class="modal fade" id="exampleModalBorrarRespCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>{{ this.respuesta.mensaje }}</div>
                            <div class="row" id="top"></div>
                            <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cerrar</button>
                        </div>
                        </div>
                    </div>
                </div>
    
                <!-- Modal RESPUESTA SUBIR DOCUMENTO -->
                <div class="modal fade" id="exampleModalSubirRespCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>{{ this.respuesta2.mensaje }}</div>
                            <div class="row" id="top"></div>
                            <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" (click)="updateListado();">Cerrar</button>
                        </div>
                        </div>
                    </div>
                </div>
    
                <!-- Modal RESPUESTA SUBIR DOCUMENTO -->
                <div class="modal fade" id="exampleModalSalirCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>{{ this.respuesta2.mensaje }}</div>
                            <div class="row" id="top"></div>
                            <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cerrar</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>