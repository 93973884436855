import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/global';

interface grupos{
  idmpe: number,
  matricula: string
}

interface personal{
  idfirma: number
}
interface busquedaAlumno{
  dato: string,
  matriculap: string
}

interface eliminarMaterias{
  matricula: string,
  matriculap: string,
  mpe: string
}

interface bajaAlumno{
  matricula: string,
  matriculap: string,
  estado: string
}

interface periodoEscolar{
  id: number,
  idtipoperiodo: number
  ,periodo: string,
  estado: string,
  fin: string
  ,"finextraordinario":string
  ,"finincripirreg":string
  ,"fininscripreg":string
  ,"finordinario":string
  ,"finvacaciones":string
  ,"inicio":string
  ,"inicioextraordinario":string
  ,"inicioinscripirreg":string
  ,"inicioinscripreg":string
  ,"inicioordinario":string
  ,"iniciovacaciones":string
}

interface Permisos{
  dato:number;
  matriculap:string;
}

interface QuitarPermiso{
  dato: string;
  dato2: string;
  dato3: string;
}

interface PonerPermiso{
  dato: string;
  dato2: string;
  dato3: string;
}

interface InsertVista{
  rol:string;
  carrera_id:number;
  semestre:number;
  matriculap:string;
}
interface EliminarVista{
  dato:string;
  matriculap:string;
}

interface SemestresAsignados{
  dato:number;
  dato2:number;
  dato3:string;
}
@Injectable({
  providedIn: 'root'
})
export class PersonalService {


  constructor(private http: HttpClient, private global: Global) { }

  listarMaterias(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/listamateria/${matricula}`, null)
  }

  listarGrupos(grupo: grupos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/listagrupo`, grupo)
  }

  alumGr(idGrupo: string, matriculap: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaMaestro/${idGrupo}/${matriculap}`);
   }

   buscarAlumnoxNombre(datos: busquedaAlumno){
     return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/busquedaAlumno`, datos, { headers:this.global.headers })
   }

   buscarAlumnoxMatricula(datos: busquedaAlumno){
     return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/busquedaAlumnomatricula`, datos)
   }

   buscarAlumnoxNombrePromedio(datos: busquedaAlumno){
     return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/buscar`, datos)
   }

   buscarAlumnoxMatriculaPromedio(datos: busquedaAlumno){
     return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/buscar`, datos)
   }

  consultarDatosKardex(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/kardexalumno`, datos)
  }

  consultarHistorialKardex(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/historia`, datos)
  }

  consultarDatosConstancia(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/constanciaalumno`, datos)
  }

  consultarHistorialConstancia(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/constancia`, datos)
  }

  consultHorario(matricula: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Mailing/SendHorario/consulta/${matricula}`)
  }

  constanciaFooter(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/footer`, datos);
  }

  listarMateriasXAlumno(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inscripcionelistamaterias`, datos)
  }

  eliminarMateriasXAlumno(datos: eliminarMaterias){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/eliminarmaterias`, datos)
  }

  bajaAlumno(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/bajaalumno`, datos)
  }

  bajaAlumnos(baja: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/documentobaja/upload/`, baja)
  }

  consultarDocumentosAlu(matricula: string, matriculap: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentosAlumnos/${matricula}/${matriculap}`, null)
  }

  descargarDocumento(matricula: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, null)
  }

  descargarDocumento2(matricula: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, { responseType: 'arraybuffer' })
  }

  validarDocumento(id: string, estado: any, data){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/validarDocumentosAlumnos/${id}/${estado}`, data)
  }

  consultarDocumentosBaja(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAluBaja/documentosAlumnosBaja/${matricula}`, null)
  }

  imprimirDocumentoBaja(idDocumento: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAluBaja/documentosAlumnosBaja/documento/${idDocumento}`, null)
  }

  validarDocumentoBaja(idDocumento: string, estado: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAluBaja/validarDocumentosAlumnosBaja/${idDocumento}/${estado}`, null)
  }

  documentosPosibles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentos/${matricula}`, null)
  }

  subirDocumentos(formdata){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/documentosPersonal/upload`, formdata)
  }

  documentosSubidos(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/${matricula}`, null)
  }

  documentosPermiso(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/permiso/${matricula}`, null)
  }

  documentosImprimir(id){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/documento/${id}`)
  }

  documentoEliminar(id){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/${id}`)
  }

  consultarKardexLargo(datos: busquedaAlumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/historiaLargo`, datos)
  }

  imprimirKardexLargo(matricula: string, matriculap: string, planestudioid){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/KardexLargo/${matricula}/${matriculap}/${planestudioid}`)
  }
 
  imprimirKardexLargos(matricula: string, matriculap: string, planestudioid){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/KardexLargo/${matricula}/${matriculap}/${planestudioid}`, { responseType: 'arraybuffer' })
  }

  imprimirKardexCorto(matricula: string, matriculap: string,idfirma, planestudioid){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/KardexCorto/${matricula}/${matriculap}/${idfirma}/${planestudioid}`, { responseType: 'arraybuffer' })
  }

  imprimirKardexCortox(matricula: string, matriculap: string,idfirma, planestudioid){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Kardex/${matricula}/${matriculap}/${idfirma}/${planestudioid}`, { responseType: 'arraybuffer' })
  }

  consultarpersonalxmatricula(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/personal/${matricula}`, null)
  }

  consultarpersonalxnombre(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/personal/nombre`, datos)
  }

  consultarDocumentosSubidosPersonal(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/${matricula}`, null)
  }

  imprimirDocumentoSubidosPersonal(id){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/documento/${id}`, null)
  }

  validarDocumentosSubidosPersonal(id, estado){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/validarDocumentosPersonal/${id}/${estado}`, null)
  }

  consultardocumentosvalidados(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsPer/documentosPersonal/validados/${matricula}`, null);
  }

  listaperiodosescolares(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }

  listarsemestres(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/semestre/${matriculap}`, null)
  }

  listarcarrara(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/carrera/${matriculap}`, null)
  }

  maestro(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Maestro/list/${matricula}`,null);
  }

  listaAulas(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/lista/${matricula}`,null);
  }

  agregarcalendarios(idperiodo, idcarrera, data){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/insert`, data, { headers:this.global.headers });
  }

  consultarcalendarios(idperiodo, idcarrera){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/fechasPeriodo/${idperiodo}/${idcarrera}`, null);
  }

  cambiarPass(nuevaP){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Personal/Login/resetPassword`, nuevaP)
  }
  cambiarPassUser(nuevaP){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Personal/Login/resetPassword/user`, nuevaP)
  }

  agregarPeriodoEscolar(datos:periodoEscolar){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/acPeriodo`, datos, { headers:this.global.headers });
  }

  agregarpersona(datos,matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/insert/${matriculap}`, datos, { headers:this.global.headers });
  }

  editarpersona(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/edit`, datos, { headers:this.global.headers });
  }

  consultardiasfestivosPE(idperiodo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/getFestivos/${idperiodo}`, null);
  }

  agregardiasfestivosPE(data){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/insertFestivo`, data, { headers:this.global.headers });
  }

  eliminardiasfestivos(idFestivo,data){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Calendarios/deleteFestivo/${idFestivo}`, data, { headers:this.global.headers });
  }

  consultarroles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/buscaroles/${matricula}`, null);
  }
  consultarrolesNa(matricula: string,matriculap: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/rolesnoactivos/notificaciones/${matricula}/${matriculap}`, null);
  }

  consultarrolesusuario(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/rolesactivos/${matricula}`, null);
  }

  consultarrolesusuarioN(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/rolesactivos/notificaciones/${matricula}`, null);
  }

  activarrolusuario(matricula: string, rol: string, matriculap: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/agregaRol/${matricula}/${rol}/${matriculap}`, null);
  }

  inactivarrolusuario(matricula: string, rol: string, matriculap: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/quitaRol/${matricula}/${rol}/${matriculap}`, null);
  }

  alumno(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/matricula/${matricula}`, null)
  }

  consultarbecas(idperiodo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AlumnosBecas/list/${idperiodo}`, null)
  }

  agregarbeca(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AlumnosBecas/insert`, datos, { headers:this.global.headers })
  }

  editarbeca(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AlumnosBecas/update`, datos, { headers:this.global.headers })
  }

  eliminarbeca(idbeca){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AlumnosBecas/delete/${idbeca}`)
  }

  listarbecas(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/becas/list`, null);
  }

  listarbecasdealumno(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/becas/list/periodo_matricula`, datos);
  }

  consultardatosgeneralesalumno(matricula, matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/datos/${matricula}/${matriculap}`, null)
  }

  editardatosgeneralesalumno(datos){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/editar`, datos, { headers:this.global.headers })
  }

  personal(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/personal/${matricula}`, null)
  }

  consultarconstancia(matricula, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}Famen/Constancia/${matricula}/${matriculap}`, null)
  }

  consultardatoscomplementariosalumnoa(matricula, matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/listaDatos/${matricula}/${matriculap}`, null)
  }

  editardatoscmp(datos){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/editarDatosCmp/`, datos)
  }

  boletaescolares(periodo, matricula, matriculap){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Boleta/${periodo}/${matricula}/${matriculap}`, { responseType: 'arraybuffer' })
  }

  tipocalificacion(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/tipocalificacion`, datos);
  }

  consultardatosacta(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/consultamaestro`, datos)
  }

  reabriractas(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/QuitarFirma`, datos);
  }

  consultarperiodosdealumno(matriculap, matriculaalumno){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/periodosCursados/${matriculap}/${matriculaalumno}`, null);
  }

  consultarplanestudiosvigente(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/planEstudioLicenciatura/${matricula}`, null);
  }

  nuevoingreso(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/SembrarNuevoIngreso/upload`, datos);
  }

  consultarcarreras(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matriculap}`, null)
  }

  consultarsemestres(matriculap, idplanstudio){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Semestres/lista/${matriculap}/${idplanstudio}`, null)
  }

  consultarperiodoescolar(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  maestrosGrpAl(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/grupoMaestro/${matricula}`, null)
  }

  cambiarPassA(nuevaP){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Login/newpassword`, nuevaP)
  }

  agregartiempo(matriculap, matriculaa, fecha){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Permiso/fechaHora/${matriculap}/${matriculaa}/${fecha}`, null)
  }

  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }

  consultarplanestudiosFiltrados(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/lista/${matriculap}`, null);
  }

  agregaralumno(jason){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/insert`, jason, { headers: this.global.headers });
  }

  cambiarsemestre(jason, matriculap) {
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/cambiarSemestre/${matriculap}`, jason);
  }

  cambiarregular(jason,matriculap) {
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/cambiarRegular/${matriculap}`, jason);
  }

  consultarFirmasAuto(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PersonalS/autoriza/firma`, datos);
  }

  consultarfaltas(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/justificantes/buscar/faltas`, datos);
  }
  insertJusticantes(datos: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/justificantes/insert`, datos);
  }

  consultarpermisosactivos(permisos:Permisos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/permisosactivos`, permisos);
  }
  consultarpermisosinactivos(permisos:Permisos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/permisosnoactivos`, permisos);
  }
  
  eliminarpermiso(permiso:QuitarPermiso){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/quitarrelacion`, permiso);
  }

  agregarpermiso(permiso:PonerPermiso){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/relacionar`, permiso);
  }

  agregarsemestredevista(vista:InsertVista){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/insertVista`, vista);
  }
  eliminarsemestredevista(vista:EliminarVista){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/deleteVista`, vista);
  }
  consultarsemestresasignados(vista:SemestresAsignados){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistasactivasxcarrera`, vista);
  }
  consultarsemestresnoasignados(vista:SemestresAsignados){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistasnoactivas`, vista);
  }
  consultarvistasasignables(vista){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistas`, vista);
  }
/*   horarioAlumno(idPeriodo,matricula){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorarioAlumnoPdf/${idPeriodo}/${matricula}`, null);
  } */

  planesEstudioRelacionados(parametros){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/Planes`, parametros);
  }

  añadirNuevoPlanEstudioAlumno(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/altaEnPlanEstudio`, parametros);
  }

  listarCandidatos(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/buscarCandidatos`, dato)
  }

  insertExamenProf(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/insert`, dato)
  }

  editarExamenProf(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/edit`, dato)
  }

  consultaExamenesAlumnos(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/ConsultarExamenAlumnos`, dato)
  }

  consultaDocumentosAlumnosExamen(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/documentosValidos`, dato)
  }

  consultaValidacionAlumnos(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/validacion`, dato)
  }

  subirArchivosGenericos(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Archivos/insert`, dato)
  }

  consultaArchivosGenericos(matriculap, dato){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Archivos/descargar/${matriculap}/${dato}`, { responseType: 'arraybuffer' })
  }
  consultaAActaExamenPro(matriculap, dato){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/ActaExamenProf/${matriculap}/${dato}`, { responseType: 'arraybuffer' })
  }

  consultaInvitacionExamenPro(matriculap, dato){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumnos/AsignacionJurados/${matriculap}/${dato}`, { responseType: 'arraybuffer' })
  }

  consultaArchivosSSU(matriculap,matricula, dato){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/DocumentoSolicitud/${matriculap}/${matricula}/${dato}`, { responseType: 'arraybuffer' })
  }

  consultaArchivosSS(matriculap, dato){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/LiberacionPDF/${matriculap}/${dato}`, { responseType: 'arraybuffer' })
  }

  cancelarExamenPro(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/cancelar`, dato)
  }

  calificarExamenPro(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/examen-profesional/calificar`, dato)
  }

  movimientosAlumno(dato){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/Movimientos/Lista`, dato)
  }

}
