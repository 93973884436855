import { Component, OnInit } from '@angular/core';
import { AdministracionService } from '../services/administracion.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import { parse } from 'path';
import { ExcelService } from 'src/app/administracion/services/excel.service';
var moment = require('moment');
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { saveAs } from 'file-saver';
declare var $ : any;


@Component({
  selector: 'app-inscripciones',
  templateUrl: './inscripciones.component.html',
  styleUrls: ['./inscripciones.component.css']
})
export class InscripcionesComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  }

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };
  periodoescolaranterior;
  periodoescolaractivos = [];
  periodosabiertos = [];
  carrerasactivos = [];
  semestresactivos = [];
  semestresfiltrados = [];
  semestresselecionados = [];
  periodosescolarescompleta = [];
  periodosescolarescompletacerradas = [];
  periodossecolarescompletaabiertas = [];
  tipoalumno;
  periodoidseleccionado;
  planestudiosseleccionado;
  fechainicio;
  fechainicio_ISO;
  fechaI: IMyDateModel = null;
  banderacarrera:boolean = true;
  banderasemestres:boolean = true;
  banderabtn:boolean = true;
  banderatablabloques:boolean = true;

  periodoorigen;
  periododestino;

  validacion = '';
  todo;
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  settings = {
    columns : {
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'9%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'30%'
      },/*
      carrera:{
        title:'Carrera',
        editable:false,
        width:'10%'
      },*/
      semestre:{
        title:'Semestre',
        editable:false,
        width:'5%'
      },
      regular:{
        title:'Regular',
        editable:false,
        width:'5%'
      },
      bloque:{
        title:'Bloque',
        editable:false,
        width:'8%'
      },
      promedio:{
        title:'Promedio',
        editable:false,
        width:'10%'
      },
      reprobadas:{
        title:'Exám. N/A',
        editable:false,
        width:'10%'
      },
      promediosemestre:{
        title:'Promedio Semestre',
        editable:false,
        width:'10%'
      },
      fechahora:{
        title:'Fecha',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns : {
      nombremateria:{
        title:'Materia',
        editable:false,
        width:'40%'
      },
      nombreGrupo:{
        title:'Grupo',
        editable:false,
        width:'10%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      },
      nombremaestro:{
        title:'Maestro',
        editable:false,
        width:'40%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'0 grupos sin firmas.'
  }

  settings3 = {
    columns : {
      periodo:{
        title:'Período',
        editable:false,
        width:'20%'
      },
      planestudio:{
        title:'Plan de Estudios',
        editable:false,
        width:'15%'
      },
      nombremateria:{
        title:'Materia',
        editable:false,
        width:'20%'
      },
      nombregrupo:{
        title:'Grupo',
        editable:false,
        width:'5%'
      },
      nombremaestro:{
        title:'Maestro',
        editable:false,
        width:'20%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      },
      maximo:{
        title:'Máximo Regulares',
        editable:false,
        width:'5%'
      },
      maximoi:{
        title:'Máximo Irregulares',
        editable:false,
        width:'5%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin resultados.'
  }

  exportaraexcel(){
    var filtrado = [];
    for(var i = 0; i < this.grupocuposfiltrados.length; i++){
      var jason = [
        this.grupocuposfiltrados[i].periodo,
        this.grupocuposfiltrados[i].planestudio,
        this.grupocuposfiltrados[i].nombregrupo,
        this.grupocuposfiltrados[i].nombremateria,
        this.grupocuposfiltrados[i].nombremaestro,
        this.grupocuposfiltrados[i].matricula,
        this.grupocuposfiltrados[i].maximo,
        this.grupocuposfiltrados[i].maximoi
      ]
      filtrado.push(jason);
    }
    //console.log(filtrado);
    setTimeout(() => {
      this.excelService.exportAsExcelFileGrupos(filtrado);
    }, 500);
  }

  data =  []
  datafiltrada = [];
  nombreperiodo = '';
  nombreplanestudios = '';
  avisos = '';
  grupossinfirmas = [];
  grupos = [];
  dataE = [];
  simular2 = '';
  banderapasos:boolean = true;
  banderapaso2:boolean = true;
  banderapaso3:boolean = true;
  banderapaso4:boolean = true;
  banderapaso6:boolean = true;
  banderapaso5:  boolean = true;
  banderacargando:boolean = true;
  banderapdf:boolean = true;
  banderatabla:boolean = true;
  banderatablagrupos:boolean = true;
  PESelecionado;
  gruposcupos = [];
  grupocuposfiltrados = [];
  src;
  type = FileMimeType.PDF;
  banderapdf2:boolean = false;

  constructor(private administracionService : AdministracionService, private excelService: ExcelService) {

  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
    this.consultarlistaperiodosescolarescompleta();
  }

  consultarhorarios(){
    this.src = '';
    this.banderapdf2 = true;
    $('#cargando').modal('show');
    this.administracionService.consultarhorarios(this.periodoidseleccionado, this.planestudiosseleccionado, this.semestresselecionados[0]).subscribe({
      next:( data : ArrayBuffer ) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
        setTimeout(() => {
          $('#cargando').modal('hide');
        }, 500);
      },
      complete:() => {
        $('#cargando').modal('hide');
        setTimeout(() => {
          $('#documento').modal('show');
          this.banderapdf2 = false;
        },1000);
      }
    });
  }

  imprimirdoc2(){
    var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
    saveAs(blob, `Documento`);
  }

  consultarpdfbloques(){
    this.banderapdf2 = true;
    $('#cargando').modal('show');
    this.administracionService.consultarpdf(this.periodoidseleccionado, this.planestudiosseleccionado, this.semestresselecionados[0]).subscribe({
      next:( data : ArrayBuffer ) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
        setTimeout(() => {
          $('#cargando').modal('hide');
        }, 500);
      },
      complete:() => {
        $('#cargando').modal('hide');
        setTimeout(() => {
          $('#documento').modal('show');
          this.banderapdf2 = false;
        },1000);
      }
    });
  }

  salir2(){
    $('#gruposcupos').modal('hide');
  }

  consultargruposcupos(){
    this.banderatablagrupos = true;
    this.gruposcupos = [];
    this.grupocuposfiltrados = [];
    this.administracionService.listaGrupos(this.maestro.matricula, this.periodoidseleccionado, this.planestudiosseleccionado).subscribe({
      next:(data : any) => {
        this.gruposcupos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.gruposcupos.length; i++){
          if((this.planestudiosseleccionado == this.gruposcupos[i].idplanestudio) && (this.semestresselecionados[0] == this.gruposcupos[i].semestre)){
            var jason = {
              carrera:this.gruposcupos[i].carrera,
              clavemateria:this.gruposcupos[i].clavemateria,
              email:this.gruposcupos[i].email,
              grupo:this.gruposcupos[i].grupo,
              idcarrera:this.gruposcupos[i].idcarrera,
              idgrupo:this.gruposcupos[i].idgrupo,
              idmaestro:this.gruposcupos[i].idmaestro,
              idmateria:this.gruposcupos[i].idmateria,
              idmpe:this.gruposcupos[i].idmpe,
              idperiodo:this.gruposcupos[i].idperiodo,
              idpersona:this.gruposcupos[i].idpersona,
              idplanestudio:this.gruposcupos[i].idplanestudio,
              matricula:this.gruposcupos[i].matricula,
              matriculamaestro:this.gruposcupos[i].matriculamaestro,
              maximo:this.gruposcupos[i].maximo,
              maximoi:this.gruposcupos[i].maximoi,
              nombregrupo:this.gruposcupos[i].nombreGrupo,
              nombremaestro:this.gruposcupos[i].nombremaestro,
              nombremateria:this.gruposcupos[i].nombremateria,
              periodo:this.gruposcupos[i].periodo,
              planestudio:this.gruposcupos[i].plandeestudio,
              semestre:this.gruposcupos[i].semestre
            }
            this.grupocuposfiltrados.push(jason);
          }else{
            if((this.planestudiosseleccionado == this.gruposcupos[i].idplanestudio) && (this.semestresselecionados[0] == '0')){
              var jason = {
                carrera:this.gruposcupos[i].carrera,
                clavemateria:this.gruposcupos[i].clavemateria,
                email:this.gruposcupos[i].email,
                grupo:this.gruposcupos[i].grupo,
                idcarrera:this.gruposcupos[i].idcarrera,
                idgrupo:this.gruposcupos[i].idgrupo,
                idmaestro:this.gruposcupos[i].idmaestro,
                idmateria:this.gruposcupos[i].idmateria,
                idmpe:this.gruposcupos[i].idmpe,
                idperiodo:this.gruposcupos[i].idperiodo,
                idpersona:this.gruposcupos[i].idpersona,
                idplanestudio:this.gruposcupos[i].idplanestudio,
                matricula:this.gruposcupos[i].matricula,
                matriculamaestro:this.gruposcupos[i].matriculamaestro,
                maximo:this.gruposcupos[i].maximo,
                maximoi:this.gruposcupos[i].maximoi,
                nombregrupo:this.gruposcupos[i].nombreGrupo,
                nombremaestro:this.gruposcupos[i].nombremaestro,
                nombremateria:this.gruposcupos[i].nombremateria,
                periodo:this.gruposcupos[i].periodo,
                planestudio:this.gruposcupos[i].plandeestudio,
                semestre:this.gruposcupos[i].semestre
              }
              this.grupocuposfiltrados.push(jason);
            }
          }
        }
        $('#gruposcupos').modal('show');
        this.banderatablagrupos=false;
      }
    });
  }


  selecionarplan(){
    var plan = <HTMLSelectElement>document.getElementById('plan');
    this.PESelecionado = plan.value;
  }

  calcularcupos(){
    if(this.PESelecionado == ""){

    }else{
      $('#cargando').modal('show');
      this.administracionService.calcularcupos(this.periodoidseleccionado, this.planestudiosseleccionado, this.semestresselecionados[0]).subscribe({
        next:(res : any) => {
          this.respuesta = res;
        },
        error:(err) => {
          console.log(err);
          $('#cargando').modal('hide');
        },
        complete:() => {
          if(this.respuesta.codigo == "OK"){
            $('#cargando').modal('hide');
            this.consultargruposcupos();
          }else{
            $('#cargando').modal('hide');
            setTimeout(() => {
             $('#respuesta').modal('show');
            },500)
          }
        }
      })
    }
  }

  gruposinfirmar(){
    this.grupos = [];
    this.banderacargando = true;
    this.administracionService.revisaractassinfirmar(this.maestro.matricula, this.periodoescolaranterior.id).subscribe({
      next:(data : any) => {
        this.grupossinfirmas = data;
      },
      error:(err) => {
        console.log(err);
      },
       complete:() => {
        this.banderacargando = false;
        for(var i = 0; i < this.grupossinfirmas.length; i++ ){
          if(this.semestresselecionados[0] == 0){

          }else{
            if(this.grupossinfirmas[i].semestre == this.semestresselecionados[0]){
              this.grupos.push(this.grupossinfirmas[i]);
            }
          }
        }
        this.banderapasos = false;
       }
    });
  }

  salir(){
    $('#pdf').modal('hide');
    this.banderapdf = true;
  }

  verpdf(){
    $('#pdf').modal('show');
    this.banderapdf = false;
  }

  exportarAsXLSX():void{
    var bloquesexcel = [];
    for(var i = 0; i < this.datafiltrada .length; i++){
      var jason = [
        this.datafiltrada[i].matricula,
        this.datafiltrada[i].nombre,
        this.datafiltrada[i].planestudio,
        this.datafiltrada[i].carrera,
        this.datafiltrada[i].semestre,
        this.datafiltrada[i].regular,
        this.datafiltrada[i].promedio,
        this.datafiltrada[i].reprobadas,
        this.datafiltrada[i].bloque,
        this.datafiltrada[i].fechahora
      ]
      bloquesexcel.push(jason);
    }
    this.excelService.exportAsExcelFileAlumnos(bloquesexcel);
  }

  clonargrupos(){
    this.administracionService.duplicargrupos(this.periodoorigen, this.periododestino, this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  selecionarperiodoescolarcompletaorigen(){
    var origen = <HTMLSelectElement>document.getElementById('periodoescolarcompletaorigen');
    this.periodoorigen = origen.value;
  }

  selecionarperiodoescolarcompletadestino(){
    var destino = <HTMLSelectElement>document.getElementById('periodoescolarcompletadestino');
    this.periododestino = destino.value;
  }

  consultarlistaperiodosescolarescompleta(){
    this.administracionService.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolarescompleta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodosescolarescompleta.length; i++){
          if(this.periodosescolarescompleta[i].estado){
            this.periodosescolarescompletacerradas.push(this.periodosescolarescompleta[i]);
          }
        }
        for(var x = 0; x < this.periodosescolarescompleta.length; x++){
          if((this.periodosescolarescompleta[x].estado == "ABIERTO") || (this.periodosescolarescompleta[x].estado == "ACTUAL")){
            this.periodossecolarescompletaabiertas.push(this.periodosescolarescompleta[x]);
          }
        }
        this.periodosescolarescompletacerradas.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    });
  }

  consultarperiodoescolaranterior(){
    this.administracionService.consultarperiodoescolaranterior(this.periodoidseleccionado).subscribe({
      next:(data : any) => {
        this.periodoescolaranterior = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  guardardatos(){
    var alumnosbloque = <HTMLSelectElement>document.getElementById('abloque');
    var duracionbloque = <HTMLSelectElement>document.getElementById('dbloque');
    var hinicio = <HTMLSelectElement>document.getElementById('horai');
    var horasdia = <HTMLSelectElement>document.getElementById('horasd');
    var separacion = <HTMLSelectElement>document.getElementById('sbloque');
    var minutosporalumno = <HTMLSelectElement>document.getElementById('minutosporalumno');
  }


  programarinscripcion(){
    this.banderatablabloques = true;
    this.data = [];
    this.datafiltrada = [];
    var alumnosbloque = <HTMLSelectElement>document.getElementById('abloque');
    var hinicio = <HTMLSelectElement>document.getElementById('horai');
    var horasdia = <HTMLSelectElement>document.getElementById('horasd');
    var separacion = <HTMLSelectElement>document.getElementById('sbloque');
    var minutosporalumno = <HTMLSelectElement>document.getElementById('minutosporalumno');
    var minutosentrealumno = <HTMLSelectElement>document.getElementById('minutosentrealumno');

    if(this.simular2 == "1" || this.simular2 == "0"){
      if( (this.todo != 0 && this.semestresselecionados.length == 0) || (alumnosbloque.value == '') || (this.fechainicio == '') || (hinicio.value == '') || (horasdia.value == '') || (separacion.value == '') || (minutosporalumno.value == '')){
        this.validacion = "Uno o mas datos estan vacíos o son inválidos."
        setTimeout(() => {
          $('#validacion').modal('show');
        }, 200);
      }else{
        if(this.semestresselecionados.length == 0){
          var jason = {
            id:0,
            periodoid:parseInt(this.periodoidseleccionado),
            planestudioid:parseInt(this.planestudiosseleccionado),
            fechainicio:this.fechainicio,
            horainicio:hinicio.value,
            horasdia:parseInt(horasdia.value),
            minutosinscripcion:parseInt(minutosporalumno.value),
            separacionbloque:parseInt(separacion.value),
            minutosentrealumno:0,
            alumnosporbloque:parseInt(alumnosbloque.value),
            regular:this.tipoalumno,
            semestre:parseInt(this.todo),
            matriculap:this.maestro.matricula,
            simular:parseInt(this.simular2)
          }
          $('#cargando').modal('show');
          this.administracionService.insertarprogramaciones(jason).subscribe({
            next:(data : any) => {
              this.data = data;
            },
            error:(err) => {
              console.log(err);
              $('#cargando').modal('hide');
            },
            complete:() => {
              if(this.data.length == 0){
                $('#cargando').modal('hide');
                setTimeout(() => {
                  $('#respuestabloques').modal('show');
                }, 1000);
              }
              for(var i = 0; i < this.data.length; i++){
                var jason = {
                  alumnoid:this.data[i].alumnoid,
                  bloque:this.data[i].bloque,
                  carrera:this.data[i].carrera,
                  carreraid:this.data[i].carreraid,
                  fechahora:this.data[i].fechahora,
                  horasdia:this.data[i].horasdia,
                  nombre:this.data[i].nombres+" "+this.data[i].paterno+" "+this.data[i].materno,
                  matricula:this.data[i].matricula,
                  planestudio:this.data[i].planestudio,
                  planestudioid:this.data[i].planestudioid,
                  promedio:this.data[i].promedio,
                  promediosemestre:this.data[i].promediosemestre,
                  regular:this.data[i].regular,
                  reprobadas:this.data[i].reprobadas,
                  semestre:this.data[i].semestre
                }
                this.datafiltrada.push(jason);
                if(this.datafiltrada.length == this.data.length){
                  this.banderatablabloques = false;
                }
              }
              if(this.data.length > 0){
                $('#cargando').modal('hide');
                setTimeout(() => {
                  $('[href="#pills-listado"]').tab('show');
                }, 200)
              }
            }
          });
        }else{
          if(this.semestresselecionados.length == 1){
            var jason2 = {
              id:0,
              periodoid:parseInt(this.periodoidseleccionado),
              planestudioid:parseInt(this.planestudiosseleccionado),
              fechainicio:this.fechainicio,
              horainicio:hinicio.value,
              horasdia:parseInt(horasdia.value),
              minutosinscripcion:parseInt(minutosporalumno.value),
              separacionbloque:parseInt(separacion.value),
              minutosentrealumno:0,
              alumnosporbloque:parseInt(alumnosbloque.value),
              regular:(this.tipoalumno),
              semestre:parseInt(this.semestresselecionados[0]),
              matriculap:this.maestro.matricula,
              simular:parseInt(this.simular2)
            }
            $('#cargando').modal('show');
            this.administracionService.insertarprogramaciones(jason2).subscribe({
              next:(data : any) => {
                this.data = data;
              },
              error:(err) => {
                console.log(err);
                $('#cargando').modal('hide');
              },
              complete:() => {
                if(this.data.length == 0){
                  $('#cargando').modal('hide');
                  setTimeout(() => {
                    $('#respuestabloques').modal('show');
                  }, 1000);
                }
                for(var i = 0; i < this.data.length; i++){
                  var jason = {
                    alumnoid:this.data[i].alumnoid,
                    bloque:this.data[i].bloque,
                    carrera:this.data[i].carrera,
                    carreraid:this.data[i].carreraid,
                    fechahora:this.data[i].fechahora,
                    horasdia:this.data[i].horasdia,
                    nombre:this.data[i].paterno+" "+this.data[i].materno+" "+this.data[i].nombres,
                    matricula:this.data[i].matricula,
                    planestudio:this.data[i].planestudio,
                    planestudioid:this.data[i].planestudioid,
                    promedio:this.data[i].promedio,
                    promediosemestre:this.data[i].promediosemestre,
                    regular:this.data[i].regular,
                    reprobadas:this.data[i].reprobadas,
                    semestre:this.data[i].semestre
                  }
                  this.datafiltrada.push(jason);
                  if(this.datafiltrada.length == this.data.length){
                    this.banderatablabloques = false;
                  }
                }
                if(this.data.length > 0){
                  $('#cargando').modal('hide');
                  setTimeout(() => {
                    $('[href="#pills-listado"]').tab('show');
                  }, 200)
                }
              }
            });
          }
        }
      }
    }else{
      this.validacion = "Rellene el campo de simular."
      setTimeout(() => {
        $('#validacion').modal('show');
      }, 200);
    }
  }

  tipoalumnos(event){
    this.tipoalumno = event.target.defaultValue;
  }

  simulacion(event){
    this.simular2 = event.target.defaultValue;
    //console.log(this.simular2);
  }

  fechainicioI(event){
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    this.periodoidseleccionado = periodoid.value;
    for(var i = 0; i < this.periodosabiertos.length; i++){
      if(this.periodoidseleccionado == this.periodosabiertos[i].id){
        this.nombreperiodo = this.periodosabiertos[i].nombre;
      }
    }
    this.banderacarrera = false;
    this.consultarperiodoescolaranterior();
  }

  seleccionarcarrera(){
    this.semestresfiltrados = [];
    var idplanstudios = <HTMLSelectElement>document.getElementById('carrera');
    this.planestudiosseleccionado = idplanstudios.value;
    //console.log(this.carrerasactivos);
    for(var i = 0; i < this.carrerasactivos.length; i++){
      if(this.planestudiosseleccionado == this.carrerasactivos[i].idplanestudio){
        this.nombreplanestudios = this.carrerasactivos[i].plandeestudio;
      }
    }
    this.consultarsemestresactivos();
  }

  agregarsemestre(semestre, event){
    if(event.target.checked){
      this.semestresselecionados.push(semestre);
    }else{
      for(var x = 0; x < this.semestresselecionados.length; x++){
        if(semestre == this.semestresselecionados[x]){
          this.semestresselecionados.splice(x, 1);
        }
      }
    }
    if(this.semestresselecionados.length == 1){
      for(var i = 0; i < this.semestresactivos.length; i++){
        var check = <HTMLSelectElement>document.getElementById(`semestres`+this.semestresactivos[i].semestre);
        if(semestre != this.semestresactivos[i].semestre){
          check.disabled = true;
        }
      }
      var todo = <HTMLSelectElement>document.getElementById('todo');
      todo.disabled = true;
    }else{
      if(this.semestresselecionados.length == 0){
      for(var i = 0; i < this.semestresactivos.length; i++){
        var check = <HTMLSelectElement>document.getElementById(`semestres`+this.semestresactivos[i].semestre);
          check.disabled = false;
        }
      var todo = <HTMLSelectElement>document.getElementById('todo');
      todo.disabled = false;
      }
    }
    this.gruposinfirmar();
  }

  selecionartodo(event){
    if(event.target.checked == true){
      this.todo = event.target.defaultValue;
      for(var i = 0; i < this.semestresactivos.length; i++){
        var check = <HTMLSelectElement>document.getElementById(`semestres`+this.semestresactivos[i].semestre);
          check.disabled = true;
      }
    }else{
      if(event.target.checked == false){
        for(var i = 0; i < this.semestresactivos.length; i++){
          var check = <HTMLSelectElement>document.getElementById(`semestres`+this.semestresactivos[i].semestre);
            check.disabled = false;
        }
      }
    }
  }

  consultarperiodoescolares(){
    this.administracionService.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodoescolaractivos = data;
        //console.log(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodoescolaractivos.length; i++){
          if(this.periodoescolaractivos[i].estado == "ABIERTO" || "ACTUAL"){
            this.periodosabiertos.push(this.periodoescolaractivos[i]);
          }
        }
        this.consultarcarrerasactivos();
      }
    });
  }

  consultarcarrerasactivos(){
    this.administracionService.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carrerasactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for( var i = 0; i < this.carrerasactivos.length; i++ ){
          if(this.carrerasactivos[i].estatus == "S"){
            this.dataE.push(this.carrerasactivos[i]);
          }
        }
      }
    });
  }

  consultarsemestresactivos(){
    this.administracionService.consultarsemestres(this.maestro.matricula, this.planestudiosseleccionado).subscribe({
      next:(data : any) => {
        this.semestresactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semestresactivos.sort(function (a, b) {
          if (a.semestre > b.semestre) {
            return 1;
          }
          if (a.semestre < b.semestre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.banderasemestres = false;

      }
    });
  }

  listadoBloques=[];

  listarBloques(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    let jason={periodoid:0,matriculap:"",planestudioid:0,semestre:0};
    jason.periodoid=parseInt(this.periodoidseleccionado);
    jason.matriculap=this.maestro.matricula;
    jason.planestudioid=parseInt(this.periodoidseleccionado);
    jason.semestre=(this.semestresselecionados[0]==undefined)?0:parseInt(this.semestresselecionados[0]);

    //console.log(jason);

    this.administracionService.consultarBloques(jason).subscribe({
      next:(data : any) => {
        this.listadoBloques = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        alert("Registros encontrados: "+this.listadoBloques.length);
      }
    });
  }

  listaBloques = {
    columns : {
      semestre:{
        title:'Semestre',
        editable:false,
        width:'3%'
      },
      regular:{
        title:'Regular',
        editable:false,
        width:'3%'
      },
      bloque:{
        title:'Bloque',
        editable:false,
        width:'3%'
      },
      estado:{
        title:'Estado',
        editable:false,
        width:'8%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'8%'
      },
      alumno:{
        title:'Alumno',
        editable:false,
        width:'25%'
      },
      promedio:{
        title:'Promedio',
        editable:false,
        width:'10%'
      },
      promediosemestre:{
        title:'Semestre',
        editable:false,
        width:'10%'
      },
      inscrito:{
        title:'Inscrito',
        editable:false,
        width:'5%'
      },
      hora:{
        title:'Hora',
        editable:false,
        width:'15%'
      },
      cuotas:{
        title:'Cuotas',
        editable:false,
        width:'15%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:20
    },
    noDataMessage:'Sin resultados.'
  }
}
