<div *ngIf="!banderaSubirArchivo">
    <div>
        <input enctype="multipart/form-data" accept="application/pdf" #inputFile type="file" name="documento" (change)="onFileSelect($event)">
    </div>
    <div class="mt-3">
        <button class="btn btn-danger btn-lg btn-block" (click)="subirArchivo()">Subir Archivo</button>
    </div>
</div>
<div *ngIf="!banderaArchivo">
    <table class="table">
        
        <thead class="bg-danger text-white">

            <tr>
                <th>Archivo</th>
                <th></th>
            </tr>

            <!-- <th>
                <td>Archivo</td>
                <td></td>
            </th> -->

        </thead>
        <tbody>
            
            <tr *ngFor="let a of archivos">
                <td>{{ a.nombreArchivo }}</td>
                <td>
                    <button (click)="verArchivo(a.clave, a.nombreArchivo)" class="btn btn-danger">Consultar</button>
                </td>
            </tr>

        </tbody>
    
    </table>
</div>

<div class="modal fade" id="staticBackdropResponse" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
        <button type="button" (click)="respuestamodalCerrar()" class="close"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            {{ respuesta.mensaje }}
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="respuestamodalCerrar()">Aceptar</button>
        </div>
    </div>
    </div>
</div>

<div class="modal fade" id="docI" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{ nombreA }}</h5>
        <button type="button" class="close" (click)="cerrarDoc()"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderaDoc">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="guardarArchivo()" >Imprimir</button>
            <button type="button" class="btn btn-secondary" (click)="cerrarDoc()" >Cerrar</button>
        </div>
    </div>
    </div>
</div>