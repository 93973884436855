import { Component, OnInit, Input } from '@angular/core';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';

@Component({
  selector: 'app-visualizar-lista-grupo-pdf',
  templateUrl: './visualizar-lista-grupo-pdf.component.html',
  styleUrls: ['./visualizar-lista-grupo-pdf.component.css']
})
export class VisualizarListaGrupoPdfComponent implements OnInit {

  src = '';
  type = FileMimeType.PDF;
  @Input() id: string;
  @Input() matriculap: string;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  constructor(private global: Global) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.src = `${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/listaMaestro/${this.id}/${this.maestro.matricula}`;
  }

}
