<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
  <body>
      <div class="container-fluid">
              <div class="col-lg-12">
                      <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="roles" data-toggle="tab" href="#Roles" role="tab" aria-controls="Roles" aria-selected="true">Roles</a>
                        </li>
                        <li class="nav-item">
                          <a *ngIf="!banderaCrear" class="nav-link" id="roles" href="#Roles" (click)="abrircrearrol()" aria-controls="CrearGrupo" aria-selected="false"  data-target="#modalCreateGroupForm" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Crear</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="roles" role="tabpanel" aria-labelledby="roles">
                          <ng2-smart-table class="table table-hover table-borderless" (custom)="onCustomAction($event)" (userRowSelect)="abrirrol($event)" [settings]="settings" [source]="roles"></ng2-smart-table>
                        </div>
              </div>
          </div>
      </div>
</body>

    <div class="modal fade" id="rolselecionado" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content" *ngIf="!banderarolselecionado">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ rolselecionado.nombre | titlecase }}</h5>
              <button type="button" class="close" (click)="cerrarrol()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="permisos-tab" data-toggle="tab" href="#permisos" role="tab" aria-controls="permisos" aria-selected="true">Permisos</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="semestres-tab" data-toggle="tab" href="#semestres" role="tab" aria-controls="semestres" aria-selected="false">Semestres</a>
                    </li>
                </ul>

                  <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="permisos" role="tabpanel" aria-labelledby="permisos-tab">
                        <div class="row" id="top"></div>
                        <div class="row">
                            <div class="col-lg-12 permisos">
                                <div class="text-center"> 
                                    <h6><strong>Permisos Activos</strong></h6>
                                </div>
                                <ng2-smart-table class="table table-sm table-striped table-hover table-borderless" (custom)="eliminarpermiso($event)" [settings]="settingspermisosactivos" [source]="permisosactivos"></ng2-smart-table>
                            </div>
                        </div>
                        <div class="row" id="top"></div>
                        <div class="row">
                            <div class="col-lg-12 permisos">
                                <div class="text-center">
                                    <h6><strong>Permisos Inactivos</strong></h6>
                                </div>
                                <ng2-smart-table class="table table-sm table-striped table-hover table-borderless" (custom)="agregarpermiso($event)" [settings]="settingspermisosinactivos" [source]="permisosinactivos"></ng2-smart-table>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="semestres" role="tabpanel" aria-labelledby="semestres-tab">
                        <div class="row" id="top"></div>
                        <div class="row" id="top">
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <select class="form-control" id="vistacarreras" (change)="generarcheckbox()">
                                      <option>--Seleccionar Carrera--</option>
                                      <option *ngFor="let carrera of vistasasignables" [value]="carrera.carrera_id">{{ carrera.carrera }}</option>
                                    </select>
                                  </div>
                            </div>
                        </div>
                        <div class="row" id="top" *ngIf="!banderacarreraselecionado">
                            <div class="col-lg-12">
                                <h4>Semestres habilitados para {{ carreraselescionado.carrera | titlecase }}</h4>
                            </div>
                        </div>
                        <div class="row" id="top" *ngIf="!banderacarreraselecionado">
                            <div class="col-lg-12">
                                <div class="form-check form-check-inline" *ngFor="let semestre of semestresfiltrados; let i = index">
                                    <input class="form-check-input" (change)="agregarsemestre(semestre,$event)" type="checkbox" id="inlineCheckbox{{i}}" [checked]="semestre.rol != '' " [value]="semestre.semestre">
                                    <label class="form-check-label" for="inlineCheckbox{{i}}">{{semestre.semestre}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" id="top" *ngIf="!banderacarreraselecionado">
                            <div class="col-lg-6">
                                <button class="btn btn-danger float-right" (click)="this.guardarmodificaciones()">Guardar</button>
                            </div>
                        </div>
                    </div>
                  </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarrol()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarrespuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger"(click)="cerrarrespuesta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="crearrol" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Crear Rol</h5>
              <button type="button" class="close" (click)="cerrarcrearrol()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="crearrolform">
                    <div class="form-group">
                        <input type="text" [formControl]="this.crearrolform.get('nombre')" class="form-control" id="nombre" placeholder="Nombre">
                      </div>
                      <div class="form-group">
                        <input type="text" [formControl]="this.crearrolform.get('descripcion')" class="form-control" id="descripcion" placeholder="Descripción">
                      </div>
                      <button class="btn btn-danger float-right" [disabled]="crearrolform.invalid" (click)="crearrol()">Guardar</button>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarcrearrol()">Salir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="editarrol" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" *ngIf="!banderaeditar">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Editar {{ editarroldatos.nombre }}</h5>
              <button type="button" class="close" (click)="cerrareditarrol()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="editarrolform">
                    <div class="form-group">
                        <input type="text" [formControl]="this.editarrolform.get('nombre')" class="form-control" id="nombre" placeholder="Nombre">
                      </div>
                      <div class="form-group">
                        <input type="text" [formControl]="this.editarrolform.get('descripcion')" class="form-control" id="descripcion" placeholder="Descripción">
                      </div>
                      <button class="btn btn-danger float-right" [disabled]="editarrolform.invalid" (click)="editarrol()">Guardar</button>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrareditarrol()">Salir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="eliminarrrol" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" *ngIf="!banderaeliminar">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Eliminar {{ idrolaeliminar.nombre }}</h5>
              <button type="button" class="close" (click)="cerrareliminarrol()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <p>¿Seguro que deseas eliminar de forma permanente <strong>{{ idrolaeliminar.nombre }}</strong>? Este cambio es irreversible.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="eliminarrol()">Aceptar</button>
              <button type="button" class="btn btn-secondary" (click)="cerrareliminarrol()">Salir</button>
            </div>
          </div>
        </div>
      </div>

      
</html>
