import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ServicioUniversitarioService } from '../services/servicio-universitario.service';
import {LocalDataSource} from 'ng2-smart-table';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { saveAs } from 'file-saver';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
declare var $ : any;
var moment = require('moment');


@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  settings = {
    columns : {
      matricula:{
        title:'Matricula',
        editable:false,
        width:'10%'
      },
      alumno:{
        title:'Alumno',
        editable:false,
        width:'20%'
      },
      periodo:{
        title:'Periódo',
        editable:false,
        width:'10%'
      },
      carrera:{
        title:'Carrera',
        editable:false,
        width:'15%'
      },
      fechasolicitud:{
        title:'Fecha de solicitud',
        editable:false,
        width:'10%'
      },
      fechaautorizado:{
        title:'Fecha de Autorizado',
        editable:false,
        width:'10%'
      },
      actividad:{
        title:'Actividad',
        editable:false,
        width:'10%'
      },
      horas:{
        title:'Horas',
        editable:false,
        width:'5%'
      },
      estatus:{
        title:'Estatus',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns : {
      matricula:{
        title:'Matricula',
        editable:false,
        width:'10%'
      },
      alumno:{
        title:'Alumno',
        editable:false,
        width:'25%'
      },
      carrera:{
        title:'Carrera',
        editable:false,
        width:'20%'
      },
      reciente:{
        title:'Solicitud Mas Reciente',
        editable:false,
        width:'10%'
      },
      horasPorValidar:{
        title:'Horas Por Validar',
        editable:false,
        width:'5%'
      },
      horasPendientes:{
        title:'Horas Pendientes',
        editable:false,
        width:'5%'
      },
      horasAcumuladas:{
        title:'Horas Acumuladas',
        editable:false,
        width:'5%'
      },
      horasServicio:{
        title:'Horas Servicio',
        editable:false,
        width:'5%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
     custom:[
       { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-eye" ></i></a>'},
      //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
     ],         
     width:'15%',
     position: 'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  alumnos = [
    { id:1, apellidom:'Gonzalez', apellidop:'Gonzalez', matricula:'15041215', nombres:'Miguel Angel', semestre:'6', carrera:'Nutrición', avance:160, total:400 }
  ]

  buscarmatriculaform = new FormGroup({
    matricula: new FormControl('', [Validators.required])
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  periodosescolares = [];
  carreras = [];
  periodoselecionado;
  carreraselecionada;
  banderacarrera:boolean = true;
  banderatabla:boolean = true;
  banderamodal:boolean = true;
  banderamatricula:boolean = true;
  banderacargando:boolean = true;
  itemSource: LocalDataSource;
  itemSource2: LocalDataSource;
  estatus = ["REVISIÓN", "ACEPTADA","RECHAZADA", "ACUMULADA"];
  listaSSU = [];
  nombrealumno = '';
  matriculalaumno = '';
  estatusalumno = '';
  alumnoplanestudioid;
  idregistro;
  src;
  src2;
  type = FileMimeType.PDF;
  fecha: IMyDateModel = null;
  fechaliberacion;
  fechaliberacion_ISO;
  banderapdf:boolean = true;
  banderapdf2:boolean = true;
  banderaestatus:boolean = true;
  banderasindatos:boolean = true;
  horasavance = [];
  alumnoavance = [];
  horaspendientes = 0;
  constructor(private serviciouniversitario: ServicioUniversitarioService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodosescolares();
    this.consultaravances();
    this.listarSSU();
  }

  liberarserviciosocial(){
    var jason = {
      alumnoplanestudioid: this.alumnoplanestudioid,
      matriculap: this.maestro.matricula,
      fechaLiberacion: this.fechaliberacion_ISO
    };
    this.serviciouniversitario.liberarservicio(jason).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  avancealumno(event){
    console.log(event);
    this.nombrealumno = `${event.data.alumno}`
    this.alumnoplanestudioid = event.data.alupleId;
    this.matriculalaumno = event.data.matricula;
    this.estatusalumno = event.data.estatus;
    this.idregistro = event.data.id;
    $('#avance').modal('show');
  }

  seleccionaralumnoavance(data){
    this.alumnoavance = [];
    this.horaspendientes = data.data.horasPendientes;
    for(var i = 0; i < this.listaSSU.length; i++){
      if(this.listaSSU[i].alumnoplanestudioid == data.data.alupleId){
        this.alumnoavance.push(this.listaSSU[i]);
      }
    }
    this.itemSource = new LocalDataSource(this.alumnoavance);
    setTimeout(() => {
      $('[href="#detalle-profile"]').tab('show');
    }, 200)
  }

  consultaravances(){
    var jason = {
      matriculap:this.maestro.matricula,
      alumnoplanestudioid:0
    }
    this.serviciouniversitario.consultarhorasdealumnos(jason).subscribe({
      next:(data:any) => {
        this.horasavance = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.itemSource2 = new LocalDataSource(this.horasavance);
      }
    })
  }

  liberarssu(){
    $('#documento').modal('show');
    this.banderapdf = true;
    this.banderasindatos = true;
    this.serviciouniversitario.generacionpdf(this.maestro.matricula, this.alumnoplanestudioid).subscribe({
      next:(data : ArrayBuffer)=>{
        this.src = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:()=>{
        this.banderapdf = false;
        if(this.src == null){
          this.banderasindatos = false;
        }
      }
    });
    // var jason = {
    //   matricula:this.matriculalaumno,
    //   matriculap:this.maestro.matricula,
    //   fechaliberacion:this.fechaliberacion
    // }
    // this.serviciouniversitario.liberarssu(jason).subscribe({
    //   next:(data : ArrayBuffer)=>{
    //     this.src = new Uint8Array(data);
    //   },
    //   error:(err) => {
    //     console.log(err);
    //   },
    //   complete:()=>{
    //     this.banderapdf = false;
    //   }
    // });
  }

  // generardocumento(){
  //   $('#documentodeliberacion').modal('show');
  //   this.banderapdf2 = true;
  //   this.serviciouniversitario.generacionpdf(this.maestro.matricula, this.matriculalaumno).subscribe({
  //     next:(data : ArrayBuffer)=>{
  //       this.src2 = new Uint8Array(data);
  //     },
  //     error:(err) => {
  //       console.log(err);
  //     },
  //     complete:()=>{
  //       this.banderapdf2 = false;
  //     }
  //   });
  // }

  fechas(event){
    this.fechaliberacion = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechaliberacion_ISO = moment(date).format('YYYY-MM-DD');
  }

  regresarmenu(){
    setTimeout(() => {
      $('#alumno').modal('show');
    }, 500)
  }

  // regresardocumentos(){
  //   $('#documento').modal('show');
  //   setTimeout(() => {
  //     $('#documentos').modal('show');
  //   }, 500)
  // }

  verConstancia(){
    setTimeout(() => {
      $('#constancia').modal('show');
    }, 500)
  }

  verdocumentos(){
    setTimeout(() => {
      $('#documentos').modal('show');
      this.consultardocumento();
    }, 500)
  }


  verestado(){
    var estatus = <HTMLSelectElement>document.getElementById('estatuslista');
    for(var i = 0; i < this.estatus.length; i++){
      if(this.estatusalumno == this.estatus[i]){
        estatus.selectedIndex = i;
        break;
      }
    }
    $('#estado').modal('show');
  }

  guardardocumento(){
    console.log(this.src);
    // saveAs(this.src, `${this.matriculalaumno}-${this.nombrealumno}.pdf`)
    var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
    saveAs(blob, "hello world.pdf");
  }

  consultardocumento(){
    this.banderacargando = true;
    this.banderasindatos = true;
    this.serviciouniversitario.visualizardocuments(this.maestro.matricula, this.matriculalaumno, this.idregistro).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderacargando = false;
        if(this.src == null){
          this.banderasindatos = false;
        }
      }
    });
  }

  seleccionaralumno(event){
    this.nombrealumno = `${event.data.alumno}`
    this.alumnoplanestudioid = event.data.alumnoplanestudioid;
    this.matriculalaumno = event.data.matricula;
    this.estatusalumno = event.data.estatus;
    this.idregistro = event.data.id;
    $('#alumno').modal('show');
  }

  periodoselecionadof(){
    var periodoescolar = <HTMLSelectElement>document.getElementById('periodoescolares');
    this.periodoselecionado = periodoescolar.value;
    this.banderacarrera = false;
  }

  carreraselecionadaf(){
    var carrera = <HTMLSelectElement>document.getElementById('carrera');
    this.carreraselecionada = carrera.value;
    this.banderamatricula = false;
  }

  consultarperiodosescolares(){
    this.serviciouniversitario.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.consultarcarreras();
      }
    });
  }

  consultarcarreras(){
    this.serviciouniversitario.listarcarrara(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carreras = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultaralumno(){
    this.banderatabla = true;
    this.serviciouniversitario.buscaralumnos(this.maestro.matricula, this.buscarmatriculaform.get('matricula').value).subscribe({
      next:(data : any) => {
        this.listaSSU = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.listaSSU.length == 0){
          $('#sinalumnos').modal('show');
        }
        this.itemSource = new LocalDataSource(this.listaSSU);
        this.banderatabla = false; 
      }
    });
  }

  listarSSU(){
    this.serviciouniversitario.listadoSSU(this.maestro.matricula, 0).subscribe({
      next:(data : any) => {
        this.listaSSU = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.itemSource = new LocalDataSource(this.listaSSU);
        this.banderatabla = false;
        this.banderamatricula = false;
      }
    });
  }

  cambiarestado(){
    var estatus = <HTMLSelectElement>document.getElementById('estatuslista');
    var jason = {
      matricula:this.matriculalaumno,
      matriculap:this.maestro.matricula,
      id:this.idregistro,
      estatus:estatus.value
    };
    this.serviciouniversitario.actualizarestados(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.listarSSU(); 
      }
    });
  }

  cerrarrespuesta(){
    $('#respuesta').modal('hide');
  }

}
