import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl  } from '@angular/forms';
import { PersonalService } from '../services/personal.service';
declare var $ : any;

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.css']
})
export class CambiarPasswordComponent implements OnInit {

  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  contraform = this.formbuilder.group({
    contraseñaanterior: ['', [Validators.required, Validators.minLength(8)]],
    contraseñanueva: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]],
    contraseñaconfirmar: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]]
  },
  {
    validators:[
      this.contraseñasiguales('contraseñanueva','contraseñaconfirmar'),
      this.contraseñasanterioriguales('contraseñanueva', 'contraseñaanterior')
    ]
  });


  constructor(public formbuilder: FormBuilder, private personalService:PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }

  contraseñasiguales(contraseñanueva, contraseñaconfirmar){
    return ( formGroup: FormGroup ) => {
      const contraseñanuevacontrol = formGroup.get(contraseñanueva);
      const contraseñaconfirmarcontrol = formGroup.get(contraseñaconfirmar);
      if(contraseñanuevacontrol.value === contraseñaconfirmarcontrol.value){

      }else{
        contraseñaconfirmarcontrol.setErrors({ noesigual:true })
      }
    }
  }

  contraseñasanterioriguales(contraseñanueva, contraseñaanterior){
    return ( formGroup: FormGroup ) => {
      const contraseñanuevacontrol = formGroup.get(contraseñanueva);
      const contraseñacanteriorcontrol = formGroup.get(contraseñaanterior);
      if(contraseñanuevacontrol.value === ''){

      }else{
        if(contraseñanuevacontrol.value === contraseñacanteriorcontrol.value){
          contraseñanuevacontrol.setErrors({ esigual:true })
        }else{

        }
      }
    }
  }

  cambiarcontra(){
    var datos = {
      usuario:this.maestro.matricula,
      oldpassword:this.contraform.get('contraseñaanterior').value,
      password:this.contraform.get('contraseñanueva').value
    }
    
    this.personalService.cambiarPassUser(datos).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.contraform.reset();
      }
    });
  }

}
