import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms'
import { M } from 'angular-mydatepicker';
import { PersonalService } from '../services/personal.service';
declare var $ : any;


@Component({
  selector: 'app-datos-personales-alumnos',
  templateUrl: './datos-personales-alumnos.component.html',
  styleUrls: ['./datos-personales-alumnos.component.css']
})
export class DatosPersonalesAlumnosComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  naciones = [
    {
      "nac_id" : 1,
      "nac_clave_nacionalidad" : "NAM",
      "nac_nacionalidad" : "NAMIBIANA",
      "nac_codigo_pais" : "101",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 2,
      "nac_clave_nacionalidad" : "AGO",
      "nac_nacionalidad" : "ANGOLESA",
      "nac_codigo_pais" : "103",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 3,
      "nac_clave_nacionalidad" : "DZA",
      "nac_nacionalidad" : "ARGELIANA",
      "nac_codigo_pais" : "104",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 4,
      "nac_clave_nacionalidad" : "BEN",
      "nac_nacionalidad" : "DE BENNIN",
      "nac_codigo_pais" : "105",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 5,
      "nac_clave_nacionalidad" : "BWA",
      "nac_nacionalidad" : "BOTSWANESA",
      "nac_codigo_pais" : "106",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 6,
      "nac_clave_nacionalidad" : "BDI",
      "nac_nacionalidad" : "BURUNDESA",
      "nac_codigo_pais" : "107",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 7,
      "nac_clave_nacionalidad" : "CPV",
      "nac_nacionalidad" : "DE CABO VERDE",
      "nac_codigo_pais" : "108",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 8,
      "nac_clave_nacionalidad" : "COM",
      "nac_nacionalidad" : "COMORENSE",
      "nac_codigo_pais" : "109",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 9,
      "nac_clave_nacionalidad" : "COD",
      "nac_nacionalidad" : "CONGOLESA",
      "nac_codigo_pais" : "110",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 10,
      "nac_clave_nacionalidad" : "COG",
      "nac_nacionalidad" : "MARFILEÑA",
      "nac_codigo_pais" : "111",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 11,
      "nac_clave_nacionalidad" : "TCD",
      "nac_nacionalidad" : "CHADIANA",
      "nac_codigo_pais" : "112",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 12,
      "nac_clave_nacionalidad" : "DJI",
      "nac_nacionalidad" : "DE DJIBOUTI",
      "nac_codigo_pais" : "113",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 13,
      "nac_clave_nacionalidad" : "EGY",
      "nac_nacionalidad" : "EGIPCIA",
      "nac_codigo_pais" : "114",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 14,
      "nac_clave_nacionalidad" : "ETH",
      "nac_nacionalidad" : "ETIOPE",
      "nac_codigo_pais" : "115",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 15,
      "nac_clave_nacionalidad" : "GAB",
      "nac_nacionalidad" : "GABONESA",
      "nac_codigo_pais" : "116",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 16,
      "nac_clave_nacionalidad" : "GMB",
      "nac_nacionalidad" : "GAMBIANA",
      "nac_codigo_pais" : "117",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 17,
      "nac_clave_nacionalidad" : "GHA",
      "nac_nacionalidad" : "GHANATA",
      "nac_codigo_pais" : "118",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 18,
      "nac_clave_nacionalidad" : "GNB",
      "nac_nacionalidad" : "GUINEA",
      "nac_codigo_pais" : "119",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 19,
      "nac_clave_nacionalidad" : "GIN",
      "nac_nacionalidad" : "GUINEA",
      "nac_codigo_pais" : "120",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 20,
      "nac_clave_nacionalidad" : "GNQ",
      "nac_nacionalidad" : "GUINEA ECUATORIANA",
      "nac_codigo_pais" : "121",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 21,
      "nac_clave_nacionalidad" : "LBY",
      "nac_nacionalidad" : "LIBIA",
      "nac_codigo_pais" : "122",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 22,
      "nac_clave_nacionalidad" : "KEN",
      "nac_nacionalidad" : "KENIANA",
      "nac_codigo_pais" : "123",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 23,
      "nac_clave_nacionalidad" : "LSO",
      "nac_nacionalidad" : "LESOTHENSE",
      "nac_codigo_pais" : "124",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 24,
      "nac_clave_nacionalidad" : "LBR",
      "nac_nacionalidad" : "LIBERIANA",
      "nac_codigo_pais" : "125",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 25,
      "nac_clave_nacionalidad" : "MWI",
      "nac_nacionalidad" : "MALAWIANA",
      "nac_codigo_pais" : "127",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 26,
      "nac_clave_nacionalidad" : "MLI",
      "nac_nacionalidad" : "MALIENSE",
      "nac_codigo_pais" : "128",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 27,
      "nac_clave_nacionalidad" : "MAR",
      "nac_nacionalidad" : "MARROQUI",
      "nac_codigo_pais" : "129",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 28,
      "nac_clave_nacionalidad" : "MUS",
      "nac_nacionalidad" : "MAURICIANA",
      "nac_codigo_pais" : "130",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 29,
      "nac_clave_nacionalidad" : "MRT",
      "nac_nacionalidad" : "MAURITANA",
      "nac_codigo_pais" : "131",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 30,
      "nac_clave_nacionalidad" : "MOZ",
      "nac_nacionalidad" : "MOZAMBIQUEÑA",
      "nac_codigo_pais" : "132",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 31,
      "nac_clave_nacionalidad" : "NER",
      "nac_nacionalidad" : "NIGERINA",
      "nac_codigo_pais" : "133",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 32,
      "nac_clave_nacionalidad" : "NGA",
      "nac_nacionalidad" : "NIGERIANA",
      "nac_codigo_pais" : "134",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 33,
      "nac_clave_nacionalidad" : "CAF",
      "nac_nacionalidad" : "CENTRO AFRICANA",
      "nac_codigo_pais" : "135",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 34,
      "nac_clave_nacionalidad" : "CMR",
      "nac_nacionalidad" : "CAMERUNESA",
      "nac_codigo_pais" : "136",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 35,
      "nac_clave_nacionalidad" : "TZA",
      "nac_nacionalidad" : "TANZANIANA",
      "nac_codigo_pais" : "137",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 36,
      "nac_clave_nacionalidad" : "RWA",
      "nac_nacionalidad" : "RWANDESA",
      "nac_codigo_pais" : "139",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 37,
      "nac_clave_nacionalidad" : "ESH",
      "nac_nacionalidad" : "DEL SAHARA",
      "nac_codigo_pais" : "140",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 38,
      "nac_clave_nacionalidad" : "STP",
      "nac_nacionalidad" : "DE SANTO TOME",
      "nac_codigo_pais" : "141",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 39,
      "nac_clave_nacionalidad" : "SEN",
      "nac_nacionalidad" : "SENEGALESA",
      "nac_codigo_pais" : "142",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 40,
      "nac_clave_nacionalidad" : "SYC",
      "nac_nacionalidad" : "DE SEYCHELLES",
      "nac_codigo_pais" : "143",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 41,
      "nac_clave_nacionalidad" : "SLE",
      "nac_nacionalidad" : "SIERRA LEONESA",
      "nac_codigo_pais" : "144",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 42,
      "nac_clave_nacionalidad" : "SOM",
      "nac_nacionalidad" : "SOMALI",
      "nac_codigo_pais" : "145",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 43,
      "nac_clave_nacionalidad" : "ZAF",
      "nac_nacionalidad" : "SUDAFRICANA",
      "nac_codigo_pais" : "146",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 44,
      "nac_clave_nacionalidad" : "SDN",
      "nac_nacionalidad" : "SUDANESA",
      "nac_codigo_pais" : "147",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 45,
      "nac_clave_nacionalidad" : "SWZ",
      "nac_nacionalidad" : "SWAZI",
      "nac_codigo_pais" : "148",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 46,
      "nac_clave_nacionalidad" : "TGO",
      "nac_nacionalidad" : "TOGOLESA",
      "nac_codigo_pais" : "149",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 47,
      "nac_clave_nacionalidad" : "TUN",
      "nac_nacionalidad" : "TUNECINA",
      "nac_codigo_pais" : "150",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 48,
      "nac_clave_nacionalidad" : "UGA",
      "nac_nacionalidad" : "UGANDESA",
      "nac_codigo_pais" : "151",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 49,
      "nac_clave_nacionalidad" : "ZAR",
      "nac_nacionalidad" : "ZAIRANA",
      "nac_codigo_pais" : "152",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 50,
      "nac_clave_nacionalidad" : "ZMB",
      "nac_nacionalidad" : "ZAMBIANA",
      "nac_codigo_pais" : "153",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 51,
      "nac_clave_nacionalidad" : "ZWE",
      "nac_nacionalidad" : "DE ZIMBAWI",
      "nac_codigo_pais" : "154",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 52,
      "nac_clave_nacionalidad" : "ARG",
      "nac_nacionalidad" : "ARGENTINA",
      "nac_codigo_pais" : "201",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 53,
      "nac_clave_nacionalidad" : "BHS",
      "nac_nacionalidad" : "BAHAMEÑA",
      "nac_codigo_pais" : "202",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 54,
      "nac_clave_nacionalidad" : "BRB",
      "nac_nacionalidad" : "DE BARBADOS",
      "nac_codigo_pais" : "203",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 55,
      "nac_clave_nacionalidad" : "BLZ",
      "nac_nacionalidad" : "BELICEÑA",
      "nac_codigo_pais" : "204",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 56,
      "nac_clave_nacionalidad" : "BOL",
      "nac_nacionalidad" : "BOLIVIANA",
      "nac_codigo_pais" : "205",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 57,
      "nac_clave_nacionalidad" : "BRA",
      "nac_nacionalidad" : "BRASILEÑA",
      "nac_codigo_pais" : "206",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 58,
      "nac_clave_nacionalidad" : "CAN",
      "nac_nacionalidad" : "CANADIENSE",
      "nac_codigo_pais" : "207",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 59,
      "nac_clave_nacionalidad" : "COL",
      "nac_nacionalidad" : "COLOMBIANA",
      "nac_codigo_pais" : "208",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 60,
      "nac_clave_nacionalidad" : "CRI",
      "nac_nacionalidad" : "COSTARRICENSE",
      "nac_codigo_pais" : "209",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 61,
      "nac_clave_nacionalidad" : "CUB",
      "nac_nacionalidad" : "CUBANA",
      "nac_codigo_pais" : "210",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 62,
      "nac_clave_nacionalidad" : "CHL",
      "nac_nacionalidad" : "CHILENA",
      "nac_codigo_pais" : "211",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 63,
      "nac_clave_nacionalidad" : "DMA",
      "nac_nacionalidad" : "DOMINICA",
      "nac_codigo_pais" : "212",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 64,
      "nac_clave_nacionalidad" : "SLV",
      "nac_nacionalidad" : "SALVADOREÑA",
      "nac_codigo_pais" : "214",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 65,
      "nac_clave_nacionalidad" : "USA",
      "nac_nacionalidad" : "ESTADOUNIDENSE",
      "nac_codigo_pais" : "215",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 66,
      "nac_clave_nacionalidad" : "VCT",
      "nac_nacionalidad" : "GRANADINA",
      "nac_codigo_pais" : "216",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 67,
      "nac_clave_nacionalidad" : "GTM",
      "nac_nacionalidad" : "GUATEMALTECA",
      "nac_codigo_pais" : "217",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 68,
      "nac_clave_nacionalidad" : "VGB",
      "nac_nacionalidad" : "BRITANICA",
      "nac_codigo_pais" : "218",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 69,
      "nac_clave_nacionalidad" : "GUY",
      "nac_nacionalidad" : "GUYANESA",
      "nac_codigo_pais" : "219",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 70,
      "nac_clave_nacionalidad" : "HTI",
      "nac_nacionalidad" : "HAITIANA",
      "nac_codigo_pais" : "220",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 71,
      "nac_clave_nacionalidad" : "HND",
      "nac_nacionalidad" : "HONDUREÑA",
      "nac_codigo_pais" : "221",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 72,
      "nac_clave_nacionalidad" : "JAM",
      "nac_nacionalidad" : "JAMAIQUINA",
      "nac_codigo_pais" : "222",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 73,
      "nac_clave_nacionalidad" : "MEX",
      "nac_nacionalidad" : "MEXICANA",
      "nac_codigo_pais" : "223",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 74,
      "nac_clave_nacionalidad" : "NIC",
      "nac_nacionalidad" : "NICARAGUENSE",
      "nac_codigo_pais" : "224",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 75,
      "nac_clave_nacionalidad" : "PAN",
      "nac_nacionalidad" : "PANAMEÑA",
      "nac_codigo_pais" : "225",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 76,
      "nac_clave_nacionalidad" : "PRY",
      "nac_nacionalidad" : "PARAGUAYA",
      "nac_codigo_pais" : "226",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 77,
      "nac_clave_nacionalidad" : "PER",
      "nac_nacionalidad" : "PERUANA",
      "nac_codigo_pais" : "227",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 78,
      "nac_clave_nacionalidad" : "PRI",
      "nac_nacionalidad" : "PUERTORRIQUEÑA",
      "nac_codigo_pais" : "228",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 79,
      "nac_clave_nacionalidad" : "DOM",
      "nac_nacionalidad" : "DOMINICANA",
      "nac_codigo_pais" : "229",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 80,
      "nac_clave_nacionalidad" : "LCA",
      "nac_nacionalidad" : "SANTA LUCIENSE",
      "nac_codigo_pais" : "230",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 81,
      "nac_clave_nacionalidad" : "SUR",
      "nac_nacionalidad" : "SURINAMENSE",
      "nac_codigo_pais" : "231",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 82,
      "nac_clave_nacionalidad" : "TTO",
      "nac_nacionalidad" : "TRINITARIA",
      "nac_codigo_pais" : "232",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 83,
      "nac_clave_nacionalidad" : "URY",
      "nac_nacionalidad" : "URUGUAYA",
      "nac_codigo_pais" : "233",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 84,
      "nac_clave_nacionalidad" : "VEN",
      "nac_nacionalidad" : "VENEZOLANA",
      "nac_codigo_pais" : "234",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 85,
      "nac_clave_nacionalidad" : "USA",
      "nac_nacionalidad" : "AMERICANA",
      "nac_codigo_pais" : "299",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 86,
      "nac_clave_nacionalidad" : "AFG",
      "nac_nacionalidad" : "AFGANA",
      "nac_codigo_pais" : "301",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 87,
      "nac_clave_nacionalidad" : "BHR",
      "nac_nacionalidad" : "DE BAHREIN",
      "nac_codigo_pais" : "303",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 88,
      "nac_clave_nacionalidad" : "BTN",
      "nac_nacionalidad" : "BHUTANESA",
      "nac_codigo_pais" : "305",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 89,
      "nac_clave_nacionalidad" : "BUR",
      "nac_nacionalidad" : "BIRMANA",
      "nac_codigo_pais" : "306",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 90,
      "nac_clave_nacionalidad" : "PRK",
      "nac_nacionalidad" : "NORCOREANA",
      "nac_codigo_pais" : "307",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 91,
      "nac_clave_nacionalidad" : "KOR",
      "nac_nacionalidad" : "SUDCOREANA",
      "nac_codigo_pais" : "308",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 92,
      "nac_clave_nacionalidad" : "CHN",
      "nac_nacionalidad" : "CHINA",
      "nac_codigo_pais" : "309",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 93,
      "nac_clave_nacionalidad" : "CYP",
      "nac_nacionalidad" : "CHIPRIOTA",
      "nac_codigo_pais" : "310",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 94,
      "nac_clave_nacionalidad" : "SAU",
      "nac_nacionalidad" : "ARABE",
      "nac_codigo_pais" : "311",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 95,
      "nac_clave_nacionalidad" : "PHL",
      "nac_nacionalidad" : "FILIPINA",
      "nac_codigo_pais" : "312",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 96,
      "nac_clave_nacionalidad" : "IND",
      "nac_nacionalidad" : "HINDU",
      "nac_codigo_pais" : "313",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 97,
      "nac_clave_nacionalidad" : "IDN",
      "nac_nacionalidad" : "INDONESA",
      "nac_codigo_pais" : "314",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 98,
      "nac_clave_nacionalidad" : "IRQ",
      "nac_nacionalidad" : "IRAQUI",
      "nac_codigo_pais" : "315",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 99,
      "nac_clave_nacionalidad" : "IRN",
      "nac_nacionalidad" : "IRANI",
      "nac_codigo_pais" : "316",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 100,
      "nac_clave_nacionalidad" : "ISR",
      "nac_nacionalidad" : "ISRAELI",
      "nac_codigo_pais" : "317",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 101,
      "nac_clave_nacionalidad" : "JPN",
      "nac_nacionalidad" : "JAPONESA",
      "nac_codigo_pais" : "318",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 102,
      "nac_clave_nacionalidad" : "JOR",
      "nac_nacionalidad" : "JORDANA",
      "nac_codigo_pais" : "319",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 103,
      "nac_clave_nacionalidad" : "KHM",
      "nac_nacionalidad" : "CAMBOYANA",
      "nac_codigo_pais" : "320",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 104,
      "nac_clave_nacionalidad" : "KWT",
      "nac_nacionalidad" : "KUWAITI",
      "nac_codigo_pais" : "321",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 105,
      "nac_clave_nacionalidad" : "LBN",
      "nac_nacionalidad" : "LIBANESA",
      "nac_codigo_pais" : "322",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 106,
      "nac_clave_nacionalidad" : "MYS",
      "nac_nacionalidad" : "MALASIA",
      "nac_codigo_pais" : "323",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 107,
      "nac_clave_nacionalidad" : "MDV",
      "nac_nacionalidad" : "MALDIVA",
      "nac_codigo_pais" : "324",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 108,
      "nac_clave_nacionalidad" : "MNG",
      "nac_nacionalidad" : "MONGOLESA",
      "nac_codigo_pais" : "325",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 109,
      "nac_clave_nacionalidad" : "NPL",
      "nac_nacionalidad" : "NEPALESA",
      "nac_codigo_pais" : "326",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 110,
      "nac_clave_nacionalidad" : "OMN",
      "nac_nacionalidad" : "OMANESA",
      "nac_codigo_pais" : "327",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 111,
      "nac_clave_nacionalidad" : "PAK",
      "nac_nacionalidad" : "PAKISTANI",
      "nac_codigo_pais" : "328",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 112,
      "nac_clave_nacionalidad" : "QAT",
      "nac_nacionalidad" : "DEL QATAR",
      "nac_codigo_pais" : "329",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 113,
      "nac_clave_nacionalidad" : "SYR",
      "nac_nacionalidad" : "SIRIA",
      "nac_codigo_pais" : "330",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 114,
      "nac_clave_nacionalidad" : "LAO",
      "nac_nacionalidad" : "LAOSIANA",
      "nac_codigo_pais" : "331",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 115,
      "nac_clave_nacionalidad" : "SGP",
      "nac_nacionalidad" : "SINGAPORENSE",
      "nac_codigo_pais" : "332",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 116,
      "nac_clave_nacionalidad" : "THA",
      "nac_nacionalidad" : "TAILANDESA",
      "nac_codigo_pais" : "334",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 117,
      "nac_clave_nacionalidad" : "TWN",
      "nac_nacionalidad" : "TAIWANESA",
      "nac_codigo_pais" : "335",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 118,
      "nac_clave_nacionalidad" : "TUR",
      "nac_nacionalidad" : "TURCA",
      "nac_codigo_pais" : "336",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 119,
      "nac_clave_nacionalidad" : "VNM",
      "nac_nacionalidad" : "NORVIETNAMITA",
      "nac_codigo_pais" : "337",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 120,
      "nac_clave_nacionalidad" : "YEM",
      "nac_nacionalidad" : "YEMENI",
      "nac_codigo_pais" : "339",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 121,
      "nac_clave_nacionalidad" : "ALB",
      "nac_nacionalidad" : "ALBANESA",
      "nac_codigo_pais" : "401",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 122,
      "nac_clave_nacionalidad" : "DEU",
      "nac_nacionalidad" : "ALEMANA",
      "nac_codigo_pais" : "403",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 123,
      "nac_clave_nacionalidad" : "AND",
      "nac_nacionalidad" : "ANDORRANA",
      "nac_codigo_pais" : "404",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 124,
      "nac_clave_nacionalidad" : "AUT",
      "nac_nacionalidad" : "AUSTRIACA",
      "nac_codigo_pais" : "405",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 125,
      "nac_clave_nacionalidad" : "BEL",
      "nac_nacionalidad" : "BELGA",
      "nac_codigo_pais" : "406",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 126,
      "nac_clave_nacionalidad" : "BGR",
      "nac_nacionalidad" : "BULGARA",
      "nac_codigo_pais" : "407",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 127,
      "nac_clave_nacionalidad" : "CSK",
      "nac_nacionalidad" : "CHECOSLOVACA",
      "nac_codigo_pais" : "408",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 128,
      "nac_clave_nacionalidad" : "DNK",
      "nac_nacionalidad" : "DANESA",
      "nac_codigo_pais" : "409",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 129,
      "nac_clave_nacionalidad" : "VAT",
      "nac_nacionalidad" : "VATICANA",
      "nac_codigo_pais" : "410",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 130,
      "nac_clave_nacionalidad" : "ESP",
      "nac_nacionalidad" : "ESPAÑOLA",
      "nac_codigo_pais" : "411",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 131,
      "nac_clave_nacionalidad" : "FIN",
      "nac_nacionalidad" : "FINLANDESA",
      "nac_codigo_pais" : "412",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 132,
      "nac_clave_nacionalidad" : "FRA",
      "nac_nacionalidad" : "FRANCESA",
      "nac_codigo_pais" : "413",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 133,
      "nac_clave_nacionalidad" : "GRC",
      "nac_nacionalidad" : "GRIEGA",
      "nac_codigo_pais" : "414",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 134,
      "nac_clave_nacionalidad" : "HUN",
      "nac_nacionalidad" : "HUNGARA",
      "nac_codigo_pais" : "415",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 135,
      "nac_clave_nacionalidad" : "IRL",
      "nac_nacionalidad" : "IRLANDESA",
      "nac_codigo_pais" : "416",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 136,
      "nac_clave_nacionalidad" : "ISL",
      "nac_nacionalidad" : "ISLANDESA",
      "nac_codigo_pais" : "417",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 137,
      "nac_clave_nacionalidad" : "ITA",
      "nac_nacionalidad" : "ITALIANA",
      "nac_codigo_pais" : "418",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 138,
      "nac_clave_nacionalidad" : "LIE",
      "nac_nacionalidad" : "LIECHTENSTENSE",
      "nac_codigo_pais" : "419",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 139,
      "nac_clave_nacionalidad" : "LUX",
      "nac_nacionalidad" : "LUXEMBURGUESA",
      "nac_codigo_pais" : "420",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 140,
      "nac_clave_nacionalidad" : "MLT",
      "nac_nacionalidad" : "MALTESA",
      "nac_codigo_pais" : "421",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 141,
      "nac_clave_nacionalidad" : "MCO",
      "nac_nacionalidad" : "MONEGASCA",
      "nac_codigo_pais" : "422",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 142,
      "nac_clave_nacionalidad" : "NOR",
      "nac_nacionalidad" : "NORUEGA",
      "nac_codigo_pais" : "423",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 143,
      "nac_clave_nacionalidad" : "NLD",
      "nac_nacionalidad" : "HOLANDESA",
      "nac_codigo_pais" : "424",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 144,
      "nac_clave_nacionalidad" : "PRT",
      "nac_nacionalidad" : "PORTUGUESA",
      "nac_codigo_pais" : "426",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 145,
      "nac_clave_nacionalidad" : "IOT",
      "nac_nacionalidad" : "BRITANICA",
      "nac_codigo_pais" : "427",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 146,
      "nac_clave_nacionalidad" : "BLR",
      "nac_nacionalidad" : "SOVIETICA BIELORRUSA",
      "nac_codigo_pais" : "428",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 147,
      "nac_clave_nacionalidad" : "UKR",
      "nac_nacionalidad" : "SOVIETICA UCRANIANA",
      "nac_codigo_pais" : "429",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 148,
      "nac_clave_nacionalidad" : "ROM",
      "nac_nacionalidad" : "RUMANA",
      "nac_codigo_pais" : "430",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 149,
      "nac_clave_nacionalidad" : "SMR",
      "nac_nacionalidad" : "SAN MARINENSE",
      "nac_codigo_pais" : "431",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 150,
      "nac_clave_nacionalidad" : "SWE",
      "nac_nacionalidad" : "SUECA",
      "nac_codigo_pais" : "432",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 151,
      "nac_clave_nacionalidad" : "CHE",
      "nac_nacionalidad" : "SUIZA",
      "nac_codigo_pais" : "433",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 152,
      "nac_clave_nacionalidad" : "YUG",
      "nac_nacionalidad" : "YUGOSLAVA",
      "nac_codigo_pais" : "435",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 153,
      "nac_clave_nacionalidad" : "AUS",
      "nac_nacionalidad" : "AUSTRALIANA",
      "nac_codigo_pais" : "501",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 154,
      "nac_clave_nacionalidad" : "FJI",
      "nac_nacionalidad" : "FIJIANA",
      "nac_codigo_pais" : "502",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 155,
      "nac_clave_nacionalidad" : "SLB",
      "nac_nacionalidad" : "SALOMONESA",
      "nac_codigo_pais" : "503",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 156,
      "nac_clave_nacionalidad" : "NRU",
      "nac_nacionalidad" : "NAURUANA",
      "nac_codigo_pais" : "504",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 157,
      "nac_clave_nacionalidad" : "PNG",
      "nac_nacionalidad" : "PAPUENSE",
      "nac_codigo_pais" : "506",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 158,
      "nac_clave_nacionalidad" : "WSM",
      "nac_nacionalidad" : "SAMOANA",
      "nac_codigo_pais" : "507",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 159,
      "nac_clave_nacionalidad" : "SVK",
      "nac_nacionalidad" : "ESLOVACA",
      "nac_codigo_pais" : "667",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 160,
      "nac_clave_nacionalidad" : "BFA",
      "nac_nacionalidad" : "BURKINA FASO",
      "nac_codigo_pais" : "609",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 161,
      "nac_clave_nacionalidad" : "EST",
      "nac_nacionalidad" : "ESTONIA",
      "nac_codigo_pais" : "621",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 162,
      "nac_clave_nacionalidad" : "FSM",
      "nac_nacionalidad" : "MICRONECIA",
      "nac_codigo_pais" : "624",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 163,
      "nac_clave_nacionalidad" : "GBD",
      "nac_nacionalidad" : "REINO UNIDO(DEPEN. TET. BRIT.)",
      "nac_codigo_pais" : "625",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 164,
      "nac_clave_nacionalidad" : "GBN",
      "nac_nacionalidad" : "REINO UNIDO(BRIT. DEL EXT.)",
      "nac_codigo_pais" : "626",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 165,
      "nac_clave_nacionalidad" : "GBO",
      "nac_nacionalidad" : "REINO UNIDO(C. BRIT. DEL EXT.)",
      "nac_codigo_pais" : "627",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 166,
      "nac_clave_nacionalidad" : "GBR",
      "nac_nacionalidad" : "REINO UNIDO",
      "nac_codigo_pais" : "629",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 167,
      "nac_clave_nacionalidad" : "KGZ",
      "nac_nacionalidad" : "KIRGUISTAN",
      "nac_codigo_pais" : "642",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 168,
      "nac_clave_nacionalidad" : "LTU",
      "nac_nacionalidad" : "LITUANIA ",
      "nac_codigo_pais" : "645",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 169,
      "nac_clave_nacionalidad" : "MDA",
      "nac_nacionalidad" : "MOLDOVIA, REPUBLICA DE",
      "nac_codigo_pais" : "648",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 170,
      "nac_clave_nacionalidad" : "MKD",
      "nac_nacionalidad" : "MACEDONIA",
      "nac_codigo_pais" : "650",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 171,
      "nac_clave_nacionalidad" : "SVN",
      "nac_nacionalidad" : "ESLOVENIA",
      "nac_codigo_pais" : "668",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    },
    {
      "nac_id" : 172,
      "nac_clave_nacionalidad" : "XES",
      "nac_nacionalidad" : "ESLOVAQUIA",
      "nac_codigo_pais" : "684",
      "fechaCreacion" : "2018-10-24 16:50:03",
      "fechaInactivo" : null
    }
  ];

  discapacidades = [
    { id: 1, discapacidad:'N/A' },
    { id: 2, discapacidad:'FÍSICA' },
    { id: 3, discapacidad:'SENSORIAL' },
    { id: 4, discapacidad:'INTELECTUAL' },
    { id: 5, discapacidad:'SÍQUICA,' },
    { id: 6, discapacidad:'VISCERAL' },
    { id: 8, discapacidad:'MÚLTIPLE' }
  ];

  datosgeneralesForm = new FormGroup({
    apellidom: new FormControl(''),
    apellidop: new FormControl(''),
    calle: new FormControl('', [Validators.required]),
    codigopostal: new FormControl('', [Validators.required]),
    colonia: new FormControl('', [Validators.required]),
    curp: new FormControl('', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    estatus: new FormControl(''),
    fechanacimiento: new FormControl(''),
    matricula: new FormControl(''),
    periodoingreso: new FormControl(''),
    periodoidingreso: new FormControl(''),
    planestudio: new FormControl(''),
    planestudioid: new FormControl(''),
    regular: new FormControl(''),
    semestre: new FormControl(''),
    semestrecalculado: new FormControl(''),
    telefono: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10) ]),
    tipo: new FormControl(''),
    nombres: new FormControl(''),
    matriculap: new FormControl(''),
    rfc: new FormControl('', [Validators.required,  Validators.minLength(12), Validators.maxLength(13)])
  });
  
  datoscomplementariosForm = new FormGroup({
    alumnoid: new FormControl(''),
    id: new FormControl(''),
    matricula: new FormControl(''),
    nacionalidad: new FormControl('', [Validators.required]),
    nacionalidadid: new FormControl(''),
    escuelaprocedencia: new FormControl('', [Validators.required]),
    municipionacimiento: new FormControl('', [Validators.required]),
    estadocivil: new FormControl('', [Validators.required]),
    trabaja:new FormControl('', [Validators.required]),
    extranjero: new FormControl('', [Validators.required]),
    municipioresidencia: new FormControl('', [Validators.required]),
    discapacidad: new FormControl('', [Validators.required]),
    discapacidadid: new FormControl('', [Validators.required]),
    hijos: new FormControl('', [Validators.required]),
    numerohijos: new FormControl('', [Validators.required]),
    cedulaprofesional: new FormControl('', [Validators.required]),
    lentes: new FormControl('', [Validators.required]),
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  banderabutton:boolean = true;
  discapacidad = '';
  nacionalidad = '';
  datosgenerales;
  datoscomplementarios;
  alumno;
  banderamodificar:boolean = true;
  banderamodificar2:boolean = true;

  constructor(private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.alumno = localStorage.getItem('alumno');
    this.consultardatosgenerales();
    this.consultardatoscomplementarios();
  }

  consultardatoscomplementarios(){
    this.personalService.consultardatoscomplementariosalumnoa(this.alumno, this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.datoscomplementarios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        let naciones = <HTMLSelectElement>document.getElementById('nacionalidad');
        let extranjero = <HTMLSelectElement>document.getElementById('extranjero');
        let trabaja = <HTMLSelectElement>document.getElementById('trabaja');
        let discapacidad = <HTMLSelectElement>document.getElementById('discapacidad');
        var lentes = <HTMLSelectElement>document.getElementById('lentes');
        this.datoscomplementariosForm.setValue(this.datoscomplementarios);
        for(var i = 0; i < this.naciones.length; i++){
          if(this.datoscomplementarios.nacionalidadid == this.naciones[i].nac_id){
            naciones.selectedIndex = i;
          }
        }
        if(this.datoscomplementarios.extranjero == "NO"){
          extranjero.selectedIndex = 0;
        }
        if(this.datoscomplementarios.extranjero == "SI"){
          extranjero.selectedIndex = 1;
        }
        if(this.datoscomplementarios.trabaja == "NO"){
          trabaja.selectedIndex = 0;
        }
        if(this.datoscomplementarios.trabaja == "SI"){
          trabaja.selectedIndex = 1;
        }
        if(this.datoscomplementarios.lentes == "NO"){
          lentes.selectedIndex = 0;
        }
        if(this.datoscomplementarios.lentes == "SI"){
          lentes.selectedIndex = 1;
        }
        for(var z = 0; z < this.discapacidades.length; z++){
          if(this.datoscomplementarios.discapacidadid == (this.discapacidades[z].id).toString()){
            discapacidad.selectedIndex = z;
          }
        }
        // this.datoscomplementariosForm.disable();
      }
    });
  }

  guardarcambioscomplementarios(){
    let naciones = <HTMLSelectElement>document.getElementById('nacionalidad');
    let extranjero = <HTMLSelectElement>document.getElementById('extranjero');
    let trabaja = <HTMLSelectElement>document.getElementById('trabaja');
    let discapacidad = <HTMLSelectElement>document.getElementById('discapacidad');
    var lentes = <HTMLSelectElement>document.getElementById('lentes');
    for(var i = 0; i < this.discapacidades.length; i++){
      if(discapacidad.value == (this.discapacidades[i].id).toString()){
        this.discapacidad = this.discapacidades[i].discapacidad
        break;
      }
    }
    for(var x = 0; x < this.naciones.length; x++){
      if(naciones.value == (this.naciones[x].nac_id).toString()){
        this.nacionalidad = this.naciones[x].nac_nacionalidad
      }
    }
    this.datoscomplementariosForm.patchValue({ discapacidad:this.discapacidad });
    this.datoscomplementariosForm.patchValue({ discapacidadid:discapacidad.value });
    this.datoscomplementariosForm.patchValue({ extranjero:extranjero.value });
    this.datoscomplementariosForm.patchValue({ lentes:lentes.value });
    this.datoscomplementariosForm.patchValue({ nacionalidad:this.nacionalidad });
    this.datoscomplementariosForm.patchValue({ nacionalidadid:naciones.value });
    this.datoscomplementariosForm.patchValue({ trabaja:trabaja.value });
    this.datoscomplementariosForm.addControl('matriculap',new FormControl(this.maestro.matricula));
    this.personalService.editardatoscmp(this.datoscomplementariosForm.value).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        if(this.respuesta.codigo == "OK"){
          // this.datoscomplementariosForm.disable();
          // this.banderamodificar2 = true;
        }
      }
    });
  }

  modificar2(){
    this.datoscomplementariosForm.enable();
    this.datosgeneralesForm.get('matricula').disable();
    this.banderamodificar2 = false;
    this.banderamodificar = true;
    this.datosgeneralesForm.disable();
  }

  consultardatosgenerales(){
    this.personalService.consultardatosgeneralesalumno(this.alumno, this.maestro.matricula).subscribe({
      next:(data : any) => {
        
        this.datosgenerales = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // console.log(this.datosgenerales);
        this.datosgeneralesForm.patchValue(this.datosgenerales);
      }
    });
  }

  // editardatos(){
  //   this.personalService.editardatoscomplementariosalumnos(this.datoscomplementariosForm).subscribe({
  //     next:(data : any) => {
  //       this.respuesta = data;
  //     },
  //     error:(err) => {
  //       console.log(err);
  //     },
  //     complete:() => {

  //     }
  //   });
  // }

  modificar(){
    this.datosgeneralesForm.enable();
    this.datosgeneralesForm.get('matricula').disable();
    this.banderamodificar = false;
    this.banderamodificar2 = true;
    this.datoscomplementariosForm.disable();
  }

  editardatosgenerales(){
    this.banderabutton = false ;
    if(this.datosgeneralesForm.invalid){
      this.datosgeneralesForm.markAllAsTouched();
    }else{
      if(this.datosgeneralesForm.valid){
        this.personalService.editardatosgeneralesalumno(this.datosgeneralesForm.value).subscribe({
          next:(data : any) =>{
            this.respuesta = data;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            this.banderabutton = true ;
            $('#respuesta').modal('show');
          }
        });
      }
    }
  }

  cerrarresuesta(){
    $('#respuesta').modal('hide');
  }



}
