<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-12">   
              <div class="row" id="top">
                <div class="col-12">
                    <form [formGroup]="busqueda" class="form-inline">
                        <div class="form-group mx-sm-3 mb-2">
                          <input [formControl]="busqueda.get('matricula')" type="text" class="form-control" id="matricula" placeholder="Matrícula">
                        </div>
                        <button type="submit" class="btn btn-danger mb-2" [disabled]="busqueda.invalid" (click)="buscarmatricula()">Buscar</button>
                    </form>
                </div>
            </div> 
            <div class="row" id="top">
                <div class="col-12" *ngIf="!banderatabla">
                    <ng2-smart-table (custom)="veralumno($event)" class="table table-hover table-striped table-sm table-borderless table-striped" [settings]="settings" [source]="alumnos"></ng2-smart-table>
                </div>
                <div class="col-12" *ngIf="!banderaspiner">
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                  </div>
            </div> 
            </div>
        </div>
    </div>

        <div class="modal fade" id="pago" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 *ngIf="!banderadatos" class="modal-title" id="staticBackdropLabel">
                      Estatus de pago del alumno<br>
                      Matrícula: {{ alumnocompleto.matricula }}<br>
                      {{ alumnocompleto.alumno }}
                    </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="estatus">Estatus de pago FAMEN</label>
                        <select class="form-control" id="estatus">
                          <option *ngFor="let e of estatus" [value]="e.valor">{{ e.estatus }}</option>
                        </select>
                      </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Salir</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="guardar()">Aceptar</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade" id="response" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    {{ response.mensaje }}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Salir</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                </div>
              </div>
            </div>
          </div>

</body>
</html>


