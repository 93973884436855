import { Component, OnInit } from '@angular/core';
import { AdministracionService } from '../services/administracion.service';
declare var $ : any;


@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoIComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  periodoescolaractivos = [];
  periodosabiertos = [];
  periodoidseleccionado;
  programaciones = [];
  banderaspinner:boolean = true;
  banderatabla:boolean = true;

  constructor(private administracionService : AdministracionService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  settingslistado = {
    columns:{
      periodo:{
        title:'Período',
        editable:false,
        width:'9%'
      },
      planestudio:{
        title:'Plan Estudio',
        editable:false,
        width:'9%'
      },
      fechainicio:{
        title:'Fecha de Inicio',
        editable:false,
        width:'9%'
      },
      horainicio:{
        title:'Hora de Inicio',
        editable:false,
        width:'9%'
      },
      horasdia:{
        title:'Horas al Día',
        editable:false,
        width:'9%'
      },
      minutosinscripcion:{
        title:'Minutos de inscripción',
        editable:false,
        width:'9%'
      },
      minutosentrealumno:{
        title:'Minutos entre alumno',
        editable:false,
        width:'9%'
      },
      alumnosporbloque:{
        title:'Alumnos por bloques',
        editable:false,
        width:'9%'
      },
      separacionbloque:{
        title:'Minutos entre bloques',
        editable:false,
        width:'9%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'9%',
        valuePrepareFunction:(cell) => {
          if(cell == 0){
            return "TODOS"
          }else{
            return cell
          }
        }
      },
      regular:{
        title:'Regulares',
        editable:false,
        width:'9%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:5
    },
    noDataMessage:'Sin datos disponibles.'
  }

  consultarinscripciones(){
    this.banderaspinner = false;
    this.administracionService.listadodeprogramaciones(this.maestro.matricula, this.periodoidseleccionado).subscribe({
      next:(data : any) => {
        this.programaciones = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderaspinner = true;
        this.banderatabla = false;
      }
    });
  }

  consultarperiodoescolares(){
    this.administracionService.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodoescolaractivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodoescolaractivos.length; i++){
          if(this.periodoescolaractivos[i].estado == "ABIERTO"){
            this.periodosabiertos.push(this.periodoescolaractivos[i]);
          }
        }
    }
    });
  }

  
  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    this.periodoidseleccionado = periodoid.value;
    this.consultarinscripciones();
  }

}
