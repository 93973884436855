import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { formatCurrency } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-alumnos-solicitud',
  templateUrl: './alumnos-solicitud.component.html',
  styleUrls: ['./alumnos-solicitud.component.css']
})
export class AlumnosSolicitudComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings = {
    columns:{
      nombrecompleto:{
      title:'Alumno',
      editable:false,         
      width:'20%'
    },
      promedio:{
      title:'Promedio',
      editable:false,         
      width:'10%'
    },
      regular:{
      title:'Regular',
      editable:false,         
      width:'10%'
    },
    matricula:{
      title:'Matrícula',
      editable:false,         
      width:'10%'
    },
    semestre:{
        title:'Semestre',
        editable:false,         
        width:'10%'
    },
    estatus:{
      title:'Estatus',
      editable:false,         
      width:'10%'
    },
    plaza:{
      title:'Plaza seleccionada',
      editable:false,         
      width:'20%'
    },
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      type:'html',
      custom:[
        { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
      //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
      ],         
      width:'15%',
      position: 'right'
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

settingsPlazas = {
  columns:{
    institucion:{
      title:'Institución',
      editable:false,         
      width:'40%'
    },
    estado:{
      title:'Estado',
      editable:false,         
      width:'10%'
    },
    periodo:{
      title:'Periodo',
      editable:false,         
      width:'20%'
    },
    disponible:{
      title:'Disponible',
      editable:false,         
      width:'10%'
    },
    tipoasginada:{
      title:'Tipo',
      editable:false,         
      width:'10%'
    }
  },
  actions:{
    add:false,
    edit:false,
    delete:false,
    columnTitle:'',
    type:'html',
    custom:[
      { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-list-alt " ></i></a>'},
    //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
    ],         
    width:'15%',
    position: 'right'
  },
  pager:{
    perPage:15
  },
  noDataMessage:'Sin datos disponibles.'
  }
  cierreForm= new FormGroup({
    periodoid: new FormControl(0, [Validators.required]),
    planestudioid: new FormControl(0, [Validators.required]),
    semestre: new FormControl('', [Validators.required])
  });
  
  listDocAlu;
  planesactivos = [];
  periodosescolares = [];
  solicitudes = [];
  plazasDisponbles = [];
  plazasDisponblesF = [];
  banderaSolicitudes: boolean = true;
  banderaPlazas : boolean = true;
  alumno = '';
  matricula = '';
  estatdoActual = '';
  solicitudid = 0;
  respuesta = {
    codigo:'',
    mensaje:'',
    detalle:''
  }
  per;
  plan;
  src;
  institucion = '';
  estado = '';
  periodo = '';
  tipo = '';  
  plazaid = 0;
  type = FileMimeType.PDF;

  semestres=[];
  bandera:boolean =true;

  constructor(private consultas: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaPeriodos();
    this.consultar();
  }

  cerrarAviso(){
    $('#aviso1').modal('hide');
  }
  cierre(){
    //console.log(this.plan.value);
    //console.log(this.per.value);
    this.cierreForm.get('periodoid').patchValue(this.per.value);
    this.cierreForm.get('planestudioid').patchValue(this.plan.value);
    $('#cierre').modal('show');
    //console.log("entro");
  }

  confirmacion(){
    $('#cierre').modal('hide');
    $('#confirmacion').modal('show');
  }
  enviarCierre(){
    
    //console.log(this.cierreForm.value);
    this.consultas.cierreAsigancion(this.cierreForm.value).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        this.cierreForm.get('semestre').patchValue('');
        $('#respuesta').modal('show');
        $('#confirmacion').modal('hide');
      }
    })
    
  }
  asignar(){
    $('#aviso1').modal('hide');
    let parametros = {
      matriculap: this.maestro.matricula,
      plazaid: this.plazaid,
      solicitudid: this.solicitudid,
      tipoasignada: this.tipo
    }
    this.consultas.asignarPlazaAlumno(parametros).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        $('#respuesta').modal('show');
        this.consultarAlumnos();
      }
    })
  }

  asignarPlaza(event){
    console.log(event);
    this.institucion = event.data.institucion;
    this.estado = event.data.estado;
    this.periodo = event.data.periodo;
    this.tipo = event.data.tipoasginada;
    this.plazaid = event.data.plazaid;
    $('#aviso1').modal('show');
  }

  abriracciones(event){
    //console.log(event);
    this.alumno = event.data.nombrecompleto;
    this.matricula = event.data.matricula;
    this.estatdoActual = event.data.estatus;
    this.solicitudid = event.data.id;
    $('#acciones').modal('show');
  }

  asignarPlazaModal(){
    this.consultarPlazasDisponibles();
    $('#asignarplaza').modal('show');
  }

  consultarPlazasDisponibles(){
    this.plazasDisponblesF = [];
    this.banderaPlazas = true;
    let periodo = <HTMLSelectElement>document.getElementById('periodo');
    let parametros = {
      matriculap: this.maestro.matricula,
      periodoid:periodo.value,
      disponible: 'SI',
      solicitudid:this.solicitudid
    }
    this.consultas.listadoPlazas(parametros).subscribe({
      next:(res: any) =>{
        this.plazasDisponbles = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        for(var i = 0; i < this.plazasDisponbles.length; i++){
          let json = {
            alumnoid: this.plazasDisponbles[i].alumnoid,
            alumnoplanestudioid:this.plazasDisponbles[i].alumnoplanestudioid,
            asignacionid: this.plazasDisponbles[i].asignacionid,
            disponible: this.plazasDisponbles[i].disponible,
            estado: this.plazasDisponbles[i].estado,
            estadoid: this.plazasDisponbles[i].estadoid,
            institucion: this.plazasDisponbles[i].institucion,
            instituicionid: this.plazasDisponbles[i].institucionid,
            periodo: this.plazasDisponbles[i].periodo,
            periodoid: this.plazasDisponbles[i].periodoid,
            planestudioid: this.plazasDisponbles[i].planestudioid,
            plazaid: this.plazasDisponbles[i].plazaid,
            tipoasginada: (this.plazasDisponbles[i].tipoasignada == 'NO')?'NO ASIGNADA':'ASIGNADA',
            solicitudid: this.plazasDisponbles[i].solicitudid,
            // planestudio:(this.plazasDisponbles[i].planestudio == undefined || this.plazasDisponbles[i].planestudio == null)?'NO ASIGNADO':this.plazasDisponbles[i].planestudio
          }
          this.plazasDisponblesF.push(json);
        }
        this.banderaPlazas = false;
        // console.table(this.plazasDisponbles);
      }
    })
  }

  cambiarEstadoModal(){
    $('#estadoModal').modal('show');
    let estado  = <HTMLSelectElement>document.getElementById('estatus');
    if(this.estatdoActual == 'REVISIÓN'){
      estado.selectedIndex = 1;
    }
    if(this.estatdoActual == 'ACEPTADA'){
      estado.selectedIndex = 2;
    }
    if(this.estatdoActual == 'RECHAZADA'){
      estado.selectedIndex = 3
    }
    if((this.estatdoActual == '') || (this.estatdoActual == undefined)){
      estado.selectedIndex = 0
    }
  }

  cambiarEstado(){
    let estado  = <HTMLSelectElement>document.getElementById('estatus');
    let comentario  = <HTMLSelectElement>document.getElementById('comentario');
    let parametros = {
      matriculap:this.maestro.matricula,
      solicitudid: this.solicitudid,
      estado:estado.value,
      comentario: comentario.value
    }
    this.consultas.cambiarEstado(parametros).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        $('#respuesta').modal('show');
        comentario.value = ''; 
        this.consultarAlumnos();
      }
    });
  }

  consultarAlumnos(){
    this.per = <HTMLSelectElement>document.getElementById('periodo');
    this.plan = <HTMLSelectElement>document.getElementById('planestudio');
    let periodo = <HTMLSelectElement>document.getElementById('periodo');
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    if(periodo.value == ''){

    }
    if((periodo.value != '') && (plan.value != '')){
      this.consultarSolicitudes(periodo.value, plan.value);
    }
  }

  consultarSolicitudes(periodo, plan){
    let estado = <HTMLSelectElement>document.getElementById('estado');
    let paramtros = {
      periodoid:parseInt(periodo),
      planestudioid:parseInt(plan),
      matriculap:this.maestro.matricula,
      estado:estado.value
    }
    this.consultas.consultarsolicitudes(paramtros).subscribe({
      next:(res: any) =>{
        this.solicitudes = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        this.banderaSolicitudes = false;
      }
    })
  }

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next:(res: any) =>{
        this.periodosescolares = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{

      }
    });
  }

  consultar(){
    this.consultas.listaCarreras(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.planesactivos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
  verDoc(){
    let jason = {
      id:this.solicitudid
    }
    this.consultas.listadoDocSol(jason).subscribe({
      next:(res: any) => {
        this.listDocAlu = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#documentosSolicitud').modal('show');
        //console.log(this.listDocAlu);
      }
    });
  }

  documento(id){
    this.src = '';
    this.bandera = true;
    this.consultas.Document(id).subscribe({
      next:(res: ArrayBuffer) =>{
        this.src = new Uint8Array(res);
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{

        $('#documento').modal('show');
        this.bandera = false;
      }
    });
  }

  semestre(){
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    this.planesactivos.forEach(x => {
      if(plan.value == x.idplanestudio){
        this.semestres=[
          { 
            tipo:'INTERNADO',
            semestre:x.semestre + 1
          },
          {
            tipo:'SERVICIO SOCIAL',
            semestre: x.semestre + 3 
          }
        ]
      }
    });
    console.log(this.semestres );
  }
  cerrarAsigacion(){
    
  }
}
