import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalAnimation, IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
import { AuditoriaService } from '../services/auditoria.service';

@Component({
  selector: 'app-auditoria-login-personal',
  templateUrl: './auditoria-login-personal.component.html',
  styleUrls: ['./auditoria-login-personal.component.css']
})
export class AuditoriaLoginPersonalComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  busqueda = new FormGroup({
    matricula:new FormControl('', Validators.required)
  });

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };
  
  settings = {
    columns : {
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'25%'
      },
      nombre:{
        title:'Nombre',
        editable:false,
        width:'25%'
      },
      ip:{
        title:'Dirección IP',
        editable:false,
        width:'25%'
      },
      fecha:{
        title:'Fecha Hora',
        editable:false,
        width:'25%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30
    },
    noDataMessage:'Sin auditoria disponible.'
  }

  fechainicio;
  fechainicio_ISO;
  fechaI: IMyDateModel = null;
  fechafin;
  fechafin_ISO;
  fechaF: IMyDateModel = null;
  idperiodo;
  periodosescolares = [];
  periodosescolaresfiltrados = [];
  auditoria = [];
  auditoriafiltrada = [];
  bandera:boolean = true;
  bandera2:boolean = true;

  constructor(private auditoriaservice: AuditoriaService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }

  buscar(){
    this.auditoria = [];
    this.bandera2 = false;
    this.bandera = true;
    var jason = {
      matricula:this.busqueda.get('matricula').value,
      ffin:this.fechafin_ISO,
      finicio:this.fechainicio_ISO
    }
    this.auditoriaservice.consultaraccesoP(jason).subscribe({
      next:(data : any) => {
        this.auditoria = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.bandera = false;
      }
    });
  }

  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    if(periodoid.value == ""){

    }else{
      this.idperiodo = periodoid.value;
    }
  }

  fechainicioF(event){
    this.fechafin = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechafin_ISO = moment(date).format('YYYY-MM-DD');
  }

  fechainicioI(event){
    this.fechainicio = event.singleDate.formatted;
    let date = `${event.singleDate.date.year}-${event.singleDate.date.month}-${event.singleDate.date.day}`
    this.fechainicio_ISO = moment(date).format('YYYY-MM-DD');
  }

}
