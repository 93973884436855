<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h2>Auditoría de Materias del Alumnos </h2> 
    <p>En esta auditoría se muestran los movimientos de las materias de los alumnos hechos por el personal . </p>

    <p><small class="text-danger">Datos obligatorios (*)</small></p>
    <div class="row">
        <div class="col-3">
            <label>Fecha Inicio</label><em class="text-danger">*</em>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="fechaI" [options]="myDpOptions" 
                    (dateChanged)="fechainicioI($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
            <div class="col-3">
            <label>Fecha Fin</label><em class="text-danger">*</em>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha Fin" 
                    angular-mydatepicker #dp2="angular-mydatepicker" 
                    [(ngModel)]="fechaF" [options]="myDpOptions" 
                    (dateChanged)="fechainicioF($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
            <div class="col-3">
                <label for="matricula">Matrícula del Personal</label>
                    <div class="form-group pr-3 mb-2">
                        <input type="text" class="form-control" [formControl]="busqueda.get('matriculap')" id=matricula placeholder="Matrícula">
                    </div>
            </div>
            <div class="col-3">
            <label for="matricula">Matrícula del alumno</label>
            <form class="form-inline">
                <div class="form-group pr-3 mb-2">
                    <input type="text" class="form-control" [formControl]="busqueda.get('matricula')" id=matricula placeholder="Matrícula">
                </div>
                <button class="btn btn-danger mb-2" [disabled]="busqueda.invalid" (click)="buscar()">Buscar</button>
                </form>
            </div>
    </div>
    <div *ngIf="!bandera2">
        <div class="row" *ngIf="!bandera">
            <div class="col-12">
                <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" [settings]="settings" [source]="auditoriafiltrada"></ng2-smart-table>
            </div>
        </div>
        <div class="row" *ngIf="bandera">
            <div class="col-12">
                <div class="text-center">
                    <div class="spinner-border text-danger" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="detalle" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="my-modal-title" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="my-modal-title">Title</h5>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Content</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secundary" data-dismiss="modal" aria-label="Close">
                        cerrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</body>

</html>