import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-instituciones',
  templateUrl: './instituciones.component.html',
  styleUrls: ['./instituciones.component.css']
})
export class InstitucionesComponent implements OnInit {

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings = {
    columns:{
    institucion:{
      title:'Institucion',
      editable:false,         
      width:'30%',
    },
    nombrecorto:{
       title:'Nombre Corto',
       editable:false,         
       width:'20%'
     },
     contacto:{
       title:'Contacto',
       editable:false,         
       width:'20%'
     },
     estado:{
       title:'Estado Republica',
       editable:false,         
       width:'20%'
     }
   },
   actions:{
     add:false,
     edit:false,
     delete:false,
     columnTitle:'',
     type:'html',
     custom:[
       { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-pencil-alt " ></i></a>'},
      //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
     ],         
     position: 'right'
   },
   pager:{
     perPage:10
   },
   noDataMessage:'Sin datos disponibles.'
 }

  formInstitucion= new FormGroup({
    institucionid: new FormControl(0),
    institucion: new FormControl('',[Validators.required])
    ,nombrecorto: new FormControl('',)
    ,contacto: new FormControl('',)
    ,estadoid: new FormControl(0,[Validators.required])
    ,estado: new FormControl('',[Validators.required])
    ,telefono: new FormControl('',)
    ,matriculap: new FormControl('', [Validators.required])
  });
  
  estadosRepublica = [];
  periodosescolares = [];
  instituciones = [];
  respuesta = {
    codigo: '',
    mensaje: '',
    detalle: ''
  }

  constructor(private consultas: ServicioSocialService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaInstituciones();
    this.listaPeriodos();
    this.listaEstados(0);
  }

  guardar(){
    for(var i = 0; i < this.estadosRepublica.length; i++){
      if(this.estadosRepublica[i].estadoid == this.formInstitucion.get('estadoid').value){
        this.formInstitucion.get('estado').patchValue(this.estadosRepublica[i].estado);
      }
    }
    this.formInstitucion.get('matriculap').patchValue(this.maestro.matricula);
    this.consultas.asignarInstituciones(this.formInstitucion.value).subscribe({
      next:(res: any) =>{
        this.respuesta = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        $('#respuesta').modal('show');
        this.listaInstituciones();
      }
    })
  }

  editar(datos){
    this.formInstitucion.patchValue(datos);
    $('#modalInstitucion').modal('show');
  }

  accion(valores){
    console.log(valores); 
    switch (valores.action)
    {
      case "editar":
        this.editar(valores.data);
          break;
      case "borrar": 
          break;
      default: 
      
        break;
    }
  }

  listaInstituciones(){
    this.consultas.listaInstituciones(this.maestro.matricula, 0).subscribe({
      next:(res: any) =>{
        this.instituciones = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
      }
    });
  }

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next:(res: any) =>{
        this.periodosescolares = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
      }
    });
  }

  listaEstados(idedo:number){
    this.consultas.listaEstadosRepublica(this.maestro.matricula,idedo).subscribe({
      next:(res: any) =>{
        this.estadosRepublica = res;
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
      }
    });
  }

}
