<div class="container-fluid">
    <div class="row">
        <div class="form-group col-6">
            <input type="text" class="form-control" id="dato" placeholder="Ingrese Matrícula o Nombre de Alumno">
        </div>
        <div class="form-group col-6">
            <div class="col">
                <button class="btn btn-danger mr-1"  (click)="buscaralumno()"><i id="icon" class="fas fa-search"  title="Buscar"></i>Buscar</button>
                <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Activar Filtros</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="collapse" id="collapseExample">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                            <label for="plan">Plan de Estudio</label>
                            <select class="form-control" id="plan">
                                <option value="">--Plan de Estudios--</option>
                                <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="semestre">Semestre</label>
                                <select class="form-control" id="semestre">
                                <option value="">--Semestre--</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="regular">Regular</label>
                                <select class="form-control" id="regular">
                                <option value="">--Regular--</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="estatus">Estatus</label>
                                <select class="form-control" id="estatus">
                                <option value="">--Estatus--</option>
                                <option value="PROCESO">PROCESO</option>
                                <option value="REVISIÓN">REVISIÓN</option>
                                <option value="CONCLUIDO">CONCLUIDO</option>
                                <option value="BAJA">BAJA</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="periodoingreso">Período Escolar Ingreso</label>
                                <select class="form-control" id="periodoingreso">
                                    <option value="">--Período Escolar--</option>
                                    <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
                                </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="periodoegreso">Período Escolar Egreso</label>
                                    <select class="form-control" id="periodoegreso">
                                        <option value="">--Período Escolar--</option>
                                        <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
                                    </select>
                                </div>
                            </div>               
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                            <label for="periodobaja">Período Escolar Baja</label>
                            <select class="form-control" id="periodobaja">
                                <option value="">--Período Escolar--</option>
                                <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
                            </select>
                            </div>
                        </div>             
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng2-smart-table (userRowSelect)="abirtareas($event)" class="table table-hover table-borderless table-striped"  [settings]="settings" [source]="alumnos"></ng2-smart-table>
        </div>
    </div>
</div>

<div class="modal fade" id="t" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
            Matrícula: {{ alumno.matricula  }}<br> {{ alumno.nombrecompleto }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="cambiarStatusModal()">Cambiar Status</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="tomarCalificaciones()">Calificar</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="reinscribir()">Reinscribir</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="subirDocumento()" *ngIf="alumno.estatus == 'CONCLUIDO'">Subir Documentos</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-danger btn-block mb-1" (click)="formatoLiberacion()">Formato Liberación</button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="fomratos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
                Generar Formato de Liberación<br>
                <strong>Alumno: </strong>{{ alumno.nombrecompleto }}<br> <strong>Matrícula: </strong>{{ alumno.matricula }} <strong>Semestre: </strong>{{ alumno.semestre }}
                <strong>Carrera: </strong>{{ alumno.carrera }}
            </h5>
            <button type="button" (click)="formatoLiberacionSalir()" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banedraformato">
                <app-formato-liberacion [alumno]="alumno"></app-formato-liberacion>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="formatoLiberacionSalir()">Salir</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="docs" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
                Subir Documentos<br>
                <strong>Alumno: </strong>{{ alumno.nombrecompleto }}<br> <strong>Matrícula: </strong>{{ alumno.matricula }} <strong>Semestre: </strong>{{ alumno.semestre }}
                <strong>Carrera: </strong>{{ alumno.carrera }}
            </h5>
            <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderadocs">
                <app-subir-documentos-alumnos [alumno]="alumno"></app-subir-documentos-alumnos>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="subirDocumentoSalir()">Salir</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="reincribir" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
                Periodo de reincripción
            </h5>
            <button type="button" class="close" (click)="reinscribirCerrar()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="periodoegreso">Período Escolar Egreso</label>
                        <select class="form-control" [formControl]="perForm.get('per')" (change)="cambioperiodo()" id="periodoegreso1">
                            <option value="">--Período Escolar--</option>
                            <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
                        </select>
                    </div>
                </div> 
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="reinscribirCerrar()">Salir</button>
            <button type="button" class="btn btn-danger" [disabled]="perForm.invalid" (click)="enviarReinscripcion()">Guardar</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="status" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
            Matrícula: {{ alumno.matricula  }}<br> {{ alumno.nombrecompleto }}
            </h5>
            <button type="button" class="close" (click)="cambiarStatusModalCerrar()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group" [formGroup]="statusForm">
                        <label for="periodobaja">Estatus Actual</label>
                        <select [formControl]="statusForm.get('status')" class="form-control" id="periodobaja">
                            <option value="">--Estatus--</option>
                            <option value="REINGRESO">REINGRESO</option>
                            <option value="BAJA">BAJA</option>
                            <option value="CONCLUIDO">CONCLUIDO</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="cambiarStatusModalCerrar()">Salir</button>
            <button type="button" class="btn btn-danger" [disabled]="statusForm.invalid" (click)="cambiarEstado()">Guardar</button>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="capturarcalificaciones" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
            Matrícula: {{ alumno.matricula  }}<br> {{ alumno.nombrecompleto }}
            </h5>
            <button type="button" class="close" (click)="tomarCalificacionesModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderacalificaciones">
                <app-capturar-calificaciones [id]="alumno.id" [alumnoplanestudioid]="alumno.alumnoplanestudioid"></app-capturar-calificaciones>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="tomarCalificacionesModal()">Salir</button>
        </div>
        </div>
    </div>
</div> 

<div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">
                ¡Atención!
            </h5>
            <button type="button" class="close" (click)="cerrarRespuesta()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    {{ respuesta.mensaje }}
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="cerrarRespuesta()">Salir</button>
        </div>
        </div>
    </div>
</div>