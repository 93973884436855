import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { SharedService } from '../shared.service'

@Component({
  selector: 'app-navbar-izq',
  templateUrl: './navbar-izq.component.html',
  styleUrls: ['./navbar-izq.component.css']
})
export class NavbarIzqComponent implements OnInit { 

  currentApplicationVersion = environment.appVersion;
  dropCatalogos = false;
  roles = [];
  filtrado = [];

  menu = [
    { title: 'Inicio', path:'/inicio', icon:'fas fa-home', disabled: false, hidden: true, rol: 10001 },
    { title: 'Mis Grupos', path:'/docentes/materias', icon:'fas fa-chalkboard-teacher', disabled: false, hidden: true, rol: 10002 },
    { title: 'Materias', path:'/materias', icon:'fas fa-book', disabled: false, hidden: true, rol: 10015 },
    { title: 'Calendarios', path:'/calendario/pdf', icon:'fas fa-calendar-alt', disabled: false, hidden: true, rol: 10012 },
    { title: 'Personal', path:'/personal', icon:'fas fa-user-friends', disabled: false, hidden: true, rol: 10059 }, 
    { title: 'Alumnos', path:'/alumnos/alumnos-inicio', icon:'fas fa-user-friends', disabled: false, hidden: true, rol: 10029 },
    { title: 'Cierre de Semestre', path:'/cierre/inicio', icon:'fas fa-door-closed', disabled: false, hidden: true, rol: 10069 },
    { title: 'Configuración Escolares ', path:'/configuracionesSE', icon:'fas fa-cog', disabled: false, hidden: true, rol: 10157 },
    { title: 'Auditorias', path:'/auditoria', icon:'fas fa-check-double', disabled:false, hidden: true, rol: 10134 },
    { title: 'Configuración General', path: '/configuracionesG', icon:'fas fa-cog', disabled:false, hidden: true, rol: 10153 },
    { title: 'Cuotas', path: '/coutas', icon: 'fas fa-dollar-sign', disabled:false, hidden: true , rol: 10154 },
    { title: 'Informes (Estadísticas)', path: '/informes/inicio', icon: 'fas fa-chart-area', disabled:false, hidden: true, rol:10145 },
    { title: 'SESCO', path:'/sesco', icon:'fas fa-user-check', disabled:false, hidden: true, rol:10128 },
    { title: 'Servicio Social', path:'/servicio-social/plazas', icon: 'fas fa-vials', disabled: false, hidden: true, rol:10208 },
    { title: 'Servicio Social Universitario', path:'/servicio-social-universitario/listado', icon: 'fas fa-vials', disabled: false, hidden: true, rol:10214 },
    { title: 'Examen Profesional', path:'/examen-profesional/list', icon: 'fas fa-stream', disable:false, rol:10215, hidden:true },
    { title: 'Mi Perfil', path:'/perfil/datos-personales', icon: 'fas fa-user', disabled: false, hidden: false },
  ];

  links = [
    { title: 'Inicio', path:'/inicio', icon:'fas fa-home', disabled: false, hidden: false },
    { title: 'Mis Grupos', path:'/docentes/materias', icon:'fas fa-chalkboard-teacher', disabled: false, hidden: true, rol:2 },
    { title: 'Calendarios', path:'/calendario/pdf', icon:'fas fa-calendar-alt', disabled: false, hidden: true, rol:2 },
    { title: 'Materias', path:'/materias/materias', icon:'fas fa-book', disabled: false, hidden: true, rol:100 },
    { title: 'Alumnos', path:'/alumnos/alumnos-inicio', icon:'fas fa-user-friends', disabled: false, hidden: true, rol:200 },
    { title: 'Personal', path:'/personal', icon:'fas fa-user-friends', disabled: false, hidden: true, rol:300 },
    // { title: 'Preactas', path:'/cierre/consultas', icon: 'fas fa-pen-fancy', disable: false, hidden: true, rol:700 },
    { title: 'Actas', path:'/cierre/actas', icon: 'fas fa-pen-fancy', disable: false, hidden: true, rol:800 },
    { title: 'Becas', path:'/becas', icon: 'fas fa-dollar-sign', disabled: false, hidden: true, rol:9999 },
    { title: 'Servicio Social Universitario', path:'/servicio-social-universitario/listado', icon: 'fas fa-vials', disabled: false, hidden: true, rol:1002 },
    { title: 'Servicio Social', path:'/servicio-social/plazas', icon: 'fas fa-vials', disabled: false, hidden: true, rol:1002 },
    { title: 'Período Escolar', path:'/admin-periodo/agregar-nuevo', icon:'fas fa-calendar-alt', disabled: false, hidden: true, rol:400 },
    { title: 'Plan de Estudios', path:'/plandeestudios/mapa', icon:'far fa-map', disabled: false, hidden: false },
    { title: 'Administración', path:'/administracion/aulas', icon:'fas fa-user-shield', disabled:false, hidden: true, rol:1000 },
    { title: 'SESCO', path:'/sesco/alumnos', icon:'fas fa-user-check', disabled:false, hidden: true, rol:1000 },
    { title: 'Auditoria', path:'/auditoria/inscripciones', icon:'fas fa-cog', disabled:false, hidden: true, rol: 1007 },
    { title: 'Valida C. Interna', path: '/validacion', icon: 'fas fa-user-check', disable: false, rol: 5, hidden: true},
    { title: 'Informes - Estadísticas', path:'/informes', icon: 'fas fa-chart-area', disable:false, hidden:true, rol:900 },
    { title: 'Trámites Pagos', path:'/tramites', icon: 'far fa-envelope', disable: false, rol: 500, hidden: true },
    { title: 'Trámites Escolares', path:'/tramites-escolares', icon: 'far fa-envelope', disable:false, rol:500, hidden:true },
    { title: 'Configuración General', path:'/configuracion-general', icon: 'fas fa-cogs', disable:false, rol:1007, hidden:true },
    //{ title: 'Notificaciones', path:'/notificaciones', icon:'fas fa-bell', disabled: false, hidden: false },
    { title: 'Mi Perfil', path:'/perfil/datos-personales', icon: 'fas fa-user', disabled: false, hidden: false },
    // { title: 'Generación', path:'/generacion', icon: 'fas fa-cog', disable:false, rol:600,hidden:false},
  ];

  catalogos = [
    {title: 'Clasificaciones', path:'/catalogo-clasificaciones'},
    {title: 'Aulas', path:'/aulas'},
    {title: 'Materias', path:'/catalogo-materias'},
    {title: 'Grupos', path:'/catalogo-grupos'},
    {title: 'Roles', path:' /catalogo-roles'},
    {title: 'Becas', path:'/catalogos/becas'}
  ]

    configuraciones =[
    // {title: 'Calendario Fechas', path:'/calendario-fechas'},
    {title: 'Calendario Exámenes', path:'/calendario-examenes'}
    ]

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }
  rolesN = [];
  rolesNFiltado = [];
  banderamenu:boolean = true;

  permisos = {
    documentos:""
  }
  constructor(private router: Router, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarroles();
  }

  

  consultarroles(){
  this.sharedService.buscarRol(this.maestro.matricula).subscribe({
    next:(res: any) => {
      this.rolesN = res;
      // localStorage.setItem('rol', JSON.stringify(res));
      // this.roles = JSON.parse(localStorage.getItem("rol"));
      // for(var i = 0; i < this.roles.length; i++){
      //   this.filtrado.push(this.roles[i].id);
      // }
      // localStorage.setItem("roles", JSON.stringify(this.filtrado));
    },
    error:() => {
      throw new Error("Error al buscar rol")
    },
    complete:() => {
      for(var z = 0; z < this.rolesN.length; z++){
          this.rolesNFiltado.push(this.rolesN[z].id)
      }
      // this.roles = JSON.parse(localStorage.getItem("rol"));
      // for(var i = 0; i < this.roles.length; i++){
      //   this.filtrado.push(this.roles[i].id);
      // }
      for(var x = 0; x < this.rolesNFiltado.length; x++){
        for(var y = 0; y < this.menu.length; y++){
          if(this.rolesNFiltado[x] == this.menu[y].rol){
            this.menu[y].hidden = false;
          }
        }
      }
      localStorage.setItem("roles", JSON.stringify(this.rolesNFiltado));
      this.banderamenu = false;
    }
  });
  }

  consultarpersmisodocumentos(){
    this.sharedService.verpersmisodocumentos(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.permisos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var y = 0; y < this.links.length; y++){
          if(this.links[y].path == '/subir-documentos'){
            if(this.permisos.documentos == "S"){
              this.links[y].hidden = false;
            }
          }
        }
      }
    });
  }

  logout(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  isDisabled(link: any){
    return link;
  }


}
