<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">   
                <div class="row" id="top">
                    <!-- --------------------------------------------modal ver detalles--------------------------------------------------------------------------- -->
                    <form [formGroup]='detailsform'>   
                    <div class="modal fade " id="modalDetailsMateriasForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        
                        <div class="modal-dialog modal-lg" role="document">
                          <div class="modal-content">
                            <table class="table table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <div class="modal-header text-center">
                                            <h4 class="modal-title w-100 font-weight-bold">Detalles de la materia</h4>
                                            <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                                <fieldset disabled>
                                                    <div class="form-row">
                                                        <div class="col-6">
                                                            <label for="disabledTextInput">Nombre</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('nombre')">
                                                        </div>
                                                        <div class="col-6">
                                                              <label for="disabledTextInput">Nombre corto</label>
                                                              <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('nombreCorto')">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-4">
                                                            <label for="disabledTextInput">Clave</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('clave')">
                                                        </div>
                                                        <div class="form-group col-4">
                                                            <label for="disabledTextInput">Creditos</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('creditos')">
                                                        </div>
                                                        <div class="form-group col-4">
                                                            <label for="disabledTextInput">Horas</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('horas')">
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-row">
                                                        <div class="form-group col-6">
                                                            <label for="disabledTextInput">Acreditada</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('acreditada')">
                                                        </div>
                                                        <div class="form-group col-6">
                                                            <label for="disabledTextInput">Practica</label>
                                                            <input type="text" id="disabledTextInput" class="form-control" [formControl]="this.detailsform.get('practica')">
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </fieldset>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                          </div>
                        </div>
                    </div>
                </form>                    
                    <!-- ----------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ------------------------------modal para editar materia------------------------------------------------------------ -->
                    <form [formGroup]='editform' (ngSubmit)="onFinishEdit()">
                        <div class="modal fade" id="modalEditMateriasForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <table class="table table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <div class="modal-header text-center">
                                            <h4 class="modal-title w-100 font-weight-bold">Editar Materia</h4>
                                            <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="modal-body mx-3">
                                                <div class="md-form mb-3">
                                                    <input data-toggle="tooltip" title="Nombre de la materia" type="text" class="form-control validate" [formControl]="this.editform.get('nombre')" id="nombreInputE" placeholder="Nombre de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <input data-toggle="tooltip" title="Nombre corto de la materia" type="text" class="form-control validate" [formControl]="this.editform.get('nombreCorto')" id="nombreCortoInputE" placeholder="Nombre corto de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <input data-toggle="tooltip" title="Clave de la materia"  type="text" class="form-control validate" [formControl]="this.editform.get('clave')" id="claveInputE" placeholder="Clave de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <input data-toggle="tooltip" title="Creditos de la materia"  type="text" class="form-control validate" [formControl]="this.editform.get('creditos')" id="creditosInputE" placeholder="Créditos">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <input  data-toggle="tooltip" title="Horas de la materia" type="text" class="form-control validate" [formControl]="this.editform.get('horas')" id="horasInputE" placeholder="Horas">
                                                </div>
                                                

                                                <div class="form-row">
                                                    <div class="form-group col-md-4">
                                                      <label for="practicaE">Practica</label>
                                                      <select id="practicaE" class="form-control">

                                                        <option [selected]="practicaModal" value='S' >Si</option>
                                                        <option [selected]="!practicaModal"  value='N' >No</option>
                                                      </select>
                                                    </div>
                                                    <div class="form-group col-md-4" >
                                                        <label for="acreditadaE">Acreditada</label>
                                                        <select id="acreditadaE" class="form-control" >
                                                            <option [selected]="acreditadaModal" value='S' >Si</option>
                                                            <option [selected]="!acreditadaModal"  value='N' >No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                              </div>
                                              <div class="md-form mb-4">
                                                <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                <button [disabled]='!editform.valid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                                
                                              </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                          </div>
                        </div>
                      </div>
                     </form>
                 
                    <!-- ------------------------------modal para crear materia------------------------------------------------------------ -->
                    <form [formGroup]='createForm' (ngSubmit)="onCreate()">
                        <div class="modal fade" id="modalCreateMateriasForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <table class="table table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <div class="modal-header text-center">
                                            <h4 class="modal-title w-100 font-weight-bold">Crear Materia</h4>
                                            <button (click)='vaciarModal()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="modal-body mx-3">
                                                <div class="md-form mb-3">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre de la materia</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('nombre')" id="nombreInputN" placeholder="Nombre de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Nombre corto</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('nombreCorto')" id="nombreCortoInputN" placeholder="Nombre corto de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Clave de la materia</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('clave')" id="claveInputN" placeholder="Clave de la materia">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Creditos</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('creditos')" id="creditosInputN" placeholder="Créditos">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Horas</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('horas')" id="horasInputN" placeholder="Horas">
                                                </div>
                                                <!-- <div class="md-form mb-3">
                                                    <label data-error="wrong" data-success="right" for="defaultForm-email">Practica</label>
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('practica')" id="practicaInputN" placeholder="Practica">
                                                </div>
                                                <div class="md-form mb-3">
                                                    <label data-error="wrong" data-success="right" for="defaultForm-email">acreditada</label>
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('acreditada')" id="acreditadaInputN" placeholder="Acreditada">
                                                </div> -->

                                                <div class="form-row">
                                                    
                                            
                                                    <div class="form-group col-md-4" >
                                                        <label for="acreditada">Acreditada</label>
                                                        <select id="acreditada" class="form-control"  >
                                                            <option [selected]="acreditadaModal" value='S' >Si</option>
                                                            <option [selected]="!acreditadaModal"  value='N' >No</option>                                                        
                                                         </select>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label for="practica">Practica</label>
                                                        <select id="practica" class="form-control">
                                                          <option [selected]="practicaModal"  value='S' >Si</option> 
                                                          <option [selected]="!practicaModal" value='N' >No</option>
                                                        </select>
                                                      </div>
                                                </div>
                                              </div>
                                              <div class="md-form mb-4">
                                                <button (click)='vaciarModal()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                <button [disabled]='createForm.invalid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                               
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                          </div>
                        </div>
                      </div>
                     </form>
                </div> 

                <!-- ----------------------------------------lista de materias---------------------------------------------------------------------- -->
                <div class="row" id="top">
                    <div id="lista" class="col-lg-12">
                        <h2>Materias</h2>

                        <form (ngSubmit)="buscarMateria()" [formGroup]="busqueda">
                            <div class="form-row">
                                    <div class="col">
                                        <div class="form-group">
                                            <input type="text" class="form-control" [formControl]="this.busqueda.get('nombre')" id="matricula" placeholder="Nombre o Clave">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <button  class="btn btn-danger"><i class="fas fa-search"></i>Buscar</button>
                                        </div>
                                    </div>
                            </div>
                        </form>

                        <table class="table table-sm table-striped table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
        
                                    <th scope="col">Clave</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col">
                                        <button (click)='vaciarModal()' data-target="#modalCreateMateriasForm" data-toggle="modal" class="text-light" id="btn-add-aula"><i id="plus" class="fas fa-plus"></i></button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let materia of response | slice:0:30">
                                    <td scope="col">{{materia.clave}}</td>
                                    <td scope="col">{{materia.nombre}}</td>

                                    <td>
                                        <button (click)="onEdit(materia.id,materia.nombre, materia.nombrec,materia.clave, materia.creditos, materia.practica, materia.acreditada,materia.horas)"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
        
                                    </td>
                                    <td scope="col" *ngIf="materia.relacion==1">
                                        <button data-toggle="tooltip" title="Esta materia no puede ser eliminada, tiene relación con un plan de estudios " [disabled]=true class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                    </td>
                                    <td scope="col" *ngIf="materia.relacion==0">
                                        <button (click)='onDelete(materia.id, materia.nombre)' class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                    </td>
                                    <td>
                                        <button (click)="onDetails(materia.nombre, materia.nombrec,materia.clave, materia.creditos, materia.practica, materia.acreditada,materia.horas)" class="btn btn-danger btn-sm" ><i class="fas fa-list"></i>Detalles</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
</body>
</html>