import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class ExamenProfesionalService {

  constructor(private http: HttpClient, private global: Global) { }

  listaperiodosescolares(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }

  listarcarrara(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/actas/carrera/${matriculap}`, null)
  }


}
