import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { ActivatedRoute } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-baja-materias',
  templateUrl: './baja-materias.component.html',
  styleUrls: ['./baja-materias.component.css']
})
export class BajaMateriasComponent implements OnInit {

  materiasCursando = [
    { alumno:{
      apllidom:'',
      apellidop:'',
      estatus:'',
      matricula:'',
      nombres:'',
      regular:'',
      semestre:''
      },
      calificacion:Number,
      estatus:'',
      grupo:{
        alias:'',
        id:Number,
        maximo:Number,
        mpe:{
          cantidadrenglones:Number,
          clave:'',
          cuentapromedio:Number,
          electiva:Number,
          id:Number,
          materia:{
            acreditada:'',
            clave:'',
            creditos:'',
            horas:'',
            id:Number,
            nombre:'',
            nombrec:'',
            practica:''
          },
        orden:Number,
        renglon:Number,
        repetida:Number,
        semestre:Number,
        tipocalificacion:'',
        visible:Number
        },
      nombre:''
      },
    id:Number 
    }
  ]

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  bandera = true;
  idMpe = '';
  bandera2 = true;
  response = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  constructor(private route: ActivatedRoute, private personalService: PersonalService) { }

  ngOnInit(): void {
    const matriculaAlumno = this.route.snapshot.paramMap.get('matriculaAlumno');
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    var jason = {
      dato:matriculaAlumno,
      matriculap:this.maestro.matricula
    }
    this.personalService.listarMateriasXAlumno(jason).subscribe({
      next:(res: any) => {
        this.materiasCursando = res;
      },
      complete:() => {
        this.bandera = false;
      },
      error:(err) => {
        console.log(err)
      }
    });
  }

  primerAviso(id){
    this.idMpe = id;
    $('#staticBackdrop').modal('show')
  }

  segundoAviso(){
    $('#staticBackdrop').modal('hide')
    setTimeout(() => {
      $('#staticBackdrop2').modal('show')
    }, 1000)
  }

  eliminarMateria(){
    const matriculaAlumno = this.route.snapshot.paramMap.get('matriculaAlumno');
    var jason = {
      matricula: matriculaAlumno,
      matriculap: this.maestro.matricula,
      mpe: this.idMpe
    }
    this.personalService.eliminarMateriasXAlumno(jason).subscribe({
      next:(res: any) => {
        this.response = res;
      },
      complete:() => {
        $('#staticBackdropResult').modal('show')
      },
      error:(err) => {
        console.log(err);
      }
    });

    this.bandera2 = true;

    var jason2 = {
      dato:matriculaAlumno,
      matriculap:this.maestro.matricula
    }

    setTimeout(() => {
      this.personalService.listarMateriasXAlumno(jason2).subscribe({
        next:(res: any) => {
          this.materiasCursando = res;
        },
        complete:() => {
          this.bandera = false;
        },
        error:(err) => {
          console.log(err);
        }
      });
    }, 1500);

  }

}
