<body>
    <div >
        <h2>Grupos</h2>
    </div>
    <div class="row" id="top">
       
        <div class="col-3">
            Período 
          <div class="form-group">
            <select class="form-control" id="periodoescolar" [formControl]="gruposPC.get('periodoid')" >
              <option>-- Períodos Escolares --</option>
              <option *ngFor="let periodo of listaperiodosCompleta" value="{{ periodo.id }}">{{ periodo.periodo}}</option>
            </select>
          </div>
        </div>
        <div class="col-3" >
            Carrera 
          <div class="form-group">
              <select class="form-control" id="carrera" [formControl]="gruposPC.get('carreraid')" >
                <option>-- Carreras --</option>
                <option *ngFor="let carrera of listacarrerasacrear" value="{{ carrera.idplanestudio }}">{{ carrera.carrera }}</option>
              </select>
            </div>
      </div>
      <div class="col-3">
        -
        <div class="form-group">
            <button (click)='gruposPeriodoCarrera()' [disabled]='!gruposPC.valid' class="btn btn-danger"><i class="fas fa-search"></i>Buscar</button>
        </div>
    </div>
    </div>
    <div class="row">
        <div class="col-12">
            
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active" id="nav-grupos-tab" data-toggle="tab" href="#grupos-home" role="tab" aria-controls="grupos-home" aria-selected="true">Grupos</a>
                  <a class="nav-item nav-link" id="nav-empalmes-tab" data-toggle="tab" href="#empalmes" role="tab" aria-controls="empalmes" aria-selected="true" (click)="empalmegruposmodal()" >Empalme de grupos</a>
                  <a class="nav-item nav-link" id="nav-cupos-tab" data-toggle="tab" href="#cupos-home" role="tab" aria-controls="cupos-home" aria-selected="true" (click)="agrearlugaresmasivomodal()">Modificar cupo a todos los grupos</a>
                  <a class="nav-item nav-link" id="nav-cuposm-tab" data-toggle="tab" href="#cuposm-home" role="tab" aria-controls="cuposm-home" aria-selected="true" (click)="agreguarlugaresmateriamodal()">Modificar cupo a todos los grupos de una materia específica</a>
                  <a class="nav-item nav-link" id="nav-crear-tab" data-toggle="tab" href="#crear-profile" role="tab" aria-controls="crear-profile" aria-selected="false" (click)="creargruposmodal()">Crear</a>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                </div>
              </div>
            <ng2-smart-table class="table table-hover table-borderless" (userRowSelect)="abrirgrupo($event)" [settings]="settings" [source]="listagrupos"></ng2-smart-table>
        </div>
    </div>

    <div class="modal fade" id="grupo" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-body">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="editar-tab" data-toggle="tab" href="#editar" role="tab" aria-controls="editar" aria-selected="true">Editar</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="horarios-tab" data-toggle="tab" href="#horarios" role="tab" aria-controls="horarios" aria-selected="true">Horarios</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="agregarhorario-tab" data-toggle="tab" href="#agregarhorario" role="tab" aria-controls="agregarhorario" aria-selected="true" (click)="agregarhorariomodal()">Añadir Horario</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="alu-tab" data-toggle="tab" href="#alumnos" role="tab" aria-controls="alumnos" aria-selected="true">Alumnos Intersemestral</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="editar" role="tabpanel" aria-labelledby="editar-tab">
                        <form [formGroup]="grupoindividual">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="materia">Materia</label>
                                        <input disabled [formControl]="grupoindividual.get('nombremateria')" type="text" class="form-control" id="materia" aria-describedby="materia">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="carrera">Carrera</label>
                                        <input disabled [value]="grupoindividual.get('carrera').value+' - ('+grupoindividual.get('plandeestudio').value+')'" type="text" class="form-control" id="carrera" aria-describedby="carrera">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="idgrupo">idGrupo</label>
                                        <input disabled [formControl]="grupoindividual.get('idgrupo')" type="text" class="form-control" id="idgrupo" aria-describedby="idgrupo">
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="semestre">Semestre</label>
                                        <input disabled [formControl]="grupoindividual.get('semestre')" type="number" class="form-control" id="semestre" aria-describedby="semestre">
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="grupo">Grupo</label>
                                        <input disabled [formControl]="grupoindividual.get('grupo')" type="text" class="form-control" id="grupo" aria-describedby="grupo">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="periodoescolar">Período Escolar</label>
                                        <input disabled [formControl]="grupoindividual.get('periodo')" type="text" class="form-control" id="periodoescolar" aria-describedby="periodoescolar">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="alias">Alias</label>
                                        <input [formControl]="grupoindividual.get('alias')" type="text" (blur)="showOrden()" class="form-control" id="alias" aria-describedby="alias">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="orden">Orden de foliación</label>
                                        <input [formControl]="grupoindividual.get('orden')" min="0" type="number" class="form-control" id="orden" aria-describedby="orden">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="maestro">Maestro</label>
                                        <select class="form-control" id="maestro" [formControl]="grupoindividual.get('matriculamaestro')">
                                          <option *ngFor="let maestro of listamaestros" [value]="maestro.id">{{ maestro.persona.apellidop }} {{ maestro.persona.apellidom }} {{ maestro.persona.nombres }}</option>
                                        </select>
                                        <small *ngIf="grupoindividual.get('firmado').value >= 1" class="text-danger">Grupo se encuentra cerrado para edición!</small>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="mregulares">Máximo Regulares</label>
                                        <input [formControl]="grupoindividual.get('maximo')" min="0" type="number" class="form-control" id="mregulares" aria-describedby="mregulares">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="mirregulares">Máximo Irregulares</label>
                                        <input [formControl]="grupoindividual.get('maximoi')" min="0" type="number" class="form-control" id="mirregulares" aria-describedby="mirregulares">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-danger mr-2 float-right" (click)="editargrupo()">Guardar</button>
                                    <button class="btn btn-secondary mr-2 float-right" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="horarios" role="tabpanel" aria-labelledby="horarios-tab">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materia">Materia</label>
                                    <input disabled [value]="grupoindividual.get('nombremateria').value" type="text" class="form-control" id="materia" aria-describedby="materia">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="carrera">Carrera</label>
                                    <input disabled [value]="grupoindividual.get('carrera').value+' - ('+grupoindividual.get('plandeestudio').value+')'" type="text" class="form-control" id="carrera" aria-describedby="carrera">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="idgrupo">idGrupo</label>
                                    <input disabled [value]="grupoindividual.get('idgrupo').value" type="text" class="form-control" id="idgrupo" aria-describedby="idgrupo">
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="semestre">Semestre</label>
                                    <input disabled [value]="grupoindividual.get('semestre').value" type="text" class="form-control" id="semestre" aria-describedby="semestre">
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="grupo">Grupo</label>
                                    <input disabled [value]="grupoindividual.get('grupo').value" type="text" class="form-control" id="grupo" aria-describedby="grupo">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="periodoescolar">Período Escolar</label>
                                    <input disabled [value]="grupoindividual.get('periodo').value" type="text" class="form-control" id="periodoescolar" aria-describedby="periodoescolar">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <ng2-smart-table class="table table-hover table-borderless" (custom)="aviso($event)" [settings]="settingsHorario" [source]="itemSourceHorario"></ng2-smart-table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-secondary float-right" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="alumnos" role="tabpanel" aria-labelledby="alu-tab">
                        <table class="table able-sm">
                            <thead class="text-white bg-danger">
                                <tr>
                                    <td>Alumno</td>
                                    <td>Matrícula</td>
                                    <td>Semestre</td>
                                    <td>Regular</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of aluDisponibles">
                                    <td>{{ a.nombrecompleto }}</td>
                                    <td>{{ a.matricula }}</td>
                                    <td>{{ a.semestre }}</td>
                                    <td>{{ a.regular }}</td>
                                    <td>
                                        <button class="btn btn-sm btn-danger" (click)="inscribirAlumno(a.matricula)">Inscribir</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
            </div> -->
          </div>
        </div>
      </div>

      <div class="modal fade" id="agregarhorariomodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Añadir Horario</h5>
              <button type="button" class="close" (click)="cerrarmodalhorario()"aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="materia">Materia</label>
                            <input disabled [value]="grupoindividual.get('nombremateria').value" type="text" class="form-control" id="materia" aria-describedby="materia">
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label for="idgrupo">idGrupo</label>
                            <input disabled [value]="grupoindividual.get('idgrupo').value" type="text" class="form-control" id="idgrupo" aria-describedby="idgrupo">
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label for="semestre">Semestre</label>
                            <input disabled [value]="grupoindividual.get('semestre').value" type="text" class="form-control" id="semestre" aria-describedby="semestre">
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label for="grupo">Grupo</label>
                            <input disabled [value]="grupoindividual.get('grupo').value" type="text" class="form-control" id="grupo" aria-describedby="grupo">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="aula">Aula</label>
                            <select class="form-control" id="aula">
                              <option *ngFor="let aula of listaaulas" [value]="aula.id">{{ aula.nombre }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="dias">Días</label>
                            <select class="form-control" id="dias">
                              <option *ngFor="let dia of diassemana" [value]="dia">{{ dia }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="hinicio">Hora Inicio</label>
                            <input  type="time" value="08:00" class="form-control" id="hinicio" >
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="hfin">Hora Fin</label>
                            <input  type="time" value="10:00" class="form-control" id="hfin" >
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            
                            <input [formControl]="this.grupoindividual.get('practica')" [checked]="this.grupoindividual.get('practica').value"   type="checkbox" class="form-check-input" id="practica" aria-describedby="practica">
                            <label for="practica">Práctica</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-danger mr-2 float-right" (click)="revisarempalme()">Agregar Horario</button>
                        <button class="btn btn-secondary mr-2 float-right" (click)="cerrarmodalhorario()">Cerrar</button>
                    </div>
                </div>
                <div *ngIf="!banderaempalmes">
                    <div class="row pt-2" *ngFor="let empalmes of listahorariosempalmados">
                        <div class="col-12">
                            <label>El grupo ingresado se empalma con <strong>{{ empalmes.grupo.mpe.materia.nombre }} {{ empalmes.grupo.mpe.semestre }}{{ empalmes.grupo.nombre }}</strong> a la hora
                            <strong>{{ empalmes.hinicio | slice:0:5 }}</strong> el día <strong>{{ empalmes.dia | slice:0:5 }}</strong> en el aula <strong>{{ empalmes.aula.nombre }}</strong>.</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="empalmes" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Empalme de Grupos</h5>
          <button type="button" class="close" aria-label="Close" (click)="cerrarempalmegrupos()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row" *ngIf="gruposEmpalme.length" >
                <div class="form-group " >
                    <div class="col" >
                        <button class="btn btn-danger" (click)="exportEmpalmes()" data-toggle="tooltip" data-placement="top" title="Descargar Registros"><i id="icon" class="fas fa-download"> </i>Exportar {{gruposEmpalme.length}} registros</button>
                    </div>
                </div>
            </div>
            <div class="row" >
                <ng2-smart-table class="table table-hover table-borderless" [settings]="empalmes" [source]="gruposEmpalme"></ng2-smart-table>
            </div>
        </div>
      </div>
    </div>
</div>

      <div class="modal fade" id="crear" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Crear Grupos</h5>
              <button type="button" class="close" aria-label="Close" (click)="cerrarcreargrupos()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="periodoescolarcrear">Período Escolar</label>
                            <select class="form-control" id="periodoescolarcrear">
                              <option *ngFor="let periodo of listaperiodoscrear" [value]="periodo.id">{{ periodo.nombre }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="carreracrear">Carrera</label>
                            <select class="form-control" id="carreracrear" (change)="consultarmaterias()">
                              <option *ngFor="let carrera of listacarrerasacrear" [value]="carrera.idplanestudio">{{ carrera.carrera }} - ( {{carrera.plandeestudio}} )</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label for="semestrecrear">Semestre</label>
                            <input type="number" class="form-control" id="semestrecrear" aria-describedby="semestrecrear">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="grupocrear">Grupo</label>
                            <input type="text" class="form-control" id="grupocrear" aria-describedby="grupocrearrupo">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="mregularescrear">Maxímo Regulares</label>
                            <input type="number" class="form-control" value="0" min="0" id="mregularescrear" aria-describedby="mregularescrear">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="mirregularescrear">Maxímo Irregulares</label>
                            <input type="number" class="form-control" value="0" min="0" id="mirregularescrear" aria-describedby="mirregularescrear">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-danger float-right" (click)="filtrarmaterias()">Buscar Materias</button>
                    </div>
                </div>
                <div class="row pt-3" *ngIf="!banderamostrartabla">
                    <div class="col-12">
                        <table class="table table-sm table-borderless table-striped">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th>Materia</th>
                                    <th>Alias</th>
                                    <th>Regulares</th>
                                    <th>Irregulares</th>
                                    <th>Maestro</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let materia of listamateriaspefiltradas; let i = index">
                                    <td>{{ materia.materia.nombre }}</td>
                                    <td>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="aliascrear{{i}}">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [value]="maximo" id="maximocrear{{i}}">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input type="text" class="form-control" [value]="maximoi" id="maximoicrear{{i}}">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <select class="form-control" id="maestrocrear{{i}}">
                                                <option *ngFor="let maestro of listamaestros" [value]="maestro.id">{{ maestro.persona.apellidop }} {{ maestro.persona.apellidom }} {{ maestro.persona.nombres }}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" (click)="creargrupos(i)">Guardar</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarrrespuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ response.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="cerrarrrespuesta()">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarrrespuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="!banderadatos">
                ¿Estás seguro de eliminar el horario el día <strong>{{ horariocompleto.dia }}</strong> de <strong>{{ horariocompleto.hinicio | slice:0:5 }}</strong> a  <strong>{{ horariocompleto.hfin | slice:0:5 }}</strong>?
                Este proceso es <strong>PERMANENTE</strong>.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="cerraraviso()">Cancelar</button>
              <button class="btn btn-danger" (click)="eliminarhorario()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="lugaresmasivo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Agregar cupos a todos los grupos</h5>
              <button type="button" class="close"  (click)="cerraragregar()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="periodoescolarcrearmasivo">Período Escolar</label>
                            <select class="form-control" id="periodoescolarcrearmasivo">
                              <option *ngFor="let periodo of listaperiodoscrear" [value]="periodo.id">{{ periodo.nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="carreramasivo">Carrera</label>
                            <select class="form-control" id="carreramasivo">
                              <option *ngFor="let carrera of listacarrerasacrear" [value]="carrera.idplanestudio">{{ carrera.carrera }} - ( {{carrera.plandeestudio}} )</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="acciones">Acción</label>
                            <select class="form-control" id="acciones">
                                <option *ngFor="let accion of acciones" [value]="accion">{{ accion }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="semestresmasivo">Semestre</label>
                            <select class="form-control" id="semestresmasivo">
                              <option *ngFor="let semestre of semestres" [value]="semestre">{{ semestre }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="grupomasivo">Grupo</label>
                            <select class="form-control" id="grupomasivo">
                                <option *ngFor="let grupo of grupos" [value]="grupo">{{ grupo }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label for="regularesmasivo">Regulares</label>
                        <input type="number" class="form-control" value="0" min="0" id="regularesmasivo" aria-describedby="regularesmasivo">
                    </div>
                    <div class="col-6">
                        <label for="irregularesmasivo">Irregulares</label>
                        <input type="number" class="form-control" value="0" min="0" id="irregularesmasivo" aria-describedby="irregularesmasivo">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="cerraragregar()">Cerrar</button>
              <button class="btn btn-danger" (click)="agregarlugaresmasivo()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="lugarespormateria" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Modificar cupo a todos los grupos de una materia específica</h5>
              <button type="button" class="close"  aria-label="Close" (click)="agregarlugaresmateriasalir()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="periodoescolarpormateria">Período Escolar</label>
                            <select class="form-control" id="periodoescolarpormateria">
                              <option *ngFor="let periodo of listaperiodoscrear" [value]="periodo.id">{{ periodo.nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="carrerapormateria">Carrera</label>
                            <select class="form-control" id="carrerapormateria" (change)="consultarmaterias2()">
                              <option *ngFor="let carrera of listacarrerasacrear" [value]="carrera.idplanestudio">{{ carrera.carrera }} - ( {{carrera.plandeestudio}} )</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="materiapormateria">Materia</label>
                            <select class="form-control" id="materiapormateria">
                              <option *ngFor="let materia of listamateriaspe" [value]="materia.id">{{ materia.materia.nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="accionesmateria">Acción</label>
                            <select class="form-control" id="accionesmateria">
                                <option *ngFor="let accion of acciones" [value]="accion">{{ accion }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label for="regularespormateria">Regulares</label>
                        <input type="number" class="form-control" value="0" min="0" id="regularespormateria" aria-describedby="regularespormateria">
                    </div>
                    <div class="col-6">
                        <label for="irregularespormateria">Irregulares</label>
                        <input type="number" class="form-control" value="0" min="0" id="irregularespormateria" aria-describedby="irregularespormateria">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="agregarlugaresmateriasalir()">Cerrar</button>
              <button class="btn btn-danger" (click)="agregarlugarespormateria()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>
