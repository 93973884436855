import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Global} from 'src/global';
interface ClasificacionPer {
  clasificacion: string;
  id: number;
}
interface Catalogo {
  clasificacion: string;
  id: number;
}
@Injectable({
  providedIn: 'root'
})
export class ClasificacionService {

  constructor(private http: HttpClient, private global: Global) {}
  listar(matricula: string){
    return this.http.post<ClasificacionPer>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Clasificacion/lista/${matricula}`, null);
  }
  editar(clasificacion: ClasificacionPer){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Clasificacion/editar`, clasificacion, { headers:this.global.headers });
  }
  eliminar(id: number){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Clasificacion/delete/${id}`);
  }
  insertar(catalogo: Catalogo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Clasificacion/insert`, catalogo, { headers:this.global.headers });
  }
}
