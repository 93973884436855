import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PersonalService } from '../services/personal.service';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
declare var $ : any;
import { saveAs } from 'file-saver';
import { KardexComponent } from '../kardex/kardex.component';
import { KardexLargoComponent } from '../kardex-largo/kardex-largo.component'
import { AgregarAlumnoComponent } from '../agregar-alumno/agregar-alumno.component';
import { JustificantesComponent } from '../justificantes/justificantes.component';

@Component({
  providers:[KardexComponent, KardexLargoComponent, AgregarAlumnoComponent, JustificantesComponent],
  selector: 'app-alumnos',
  templateUrl: './alumnos.component.html',
  styleUrls: ['./alumnos.component.css']
})
export class AlumnosComponent implements OnInit {

  @ViewChild('inputFile') myInputVariable: ElementRef;
  uploadForm: FormGroup;
  httpstatus = '';
  httpstatustext = '';
  httpurl = '';
  banderahttp:boolean = true;
  banderaspinner:boolean = true;
  alumnoestatus = '';
  busqueda = new FormGroup({
    matricula: new FormControl(''),
    nombre: new FormControl('')
  })

  alumnos;
  bandera = true;
  bandera2  = true;
  dato;
  response = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

    maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  matriculaAlumno;
  nombreAlumno = '';
  mostrar:boolean = true;
  apleId="";
  

  matriculaA;
  nombreA;

  documentos = [];
  documentosBaja = [];
  firmaAutorizada = [];
  idDocumento;
  idDocumentoBaja;
  respuesta = {
    codigo:'',
    mensaje:'',
    detalle:''
  }

  settings = {
    columns:{
      carrera:{
        title:'Carrera',
        editable:false,
        width:'12%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'8%'
      },
      apellidop:{
        title:'Apellido Paterno',
        editable:false,
        width:'10%'
      },
      apellidom:{
        title:'Apellido Materno',
        editable:false,
        width:'10%'
      },
      nombres:{
        title:'Nombre',
        editable:false,
        width:'10%'
      },
      semestre:{
        title:'Sem.',
        editable:false,
        width:'5%'
      },
      regular:{
        title:'Regular',
        editable:false,
        width:'5%'
      },
      estatus:{
        title:'Estatus',
        editable:false,
        width:'10%'
      },
      periodoingreso:{
        title:'Ingreso',
        editable:false,
        width:'10%'
      },
      periodoegreso:{
        title:'Egreso',
        editable:false,
        width:'10%'
      },
      periodobaja:{
        title:'Baja',
        editable:false,
        width:'10%'
      }

    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:30,
      custom:[
        { name:'selecionarDocument', title:'Solicitar' }
      ]
    },
    noDataMessage:'Sin datos disponibles.'
  }

  tblMovimientos = {
    columns:{
      carrera:{
      title:'Carrera',
      editable:false,
      width:'10%'
    }/*,matricula:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      },alumno:{
        title:'Alumno',
        editable:false,
        width:'15%'
      }*/,periodo:{
        title:'Período',
        editable:false,
        width:'10%'
      },movimiento:{
        title:'Movimiento',
        editable:false,
        width:'10%'
      },semestre:{
        title:'Semestre',
        editable:false,
        width:'10%'
      }

    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:15
    },
    noDataMessage:'Sin datos disponibles.'
  }

  nombreDoc = '';

  General:boolean = true;
  Kardex:boolean = true;
  KardexLargo:boolean = true;
  Constancia:boolean = true;
  Horario:boolean = true;
  AltaMaterias:boolean = true;
  Calificaciones:boolean = true;
  Becas:boolean = true;
  Datospersonales:boolean = true;

  banderaTable:boolean = true;
  banderaTableBajas:boolean = true;
  banderaMensajeDoc:boolean = true;
  banderaMensajeBaja:boolean = true;
  banderaAltaMaterias:boolean = true;
  banderaSinDatos:boolean = true;
  banderaboleta:boolean = true;
  banderadocumentoalumno:boolean = true;
  banderaagregaralumno:boolean = true;
  banderahistorial:boolean = true;
  banderajustificantes:boolean = true;
  src;
  type = FileMimeType.PDF;
  alumnodata;
  periodosescolarescompleto = [];
  idperiodo;
  fecha = new Date();
  fecha2 = new Date().toISOString().substring(0, 10);
  tiempos = this.fecha.getHours() + ':' + this.fecha.getMinutes();
  mensajebtn = 'Dar Baja'
  validart = "0";
  iddocumentoimprimir = 0;
  planestudioid = 0;
  firma ;
  firmaC ;
  banderaFirma:boolean = true;

  periodosescolares = [];
  periodostodos = [];
  periodosescolaresfiltrados = [];
  periodosescolaresAbiertos = [];
  plandeestudios = [];
  planesdeestudiosfiltrados = [];
  semestres = [];
  periodoescolarselecionado = "";
  plandeesudioselecionado = "";

  semestresplanestudios = [];
  alumnosemestre = '';
  alumnoplanestudio = '';
  alumnoregular = '';
  banderasemestres: boolean = true;


  access = [];
  banderaExportarAlumnos : boolean = true;
  // KARDEX
  banderaKardex : boolean = true;
  banderaKardexLargo : boolean = true;
  // DOCUMENTOS
  banedraRevisarDocuments : boolean = true;
  banderaValidarDocumentos : boolean = true;
  banderaImprimirDocumentos : boolean = true;
  // HORARIO
  banderaHorarioAlumno : boolean = true;
  banderaImprimirHorario : boolean = true;
  // BAJAS/REINGRESO
  banderaBajaAlumno : boolean = true;
  // AGREGAR MATERIAS
  banderaAgregarMaterias : boolean = true;
  // CAlIFICACIONES
  banderaCalificaciones : boolean = true;
  banderaAbrirCalificaciones : boolean = true;
  // CONSTANCIA
  banderaConstancia : boolean = true;
  banderaImprimirConstancia : boolean = true;
  // Datos Personales
  banderaDatosPersonales : boolean = true;
  // Boleta
  banderaBoleta : boolean = true;
  banderaImprimirBoleta : boolean = true;
  // Restablecer Constraseña
  banderaRestablecerContra : boolean = true;
  // Tiempo de Inscripcion 
  banderaModificarTiempo : boolean = true;
  // Cambiar Semestre
  banderaCambiarSemestre : boolean = true;
  // Cambiar Estatus
  banderaCambiarEstatus : boolean = true;
  // Asistencias
  banderaAsistencias : boolean = true;
  banderaTodo : boolean = true;
  constructor(private justificantes: JustificantesComponent, private agregara: AgregarAlumnoComponent, private kardexL: KardexLargoComponent, private kardex: KardexComponent, private global: Global, private formBuilder: FormBuilder, private personalService: PersonalService, private router: Router, private excelService:ExcelService) {
    $('body').tooltip({
      selector: '[data-toggle="tooltip"]'
    })
  }

  viewBtn(f){
    this.banderaExportarAlumnos = true;

    this.banderaKardex = true;

    this.banderaKardexLargo = true;

    this.banedraRevisarDocuments = true;

    this.banderaValidarDocumentos = true;

    this.banderaHorarioAlumno = true;

    this.banderaImprimirHorario = true;

    this.banderaHorarioAlumno = true;

    this.banderaBajaAlumno = true;

    this.banderaAgregarMaterias = true;

    this.banderaCalificaciones = true;

    this.banderaConstancia = true;

    this.banderaImprimirConstancia = true;

    this.banderaDatosPersonales = true;

    this.banderaBoleta = true;

    this.banderaImprimirBoleta = true;

    this.banderaRestablecerContra = true;

    this.banderaModificarTiempo = true;

    this.banderaCambiarSemestre = true;

    this.banderaCambiarEstatus  = true;

    this.banderaAsistencias  = true;

    this.banderaImprimirDocumentos = true;

    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10032 || this.access[i] == 3){
        this.banderaExportarAlumnos = false;
      }
      if(this.access[i] == 10033 || this.access[i] == 3){
        this.banderaKardex = false;
      }
      if(this.access[i] == 10035 || this.access[i] == 3){
        this.banderaKardexLargo = false;
      }
      if((this.access[i] == 10039 || this.access[i] == 3) && f == 1){
        this.banedraRevisarDocuments = false;
      }
      if(this.access[i] == 10040 || this.access[i] == 3){
        this.banderaValidarDocumentos = false;
      }
      if(this.access[i] == 10037 || this.access[i] == 3){
        this.banderaHorarioAlumno = false;
      }
      if(this.access[i] == 10038 || this.access[i] == 3){
        this.banderaImprimirHorario = false;
      }
      if(this.access[i] == 10041 || this.access[i] == 3){
        this.banderaImprimirDocumentos = false;
      }
      if((this.access[i] == 10042 || this.access[i] == 3) && f == 1){
        this.banderaBajaAlumno = false;
      }
      if((this.access[i] == 10044 || this.access[i] == 3) && f == 1){
        this.banderaAgregarMaterias = false;
      }
      if((this.access[i] == 10045 || this.access[i] == 3) && f == 1){
        this.banderaCalificaciones = false;
      }
      if(this.access[i] ==  10048 || this.access[i] == 3){
        this.banderaConstancia = false;
      }
      if(this.access[i] ==  10049 || this.access[i] == 3){
        this.banderaImprimirConstancia = false;
      }
      if((this.access[i] ==  10050 || this.access[i] == 3) && f == 1){
        this.banderaDatosPersonales = false;
      }
      if(this.access[i] ==  10052 || this.access[i] == 3){
        this.banderaBoleta = false;
      }
      if(this.access[i] ==  10053 || this.access[i] == 3){
        this.banderaImprimirBoleta = false;
      }
      if(this.access[i] ==  10054 || this.access[i] == 3){
        this.banderaRestablecerContra = false;
      }
      if((this.access[i] ==  10055 || this.access[i] == 3) && f == 1){
        this.banderaModificarTiempo = false;
      }
      if((this.access[i] ==  10056 || this.access[i] == 3) && f == 1){
        this.banderaCambiarSemestre = false;
      }
      if((this.access[i] ==  10057 || this.access[i] == 3) && f == 1){
        this.banderaCambiarEstatus  = false;
      }
      if((this.access[i] ==  10058 || this.access[i] == 3) && f == 1){
        this.banderaAsistencias  = false;
      }
    }
  }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required, ]
    });
    this.ConsultafirmasAutoriaza();
    this.consultarperiodosescolares();
    this.consultarperiodosescolaresAbiertos();
    this.consultarplanestudios();
    this.viewBtn(0);
  }


  opciones6(){
    var plan = <HTMLSelectElement>document.getElementById('plan');
    if((plan.value == "")){

    }else{
      this.plandeesudioselecionado = plan.value;
      this.consultarsemestres();
    }

  }

  consultarsemestres(){
    this.personalService.consultarsemestres(this.maestro.matricula, this.plandeesudioselecionado).subscribe({
      next: (res: any) => {
        this.semestres = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        this.banderasemestres = false;
      }
    });
  }

  consultarperiodosescolares(){
    this.personalService.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolares = data;
        this.periodostodos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
  consultarperiodosescolaresAbiertos(){
    this.personalService.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosescolaresAbiertos = data;
        
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarplanestudios(){
    this.personalService.consultarplanestudiosFiltrados(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.plandeestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.plandeestudios.length; i++){
          if((this.plandeestudios[i].id == 1) || this.plandeestudios[i].id == 2){
            this.planesdeestudiosfiltrados.push(this.plandeestudios[i]);
          }
        }
      }
    });
  }

  buscarjustificantes(){
    this.justificantes.buscar();
  }


  verJustificantesModal(){
    $('#justificantesO').modal('show');
    this.banderajustificantes = false;
  }

  cambiarregularestatus(){
    var regular = <HTMLSelectElement> document.getElementById('cambiaresta');
    var jason = {
      id: this.alumnoplanestudio,
      regular: regular.value
    };
    this.personalService.cambiarregular(jason, this.maestro.matricula).subscribe({
      next: (res: any) => {
        this.respuesta = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        $('#respuesta').modal('show');
        this.onSubmit();
      }
    });
  }

  cambiarestatussemestre(){
    $('#cambiarestatus').modal('show');
  }

  cambiarsemestrealumno(){
    var semestre = <HTMLSelectElement> document.getElementById('cambiarsem');
    var jason = {
      id: this.alumnoplanestudio,
      semestre: semestre.value
    };
    this.personalService.cambiarsemestre(jason,this.maestro.matricula).subscribe({
      next: (res: any) => {
        this.respuesta = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        $('#respuesta').modal('show');
        this.onSubmit();
      }
    });
  }

  vercambiarsemestre(){
    $('#cambiarsemestre').modal('show');
  }

  consultarsemestrespe(matriculap, idplan){
    this.personalService.consultarsemestres(matriculap, idplan).subscribe({
      next: (res:any) => {
        this.semestresplanestudios = res;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  verhistorial(){
    $('#historial').modal('show');
    this.banderahistorial = false;
  }

  ConsultafirmasAutoriaza(){
    var jason = {
      matriculap: this.maestro.matricula
    }
    this.personalService.consultarFirmasAuto(jason).subscribe({
      next:(data : any) => {
        this.firmaAutorizada = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.firmaAutorizada);

      }
    })
  }
  seleccionarAurizacion(){
    this.banderaFirma = true;
    var firmaid = <HTMLSelectElement>document.getElementById('firma');
    this.firma = firmaid.value;

    this.verKardex();
    this.banderaFirma = false;
  }
  seleccionarAurizacionConstancia(){
    this.banderaFirma = true;
    var firmaid = <HTMLSelectElement>document.getElementById('firmaC');
    this.firmaC = firmaid.value;

    this.verConstancia();
    this.banderaFirma = false;
  }

  imprimirkardex(){
    this.src = '';
    this.personalService.imprimirKardexCorto(this.matriculaAlumno, this.maestro.matricula, this.firma, this.planestudioid).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
        saveAs(blob, `Documento`);
      }
    })
  }

  agregar(){
    this.agregara.ingresaralumno();
  }

  agregaralumno(){
    this.banderaagregaralumno = false;
    $('#agregaralumno').modal('show');
  }

  captureScreen(){
    this.kardex.captureScreen();
  }

  captureScreen2(){
    this.kardexL.captureScreen();
  }

  reset(){
    this.Kardex = true;
    this.KardexLargo = true;
    this.Constancia = true;
    this.Horario = true;
    this.AltaMaterias = true;
    this.Calificaciones = true;
    this.Becas = true;
    this.Datospersonales = true;
    this.banderaboleta = true;
    this.banderaagregaralumno = true;
    this.banderahistorial = true;
    this.banderaFirma = true;
    this.banderajustificantes = true;
    $('#firma').prop('selectedIndex',0);
    $('#firmC').prop('selectedIndex',0);
  }

  verTareas(){
    $('#staticBackdropA').modal('show');
    this.Kardex = true;
    this.KardexLargo = true;
    this.Constancia = true;
    this.Horario = true;
    this.AltaMaterias = true;
    this.Calificaciones = true;
    this.Becas = true;
    this.Datospersonales = true;
    this.banderaboleta = true;
    this.banderaagregaralumno = true;
    this.banderahistorial = true;
    this.banderajustificantes = true;
    $('#firma').prop('selectedIndex',0);
    $('#firmaC').prop('selectedIndex',0);
  }

  cambiarestatus(event){
    var tiempo = <HTMLSelectElement>document.getElementById('currentTime');
    var fecha = <HTMLSelectElement>document.getElementById('date');
    // console.log(event.target.value);
    this.validart = event.target.value;
    if(this.validart == "1"){
      tiempo.disabled = true;
      fecha.disabled = true;
    }else{
      tiempo.disabled = false;
      fecha.disabled = false;
    }
  }

  agregartiempo(){
    var currentDate = this.fecha.toISOString().slice(0,10);
    var currentTime = this.fecha.getHours() + ':' + this.fecha.getMinutes();
    var tiempo = <HTMLSelectElement>document.getElementById('currentTime');
    setTimeout(() => {
      tiempo.value = currentTime;
      $('#tiempo').modal('show');
    }, 500);
  }

  agregart(){
    var tiempo = <HTMLSelectElement>document.getElementById('currentTime');
    var fecha = <HTMLSelectElement>document.getElementById('date');
    var radio = document.getElementById("inlineRadioT1") as HTMLInputElement;

    var fechahora = fecha.value+" "+tiempo.value
    if(this.validart == "0"){
      this.personalService.agregartiempo(this.maestro.matricula, this.matriculaAlumno, fechahora).subscribe({
        next:( data : any ) => {
          this.respuesta = data;
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          $('#respuesta').modal('show');
        }
      });
    }else{
      if(this.validart == "1"){
        var fechadef = "1900-01-01 00:00:00";
        this.personalService.agregartiempo(this.maestro.matricula, this.matriculaAlumno, fechadef).subscribe({
          next:( data : any ) => {
            this.respuesta = data;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            $('#respuesta').modal('show');
            radio.checked = true;
            tiempo.disabled = false;
            fecha.disabled = false;
          }
        });
      }
    }
  }

  consultarconstancia(){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Constancia/${this.matriculaAlumno}/${this.maestro.matricula}/${this.firmaC}/${this.planestudioid}`);
  }

  verboleta(){
    var idperiodo = <HTMLSelectElement>document.getElementById('periodos');
    this.idperiodo = idperiodo.value;
    $('#periodosescolaresmodal').modal('hide');
    setTimeout(() => {
      $('#boleta').modal('show');
      this.banderaboleta = false;
      idperiodo.selectedIndex = 0;
    }, 1000);
  }

  imprimirboleta(){
    this.personalService.boletaescolares(this.idperiodo, this.matriculaAlumno, this.maestro.matricula).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var blob = new Blob([this.src], {type: "application/pdf;charset=utf-8"});
        saveAs(blob, `Bloques`);
      }
    });
  }

  consultarperiodos(){
    this.personalService.consultarperiodosdealumno(this.maestro.matricula, this.matriculaAlumno).subscribe({
      next:(data : any) => {
        this.periodosescolarescompleto = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.periodosescolarescompleto.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    });
  }

  restablecercontras(){
    var jason = {
      usuario:this.matriculaAlumno,
      password:this.matriculaAlumno, 
      matriculap:this.maestro.matricula
    }
    $('#staticBackdropA').modal('hide');
    this.personalService.cambiarPassA(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
      }
    });
  }

  verPeriodosescolares(){
    $('#periodosescolaresmodal').modal('show');
  }

  verDatosPersonales(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    setTimeout(() => {
      $('#staticBackdropDatosPersonales').modal('show');
      this.Datospersonales = false;
    }, 700)
  }

  verBecas(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    $('#staticBackdropBecas').modal('show');
    this.Becas = false;
  }

  verCalificaciones(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    $('#staticBackdropCalificaciones').modal('show');
    this.Calificaciones = false;
  }

  verKardex(){
    $('#SelectFirmaKardex').modal('hide');
    localStorage.setItem('alumno', this.matriculaAlumno);
    localStorage.setItem('firma', this.firma);
    $('#staticBackdropKardex').modal('show');
    this.Kardex = false;
  }
  verSeleccionKardex(){
    $('#SelectFirmaKardex').modal('show');
  }

  verSeleccionConstancia(){
    $('#SelectFirmaConstancia').modal('show');
  }
  verKardexlargo(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    $('#staticBackdropKardexLargo').modal('show');
    this.KardexLargo = false;
  }

  verConstancia(){
    $('#SelectFirmaConstancia').modal('hide');
    localStorage.setItem('alumno', this.matriculaAlumno);
    localStorage.setItem('firma', this.firmaC);
    $('#staticBackdropConstancia').modal('show');
    this.Constancia = false;
  }

  verHorario(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    $('#staticBackdropHorario').modal('show');
    this.Horario = false;
  }

  horarioalumno(){
    let matricula =localStorage.getItem('alumno');
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorarioAlumnoPdf/0/${matricula}`);
  }

  verAltaMaterias(){
    localStorage.setItem('alumno', this.matriculaAlumno);
    $('#staticBackdropAltaMaterias').modal('show');
    this.AltaMaterias = false;
  }

  exportAsXLSX():void {
    var alumnosexcel = [];
    for(var i = 0; i < this.alumnos.length; i++){
      var jason = [
        this.alumnos[i].apellidop,
        this.alumnos[i].apellidom,
        this.alumnos[i].nombres,
        this.alumnos[i].matricula,
        this.alumnos[i].semestre,
        this.alumnos[i].carrera,
        this.alumnos[i].estatus,
        this.alumnos[i].regular,
        this.alumnos[i].periodoingreso,
        this.alumnos[i].periodoegreso
      ]
      alumnosexcel.push(jason);
    }
      this.excelService.exportAsExcelFileAlumnos(alumnosexcel);
  }

  imprimirKardexLargo(){
    this.personalService.imprimirKardexLargo(this.matriculaAlumno, this.maestro.matricula, this.planestudioid);
  }

  verDocuentos(matriculaAlumno){
    this.personalService.consultarDocumentosBaja(matriculaAlumno).subscribe({
      next:(res : any) => {
        this.documentosBaja = res;
      },
      complete:() => {
        this.personalService.consultarDocumentosAlu(matriculaAlumno, this.maestro.matricula).subscribe({
          next:(res : any) => {
            this.documentos = res;
          },
          complete: () => {
            if(this.documentos.length > 0 || this.documentosBaja.length > 0){
              this.banderaTable = true;
              if(this.documentos.length <= 0 ){
                this.banderaMensajeDoc = false;
              }
              if(this.documentosBaja.length <= 0){
                this.banderaMensajeBaja = false;
              }
            }else{
              this.banderaTable = false;
            }
            $('#staticBackdropDocumentos').modal('show');
          },
          error: (err) => {
            this.httpstatus = err.status;
            this.httpstatustext = err.statusText;
            this.httpurl = err.url;
            this.banderahttp = false;
          }
        });
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
  }

  imprimirDocumentos(){
    this.personalService.descargarDocumento(this.idDocumento);
  }

  visualizardocumento(id){
    this.idDocumento = id;
    this.banderadocumentoalumno = true;
    $('#staticBackdropDocumentos').modal('hide');
    this.personalService.descargarDocumento2(id).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var x = 0; x < this.documentos.length; x++){
          if(id == this.documentos[x].id){
            this.nombreDoc = this.documentos[x].nombre
          }
        }
        this.banderadocumentoalumno = false;
        $('#documentoalumno').modal('show');
      }
    });
  }

  validarDocumento(id){
    this.idDocumento = id;
    for(var x = 0; x < this.documentos.length; x++){
      if(this.idDocumento == this.documentos[x].id){
        this.nombreDoc = this.documentos[x].nombre
      }
    }
      $('#staticBackdropValidar').modal('show');
  }

  documentoValido(){
    var estado = 2;
    let jason = {
      matriculap:this.maestro.matricula
    };
    this.personalService.validarDocumento(this.idDocumento, estado,jason).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        $('#staticBackdropRespuestaDoc').modal('show');
      }
    });
  }

  documentoNoValido(){
    var estado = 0;
    let jason = {
      matriculap:this.maestro.matricula
    };
    this.personalService.validarDocumento(this.idDocumento, estado, jason).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      },
      complete:() => {
        $('#staticBackdropRespuestaDoc').modal('show');
      }
    });
  }

  imprimirDocumentoBaja(idDocumento){
    this.personalService.imprimirDocumentoBaja(idDocumento);
  }

  validarDocumentoBaja(idDoc){
    this.idDocumentoBaja = idDoc;
    $('#staticBackdropValidarDocBaja').modal('show');
  }

  documentoValidoBaja(){
    var estado = 2;
    this.personalService.validarDocumentoBaja(this.idDocumentoBaja, estado).subscribe({
      next:(res : any) => {
        this.respuesta =  res;
      },
      complete:() => {
        $('#staticBackdropRespuestaDoc').modal('show');
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
  }

  documentoNoValidoBaja(){
    var estado = 0;
    this.personalService.validarDocumentoBaja(this.idDocumentoBaja, estado).subscribe({
      next:(res : any) => {
        this.respuesta =  res;
      },
      complete:() => {
        $('#staticBackdropRespuestaDoc').modal('show');
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
  }

  sinDatosDisponibles(){
    $('#staticBackdropSinDatos').modal('show');
  }


  onSubmit(){
    this.banderaspinner = false;
    this.matriculaA = this.busqueda.get('matricula').value;
    this.nombreA = this.busqueda.get('nombre').value;
    var plan = <HTMLSelectElement>document.getElementById('plan');
    var regular = <HTMLSelectElement>document.getElementById('regular');
    var semestre = <HTMLSelectElement>document.getElementById('semestre');
    var estatus = <HTMLSelectElement>document.getElementById('estatus');
    var pingreso = <HTMLSelectElement>document.getElementById('periodoingreso');
    var pegreso = <HTMLSelectElement>document.getElementById('periodoegreso');
    var pbaja = <HTMLSelectElement>document.getElementById('periodobaja');
    if((this.busqueda.get('matricula').value === '' && this.busqueda.get('nombre').value === '')){
      var jason = {
        dato: this.busqueda.get('matricula').value,
        matriculap: this.maestro.matricula,
        planestudioid: plan.value,
        regular: (regular.value == null || regular.value == undefined || regular.value == "")?'':regular.value,
        semestre: (semestre.value == null || semestre.value == undefined || semestre.value == "")?'':semestre.value,
        estatus: (estatus.value == null || estatus.value == undefined || estatus.value == "")?'':estatus.value,
        periodoidingreso: (pingreso.value == null || pingreso.value == undefined || pingreso.value == "")?'':pingreso.value,
        periodoegreso: (pegreso.value == null || pegreso.value == undefined || pegreso.value == "")?'':pegreso.value,
        periodoidbaja: (pbaja.value == null || pbaja.value == undefined || pbaja.value == "")?'':pbaja.value
      }
      this.personalService.buscarAlumnoxNombre(jason).subscribe({
        next:(resp) => {
          this.alumnos = resp;
          if(this.alumnos.length == 0){
            this.sinDatosDisponibles();
          }else{
            this.bandera = false;

          }
        },
        error:(err) => {
          this.httpstatus = err.status;
          this.httpstatustext = err.statusText;
          this.httpurl = err.url;
          this.banderahttp = false;
        },
        complete:() => {
          this.banderaspinner = true;
        }
      });
    }else{
      if((this.busqueda.get('matricula').value !== '' && this.busqueda.get('nombre').value !== '')){
        var jason = {
          dato: this.busqueda.get('matricula').value,
          matriculap: this.maestro.matricula,
          planestudioid: plan.value,
          regular: (regular.value == null || regular.value == undefined || regular.value == "")?'':regular.value,
          semestre: (semestre.value == null || semestre.value == undefined || semestre.value == "")?'':semestre.value,
          estatus: (estatus.value == null || estatus.value == undefined || estatus.value == "")?'':estatus.value,
          periodoidingreso: (pingreso.value == null || pingreso.value == undefined || pingreso.value == "")?'':pingreso.value,
          periodoegreso: (pegreso.value == null || pegreso.value == undefined || pegreso.value == "")?'':pegreso.value,
          periodoidbaja: (pbaja.value == null || pbaja.value == undefined || pbaja.value == "")?'':pbaja.value
        }
        this.personalService.buscarAlumnoxNombre(jason).subscribe({
          next:(resp) => {
            this.alumnos = resp;
            if(this.alumnos.length == 0){
              this.sinDatosDisponibles();
            }else{
              this.bandera = false;

            }
          },
          error:(err) => {
            this.httpstatus = err.status;
            this.httpstatustext = err.statusText;
            this.httpurl = err.url;
            this.banderahttp = false;
          },
          complete:() => {
            this.banderaspinner = true;
          }
        });
      }else{
        if(this.busqueda.get('matricula').value !== '' && this.busqueda.get('nombre').value === ''){
          var jason = {
            dato: this.busqueda.get('matricula').value,
            matriculap: this.maestro.matricula,
            planestudioid: plan.value,
            regular: (regular.value == null || regular.value == undefined || regular.value == "")?'':regular.value,
            semestre: (semestre.value == null || semestre.value == undefined || semestre.value == "")?'':semestre.value,
            estatus: (estatus.value == null || estatus.value == undefined || estatus.value == "")?'':estatus.value,
            periodoidingreso: (pingreso.value == null || pingreso.value == undefined || pingreso.value == "")?'':pingreso.value,
            periodoegreso: (pegreso.value == null || pegreso.value == undefined || pegreso.value == "")?'':pegreso.value,
            periodoidbaja: (pbaja.value == null || pbaja.value == undefined || pbaja.value == "")?'':pbaja.value
          }
          this.personalService.buscarAlumnoxNombre(jason).subscribe({
            next:(resp) => {
              this.alumnos = resp;
              if(this.alumnos.length == 0){
                this.sinDatosDisponibles();
              }else{
                this.bandera = false;

              }
            },
            error:(err) => {
              this.httpstatus = err.status;
              this.httpstatustext = err.statusText;
              this.httpurl = err.url;
              this.banderahttp = false;
            },
            complete:() => {
              this.banderaspinner = true;
            }
          })
        }else{
          if(this.busqueda.get('nombre').value !== '' && this.busqueda.get('matricula').value === ''){
            var jason = {
              dato: this.busqueda.get('nombre').value,
              matriculap: this.maestro.matricula,
              planestudioid: plan.value,
              regular: (regular.value == null || regular.value == undefined || regular.value == "")?'':regular.value,
              semestre: (semestre.value == null || semestre.value == undefined || semestre.value == "")?'':semestre.value,
              estatus: (estatus.value == null || estatus.value == undefined || estatus.value == "")?'':estatus.value,
              periodoidingreso: (pingreso.value == null || pingreso.value == undefined || pingreso.value == "")?'':pingreso.value,
              periodoegreso: (pegreso.value == null || pegreso.value == undefined || pegreso.value == "")?'':pegreso.value,
              periodoidbaja: (pbaja.value == null || pbaja.value == undefined || pbaja.value == "")?'':pbaja.value
            }
            this.personalService.buscarAlumnoxNombre(jason).subscribe({
              next:(resp) => {
                this.alumnos = resp;
                if(this.alumnos.length == 0){
                  this.sinDatosDisponibles();
                }else{
                  this.bandera = false;

                }
              },
              error:(err) => {
                this.httpstatus = err.status;
                this.httpstatustext = err.statusText;
                this.httpurl = err.url;
                this.banderahttp = false;
              },
              complete:() => {
                this.banderaspinner = true;
              }
            })
          }else{
            throw new Error("Error al buscar alumno");
          }
        }
      }
    }
  }
  abrirTareas(value){
    this.viewBtn(value.data.editable);
    this.planestudioid = value.data.planestudioid;
    this.apleId=value.data.aple_id;
    this.alumnoregular = value.data.regular;
    this.alumnoestatus = value.data.estatus;
    this.matriculaAlumno = value.data.matricula;
    this.alumnosemestre = value.data.semestre;
    this.alumnoplanestudio = value.data.aple_id;
    localStorage.setItem('alumnocompleto', JSON.stringify(value.data));
    this.nombreAlumno = `${value.data.apellidop} ${value.data.apellidom} ${value.data.nombres}`;
    $('#staticBackdropA').modal('show');
    this.consultarperiodos();
    this.consultarsemestrespe(this.maestro.matricula, this.planestudioid);
  }

  // primeraviso(id){
  //   this.dato = id;
  //   $('#staticBackdrop').modal('show')
  // }
  // segundoAviso(){
  //   $('#staticBcakdrop').modal('hide')
  //   setTimeout(() => {
  //     $('#staticBackdrop2').modal('show')
  //   }, 1000)
  // }

  btnBajas(){
    this.mostrar = false;
  }
  btnAcciones(){
    this.mostrar = true;
    this.myInputVariable.nativeElement.value = null;
  }

    onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  darBaja(){
    var sel = <HTMLInputElement>document.getElementById('scripts');

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    formData.append('matricula', this.apleId);

    formData.append('matriculap', this.maestro.matricula);
    formData.append('estado', sel.value);
    formData.append('fechasolicitud', (<HTMLInputElement>document.getElementById('fechasolicitud')).value);
    formData.append('observaciones', (<HTMLInputElement>document.getElementById('observaciones')).value);

    // var jason = {
    //   matricula:this.matriculaAlumno,
    //   matriculap:this.maestro.matricula,
    //   estado:sel.value
    // }


    this.personalService.bajaAlumnos(formData).subscribe({
      next:(res : any) => {
        this.response = res;
      },
      complete:() => {
        $('#staticBackdropResponse').modal('show');
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });

    this.myInputVariable.nativeElement.value = null;
  }

  mensaje(){
    var sel = <HTMLInputElement>document.getElementById('scripts');
    if(sel.value == 'REINGRESO'){
      this.mensajebtn = 'Dar Alta'
    }else{
      this.mensajebtn = 'Dar Baja'
    }
  }

  agregarPlan(){
    $('#addPlanEstudio').modal('show');
    //console.log(this.periodosescolares);
    
  }
  
  movimientos=[];
  historialMovimientos(){
    
    //console.log(this.periodosescolares);
    let aple = JSON.parse(localStorage.getItem('alumnocompleto'));
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.personalService.movimientosAlumno({'alumnoplanestudioid':aple.aple_id,"matriculap":this.maestro.matricula}).subscribe({
      next:(res : any) => {
        this.movimientos =  res;
      },
      complete:() => {
        $('#MovimientosAlumno').modal('show');
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
    
  }
  enviarNuevoPlan(){
    let nplan = <HTMLInputElement>document.getElementById('addplan');
    let nperiodo = <HTMLInputElement>document.getElementById('addperiodo');
    var jason = {
      planestudioid:nplan.value,
      periodoingresoid:nperiodo.value,
      matricula: this.matriculaAlumno,
      matriculap:this.maestro.matricula

    }
    this.personalService.añadirNuevoPlanEstudioAlumno(jason).subscribe({
      next:(res : any) => {
        this.response = res;
      },
      complete:() => {
        $('#addPlanEstudio').modal('hide');
        $('#addplan').prop('selectedIndex',0);
        $('#addperiodo').prop('selectedIndex',0);
        this.onSubmit();
        $('#staticBackdropResponse').modal('show');
      },
      error:(err) => {
        this.httpstatus = err.status;
        this.httpstatustext = err.statusText;
        this.httpurl = err.url;
        this.banderahttp = false;
      }
    });
  }
  // bajaAlumno(){
  //   var jason = {
  //     dato:this.dato,
  //     matriculap:this.maestro.matricula
  //   }
  //   this.personalService.bajaAlumno(jason).subscribe({
  //     next:(res: any) => {
  //       this.response = res;
  //     },
  //     complete:() => {
  //       $('#staticBackdropResponse').modal('show')
  //       this.ngOnInit();
  //     },
  //     error:(err) => {
  //       console.log(err);
  //     }
  //   })
  // }

}
