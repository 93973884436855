import { Component, OnInit } from '@angular/core';
import { ServicioUniversitarioService } from '../services/servicio-universitario.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { saveAs } from 'file-saver';

declare var $ : any;

@Component({
  selector: 'app-liberaciones',
  templateUrl: './liberaciones.component.html',
  styleUrls: ['./liberaciones.component.css']
})
export class LiberacionesComponent implements OnInit {

  liberaciones = [];
  periodos = [];
  plandeestudios = [];
  src;
  type = FileMimeType.PDF;
  busquedaForm = new FormGroup({
    periodoid: new FormControl (''),
    planestudioid: new FormControl (''),
    matriculap: new FormControl('')
  });
  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settings = {
    columns:{
      alumno:{
        title:'Alumno',
        editable:false,
        width:'30%'
      },
      matricula:{
        title:'Matrícula',
        editable:false,
        width:'10%'
      },
      carrera:{
        title:'Carrera',
        editable:false,
        width:'15%'
      },
      fechaLiberacion:{
        title:'Fecha Liberación',
        editable:false,
        width:'10%'
      },
      libera:{
        title:'Libera',
        editable:false,
        width:'15%'
      }, 
      periodoliberacion:{
        title:'Período Liberación',
        editable:false,
        width:'20%'
      },
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:15,
      // custom:[
      //   { name:'selecionarDocument', title:'Solicitar' }
      // ]
    },
    noDataMessage:'Sin datos disponibles.'
  };

  alumno =     {
    alumno: '',
    alumnoplanestudioid: '',
    carrera: '',
    fechaliberacion: '',
    libera: '',
    liberacionid: '',
    matricula: '',
    periodoliberacion: ''
}

  banderatabla: boolean = true;
  banderapdf: boolean = true;

  constructor(private ssu: ServicioUniversitarioService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodos();
    this.consultarplanes();
  }

  abirtareas(event){
    this.alumno = event.data;
    $('#t').modal('show');
  }

  cerrarpdf(){
    $('#formato').modal('hide');
  }

  consultarpdfliberacion(){
    this.banderapdf = true;
    this.ssu.liberacionSSUPDF(this.maestro.matricula, this.alumno.alumnoplanestudioid).subscribe({
      next:(res: ArrayBuffer) => {
        this.src = new Uint8Array(res);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#formato').modal('show');
        this.banderapdf = false;
      }
    });
  }

  consultarliberaciones(){
    this.banderatabla = true;
    this.busquedaForm.get('matriculap').patchValue(this.maestro.matricula);
    this.ssu.consultarLiberaciones(this.busquedaForm.value).subscribe({
      next:(res: any) => {
        this.liberaciones = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderatabla = false;
        //console.log(this.liberaciones);
      }
    });
  }

  consultarperiodos(){
    this.ssu.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.periodos = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarplanes(){
    this.ssu.consultarplanestudios().subscribe({
      next:(res: any) => {
        this.plandeestudios = res; 
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

}
