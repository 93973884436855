import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  constructor(private http: HttpClient, private global: Global) { }

  agregaraviso(datos){
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/insert`, datos, {headers: headers});
  }

  consultaravisosactivos(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/listActivos`, null);
  }

  borraraviso(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/borrar`, datos);
  }
  consultarplanestudiosFiltrados(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/lista/${matriculap}`, null);
  }
  listaCarreras(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matricula}`,null);
  }
  editarPrioridad(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/edit/prioridad`, datos);
  }
  addRolesNew(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/insert/rol`, datos);
  }
  editAviso(datos){
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/edit/aviso`, datos,{headers: headers});
  }

  consultarroles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/buscaroles/${matricula}`, null);
  }

  consultarrolesavisos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/avisos/buscaroles/${matricula}`, null);
  }

  lista(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/listaGrupos/${matricula}`,null);
  }

  listaGrupos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/listaGruposN/${matricula}`,null);
  }

  

  gruposPeriodoCarrera(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/listaGruposPC`, jason)
  }

  gruposEmpalme(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/empalmes`, jason)
  }
  
  listaAulas(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/lista/${matricula}`,null);
  }

  listacarreras(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/carrera/listaActivas/${matricula}`,null);
  }

  listaperiodos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbierto/${matricula}`,null);
  }

  listamaestro(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Maestro/list/${matricula}`,null);
  }

  horariodeGrupo(matricula:string,idGrupo:string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/horarioGrupo/${matricula}/${idGrupo}`,null);
  }

  listaaulas(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/AulaS/lista/${matricula}`,null);
  }

  empalme(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/empalmeHorario`, jason)
  }

  materiaconsultarmateriasplanestudios(matricula: string,ple:string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/list/${matricula}/${ple}`,null);
  }

  creargrupo(grupo){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/insert`,grupo, { headers:this.global.headers });
  }

  editarGrupo(grupo){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/editar`,grupo, { headers:this.global.headers });
  }

  agregarhorario(grupo){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/insert`,grupo, { headers:this.global.headers })
  }  

  delete(idhorario,matriculap ){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/delete/${idhorario}/${matriculap}`, null)
  }

  cuposmasivos(jason){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Grupo/grupoCupoModificar`, jason)
  }
consultarAlumnosParaIntersemestral
  (parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/listaParaInterSemestral`, parametros);
  }

  inscribirInterSemestral(parametros){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inscribirInterSemestral`, parametros);
  }
  getDepartamentales(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/Departamental/lista`, datos);
  }
  consultarplanestudios(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/list`, null);
  }
  consultarMPE(matriculap,idPlan){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/list/${matriculap}/${idPlan}`, null);
  }

  crearDepartamental(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/Departamental/create`, datos);
  }

  borrarDepartamental(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MateriasPlan/Departamental/delete`, datos);
  }

  consultarrolesOrderbyApellido(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/buscaroles/order/${matricula}`, null);
  }

  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }
}
