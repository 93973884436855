import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.css']
})
export class SescoCalificacionesComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  };
  respuesta = {
    codigo:'',
    mensaje:'',
    detalle:''
  };
  busqueda = new FormGroup({
    matricula: new FormControl('', [Validators.required]),
    planestudioid: new FormControl(''),
    matriculap: new FormControl('')
  });

  bandera:boolean = true;
  planestudios = [];
  calificaciones = [];
  calificacionesfiltrados = [];

  constructor(private sesco: SescoService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarplanestudios();
  }

  validar(){
    if(this.busqueda.invalid){

    }else{
      if(this.busqueda.valid){
        this.busqueda.get('matriculap').patchValue(this.maestro.matricula);
        // console.log(this.busqueda.value);
        this.importarcalificaciones(this.busqueda.value);
      }
    }
  }
  settings = {
    columns : {
      periodo:{
        title:'Período',
        editable:false,
        width:'15%'
      },
      semestre:{
        title:'Semestre',
        editable:false,
        width:'5%'
      },
      materia:{
        title:'Materia',
        editable:false,
        width:'20%'
      },
      acta:{
        title:'Acta',
        editable:false,
        width:'5%'
      },
      calificacion:{
        title:'Calificación',
        editable:false,
        width:'10%'
      },
      fechacalificacion:{
        title:'Fecha',
        editable:false,
        width:'10%'
      },
      tipocalificacion:{
        title:'Tipo de Calificación',
        editable:false,
        width:'15%'
      },
      nota:{
        title:'Nota',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }
  importarcalificaciones(jason){
    this.bandera = true;
    this.calificacionesfiltrados = [];
    this.sesco.importarcalificaciones(jason).subscribe({
      next:(res:any) => {
        this.calificaciones = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.calificaciones.length; i++){
          let jason = {
            periodo:this.calificaciones[i].periodo,
            semestre:this.calificaciones[i].semestre + '-' + this.calificaciones[i].grupo,
            materia:this.calificaciones[i].materia,
            acta:this.calificaciones[i].acta,
            calificacion:this.calificaciones[i].calificacion,
            fechacalificacion:this.calificaciones[i].fechacalificacion,
            tipocalificacion:this.calificaciones[i].tipocalificacion,
            nota:this.calificaciones[i].nota
          }
          this.calificacionesfiltrados.push(jason);
        }
        this.bandera = false;
      }
    });
  }

  consultarplanestudios(){
    this.sesco.consultarcarreras(this.maestro.matricula).subscribe({
      next:(res:any) => {
        this.planestudios = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var planes = <HTMLSelectElement>document.getElementById('planestudios');
        planes.selectedIndex = 1;
      }
    });
  }

}
