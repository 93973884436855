import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../services/personal.service';
import { Global } from 'src/global';
import { saveAs } from 'file-saver';
declare var $ : any;


@Component({
  selector: 'app-horario-alumno',
  templateUrl: './horario-alumno.component.html',
  styleUrls: ['./horario-alumno.component.css']
})
export class HorarioAlumnoComponent implements OnInit {
  
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }


  horarioUrl = '';
  id;
  response = {
    codigo:'',
    mensaje:'',
    detalle:''
  }
  bandera:boolean = true;
  bandera2:boolean = true;
  materias = [];

  alumno = {
    apellidom:'',
    apellidop:'',
    estatus:'',
    matricula:'',
    nombres:'',
    regular:'',
    semestre:''
  };

  constructor(private route: ActivatedRoute, private personalService: PersonalService, private global: Global) { }

  ngOnInit(): void {
    this.bandera = false;
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    // const matriculaAlumno = this.route.snapshot.paramMap.get('matriculaAlumno');
    const matriculaAlumno = localStorage.getItem('alumno');
    var jason = {
      dato: matriculaAlumno,
      matriculap: this.maestro.matricula,
      hr: 'S'
    }
    this.personalService.listarMateriasXAlumno(jason).subscribe({
      next:(res : any) => {
        this.materias = res;
      },
      complete:() => {
        if(this.materias.length == 0){
          this.bandera2 = false;
        }else{
          for(var i = 0; i < this.materias.length; i++){
            if(this.materias.length >= 1){
              this.alumno = this.materias[i].alumno;
              break;
            }
          }
          this.bandera = true;
        }
      },
      error:(err) => {
        console.log(err);
      }
    });

  }

  horarioalumno(matricula){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorarioAlumnoPdf/0/${matricula}`);
  }

  primerAvisoEliminarMaterias(id){
    this.id = id;
    $('#staticBackdrop').modal('show');
  }

  segundoAviso(){
    $('#staticBackdrop').modal('hide')
    setTimeout(() => {
      $('#staticBackdrop2').modal('show')
    }, 1000)
  }

  cerrarModal1(){
    $('#staticBackdrop').modal('hide');
  }

  cerrarModal2(){
    $('#staticBackdrop2').modal('hide');
  }

  cerrarModal3(){
    $('#staticBackdropResult').modal('hide');
  }

  eliminarMateria(){
    $('#staticBackdrop2').modal('hide');
    setTimeout(() => {
      const matriculaAlumno = localStorage.getItem('alumno');

      var jason = {
        matricula: matriculaAlumno,
        matriculap: this.maestro.matricula,
        mpe: this.id
      }
      this.personalService.eliminarMateriasXAlumno(jason).subscribe({
        next:(res: any) => {
          this.response = res;
        },
        complete:() => {
          $('#staticBackdropResult').modal('show')
        },
        error:(err) => {
          console.log(err);
        }
      });
  
      this.bandera = false;
  
      var jason2 = {
        dato:matriculaAlumno,
        matriculap:this.maestro.matricula
      }
  
      setTimeout(() => {
        this.personalService.listarMateriasXAlumno(jason2).subscribe({
          next:(res: any) => {
            this.materias = res;
          },
          complete:() => {
            this.bandera = true;
          },
          error:(err) => {
            console.log(err);
          }
        });
      }, 1500);
    }, 1000)
  }


}
