<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <h2>Auditoría Acceso del personal</h2>
    <p>En esta auditoría se muestran los inicio de sesión del personal. </p>

    <div class="row">
        <div class="col-3">
            <label>Fecha Inicio</label>
              <div class="input-group-append">
                  <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                  angular-mydatepicker #dp="angular-mydatepicker" 
                  [(ngModel)]="fechaI" [options]="myDpOptions" 
                  (dateChanged)="fechainicioI($event)"/>
              <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                  <i class="fas fa-calendar-alt"></i>
              </button>
              </div>
          </div>
          <div class="col-3">
            <label>Fecha Fin</label>
              <div class="input-group-append">
                  <input class="form-control" name="mydate" placeholder="Fecha Fin" 
                  angular-mydatepicker #dp2="angular-mydatepicker" 
                  [(ngModel)]="fechaF" [options]="myDpOptions" 
                  (dateChanged)="fechainicioF($event)"/>
              <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                  <i class="fas fa-calendar-alt"></i>
              </button>
              </div>
          </div>
          <div class="col-3">
            <label for="matricula">Matrícula</label>
            <form class="form-inline">
                <div class="form-group pr-3 mb-2">
                  <input type="text" class="form-control" [formControl]="busqueda.get('matricula')" id=matricula placeholder="Matrícula">
                </div>
                <button class="btn btn-danger mb-2" [disabled]="busqueda.invalid" (click)="buscar()">Buscar</button>
              </form>
          </div>
    </div>
    <div *ngIf="!bandera2">
        <div class="row" *ngIf="!bandera">
            <div class="col-12">
                <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" [settings]="settings" [source]="auditoria"></ng2-smart-table>
            </div>
        </div>
        <div class="row" *ngIf="bandera">
            <div class="col-12">
                <div class="text-center">
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>
