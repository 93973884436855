<div class="row" [formGroup]="formatoForm">
    <div class="col-6">
        <div class="form-group">
            <label for="periodo">Período</label>
            <select [formControl]="formatoForm.get('periodoid')" class="form-control" id="periodo">
                <option value="">--Período Escolar--</option>
                <option *ngFor="let periodo of periodos" [value]="periodo.id">{{ periodo.periodo }}</option>
            </select>
          </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <label for="plan">Plan de Estudios</label>
            <select [formControl]="formatoForm.get('planestudiosid')" class="form-control" id="plan">
                <option value="">--Plan de Estudios--</option>
                <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
            </select>
          </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <button class="btn btn-danger btn-block" (click)="consultarFormato()">Generar Formato</button>
    </div>
</div>

<div class="modal fade" id="f" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="static">

            </h5>
            <button type="button" (click)="formatoLiberacionSalir()" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderaPDF">
                <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="formatoLiberacionSalir()">Salir</button>
        </div>
        </div>
    </div>
</div>