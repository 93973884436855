import { Component, Input, OnInit } from '@angular/core';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
import { PersonalService } from '../services/personal.service';
declare var $ : any;

@Component({
  selector: 'app-boletas',
  templateUrl: './boletas.component.html',
  styleUrls: ['./boletas.component.css']
})
export class BoletasComponent implements OnInit {

  @Input() matricula: string;
  @Input() matriculap: string;
  @Input() idperiodo: string;

  src;
  type = FileMimeType.PDF;
  banderapdf:boolean = true;

  constructor(private personalService: PersonalService) { }

  ngOnInit(): void {
    this.consultarboleta();
  }

  consultarboleta(){
    this.personalService.boletaescolares(this.idperiodo, this.matricula,this.matriculap).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderapdf = false;
      }
    });
  }
}
