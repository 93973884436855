<div>
    <h3>Importación de datos del personal</h3>
    <div class="row">
        <div class="col-4">
                    <input type="text" [formControl]="busqueda.get('dato')" class="form-control" id="matricula" placeholder="Matricula" autocomplete="off">
        </div>
        <div class="col-4">
                <select [formControl]="busqueda.get('todo')" class="form-control" id="todo" >
                    <option value="0">Importar solo el nombre y apellidos</option>
                    <option value="1">Importar todos los dato disponibles</option>
                </select>
        </div>
        <div class="col-4">
                <button class="btn btn-danger mb-2" [disabled]="busqueda.invalid" (click)="Actualizar()">Actualizar</button>
        </div>
    </div>
    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="aceptar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ¿Estas se seguro que quieres importar los datos?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="enviar()">Actualizar</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</div>
