<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>

    <div class="bg-white" *ngIf="!bandera">
        <ng2-smart-table class="table table-hover table-borderless table-striped" [settings]="settings" [source]="historialkardexfiltrado"></ng2-smart-table>
    </div>
    
    <!-- <div class="bg-white">
        <table class="table table-sm- table-borderless table-hover">
            <thead class="bg-danger text-white">
                <tr>
                    <th>Semestre</th>
                    <th>Clave</th>
                    <th>Materia</th>
                    <th>Acta</th>
                    <th>Fecha</th>
                    <th>Tipo Exámen</th>
                    <th>Calificación</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let materia of historialkardex; let i = index">
                    <td>{{ materia.semestre }}{{ materia.grupo }}</td>
                    <td>{{ materia.cve }}</td>
                    <td>{{ materia.materia }}</td>
                    <td>{{ materia.acta }}</td>
                    <td>{{ materia.fecha }}</td>
                    <td>{{ materia.tipoexamen }}</td>
                    <td>
                        <div class="form-group">
                            <input type="number" class="form-control form-control-sm" id="calificacion{{i}}" value="{{ materia.calificacion | number }}">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->

</body>
</html>
