import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalDataSource } from 'ng2-smart-table';
import { PersonalService } from '../services/personal.service';
declare var $ : any;
@Component({
  selector: 'app-alta-alumnos-nuevo-ingreso',
  templateUrl: './alta-alumnos-nuevo-ingreso.component.html',
  styleUrls: ['./alta-alumnos-nuevo-ingreso.component.css']
})
export class AltaAlumnosNuevoIngresoComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  uploadForm: FormGroup;
  carrerasactivos = [];
  NewForm = new FormGroup({
    carrera: new FormControl('', [Validators.required]),
    periodo: new FormControl('',[Validators.required]),
    file: new FormControl('')
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  periodoidseleccionado;
  periodosabiertos = [];
  nombreplanestudios = '';
  planestudiosseleccionado;
  semestresactivos = [];
  banderacarrera:boolean = true;
  banderasemestres:boolean = true;
  nombreperiodo = '';
  semestresfiltrados = [];
  periodosescolarescompleta = [];
  periodosescolarescompletacerradas = [];
  periodossecolarescompletaabiertas = [];
  periodoescolaractivos = [];
  
  constructor(private personal: PersonalService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
    this.consultarlistaperiodosescolarescompleta();
    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required, ]
    });
    
  }
  consultarlistaperiodosescolarescompleta(){
    this.personal.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolarescompleta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodosescolarescompleta.length; i++){
          if(this.periodosescolarescompleta[i].estado == "CERRADO"){
            this.periodosescolarescompletacerradas.push(this.periodosescolarescompleta[i]);
          }else{
            if(this.periodosescolarescompleta[i].estado == "ABIERTO" || "ACTUAL"){
              this.periodossecolarescompletaabiertas.push(this.periodosescolarescompleta[i]);
            }
          }
        }
        this.periodosescolarescompletacerradas.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    });
  }
  consultarperiodoescolares(){
    this.personal.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodoescolaractivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.periodoescolaractivos.length; i++){
          if(this.periodoescolaractivos[i].estado == "ABIERTO" || "ACTUAL"){
            this.periodosabiertos.push(this.periodoescolaractivos[i]);
          }
        }
        this.consultarcarrerasactivos();
      }
    });
  }
  consultarcarrerasactivos(){
    this.personal.consultarcarreras(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.carrerasactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }
  seleccionarperiodo(){
    var periodoid = <HTMLSelectElement>document.getElementById('periodoescolar');
    this.periodoidseleccionado = periodoid.value;
    console.log(this.periodoidseleccionado);
    for(var i = 0; i < this.periodosabiertos.length; i++){
      if(this.periodoidseleccionado == this.periodosabiertos[i].id){
        this.nombreperiodo = this.periodosabiertos[i].nombre;
      }
    }
    this.banderacarrera = false;
  }

  seleccionarcarrera(){
    this.semestresfiltrados = [];
    var idplanstudios = <HTMLSelectElement>document.getElementById('carrera');
    this.planestudiosseleccionado = idplanstudios.value;
    console.log(this.planestudiosseleccionado);
    console.log(this.carrerasactivos);
    for(var i = 0; i < this.carrerasactivos.length; i++){
      if(this.planestudiosseleccionado == this.carrerasactivos[i].idplanestudio){
        this.nombreplanestudios = this.carrerasactivos[i].plandeestudio;
      }
    }
    //this.consultarsemestresactivos();
    //sthis.consultarperiodoescolaranterior();
    // for(var i = 0; i < this.semestresactivos.length; i++){
    //   if(this.carreraidseleccionado == this.semestresactivos[i].idcarrera){
    //     this.semestresfiltrados.push(this.semestresactivos[i]);
    //   }
    // }
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  enviar(){
    let respuestas = [];
    this.NewForm.patchValue({ file:this.uploadForm.get('file').value });

    this.NewForm.patchValue({ carrera: this.planestudiosseleccionado});
    this.NewForm.patchValue({ periodo: this.periodoidseleccionado});
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    formData.append('carrera', this.NewForm.get('carrera').value);
    formData.append('periodo', this.NewForm.get('periodo').value);  
    console.log(this.NewForm.value);
    // console.log(formData);
    // console.log(this.uploadForm.get('file').value); 

  this.personal.nuevoingreso(formData).subscribe({
    next:(data : any) => {
      respuestas.push(data);
      if(this.respuesta.codigo == "ERROR"){
        //this.bandera = false;
      }
    },
    error:(err) => {
      console.log(err);
    },
    complete:() => {
        this.respuesta = respuestas[0];
        $('#respuesta').modal('show');
        this.NewForm.reset();
      
    }
  });

  }
  consultarsemestresactivos(){
    this.personal.consultarsemestres(this.maestro.matricula, this.planestudiosseleccionado).subscribe({
      next:(data : any) => {
        this.semestresactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semestresactivos.sort(function (a, b) {
          if (a.semestre > b.semestre) {
            return 1;
          }
          if (a.semestre < b.semestre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.banderasemestres = false;
        
      }
    });
  }

}
