<body>

    <div>
        <h4>Captura de Fechas para Calificaciones</h4>
    </div>

    <div class="row">
        <div class="col-lg-6">

            <div class="form-group">
                <select class="form-control" id="periodo" (change)="selecionarperiodo()">
                  <option>--Período Escolar--</option>
                  <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                </select>
            </div>

        </div>
        <div class="col-lg-6"> 

            <div class="form-group" *ngIf="!banderacarrera">
                <select class="form-control" id="carrera" (change)="selecionarcarrera()">
                  <option>--Carrera--</option>
                  <option *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{ carrera.carrera }}</option>
                </select>
            </div>

        </div>
    </div>

    <div class="row" id="top">
        <div class="col-lg-12">
            <div *ngIf="!banderasemestres">
                <h5>Asignar Semestres</h5>
            </div>
            <div *ngIf="!banderasemestres">
                <div class="form-check form-check-inline form-control-lg" *ngFor="let semestre of semestresfiltrados; let i = index">
                    <input class="form-check-input" (click)="semestresselecionados(semestre.semestre, $event)" type="checkbox" id="inlineCheckbox{{i}}" value="{{semestre.semestre}}">
                    <label class="form-check-label" for="inlineCheckbox{{i}}">{{ semestre.semestre }}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-5">
            <div class="pt-3">
                <h5>Examenes Ordinarios</h5>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-4">
            <div class="pt-3">
                <label>Fecha de Inicio *</label>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="examenordinarioinicio" [options]="myDpOptions" 
                    (dateChanged)="examenordinarioi($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="pt-3">
                <label>Fecha de Fin *</label>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha fin" 
                    angular-mydatepicker #dp2="angular-mydatepicker" 
                    [(ngModel)]="examenordinariofin" [options]="myDpOptions" 
                    (dateChanged)="examenordinariof($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp2.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-5">
            <div class="pt-3">
                <h5>Examenes Extraordinarios</h5>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-4">
            <div class="pt-3">
                <label>Fecha de Inicio *</label>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp3="angular-mydatepicker" 
                    [(ngModel)]="examenextraordinarioinicio" [options]="myDpOptions" 
                    (dateChanged)="examenextraordinarioi($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp3.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="pt-3">
                <label>Fecha de Fin *</label>
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha fin" 
                    angular-mydatepicker #dp4="angular-mydatepicker" 
                    [(ngModel)]="examenextraordinariofin" [options]="myDpOptions" 
                    (dateChanged)="examenextraordinariof($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp4.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-5">
            <div class="pt-3">
                <h5>Examenes Especiales</h5>
            </div>
        </div>
    </div>

    <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-4">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp5="angular-mydatepicker" 
                    [(ngModel)]="preactasordinariasinicio" [options]="myDpOptions" 
                    (dateChanged)="examenESI($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp5.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha fin" 
                    angular-mydatepicker #dp6="angular-mydatepicker" 
                    [(ngModel)]="preactasordinariasfin" [options]="myDpOptions" 
                    (dateChanged)="examenESF($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp6.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-5">
            <div class="pt-3">
                <h5>Preactas Extraordinarias</h5>
            </div>
        </div>
    </div> -->

    <!-- <div class="row" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-4">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha inicio" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="preactasextraordinariasinicio" [options]="myDpOptions" 
                    (dateChanged)="preactasextraordinariasi($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha fin" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="preactasextraordinariasfin" [options]="myDpOptions" 
                    (dateChanged)="preactasextraordinariasf()"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row pt-4" id="top" *ngIf="!banderasemestres">
        <div class="col-lg-12">
            <div>
                <button class="btn btn-danger" (click)="guardarcambios()">Guardar</button>
            </div>
        </div>
    </div>

    <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ respuesta.mensaje }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
    </div>


    <div class="modal fade" id="validacion" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ validacion }}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
    </div>



</body>