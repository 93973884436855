import { Component, OnInit, Input } from '@angular/core';
import { PersonalService } from '../services/personal.service';

@Component({
  selector: 'app-historial-alumnos-sesco',
  templateUrl: './historial-alumnos-sesco.component.html',
  styleUrls: ['./historial-alumnos-sesco.component.css']
})
export class HistorialAlumnosSescoComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  };

  settings = {
    columns:{
      semestre:{
        title:'Semestre',
        editable:false,
        width:'5%'
      },
      clave:{
        title:'Clave',
        editable:false,
        width:'10%'
      },
      materia:{
        title:'Materia',
        editable:false,
        width:'40%'
      },
      acta:{
        title:'Acta',
        editable:false,
        width:'5%'
      },
      fecha:{
        title:'Fecha',
        editable:false,
        width:'20%'
      },
      tipoexamen:{
        title:'Exámen',
        editable:false,
        width:'15%'
      },
      calificacion:{
        title:'Calificación',
        editable:false,
        width:'5%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false
    },
    pager:{
      perPage:100
    },
    noDataMessage:'Sin datos disponibles.'
  }

  historialkardex;
  historialkardexfiltrado = [];
  bandera:boolean = true;
  @Input() alumno: string;

  constructor(private personalservice: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarhistorial();
  }

  consultarhistorial(){
    this.bandera = true;
    var jason = {
      dato: this.alumno,
      matriculap: this.maestro.matricula
    }
    this.personalservice.consultarKardexLargo(jason).subscribe({
      next:(res) => {
        this.historialkardex = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        for(var i = 0; i < this.historialkardex.length; i++){
          var jason = {
            semestre:this.historialkardex[i].semestre+this.historialkardex[i].grupo,
            clave:this.historialkardex[i].cve,
            materia:this.historialkardex[i].materia,
            acta:this.historialkardex[i].acta,
            fecha:this.historialkardex[i].fecha,
            tipoexamen:this.historialkardex[i].tipoexamen,
            calificacion:this.historialkardex[i].calificacion
          }
          this.historialkardexfiltrado.push(jason);
        }
        this.bandera = false;
      }
    });
  }

}
