<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">   
              <h2 class="pt-3">Aulas</h2>
                  <!-- -------------------Modal para editar aulas------------------------------------------------ -->
                  <form [formGroup]='editForm' (ngSubmit)="onWarningEdit()">
                    <div class="modal fade" id="modalEditAulasForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <table class="table table-striped">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <div class="modal-header text-center">
                                    <h4 class="modal-title w-100 font-weight-bold">Editar Aula</h4>
                                    <button (click)='onClose()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="modal-body mx-3">
                                            <div class="md-form mb-3">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Aula</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.editForm.get('aula')" id="aulaInput">
                                            </div>
                                            <div class="md-form mb-3">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Edificio</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.editForm.get('edificio')" id="edificioInput">
                                            </div>
                                            <div class="md-form mb-3">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Capacidad</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.editForm.get('capacidad')" id="capacidadInput">
                                            </div>
                                    
                                          </div>
                                          <div class="md-form mb-4">
                                            <button (click)='onClose()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                            <button [disabled]='!editForm.valid'  type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                      </div>
                    </div>
                  </div>
                </form>
                <!-- --------------------------------------------------------------------------------------------------------- -->
                <!-- -------------------Modal para crear aulas------------------------------------------------ -->
                <form [formGroup]='createForm' (ngSubmit)="onCreate()">
                    <div class="modal fade" id="modalCreateAulasForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <table class="table table-striped">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <div class="modal-header text-center">
                                    <h4 class="modal-title w-100 font-weight-bold">Crear Aula</h4>
                                    <button (click)='onClose()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="modal-body mx-3">
                                            <div class="md-form mb-5">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Aula</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.createForm.get('aula')" id="aulaInputN">
                                            </div>
                                            <div class="md-form mb-5">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Edificio</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.createForm.get('edificio')" id="edificioInputN">
                                            </div>
                                            <div class="md-form mb-5">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Capacidad</label>
                                                <input  type="text" class="form-control validate" [formControl]="this.createForm.get('capacidad')" id="capacidadInputN">
                                            </div>
                                    
                                          </div>
                                          <div class="md-form mb-4">
                                            <button (click)='onClose()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                            <button [disabled]='!createForm.valid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                          
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                      </div>
                    </div>
                  </div>
                </form>
                <!-- --------------------------------------------------------------------------------------------------------- -->
                
                    <!----------------------- lista de aulas ------------------------->
                    <table class="table table-sm table-striped table-hover table-borderless">
                        <thead class="bg-danger text-white">
                            <tr>

                                <th scope="col">Aula</th>
                                <th scope="col">Edificio</th>
                                <th scope="col">Capacidad</th>
                                <th scope="col"></th>
                                <th scope="col">
                                    <button data-target="#modalCreateAulasForm" data-toggle="modal" class="text-light" id="btn-add-aula"><i id="plus" class="fas fa-plus"></i></button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let aula of response">
                                <td scope="col">{{aula.nombre}}</td>
                                <td scope="col">{{aula.edificio}}</td>
                                <td scope="col">{{aula.capacidad}}</td>
                                <td>
                                    <button (click)="onEdit(aula.nombre,aula.edificio,aula.capacidad, aula.id)" data-target="#modalEditAulasForm" data-toggle="modal"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
                                </td>
                                <td scope="col">
                                    <button (click)='onDelete(aula.id,aula.nombre)' class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                    <!-- <button (click)="onDelete(clasificacion.id)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-------------------- modal de aviso de que se realizo la accion------------------------->
                    <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header" *ngIf="this.mensajeAvisoError==true">
                              <h5 class="modal-title" id="exampleModalLabel">ADVERTENCIA!</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" *ngIf="this.mensajeAvisoError==true">
                                {{this.mensajeAviso}}
                            </div>
                            <div class="modal-header"  *ngIf="this.mensajeAvisoError==false">
                                <h5 class="modal-title" id="exampleModalLabel">Felicidades!</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"  *ngIf="this.mensajeAvisoError==false">
                                {{this.mensajeAviso}}
                                <label><strong>{{this.aulaDatos.nombre}}</strong></label>
                            </div>
                            
                            <div class="modal-footer">
                              <button type="button"  class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button (click)='recargarLista()' type="button" class="btn btn-danger" data-dismiss="modal" >Aceptar</button>
                            </div>
                          </div>
                        </div>
                      </div>
            
                        <!-- ----------------------modal para que el usuario confirme que si desea realizar la accion------------------------------------------------------ -->
                        <div class="modal fade" id="confirmacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  {{this.mensaje}}
                                  <label><strong>{{this.aulaDatos.nombre}}</strong></label>
                                </div>
                                <div class="modal-footer">
                                  <button type="button"  class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onFinish()">Aceptar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                    
                        
                </div>
            </div>
        </div>
    </body>
</html>


