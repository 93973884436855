<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body #htmlData class="bg-white">
    <div class="container">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Matrícula</th>
                            <th>Nombre</th>
                            <th>Promedio</th>
                            <th>Bloque</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dato of datos">
                            <td>{{ dato.matricula }}</td>
                            <td>{{ dato.nombres }}</td>
                            <td>{{ dato.promedio }}</td>
                            <td>{{ dato.bloque }}</td>
                            <td>{{ dato.fechahora }}</td>
                            <td>{{ dato.horasdia }}</td>
                        </tr>
                    </tbody>
                </table>             
    </div>
</body>
<button class="btn btn-danger" (click)="downloadPDF(htmlData)">Descargar</button>
</html>