<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    
    <div *ngIf="banderaspinner">
        <div class="d-flex justify-content-center">
            <div class="spinner-border text-danger" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
    </div>
    <div *ngIf="!banderaspinner">
        <div class="row">
            <div class="col-12 pb-2">
                <button class="btn btn-danger" (click)="exportarexcel()">Exportar</button>
            </div>
        </div>
        <table class="table table-sm table-hover table-borderless table-striped">
            <thead class="bg-danger text-white">
                <tr>
                    <th style="width:10%">Matrícula</th>
                    <th style="width:25%">Alumno</th>
                    <th style="width:20%">CURP</th>
                    <th style="width:45%"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alumno of alumnosfiltrados; let i = index">
                    <td>{{ alumno.matricula }}</td>
                    <td>{{ alumno.alumno }}</td>
                    <td>{{ alumno.curp }}</td>
                    <td>
                        <div class="form-group row">
                            <div class="col">
                                <select class="form-control" id="periodoingreso{{i}}">
                                    <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                                </select>                        
                            </div>
                            <div class="col">
                                <select class="form-control" id="planestudio{{i}}">
                                    <option *ngFor="let plan of planestudios" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{ plan.plandeestudio }})</option>
                                </select>
                            </div>
                            <div class="col">
                                <button *ngIf="!banderaDetalles" class="btn btn-danger mr-2" (click)="veralumno(i)"><i class="fas fa-list"></i></button>
                                <button *ngIf="!banderaImportar" class="btn btn-danger" (click)="importaralumno(i)">Importar</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal fade" id="datos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <!-- <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ this.alumno.get('apellido_paterno').value }} {{ this.alumno.get('apellido_materno').value }} {{ this.alumno.get('nombre').value }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
              <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="nombres">Nombre</label>
                        <input type="text" readonly class="form-control" id="nombres" [formControl]="this.alumno.get('nombre')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="apellido_paterno">Apellido Paterno</label>
                        <input type="text" readonly class="form-control" id="apellido_paterno" [formControl]="this.alumno.get('apellido_paterno')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="apellido_materno">Apellido Materno</label>
                        <input type="text" readonly class="form-control" id="apellido_materno" [formControl]="this.alumno.get('apellido_materno')">
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="calle">Calle</label>
                        <input type="text" readonly class="form-control" id="calle" [formControl]="this.alumno.get('calle')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="colonia">Colonia</label>
                        <input type="text" readonly class="form-control" id="colonia" [formControl]="this.alumno.get('colonia')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="codigo">Código Postal</label>
                        <input type="text" readonly class="form-control" id="codigo" [formControl]="this.alumno.get('codigo')">
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="curp">CURP</label>
                        <input type="text" readonly class="form-control" id="curp" [formControl]="this.alumno.get('curp')">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="rfc">RFC</label>
                        <input type="text" readonly class="form-control" id="rfc" [formControl]="this.alumno.get('rfc')">
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="matricula">Matrícula</label>
                        <input type="text" readonly class="form-control" id="matricula" [formControl]="this.alumno.get('matricula')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="email">Correo Electrónico</label>
                        <input type="text" readonly class="form-control" id="email" [formControl]="this.alumno.get('email')">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="celular">Celular</label>
                        <input type="text" readonly class="form-control" id="celular" [formControl]="this.alumno.get('celular')">
                    </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Understood</button>
            </div> -->
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="aviso" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="!bandera ">
              ¡Estás seguro de importar el alumno <strong>{{ a.apellidop }} {{ a.apellidom }} {{ a.nombres }}</strong> en el período <strong>{{ planingreso.carrera }} - ({{ planingreso.plandeestudio }})</strong>?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="importar()" data-dismiss="modal">Aceptar</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

</body>
</html>