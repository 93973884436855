import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfiguracionesService } from 'src/app/configuraciones/configuraciones.service';
import {ConfGeneralServiceService} from '../service/conf-general-service.service';
declare var $ : any;

@Component({
  selector: 'app-inicio-conf-general',
  templateUrl: './inicio-conf-general.component.html',
  styleUrls: ['./inicio-conf-general.component.css']
})
export class InicioConfGeneralComponent implements OnInit {
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }
  createForm = new FormGroup({
    parametro: new FormControl('',[Validators.required,Validators.minLength(3)]),
    valor: new FormControl('', [Validators.required]),
    valor_max: new FormControl('', [Validators.required]),
    periodo_id: new FormControl('')
  });

  editForm = new FormGroup({
    id: new FormControl(''),
    parametro: new FormControl('',[Validators.required,Validators.minLength(3)]),
    valor: new FormControl('', [Validators.required]),
    valor_max: new FormControl(''),
    periodo_id: new FormControl('')
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  } 

  periodosescolares = [];
  configuraciones = [];
  bandera = true;
  constructor(private service:ConfGeneralServiceService) { }
  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listConf();
    this.consultarperiodosescolares();
  }
//lista las configuraciones
  listConf(){
    this.service.listaConf().subscribe({
      next: (res : any)=>{
        this.configuraciones = res;
        //console.log(this.configuraciones);
        
      },
      error: () => {
        throw new Error('Error al listar tutoriales')
      },
      complete:() => {
        this.bandera = false;
      }
    })
  }
//lista los periodos
  consultarperiodosescolares(){
    this.service.listaperiodosescolares(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.periodosescolares = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.periodosescolares);
      }
    });
  }
//agregar nuevo parametro
  addConf(){
    $('#formadd').modal('show');
  }
  enviarCreateForm(){
    var periodo_id = <HTMLSelectElement>document.getElementById('periodo');
    var jason ={
      parametro:this.createForm.get('parametro').value,
      valor:this.createForm.get('valor').value,
      valor_max:this.createForm.get('valor_max').value,
      periodo_id:periodo_id.value
    };
    console.log(jason);
    this.service.insertar(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.createForm.patchValue({
          id:'',
          parametro:'',
          valor: '',
          valor_max:'',
          periodo_id:''
        })
        this.listConf();
        $('#formadd').modal('hide');
        $("#periodo").prop('selectedIndex',0);
        $('#respuesta').modal('show');
      }
    });

  }
//Editar un parametro
  editConf(id,par, val, val_max, per_id){
    this.editForm.patchValue({
      id:id,
      parametro:par,
      valor: val,
      valor_max:val_max,
      periodo_id:per_id
    })
    $("#periodoE").val(per_id);
    $('#formEdit').modal('show');
  }
  enviarEditForm(){
    var periodo_id = <HTMLSelectElement>document.getElementById('periodoE');
    var jason ={
      id:this.editForm.get('id').value,
      parametro:this.editForm.get('parametro').value,
      valor:this.editForm.get('valor').value,
      valor_max:this.editForm.get('valor_max').value,
      periodo_id:parseInt(periodo_id.value)
    };
    console.log(jason);
    this.service.edit(jason).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.editForm.patchValue({
          id:'',
          parametro:'',
          valor: '',
          valor_max:'',
          periodo_id:''
        })
        this.listConf();          
        $('#formEdit').modal('hide');
        $("#periodoE").prop('selectedIndex',0);
        $('#respuesta').modal('show');
      }
    });

  }
}
