
<div class="card">
    <div class="card-body">
        <i [class]="icon"></i>
        <span><strong>Bienvenido: </strong></span>
        <span>{{ alumno }}</span>
        <span> {{ matricula }} </span>
        <div class="float-right" *ngFor="let link of links">
          <a class="h5" [routerLink]="link.path"><i class=" fas fa-question-circle " data-toggle="tooltip" data-placement="right" title="{{link.title}}"></i></a>  
        </div>
        
        <!-- <hr>
      <h6 id="buttomlabel" class="card-title">Carrera: <span>{{ carrera }}</span></h6> -->
    </div>
  </div>