import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from 'src/global';
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
@Injectable({
  providedIn: 'root'
})
export class ConfGeneralServiceService {

  constructor(private http: HttpClient, private global: Global) { }

  listaConf(){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/configuracion/list`, null);
  }
  listaPeriodosEscolaresCompleta(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listado/${matriculap}`, null)
  }
  listaperiodosescolares(matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/listaAbiertoEscolar/${matriculap}`, null)
  }
  insertar(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/configuracion/insert`, doc, {headers: headers});
  }
  edit(doc: any){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/configuracion/edit`, doc, {headers: headers});
  }
}
