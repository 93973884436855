<div class="container">
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">
                    <div class="card rounded shadow shadow-sm">
                        <div class="card-header" id="header">
                            <!-- <h3 class="mb-0">Inicia Sesion</h3> -->
                            <div>
                                <img src="../../../assets/img/logov2.2.png" width="100%">
                                
                            </div>
                            <div>
                                <label><strong>IMPORTANTE</strong>
                                    ¡Accede al sistema, a través de alguno de los siguientes exploradores desde un equipo de cómputo: 
                                    Google Chrome, Internet Explorer, Microsoft Edge; cualquiera de ellos en sus últimas versiones. 
                                    No compatible con SAFARI ni dispositivos móviles!
                                </label>
                            </div>
                        </div>
                        <div class="card-body">
                            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                                <app-input icon="fas fa-user" placeholder="Matrícula" type="text" [control]="this.loginForm.get('usuario')"></app-input>
                                <app-input icon="fas fa-lock" placeholder="Contraseña" type="password" [control]="this.loginForm.get('password')"></app-input>
                                <div *ngIf="loginForm.errors"  class="alert alert-danger" role="alert">
                                    <p class="errors" *ngIf="loginForm.errors.credentials">
                                        El usuario o contraseña proporcionados son invalidos
                                    </p>
                                    <p class="errors" *ngIf="loginForm.errors.noConnection">
                                        En proceso de actualización, intente más tarde!
                                    </p>
                                    <p class="errors" *ngIf="loginForm.errors.unknownError"> 
                                        En proceso de actualización, intente más tarde!
                                    </p>
                                    <p class="errors" *ngIf="loginForm.errors.incomplete">
                                        Debes de ingresar tu matricula y contraseña!
                                    </p>
                                </div>
                                <div class="button">
                                    <button type="submit" class="btn btn-danger">Ingresar</button>
                                </div>
                                <div class="olvideContraseña">
                                    <label  type="button" class="text-secondary" routerLink="/resetPass"><a class="hover">Olvidé mi contraseña</a></label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>