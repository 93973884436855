<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  <script src="alumnos.component.ts"></script>
</head>
<body>
    <div class="container-fluid" *ngIf="banderahttp">
        <div class="row" id="top"></div>
        <div class="row">
            <!-- <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div> -->
            <div class="col-lg-12">
                <!-- <div class="row" id="top">
                    <div class="col-lg-7">
                        <app-bienvenido icon="fas fa-user-tie" alumno="{{ maestro.nombres | uppercase }} {{ maestro.apellidop | uppercase }} {{ maestro.apellidom | uppercase }}"
                                matricula="{{ maestro.matricula }}"></app-bienvenido>
                    </div>
                </div> -->
                <!-- <div class="row" id="top">
                    <div class="col-7">
                        <div class="card">
                            <div class="card-body">
                                <h3>Listado de Alumnos</h3>
                                <hr>
                                <nav>
                                    <a class="card-link agregar" (click)="agregaralumno()">Agregar Alumnos <i id="icon" class="fas fa-plus"  title="Agregar"></i></a>
                                    <a class="card-link agregar" routerLink="/alumnos-sesco">Importar Alumnos de SESCO</a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row" id="top">
                        <div class="col-12">
                            <form [formGroup]="busqueda">
                                <div class="row">
                                    <div class="form-group col-6">
                                        <input type="text" class="form-control" [formControl]="this.busqueda.get('nombre')" id="Nombre" placeholder="Ingrese Matrícula o Nombre de Alumno">
                                    </div>
                                        <div class="form-group ">
                                            <div class="col">
                                                <button class="btn btn-danger mr-1" (click)="reset(); onSubmit()"><i id="icon" class="fas fa-search"  title="Buscar"></i>Buscar</button>
                                                <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Activar Filtros</button>
                                            </div>
                                        </div>
                                        <div class="form-group " *ngIf="!banderaExportarAlumnos">
                                            <div class="col" >
                                                <button *ngIf="!banderaExportarAlumnos" class="btn btn-danger" (click)="exportAsXLSX()" data-toggle="tooltip" data-placement="top" title="Descargar Registros"><i id="icon" class="fas fa-download"></i>Exportar</button>
                                            </div>
                                        </div>
                                </div>
                                <div class="collapse" id="collapseExample">
                                    <div class="card card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                <label for="plan">Plan de Estudio</label>
                                                <select (change)="opciones6()" class="form-control" id="plan">
                                                    <option value="">--Plan de Estudios--</option>
                                                    <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{ plan.carrera.nombre }} - ({{ plan.nombre }})</option>
                                                </select>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="semestre">Semestre</label>
                                                    <select class="form-control" id="semestre">
                                                    <option value="">--Semestre--</option>
                                                    <option *ngFor="let semestre of semestres" [value]="semestre.semestre">{{ semestre.semestre }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="regular">Regular</label>
                                                    <select class="form-control" id="regular">
                                                    <option value="">--Regular--</option>
                                                    <option value="SI">SI</option>
                                                    <option value="NO">NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="estatus">Estatus</label>
                                                    <select class="form-control" id="estatus">
                                                    <option value="">--Estatus--</option>
                                                    <option value="NUEVO INGRESO">NUEVO INGRESO</option>
                                                    <option value="REINGRESO">REINGRESO</option>
                                                    <option value="REINGRESO+">REINGRESO+</option>
                                                    <option value="EGRESADO">EGRESADO</option>
                                                    <option value="BAJA">BAJA</option>
                                                    <option value="SUSPENSION DE INSCRIPCION">SUSPENSION DE INSCRIPCION</option>
                                                    <option value="BAJA DEFINITIVA">BAJA DEFINITIVA</option>
                                                    <option value="BAJA TEMPORAL">BAJA TEMPORAL</option>
                                                    <option value="INACTIVO">INACTIVO/NO INSCRITO</option>
                                                    <option value="INACTIVO+">INACTIVO/+4 PERÍODOS</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="periodoingreso">Período Escolar Ingreso</label>
                                                    <select class="form-control" id="periodoingreso">
                                                        <option value="">--Período Escolar--</option>
                                                        <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.periodo }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="periodoegreso">Período Escolar Egreso</label>
                                                    <select class="form-control" id="periodoegreso">
                                                        <option value="">--Período Escolar--</option>
                                                        <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.periodo }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="periodobaja">Período Escolar Baja</label>
                                                    <select class="form-control" id="periodobaja">
                                                        <option value="">--Período Escolar--</option>
                                                        <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.periodo }}</option>
                                                    </select>
                                                </div>
                                            </div>                 
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row" id="top">
                                <div class="col-lg-12">
                                    <div id="top" [hidden]="bandera">
                                        <div>
                                            <ng2-smart-table class="table table-hover table-borderless table-striped" (userRowSelect)="abrirTareas($event)" [settings]="settings" [source]="alumnos"></ng2-smart-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12" *ngIf="!banderaspinner">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border text-danger" style="width: 5rem; height: 5rem;" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div class="modal fade" id="staticBackdropA" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            Matrícula: {{ matriculaAlumno }}<br> {{ this.nombreAlumno }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-12" [hidden]="!mostrar">
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaKardex" class="btn btn-danger btn-block mb-1" (click)="verSeleccionKardex()" data-dismiss="modal" >Kárdex</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="!banderaKardexLargo" class="btn btn-danger btn-block mb-1" (click)="verKardexlargo()" data-dismiss="modal" >Kárdex Largo</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaHorarioAlumno" class="btn btn-danger btn-block mb-1" (click)="verHorario()" data-dismiss="modal" >Horario</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="!banderaValidarDocumentos" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verDocuentos(matriculaAlumno)">Revisar Documentos</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaBajaAlumno" class="btn btn-danger btn-block mb-1" (click)="btnBajas()">Baja/Reingreso</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="(alumnoestatus == 'REINGRESO' || alumnoestatus == 'REINGRESO+' || alumnoestatus == 'NUEVO INGRESO') && !banderaAgregarMaterias" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verAltaMaterias()">Agregar Materias</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaCalificaciones"  class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verCalificaciones()">Calificaciones</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="!banderaConstancia" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verSeleccionConstancia()">Constancia</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaDatosPersonales" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verDatosPersonales()">Datos Personales</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="!banderaBoleta" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verPeriodosescolares()">Boleta</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaRestablecerContra" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="restablecercontras()">Restablecer contraseña</button>
                                            </div>
                                            <div class="col-6">
                                                <button *ngIf="!banderaModificarTiempo" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="agregartiempo()">Modificar tiempo de inscripción</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaCambiarSemestre" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="vercambiarsemestre()">Cambiar Semestre</button>
                                            </div>
                                            <div class="col-6">
                                                    <button *ngIf="!banderaCambiarEstatus" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="cambiarestatussemestre()">Cambiar Estatus</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaCambiarSemestre" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="agregarPlan()">Agregar plan de estudio</button>
                                            </div>
                                            
                                            <div class="col-6">
                                                <button *ngIf="!banderaCambiarSemestre" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="historialMovimientos()">Movimientos</button>
                                            </div>
                                        </div>
                                        
                                        <!-- <div class="row">
                                            <div class="col-6">
                                                <button *ngIf="!banderaAsistencias" class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verJustificantesModal()">Asistencias</button>
                                            </div>
                                        </div> -->
<!--                                      <div class="row">
                                        <div class="col-6">
                                          <button class="btn btn-danger btn-block mb-1" data-dismiss="modal" (click)="verhistorial()">Historal</button>
                                        </div>
                                      </div>-->
                                    </div>
                                    <div class="col-lg-12" [hidden]="mostrar">
                                        <label data-success="right" for="defaultForm-email" style="font-weight:bold;">Cambiar Estado</label>
                                        <div class="input-group mb-3">
                                            <select class="custom-select" id="scripts">
                                                <option selected>--BAJA/REINGRESO--</option>
                                                <option value="BAJA DEFINITIVA">BAJA DEFINITIVA</option>
                                                <option value="BAJA TEMPORAL">BAJA TEMPORAL</option>
                                                <option vlaue="SUSPENSION DE INSCRIPCION">SUSPENSION DE INSCRIPCION</option>
                                                <option value="REINGRESO">REINGRESO</option>
                                                <option value="REINGRESO+">REINGRESO+</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label data-success="right" for="defaultForm-email" style="font-weight:bold;">Fecha Solicitud   </label>
                                            <input type="date" #fechasolicitud name="fechasolicitud" id="fechasolicitud">
                                        </div>
                                        <label data-success="right" for="defaultForm-email" style="font-weight:bold;">Observaciones / Notas</label>
                                        <div>
                                            <textarea class="form-control"  rows="4" cols="50" #observaciones name="observaciones" id="observaciones"></textarea>
                                        </div>
                                        <label data-success="right" for="defaultForm-email" style="font-weight:bold;">Seleccionar Comprobante de Movimiento</label>
                                        <div>
                                            <input enctype="multipart/form-data" accept="application/pdf" #inputFile type="file" name="documento" (change)="onFileSelect($event)">
                                        </div>
                                        <div class="row" id="top"></div>
                                        <div>
                                            <button class="btn btn-danger btn-lg btn-block" (click)="darBaja()" data-dismiss="modal" [disabled]="this.uploadForm.get('file').value.size > 3145728 || this.uploadForm.get('file').value == '' || this.uploadForm.get('file').value == null ">Aplicar Movimiento</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="btnAcciones()">Cerrar</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

            <div class="modal fade" id="staticBackdropResponse" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            {{ response.mensaje }}
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onSubmit()">Aceptar</button>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="modal fade" id="justificantesO" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Justificantes</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="!banderajustificantes">
                                <app-justificantes [alumno]="matriculaAlumno"></app-justificantes>
                            </div>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                        <!-- <button type="button" class="btn btn-danger" (click)="buscarjustificantes()">Aceptar</button> -->
                        </div>
                    </div>
                    </div>
                </div>

                <div class="modal fade" id="staticBackdropDocumentos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Documentos subidos por el alumno</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div [hidden]="!banderaTable">
                                <table class="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>Documento</th>
                                            <th>Estatus</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let documento of documentos">
                                        <td>{{ documento.nombre }}</td>
                                        <td>
                                          <i *ngIf="documento.status == '1'" class="fas fa-spinner text-warning"></i>
                                          <i *ngIf="documento.status == '2'" class="fas fa-check text-success"></i>
                                          <i *ngIf="documento.status == '0'" class="fas fa-times text-danger"></i>
                                        </td>
                                        <td><button class="btn btn-danger btn-sm" (click)="visualizardocumento(documento.idregistro)">Visualizar</button></td>
                                        <!-- <td><button class="btn btn-sm btn-danger" (click)="imprimirDocumentos(documento.id)">Imprimir</button></td>
                                        <td><button class="btn btn-sm btn-danger"  data-dismiss="modal" (click)="validarDocumento(documento.id)">Validar</button></td> -->
                                    </tbody>
                                </table>
                                <div [hidden]="banderaMensajeDoc">
                                    <label>El alumno no cuenta con documentos registrados</label>
                                </div>
                            </div>
                            <div id="top"></div>
                            <div [hidden]="!banderaTable">
                                <table class="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>Documento de movimientos</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let doc of documentosBaja">
                                        <td>{{ doc.nombre }}</td>
                                        <td><button class="btn btn-sm btn-danger" (click)="imprimirDocumentoBaja(doc.id)">Imprimir</button></td>
                                    </tbody>
                                </table>
                                <div [hidden]="banderaMensajeBaja">
                                    <label>El alumno no cuenta con documentos registrados</label>
                                </div>
                            </div>
                            <div [hidden]="banderaTable">
                                <label>El alumno no cuenta con documentos registrados</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="btnAcciones()">Aceptar</button>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container-fluid" *ngIf="!banderahttp">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-9">
                <app-http-errors [status]="httpstatus" [statustext]="httpstatustext" [url]="httpurl"></app-http-errors>
            </div>
        </div>
    </div>

    <div class="modal fade" id="cambiarestatus" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Cambiar Estatus</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="estatusactual">Regular</label>
                  <input type="text" readonly [value]="alumnoregular" class="form-control" id="estatusactual">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="cambiaresta">Cambiar Estatus</label>
                  <select class="form-control" id="cambiaresta">
                    <option value="SI">REGULAR</option>
                    <option value="NO">IRREGULAR</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn secondary" data-dismiss="modal" (click)="verTareas()">Cancelar</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cambiarregularestatus()">Aceptar</button>
          </div>
        </div>
      </div>
    </div>

      <div class="modal fade" id="cambiarsemestre" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Cambiar Semestre</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="semestreactual">Semestre Actual</label>
                    <input type="text" [value]="alumnosemestre" class="form-control" id="semestreactual">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="cambiarsem">Cambiar Semestre</label>
                    <select class="form-control" id="cambiarsem">
                      <option *ngFor="let semestre of semestresplanestudios" [value]="semestre.semestre">{{ semestre.semestre }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn secondary" data-dismiss="modal" (click)="verTareas()">Cancelar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cambiarsemestrealumno()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

                <div class="modal fade" id="staticBackdropValidar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">{{ nombreDoc }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6">
                                        <button class="btn btn-danger" data-dismiss="modal" (click)="documentoValido()">VÁLIDO</button>
                                </div>
                                <div class="col-lg-6">
                                    <button class="btn btn-danger" data-dismiss="modal" (click)="documentoNoValido()">INVÁLIDO</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Aceptar</button>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="modal fade" id="staticBackdropValidarDocBaja" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Documentos de baja</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="btnAcciones()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6">
                                        <button class="btn btn-danger" data-dismiss="modal" (click)="documentoValidoBaja()">VÁLIDO</button>
                                </div>
                                <div class="col-lg-6">
                                    <button class="btn btn-danger" data-dismiss="modal" (click)="documentoNoValidoBaja()">INVÁLIDO</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="btnAcciones()">Aceptar</button>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="modal fade" id="staticBackdropRespuestaDoc" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            {{ respuesta.mensaje }}
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onSubmit()">Aceptar</button>
                        </div>
                    </div>
                    </div>
                </div>

                    <div class="modal fade" id="staticBackdropKardex" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Kárdex</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">

                                    <div *ngIf="!Kardex">
                                        <app-kardex [planestudioid]="planestudioid" [alumno]="matriculaAlumno" [firma]="firma"></app-kardex>
                                    </div>

                            </div>
                            <div class="modal-footer">
                                <!-- <button class="btn btn-danger" (click)="verSeleccionKardex()" data-dismiss="modal" >Otra firma</button> -->
                                <button (click)="imprimirkardex()" class="btn btn-danger">Imprimir PDF</button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="SelectFirmaKardex" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Kárdex</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <select class="form-control " id="firma" (change)="seleccionarAurizacion()">
                                        <option > <p class="text-muted">---Firma Autorizada--- </p> </option>
                                        <option *ngFor="let f of firmaAutorizada" value="{{ f.id }}">{{ f.nombrecompleto }} - {{f.puesto}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="SelectFirmaConstancia" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Constancia</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <select class="form-control " id="firmaC" (change)="seleccionarAurizacionConstancia()">
                                        <option > <p class="text-muted">---Firma Autorizada--- </p> </option>
                                        <option *ngFor="let f of firmaAutorizada" value="{{ f.id }}">{{ f.nombrecompleto }} - {{f.puesto}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropKardexLargo" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Kárdex Largo</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!KardexLargo">
                                    <app-kardex-largo [planestudioid]="planestudioid" [alumno]="matriculaAlumno"></app-kardex-largo>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" (click)="imprimirKardexLargo()">Imprimir</button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropConstancia" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Constancia</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Constancia">
                                    <app-constancia [planestudioid]="planestudioid" [firma]="firmaC"></app-constancia>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button *ngIf="!banderaImprimirConstancia" type="button" class="btn btn-danger" (click)="consultarconstancia()">Imprimir PDF</button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div class="modal fade" id="staticBackdropHorario" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Horario</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Horario">
                                    <app-horario-alumno></app-horario-alumno>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button *ngIf="!banderaImprimirHorario" type="button" class="btn btn-danger" (click)="horarioalumno()">Imprimir</button>
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropAltaMaterias" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Alta Materias</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!AltaMaterias">
                                    <app-inscripciones [alumno]="matriculaAlumno"></app-inscripciones>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div class="modal fade" id="staticBackdropSinDatos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">¡Atención!</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <label>No se encuentran registros con los datos ingresados</label>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropCalificaciones" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title text-center" id="staticBackdropLabel">Calificaciones</h5>
                            <button type="button" (click)="reset()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Calificaciones">
                                    <app-corregir-calificacion-escolares></app-corregir-calificacion-escolares>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropBecas" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Becas</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Becas">
                                    <app-becas></app-becas>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="staticBackdropDatosPersonales" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Datos Personales</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div *ngIf="!Datospersonales">
                                    <app-datos-personales-alumnos></app-datos-personales-alumnos>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="periodosescolaresmodal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Seleccione período escolar</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <select class="form-control" id="periodos">
                                        <option>--Período Escolar--</option>
                                      <option *ngFor="let periodo of periodosescolarescompleto" [value]="periodo.id">{{ periodo.nombre }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="verboleta()">Aceptar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="boleta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Boleta</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body" >
                                <div *ngIf="!banderaboleta">
                                    <app-boletas [idperiodo]="this.idperiodo" [matricula]="this.matriculaAlumno" [matriculap]="this.maestro.matricula"></app-boletas>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button *ngIf="!banderaImprimirBoleta" type="button" class="btn btn-danger" (click)="imprimirboleta()">Imprimir</button>
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="agregaralumno" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Agregar Alumno</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body" >
                                <div *ngIf="!banderaagregaralumno">
                                    <app-agregar-alumno></app-agregar-alumno>
                                </div>
                            </div>
                            <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Salir</button>
                            <button type="button" class="btn btn-danger" (click)="agregar()">Agregar</button>
                            </div> -->
                        </div>
                        </div>
                    </div>

                    <div class="modal fade" id="historial" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Historial de Calificaciones</h5>
                            <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body" >
                                <div *ngIf="!banderahistorial">
                                    <app-historial-alumnos-sesco [alumno]="matriculaAlumno"></app-historial-alumnos-sesco>
                                </div>
                            </div>
                            <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Salir</button>
                            <button type="button" class="btn btn-danger" (click)="agregar()">Agregar</button>
                            </div> -->
                        </div>
                        </div>
                    </div>


            <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                    <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body" >
                        {{ respuesta.mensaje }}
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="verTareas()">Cerrar</button>
                    </div>
                </div>
                </div>
            </div>

            <div class="modal fade" id="documentoalumno" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ nombreDoc }}</h5>
                    <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body" >
                        <div *ngIf="!banderadocumentoalumno">
                            <tld-file-viewer [pdfZoom]="1"  [src]="src" [type]="type"></tld-file-viewer>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" data-dismiss="modal" *ngIf="!banderaValidarDocumentos" (click)="documentoValido()">Aceptar</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" *ngIf="!banderaValidarDocumentos" (click)="documentoNoValido()">Rechazar</button>
                        <button type="button" class="btn btn-secondary" *ngIf="!banderaImprimirDocumentos" (click)="imprimirDocumentos()">Imprimir</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="verTareas()">Salir</button>
                    </div>
                </div>
                </div>
            </div>


            <div class="modal fade" id="tiempo" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Modificar tiempo de inscripción</h5>
                    <button type="button" class="close" (click)="reset()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body" >
                        <div class="row">
                            <div class="col-6 text-center">
                                <h6>Fecha</h6>
                                <input id="date" [value]="fecha2" type="date">
                            </div>
                            <div class="col-6 text-center">
                                <h6>Hora</h6>
                                <input id="currentTime" data-format="hh:mm:ss" type="time">
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-12 text-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" checked (change)="cambiarestatus($event)" type="radio" name="validartiempo" id="inlineRadioT1" value="0">
                                    <label class="form-check-label" for="inlineRadio1">Programar inscripción</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" (change)="cambiarestatus($event)" type="radio" name="validartiempo" id="inlineRadioT2" value="1">
                                    <label class="form-check-label" for="inlineRadio2">Cancelar inscripción</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="text-center">
                            <input id="date" type="date">
                            <input id="currentTime" data-format="hh:mm:ss" type="time">
                        </div> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="verTareas()">Salir</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="agregart()">Guardar cambios</button>
                    </div>
                </div>
                </div>
            </div>

            <div class="modal fade" id="addPlanEstudio" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Agregar plan de estudio</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <h5><b>Matrícula: </b>{{matriculaAlumno}}</h5>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="addplan">Seleccione un plan de estudio</label>
                                    <select class="form-control" id="addplan" >
                                        <option *ngFor="let plan of plandeestudios" [value]="plan.id">{{plan.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="addperiodo">Seleccione un periodo</label>
                                    <select class="form-control" id="addperiodo" >
                                        <option *ngFor="let periodo of periodosescolaresAbiertos" [value]="periodo.id">{{periodo.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cerrar</button>
                    <button type="button" class="btn btn-danger" (click)="enviarNuevoPlan()">Aceptar</button>

                    </div>
                </div>
                </div>
            </div>

            <div class="modal fade" id="MovimientosAlumno" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Historial Movimientos</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <h5><b>Matrícula: </b>{{matriculaAlumno}} {{nombreAlumno}}</h5>
                        <div class="row">
                            <div class="col-12">
                                <ng2-smart-table class="table table-hover table-borderless table-striped" [settings]="tblMovimientos" [source]="movimientos"></ng2-smart-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cerrar</button>

                    </div>
                </div>
                </div>
            </div>
            

</body>
</html>
