import { Component, OnInit } from '@angular/core';
import { PersonalService } from '../services/personal.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-grupos-materias',
  templateUrl: './grupos-materias.component.html',
  styleUrls: ['./grupos-materias.component.css']
})
export class GruposMateriasComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  grupos = [];
  bandera:boolean = true;
  banderaSpinner:boolean = true;
  banderaPreacta:boolean = true;
  banderareabriractas:boolean = true;
  banderadocumento:boolean = true;
  banderadatos:boolean = true;
  roles = [];
  materiaid;
  button = '';
  grupo;
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  formdata = new FormData();

  uploadform = new FormGroup({
    comentarios: new FormControl('', [Validators.required]),
    file: new FormControl('')
  });

  access = [];
  banderaVisualizarLista : boolean = true;
  banderaImprimirPDF : boolean = true;
  banderaVisualizarPreacta : boolean = true;
  banderaReabrirActa : boolean = true;

  constructor(private personalService: PersonalService,  private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    // this.roles = JSON.parse(localStorage.getItem('roles'));
    // for(var i = 0; i < this.roles.length; i++){
    //   if(this.roles[i] == 9){
    //     this.banderaPreacta = false;
    //     break;
    //   }
    // }
    // for(var i = 0; i < this.roles.length; i++){
    //   if(this.roles[i] == 1006){
    //     this.banderareabriractas = false;
    //     break;
    //   }
    // }
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10018 || this.access[i] == 3){
        this.banderaVisualizarLista = false;
      }
      if(this.access[i] == 10019 || this.access[i] == 3){
        this.banderaImprimirPDF = false;
      }
      if(this.access[i] == 10020 || this.access[i] == 3){
        this.banderaVisualizarPreacta = false;
      }
      if(this.access[i] == 10156 || this.access[i] == 3){
        this.banderaReabrirActa = false;
      }
    }
    this.consultargrupos();
  }

  consultargrupos(){
    this.grupos = [];
    this.banderaSpinner = true;
    this.bandera = true;
    const id = localStorage.getItem('grupo')
    var jason = {
      idmpe:parseInt(id),
      matricula:this.maestro.matricula
    }   
    this.personalService.listarGrupos(jason).subscribe({
      next:(resp : any) => {
        this.grupos = resp;
      },
      complete:() => {
        if(this.grupos.length > 0){
          this.bandera = false;
        }
        this.banderaSpinner = false;
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadform.get('file').setValue(file);
    }
  }

  abrirmensaje1(grupo){
    this.grupo = grupo;
    $('#aviso1').modal('show');
    this.banderadatos = false;
  }

  abrirmensaje2(){
    $('#aviso1').modal('hide');
    setTimeout(() => {
      $('#aviso2').modal('show');
    });
  }

  abrirmensaje3(){
    this.formdata.append('matriculap', this.maestro.matricula);
    this.formdata.append('comentario', this.uploadform.get('comentarios').value);
    this.formdata.append('file', this.uploadform.get('file').value);
    this.formdata.append('grupo', this.grupo.id);
    $('#aviso2').modal('hide');
    setTimeout(() => {
      $('#aviso3').modal('show');
    });
  }

  abriractas(){
    $('#aviso3').modal('hide');
    setTimeout(() => {
      $('#cargando').modal('show');
      this.personalService.reabriractas(this.formdata).subscribe({
        next:(data : any) => {
          this.respuesta = data;
        },
        error:(err) => {
          console.log(err);
          $('#cargando').modal('hide');
        },
        complete:() => {
          $('#cargando').modal('hide');
          setTimeout(() => {
            $('#respuesta').modal('show');
            this.uploadform.reset();
            this.consultargrupos();
          },1000)
        }
      });
    }, 500);
  }

  cerrarrespuesta(){
    $('#respuesta').modal('hide');
  }

  cerraraviso1(){
    $('#aviso1').modal('hide');
  }

  cerraraviso3(){
    $('#aviso3').modal('hide');
  }

  cerraraviso2(){
    $('#aviso2').modal('hide');
  }


  verlistapdf(grupo){
    this.materiaid = grupo.id;
    $('#documento').modal('show');
    this.banderadocumento = false;
  }

  cerrarpdf(){
    $('#documento').modal('hide');
    this.banderadocumento = true;
  }

  preacta(id){
    $('#grupos').modal('hide');
  }

  imprimirLista(){
    this.personalService.alumGr(this.materiaid,this.maestro.matricula);
  }

}
