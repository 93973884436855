import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl,Validators } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {

  personal:{
    matricula: string
  }

  changePasswordForm = new FormGroup({
    passwordA: new FormControl(''),
    password: new FormControl('',[Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]),
    passwordConfirmation: new FormControl('',[Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/), Validators.required])
  })

  response = {
    codigo: '',
    detalle: '',
    mensaje: ''
  }

  banedra:boolean = true;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.personal = JSON.parse(localStorage.getItem('personal'));
  }

  onSubmit(){
    if(this.changePasswordForm.controls["password"].value != this.changePasswordForm.controls["passwordConfirmation"].value){
      this.changePasswordForm.setErrors({ noMatch: true });
    }
    else{
      if(this.changePasswordForm.controls["passwordA"].value === this.changePasswordForm.controls["password"].value){
        this.changePasswordForm.setErrors({ iguales: true })
      }
      else{
        let personal = {
          usuario: this.personal.matricula,
          password: this.changePasswordForm.controls["password"].value
        }
        this.authService.cambiarPass(personal).subscribe({
          next:(res : any) =>{
            this.response = res;
          },
          error:(err) => {
            throw new Error('Error en la peticion');
          },
          complete:() => {
            $('#myModal').modal('show');
            if(this.response.codigo == "OK"){
              this.banedra = false;
            }
          }
        })
      }
    }
  }

  redirect(){
    this.router.navigateByUrl('/inicio')
  }

}
