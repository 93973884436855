import { Component, OnInit } from '@angular/core';
import { CierreService } from '../services/cierre.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

declare var $ : any;

@Component({
  selector: 'app-corregir-calificacion-maestro',
  templateUrl: './corregir-calificacion-maestro.component.html',
  styleUrls: ['./corregir-calificacion-maestro.component.css']
})
export class CorregirCalificacionMaestroComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  alumno = {
    apellidom:'',
    apellidop:'',
    estatus:'',
    matricula:'',
    nombres:'',
    regular:'',
    semestre:''
  };

  response = {
    codigo:'',
    mensaje:'',
    detalle:''
  }

  alumnos;
  uploadForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private cierreservice: CierreService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    const matriculaAlumno = localStorage.getItem('alumno');
    this.uploadForm = this.formBuilder.group({
      certificate: ['', Validators.required],
      public: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  consultaralumnos(){
    var jason = {
      dato1:this.maestro.matricula,
      dato2:"1",
      dato3:this.maestro.matricula
    }
    this.cierreservice.consultarcambiosolicitud(jason).subscribe({
      next:(res : any) => {
        this.alumnos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  onFileSelectpublic(event){
    if (event.target.files.length > 0) {
      const public2 = event.target.files[0];
      this.uploadForm.get('public').setValue(public2);
    }
  }

  onFileSelectcertificate(event){
    if (event.target.files.length > 0) {
      const cert = event.target.files[0];
      this.uploadForm.get('certificate').setValue(cert);
    }
  }

  firmar(){
    var pas = <HTMLSelectElement>document.getElementById('pass');
    const formData = new FormData();
    formData.append('certificate', this.uploadForm.get('certificate').value);
    formData.append('public', this.uploadForm.get('public').value);
    formData.append('password', pas.value);
  }

  mostraraviso1(){
    $('#aviso1').modal('show');
  }

  cerraraviso1(){
    $('#aviso1').modal('hide');
  }

  cerraraviso2(){
    $('#aviso2').modal('hide');
  }

  mostraraviso2(){
    $('#aviso1').modal('hide');
    setTimeout(() => {
      $('#aviso2').modal('show');
    },1000);  
  }

}
