import { Component, OnInit } from '@angular/core';
import { PlanestudiosService } from '../service/planestudios.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioPEComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipocontrato: string
  }

  periodosactivos = [];

  constructor(private planestudios: PlanestudiosService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.consultarperiodoescolares();
  }

  consultarperiodoescolares(){
    this.planestudios.consultarperiodoescolar(this.maestro.matricula).subscribe({
      next:(data : any) => {
        this.periodosactivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // for(var i = 0; i < this.periodoescolaractivos.length; i++){
        //   if(this.periodoescolaractivos[i].estado == "ABIERTO"){
        //     this.periodosabiertos.push(this.periodoescolaractivos[i]);
        //   }
        // }
    }
    });
  }

}