import { Component, OnInit } from '@angular/core';
import { SescoService } from '../services/sesco.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ExcelService } from '../services/excel.service';
declare var $:any;

@Component({
  selector: 'app-importacion-sesco-alumnos',
  templateUrl: './importacion-sesco-alumnos.component.html',
  styleUrls: ['./importacion-sesco-alumnos.component.css']
})
export class SescoAlumnosComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipoContrato: string
  }

  alumno = new FormGroup({
    apellido_materno: new FormControl(''),  //
    apellido_paterno: new FormControl(''),  //
    calle: new FormControl(''),  //
    celular: new FormControl(''), 
    codigo: new FormControl(''),  //
    colonia: new FormControl(''),  //
    curp: new FormControl(''),  //
    email: new FormControl(''),  //
    matricula: new FormControl(''),  //
    nombre: new FormControl(''),  //
    rfc: new FormControl('') //
  });
  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  };
  alumnos = [];
  alumnosfiltrados = [];
  periodosescolares = [];
  periodosescolaresfiltrados = [];
  planestudios = [];
  alumnosExcel = [];
  banderaspinner:boolean = true;
  a;
  access = [];
  banderaDetalles : boolean = true;
  banderaImportar : boolean = true;
  bandera : boolean = true;
  planingreso;
  alu;
  constructor(private sesco: SescoService, private excel: ExcelService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.access = JSON.parse(localStorage.getItem('roles'));
    this.consultaralumnossesco();
    this.consultarperiodosescolares();
    this.consultarplandeestudios();
    this.viewBtn();
  }

  abriraviso(){
    $('#aviso').modal('show');
  }

  viewBtn() {
    for(var i = 0; i < this.access.length; i++){
      if(this.access[i] == 10131 || this.access[i] == 3){
        this.banderaDetalles = false;
      }
      if(this.access[i] == 10130 || this.access[i] == 3){
        this.banderaImportar = false;
      }
    }
  }

  veralumno(index){
    this.alumno.patchValue(this.alumnos[index]);
    $('#datos').modal('show');    
  }

  importaralumno(i){
    let periodoingreso = <HTMLSelectElement>document.getElementById(`periodoingreso${i}`);
    let planestudio = <HTMLSelectElement>document.getElementById(`planestudio${i}`);
    // console.log(this.alumnos[i]);
    this.alumno.patchValue(this.alumnos[i]);
    // console.log(this.alumno.value);
    var jason = {
      matricula:this.alumno.get('matricula').value,
      nombres:this.alumno.get('nombre').value,
      apellidom:this.alumno.get('apellido_materno').value,
      apellidop:this.alumno.get('apellido_paterno').value,
      calle:this.alumno.get('calle').value,
      colonia:this.alumno.get('colonia').value,
      rfc:this.alumno.get('rfc').value,
      codigopostal:this.alumno.get('codigo').value,
      email:this.alumno.get('email').value,
      curp:this.alumno.get('curp').value,
      telefono:this.alumno.get('celular').value,
      planestudioid:parseInt(planestudio.value),
      periodoidingreso:parseInt(periodoingreso.value),
      matriculap:this.maestro.matricula,
      semestre:1
    };
    for(var z = 0; z < this.planestudios.length; z++){
      if(jason.planestudioid == this.planestudios[z].idplanestudio){
        this.planingreso = this.planestudios[z]
      }
    }
    this.abriraviso();
    this.a = jason;
    this.bandera = false;
    this.alu = jason;
  }

  importar(){
    this.sesco.importalumnossesco(this.alu).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        if(this.respuesta.codigo == "OK"){
          this.consultaralumnossesco();
        }
      }
    });
  }

  consultarplandeestudios(){
    this.sesco.consultarCarrerasActivas(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.planestudios = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  consultarperiodosescolares(){
    this.sesco.listaPeriodosEscolaresCompleta(this.maestro.matricula).subscribe({
      next:(data:any) => {
        this.periodosescolares = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        // for(var i = 0; i < this.periodosescolares.length; i++){
        //   if(this.periodosescolares[i].estado == "ACTUAL" || this.periodosescolares[i].estado == "ABIERTO"){
        //     this.periodosescolaresfiltrados.push(this.periodosescolares[i]);
        //   }
        // }
      }
    });
  }

  consultaralumnossesco(){
    this.banderaspinner = true;
    let jason = {
      dato:this.maestro.matricula
    };
    this.sesco.listaalumnossesco(jason).subscribe({
      next:(data:any) => {
        this.alumnos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.alumnosfiltrados = [];
        for(var i = 0; i < this.alumnos.length; i++){
          var jason = {
            alumno:this.alumnos[i].apellido_paterno + " " + this.alumnos[i].apellido_materno + " " + this.alumnos[i].nombre,
            matricula:this.alumnos[i].matricula,
            calle:this.alumnos[i].calle,
            celular:this.alumnos[i].celular,
            codigo:this.alumnos[i].codigo,
            colonia:this.alumnos[i].colonia,
            curp:this.alumnos[i].curp,
            email:this.alumnos[i].email,
            rfc:this.alumnos[i].rfc
          }
          this.alumnosfiltrados.push(jason);
        }
        this.banderaspinner = false;
      }
    });
  }

  exportarexcel(){
    this.alumnosExcel = [];
    for(var i = 0; i < this.alumnos.length; i++){
      let array = [
        this.alumnos[i].apellido_paterno + " " + this.alumnos[i].apellido_materno + " " + this.alumnos[i].nombre,
        this.alumnos[i].matricula,
        this.alumnos[i].calle,
        this.alumnos[i].colonia,
        this.alumnos[i].codigo,
        this.alumnos[i].celular,
        this.alumnos[i].curp,
        this.alumnos[i].email,
        this.alumnos[i].rfc
      ];
      this.alumnosExcel.push(array);
    }
    this.excel.exportAsExcelFileAlumnosSESCO(this.alumnosExcel);
  }

}
