import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-pdf-bloques',
  templateUrl: './pdf-bloques.component.html',
  styleUrls: ['./pdf-bloques.component.css']
})
export class PdfBloquesComponent implements OnInit {

  @ViewChild('htmlData') htmlData:ElementRef;
  @Input() bloques;


  datos = [
    {
        "alumnoid": 1099,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1055372",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.296923076923074,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    },
    {
        "alumnoid": 1049,
        "bloque": 1,
        "carrera": "MÉDICO CIRUJANO",
        "carreraid": 1,
        "fechahora": "2021-01-13 08:00",
        "horasdia": "2021-01-13 09:00",
        "matricula": "1054094",
        "nombres": "JOSUE HUMBERTO GUZMAN GUZMAN",
        "planestudio": "2010 SEGUNDO PLAN",
        "planestudioid": 1,
        "promedio": 9.204615384615385,
        "regular": "SI",
        "reprobadas": 0,
        "semestre": "8"
    }
]

  constructor() { }

  ngOnInit(): void {

  }

    downloadPDF(data){
    // let DATA = this.htmlData.nativeElement;
    //     html2canvas(DATA).then(canvas => {
    //     const contentDataURL = canvas.toDataURL('image/png')
    //     //let pdf = new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
    //     let pdf = new jsPDF('p', 'cm', 'a4');
    //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 21.0, 29.7);
    //     pdf.save('Constancia.pdf');
    //   });
    // html2canvas(data, { allowTaint: true }).then(canvas => {
    //   let HTML_Width = canvas.width;
    //   let HTML_Height = canvas.height;
    //   let top_left_margin = 25;
    //   let PDF_Width = HTML_Width + (top_left_margin * 2);
    //   let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    //   // let PDF_Width = HTML_Width + (top_left_margin * 2);
    //   // let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    //   let canvas_image_width = HTML_Width;
    //   let canvas_image_height = HTML_Height;
    //   let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    //   canvas.getContext('2d');
    //   let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
    //   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
    //   for (let i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage([PDF_Width, PDF_Height], 'p');
    //     pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    //   }
    //    pdf.save("HTML-Document.pdf");
    // });
    // var data = document.getElementById('contentToConvert');  //Id of the table
    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options
    //   let imgWidth = 208;
    //   let pageHeight = 295;
    //   let imgHeight = canvas.height * imgWidth / canvas.width;
    //   let heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    //   let position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdf.save('MYPdf.pdf'); // Generated PDF
    // });
  }



}
