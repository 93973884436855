import { SescoService } from './../services/sesco.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $ : any;
@Component({
  selector: 'app-datos-alumnos',
  templateUrl: './datos-alumnos.component.html',
  styleUrls: ['./datos-alumnos.component.css']
})
export class DatosAlumnosComponent implements OnInit {
  
  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,
    rfc: string,
    tipocontrato: string
  };

  respuesta = {
    codigo:'',
    mensaje:'',
    detalle:''
  };

  busqueda = new FormGroup({
    dato: new FormControl('')
  })

  datosAlumno = new FormGroup({
    nombre: new FormControl(''),
    apellido_materno: new FormControl(''),
    apellido_paterno: new FormControl(''),
    calle: new FormControl(''),
    celular: new FormControl(''),
    codigo: new FormControl(''),
    colonia: new FormControl(''),
    curp: new FormControl(''),
    email: new FormControl(''),
    matricula: new FormControl(''),
    rfc: new FormControl(''),
    todo: new FormControl(false)
  })
  
  datos; 

  banderaForm : boolean = false;
  banderaSpinner: boolean = false
  banderaTodo : boolean = false;
  banderaVacio : boolean = false;
  constructor(private service : SescoService ) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
  }
  buscar(){
    this.banderaVacio = false;
    this.banderaSpinner = true;
    this.banderaForm = false;
    let jason = {
        dato: this.maestro.matricula,
        dato2: this.busqueda.get('dato').value}
    this.service.consultaDatosAlumnoSesco(jason).subscribe({
      next:(res:any) => {
        this.datos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.datos.length != 0){
          this.datosAlumno.patchValue(this.datos[0]);
          this.datosAlumno.disable();
          this.datosAlumno.get('todo').enable();
          this.banderaVacio = false;
          this.banderaForm = true;
          this.banderaSpinner =false;
        }else{
          this.banderaVacio = true;
          this.banderaSpinner =false;
        }
      }
    });
  }
  actualizar(){
    let jason = {
      matriculap: this.maestro.matricula,
      matricula: this.datosAlumno.get('matricula').value,
      todo:this.datosAlumno.get('todo').value?1:0
    }
    this.service.actualizarDatosAlumnoSesco(jason).subscribe({
      next:(res:any) => {
        this.respuesta = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show')
        this.busqueda.reset()
        this.datosAlumno.reset()
        this.banderaForm = false;
      }
    });
    
  }
}
