<body>

    <div>
        <h4>Administración de días festivos en el ciclo escolar</h4>
    </div>

    <div class="row">
        <div class="col-lg-3">

            <div class="form-group">
                <select class="form-control" id="periodo" (change)="selecionarperiodo()">
                  <option>--Período Escolar--</option>
                  <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
                </select>
            </div>

        </div>
    </div>

    <div class="row" *ngIf="!banderaformulario">
        <div class="col-lg-4">
            <div class="pt-3">
                <input type="text" id="nombre" class="form-control" placeholder="Nombre">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!banderaformulario">
        <div class="col-lg-3">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="fecha" placeholder="Fecha" 
                        angular-mydatepicker #dpFecha="angular-mydatepicker" id="fecha"  [options]="myDpOptions" 
                        (dateChanged)="formatoFecha($event)"/>
                    <button type="button" class="btn btn-danger" (click)="dpFecha.toggleCalendar()">
                        <i class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="pt-3">
                <div class="input-group-append">
                    <input class="form-control" name="fechaFeriado" placeholder="Fecha Día Feriado" 
                        angular-mydatepicker #dpFechaFeriado="angular-mydatepicker" id="fechaFeriado"  [options]="myDpOptions" 
                        (dateChanged)="formatoFechaFeriado($event)"/>
                    <button type="button" class="btn btn-danger" (click)="dpFechaFeriado.toggleCalendar()">
                        <i class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
        </div>
      </div>
      <div class="row" *ngIf="!banderaformulario">
        <div class="col-lg-2">
            <div class="pt-3">
                <button class="btn btn-danger" (click)="agregardia()" >Agregar</button>
            </div>
        </div>
    </div>

        <div class="row pt-3" *ngIf="!banderaformulario">
            <div class="col-lg-12">
                <div class="form-group">
                    <h5>Días festivos activos</h5>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!banderaformulario">
            <div class="col-lg-12" *ngIf="!banderadiasF">
                <div class="form-group">
                    <h6>Sin datos disponbiles.</h6>
                </div>
            </div>
            <div class="col-lg-12" *ngIf="banderadiasF">
                <!-- <div class="form-group">
                    <table class="table table-hover table-sm- table-borderless">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>Dia</th>
                                <th>Fecha</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dia of diasF">
                                <td>{{ dia.nombre }}</td>
                                <td>{{ dia.fecha }}</td>
                                <td><button class="btn btn-danger"><i class="fas fa-pencil-alt"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <ng2-smart-table class="table table-hover table-striped table-sm table-borderless" (custom)="eliminarmodal($event)" [settings]="settings" [source]="diasF"></ng2-smart-table>
            </div>
        </div>



        <div class="modal fade" id="validacion" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{{ validacion }}</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="modal fade" id="respuesta" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{{ respuesta.mensaje }}</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="modal fade" id="eliminar" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">¡Atención!</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" *ngIf="!banderabtn">
                  <p>¿Estas seguro que deseas eliminar <strong>{{ diaselecionado.nombre }}</strong> de forma permanente del período escolar <strong>{{ periodoselecionado.nombre }}</strong>?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="eliminardiafestivo()" data-dismiss="modal">Aceptar</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
              </div>
            </div>
        </div>


</body>