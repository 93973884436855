<div class="row" id="top">
    <div class="col-12">
        <h3>Listado/Asignación de Plazas</h3>
    </div>
</div>
<div class="row" id="top">
    <div class="col-4">
        <select (change)="listaPlazas();" class="form-control" id="plazaperiodoid2" >
            <option selected>--Período Escolar--</option>
            <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
        </select>
    </div>
</div>
<div class="row" *ngIf="!banderatabla">
    <div class="col-12">          
        <ng2-smart-table class="table table-hover table-borderless" [settings]="settingsPlazas" [source]="plazas" (custom)='verDetalles($event)'></ng2-smart-table>         
    </div>
</div>

<div class="modal fade" id="detalles" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
              Asignación de Plazas Para <strong>{{ nombreInstitucion }}</strong> en <strong>{{ estado }}</strong><br>
              Plan de Estudios: <strong>{{ planEstudios }}</strong> Período: <strong>{{ periodo }}</strong><br>
              Total de Lugares: <strong>{{ disponibles + nodisponibles }}</strong> Lugares Disponibles: <strong>{{ total }}</strong>
            </h5>
          <h5></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!banderaDetalles">
                <ng2-smart-table class="table table-hover table-borderless" [settings]="settingsPlazasDetalle" [source]="lugares"></ng2-smart-table>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>