<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row">
                <div class="col-lg-5">   
                    <!-- ------------------------------------------------------------------- -->
                    <form [formGroup]='editForm' (ngSubmit)="onWarningEdit()">
                        <div class="modal fade" id="modalEditForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <!-- <div class="modal-header text-center"> -->
                            <table class="table table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <div class="modal-header text-center">
                                        <h4 class="modal-title w-100 font-weight-bold">Editar Clasificación</h4>
                                        <button (click)="onClose()" otype="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>
                                    </tr>
                                </thead>
                            <!-- </div> -->
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="modal-body mx-3">
                                                <div class="md-form mb-5">
                                                    <label >Nombre de la clasificación</label>
                                                    <input type="text" class="form-control validate" [formControl]="this.editForm.get('nombre')" id="clasificacion">
                                                </div>
                                        
                                              </div>
                                              <div class="md-form mb-4">
                                                <button type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                <button [disabled]='!editForm.valid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                              </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                          </div>
                        </div>
                      </div>
                    </form>
                    <!-- ----------------------------Crear Clasificacion---------------------- -->

                    <!-- <div>
                        <table class="table table-striped">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th scope="col">Crear clasificación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <form [formGroup] = 'createForm' (ngSubmit)='onCreate()'>
                                            <div class="form-group">
                                              <label for="exampleInputEmail1">Nombre</label>
                                              <input type="email" class="form-control" id="nuevaClasi" aria-describedby="emailHelp" [formControl]="this.createForm.get('clasificacion')">
                                            </div>
                
                                            <button id="btn-crearCla" [disabled] ='!createForm.valid' type="submit" class="btn btn-danger btn-sm"><i class="fas fa-plus"></i>Crear</button>
                                            
                                        </form>
                                    </td>
                                       
                                </tr>
                            </tbody>
                        </table>
             
                    </div>  -->
                    <form [formGroup]='createForm' (ngSubmit)="onCreate()">
                        <div class="modal fade" id="modalCreateClasificacionForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <table class="table table-striped">
                                <thead class="bg-danger text-white">
                                    <tr>
                                        <div class="modal-header text-center">
                                        <h4 class="modal-title w-100 font-weight-bold">Crear Clasificación</h4>
                                        <button (click)='onClose()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="modal-body mx-3">
                                                <div class="md-form mb-5">
                                                    <!-- <label data-error="wrong" data-success="right" for="defaultForm-email">Aula</label> -->
                                                    <input  type="text" class="form-control validate" [formControl]="this.createForm.get('clasificacion')" id="aulaInputN" placeholder="Nombre de la clasificacion">
                                                </div>
                                              </div>
                                              <div class="md-form mb-4">
                                                <button (click)='onClose()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                                <button [disabled]='!createForm.valid' type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                               
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                          </div>
                        </div>
                      </div>
                     </form>
                    <!-- ------------------------------------------------------------------------------------------------------- -->
                    <table class="table table-striped">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th scope="col">Clasificaciones</th>
                                <th scope="col"></th>
                                <th scope="col">
                                    <button data-target="#modalCreateClasificacionForm" data-toggle="modal" id="btn-add-clas"><i id="plus" class="fas fa-plus"></i></button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let clasificacion of response">
                                <td scope="col">{{clasificacion.clasificacion}}</td>
                                
                                <td>
                                    <button (click)="onEdit(clasificacion.id,clasificacion.clasificacion)" data-target="#modalEditForm" data-toggle="modal"  class="btn btn-danger btn-sm" ><i class="fas fa-pencil-alt"></i>Editar</button>
                                </td>
                                <td scope="col">
                                    <button (click)="onDelete(clasificacion.id,clasificacion.clasificacion)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i>Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                     <!-------------------- modal de aviso de que se realizo la accion------------------------->
                    <div class="modal fade" id="aviso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header" *ngIf="this.mensajeAvisoError==true">
                              <h5 class="modal-title" id="exampleModalLabel">ADVERTENCIA!</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" *ngIf="this.mensajeAvisoError==true">
                                {{this.mensajeAviso}}
                            </div>
                            <div class="modal-header"  *ngIf="this.mensajeAvisoError==false">
                                <h5 class="modal-title" id="exampleModalLabel">Felicidades!</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"  *ngIf="this.mensajeAvisoError==false">
                                {{this.mensajeAviso}}
                                <label><strong>{{this.clasificacionDatos.nombre}}</strong></label>
                            </div>
                            
                            <div class="modal-footer">
                              <button type="button"  class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button  type="button" class="btn btn-danger" data-dismiss="modal" >Aceptar</button>
                            </div>
                          </div>
                        </div>
                      </div>
            
                        <!-- ----------------------modal para que el usuario confirme que si desea realizar la accion------------------------------------------------------ -->
                        <div class="modal fade" id="confirmacion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  {{this.mensaje}}
                                  <label><strong>{{this.clasificacionDatos.nombre}}</strong></label>
                                </div>
                                <div class="modal-footer">
                                  <button type="button"  class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onFinished()">Aceptar</button>
                                </div>
                              </div>
                            </div>
                          </div>
    
                </div>
        </div>
    </div>
</body>
</html>


