import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Global } from 'src/global';
import { NgDragDropModule } from 'ng-drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ListadoTutorialesComponent } from './tutoriales/listado/listado.component';
import { InicioTutorialesComponent } from './tutoriales/inicio/inicio.component';
import { AuditoriaModule } from './auditoria/auditoria.module';
import { NgxPaginationModule } from 'ngx-pagination';
//import { PersonalSinFirmaComponent } from './infomes/personal-sin-firma/personal-sin-firma.component';


@NgModule({
  declarations: [
    AppComponent,
    //PersonalSinFirmaComponent
    // ListadoTutorialesComponent,
    // InicioTutorialesComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgDragDropModule.forRoot(),
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    NgbModule,
    AngularEditorModule,
    Ng2SmartTableModule,
    AngularFileViewerModule,
    AngularMyDatePickerModule,
    FormsModule,
    AuditoriaModule,
    NgxPaginationModule
  ],
  providers: [ Global, {provide: LocationStrategy, useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
