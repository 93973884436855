import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 *  CLASE INJECTABLE PARA VARIABLES DE SERVIDOR BACKEND
 */
//
@Injectable()
export class Global{

    /**
     *  PROTOCOLO
     */ 
    //
    protocolo = 'https'

    /**
     *  URL
     */
    //
    backendUrl = (this.protocolo=='https')?'personal.famen.ujed.mx':'localhost:8080';//local
    // backendUrl = '3.23.203.196:80'; // pruebas
    // backendUrl = 'personal.famen.ujed.mx'; //prod
    //backendUrl = '200.23.125.91:80'

    /**
     *  CONTEXTO
     */
    //
    contexto = (this.protocolo=='https')?'famen_personal':'famen_escolares'; // local
    //contexto = 'famen_profesores'; // pruebas
    // contexto = 'famen_personal' ; //prod


     /**
     *  CABECERAS PARA MANDAR CARACTERES ESPECIALES AL BACKEND, COMO ACENTOS
     */
    //
    headers = new HttpHeaders(
        {'Content-Type':'application/json; charset=utf-8'}
    );
}