import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PersonalService } from '../services/personal.service';
declare var $ : any;

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.css']
})
export class DatosPersonalesComponent implements OnInit {

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
  }

  datospersonalform = new FormGroup({
    apellidom : new FormControl(''),
    apellidop : new FormControl(''),
    calle : new FormControl('', [Validators.required]),
    celular : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    colonia : new FormControl('', [Validators.required]),
    curp : new FormControl('', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]),
    email : new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    maestro : new FormControl('', [Validators.required]),
    matricula : new FormControl(''),
    nombres : new FormControl(''),
    puesto: new FormControl(''),
    numeroexterior : new FormControl('', [Validators.required]),
    rfc : new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(13)]),
    id : new FormControl('', [Validators.required]),
    fotografia : new FormControl(''),
    numerointerior : new FormControl(''),
    tipoContrato: new FormControl(''),
    autorizafirma: new FormControl('')
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  banderaeditardatos:boolean = true;
  
  constructor(private personalService: PersonalService) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.datospersonalform.setValue(this.maestro);
  }

  modificardatos(){
    this.datospersonalform.enable();
    this.banderaeditardatos = false;
  }

  guardar(){
    if(this.datospersonalform.invalid){
      this.datospersonalform.markAllAsTouched();
    }else{
      if(this.datospersonalform.valid){
        this.datospersonalform.addControl('matriculap', new FormControl(this.maestro.matricula));
        this.personalService.editarpersona(this.datospersonalform.value).subscribe({
          next:(res : any) => {
            this.respuesta = res;
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
          $('#respuesta').modal('show');
            if(this.respuesta.codigo == "OK"){
              this.actualizardatos();
              this.banderaeditardatos = true;
            }
          }
        });
      }
    }
  }

  actualizardatos() {
    this.personalService.personal(this.maestro.matricula).subscribe({
      next:(res : any) => {
        this.maestro = res;
        localStorage.setItem('personal', JSON.stringify(res))
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  cerrarresuesta(){
    $('#respuesta').modal('hide');
  }


}
