<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-7">
                <!-- <app-materias-cursables></app-materias-cursables> -->
                <div id="accordion">
                    <div class="card" *ngFor="let materia of materias; let i = index">
                      <div class="card-header" id="heading{{ i }}">
                        <h5 class="mb-0">
                          <a class="text-secondary" data-toggle="collapse" attr.data-target="#collapse{{i}}" aria-expanded="false" attr.aria-controls="collapse{{i}}" (click)="tomarId(materia.materia.id)">
                            <span><i class="fas fa-plus"></i></span><span>Semestre:{{materia.semestre}} - </span>  {{ materia.materia.nombre }} <span class="text-danger float-right">{{ materia.repetida }}</span>
                          </a>
                        </h5>
                      </div>

                      <div  id="collapse{{i}}" class="collapse" attr.aria-labelledby="heading{{i}}" data-parent="#accordion">
                        <ul id="ul" *ngFor="let lista of listadogrupos; let x = index">
                          <li   draggable  [dragData]="lista"   id="materia" >Grupo: <span class="text-danger">{{ lista.gpo_nombre }} - <span class="text danger">{{ lista.alias }}</span></span> Maestro: <span class="text-danger">{{ lista.maestro }}</span>
                              <div>
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td class="text-danger"  *ngFor="let lisdias of lista.dias" id="dia"> {{ lisdias.dia }}</td>
                                    </tr>
                                    <tr>
                                      <td *ngFor="let lishini of lista.dias" id="dia"><strong class="text-danger">I: </strong>{{ lishini.hinicio }}</td>
                                    </tr>
                                    <tr>
                                      <td *ngFor="let lishini of lista.dias" id="dia"><strong class="text-danger">F: </strong>{{ lishini.hfin }}</td>
                                    </tr>
                                    <tr>
                                      <td *ngFor="let lishini of lista.dias" id="dia"><strong class="text-danger">Aula: </strong>{{ lishini.aula }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="col-lg-5">
                <!-- <app-pre-horario></app-pre-horario> -->
                  <div class="container bg-white sticky-top drop" droppable dragHintClass='drag-hint-border' (onDrop)="onItemDrop($event);">
                    <div class="drop">
                        <h3 class="text-center"> Mi Horario</h3>
                        <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-cloud-arrow-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: auto; display: block;">
                        <path fill-rule="evenodd" d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"></path>
                        </svg>
                        <div id="div" class="text-center" [hidden]="this.listadogruposhorario.length > 0">
                            <label>Arrastra tus materias aqui!</label>
                        </div>
                            <ul class="list-group bg-white">
                                <li class="list-group-item" *ngFor="let lista of listadogruposhorario; let i = index"><span></span>
                                    <div>
                                        <span><i class="fas fa-circle"></i>{{ lista.materia }} - Grupo {{ lista.nombre_gpo || lista.gpo_nombre }}</span>
                                    </div>
                                    <div>
                                        <span class="text-danger">{{ lista.maestro }}</span>
                                    </div>
                                    <div  *ngFor="let dias of lista.dias">
                                        <span><strong class="text-danger">{{ dias.dia | slice:0:2 }}   I: </strong> {{ dias.hinicio }} <strong class="text-danger"> F:</strong> {{ dias.hfin }}</span>
                                    </div>
                                    <button  type="button" class="close" aria-label="Close" (click)="eliminarmateriadehorario(i)">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                </li>
                            </ul>
                    </div>
                    <div id="div" class="text-center">
                        <button class="btn btn-outline-danger" [hidden]="this.listadogruposhorario.length === 0" (click)="mandarMaterias()">
                            Terminar
                        </button>
                    </div>
                    <div class="bg-white pt-2">
                      <div *ngFor="let materia of horarioactual">
                        <div class="text-white bg-danger text-center">
                          <label>{{ materia.grupo.mpe.materia.nombre }}</label><span class="float-right pr-1">{{ materia.grupo.mpe.repetida }}</span><br>
                          <label>Grupo: {{ materia.grupo.mpe.semestre }}{{ materia.grupo.nombre |  slice:0:1 }}</label>
                        </div>
                        <div>
                          <table class="table table-sm">
                            <thead>
                              <tr>
                                <th *ngFor="let dia of materia.egh"><strong class="text-danger">{{ dia.dia | slice:0:2 }}</strong></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td *ngFor="let dia of materia.egh"><strong class="text-danger">I:</strong><small> {{ dia.hinicio | slice:0:5 }}</small></td>
                              </tr>
                              <tr>
                                <td *ngFor="let dia of materia.egh"><strong class="text-danger">F:</strong><small> {{ dia.hfin | slice:0:5 }}</small></td>
                              </tr> 
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" id="top"></div>
    </div>
    <div class="modal" id="mensaje" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Atención</h5>
              <button type="button" class="close" (click)="salir()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ mensajesmodal }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="salir()">Salir</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="empalme" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="salir2()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ mensajesmodal }}<br>
              La materia <strong>{{ materiaempalmada1 }}</strong> se empalma con <strong>{{ materiaempalmada2 }}</strong>.<br>
              A la hora <strong>{{ hora1 | slice:0:5 }}</strong> el día <strong>{{ dia1 }}</strong>.            
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="salir2()">Salir</button>
            </div>
          </div>
        </div>
      </div>
</body>
</html>