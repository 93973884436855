import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../servicio-social.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion-plazas.component.html',
  styleUrls: ['./administracion-plazas.component.css']
})
export class AdministracionPlazasComponent implements OnInit {
  

  maestro: {
    apellidom: string;
    apellidop: string;
    calle: string;
    celular: string;
    colonia: string;
    curp: string;
    email: string;
    fotografia: string;
    maestro: number;
    matricula: string;
    nombres: string;
    numeroexterior: string;
    numerointerior: string;
    rfc: string;
    tipocontrato: string;
  };

  settingsPlazas = {
    columns:{
    institucion:{
      title:'Institución',
      editable:false/*,         
      width:'9%'*/
    },
    ubicacion:{
      title:'Ubicación',
      editable:false/*,         
      width:'9%'*/
    },
    rotacion:{
      title:'Rotación',
      editable:false,         
      width:'9%'
    },
    asignadas:{
      title:'Asignadas',
      editable:false,         
      width:'9%'
    },
    noasignadas:{
      title:'No Asignadas',
      editable:false,         
      width:'9%'
    },
    carrera:{
      title:'Plan de estudio',
      editable:false,         
      width:'9%'
    },
    semestre:{
      title:'Semestre',
      editable:false,         
      width:'9%'
    },
    estadoRepublica:{
      title:('Estado República'),
      editable:false/*,         
      width:'9%'*/
    }
  },
  actions:{
    add:false,
    edit:false,
    delete:false,
    columnTitle:'',
    type:'html',
    custom:[
      { name:'editar', title:'<a class="btn btn-danger btn-sm text-white boton" title="Editar"><i class="fas fa-pencil-alt " ></i></a>'},
    //  { name:'borrar', title:' <a class="btn btn-danger btn-sm text-white boton" title="Eliminar" ><i class="fas fa-trash " ></i></a>'}
    ],         
    width:'15%',
    position: 'right'
  },
  pager:{
    perPage:15
  },
  noDataMessage:'Sin datos disponibles.'
}
  
  formPeriodo= new FormGroup({
    periodoid:new FormControl(0)}
  );

  formPlaza= new FormGroup({
    id:new FormControl(0),
    periodoid: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")]),
    institucionid: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")]),
    asignadas: new FormControl(0,[Validators.required, Validators.pattern("^[0-9]*$")]),
    noasignadas: new FormControl(0,[Validators.required, Validators.pattern("^[0-9]*$")]),
    plazas: new FormControl(0,[Validators.required, Validators.pattern("^[1-9]*$")]),
    ubicacion: new FormControl(''),
    rotacion: new FormControl('NO'),
    mat: new FormControl(''),
    planestudioid: new FormControl(0)
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  instituciones:any;
  plazas:any;
  periodosescolares:any;
  estadosRepublica:any;

  banderatabla:boolean = true;
  banderarotacion:boolean = true;
  rotacionvalor = 'NO'
  sumatoriaplazas;

  planesactivos = [];
  titituloactiva = 'Crear Plaza';
  internado;
  ss;
  semestres=[];
  constructor(private consultas: ServicioSocialService) { }


  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.listaPeriodos();
    this.listaInstituciones();
    this.consultar();
  }

   consultar(){
     this.consultas.listaCarreras(this.maestro.matricula).subscribe({
       next:(res: any) => {
        this.planesactivos = res;
       },
       error:(err) => {
        console.log(err);
       },
       complete:() => {

       }
     });
   }

  rotacion(){
    this.banderarotacion = true;
    var rotacion = <HTMLSelectElement>document.getElementById('rotacion');
    this.rotacionvalor = rotacion.value;
    if(this.rotacionvalor == "SI"){
      this.banderarotacion = false;
    }    
  }

  sumarplazas(){
    var asignadas = this.formPlaza.get('asignadas').value;
    var noasignadas = this.formPlaza.get('noasignadas').value;
    this.sumatoriaplazas = asignadas + noasignadas;
  }

  semestre(){
    let plan = <HTMLSelectElement>document.getElementById('planestudio');
    this.planesactivos.forEach(x => {
      if(plan.value == x.idplanestudio){
        this.semestres=[
          { 
            tipo:'INTERNADO',
            semestre:x.semestre + 1
          },
          {
            tipo:'SERVICIO SOCIAL',
            semestre: x.semestre + 3 
          }
        ]
      }
    });
    //console.log(this.semestres );
  }
  tomardatos(){
    var idperiodo = <HTMLSelectElement>document.getElementById('peridoescolar');
    var planestudio = <HTMLSelectElement>document.getElementById('planestudio');
    var idinstitucion = <HTMLSelectElement>document.getElementById('institucion');
    var semester = <HTMLSelectElement>document.getElementById('semestre');
    var asignadas = this.formPlaza.get('asignadas').value;
    var noasignadas = this.formPlaza.get('noasignadas').value;
    var ubicacion = <HTMLSelectElement>document.getElementById('ubicacion');
    var rotacion = <HTMLSelectElement>document.getElementById('rotacion');
    var ubicacion1 = <HTMLSelectElement>document.getElementById('ubicacion1');
    var ubicacion2= <HTMLSelectElement>document.getElementById('ubicacion2');
    var ubicacion3 = <HTMLSelectElement>document.getElementById('ubicacion3');
    if(rotacion.value == "NO"){
      var jason = {
        id:this.formPlaza.get('id').value,
        planestudioid: parseInt(planestudio.value),
        periodoid:idperiodo.value,
        institucionid:parseInt(idinstitucion.value),
        asignadas:asignadas,
        noasignadas:noasignadas,
        plazas:this.sumatoriaplazas,
        ubicacion:ubicacion.value,
        rotacion:rotacion.value,
        mat:this.maestro.matricula,
        semestre:semester.value,
        matriculap: this.maestro.matricula
      }
      this.insertarPlazasAdministrar(jason);
    }else{
      if(rotacion.value == "SI"){
        var jason2 = {
          id:this.formPlaza.get('id').value,
          periodoid:idperiodo.value,
          planestudioid: parseInt(planestudio.value),
          institucionid:parseInt(idinstitucion.value),
          asignadas:asignadas,
          noasignadas:noasignadas,
          plazas:this.sumatoriaplazas,
          ubicacion:ubicacion.value,
          rotacion:rotacion.value,
          ubicacion1:ubicacion1.value,
          ubicacion2:ubicacion2.value,
          ubicacion3:ubicacion3.value,
          semestre:semester.value,
          matriculap: this.maestro.matricula
        }
        this.insertarPlazasAdministrar(jason2);
      }
    }
  }

  insertarPlazasAdministrar(data){
    this.consultas.insertarPlazasAdministrar(data).subscribe({
      next: (res : any) =>{
        this.respuesta = res;
      },
      error: (err)=>{
        console.log(err);
      },
      complete:()=>{
        $('#respuesta').modal('show');
        this.listaPlazas();
        this.titituloactiva = 'Crear Plaza'
        if(this.respuesta.codigo == "OK"){
          var idperiodo = <HTMLSelectElement>document.getElementById('peridoescolar');
          var idinstitucion = <HTMLSelectElement>document.getElementById('institucion');
          var asignadas = this.formPlaza.get('asignadas').value;
          var noasignadas = this.formPlaza.get('noasignadas').value;
          var ubicacion = <HTMLSelectElement>document.getElementById('ubicacion');
          var rotacion = <HTMLSelectElement>document.getElementById('rotacion');
          var ubicacion1 = <HTMLSelectElement>document.getElementById('ubicacion1');
          var ubicacion2= <HTMLSelectElement>document.getElementById('ubicacion2');
          var ubicacion3 = <HTMLSelectElement>document.getElementById('ubicacion3');
        }
      }
    });
  }

  cerrarmodalrespuseta(){
    $('#respuesta').modal('hide');
  }

  //---------------------------------------------------------------------------------------

  listaPeriodos(){
    this.consultas.periodos(this.maestro.matricula).subscribe({
      next: (res) =>{
        this.periodosescolares = res;
      },
      error: (err)=>{
        throw new Error('Error al cargar períodos');
      },
      complete:()=>{

      }
    });
  }
  

  sumarPlaza(){
    this.formPlaza.patchValue({
      id: ((this.formPlaza.get('id').value==null || this.formPlaza.get('id').value==undefined)?0:this.formPlaza.get('id').value)
      ,periodoid: this.formPlaza.get('periodoid').value
      ,institucionid: this.formPlaza.get('institucionid').value
      ,asignadas: this.formPlaza.get('asignadas').value
      ,noasignadas: this.formPlaza.get('noasignadas').value
      ,plazas: this.formPlaza.get('asignadas').value + this.formPlaza.get('noasignadas').value
      ,ubicacion: this.formPlaza.get('ubicacion').value
      ,rotacion: this.formPlaza.get('rotacion').value
      ,mat: this.maestro.matricula
    });
    //(<HTMLSelectElement>document.getElementById('plazas')).value=this.formPlaza.get('plazasasignadas').value+this.formPlaza.get('plazasnoasignadas').value
    console.log(this.formPlaza.get('plazas').value );
  }


  editarPlaza(valores){
    this.titituloactiva = 'Editar Plaza'
    $('#modalPlaza').modal('show'); 
    this.formPlaza.patchValue({
      id: ((valores.id==null || valores.id==undefined)?0:valores.id)
      ,planestudioid: valores.planestudioid
      ,periodoid: valores.periodoid
      ,institucionid: valores.institucionid
      ,asignadas: valores.asignadas
      ,noasignadas: valores.noasignadas
      ,plazas: valores.asignadas + valores.noasignadas
      ,ubicacion: valores.ubicacion
      ,rotacion: valores.rotacion
      ,mat: this.maestro.matricula
    });
    let instituciones = <HTMLSelectElement>document.getElementById('institucion');
    let periodos = <HTMLSelectElement>document.getElementById('peridoescolar');
    let rotacion = <HTMLSelectElement>document.getElementById('rotacion');
    var planestudio = <HTMLSelectElement>document.getElementById('planestudio');
    var semest = <HTMLSelectElement>document.getElementById('semestre');

    for(var x = 0; x < this.periodosescolares.length; x++){
      if(this.periodosescolares[x].nombre == valores.periodo){
        periodos.selectedIndex =  x+1;
      }
    }
    for(var i = 0; i < this.instituciones.length; i++){
      if(this.instituciones[i].institucionid == this.formPlaza.get('institucionid').value){
        instituciones.selectedIndex = i+1;
      }
    }
    if(valores.rotacion == 'SI'){
      rotacion.selectedIndex = i = 1;
    }
    if(valores.rotacion == 'NO'){
      rotacion.selectedIndex = i = 2;
    }
    for(var a = 0; a < this.planesactivos.length; a++){
      if(this.planesactivos[a].idplanestudio == valores.planestudioid){
        planestudio.selectedIndex = a+1;
        this.semestre();
      }
    }
    for(var j = 0; j < this.semestres.length; j++){
      if(this.semestres[j].semestre == valores.semestre){
        semest.selectedIndex = j+1;
      }
    }
  }

  limpiarPlaza(){
    this.formPlaza.reset();
    this.formPlaza.patchValue({
      id: 0
      ,periodoid: 0
      ,institucionid:0
      ,asignadas: 0
      ,noasignadas: 0
      ,plazas: 0
      ,ubicacion: "DURANGO"
      ,rotacion: "SI"
      ,mat: this.maestro.matricula
    });
  }

  listaInstituciones(){
    this.consultas.listaInstituciones(this.maestro.matricula,0).subscribe({
      next: (res) =>{
        this.instituciones = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Instituciones');
      },
      complete:()=>{

      }
    });
  }

  listaPlazas(){
    var idperiodo = <HTMLSelectElement>document.getElementById('plazaperiodoid2');
    this.consultas.listaPlazasAdministrar(this.maestro.matricula, idperiodo.value).subscribe({
      next: (res) =>{
        this.plazas = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Plazas');
      },
      complete:()=>{
        
        this.banderatabla = false;
      }
    });
  }

  accionPlazas(valores){
    switch (valores.action)
    {
      case "editar":
          this.editarPlaza(valores.data);
          break;
      case "borrar": 
          break;
      default: 
      
        break;
    }
  }

  listaEstados(idedo:number){
    this.consultas.listaEstadosRepublica(this.maestro.matricula,idedo).subscribe({
      next: (res) =>{
        this.estadosRepublica = res;
      },
      error: ()=>{
        throw new Error('Error al cargar Estados de la República');
      },
      complete:()=>{

      }
    });
  }
}
