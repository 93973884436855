<div class="row" id="top">
    <div class="col-12">
        <h3>Reporte Final Asignación de Plazas</h3>
    </div>
</div>
<div class="row" id="top">
    <div class="col-3">
        <select class="form-control" id="plazaperiodoid2" >
            <option selected>--Período Escolar--</option>
            <option *ngFor="let periodo of periodosescolares" [value]="periodo.id">{{ periodo.nombre }}</option>
        </select>
    </div>
    <div class="col-3">
        <select class="form-control" id="planestudio" >
            <option value="">--Plan de Estudio--</option>
            <option *ngFor="let plan of planesactivos" [value]="plan.idplanestudio">{{ plan.carrera }} - ({{plan.plandeestudio}})</option>
        </select>
    </div>
    <div class="col-3">
      <select class="form-control" id="semestre" >
          <option value="">--Semestre--</option>
          <option value="11">Internado</option>
          <option value="13">Servicio Social</option>
      </select>
  </div>
    <div class="col-3">
            <button (click)="consultarReporte()" class="btn btn-danger">Generar</button>
    </div>
</div>

  
<div class="modal fade" id="documento" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl  modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actas</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="!banderapdf">
            <tld-file-viewer [src]="src" [type]="type"></tld-file-viewer>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-danger" (click)="imprimiractasporgrupo()">Imprimir Lista PDF</button>
        </div>
      </div>
    </div>
</div>