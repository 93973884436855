<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container-fluid">
        <div class="row" >
            <div class="col-12">   
                <div class="row" id="cuerpo"> 
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
                                    <li class="nav-item" *ngIf="!banderaListadoFechas">
                                        <a class="nav-link active" id="fechas" data-toggle="tab" href="#Fechas" role="tab" aria-controls="Fechas" aria-selected="true">Fechas</a>
                                    </li>
                                    <li class="nav-item" *ngIf="!banderaAgregarFechas">
                                        <a class="nav-link" id="agregarFecha" href="#Fechas" aria-controls="AgregarFecha" aria-selected="false"  data-target="#modalFechas" data-toggle="modal"><i id="plus" class="fas fa-plus"></i>Agregar</a>
                                    </li>
                                </ul>
                                <div class="tab-content mb-2" id="myTabContent" *ngIf="!banderaListadoFechas">
                                    <div class="tab-pane fade show active " id="fechas" role="tabpanel" aria-labelledby="fechas">
                                        <ng2-smart-table class="table table-sm table-striped table-hover table-borderless" [settings]="settingsExamenes" [source]="fechasExamenes" (custom)='accion($event)'></ng2-smart-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ----------------------------------modal para agregar fecha-------------------------------------------------------------- -->
        
        <div class="modal fade" id="modalFechas" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <table class="table table-striped">
                        <thead class="bg-danger text-white">
                            <tr>
                                <div class="modal-header text-center">
                                <h4 class="modal-title w-100 font-weight-bold">Fecha de Exámenes</h4>
                                <button (click)='onCloseFechas()' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <form  [formGroup]='createForm' (ngSubmit)="onCreate()">
                                        <div class="form-row">
                                            <div class="md-form mb-3 col-4">
                                                <input  type="hidden"  class="form-control validate" [formControl]="this.createForm.get('idCalendario')" id="id" >
                                                <label data-error="wrong"  data-success="right" for="defaultForm-email">Período Escolar</label>
                                                <select id='periodoEscolar' [formControl]="this.createForm.get('periodoId')" class="form-control">
                                                    <option *ngFor="let periodo of periodos" [value]="periodo.id">{{periodo.nombre}}</option>
                                                </select>
                                            </div>
                                            <div class="md-form mb-3 col-6">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Carrera</label>
                                                <select id='carrera' (change)="selectPlan(1)" class="form-control">
                                                    <option>Seleccionar</option>
                                                    <option *ngFor="let carrera of carreras" [value]="carrera.idcarrera">{{carrera.carrera}}-{{carrera.plandeestudio}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="md-form mb-3 col-3 text-center">
                                                <label>Semestre</label>
                                                <!-- -->
                                                <select class="form-control" [formControl]="this.createForm.get('semestre')" id='semestre'(change)="listaMaterias(1)" >
                                                    <option value="">--SEMESTRE--</option>
                                                    <option *ngFor="let s of semestre" [value]="s.id">{{s.semestre}}</option>
                                                </select>
                                            </div>

                                            <div class="md-form mb-3 col-9">
                                                <label>Materia</label>
                                                <!-- -->
                                                <select id='materia' [formControl]="this.createForm.get('mpeId')" class="form-control" >
                                                    <option [selected]="this.createForm.get('mpeId').value==materia.id" *ngFor="let materia of materiasPle" [value]="materia.id">{{materia.materia.nombre}} - Sem {{materia.semestre}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-row">                                   
                                            <div class="md-form mb-3 col-4">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Fecha Examen Ordinario</label>
                                                <input  type="date" class="form-control validate" [formControl]="this.createForm.get('fechaOrdinario')" id="fechaOrdinario" value="{{fechaActual}}">
                                            </div>
                                            <div class="md-form mb-3 col-4">
                                                <label data-error="wrong" data-success="right" for="defaultForm-email">Fecha Examen Extraordinario</label>
                                                <input  type="date" class="form-control validate" [formControl]="this.createForm.get('fechaExtraordinario')" id="fechaExtraordinario" value="{{fechaActual}}">
                                            </div>
                                        </div>
                                        <div class="md-form mb-4">
                                            <button (click)='onCloseFechas()' type="button" class="btn btn-secondary btn-md mr-2"  data-dismiss="modal">Cerrar</button>
                                            <button [disabled]='!createForm.valid' id="btnAceptarGrupo" type="submit" class="btn btn-danger btn-md">Aceptar</button>
                                        </div>
                                    </form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            
    </div>
</body>
</html>


