import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Global} from 'src/global';

interface Permisos{
  dato:number;
  matriculap:string;
}
interface Delete{
  dato:number;
  matriculap:string;
}
interface Editar{
  id:number;
  descripcion:string;
  nombre:string;
  matriculap:string;
}
interface Crear{
  descripcion:string;
  nombre:string;
  matriculap:string;
}
interface QuitarPermiso{
  dato: string;
  dato2: string;
  dato3: string;
}
interface PonerPermiso{
  dato: string;
  dato2: string;
  dato3: string;
}
interface Vistas{
  dato:number;
  matriculap:string;
}

interface SemestresAsignados{
  dato:number;
  dato2:number;
  dato3:string;
}
interface InsertVista{
  rol:string;
  carrera_id:number;
  semestre:number;
  matriculap:string;
}
interface EliminarVista{
  dato:string;
  matriculap:string;
}



@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient, private global: Global) { }

  consultarroles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/buscaroles/${matricula}`, null);
  }
  consultarpermisosactivos(permisos:Permisos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/permisosactivos`, permisos);
  }
  consultarpermisosinactivos(permisos:Permisos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/permisosnoactivos`, permisos);
  }

  agregarpermiso(permiso:PonerPermiso){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/relacionar`, permiso);
  }
  eliminarpermiso(permiso:QuitarPermiso){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/quitarrelacion`, permiso);
  }

  eliminarrol(rol:Delete){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/delete`, rol);
  }
  editarrol(rol:Editar){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/editar`, rol, { headers:this.global.headers });
  }
  crearrol(rol:Crear){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/insert`, rol, { headers:this.global.headers });
  }
  
  consultarvistasactivaspormatricular(vista:Vistas){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistasactivas`, vista);
  }
  consultarvistasasignables(vista){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistas`, vista);
  }
  consultarsemestresasignados(vista:SemestresAsignados){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistasactivasxcarrera`, vista);
  }
  consultarsemestresnoasignados(vista:SemestresAsignados){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/vistasnoactivas`, vista);
  }
  agregarsemestredevista(vista:InsertVista){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/insertVista`, vista);
  }
  eliminarsemestredevista(vista:EliminarVista){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/rol/deleteVista`, vista);
  }

}