import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PersonalService } from '../services/personal.service';
declare var $ : any;


@Component({
  selector: 'app-subir-documentos',
  templateUrl: './subir-documentos.component.html',
  styleUrls: ['./subir-documentos.component.css']
})
export class SubirDocumentosComponent implements OnInit {

  uploadForm: FormGroup;

  documentos = [{
    id:Number,
    nombre:''
  }]

  documentosSubidos = [{
    archivo:'',
    id:Number,
    nombre:''
  }]

  maestro:{
    apellidom: string,
    apellidop: string,
    calle: string,
    celular: string,
    colonia: string,
    curp: string,
    email: string,
    fotografia: string,
    maestro: number,
    matricula: string,
    nombres: string;
    numeroexterior: string,
    numerointerior: string,  
    rfc: string,
    tipoContrato: string
  }

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  respuesta2 = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  documentoSelectionado = {
    id:Number,
    nombre:''
  };

  bandera = true;
  banderabtn = true;
  banderatabla = true;
  banderadocumento = true;

  iddocumento = '';
  documentonombre = '';

  constructor(private personalService: PersonalService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.maestro = JSON.parse(localStorage.getItem('personal'));
    this.documentosposibles();
    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required, ]
    });
  }

  verDocumento(id, nombre){
    this.iddocumento = id;
    this.documentonombre = nombre; 
    $('#documento').modal('show');
    this.banderadocumento = false;
  }

  cerrarDocumento(){
    $('#documento').modal('hide');
    this.banderadocumento = true;
  }

  updateListado(){
    this.documentosposibles();
  }

  documentosposibles(){
    this.personalService.documentosPosibles(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.documentos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentos.length == 0){
          this.banderabtn = false;
        }
        this.documentossubidos();
      }
    });
  }

  documentossubidos(){
    this.personalService.documentosSubidos(this.maestro.matricula).subscribe({
      next:(res: any) => {
        this.documentosSubidos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.banderatabla = false;
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  onSubmit(){
    var sel = <HTMLInputElement>document.getElementById('scripts');
    var id;
    for(var i = 0; i < this.documentos.length; i++){
      if(sel.value == this.documentos[i].nombre){
        id = this.documentos[i].id;
        break;
      }
    }
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    formData.append('matricula', this.maestro.matricula);
    formData.append('tipo', id);
    console.log(formData);
    console.log(this.uploadForm.get('file').value);
    console.log(id);
    console.log(this.maestro.matricula);
    this.personalService.subirDocumentos(formData).subscribe({
      next:(res: any) => {
        this.respuesta2 = res;
        if(this.respuesta2.codigo == "ERROR"){
          this.bandera = false;
        }else{
          $('#exampleModalCenter').modal('hide');
          setTimeout(() => {
            $('#exampleModalSubirRespCenter').modal('show');
            this.updateListado();
          }, 500)
        }
      },
      error:(err) => {
        console.log(err);
      }
    })
  }

  selectDocumento(documento: any){
    this.documentoSelectionado = documento;
  }

  borrarDocumento(){
    this.personalService.documentoEliminar(this.documentoSelectionado.id).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      complete:() => {
        $('#exampleModalBorrarRespCenter').modal('show');
        if(this.respuesta.codigo == "OK"){
          this.banderabtn = true;
          this.updateListado();
        }else{

        }
      }
      ,
      error:(err) => {
        console.log(err);
      }
    });
  }

  imprimirDocumento(id){
    this.personalService.documentosImprimir(id);
  }

}